import React, { Component } from 'react';
import {Row, Col, Modal,Tab, Tabs} from 'react-bootstrap';
import { withRouter } from "react-router-dom";

import moment from 'moment';
import { LocaleProvider, DatePicker } from 'antd';
import FileViewer from 'react-file-viewer';
import swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import enUS from 'antd/lib/locale-provider/en_US';
import _ from 'lodash';
import 'antd/dist/antd.css';
import "sweetalert2/dist/sweetalert2.min.css";
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import ReactPlayer from 'react-player';

import ImageModal from './ImageModal';
import AlbumModal from './AlbumModal';

const readCookie = require('../../cookie.js').readCookie;

export default class AddResourceBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openDataModal: false,
			addFilesToAlbum: false,
			showAlbumForm: true,
			openModal: false,
			visibility:"",
			img_arr: [],
			video_arr: [],
			album_data: [],
			videoUrl: false,
			title: '',
			description: '',
			type: null,
			uploadDocs: false,
			uploadImage: false,
			uploadAlbum: false,
			type: '',
			fileType: null,
			filePreviewUrl: null,
			url: null,
			buttonType : "",
			imgId:"",
			activeTab: 1,
			file:null
		}
	}


	componentDidMount(){
		// if (this.props.rout.hasOwnProperty('resource_type') && this.props.rout.hasOwnProperty('resource_file') && this.props.rout.resource_file === 'photos') {
		// 	this.openAlbum();
		// 	this.setState({ activeTab: 1 });
		// } if (this.props.rout.hasOwnProperty('resource_type') && this.props.rout.hasOwnProperty('resource_file') && this.props.rout.resource_file === 'videos') {
		// 	this.openAlbum();
		// 	this.setState({ activeTab: 2 });
		// } else if (this.props.rout.hasOwnProperty('resource_file')) {
		// 	window.location.pathname = '/404';
		// }
		fetch(process.env.REACT_APP_MIS_API_URL+"/project/"+this.props.project_id+"/resources?albumId="+this.props.album_id,{
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		})
		.then((data1) => data1.json())
		.then(res => {
			this.setState({album_data: res.data});
			console.log(res.data);
			let img_arr = [];
			let video_arr = []
			_.map(res.data, (data, index) => {
				if(data.url !== undefined){
					(
						this.validateYouTubeUrl(this, data)===true
							?
						video_arr.push(data)
							:
						img_arr.push(data)
					)
				}else{
					return null;
				}
			});
			this.setState({
				img_arr: img_arr,
				video_arr: video_arr,
				// title, description, visibility
			});

		})
		.catch(err => {
			console.log("err", err)
		})
	}

	notifySuccess = (text) => toast.success(text);

	notifyError = (text) => toast.error(text);

    cancelForm(){
		this.setState({
			addFilesToAlbum: false,
			showAlbumForm: true,
			openModal: false,
			img_arr: [],
			video_arr: [],
			album_data: [],
			videoUrl: false,
			title: null,
			description: null,
			type: null,
			uploadDocs: false,
			uploadImage: false,
			uploadAlbum: false,
		})
        this.props.cancelResourcesForm();
	}

	uploadOptions(event){
		this.setState({type: event.target.value, videoUrl: false, uploadAlbum: false, uploadDocs: false, uploadImage: false});
		switch(event.target.value) {
			case "video":
				this.setState({videoUrl: true})
			case "doc":
				this.setState({uploadDocs: true})
				break;

			case "image":
				this.setState({uploadImage: true})
				break;

			case "album":
				this.setState({uploadAlbum: true})
				break;
		}

	}

	uploadDocFile(data, event){
		this.setState({file: event.target.files[0], type: data});
	}

	uploadAlbumFile(event){
		this.setState({file: event.target.files[0]});
	}

	validateYouTubeUrl = (data, event) => {
		var url = data;
		if(event.url === undefined){
			return false;
		}
		if (event.url !== undefined || event.url !== '') {
			var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
			var match = event.url.match(regExp);
			if (match && match[2].length == 11) {
				return true;
			} else {
				return false;
			}
		}
	}

	submitForm(event){
		if(this.state.file !== null, this.state.title !== "", this.state.visibility !== "", this.state.description !== ""){
		var data = new FormData();
		data.append('url', this.state.url);
		data.append('file', this.state.file);
		data.append('type', this.state.type);
		data.append('title', this.state.title);
		data.append('albumId', this.props.album_id);
		data.append('visibility', this.state.visibility);
		data.append('description', this.state.description);
		fetch(process.env.REACT_APP_MIS_API_URL+"/project/"+this.props.project_id+"/resources",{
			method: 'POST',
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			},
			body: data
			})
			.then((response) => response.json())
			.then(res => {
				if(res.status === "ok"){
					Alert.success('Resources Added', {
	          position: 'bottom-center',
	          timeout: 500
					});
					
					if (this.state.addFilesToAlbum === true) {
						this.setState({
							addFilesToAlbum: false,
							type: null,
							uploadDocs: false,
							uploadImage: false,
							uploadAlbum: false
						})
					} else {
						// this.props.cancelResourcesForm();
						this.props.reloadPageAndCancel();
					}
					window.location.pathname = '/im/projects/' + this.props.match.params.project_id + '/resources/images'
					// this.props.history.push('/im/projects/' + this.props.match.params.project_id + '/resources/images');
				}
			}).catch(err => {
				// console.log("err", err);
				Alert.error('Failed to add Resources', {
					position: 'bottom-center',
					timeout: 500
				})
			})
		} else {
			this.notifyError("Mandatory Values are Missing");
		}
	}

	submitEditDetails (e){
		var data =  new FormData();
		data.append('title', this.state.title);
		data.append('albumId', this.props.album_id);
		data.append('imageId', this.state.imgId);
		data.append('visibility', this.state.visibility);
		data.append('description', this.state.description);
		fetch(process.env.REACT_APP_MIS_API_URL+"/resources/" + this.state.imgId,{
		method: 'PUT',
		headers: {
			'Auth': JSON.parse(readCookie('mis_data')).access_token
		},
		body: data
		})
		.then((response) => response.json())
		.then(res => {
			// console.log("res", res)
			if(res.status === "ok"){
				Alert.success('Resources Added', {
					position: 'bottom-center',
					timeout: 500
				});

				if(this.state.addFilesToAlbum === true){
					this.setState({
						addFilesToAlbum: false,
						type: null,
						uploadDocs: false,
						uploadImage: false,
						uploadAlbum: false
					})
				} else {
					this.props.cancelResourcesForm();
				}
				let img_arr = this.state.img_arr;
				for(var i = 0; i < img_arr.length; i++) {
					if(img_arr[i].id === this.state.imgId) {
						img_arr[i].title = res.data.title;
						img_arr[i].description = res.data.description;
						img_arr[i].visibility = res.data.visibility;
						img_arr[i].url = res.data.url;
					}
				}
				this.setState({img_arr, title : res.data.title, description: res.data.description, visibility: res.data.visibility, url: res.data.url});
				// window.location.pathname = '/im/projects/' + this.props.project_id + '/resources/images';
			}
		}).catch(err => {
			console.log("err", err);
			Alert.error('Failed to add Resources', {
				position: 'bottom-centerx',
				timeout: 500
			})
		})
	}

	resourcesVisibility(event){
		this.setState({visibility: event.target.value});
	}

	setTitle(event){
		this.setState({title: event.target.value});
	}

	setDescription(event){
		this.setState({description: event.target.value});
	}

	addFilesToAlbum(name, id ,event){
		this.setState({addFilesToAlbum: true, buttonType: name, title: "", description: "", visibility: ""});
	}
	editFilesOfAlbum(name, id , i,event){
		let imageData = this.state.img_arr
		this.setState({addFilesToAlbum: true, buttonType: name, imgId: id, title : imageData[i].title, description: imageData[i].description, visibility: imageData[i].visibility});
	}

	setUrl(event){
		this.setState({url: event.target.value})
	}

	openAlbum(data, event){
		this.setState({openModal: true})
		this.props.onOpenModal(this.state.img_arr, data, {type: "image"});
	}

	openVideos(data, event){
		this.setState({openModal: true})
		this.props.onOpenModal(this.state.video_arr, data, {type: "video"});
	}

	onCloseModal(data, event){
		this.setState({openModal: false})
	}

	closeForm(){
		this.setState({addFilesToAlbum: false})
	}
	onCloseDataModal(){
		this.setState({openDataModal: false})
	}
	keyChange(key) {
		// if (key !== this.state.activeTab) {
		// 	if (key === 1) {
		// 		window.location.pathname = '/im/projects/' + this.props.project_id + '/resources/images/albums/photos';
		// 	} else if (key === 2) {
		// 		window.location.pathname = '/im/projects/' + this.props.project_id + '/resources/images/albums/videos';
		// 	}
		// }
		this.setState({activeTab: key});
	}

	render() {
		return (
			<div className="im-project-container im-project-report-expense-container">
			<Alert stack={{limit: 3}} />
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<div className="padding0">
					{
						(this.props.fileType === 'image')
							?
						<Row>
							{/* <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} /> */}
							<Col md={12}
								style={{
									background: '#f3f3f3',
									boxShadow: '0px 0px 2px #999', margin:'5px 5px 25px 5px !important',
									padding: '10px 10px 30px 10px !important'
								}}
							>
								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Title:</label>
									<input className="form-control" type="text" placeholder="Enter Title" onChange={this.setTitle.bind(this)} />
								</Col>

								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Description:</label>
									<input className="form-control" type="text" placeholder="Enter Description"
									//ref={(e) => this.description = e.target}
									onChange={this.setDescription.bind(this)}
									/>
								</Col>
								{this.state.buttonType === 'add-files' ? (
									<Col className="form-group" md={4}>
									<label className="control-label is-imp">Select Visibility:</label>
									<select className="form-control" required onChange={this.resourcesVisibility.bind(this)}>
										<option value=''>Select</option>
										<option value='public' >public</option>
										<option value='private' >private</option>
									</select>
								</Col>
								) : (null)}
								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Upload Image</label>
									<input type="file" accept="image/*" className="btn btn-info" onChange={this.uploadDocFile.bind(this, 'image')}></input>
								</Col>

								<Col className="mt10 mb10 form-buttons" md={12}>

									<button type="button" className="btn btn-default btn-danger pull-right" onClick={this.cancelForm.bind(this)}> Cancel </button>
									<button type="button" className="btn btn-default pull-right" style={{marginRight: '10px'}} onClick={this.submitForm.bind(this)}> Submit </button>

								</Col>
						</Col>
						</Row>
							:
						null
					}

					{
						(this.props.fileType === 'album')
							?
						<Row>
							<Col md={12}
								style={{
									background: '#f3f3f3',
									boxShadow: '0px 0px 2px #999', margin:'5px 5px 25px 5px !important',
									padding: '10px 10px 30px 10px !important'
								}}
							>
									{/* <button type="file" className="btn btn-info">Upload Docs</button> */}

								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Title:</label>
									<input className="form-control" type="text" placeholder="Enter Title" onChange={this.setTitle.bind(this)} />
								</Col>

								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Description:</label>
									<input className="form-control" type="text" placeholder="Enter Description"
									//ref={(e) => this.description = e.target}
									onChange={this.setDescription.bind(this)}
									/>
								</Col>

								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Select Visibility:</label>
									<select className="form-control" required onChange={this.resourcesVisibility.bind(this)}>
										<option value=''>Select</option>
										<option value='public' >public</option>
										<option value='private' >private</option>
									</select>
								</Col>

								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Select Image:</label>
									<input type="file" accept="image/*" className="btn btn-info" onChange={this.uploadDocFile.bind(this, 'album')}></input>

								</Col>
								{
									(this.state.uploadImage === true
										?
										<Col className="form-group" md={4}>
											<label className="control-label is-imp">Upload File</label>
											<input type="file" accept="image/*" className="btn btn-info" onChange={this.uploadDocFile.bind(this,'image')}></input>
										</Col>
										:
										null
									)
								}

								{
									(this.state.videoUrl
										?
										<Col className="form-group" md={4}>
										<label className="control-label is-imp">Type Video Url</label>
											<input type="text" className="form-control" onChange={(e) => this.setState({url: e.target.value})} />
										</Col>
										:
										null
									)
								}
								<Col className="mt10 mb10 form-buttons" md={12}>
									<button type="button" className="btn btn-default btn-danger pull-right" onClick={this.cancelForm.bind(this)}> Cancel </button>
									<button type="button" className="btn btn-default pull-right" style={{marginRight: '10px'}} onClick={this.submitForm.bind(this)}> Submit </button>
								</Col>
						</Col>
						</Row>
							:
						null
					}

					{
						(this.props.fileType === 'docs')
							?
						<Row>
							<Col md={12}
								style={{
									background: '#f3f3f3',
									boxShadow: '0px 0px 2px #999', margin:'5px 5px 25px 5px !important',
									padding: '10px 10px 30px 10px !important'
								}}
							>
								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Title:</label>
									<input className="form-control" type="text" placeholder="Enter Title" onChange={this.setTitle.bind(this)} />
								</Col>

								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Description:</label>
									<input className="form-control" type="text" placeholder="Enter Description"
									//ref={(e) => this.description = e.target}
									onChange={this.setDescription.bind(this)}
									/>
								</Col>

								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Select Visibility:</label>
									<select className="form-control" required onChange={this.resourcesVisibility.bind(this)}>
										<option value=''>Select</option>
										<option value='public' >Visible To Corporates</option>
										<option value='private' >Visible Only To My Organisation</option>
									</select>
								</Col>

								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Upload File</label>
										<input type="file" className="btn btn-info"
											// type="file"
										accept="application/pdf,application/msword,
										application/vnd.openxmlformats-officedocument.wordprocessingml.document"
										onChange={this.uploadDocFile.bind(this, 'doc')}
									/>
								</Col>
								<Col className="mt10 mb10 form-buttons" md={12}>
									<button type="button" className="btn btn-default btn-danger pull-right" onClick={this.cancelForm.bind(this)}> Cancel </button>
									<button type="button" className="btn btn-default pull-right" style={{marginRight: '10px'}} onClick={this.submitForm.bind(this)}> Submit </button>
								</Col>
						</Col>

						</Row>
							:
						null
					}
				</div>

				{
					(this.props.album_id !== null)
						?
						<Row style={{display:"flex", flexDirection:"column"}}>
							{
							(this.state.addFilesToAlbum === true)
								?
								<Row>
								<Col md={12}
									style={{
										background: '#f3f3f3',
										boxShadow: '0px 0px 2px #999', margin:'5px 5px 25px 5px !important',
										padding: '10px 10px 30px 10px !important'
									}}
								>
									{this.state.buttonType !== "edit-details" ? (
										<Col className="form-group" md={4}>
											<label className="control-label is-imp">Title:</label>
											<input className="form-control" type="text" value={this.state.title} placeholder="Enter Title" onChange={this.setTitle.bind(this)} />
										</Col>
									) : (
										<Col className="form-group" md={4}>
										<label className="control-label">Title:</label>
										<input className="form-control" type="text" value={this.state.title} placeholder="Enter Title" onChange={this.setTitle.bind(this)} />
									</Col>
									)}

									{this.state.buttonType !== "edit-details" ? (
										<Col className="form-group" md={4}>
											<label className="control-label is-imp">Description:</label>
											<input className="form-control" type="text" value={this.state.description} placeholder="Enter Description" onChange={this.setDescription.bind(this)}/>
										</Col>
									) : (
										<Col className="form-group" md={4}>
										<label className="control-label">Description:</label>
										<input className="form-control" type="text" value={this.state.description} placeholder="Enter Description"
										onChange={this.setDescription.bind(this)}
										/>
									</Col>
									)}

									<Col className="form-group" md={4}>
										<label className="control-label is-imp">Select Visibility:</label>
										<select className="form-control" value={this.state.visibility} required onChange={this.resourcesVisibility.bind(this)}>
											<option value=''>Select</option>
											<option value='public' >Visible To Corporates</option>
											<option value='private' >Visible Only To My Organisation</option>
										</select>
									</Col>

									{this.state.buttonType !== "edit-details" ? (
										<Col className="form-group" md={4}>
										<label className="control-label is-imp">Select Document Type:</label>
										<select className="form-control" required onChange={this.uploadOptions.bind(this)}>
											<option value=''>Select Type</option>
											<option value='image'>Image</option>
											{(this.props.album_id === null
													?
												<option value='album'>Album</option>
													:
												<option value='video'>Video Url</option>
											)}
											{(this.props.album_id === null
													?
												<option value='doc'>Document</option>
													:
												null
											)}
										</select>
									</Col>
									) : null}

									{
										((this.state.uploadDocs === true && this.state.videoUrl === false)
											?
											<Col className="form-group" md={4}>
												<label className="control-label is-imp">Upload File</label>
														<input type="file" className="btn btn-info"
															// type="file"
													accept="application/pdf,application/msword,
													application/vnd.openxmlformats-officedocument.wordprocessingml.document"
													onChange={this.uploadDocFile.bind(this, 'doc')}
												/>
											</Col>
											:
											null
										)
									}
									{

										(this.state.uploadImage === true
											?
											<Col className="form-group" md={4}>
												<label className="control-label is-imp">Upload File</label>
												<input type="file" accept="image/*" className="btn btn-info" onChange={this.uploadDocFile.bind(this, 'image')}></input>
											</Col>
											:
											null
										)
									}
									{
										(this.state.uploadAlbum === true
											?
											<Col className="form-group" md={4}>
												<label className="control-label is-imp">Upload File</label>

												<input type="file" accept="image/*" className="btn btn-info" onChange={this.uploadAlbumFile.bind(this)} multiple></input>
											</Col>
											:
											null
										)

									}
									{
										(this.state.videoUrl
											?
											<Col className="form-group" md={4}>
											<label className="control-label is-imp">Type Video Url</label>

												<input type="text" className="form-control" onChange={(e) => this.setState({url: e.target.value})} />
											</Col>
											:
											null
										)
									}

									<Col className="mt10 mb10 form-buttons" md={12}>
										<button type="button" className="btn btn-default btn-danger pull-right" onClick={this.closeForm.bind(this)}> Cancel </button>
										<button type="button" className="btn btn-default pull-right" style={{marginRight: '10px'}} onClick={this.state.buttonType === 'edit-details' ? this.submitEditDetails.bind(this) : this.submitForm.bind(this)}> Submit </button>
									</Col>
							</Col>

							</Row>
								:
								<Col>
									<Col className="form-group" md={4} style={{paddingLeft:"0px"}}>
										<button type="button" className="btn btn-info" onClick={this.cancelForm.bind(this)}> Go Back </button>
									</Col>
								</Col>
							}
					<div className='tabs mt30'>
						<Tabs activeKey={this.state.activeTab} onSelect={this.keyChange.bind(this)} id="resources-tabs-container">
						<Tab eventKey={1} title="Photos">
							<Row>
              	<div
									className="albums-container"
									style={{
									backgroundColor: "#FCFCFC",
									width: "100%",
									height: "40%",
									padding: "20px",
									display: "flex",
									overflowY: "scroll",
									flexDirection: "column",
									alignItems: "center",
									border:"1px solid #e3e3e3"
									}}
								>
									<div
									className="albums-container-header"
									style={{ textAlign: "center" }}
									>
										<h4
											style={{
											marginTop: "0",
											marginBottom: "20px",
											fontWeight: "bold"
											}}
										>
											Photos
										</h4>
                					</div>
                				<div
									className="albums-row"
									style={{
										display: "flex",
										flexWrap: "wrap",
										width: "960px"
									}}
								>
								{
									(this.state.img_arr.length>0)
										?
									_.map(this.state.img_arr, (album, index) => (
									<div style={{marginBottom:"15px"}}>
										<div key={index}
											className="album-1-container"
											style={{
											margin: "0 10px 0 0",
											width: "150px",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											cursor : 'pointer'
											}}
											onClick={this.openAlbum.bind(this, index)}>
											<div
												className="album-1"
												style={{
													backgroundColor: "#F3F3F3",
													width: "150px",
													height: "150px",
													borderRadius: "7px",
													overflow: "hidden"
											}}>
												<img
													src={album.url}
													alt="album"
													style={{ width: "140%", height: "100%" }}
												/>
												</div>
										</div>
										<div style={{width:"150px", display:"flex", justifyContent:"space-between", padding:"5px 2px"}}>
											<i style={{color:"#ef5a20", cursor:"pointer"}} className="fa fa-edit" onClick={this.editFilesOfAlbum.bind(this, 'edit-details', album.id, index)}>Edit</i>
										</div>
									</div>
									))
										:	"No photos to show"
									}
									</div>
								</div>
							</Row>
						</Tab>
						<Tab eventKey={2} title="YouTube videos">
							<Row>
              	<div
									className="albums-container"
									style={{
									backgroundColor: "#FCFCFC",
									width: "100%",
									height: "43%",
									padding: "20px",
									display: "flex",
									overflowY: "scroll",
									flexDirection: "column",
									alignItems: "center",
									border:"1px solid #e3e3e3"
									}}
								>
									<div
									className="albums-container-header"
									style={{ textAlign: "center" }}
									>
										<h4
											style={{
											marginTop: "0",
											marginBottom: "20px",
											fontWeight: "bold"
											}}
										>
											YOUTUBE VIDEOS
										</h4>
                					</div>
                				<div
									className="albums-row"
									style={{
										display: "flex",
										flexWrap: "wrap",
										width: "960px"
									}}
								>
								{
									(this.state.video_arr.length>0)
										?
									_.map(this.state.video_arr, (album, index) => (

									<div key={index}
										className="album-1-container"
										style={{
										margin: "0 10px 0 0",
										width: "150px",
										height: "200px",
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor : 'pointer'
										}}
									>
										<div
											className="album-1"
											style={{
												backgroundColor: "#F3F3F3",
												width: "150px",
												height: "150px",
												borderRadius: "7px",
												overflow: "hidden"
											}}
											>
											<ReactPlayer url={album.url} width="140%" height="100%"	/>
										</div>
											<button className="video-play-button" onClick={this.openVideos.bind(this, index)}><i className="fa fa-play"></i> View</button>
										</div>
									))
										:
										"No videos to show"
									}
									</div>
								</div>
							</Row>
						</Tab>
					</Tabs>
						<Col className="form-group mt30" md={12} style={{display:"flex", justifyContent:"center"}}>
							<button type="button" className="btn btn-info" onClick={this.addFilesToAlbum.bind(this, 'add-files')}> Add files to Album </button>
						</Col>
						</div>
					</Row>
						:
						null
				}
			</div>
		);
	}
}