import React from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import swal from "sweetalert2";
import $ from 'jquery';

import EditOrgDetailsModal from './EditOrgDetailsModal';

import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../cookie.js').readCookie;

export default class SettingsOrg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updated_domain: '',
      showDomainChanger: false,
      showDomainChangerLoader: false,
      editOrgDetailsModalVisibility: false
    }
    this.onNgoNameClick = this.onNgoNameClick.bind(this);
    this.changeDomain = this.changeDomain.bind(this);
    this.showEditOrgDetailsModal = this.showEditOrgDetailsModal.bind(this);
    this.hideEditOrgDetailsModal = this.hideEditOrgDetailsModal.bind(this);
  }

  onNgoNameClick() {
    swal({
      html: "<h4>Go to your NGO Profile on LetsEndorse to change this!</h4>",
      confirmButtonText: "Take me there!",
      type: "info"
    }).then(function() {
      window.open(process.env.REACT_APP_LE_URL + '/ngo/' + this.props.ngo.ngo_id);
    }.bind(this)).catch(swal.noop);
  }

  onChangeDomainClick() {
    this.setState({ showDomainChanger: true });
  }

  changeDomain(e) {
    this.setState({ updated_domain: e.target.value });
  }

  closeUpdateDomain() {
    this.setState({
      updated_domain: '',
      showDomainChanger: false
    });
  }

  updateDomain() {
    if(this.state.updated_domain !== '') {
      this.setState({ showDomainChangerLoader: true });
      fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/add_domain', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify({ domain: this.state.updated_domain })
      }).then((response) => response.json())
      .then((data) => {
        if(data.status === 'ok') {
          this.props.updateNgoData();
        } else if(data.status === 'error') {
          swal({
            html: data.message,
            type: "info"
          });
        }
        this.setState({
          updated_domain: '',
          showDomainChanger: false,
          showDomainChangerLoader: false
        });
      });
    }
  }

  componentWillMount() {
    this.props.updateNgoData();
  }

  showEditOrgDetailsModal() {
    this.setState({ editOrgDetailsModalVisibility: true });
  }

  hideEditOrgDetailsModal() {
    this.setState({ editOrgDetailsModalVisibility: false });
  }

  render () {
    return (
      <div className="tab-container">
        <Row>
          <Col md={12}>
            <Row id="personal-details" className="mt20">
              <Row className="flex-center name-img-container">
                <Col md={4} className="mb10">
                  <div className="ngo_image">
                    <img src={this.props.ngo.ngo_logo} alt="" onError={(event)=>event.target.setAttribute("src","/images/logo.png")} />
                  </div>
                </Col>
                <Col md={8} className="mb10">
                  <span className="edit-org-details" onClick={this.showEditOrgDetailsModal}>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </span>
                  <Row className="ngo-details">
                    <Col md={12} className="mb10">
                      <span className="settings-title">Name:</span>
                      <span>
                        {this.props.ngo.ngo_name}
                      </span>
                    </Col>
                    {this.props.ngo.ngo_email && this.props.ngo.ngo_email !== '' ? (
                      <Col md={12} className="mb10">
                        <span className="settings-title">Email:</span>
                        <span>
                          {this.props.ngo.ngo_email}
                        </span>
                      </Col>
                    ) : (null) }
                    {this.props.ngo.plan.name === 'premium' ? (
                      <Col md={12} className="mb10">
                        <span className="settings-title">Verified Domain:</span>
                        <span>
                          {this.props.ngo.domains.map(function(domain, i) {
                            if(i < this.props.ngo.domains.length - 1) return <span key={i}>{domain + ', '}</span>
                            else return <span key={i}>{domain}</span>
                          }.bind(this))}
                          <span className="verified-mark" title="Verified">
                            <i className="fa fa-check-circle" aria-hidden="true"></i>
                          </span>
                          <Button className="btn-success react-bs-table-add-btn" onClick={this.onChangeDomainClick.bind(this)}>Add Domain</Button>
                        </span>
                      </Col>
                    ): (null)}
                    {this.props.ngo.plan.name === 'premium' ? (
                      <Col md={12} className="mb10" hidden={!this.state.showDomainChanger}>
                        <span className="settings-title"></span>
                        <span className="domainChanger">
                          <div className="domainChangerIcon" title="Close Verify Domain" onClick={this.closeUpdateDomain.bind(this)} hidden={this.state.showDomainChangerLoader}>
                            <i className="fa fa-close" aria-hidden="true"></i>
                          </div>
                          <input className="domainChangerInput" type="text" placeholder="domain.com" value={this.state.updated_domain} onChange={this.changeDomain}/>
                          <div className="domainChangerIcon" title="Verify Domain" onClick={this.updateDomain.bind(this)} hidden={this.state.showDomainChangerLoader}>
                            <i className="fa fa-arrow-right" aria-hidden="true"></i>
                          </div>
                          <div className="domainChangerIcon" title="Verifying Domain" hidden={!this.state.showDomainChangerLoader}>
                            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                          </div>
                        </span>
                      </Col>
                    ): (null)}
                  </Row>
                </Col>
              </Row>

              <Row>
                {(() => {
                  if(this.props.ngo.ngo_website_url !== '') {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Website:</span>
                        <a href={this.props.ngo.ngo_website_url} className="settings-input" target="_blank">{this.props.ngo.ngo_website_url}</a>
                      </Col>
                    );
                  } else {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Website:</span>N/A
                      </Col>
                    );
                  }
                })()}
                {(() => {
                  if(this.props.ngo.ngo_blog_url !== '') {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Blogspot:</span>
                        <a className="settings-input" href={this.props.ngo.ngo_blog_url} target="_blank">{this.props.ngo.ngo_blog_url}</a>
                      </Col>
                    );
                  } else {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Blogspot:</span>N/A
                      </Col>
                    );
                  }
                })()}
                {(() => {
                  if(this.props.ngo.ngo_fb_url !== '') {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Facebook:</span>
                        <a href={this.props.ngo.ngo_fb_url} className="settings-input" target="_blank">{this.props.ngo.ngo_fb_url}</a>
                      </Col>
                    );
                  } else {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Facebook:</span>N/A
                      </Col>
                    );
                  }
                })()}
                {(() => {
                  if(this.props.ngo.ngo_twitter_url !== '') {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Twitter:</span>
                        <a className="settings-input" href={this.props.ngo.ngo_twitter_url} target="_blank">{this.props.ngo.ngo_twitter_url}</a>
                      </Col>
                    );
                  } else {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Twitter:</span>N/A
                      </Col>
                    );
                  }
                })()}
                {(() => {
                  if(this.props.ngo.ngo_linkedin_url !== '') {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Linkedin:</span>
                        <a className="settings-input" href={this.props.ngo.ngo_linkedin_url} target="_blank">{this.props.ngo.ngo_linkedin_url}</a>
                      </Col>
                    );
                  } else {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Linkedin:</span>N/A
                      </Col>
                    );
                  }
                })()}
                {(() => {
                  if(this.props.ngo.ngo_youtube_url !== '') {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Youtube:</span>
                        <a className="settings-input" href={this.props.ngo.ngo_youtube_url} target="_blank">{this.props.ngo.ngo_youtube_url}</a>
                      </Col>
                    );
                  } else {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Youtube:</span>N/A
                      </Col>
                    );
                  }
                })()}
                {(() => {
                  if(this.props.ngo.ngo_instagram_url !== '') {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Instagram:</span>
                        <a className="settings-input" href={this.props.ngo.ngo_instagram_url} target="_blank">{this.props.ngo.ngo_instagram_url}</a>
                      </Col>
                    );
                  } else {
                    return (
                      <Col md={6} className="mb10 ngo-urls">
                        <span className="settings-title">Instagram:</span>N/A
                      </Col>
                    );
                  }
                })()}

                {/*<Col md={12} className="mb10">
                  <note>To update these links go to your NGO's page on LetsEndorse <a href={process.env.REACT_APP_LE_URL + '/ngo/' + this.props.ngo.ngo_id} target="_blank">here</a></note>
                </Col>*/}

                {this.props.ngo.ngo_80g_certificate_url !== '' || this.props.ngo.ngo_pan_no !== '' ? (
                  <Row className="documents-and-certificates">
                    <Col md={12} className="mt20">
                      <h4 className="settings-title mt10 mb10 title">Documents & Certificates</h4>
                    </Col>
                    {(() => {
                      if(this.props.ngo.ngo_80g_certificate_url !== '') {
                        return (
                          <Col md={8} className="mb10">
                            <span className="settings-title">80G:</span>
                            <a className="settings-input" href={this.props.ngo.ngo_80g_certificate_url} target="_blank">{this.props.ngo.ngo_80g_certificate_url}</a>
                          </Col>
                        );
                      }
                    })()}
                    {(() => {
                      if(this.props.ngo.ngo_pan_no !== '') {
                        return (
                          <Col md={4} className="mb10">
                            <span className="settings-title">PAN:</span>{this.props.ngo.ngo_pan_no}
                          </Col>
                        );
                      }
                    })()}
                    <Col md={12} className="mb10">
                      <note>To update these please get in touch with us at <a href="mailto:support@letsendorse.com" target="_blank">support@letsendorse.com</a></note>
                    </Col>
                  </Row>
                ) : (null)}
              </Row>
          </Row>
        </Col>
      </Row>
      {this.state.editOrgDetailsModalVisibility ? (
        <EditOrgDetailsModal ngo={this.props.ngo}
          ngo_id={this.props.ngo.ngo_id}
          updateNgoData={this.props.updateNgoData}
          hideEditOrgDetailsModal={this.hideEditOrgDetailsModal}
          showEditOrgDetailsModal={this.state.editOrgDetailsModalVisibility} />
      ) : (null)}
    </div>
    );
  }
}