import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const readCookie = require('../cookie.js').readCookie;

export default class DashboardTaskss extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	tasks_assigned_to_me: null,
    	tasks_assigned_by_me: null
    }
  }

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/tasks/'+ this.props.ngo.ngo_id + '/dashboard', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
    	this.setState({ tasks_assigned_to_me: data.tasks.tasks_assigned_to_me, tasks_assigned_by_me: data.tasks.tasks_assigned_by_me });
    }.bind(this));
	}

	getEndDateFormat(date) {
		date = date.split(" ")
		return(date[0] + " " +date[1] +" "+ date[2] + " " + date[3])
	}

  render() {
  	if(this.state.tasks_assigned_to_me !== null && this.state.tasks_assigned_by_me !== null) {
	    return (
	      <div className="dashboard-tasks-container">
	      	<h3>Tasks</h3>
	        <Tabs>
				    <TabList>
				      <Tab>Tasks For Me</Tab>
				      <Tab>Tasks Assigned By Me</Tab>
				    </TabList>

				    <TabPanel>
				    	<div className="taskslist">
				      	{this.state.tasks_assigned_to_me.map(function(task, index) {
				      		return (
				      			<Row className="task" key={index}>
					      			<div className="task-header-container">
					      				<Col md={4}>
													<span className="task-title">
														<Link to={"tasks/details/" + task.id}>{task.title}</Link>
													</span>
												</Col>
												<Col md={3} className="pull-right">
													<span className="task-date">
														<span className="assigned-title">Due Date: </span>
														{this.getEndDateFormat(task.due_on)}
													</span>
												</Col>
												<Col md={12} className="assigned-desc">
													<span className="assigned-title">Assigned For: </span>
													{(() => {
	                          if('groups' in task.assigned_for) {
	                            return task.assigned_for.groups.map((group, i) => {
	                            	return (
	                            		<span key={i} className="group-tag-name" data-group-id={group.id}>{group.name}</span>
	                            	);
	                            })
	                          }
	                        })()}
	                        {(() => {
	                          if('users' in task.assigned_for) {
	                            return task.assigned_for.users.map((user, i) => {
	                            	return (
	                            		<span key={i} className="user-tag-name" data-user-id={user.id}>{user.name}</span>
	                            	);
	                            })
	                          }
	                        })()}
												</Col>
											</div>
					      		</Row>
					      	)
				      	}.bind(this))}
				      	{this.state.tasks_assigned_to_me.length === 0 ? (
				      		<Row className="task no-task">
				      			<span>You haven't been assigned any tasks yet.</span>
				      		</Row>
				      	) : (null)}
				      </div>
				    </TabPanel>

				    <TabPanel>
				    	<div className="taskslist">
				    		{this.state.tasks_assigned_by_me.map(function(task, index) {
				      		return (
				      			<Row className="task" key={index}>
				      				<div className="task-header-container">
					      				<Col md={4}>
													<span className="task-title">
														<Link to={"tasks/details/" + task.id}>{task.title}</Link>
													</span>
												</Col>
												<Col md={3} className="pull-right">
													<span className="task-date">
														<span className="assigned-title">Due Date: </span>
														{this.getEndDateFormat(task.due_on)}
													</span>
												</Col>
												<Col md={12} className="assigned-desc">
													<span className="assigned-title">Assigned To: </span>
													{(() => {
                            if('groups' in task.assigned_to) {
                              return task.assigned_to.groups.map((group, i) => {
                              	return (
                              		<span key={i} className="group-tag-name" data-group-id={group.id}>{group.name}</span>
                              	);
                        			})
                          	}
                          })()}
                          {(() => {
                            if('users' in task.assigned_to) {
                              return task.assigned_to.users.map((user, i) => {
                              	return (
                              		<span key={i} className="user-tag-name" data-user-id={user.id}>{user.name}</span>
                              	);
                              })
                            }
                          })()}
                          {(() => {
                            if('id' in task.assigned_to) {
                              if(!task.assigned_to.id) {
                              	return (
                              		<span className="user-tag-name" data-user-id={task.assigned_to.id}>{task.assigned_to.name}</span>
                              	);
                              }
                            }
                          })()}
												</Col>
											</div>
				      			</Row>
				      		)
				      	}.bind(this))}
				      	{this.state.tasks_assigned_by_me.length === 0 ? (
				      		<Row className="task no-task">
				      			<span>You haven't assigned any tasks yet.</span>
				      		</Row>
				      	) : (null)}
				    	</div>
				    </TabPanel>
				  </Tabs>
	      </div>
	    );
	  } else return null;
  }
}
