import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export default class TopMenu extends Component {
  render() {
    return (
      <div className="top-menu">
        <Row className="full-width">
            <Col md={12} className="org-additional-buttons">
                <div className="current_user">
                  <Link to={'/dm/settings'} className="loggedin_user">
                    {(() => {
                      if(this.props.userData['profile_picture'] === '') {
                        return (
                          <img alt={this.props.userData['first_name']} src="/images/user.png" className="img-circle" />
                        );
                      } else {
                        return (
                          <img alt={this.props.userData['first_name']} src={this.props.userData['profile_picture']} onError={(event)=>event.target.setAttribute("src","/images/user.png")} />
                        );
                      }
                    })()}
                    {(() => {
                      if(this.props.userData['first_name'] !== '' || this.props.userData['last_name'] !== '') {
                        return (
                          <span>{this.props.userData['first_name'] + ' ' + this.props.userData['last_name']}</span>
                        );
                      } else {
                        return (
                          <span>{this.props.userData['email']}</span>
                        );
                      }
                    })()}
                  </Link>
                  <Link to={"/"} 
                    style={{ 
                      color: '#fff',
                      width: '70px',
                      height: '35px',
                      padding: '7px',
                      fontWeight: 600,
                      textAlign: 'center',
                      borderRadius: '6px',
                      margin: '0px 15px',
                      background: '#ef5a20',
                      boxShadow: '1px 1px 1px #555' 
                    }}>
                    Home
                  </Link>
                  <div className="logout-user-button-item" onClick={this.props.logoutUser}>
                    <p><i className="fa fa-power-off"></i> Logout</p>
                  </div>
                </div>
            </Col>
        </Row>
      </div>
    );
  }
}
