import React, { Component } from "react";
import { Modal, Row, Col } from "react-bootstrap";

const readCookie = require('../cookie.js').readCookie;

export default class AddTagModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      showTagNameError: false
    }
  }

  handleTagNameChange = e =>{
    this.setState({name: e.target.value, showTagNameError: false});
  }

  handleSubmit = () => {
    let name = this.state.name;
    if(name === ''){
      this.setState({showTagNameError: true})
    }
    fetch(process.env.REACT_APP_API_URL + '/create/campaign_tag/' + this.props.ngo.ngo_id, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
      body: JSON.stringify({name})
    }).then(res => res.json())
    .then(data => {
      if(data.status === 'ok'){
        this.cancelSubmit()  
      } else {
        console.log('error');
      }
    })
  }

  cancelSubmit = () => {
    this.setState({name: '', showTagNameError: false});
    this.props.onCloseAddTag();
  }

  render() {
    return (
      <div>
        <Modal show={this.props.showAddTagModal} onHide={this.props.onCloseAddTag} bsSize="large" >
          <Modal.Header>
            <Modal.Title className="modal-header-custom">Add a new Tag</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{height: '170px'}}>
            <Row>
              <Col className="form-group" md={12} style={{display: 'flex'}}>
                <label className="control-label imp-label"> Give a name for your Tag: </label>
                <input type="text" className="form-control mt10 ml15" style={{width: '50%'}} id="tag_name" name="tag_name" value={this.state.name}
                  placeholder="Name of the Tag" onChange={this.handleTagNameChange} required />
                <span hidden={!this.state.showTagNameError}><span className="required-span">Please enter Tag Name.</span></span>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="add-tag-btn-container" style={{marginTop: '20px'}}>
                <button className="btn mr5" disabled={this.state.name === '' ? true : false} onClick={this.handleSubmit}>Submit</button>
                <button className="btn ml5" onClick={this.cancelSubmit}>Cancel</button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
