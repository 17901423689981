import React, {Component} from 'react';

export default class SingleDonorQuicklinks extends Component {
  onAddCampaignOpen() {
    window.localStorage.setItem('previousLocation', window.location.pathname);
    window.location.pathname = '/dm/campaigns/new';
  }

  render() {
    return (
      <div className="donor-quicklinks-container">
      	<div onClick={this.onAddCampaignOpen}><i className='fa fa-flag-o'></i>Create Campaign</div>
      	<div onClick={this.props.onFollowOpen}><i className='fa fa-calendar-o'></i>Create Task</div>
      </div>
    );
  }
}