import { CIRCLE, RECTANGLE, SQUARE } from "../constants/constants";
import { ToastContainer, toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import React, { Component } from 'react';
import Alert from 'react-s-alert';
import swal from 'sweetalert2';
import moment from 'moment';
import _ from 'lodash';

import SeeMore from "./seeMore";
import TextBox from "./TextBox";
import UserProfile from "./userProfile";
import AttachmentBox from "./AttachmentBox";
import ImageContainer from "./ImageContainer";

const readCookie = require('../../cookie.js').readCookie;

export default class CommentBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contenteditable: "false",
      editableClass: null,
      editMode: false,
      attachmentUnmount: false,
      text: ""
    }
  }

  editUpdate(e) {
    this.setState({
      contenteditable: "true",
      editableClass: {
        backgroundColor: 'white',
        border: "1px solid #eee",
        padding: "5px 10px"
      },
      editMode: true,
    })

  }

  deleteUpdate(e, data) {
    console.log(e, data)
    swal({
      title: 'Do you want to delete this update?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      let update_id = e.id;
      let index = data;
      let info = {
        isActive: "false"
      }
      fetch(process.env.REACT_APP_MIS_API_URL + "/post/" + update_id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Auth': JSON.parse(readCookie('mis_data')).access_token
        },
        body: JSON.stringify(info)
      })
        .then(res => {
          console.log("res", res);
          this.props.deleteUpdate(index);
          toast.success("You have successfully deleted your comment")
        }).catch(err => {
          console.log("err", err);
          toast.error("You have failed to delete your comment")
        })
    });
  }

  cancelUpdate() {
    this.setState({
      contenteditable: "false",
      editableClass: null,
      editMode: false,
      attachmentUnmount: false,
    });
    this.refs["description"].innerText = this.props.text
  }

  saveUpdate(e, data) {
    if(this.refs["description"].innerText !== ""){
      let update_id = e.id;
      let index = data;
      let newUpdate = {
        "type": "project",
        "text": this.refs["description"].innerText,
      };
    let uri = process.env.REACT_APP_MIS_API_URL + "/post/" + update_id;
    fetch(uri, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Auth': JSON.parse(readCookie('mis_data')).access_token
      },
      body: JSON.stringify(newUpdate)
    })
      .then(res => (res.json()))
      .then(res => {
        console.log("json res", res);

        this.setState({
          contenteditable: "false",
          editableClass: null,
          editMode: false,
          attachmentUnmount: false,
        });
        this.props.editUpdate(index, newUpdate.text);
        toast.success("You have successfully updated your comment")
      })
      .catch(err => {
        console.log("err", err);
        toast.error("You have failed to updated your comment")
      })
    } else {
      toast.error("Please enter text");
    }

  }

  showOtherImages(e, data) {
    console.log("showOtherImages", e, data)
    this.props.showMoreImages(e, data);
  }

  deleteAttachment(e, data) {
    this.setState({
      attachmentUnmount: true
    })
  }

  render() {
    return (
      <div className="row project-update-container">
        <Alert stack={{ limit: 3 }} />
        <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
        <div>
          <div className="col-sm-1">
            <UserProfile
              scaleComponent={2}
              backgroundImage={this.props.update.profilePicture ? this.props.update.profilePicture : this.props.profilePicture}
              boxType={CIRCLE}
            />
          </div>
          <Col md={10}>

            <div className="col-sm-12">
              <h5 style={{ fontWeight: '500', color: 'gray', marginTop: "0" }}>
                <b>{this.props.postedBy}</b>{" on "}{moment(this.props.createdAt).format('MMM Do YY, h:mm a')}
              </h5>
              <div contentEditable={this.state.contenteditable}
                ref="description"
                className="editable-div"
                style={this.state.editableClass}
              >
                {this.props.text}
              </div>
            </div>

            <div className="col-sm-12">
              {
                (this.props.images.length !== 0
                  ?
                  <button className="btn btn-info"
                    onClick={this.showOtherImages.bind(this, this.props.images)}
                  >view images</button>
                  :
                  null
                )
              }
            </div>

          </Col>
          <div className="col-sm-1">
            <div className="project-options">
              <div className="col-sm-6" onClick={this.editUpdate.bind(this, this.props.update)}>
                <i className="fa fa-pencil-square-o" />
              </div>
              <div className="col-sm-6" onClick={this.deleteUpdate.bind(this, this.props.update, this.props.index)}>
                <i className="fa fa-close" />
              </div>
            </div>
            {
              (this.state.editMode)
                ?
                <div>
                  <button className="btn btn-default btn-info pull-right" style={{ marginBottom: '5px' }} onClick={this.saveUpdate.bind(this, this.props.update, this.props.index)}>Save</button>
                  <button className="btn btn-default btn-danger pull-right" onClick={this.cancelUpdate.bind(this)}>Cancel</button>
                </div>
                :
                null

            }

          </div>
        </div>
      </div>
    )
  }
}