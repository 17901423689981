export const RECTANGLE = "RECTANGLE";
export const SQUARE = "SQUARE";
export const CIRCLE = "CIRCLE";

export const FETCH_ALL_UPDATES = "FETCH_ALL_UPDATES";

//for socket
export const PROD_SOCKET_URL = process.env.REACT_APP_MIS_API_URL;
export const ONE_TO_ONE = "ONE_TO_ONE";
export const LOCAL_SOCKET_URL = process.env.REACT_APP_MIS_API_URL;
export const USER_CONNECTED = "USER_CONNECTED";
export const MESSAGE_SENT = "MESSAGE_SENT";
export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";
export const END_CHAT = "END_CHAT";

