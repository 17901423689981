import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { LocaleProvider, Select } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import Icon from 'react-icons-kit';
import $ from 'jquery';
import swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { ic_add_box } from 'react-icons-kit/md/ic_add_box';
import { ic_add_location } from 'react-icons-kit/md/ic_add_location';
import { ic_delete } from 'react-icons-kit/md/ic_delete';

import 'antd/dist/antd.css';

import BudgetEditorModal from './BudgetEditorModal';

const readCookie = require('../cookie.js').readCookie;

const Option = Select.Option;

export default class IMAddProject extends Component {
	constructor(props) {
		super(props);
		this.state = {
			project: {
				need: '',
				title: '',
				location: [],
				partners: [],
				unitCost: '',
				unitName: '',
				milestones: [],
				description: '',
				selectedGoal: '',
				coverImage: null,
				proposedBudget: 0,
				amountBreakup: [],
				innovationUrl: '',
				coverVideoUrl: '',
				expectedImpact: '',
				selectedAgenda: '',
				eeOpportunities: [],
				unitEconomics: null,
				proposedDuration: '',
				impactMonitoring: '',
				pastProjectHistory: '',
				sustainabilityPlan: '',
				deploymentLocations: [],
				selectedBeneficiary: '',
				selectedBeneficiaryType: '',
				selectedLocationType: '',
				selectedProjectCategory: '',
				selectedKpi: [],
				selectedThematicCategory: '',
				proposedDurationType: 'month',
				assignedFor: [],
				invitations: []
			},
			custom: false,
			goals: [],
			address: [],
			agendas: [],
			costHeads: {},
			allAgendas: [],
			beneficiaries: [],
			selectedBeneficiaries: [],
			locationTypes: [],
			projectCategories: [],
			kpis: [],
			Allkpis: [],
			thematicCategories: [],
			coverImagePreviewUrl: '',
			budgetEditorModalVisibility: false,
			budgetEditorModal: {
				selectedColumn: '',
				selectedRowIndex: -1,
				selectedColumnName: '',
				selectedColumnValue: ''
			},
			customProject: '',
			customKpi: "",
			customKpiInput: [{
				name: "",
				unit: "",
				dataType: ""
			}],
			beneficiariesData: [
				{
					type: "",
					potential: 0,
					name: '',
					id: ""
				}
			],
			kpiData: [
				{
					id: "",
					unit: "",
					dataType: "",
					type: "",
					name: "",
					targetValue: "",
					baselineValue: "",
					custom: false
				}
			],
			partnerCorporates: [],
			selectedPartner: [],
			projectVisibility: "",
			sample_size: [
				{
					"unit": "Number",
					"symbol": "None",
					"dataType": "Number"
				},
				{
					"unit": "kilograms",
					"symbol": "kg",
					"dataType": "float"
				},
				{
					"unit": "litres",
					"symbol": "L",
					"dataType": "float"
				},
				{
					"unit": "grams",
					"symbol": "gm",
					"dataType": "float"
				},
				{
					"unit": "percentage",
					"symbol": "%",
					"dataType": "float"
				},
				{
					"unit": "feet",
					"symbol": "ft",
					"dataType": "float"
				},
				{
					"unit": "inches",
					"symbol": "in",
					"dataType": "float"
				},
				{
					"unit": "centimeter",
					"symbol": "cm",
					"dataType": "float"
				},
				{
					"unit": "meter",
					"symbol": "m",
					"dataType": "float"
				},
				{
					"unit": "kilometer",
					"symbol": "km",
					"dataType": "float"
				}
			],
			showPublic: false,
			indicators: [],
			projectCategory: {},
			partnerCorporatesId: ""
		}
		this.getCostHeadName = this.getCostHeadName.bind(this);
		this.uploadCoverImage = this.uploadCoverImage.bind(this);
	}

	componentDidMount() {
		if (JSON.parse(readCookie('mis_data')).data.organisationData.publicProjectsAllowed && JSON.parse(readCookie('mis_data')).data.organisationData.publicProjectsAllowed === true) {
			this.setState({ showPublic: true });
		}
		let promises = [];
		let promise1 = fetch(process.env.REACT_APP_MIS_API_URL + '/getOtAttributes', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				var goals = [], allAgendas = [], beneficiaries = [], locationTypes = [];
				for (var i = 0; i < data.otAttributes.length; i++) {
					if (data.otAttributes[i].type === 'goal') goals.push(data.otAttributes[i]);
					else if (data.otAttributes[i].type === 'agenda') allAgendas.push(data.otAttributes[i]);
					else if (data.otAttributes[i].type === 'beneficiary') beneficiaries.push(data.otAttributes[i]);
					else if (data.otAttributes[i].type === 'setting') locationTypes.push(data.otAttributes[i]);
					this.setState({ goals, allAgendas, beneficiaries, locationTypes });
				}
			}.bind(this));
		promises.push(promise1);

		let promise2 = fetch(process.env.REACT_APP_MIS_API_URL + '/cost-attributes', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				let costHeads = {};
				for (var i = 0; i < data.data.length; i++) {
					for (var j = 0; j < data.data[i].costHeads.length; j++) {
						costHeads[data.data[i].costHeads[j].key] = data.data[i].costHeads[j].name;
					}
				}
				this.setState({ costHeads });
			}.bind(this));
		promises.push(promise2);

		let promise3 = fetch(process.env.REACT_APP_MIS_API_URL + '/project-attribute?type=thematic', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				this.setState({ thematicCategories: data.data });
			}.bind(this));
		promises.push(promise3);

		let promise4 = fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/' + JSON.parse(readCookie('mis_data')).data._organisationId + '/project-categories', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				let Allkpis = [];
				for (let i = 0; i < data.data.length; i++) {
					for (let j = 0; j < data.data[i].KPIs.length; j++) {
						Allkpis.push(data.data[i].KPIs[j]);
					}
				}
				this.setState({ projectCategories: data.data, Allkpis });
			}.bind(this));
		promises.push(promise4);

		Promise.all(promises).then(d => {
			if (this.state.project.deploymentLocations.length === 0) this.addDeploymentLocation(this);
			if (this.state.project.milestones.length === 0) this.addMilestone(this);
			if (this.state.project.amountBreakup.length === 0) this.addAmountBreakupRow(this);
			if (this.state.project.eeOpportunities.length === 0) this.addEEOpportunity(this);
			if (this.state.project.partners.length === 0) this.addPartner(this);
		}).catch(e => {
			console.log('Promise failed')
		});
		fetch(process.env.REACT_APP_MIS_API_URL + "/ngo/mis/corporates-for-project-invite", {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(data => {
				this.setState({ partnerCorporates: data.data })
			})
	}

	updateChange(name, e, index) {
		var project = this.state.project;
		if (name === 'coverImage') {
			var file = e.target.files[0];
			project['coverImage'] = file;
			project['coverVideoUrl'] = '';
			var that = this;
			var reader = new FileReader();
			reader.onloadend = function () {
				that.setState({ coverImagePreviewUrl: reader.result });
			}
			reader.readAsDataURL(file);
			this.uploadCoverImage(file);
		} else if (name === 'coverVideoUrl') {
			project['coverImage'] = null;
			project['coverVideoUrl'] = e.target.value;
			this.setState({ coverImagePreviewUrl: '' });
		} else if (name === 'customProject') {
			this.setState({ customProject: e.target.value });
		} else if (name === 'selectedGoal') {
			project['selectedGoal'] = e.target.value;
			var agendas = [];
			for (var i = 0; i < this.state.allAgendas.length; i++) {
				if (this.state.allAgendas[i]._goalId === e.target.value) {
					agendas.push(this.state.allAgendas[i]);
				}
			}
			this.setState({ agendas });
		} else if (name === "selectedProjectCategory") {
			project[name] = e.target.value;
			let kpis = []
			for (let i = 0; i < this.state.Allkpis.length; i++) {
				if (project[name] === this.state.Allkpis[i].projectCategoryId) {
					kpis.push(this.state.Allkpis[i])
				}
			}
			this.setState({ project, kpis });
		} else if (name === "kpiType") {
			let kpiData = this.state.kpiData;
			kpiData[e]["type"] = index.target.value
			this.setState({ kpiData });
		} else if (name === "kpiName") {
			let kpiData = this.state.kpiData;
			let Allkpis = this.state.Allkpis;
			for (let j = 0; j < Allkpis.length; j++) {
				if (index.target.value === Allkpis[j].id) {
					kpiData[e]["unit"] = Allkpis[j].unit;
				}
			}
			if (parseInt(index.target.value) !== 0) {
				kpiData[e]["id"] = index.target.value;
				kpiData[e]["name"] = $(index.target).find('option:selected').data('name');
			} else {
				kpiData[e]["id"] = 0;
				kpiData[e]["name"] = "";
				kpiData[e]["custom"] = true;
			}
			this.setState({ kpiData });
		} else if (name === "kpiTargetValue") {
			let kpiData = this.state.kpiData;
			kpiData[e]["targetValue"] = index.target.value
			this.setState({ kpiData });
		} else if (name === "kpiBaselineValue") {
			let kpiData = this.state.kpiData;
			kpiData[e]["baselineValue"] = index.target.value
			this.setState({ kpiData });
		} else if (name === "selectedBeneficiaryType") {
			let beneficiariesData = this.state.beneficiariesData;
			beneficiariesData[e]['type'] = index.target.value;
			this.state.beneficiaries.push({
				name: "Custom",
				_id: "0"
			});
			this.setState({ beneficiariesData });
		} else if (name === "selectedBeneficiary") {
			let beneficiariesData = this.state.beneficiariesData;
			beneficiariesData[e]['id'] = index.target.value;
			beneficiariesData[e]['name'] = $(index.target).find('option:selected').data('name');
			this.setState({ beneficiariesData });
		} else if (name === "selectedBeneficiaryPotential") {
			let beneficiariesData = this.state.beneficiariesData;
			beneficiariesData[e]['potential'] = index.target.value;
			this.setState({ beneficiariesData });
		} else if (name === "selectedBeneficiaryName") {
			let beneficiariesData = this.state.beneficiariesData;
			beneficiariesData[e]['name'] = index.target.value;
			this.setState({ beneficiariesData });
		} else if (name === "partnerCorporate") {
			project['assignedFor'] = e.target.value;
			this.setState({ project });
		} else {
			project[name] = e.target.value;
			this.setState({ project });
		}
		this.setState({ project })
	}

	uploadCoverImage(file) {
		var data = new FormData();
		data.append('image', file);

		fetch(process.env.REACT_APP_MIS_API_URL + '/uploadImage', {
			method: 'POST',
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			},
			body: data
		}).then((response) => response.json())
			.then(function (data) {
				if (data['status'] === 'ok') {
					var project = this.state.project;
					project['coverImage'] = null;
					this.setState({ project, coverImagePreviewUrl: data.url });
				}
			}.bind(this));
	}

	uploadImageClick() {
		$('#uploadCoverImage').click();
	}

	getCostHeadName(id) {
		var costHeadName = '';
		if (this.state.costHeads.hasOwnProperty(id)) costHeadName = this.state.costHeads[id];
		return costHeadName;
	}

	onDeploymentLocationChange(index, deploymentLocation) {
		this.changeLocation(index, deploymentLocation);

		geocodeByAddress(deploymentLocation)
		.then(results => getLatLng(results[0]))
		.then(function (latLng) {
			this.changeLatLng(index, latLng);
		}.bind(this)).catch(error => console.error('Error', error));
	}

	changeLocation(index, value) {
		let project = this.state.project;
		project['deploymentLocations'][index].address = value;
		this.setState({ project });
	}

	changeLatLng(index, value) {
		let project = this.state.project;
		project['deploymentLocations'][index].latitude = value.lat;
		project['deploymentLocations'][index].longitude = value.lng;
		this.setState({ project });
	}

	addDeploymentLocation() {
		let project = this.state.project;
		let preferedGeographiesLocationJSON = {};
		preferedGeographiesLocationJSON['address'] = '';
		preferedGeographiesLocationJSON['locationError'] = '';
		preferedGeographiesLocationJSON['latitude'] = '';
		preferedGeographiesLocationJSON['longitude'] = '';
		project['deploymentLocations'].push(preferedGeographiesLocationJSON);
		this.setState({ project });
	}

	removeDeploymentLocation(index) {
		let project = this.state.project;
		project['deploymentLocations'].splice(index, 1);
		this.setState({ project });
	}

	addMilestone() {
		let project = this.state.project;
		project['milestones'].push('');
		this.setState({ project });
	}

	removeMilestone(index) {
		let project = this.state.project;
		project['milestones'].splice(index, 1);
		this.setState({ project });
	}

	updateMilestoneChange(index, e) {
		let project = this.state.project;
		project['milestones'][index] = e.target.value;
		this.setState({ project });
	}

	updateUnitEconomicsChange(value) {
		let project = this.state.project;
		project['unitEconomics'] = value;
		this.setState({ project });
	}

	updateAmountBreakupChange(name, index, e) {
		let project = this.state.project;
		var preTaxAmount, postTaxAmount, proposedBudget, i;
		if (name === 'unitCost' || name === 'totalUnits' || name === 'preTaxAmount' || name === 'taxRate') {
			project['amountBreakup'][index][name] = parseFloat(e.target.value);
			if (project['amountBreakup'][index]['unitEconomics']) {
				preTaxAmount = parseFloat(project['amountBreakup'][index]['unitCost']) * parseFloat(project['amountBreakup'][index]['totalUnits']);
				postTaxAmount = preTaxAmount + (preTaxAmount * parseFloat(project['amountBreakup'][index]['taxRate']) / 100);
				project['amountBreakup'][index]['postTaxAmount'] = parseFloat(postTaxAmount);
			} else {
				postTaxAmount = parseFloat(project['amountBreakup'][index]['preTaxAmount']) + (parseFloat(project['amountBreakup'][index]['preTaxAmount']) * parseFloat(project['amountBreakup'][index]['taxRate']) / 100);
				project['amountBreakup'][index]['postTaxAmount'] = parseFloat(postTaxAmount);
			}
			proposedBudget = 0;
			for (i = 0; i < project['amountBreakup'].length; i++) {
				proposedBudget += parseFloat(project['amountBreakup'][i]['postTaxAmount']);
			}
			project['proposedBudget'] = proposedBudget;
		} else if (name === 'unitEconomics') {
			project['amountBreakup'][index][name] = e.target.checked;
			if (project['amountBreakup'][index]['unitEconomics']) {
				preTaxAmount = parseFloat(project['amountBreakup'][index]['unitCost']) * parseFloat(project['amountBreakup'][index]['totalUnits']);
				postTaxAmount = preTaxAmount + (preTaxAmount * parseFloat(project['amountBreakup'][index]['taxRate']) / 100);
				project['amountBreakup'][index]['postTaxAmount'] = parseFloat(postTaxAmount);
			} else {
				postTaxAmount = parseFloat(project['amountBreakup'][index]['preTaxAmount']) + (parseFloat(project['amountBreakup'][index]['preTaxAmount']) * parseFloat(project['amountBreakup'][index]['taxRate']) / 100);
				project['amountBreakup'][index]['postTaxAmount'] = parseFloat(postTaxAmount);
			}
			proposedBudget = 0;
			for (i = 0; i < project['amountBreakup'].length; i++) {
				proposedBudget += parseFloat(project['amountBreakup'][i]['postTaxAmount']);
			}
			project['proposedBudget'] = proposedBudget;
		} else project['amountBreakup'][index][name] = e.target.value;
		this.setState({ project });
	}

	addAmountBreakupRow() {
		let project = this.state.project;
		let amountBreakupRow = {};
		amountBreakupRow['costHead'] = '';
		amountBreakupRow['expenseItem'] = '';
		amountBreakupRow['unitEconomics'] = true;
		amountBreakupRow['unitCost'] = 0;
		amountBreakupRow['totalUnits'] = 0;
		amountBreakupRow['preTaxAmount'] = 0;
		amountBreakupRow['taxRate'] = 0;
		amountBreakupRow['postTaxAmount'] = 0;
		amountBreakupRow['comments'] = '';
		project['amountBreakup'].push(amountBreakupRow);
		this.setState({ project });
	}

	openBudgetEditorModal(selectedColumn, selectedColumnName, selectedColumnValue, selectedRowIndex) {
		let budgetEditorModal = {}
		budgetEditorModal['selectedColumn'] = selectedColumn;
		budgetEditorModal['selectedColumnValue'] = selectedColumnValue;
		budgetEditorModal['selectedColumnName'] = selectedColumnName;
		budgetEditorModal['selectedRowIndex'] = selectedRowIndex;
		this.setState({ budgetEditorModal, budgetEditorModalVisibility: true });
	}

	hideBudgetEditorModal() {
		let budgetEditorModal = {
			selectedColumn: '',
			selectedColumnValue: '',
			selectedColumnName: '',
			selectedRowIndex: -1
		}
		this.setState({ budgetEditorModal, budgetEditorModalVisibility: false });
	}

	saveBudgetEditorValue(selectedColumn, selectedColumnValue, selectedRowIndex) {
		let project = this.state.project;
		project['amountBreakup'][selectedRowIndex][selectedColumn] = selectedColumnValue;
		this.hideBudgetEditorModal(this);
		this.setState({ project });
	}

	removeAmountBreakupRow(index) {
		let project = this.state.project;
		project['amountBreakup'].splice(index, 1);
		this.setState({ project });
	}

	updateEEOpportunityChange(index, e) {
		let project = this.state.project;
		project['eeOpportunities'][index] = e.target.value;
		this.setState({ project });
	}

	addEEOpportunity() {
		let project = this.state.project;
		project['eeOpportunities'].push('');
		this.setState({ project });
	}

	removeEEOpportunity(index) {
		let project = this.state.project;
		project['eeOpportunities'].splice(index, 1);
		this.setState({ project });
	}

	updatePartnerChange(index, e) {
		let project = this.state.project;
		project['partners'][index] = e.target.value;
		this.setState({ project });
	}

	addPartner() {
		let project = this.state.project;
		project['partners'].push('');
		this.setState({ project });
	}

	removePartner(index) {
		let project = this.state.project;
		project['partners'].splice(index, 1);
		this.setState({ project });
	}

	cancelEditProject() {
		window.location.pathname = '/im/projects';
	}

	saveProject(type) {
		let project = JSON.parse(JSON.stringify(this.state.project));
		project['ngoId'] = JSON.parse(readCookie('mis_data')).data._organisationId;
		project['goalId'] = project['selectedGoal'];
		project['agendaId'] = project['selectedAgenda'];
		project['thematicCategoryId'] = project['selectedThematicCategory'];
		project['projectCategoryId'] = project['selectedProjectCategory'];
		if (project['selectedProjectCategory'] === '0') project['projectCategoryName'] = this.state.customProject;
		project['expectedDuration'] = project['proposedDuration'];
		project['proposedBudget'] = this.state.project.proposedBudget;
		project['amountBreakup'] = this.state.project.amountBreakup;
		project['milestones'] = this.state.project.milestones;
		project['expectedDurationObject'] = {};
		project['expectedDurationObject']['duration'] = project['proposedDuration'];
		project['expectedDurationObject']['type'] = project['proposedDurationType'];
		project['location'] = project['deploymentLocations'];
		project['locationTypeId'] = project['selectedLocationType'];
		project['locationTypeId'] = project['selectedLocationType'];
		project['targetBeneficiaries'] = this.state.beneficiariesData;
		project['projectVisibility'] = this.state.projectVisibility;
		project['kpis'] = this.state.kpiData;
		if (project['coverImage'] === null && this.state.coverImagePreviewUrl !== '') {
			project['coverImage'] = this.state.coverImagePreviewUrl;
		} else {
			project['coverImage'] = '';
		}
		if (this.state.projectVisibility === "public") {
			delete project['assignedFor']
		}
		delete project['selectedGoal'];
		delete project['selectedAgenda'];
		delete project['selectedThematicCategory'];
		delete project['selectedProjectCategory'];
		delete project['selectedBeneficiary'];
		delete project['proposedDuration'];
		delete project['proposedDurationType'];
		delete project['deploymentLocations'];
		delete project['selectedLocationType'];
		if (type === 'save') {
			if (project.title !== '') {
				project['status'] = 'Draft';
				fetch(process.env.REACT_APP_MIS_API_URL + '/create-project', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					},
					body: JSON.stringify(project)
				}).then((response) => response.json())
					.then((data) => {
						if (data['status'] === 'ok') {
							this.notifySuccess('This Project has been saved successfully.');
							window.location.pathname = '/im/projects/' + data.projectId + '/edit';
						}
					});
			} else {
				swal({
					title: 'Project creation failed!',
					text: 'Please enter a valid Project Title.',
					type: 'error'
				});
			}
		} else if (type === 'submit') {
			let flag = false;
			let beneficiaryFlag = false;
			let kpiData = this.state.kpiData;
			let beneficiariesData = this.state.beneficiariesData;
			kpiData.map((kpi,i)=>{
				if((kpi.type === "") || (kpi.name === "" && kpi.custom) || (kpi.unit === "")){
					flag = true;
					return;
				}
			});
			if(flag === true){
				this.notifyError("Mandatory Values are Missing.");
				return;
			}
			beneficiariesData.map((beneficiary,i)=>{
				if((beneficiary.type === "") || (beneficiary.name === "")){
					beneficiaryFlag = true;
					return;
				}
			});
			if(beneficiaryFlag === true){
				this.notifyError("Mandatory Values are Missing.");
				return;
			}
			if (project.title !== '' && project.description !== '' && project.need !== ''
				&& project.expectedImpact !== '' && project.goalId !== '' && project.thematicCategoryId !== ''
				&& project.projectCategoryId !== '' && project.expectedDuration !== ''
				&& project.expectedDurationObject.type !== '' && project.location.length !== 0 && project.location[0].address !== ''
				&& project.locationTypeId !== '' && project.milestones !== undefined && project.milestones[0] !== ''
				&& ((project.unitEconomics === true && project.unitCost !== '' && project.unitName !== '') || project.unitEconomics === false)
				&& project.amountBreakup.length !== 0 && project.amountBreakup[0].costHead !== '' && project.amountBreakup[0].expenseItem !== ''
				&& project.amountBreakup[0].postTaxAmount !== 0 && project.proposedBudget !== 0 && ((project['projectVisibility'] === "public") || (project['projectVisibility'] === "private" && project['assignedFor'].length)) && project['agendaId'] !== "") {
				project['status'] = 'Submitted';
				swal({
					title: 'Do you want to submit this Project?',
					text: "You won't be able to revert this!",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, Submit it!'
				}).then((result) => {
					fetch(process.env.REACT_APP_MIS_API_URL + '/create-project', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'Auth': JSON.parse(readCookie('mis_data')).access_token
						},
						body: JSON.stringify(project)
					}).then((response) => response.json())
					.then((data) => {
						if (data['status'] === 'ok') {
							this.notifySuccess('This Project has been submitted successfully.');
							window.location.pathname = '/im/projects';
						}
					});
				});
			} else {
				swal({
					title: 'Project creation failed!',
					text: 'Please enter valid inputs for all the mandatory fields.',
					type: 'error'
				});
			}
		}
	}

	notifySuccess = (text) => toast.success(text);
	notifyError = (text) => toast.error(text);

	handleChange = address => {
		this.setState({ address: address });
	}

	kpiNameInput(e) {
		this.setState({ customKpi: e.target.value })
	}

	customKpi(i, name, e) {
		let kpiData = this.state.kpiData;
		if (name === "customKpiName") {
			kpiData[i]["name"] = e.target.value
		} else if (name === "unit") {
			kpiData[i]["unit"] = e.target.value
			// kpiData[i]["unitName"] = $(e.target).find('option:selected').data('name')
			kpiData[i]["dataType"] = $(e.target).find('option:selected').data('type')
		}
		this.setState({ kpiData });
	}

	addCustomKpi(i, e) {
		let array = this.state.customKpiInput;
		let flag = true;
		for (let i = 0; i < array.length; i++) {
			if (array[i].name === "" || array[i].unit === "" || array[i].symbol === "" || array[i].dataType === "") {
				flag = false;
				break;
			}
		}
		if (flag) {
			array.push({ name: "", unit: "", dataType: "", symbol: "" })
		} else {
			this.notifyError("Please enter a valid KPI name!")
		}
		this.setState({ customKpiInput: array });
	}

	removeCustomKpiInput(i, e) {
		let array = this.state.customKpiInput;
		array.splice(i, 1)
		this.setState(array);
	}

	addNewSelectedBeneficiaryRow(e) {
		let beneficiariesData = this.state.beneficiariesData;
		if (beneficiariesData[e]['type'] !== "" && beneficiariesData[e]['name'] !== "" && beneficiariesData[e]['potential'] !== "") {
			beneficiariesData.push({
				type: "",
				name: "",
				potential: "",
				id: ""
			});
			this.setState({ beneficiariesData });
		} else {
			this.notifyError("The fields are empty, please provide valid information !")
		}
	}

	addMoreKpiData(e) {
		let kpiData = this.state.kpiData;
		if (kpiData[e]['type'] !== "" && kpiData[e]['name'] !== "" && kpiData[e]['unit'] !== "") {
			kpiData.push({
				unit: "",
				type: "",
				name: "",
				targetValue: "",
				baselineValue: "",
				customKpi: "",
				custom: false
			});
			this.setState({ kpiData });
		} else {
			this.notifyError("The fields are empty, please provide valid information !")
		}
	}

	removeBeneficiary(i) {
		let beneficiariesData = this.state.beneficiariesData;
		beneficiariesData.splice(i, 1);
		this.setState({ beneficiariesData });
	}
	removeKpiData(i) {
		let kpiData = this.state.kpiData;
		kpiData.splice(i, 1);
		this.setState({ kpiData });
	}

	projectVisibility(e) {
		this.setState({ projectVisibility: e.target.value })
	}

	newFunction = (e) => {
		let partnerData = $(e.target).find('option:selected').data('data');
		let project = this.state.project;
		project["assignedFor"] = [];
		project["invitations"] = [];
		project['assignedFor'].push(partnerData.companyId); //companyId
		project['invitations'].push(partnerData.id);
		this.setState({ project });
		fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/project-invitation/' + e.target.value, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
		.then(function (data) {
			if (data.status === 'ok') {
				let indicators = [];
				let projectCategory;
				let project = this.state.project;
				let kpiData = [];
					if (data.data.indicators.length > 0) {
						for (let i = 0; i < data.data.indicators.length; i++) {
							let kpiDataObject = {};
							indicators.push(data.data.indicators[i]);
							kpiDataObject["type"] = data.data.indicators[i]["type"];
							kpiDataObject["unit"] = data.data.indicators[i]["unit"];
							kpiDataObject["name"] = data.data.indicators[i]["name"];
							kpiDataObject["indicatorId"] = data.data.indicators[i]["id"];
							kpiData.push(kpiDataObject);
						}
					} else {
						kpiData.push({
							unit: "",
							type: "",
							name: "",
							targetValue: "",
							baselineValue: "",
							customKpi: "",
							custom: false
						});
					}
				if (Object.values(data.data.projectCategory).length > 0) {
					project['selectedProjectCategory'] = data.data.projectCategory.id
				}
				projectCategory = data.data.projectCategory;
				this.setState({indicators, projectCategory, kpiData, project})
			}
		}.bind(this));
	}

	render() {
		const children2 = [];
		for (let i = 0; i < this.state.partnerCorporates.length; i++) {
			children2.push(<Option key={i + 1} value={this.state.partnerCorporates[i].id}>{this.state.partnerCorporates[i].companyName}({this.state.partnerCorporates[i].subject})</Option>);
		}
		return (
			<div className="im-container im-add-project">
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<h2 className="add-project-title">
					Add Project
				</h2>
				<Col md={8} mdOffset={2} className="add-project-form-container">
					<Col className="form-group" md={12}>
						<label className="control-label is-imp">Title of the Project:</label>
						<input type="text" className="form-control" required placeholder="Title of the Project" value={this.state.project.title} onChange={this.updateChange.bind(this, 'title')} />
					</Col>
					<Col className="form-group" md={12}>
						<label className="control-label is-imp">Background and Rationale of the Project:</label>
						<textarea type="text" className="form-control" required placeholder="Background and Rationale of the Project" value={this.state.project.description} onChange={this.updateChange.bind(this, 'description')}></textarea>
					</Col>

					<Col className="form-group" md={12}>
						<label className="control-label is-imp">Overall Objective of the Project:</label>
						<textarea type="text" className="form-control" required placeholder="Overall Objective of the Project" value={this.state.project.need} onChange={this.updateChange.bind(this, 'need')}></textarea>
					</Col>

					<Col className="form-group" md={12}>
						<label className="control-label is-imp">Expected Impact:</label>
						<textarea type="text" className="form-control" required placeholder="Expected Impact" value={this.state.project.expectedImpact} onChange={this.updateChange.bind(this, 'expectedImpact')}></textarea>
					</Col>

					<Col className="form-group" md={12}>
						<label className="control-label">What are the Systems in place to monitor the impact of the project?</label>
						<textarea type="text" className="form-control" placeholder="Systems in place for impact monitoring" value={this.state.project.impactMonitoring} onChange={this.updateChange.bind(this, 'impactMonitoring')}></textarea>
					</Col>

					<Col className="form-group" md={12}>
						<label className="control-label">What is your History of executing similar projects in the past?</label>
						<textarea type="text" className="form-control" placeholder="History of executing similar projects in past" value={this.state.project.pastProjectHistory} onChange={this.updateChange.bind(this, 'pastProjectHistory')}></textarea>
					</Col>

					<Col className="form-group" md={12}>
						<label className="control-label">If you are deploying an Innovation, paste the link:</label>
						<input type="text" className="form-control" placeholder="Innovation Url" value={this.state.project.innovationUrl} onChange={this.updateChange.bind(this, 'innovationUrl')} />
					</Col>

					<Col className="form-group partners-container mt10" md={12}>
						<label className="control-label">Enlist any partnerships formed to execute this project:</label>
						<Row className="partners">
							{this.state.project.partners.map(function (partner, index) {
								return (
									<Col md={6} className="form-group partner-container" key={index}>
										<Col md={this.state.project.partners.length > 1 ? 11 : 12} className="form-group">
											<label className="control-label">{'Partner ' + (index + 1) + ':'}</label>
											<input className="form-control" placeholder="Partner..." type="text" required
												value={partner} onChange={this.updatePartnerChange.bind(this, index)} />
										</Col>
										{this.state.project.partners.length > 1 ? (
											<Col md={1} className="remove-btn-container">
												<span className="remove-btn" onClick={this.removePartner.bind(this, index)}><Icon icon={ic_delete} size={22} /></span>
											</Col>
										) : (null)}
									</Col>
								)
							}.bind(this))}
							<Col md={12} className="m0 add-btn-container">
								<button className="btn add-btn" onClick={this.addPartner.bind(this)}><Icon icon={ic_add_box} /> Add Partner</button>
							</Col>
						</Row>
					</Col>

					<Row className="mt10">
						<Col className="form-group" md={4}>
							<label className="control-label is-imp">Goal:</label>
							<select className="form-control" value={this.state.project.selectedGoal}
								onChange={this.updateChange.bind(this, 'selectedGoal')}>
								<option disabled value=''>Select Goal</option>
								{this.state.goals.map(function (goal, index) {
									return (
										<option value={goal._id} key={index}>{goal.name}</option>
									)
								})}
							</select>
						</Col>

						<Col className="form-group" md={4}>
							<label className="control-label is-imp">Agenda:</label>
							<select className="form-control" value={this.state.project.selectedAgenda}
								onChange={this.updateChange.bind(this, 'selectedAgenda')}>
								{this.state.agendas.length !== 0 || this.state.project.selectedGoal !== '' ? (
									<option disabled value=''>Select Agenda</option>
								) : (null)}
								{this.state.agendas.length === 0 && this.state.project.selectedGoal === '' ? (
									<option disabled value=''>Select Goal First</option>
								) : (
										this.state.agendas.map(function (agenda, index) {
											return (
												<option value={agenda._id} key={index}>{agenda.name}</option>
											)
										})
									)}
							</select>
						</Col>

						<Col className="form-group" md={4}>
							<label className="control-label is-imp">CSR Thematic Category:</label>
							<select className="form-control" value={this.state.project.selectedThematicCategory}
								onChange={this.updateChange.bind(this, 'selectedThematicCategory')}>
								<option disabled value=''>Select Thematic Category</option>
								{this.state.thematicCategories.map(function (thematicCategory, index) {
									return (
										<option value={thematicCategory._id} key={index}>{thematicCategory.name}</option>
									)
								})}
							</select>
						</Col>
					</Row>

					{/*<Row className="form-group project-category-selector-container mt10" >
						<Col className='form-group' md={12}>
							<div className="project-category-selector">
								<label className="control-label is-imp">Project Category: </label>
								<select className="form-control" value={this.state.project.selectedProjectCategory}
									onChange={this.updateChange.bind(this, 'selectedProjectCategory')}>
									<option disabled value=''>Select Project Category</option>
									{this.state.projectCategories.map(function (projectCategory, index) {
										return (
											<option key={index} data-key={projectCategory.name} value={projectCategory.id}>
												{projectCategory.name}
											</option>
										)
									})}
									<option value="0" data-key="Custom">Custom</option>
								</select>
							</div>
						</Col>
						{this.state.project.selectedProjectCategory === '0' ? (
							<Col className='form-group' md={6}>
								<div className="custom-project-category-container">
									<label className="control-label is-imp">Custom Project Category: </label>
									<input className="form-control" placeholder="Custom Project" type="text" required
										value={this.state.customProject} onChange={this.updateChange.bind(this, 'customProject')} />
								</div>
							</Col>
						) : (null)}
					</Row>*/}

					<Col className="form-group milestones-container mt10" md={12}>
						<label className="control-label is-imp">Key activities/milestones planned to achieve through this project:</label>
						<Row className="milestones">
							{this.state.project.milestones.map(function (milestone, index) {
								return (
									<Row className="form-group milestone-container" key={index}>
										<Col md={this.state.project.milestones.length > 1 ? 11 : 12} className="form-group">
											<label className="control-label is-imp">{'Activity/Milestone ' + (index + 1) + ':'}</label>
											<input className="form-control" placeholder="Activity/Milestone..." type="text" required
												value={milestone} onChange={this.updateMilestoneChange.bind(this, index)} />
										</Col>
										{this.state.project.milestones.length > 1 ? (
											<Col md={1} className="remove-btn-container">
												<span className="remove-btn" onClick={this.removeMilestone.bind(this, index)}><Icon icon={ic_delete} size={22} /></span>
											</Col>
										) : (null)}
									</Row>
								)
							}.bind(this))}
							<Col md={12} className="m0 add-btn-container">
								<button className="btn add-btn" onClick={this.addMilestone.bind(this)}><Icon icon={ic_add_box} /> Add Milestone</button>
							</Col>
						</Row>
					</Col>

					<Col md={12}>
						<label className="control-label is-imp">Select Project Visibility</label>
						{this.state.showPublic === true ? (
							<Col md={12} style={{ display: "flex", alignItems: "center", paddingLeft: "0px" }}>
								<input type='radio' name="lePlatform" checked={this.state.projectVisibility === "public" ? true : false} value="public" onChange={this.projectVisibility.bind(this)} />
								<label className="control-label" style={{ marginTop: "8px", marginLeft: "3px" }}>Public<span style={{ fontSize: "11px" }}> (Project is visible to all corporates on the platform)</span> </label>
							</Col>
						) : (null)}
						<Col md={12} style={{ paddingLeft: "0px" }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<input type='radio' name="partnerCorporates" disabled={this.state.partnerCorporates.length > 0 ? false : true} checked={this.state.projectVisibility === "private" ? true : false} value="private" onChange={this.projectVisibility.bind(this)} />
								<label className="control-label" style={{ marginTop: "8px", marginLeft: "3px" }}>Private<span style={{ fontSize: "11px" }}> (Project is shared only with selected corporate partners)</span></label>
							</div>
							{this.state.projectVisibility === "private" ?
								(<LocaleProvider local={enUS}>
									<select mode="multiple" style={{ width: '40%', height:"34px" }} name="partnerCorporate" onChange={this.newFunction}>
										<option value="">Select Corporate</option>
										{this.state.partnerCorporates.map((data, i) => {
											return <option key={i} data-data={JSON.stringify(data)} value={data.id}>{data.companyName} ({data.subject})</option>
										})}
									</select>
								</LocaleProvider>) : (null)
							}
						</Col>
					</Col>

					<Col className="mt10" style={{float:"left", display: "flex", width: "100%"}} >
						<Col className='form-group' md={12}>
							<div className="project-category-selector">
								<label className="control-label is-imp">Project Category: </label>
								{Object.values(this.state.projectCategory).length === 0 ? (
									<select className="form-control" value={this.state.project.selectedProjectCategory}
										onChange={this.updateChange.bind(this, 'selectedProjectCategory')}>
										<option disabled value=''>Select Project Category</option>
										{this.state.projectCategories.map(function (projectCategory, index) {
											return (
												<option key={index} data-key={projectCategory.name} value={projectCategory.id}>
													{projectCategory.name}
												</option>
											)
										})}
										<option value="0" data-key="Custom">Custom</option>
									</select>
								) : (
									<input className='form-control' type="text" value={this.state.projectCategory.name} disabled style={{width:"100%", height:"34px"}}/>
								)}
							</div>
						</Col>
						{this.state.project.selectedProjectCategory === '0' && Object.values(this.state.projectCategory).length === 0 ? (
							<Col className='form-group' md={6}>
								<div className="custom-project-category-container">
									<label className="control-label is-imp">Custom Project Category: </label>
									<input className="form-control" placeholder="Custom Project" type="text" required
										value={this.state.customProject} onChange={this.updateChange.bind(this, 'customProject')} />
								</div>
							</Col>
						) : (null)}
					</Col>

					<Col md={12} className="mt10">
						<label className="control-label mb10">Output/Outcome Indicators</label>
						<Row style={{ background: "#f8f8f8", paddingBottom: "20px" }}>
							{this.state.indicators.length === 0 ? (
								this.state.kpiData.map((data, index) => {
									return (
										<Col className='form-group' key={index} md={12} style={{ display: "flex", alignItems: "center" }}>
										<div className='kpi-selector' style={{ width: "16%" }}>
											<label className='kpi-label control-label is-imp'>Select Type:</label>
											<select value={data.type} className='form-control' style={{ width: '100%' }} onChange={this.updateChange.bind(this, "kpiType", index)}>
												<option disabled selected value="">Select Type</option>
												<option value='Output'>Output</option>
												<option value='Outcome'>Outcome</option>
											</select>
										</div>
										{data.custom !== true ? (
											<div className='kpi-selector' style={{ width: "40%", marginLeft: "15px", display: "flex" }}>
												<div>
													<label className='kpi-label control-label is-imp'>Select Indicators:</label>
													<select className='form-control' value={data.id} style={{ width: '100%' }} onChange={this.updateChange.bind(this, "kpiName", index)}>
														{(this.state.kpis.length !== 0 || this.state.project.selectedProjectCategory !== '') ?
															<option disabled selected key={0} value="">Select Indicators</option> : null
														}
														{(this.state.kpis.length === 0 && this.state.project.selectedProjectCategory === '') ?
															<option disabled selected key={1} value="">Select Project Category First</option> :
															this.state.kpis.map((kpi, i) => {
																return <option key={i} data-name={kpi.name} value={kpi.id}>{kpi.name}</option>
															})
														}}
														{(this.state.kpis.length !== 0 || this.state.project.selectedProjectCategory !== '') ?
															<option key={this.state.kpis.length + 1} value="0">Custom</option> : null
														}
													</select>
												</div>
												<div style={{ marginLeft: "15px" }}>
													<label className='kpi-label control-label is-imp'>UNIT:</label>
													<input type="text" disabled value={data.unit} className='form-control' onChange={this.customKpi.bind(this, index, "unit")} />
												</div>
											</div>
										) : (
												<div className="custom-Kpi-container" style={{ width: "40%", marginLeft: "15px", display: "flex" }}>
													<div>
														<label className="control-label is-imp">Enter Indicator Name: </label>
														<input name="customKpiName" style={{ display: "block", width: "100%" }} className="form-control" placeholder="Enter Indicator Name:" type="text" required={(data.custom === true) && (index === 0) ? true : false}
															value={data.name} onChange={this.customKpi.bind(this, index, "customKpiName")} />
													</div>
													<div style={{ marginLeft: "15px" }}>
														<label className="control-label is-imp">Select Unit: </label>
														<select name="unit" className='form-control' style={{ width: '100%' }} onChange={this.customKpi.bind(this, index, "unit")}>
															<option disabled selected>Select Unit</option>
															{this.state.sample_size.map((unit, i) => {
																return (
																	<option key={i} value={unit.symbol} data-type={unit.dataType} data-name={unit.unit}>{unit.unit}</option>
																)
															})}
														</select>
													</div>

												</div>
											)}
										<div className='kpi-selector' style={{ width: "15%", marginLeft: "15px" }}>
											<label className='kpi-label control-label'>Baseline Value:</label>
											<input type='number' className="form-control" style={{ display: "block", width: "100%", marginTop: "6px" }} value={data.baselineValue} placeholder="Baseline Value" onChange={this.updateChange.bind(this, "kpiBaselineValue", index)} />
										</div>
										<div className='kpi-selector' style={{ width: "14%", marginLeft: "15px" }}>
											<label className='kpi-label control-label'>Target Value:</label>
											<input type='number' className="form-control" style={{ display: "block", width: "100%", marginTop: "6px" }} value={data.targetValue} placeholder="Target Value" onChange={this.updateChange.bind(this, "kpiTargetValue", index)} />
										</div>
										<div style={{ display: "flex", alignItems: "center", marginTop: "35px" }}>
											{this.state.kpiData.length > 1 ? (
												<i className="fa fa-trash" onClick={this.removeKpiData.bind(this, index)} style={{ backgroundColor: "#ef5a20", borderRadius: "50%", padding: "5px 7px", color: "#fff", cursor: "pointer", marginLeft: "10px" }}></i>
											) : null}
											<i className="fa fa-plus" style={{ backgroundColor: "#ef5a20", borderRadius: "50%", padding: "5px 7px", color: "#fff", cursor: "pointer", marginLeft: "10px" }} onClick={this.addMoreKpiData.bind(this, index)}></i>
										</div>
									</Col>)
								})
							):(
								this.state.indicators.map((data, index) => {
									return (
										<Col className='form-group' key={index} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											<div className='kpi-selector' style={{ width: "20%" }}>
												<label className='kpi-label control-label is-imp'>Select Type:</label>
												<select value={data.type} className='form-control' disabled style={{ width: '100%' }}>
													<option disabled selected value={data.type}>{data.type}</option>
												</select>
											</div>
												<div className='kpi-selector' style={{ width: "40%", marginLeft: "15px", display: "flex" }}>
													<div style={{width:"50%"}}>
														<label className='kpi-label control-label is-imp'>Select Indicators:</label>
														<select className='form-control' value={data.id} disabled style={{ width: '100%' }}>
																<option key={this.state.kpis.length + 1} selected disabled value={data.id}>{data.name}</option>
														</select>
													</div>
													<div style={{ marginLeft: "18px", width:"50%" }}>
														<label className='kpi-label control-label is-imp'>UNIT:</label>
														<input type="text" disabled value={data.unit} className='form-control'/>
													</div>
												</div>
											<div className='kpi-selector' style={{ width: "20%", marginLeft: "15px" }}>
												<label className='kpi-label control-label'>Baseline Value:</label>
												<input type='number' className="form-control" style={{ display: "block", width: "100%", marginTop: "6px" }} value={data.baselineValue} placeholder="Baseline Value" onChange={this.updateChange.bind(this, "kpiBaselineValue", index)} />
											</div>
											<div className='kpi-selector' style={{ width: "20%", marginLeft: "15px" }}>
												<label className='kpi-label control-label'>Target Value:</label>
												<input type='number' className="form-control" style={{ display: "block", width: "100%", marginTop: "6px" }} value={data.targetValue} placeholder="Target Value" onChange={this.updateChange.bind(this, "kpiTargetValue", index)} />
											</div>
										</Col>
									)
								})
							)}
						</Row>
					</Col>


					<Col className="form-group cover-image-video-container mt10" md={12}>
						<label className="control-label">Upload A Cover Image: </label>
						<div className="cover-image-video">
							<div className="image-upload">
								<input type="file" accept="image/*" className="hidden" id="uploadCoverImage" onChange={this.updateChange.bind(this, 'coverImage')} />
								<button className="btn" onClick={this.uploadImageClick.bind(this)}>
									{this.state.coverImagePreviewUrl !== '' ? 'Upload Different Image' : 'Upload Image'}
								</button>
								<img src={this.state.coverImagePreviewUrl} alt="Uploaded file" className={this.state.coverImagePreviewUrl !== "" ? "image-preview" : "hidden"} />
							</div>
						</div>
					</Col>


					<Col md={12} className="mt20">
						<label className="control-label mb10">Beneficiaries</label>
						<Row style={{ background: "#f8f8f8", paddingBottom: "20px" }}>
							{this.state.beneficiariesData.map(function (data, index) {
								return (
									<Col key={index} md={12} className="mt10">
										<Col className="form-group" md={3} style={{ paddingLeft: "0" }}>
											<label className="control-label is-imp">Select Impact Type:</label>
											<select className="form-control" value={data.type}
												onChange={this.updateChange.bind(this, 'selectedBeneficiaryType', index)}>
												<option disabled value=''>Select Beneficiary Type</option>
												<option value="Direct" >Direct</option>
												<option value="Indirect" >Indirect</option>
											</select>
										</Col>
										<Col className="form-group" md={4}>
											<label className="control-label is-imp">Select Beneficiaries:</label>
											<select className="form-control" value={data.id}
												onChange={this.updateChange.bind(this, 'selectedBeneficiary', index)}>
												<option disabled value=''>Select Beneficiary</option>
												{this.state.beneficiaries.map(function (beneficiary, index) {
													return (
														<option value={beneficiary._id} data-name={beneficiary.name} key={index}>{beneficiary.name}</option>
													)
												})}
											</select>
											{data.id === "0" ? (
												<div>
													<label className="control-label">Enter Beneficiary Name:</label>
													<input className='form-control' type="text" placeholder="Enter Beneficiary Name" onChange={this.updateChange.bind(this, 'selectedBeneficiaryName', index)} />
												</div>
											) : (null)}
										</Col>
										<Col className="form-group" md={3}>
											<label className="control-label">Enter Target Value:</label>
											<input className='form-control' type="number" value={data.potential} placeholder="Enter Target Value" onChange={this.updateChange.bind(this, 'selectedBeneficiaryPotential', index)} />
										</Col>
										<Col className="form-group" style={{ marginTop: "10px" }} md={1}>
											<button className="btn" style={{ marginTop: "35px" }} onClick={this.addNewSelectedBeneficiaryRow.bind(this, index)}><i className="fa fa-plus"></i></button>
										</Col>
										{this.state.beneficiariesData.length > 1 ? (
											<Col md={1} className="remove-btn-container" style={{ marginTop: "42px" }}>
												<i className="fa fa-trash" onClick={this.removeBeneficiary.bind(this, index)} style={{ backgroundColor: "#ef5a20", borderRadius: "50%", padding: "5px 7px", color: "#fff", cursor: "pointer" }}></i>
											</Col>
										) : (null)}
									</Col>
								)
							}.bind(this))}
						</Row>
					</Col>

					<Row className="form-group mt10">
						<Col md={12}>
							<label className="control-label is-imp">Proposed Duration:</label>
							<div className="proposed-duration-container">
								<input className="form-control" value={this.state.project.proposedDuration} placeholder="Duration"
									type="number" required onChange={this.updateChange.bind(this, 'proposedDuration')} />
								<select className="form-control" value={this.state.project.proposedDurationType}
									onChange={this.updateChange.bind(this, 'proposedDurationType')}>
									<option value='day'>Day(s)</option>
									<option value='month'>Month(s)</option>
									<option value='year'>Year(s)</option>
								</select>
							</div>
						</Col>
					</Row>

					<Col className="form-group deployment-locations-container mt10" md={12}>
						<label className="control-label is-imp">Location Of Deployment:</label>
						<Row className="deployment-locations">
							{this.state.project.deploymentLocations.map(function (deploymentLocation, index) {
								let inputProps = {
									value: deploymentLocation.address,
									onChange: this.onDeploymentLocationChange.bind(this, index),
									placeholder: 'Search Deployment Location...'
								};

								let cssClasses = {
									input: 'location form-control ' + deploymentLocation.locationError,
									autocompleteContainer: 'my-autocomplete-container'
								};

								return (
									<Row className="form-group deployment-location-container" key={index}>
										<Col md={this.state.project.deploymentLocations.length > 1 ? 11 : 12} className="form-group">
											<label className="control-label is-imp">{'Location ' + (index + 1) + ':'}</label>
											<PlacesAutocomplete inputProps={inputProps} classNames={cssClasses}
												value={this.state.address}
												onChange={value => this.setState({ address: value.target.value }, () => {
												})}
												onPress={(data, details) => { // 'details' is provided when fetchDetails = true
													//props.location = data.description;
												}}
											/>
										</Col>
										{this.state.project.deploymentLocations.length > 1 ? (
											<Col md={1} className="remove-btn-container">
												<span className="remove-btn" onClick={this.removeDeploymentLocation.bind(this, index)}><Icon icon={ic_delete} size={22} /></span>
											</Col>
										) : (null)}
									</Row>
								)
							}.bind(this))}
							<Col md={12} className="m0 add-btn-container">
								<button className="btn add-btn" onClick={this.addDeploymentLocation.bind(this)}><Icon icon={ic_add_location} /> Add another location</button>
							</Col>
						</Row>
					</Col>

					<Row className="mt10">
						<Col className="form-group" md={6}>
							<label className="control-label is-imp">Project Location Type: </label>
							<select className="form-control" value={this.state.project.selectedLocationType}
								onChange={this.updateChange.bind(this, 'selectedLocationType')}>
								<option disabled value=''>Select Location Type</option>
								{this.state.locationTypes.map(function (locationType, index) {
									return (
										<option value={locationType._id} key={index}>{locationType.name}</option>
									)
								})}
							</select>
						</Col>
					</Row>

					<Row className="mt10 unit-economics-container">
						<Col className="form-group" md={4}>
							<label className="control-label is-imp">Does this project follow unit economics?</label>
							<div className="unit-economics-radio-container">
								<label className="label-container">Yes
								  <input type="radio" name="unit-economics" checked={this.state.project.unitEconomics === true ? true : false}
										onChange={this.updateUnitEconomicsChange.bind(this, true)} />
									<span className="checkmark"></span>
								</label>
								<label className="label-container">No
								  <input type="radio" name="unit-economics" checked={this.state.project.unitEconomics === false ? true : false}
										onChange={this.updateUnitEconomicsChange.bind(this, false)} />
									<span className="checkmark"></span>
								</label>
							</div>
						</Col>

						{this.state.project.unitEconomics === true ? (
							<Col className="form-group" md={4}>
								<label className="control-label is-imp">Enter the cost of one unit of installation:</label>
								<input className="form-control" placeholder="Cost of one unit of installation" type="number" required
									value={this.state.project.unitCost} onChange={this.updateChange.bind(this, 'unitCost')} />
							</Col>
						) : (null)}

						{this.state.project.unitEconomics === true ? (
							<Col className="form-group" md={4}>
								<label className="control-label is-imp">What is the unit called?</label>
								<input className="form-control" placeholder="Unit name" type="text" required
									value={this.state.project.unitName} onChange={this.updateChange.bind(this, 'unitName')} />
							</Col>
						) : (null)}
					</Row>

					<Col className="form-group" md={12}>
						<Row className="mt10 amount-breakup-table-container">
							<div className="amount-breakup-table-label-button">
								<label className="control-label is-imp">Add Budget:</label>
								<button className="btn add-budget-btn" onClick={this.addAmountBreakupRow.bind(this)}><Icon icon={ic_add_box} /> Add Budget</button>
							</div>
							<div className="amount-breakup-table">
								<div className="amount-breakup-table-headers">
									<div className="header-item expense-head">
										<label className="is-imp" style={{ fontWeight: '400', fontSize: '12px', marginBottom: 'initial' }}>Expense Head</label>
									</div>
									<div className="header-item expense-item">
										<label className="is-imp" style={{ fontWeight: '400', fontSize: '12px', marginBottom: 'initial' }}>Expense Item</label>
									</div>
									<div className="header-item in-units">In Units?</div>
									<div className="header-item unit-cost">Unit Cost</div>
									<div className="header-item total-units">Total Units</div>
									<div className="header-item pretax-amount">Pretax Amount</div>
									<div className="header-item tax-rate">Tax Rate</div>
									<div className="header-item proposed-amount">Proposed Amount</div>
									<div className="header-item comments">Comments</div>
									<div className="header-item delete">Delete</div>
								</div>

								<div className="amount-breakup-table-rows">
									{this.state.project.amountBreakup.map(function (row, index) {
										return (
											<div className="amount-breakup-table-row" key={index}>
												<div className="row-item expense-head" onClick={this.openBudgetEditorModal.bind(this, 'costHead', 'Expense Head', row.costHead, index)}>
													<span title={this.getCostHeadName(row.costHead)}>{this.getCostHeadName(row.costHead)}</span>
												</div>

												<div className="row-item expense-item" onClick={this.openBudgetEditorModal.bind(this, 'expenseItem', 'Expense Item', row.expenseItem, index)}>
													<span title={row.expenseItem}>{row.expenseItem}</span>
												</div>

												<div className="row-item in-units">
													<div>
														<label className="label-container">Yes
														  <input type="checkbox" name="unit-economics" checked={row.unitEconomics === true ? true : false}
																onChange={this.updateAmountBreakupChange.bind(this, 'unitEconomics', index)} />
															<span className="checkmark"></span>
														</label>
													</div>
												</div>

												<div className="row-item unit-cost">
													<input className="form-control" disabled={!row.unitEconomics} type="number" value={row.unitCost} onChange={this.updateAmountBreakupChange.bind(this, 'unitCost', index)} />
												</div>

												<div className="row-item total-units">
													<input className="form-control" disabled={!row.unitEconomics} type="number" value={row.totalUnits} onChange={this.updateAmountBreakupChange.bind(this, 'totalUnits', index)} />
												</div>

												<div className="row-item pretax-amount">
													<input className="form-control" disabled={row.unitEconomics} type="number" value={row.preTaxAmount} onChange={this.updateAmountBreakupChange.bind(this, 'preTaxAmount', index)} />
												</div>

												<div className="row-item tax-rate">
													<input className="form-control" type="number" value={row.taxRate} onChange={this.updateAmountBreakupChange.bind(this, 'taxRate', index)} />
												</div>

												<div className="row-item proposed-amount">
													<span>{Math.round(row.postTaxAmount * 100) / 100}</span>
												</div>

												<div className="row-item comments" onClick={this.openBudgetEditorModal.bind(this, 'comments', 'Comments', row.comments, index)}>
													<div title={row.comments}>{row.comments}</div>
												</div>

												<div className="row-item delete">
													<span className="remove-amount-breakup-row" onClick={this.removeAmountBreakupRow.bind(this, index)}><Icon icon={ic_delete} size={22} /></span>
												</div>
											</div>
										)
									}.bind(this))}
								</div>
								<div className="total-budget-container mt10">
									<b>Total Budget:</b>{' INR ' + this.state.project.proposedBudget}
								</div>
							</div>
						</Row>
					</Col>

					<Col className="form-group" md={12}>
						<label className="control-label">How do you plan to sustain this project in a long-term and what would be your funding source:</label>
						<textarea type="text" className="form-control" required placeholder="Sustainability Plan" value={this.state.project.sustainabilityPlan} onChange={this.updateChange.bind(this, 'sustainabilityPlan')}></textarea>
					</Col>

					<Col className="form-group ee-opportunities-container mt10" md={12}>
						<label className="control-label">Enlist various Opportunities for Employee Engagement, as part of this project:</label>
						<Row className="ee-opportunities">
							{this.state.project.eeOpportunities.map(function (eeOpportunity, index) {
								return (
									<Row className="form-group ee-opportunity-container" key={index}>
										<Col md={this.state.project.eeOpportunities.length > 1 ? 11 : 12} className="form-group">
											<label className="control-label">{'Opportunity ' + (index + 1) + ':'}</label>
											<input className="form-control" placeholder="Opportunity..." type="text" required
												value={eeOpportunity} onChange={this.updateEEOpportunityChange.bind(this, index)} />
										</Col>
										{this.state.project.eeOpportunities.length > 1 ? (
											<Col md={1} className="remove-btn-container">
												<span className="remove-btn" onClick={this.removeEEOpportunity.bind(this, index)}><Icon icon={ic_delete} size={22} /></span>
											</Col>
										) : (null)}
									</Row>
								)
							}.bind(this))}
							<Col md={12} className="m0 add-btn-container">
								<button className="btn add-btn" onClick={this.addEEOpportunity.bind(this)}><Icon icon={ic_add_box} /> Add Opportunity</button>
							</Col>
						</Row>
					</Col>


					{/*<Col md={12} style={{ marginBottom: "50px" }}>
						<label className="control-label is-imp">Select Project Visibility</label>
						{this.state.showPublic === true ? (
							<Col md={12} style={{ display: "flex", alignItems: "center", paddingLeft: "0px" }}>
								<input type='radio' name="lePlatform" checked={this.state.projectVisibility === "public" ? true : false} value="public" onChange={this.projectVisibility.bind(this)} />
								<label className="control-label" style={{ marginTop: "8px", marginLeft: "3px" }}>Public<span style={{ fontSize: "11px" }}> (Project is visible to all corporates on the platform)</span> </label>
							</Col>
						) : (null)}
						<Col md={12} style={{ paddingLeft: "0px" }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<input type='radio' name="partnerCorporates" disabled={this.state.partnerCorporates.length > 0 ? false : true} checked={this.state.projectVisibility === "private" ? true : false} value="private" onChange={this.projectVisibility.bind(this)} />
								<label className="control-label" style={{ marginTop: "8px", marginLeft: "3px" }}>Private<span style={{ fontSize: "11px" }}> (Project is shared only with selected corporate partners)</span></label>
							</div>
							{this.state.projectVisibility === "private" ?
								(<LocaleProvider local={enUS}>
									<select mode="multiple" style={{ width: '40%' }}
										onChange={this.updateChange.bind(this, '	')}>
										{this.state.	s.map((data, i) => {
											return <option key={i} value="">{data.companyName}({data.subject})</option>
										})}
									</select>
								</LocaleProvider>) : (null)
							}
						</Col>
					</Col>*/}



					<Col md={12} className="mt20 mb20 add-project-btn-container">
						<button className="btn discard-project-btn" onClick={this.cancelEditProject.bind(this)}>
							<i className="fa fa-times"></i>
							Discard
						</button>
						<button className="btn save-project-btn" onClick={this.saveProject.bind(this, 'save')}>
							<i className="fa fa-check"></i>
							Save As Draft
						</button>
						<button className="btn submit-project-btn" onClick={this.saveProject.bind(this, 'submit')}>
							<i className="fa fa-paper-plane"></i>
							Submit
						</button>
					</Col>

				</Col>



				{this.state.budgetEditorModalVisibility ? (
					<BudgetEditorModal selectedColumn={this.state.budgetEditorModal.selectedColumn} hideBudgetEditorModal={this.hideBudgetEditorModal.bind(this)}
						budgetEditorModalVisibility={this.state.budgetEditorModalVisibility} selectedColumnValue={this.state.budgetEditorModal.selectedColumnValue}
						selectedColumnName={this.state.budgetEditorModal.selectedColumnName} selectedRowIndex={this.state.budgetEditorModal.selectedRowIndex}
						saveBudgetEditorValue={this.saveBudgetEditorValue.bind(this)} />
				) : (null)}
			</div>
		)
	}
}