import React from 'react';
import {Row, Col, Button, Modal} from 'react-bootstrap';
import $ from 'jquery';
import swal from "sweetalert2";
import ReactPasswordStrength from 'react-password-strength';

import "react-password-strength/dist/style.css";
import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../../ImpactManagement/cookie.js').readCookie;

export default class SettingsSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_password_error: false,
      show_new_password_error: false,
      show_mismatch_error: false,
      changePasswordModalVisibility: false,
      current_password: '',
      new_password: '',
      confirm_password: '',
      passwordData: null
    }
    this.onChangePasswordClick = this.onChangePasswordClick.bind(this);
    this.onChangeDetailsClick = this.onChangeDetailsClick.bind(this);
    this.changeProfilePic = this.changeProfilePic.bind(this);
  }

  onChangePasswordClick() {
    this.setState({ changePasswordModalVisibility: true });
  }

  onChangeDetailsClick() {
    swal({
      title: 'Change Details',
      html: '<div class="row">' +
        '<div class="col-md-6">' +
          '<span class="is-imp">First Name</span><input type="input" id="swal-input1" class="swal2-input" placeholder="First Name" value="' + this.props.userData.first_name + '">' +
        '</div><div class="col-md-6">' +
          '<span class="is-imp">Last Name</span><input type="input" id="swal-input2" class="swal2-input" placeholder="Last Name" value="' + this.props.userData.last_name + '">' +
        '</div><div class="col-md-6">' +
          '<span>Bank Name</span><input type="input" id="swal-input3" class="swal2-input" placeholder="Bank Name" value="' + this.props.userData.bank_name + '">' +
        '</div><div class="col-md-6">' +
          '<span>Bank Account Number</span><input type="input" id="swal-input4" class="swal2-input" placeholder="Bank Account Number" value="' + this.props.userData.bank_account_number + '">' +
        '</div><div class="col-md-6">' +
          '<span>IFSC</span><input type="input" id="swal-input5" class="swal2-input" placeholder="IFSC" value="' + this.props.userData.ifsc + '">' +
        '</div><div class="col-md-6">' +
          '<span>UPI</span><input type="input" id="swal-input6" class="swal2-input" placeholder="UPI (98xxxxxxxx@upi)" value="' + this.props.userData.upi + '">' +
        '</div><div class="col-md-6">' +
          '<span>Mobile</span><input type="input" id="swal-input7" class="swal2-input" placeholder="Mobile" value="' + this.props.userData.mobile + '">' +
        '</div></div>',
      customClass: 'swal-wide'
    }).then(function (result) {
      let val1 = $('#swal-input1').val();
      let val2 = $('#swal-input2').val();
      let bank_name = $('#swal-input3').val();
      let bank_account_number = $('#swal-input4').val();
      let ifsc = $('#swal-input5').val();
      let upi = $('#swal-input6').val();
      let mobile = $('#swal-input7').val();
      if (val1 !== '' && val2 !== '' && result) {
        swal({
          title: 'Are you sure?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Change!',
          cancelButtonText: 'No, cancel!'
        }).then(function(result) {
          if(result && val1 !== '' && val2 !== '') {
            let new_data = {};
            new_data['first_name'] = val1;
            new_data['last_name'] = val2;
            new_data['bank_name'] = bank_name;
            new_data['bank_account_number'] = bank_account_number;
            new_data['ifsc'] = ifsc;
            new_data['upi'] = upi;
            new_data['mobile'] = mobile;
            fetch(process.env.REACT_APP_API_URL + '/update_user', {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
              },
              body: JSON.stringify({ new_data })
            }).then((response) => response.json())
            .then(function(data) {
              if(data['status'] !== 'ok') {
                swal('Oops...',
                'Something went wrong!',
                'error');
              } else {
                var others = {};
                others['bank_name'] = bank_name;
                others['bank_account_number'] = bank_account_number;
                others['ifsc'] = ifsc;
                others['upi'] = upi;
                others['mobile'] = mobile;
                this.props.updateUser(val1, val2, '', others);
              }
            }.bind(this));
          }
        }.bind(this));
      }
    }.bind(this)).catch(swal.noop)
  }

  showProfPicUploadButton() {
    $('#uploadUserPic').removeClass('hidden');
    $('#cancelUploadUserPic').removeClass('hidden');
  }

  changeProfilePic() {
    var profPic = $('input#userProfPic');

    var data = new FormData();
    data.append('profile_picture', profPic[0].files[0]);

    fetch(process.env.REACT_APP_API_URL + '/update_profile_picture', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: data
    }).then((response) => response.json())
    .then(function(data) {
      if(data['status'] === 'ok') {
        this.props.updateUser('', '', data['profile_picture']);
        $('#uploadUserPic').addClass('hidden');
        $('#cancelUploadUserPic').addClass('hidden');
        $('input#userProfPic').val('');
      }
    }.bind(this));
  }

  cancelChangeProfilePic() {
    $('#uploadUserPic').addClass('hidden');
    $('#cancelUploadUserPic').addClass('hidden');
    $('input#userProfPic').val('');
  }

  hideChangePasswordModal() {
    this.setState({ changePasswordModalVisibility: false });
  }

  changeNewPassword(passwordData) {
    this.setState({ passwordData, new_password: passwordData.password, show_new_password_error: false });
  }

  changePassword() {
    let current_password = this.state.current_password;
    let new_password = this.state.new_password;
    let confirm_password = this.state.confirm_password;
    let passwordData = this.state.passwordData;
    if(passwordData !== null) {
      if(current_password !== '' && new_password === confirm_password && passwordData.isValid) {
        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Change!',
          cancelButtonText: 'No, cancel!',
          confirmButtonClass: 'btn',
          cancelButtonClass: 'btn',
        }).then(function(result) {
          if(result) {
            fetch(process.env.REACT_APP_API_URL + '/update_password', {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
              },
              body: JSON.stringify({
                new_password: new_password,
                old_password: current_password
              })
            }).then((response) => response.json())
            .then(function(data) {
              if(data['status'] !== 'ok') {
                swal('Oops...', 'Something went wrong!', 'error');
              } else {
                this.setState({changePasswordModalVisibility: false}, function() {
                  swal('Password updated', 'Password updated successfully', 'success');
                }.bind(this));
              }
            }.bind(this));
          }
        }.bind(this));
      } else {
        if(current_password === '') this.setState({ show_password_error: true });
        if(new_password !== confirm_password) this.setState({ show_mismatch_error: true });
        if(!passwordData.isValid) this.setState({ show_new_password_error: true });
      }
    } else {
      if(current_password === '') this.setState({ show_password_error: true });
      this.setState({ show_new_password_error: true });
    }
  }

  updateChange(name, e) {
    this.setState({ [name]: e.target.value, show_password_error: false, show_mismatch_error: false });
  }

  render () {
    return (
      <div className="tab-container">
        <Row>
          <Col md={12}>
            <Row id="personal-details" className="mt20 em-personal-details">
              <Row className="flex-center">
                <Col md={4} className="mb10">
                  <div className="ngo_image">
                    {(() => {
                      if(this.props.userData['profile_picture'] === '') {
                        return (
                          <img src='/images/user.png' alt="" className="img-circle" />
                        );
                      } else {
                        return (
                          <img src={this.props.userData['profile_picture']} alt="" onError={(event)=>event.target.setAttribute("src","/images/user.png")} />
                        );
                      }
                    })()}
                    <input id="userProfPic" className="mb10" type="file" onChange={this.showProfPicUploadButton} />
                    <Col md={12} className="mt10">
                      <Button id="uploadUserPic" className="btn-success react-bs-table-add-btn col-md-offset-1 col-md-4 hidden" onClick={this.changeProfilePic}>Upload</Button>
                      <Button id="cancelUploadUserPic" className="btn-success react-bs-table-add-btn col-md-offset-2 col-md-4 hidden" onClick={this.cancelChangeProfilePic}>Cancel</Button>
                    </Col>
                  </div>
                </Col>
                <Col md={8} className="mb10">
                  <Row>
                    <Col md={12} className="mb10">
                      <span className="detail-title settings-title">First Name:</span>
                      <span className="detail-data">
                        {this.props.userData['first_name']}
                      </span>
                    </Col>
                    <Col md={12} className="mb10">
                      <span className="detail-title settings-title">Last Name:</span>
                      <span className="detail-data">
                        {this.props.userData['last_name']}
                      </span>
                    </Col>
                    <Col md={12} className="mb10">
                      <span className="detail-title settings-title">Email:</span>
                      <span className="detail-data email">
                        {this.props.userData['email']}
                      </span>
                    </Col>
                    <Col md={12} className="mb10">
                      <span className="detail-title settings-title">Bank Name:</span>
                      <span className="detail-data email">
                        {this.props.userData['bank_name'] || 'N/A'}
                      </span>
                    </Col>
                    <Col md={12} className="mb10">
                      <span className="detail-title settings-title">Account Number:</span>
                      <span className="detail-data email">
                        {this.props.userData['bank_account_number'] || 'N/A'}
                      </span>
                    </Col>
                    <Col md={12} className="mb10">
                      <span className="detail-title settings-title">IFSC:</span>
                      <span className="detail-data email">
                        {this.props.userData['ifsc'] || 'N/A'}
                      </span>
                    </Col>
                    <Col md={12} className="mb10">
                      <span className="detail-title settings-title">UPI:</span>
                      <span className="detail-data email">
                        {this.props.userData['upi'] || 'N/A'}
                      </span>
                    </Col>
                    <Col md={12} className="mb10">
                      <span className="detail-title settings-title">Mobile:</span>
                      <span className="detail-data email">
                        {this.props.userData['mobile'] || 'N/A'}
                      </span>
                    </Col>
                    <Col md={12} className="mb10">
                      <Button className="btn-success react-bs-table-add-btn" onClick={this.onChangeDetailsClick}>Change Details</Button>
                      <Button className="btn-success react-bs-table-add-btn" onClick={this.onChangePasswordClick}>Change Password</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
          </Col>

          {this.state.changePasswordModalVisibility ? (
            <div className="edit-org-details-modal">
              <Modal show={this.state.changePasswordModalVisibility} onHide={this.hideChangePasswordModal.bind(this)} style={{width: 'auto'}}>
                <Modal.Header>
                  <Modal.Title className="modal-header-custom">
                    Change Password
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col className="margin-col-50" md={12}>
                      <form id="change-password-modal-form" ref="vForm">
                        <Col className="form-group" md={12}>
                          <label className="control-label is-imp">Current Password</label>
                          <input type="password" className="form-control" placeholder="Current Password" onChange={this.updateChange.bind(this, "current_password")} />
                          <span hidden={!this.state.show_password_error}><span className="required-span"> Incorrect Password! </span></span>
                        </Col>

                        <Col className="form-group" md={12}>
                          <label className="control-label is-imp">New Password</label>
                          <ReactPasswordStrength minLength={4} minScore={1}
                            className="new-password"
                            scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                            changeCallback={this.changeNewPassword.bind(this)}
                            inputProps={{ name: "password_input", autoComplete: "off", placeholder: "New Password", className: "form-control" }}
                          />
                          <span hidden={!this.state.show_new_password_error}><span className="required-span"> Invalid Password! </span></span>
                        </Col>

                        <Col className="form-group" md={12}>
                          <label className="control-label is-imp">Repeat New Password</label>
                          <input type="password" className="form-control" placeholder="Repeat New Password" onChange={this.updateChange.bind(this, "confirm_password")} />
                          <span hidden={!this.state.show_mismatch_error}><span className="required-span"> Password mismatch! </span></span>
                        </Col>
                      </form>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="footer-center">
                  <Button className="btn btn-danger brand-color-background" onClick={this.hideChangePasswordModal.bind(this)}>Cancel</Button>
                  <Button className="btn btn-warning brand-color-background" onClick={this.changePassword.bind(this)}>Save</Button>
                </Modal.Footer>
              </Modal>
            </div>
          ) : (null)}
        </Row>
      </div>
    );
  }
}