import React, {Component} from 'react';
import {Row, Col ,Button} from 'react-bootstrap';
import Multiselect from 'react-bootstrap-multiselect';
import MultiToggle from 'react-multi-toggle';
import swal from "sweetalert2";
import $ from 'jquery';

import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
import "sweetalert2/dist/sweetalert2.min.css";
import 'react-multi-toggle/style.css';

const readCookie = require('../../cookie.js').readCookie;

export default class AddNewDonor extends Component {
  constructor(props){
    super(props);
    this.state= {
      name : this.props.group_name,
      description : this.props.group_desc,
      list_id: this.props.list_id,
      donors: [],
      donor_ids : this.props.donor_ids,
      showNameError: false,
      showDescError: false,
      showConditionsError: false,
      group_type: 'auto_update',
      conditions: [
        {
          "field": "",
          "field_label": "",
          "field_type": "",
          "data_type": "",
          "operation": "",
          "operations": [],
          "num_of_input_fields": 0
        }
      ],
      match_type: "any",
      fields: []
    }
    this.onChange = this.onChange.bind(this);
    this.getListDonors = this.getListDonors.bind(this);
  }

  onSubmitGroup() {
    let donor_ids = this.state.donor_ids;
    let name = this.state.name;
    let description = this.state.description;
    let list_id = this.state.list_id;
    let group_type = this.state.group_type;
    let match_type = this.state.match_type;

    if(name !== '' && description !== '') {
      if(group_type === 'auto_update') {
        let conditionsError = false;
        let conditions = this.state.conditions.slice();
        for(var i = 0; i < conditions.length; i++) {
          // delete conditions[i].operations;
          // delete conditions[i].field_label;
          // delete conditions[i].num_of_input_fields;
          if(conditions[i].field === '' || conditions[i].field_type === '' || conditions[i].data_type === '' || conditions[i].operation === '') conditionsError = true;
        }

        if(!conditionsError) {
          fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/list/' + list_id + '/segment-members-count', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
            },
            body: JSON.stringify({
              conditions,
              match_type
            })
          }).then(function(response) {
            return response.json();
          }).then(function(data) {
            if(data.status === 'ok') {
              swal({
                title: 'Are you sure?',
                text: "There are total " + data.member_count + "member(s) in this segment!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Save!',
                cancelButtonText: 'No, Cancel!',
                confirmButtonClass: 'btn',
                cancelButtonClass: 'btn',
              }).then(function (result) {
                if(result) {
                  fetch(process.env.REACT_APP_API_URL + '/add_group/' + this.props.ngo['ngo_id'], {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
                    },
                    body: JSON.stringify({
                      name,
                      description,
                      list_id,
                      group_type,
                      conditions,
                      match_type
                    })
                  }).then((response) => response.json())
                  .then((data) => {
                    this.props.onAddGroupClose('refresh');
                  });
                }
              }.bind(this));
            }
          }.bind(this));
        } else this.setState({ showConditionsError: true });

      } else if(group_type === 'manual') {
        fetch(process.env.REACT_APP_API_URL + '/add_group/' + this.props.ngo['ngo_id'], {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify({
            name,
            description,
            list_id,
            donor_ids,
            group_type
          })
        }).then((response) => response.json())
        .then((data) => {
          this.props.onAddGroupClose('refresh');
        });
      }
    } else {
      if(name === '') this.setState({ showNameError: true });
      if(description === '') this.setState({ showDescError: true });
    }
  }

  setname(value) {
    if(value === '') this.setState({ showNameError: true });
    else this.setState({ showNameError: false });
    this.setState({ name : value });
  }

  setdescription(value) {
    if(value === '') this.setState({ showDescError: true });
    else this.setState({ showDescError: false });
    this.setState({ description : value })
  }

  showButtonText(options, select) {
    if (options.length === 0) {
      return 'None Selected';
    } else if (options.length > 2) {
      return options.length + ' options selected!';
    } else {
      let labels = [];
      options.each(function() {
        if ($(this).attr('label') !== undefined) {
          labels.push($(this).attr('label'));
        } else {
          labels.push($(this).html());
        }
      });
      return labels.join(', ') + '';
    }
  }

  onChange = (value) => {
    this.setState({ donor_ids: $(value).parents('select').val() });
  };

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/list/' + this.props.list_id + '/segment-fields', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ fields: data.segment_fields, allFields: data.segment_fields });
    }.bind(this));

    this.getListDonors(this.props.list_id);
  }

  getListDonors(list_id) {
    let query = '';
    if(list_id !== '') query = '?donor_category=' + list_id;
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      var donors = [];
      for(var i = 0; i < data['donors'].length; i++) {
        var donor = {};
        donor['value'] = data['donors'][i]['donor_id'];
        donor['label'] = data['donors'][i]['first_name'] + ' ' + data['donors'][i]['last_name'] + ' (' + data['donors'][i]['email'] + ')';
        donors.push(donor);
      }
      this.setState({ donors, donor_ids: [] });
    }.bind(this));
  }

  onGroupSizeSelect(value) {
    this.setState({ group_type: value });
  }

  handleFieldChange(condition_index, e) {
    let fields = this.state.fields;
    let conditions = this.state.conditions;
    let selectedDataType;
    for(var i = 0; i < fields.length; i++) {
      if(fields[i].value === conditions[condition_index].field) {
        fields[i]['used'] = false;
      }
    }
    for(i = 0; i < fields.length; i++) {
      if(fields[i].value === e.target.value) {
        // fields[i]['used'] = true;
        conditions[condition_index].field = fields[i].value;
        conditions[condition_index].field_label = fields[i].label;
        conditions[condition_index].field_type = fields[i].field_type;
        conditions[condition_index].data_type = fields[i].data_type;
        conditions[condition_index].operation = "";
        selectedDataType = fields[i].data_type;
      }
    }
    fetch(process.env.REACT_APP_API_URL + '/segment-operations/' + selectedDataType, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      conditions[condition_index].operations = data.operations;
      this.setState({ fields, conditions });
    }.bind(this));
  }

  handleOperationChange(condition_index, e) {
    let conditions = this.state.conditions;
    conditions[condition_index].operation = e.target.value;
    for(var i = 0; i < conditions[condition_index].operations.length; i++) {
      if(conditions[condition_index].operations[i].value === e.target.value) {
        conditions[condition_index].num_of_input_fields = conditions[condition_index].operations[i].num_of_input_fields;
      }
    }
    this.setState({ conditions });
  }

  addCondition() {
    let conditions = this.state.conditions;
    let condition = {
      "field": "",
      "field_label": "",
      "field_type": "",
      "data_type": "",
      "operation": "",
      "operations": [],
      "num_of_input_fields": 0
    };

    conditions.push(condition);
    this.setState({ conditions });
  }

  removeCondition(condition_index) {
    let conditions = this.state.conditions;
    conditions.splice(condition_index, 1);
    this.setState({ conditions });
  }

  changeValue(condition_index, index, e) {
    let conditions = this.state.conditions;
    let values = [];
    if(conditions[condition_index].hasOwnProperty('values')) values = conditions[condition_index].values;
    values[index] = e.target.value;
    conditions[condition_index].values = values;
    this.setState({ conditions });
  }

  matchTypeChange(e) {
    this.setState({ match_type: e.target.value });
  }

  render () {
    let group_type_options = [
      {
        displayName: 'Auto Update',
        value: 'auto_update'
      }, {
        displayName: 'Manual',
        value: 'manual'
      }
    ];
    if(this.state.list_id !== null) {
      return (
        <div className="margin-col-10 add-new-group">
          <Row>
            <Col md={12} className="no-pad-left no-pad-right">
              <p className="modal-sec-title red">DETAILS OF SEGMENT </p>
            </Col>

            <Col className="form-group" md={12}>
              <label className="control-label is-imp">Name Of The Segment:</label>
              <input type="text" className="form-control"
                id="last_name" name="last_name" value={this.state.name}
                onChange={(e) => {this.setname(e.target.value)}}
                placeholder="Segment 1" required/>
              <span hidden={!this.state.showNameError}><span className="required-span">Please enter a valid Name.</span></span>
            </Col>

            <Col className="form-group" md={12}>
              <label className="control-label is-imp">Segment Description:</label>
              <textarea cols="40" rows="3" className="form-control"
                id="addNewGroupDescription" name="description" value={this.state.description}
                onChange={(e) => {this.setdescription(e.target.value)}}
                placeholder="Segment description" required/>
              <span hidden={!this.state.showDescError}><span className="required-span">Please enter a valid Description.</span></span>
            </Col>

            <Col className="form-group" md={12}>
              <MultiToggle options={group_type_options}
                selectedOption={this.state.group_type}
                onSelectOption={this.onGroupSizeSelect.bind(this)}
                label="Select Segment Type"
              />
            </Col>

            {this.state.group_type === 'manual' ? (
              <Col className="form-group" md={12}>
                <label className="control-label">Tag Members:</label>
                <div>
                  <Multiselect id="addDonorToGroup" disabled={this.state.list_id === '' ? true : false} buttonClass="addDonorToGroup btn btn-default" includeSelectAllOption={true}
                    onChange={this.onChange.bind(this)} buttonText={this.showButtonText} multiple enableCaseInsensitiveFiltering={true}
                    buttonWidth={'100%'} data={this.state.donors} maxHeight={200} enableFiltering={true} />
                </div>
              </Col>
            ) : (null)}

            {this.state.group_type === 'auto_update' ? (
              <Col className="form-group conditionals-container" md={12}>
                <span className="match-type-span">
                  Members match
                  <select className="match-type-select" defaultValue={this.state.match_type} onChange={this.matchTypeChange.bind(this)}>
                    <option value="all">all</option>
                    <option value="any">any</option>
                  </select>
                  of the following conditions:
                </span>
                <label className="control-label">Select conditionals:</label>
                {this.state.conditions.map(function(condition, condition_index) {
                  return (
                    <div className="condition-container" key={condition_index}>
                      <div className="btn remove-condition-container" onClick={this.removeCondition.bind(this, condition_index)}><i className="fa fa-minus-square-o"></i></div>
                      <select className="form-control" value={condition.field}
                        onChange={this.handleFieldChange.bind(this, condition_index)}>
                        <option disabled value="">Select Field</option>
                        {condition.field !== '' ? (
                          <option disabled value={condition.field}>{condition.field_label}</option>
                        ) : (null)}
                        {this.state.fields.map(function(field, index) {
                          if(!field.hasOwnProperty('used')) {
                            return <option key={index} value={field.value}>{field.label}</option>
                          } else if(field.hasOwnProperty('used') && !field.used) {
                            return <option key={index} value={field.value}>{field.label}</option>
                          }
                        })}
                      </select>
                      {condition.field !== '' && condition.hasOwnProperty('operations') ? (
                        <select className="form-control" value={condition.operation}
                          onChange={this.handleOperationChange.bind(this, condition_index)}>
                          <option disabled value="">Select Operation</option>
                          {condition.operations.map(function(operation, index) {
                            return <option key={index} value={operation.value}>{operation.label}</option>
                          })}
                        </select>
                      ) : (null)}
                      {condition.field !== '' && condition.operation !== '' ? (() => {
                        if(condition.data_type === 'Text') {
                          return (() => {
                            var a = [];
                            for (var i = 0; i < condition.num_of_input_fields; i++) {
                              a.push(i);
                            }
                            return a.map(function(x, index) {
                              return <input className="form-control" key={index} type="text" onChange={this.changeValue.bind(this, condition_index, index)} />
                            }.bind(this))
                          })()
                        } else if(condition.data_type === 'Number') {
                          return (() => {
                            var a = [];
                            for (var i = 0; i < condition.num_of_input_fields; i++) {
                              a.push(i);
                            }
                            return a.map(function(x, index) {
                              return <input className="form-control" key={index} type="number" onChange={this.changeValue.bind(this, condition_index, index)} />
                            }.bind(this))
                          })()
                        } else if(condition.data_type === 'Date') {
                          return (() => {
                            var a = [];
                            for (var i = 0; i < condition.num_of_input_fields; i++) {
                              a.push(i);
                            }
                            return a.map(function(x, index) {
                              return <input className="form-control" key={index} type="date" onChange={this.changeValue.bind(this, condition_index, index)} />
                            }.bind(this))
                          })()
                        }
                      })() : (null)}
                    </div>
                  )
                }.bind(this))}
                <div className="btn add-condition-container" onClick={this.addCondition.bind(this)}><i className="fa fa-plus-square-o"></i>Add</div>
                <span hidden={!this.state.showConditionsError}><span className="required-span">Please enter valid Conditions.</span></span>
              </Col>
            ) : (null)}
          </Row>

          <Row>
            <div className="form-group btn-container" >
              <Button onClick={this.props.onAddGroupClose} className="btn btn-danger brand-color-background">
                Cancel
              </Button>
              <Button className="btn btn-warning brand-color-background" id="submit_group" onClick={() => { this.onSubmitGroup() }}>
                Add New Segment
                <span className="glyphicon glyphicon-send"></span>
              </Button>
            </div>
          </Row>
        </div>
      );
    } else return null;
  }
}