import moment from 'moment';
import swal from 'sweetalert2';
import { textbox, textarea, locationElement, areaMapElement, distanceElement, scaleInput, barcodeInput, signatureInput, name, choiceCheckbox, paymentRadioInput, paymentDropdownInput, choiceRadio, choiceDropdown, coRelationDropdownInput, address, yesno, phone, date, time, email, number, website, currency, rating, file, header, content } from './viewElements';

const $ = window.$;

export function createFormToView(form) {
	if(form !== undefined) {
		var formSubmissionSetting = form['submission_settings'];
		// Form Details
		var formDetails = form['form_details'];
		$('._form-holder ._form-details p._form-name-element').html(formDetails['title']);

		// Form Header
		var formHeader = form['form_header'];
		if(formHeader !== null && typeof formHeader === 'object' && formHeader.length === undefined) {
			var headerContainer = $('._form-holder ._header-container-view');
			$(headerContainer).append(header);
			$(headerContainer).find('._form-element').first().find('h4').html(formHeader['headerTitle']);
			$(headerContainer).find('._form-element').first().find('h5').html(formHeader['headerSubtitle']);
			if(formHeader['headerBackground'] !== '') {
				$('._form-holder ._header-container-view').find('._form-element').first().find('._form-header').css('background', 'url(' + formHeader['headerBackground'] + ')');
			} else {
				$('._form-holder ._header-container-view').find('._form-element').first().find('._form-header').css('background', '#f1f1f1');
			}
		}

		// Form Elements
		var formContainer = $('._form-holder ._element-container-view');
		var elementIndex = 0;

		var formElements = form['form_element'];

		var pagebreakCount = 0;

		for(var i = 0; i < formElements.length; i++) {
			if(formElements[i]['type'] == 'pagebreak') pagebreakCount += 1;
		}

		for(var i = 0; i <= pagebreakCount; i++) {
			if(i < pagebreakCount) {
				if(i == 0) $(formContainer).append('<div class="_form-page" id="form-page-' + i + '"><div class="_page-elements"></div><div class="container"><a class="grey-text text-lighten-4 btn waves-effect waves-light right" id="next-page">Next</a></div></div>');
				else $(formContainer).append('<div class="_form-page" id="form-page-' + i + '"><div class="_page-elements"></div><div class="container"><a class="grey-text text-lighten-4 btn waves-effect waves-light" id="prev-page">Back</a><a class="grey-text text-lighten-4 btn waves-effect waves-light right" id="next-page">Next</a></div></div>');
			} else if (i == pagebreakCount) {
				if(i == 0) $(formContainer).append('<div class="_form-page" id="form-page-' + i + '"><div class="_page-elements"></div><div class="container"><a class="grey-text text-lighten-4 btn waves-effect waves-light right" id="submit-page">Submit</a></div></div>');
				else $(formContainer).append('<div class="_form-page" id="form-page-' + i + '"><div class="_page-elements"></div><div class="container"><a class="grey-text text-lighten-4 btn waves-effect waves-light" id="prev-page">Back</a><a class="grey-text text-lighten-4 btn waves-effect waves-light right" id="submit-page">Submit</a></div></div>');
			}
		}

		var pageCount = 0, autocompletes = {};
		for(var i = 0; i < formElements.length; i++) {
			var selectedFormElement = formElements[i];

			if (selectedFormElement['type'] == 'textbox') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(textbox);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="textbox"]').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="textbox"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#textbox').attr('placeholder', selectedFormElement['placeholder']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'textarea') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(textarea);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="textarea"]').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="textarea"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('textarea#textarea').attr('placeholder', selectedFormElement['placeholder']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'location') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(locationElement);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="locationLabel"]').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="locationLabel"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
				if(selectedFormElement['locationType'] == 'searchbox') {
					var locationElem = '<input type="text" id="locationLabel" class="validate browser-default form-control" placeholder="Input text here.." />';
					$(locationElem).insertAfter($(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('.location-container label'));
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('.location-container input#locationLabel').val(window['locationSearchBox' + i]);
					var $location = $(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('.location-container input#locationLabel');
					var autocomplete = new window.google.maps.places.Autocomplete($location[0]);
					autocompletes[selectedFormElement['index']] = autocomplete;
				} else if(selectedFormElement['locationType'] == 'mappointer') {
					var locationElem = '<div id="location-map'+ selectedFormElement['index'] + '" style="height: 250px;"></div>';
					$(locationElem).insertAfter($(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('.location-container label'));
					var latLng = {lat: parseFloat(window['locationMapPointer' + i]['lat']), lng: parseFloat(window['locationMapPointer' + i]['lng'])};
					var map = new window.google.maps.Map(
						// $(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('.location-container label').find('#location-map'), {zoom: 5, center: latLng}
						document.getElementById('location-map' + selectedFormElement['index']), {zoom: 5, center: latLng}
					);
					var marker = new window.google.maps.Marker({position: latLng, map: map, draggable: true, animation: window.google.maps.Animation.DROP});
					marker.addListener('dragend', function (event) {
						latLng = {lat: event.latLng.lat(), lng: event.latLng.lng()};
						$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index='+ selectedFormElement['index'] + ']').find('.location-container').find('#location-map' + selectedFormElement['index']).attr('data-lat', latLng['lat']);
						$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index='+ selectedFormElement['index'] + ']').find('.location-container').find('#location-map' + selectedFormElement['index']).attr('data-lng', latLng['lng']);
					});
					window.google.maps.event.addListener(map, 'click', function(event) {
						if (marker) {
							marker.setPosition(event.latLng)
						} else {
							marker = new window.google.maps.Marker({
								map: map,
								draggable: true,
								position: event.latLng,
								animation: window.google.maps.Animation.DROP
							});
						}
						latLng = {lat: event.latLng.lat(), lng: event.latLng.lng()};
						$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index='+ selectedFormElement['index'] + ']').find('.location-container').find('#location-map' + selectedFormElement['index']).attr('data-lat', latLng['lat']);
						$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index='+ selectedFormElement['index'] + ']').find('.location-container').find('#location-map' + selectedFormElement['index']).attr('data-lng', latLng['lng']);
					});
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index='+ selectedFormElement['index'] + ']').find('.location-container').find('#location-map' + selectedFormElement['index']).attr('data-lat', latLng['lat']);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index='+ selectedFormElement['index'] + ']').find('.location-container').find('#location-map' + selectedFormElement['index']).attr('data-lng', latLng['lng']);
				}
			} else if(selectedFormElement['type'] == 'areamap') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(areaMapElement);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="area_mapLabel"]').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="area_mapLabel"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);

				var locationElem = '<div id="area_map"></div>';
				$(locationElem).insertAfter($(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('.area_map-container label'));
				var latLng = {lat: 20.5937, lng: 78.9629};
				var map = new window.google.maps.Map(
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('.area_map-container').find('#area_map'), {zoom: 5, center: latLng}
				);
				var coords = [
					{lat: 11.9716, lng: 77.5946},
					{lat: 13.9716, lng: 76.5946},
					{lat: 15.9716, lng: 78.5946},
					{lat: 17.9716, lng: 79.5946}
			  ];

			  // Construct the polygon.
			  var editablePolygon = new window.google.maps.Polygon({
					paths: coords,
					strokeColor: '#ef5a20',
					strokeOpacity: 0.5,
					strokeWeight: 2,
					fillColor: '#ef5a20',
					fillOpacity: 0.35,
					editable: true
			  });
			  editablePolygon.setMap(map);
			} else if(selectedFormElement['type'] == 'distance') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(distanceElement);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="distanceLabel"]').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="distanceLabel"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);

				var locationElem = '<div id="distance-map-'+selectedFormElement['index']+'" class="distance-map"></div>';
				$(locationElem).insertAfter($(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index=' + selectedFormElement['index'] + ']').find('.distance-container label'));
				if(!$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index=' + selectedFormElement['index'] + ']').find('.distance-container #distance-legend').length){
					var distanceLegend = '<div id="distance-legend"></div>';
					$(distanceLegend).insertAfter($(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index=' + selectedFormElement['index'] + ']').find('.distance-container #distance-map-'+selectedFormElement['index']));
				}
				console.log(window['distacePointers' + i])
				var latLng = {lat: parseFloat(window['distacePointers' + i]['latLng1']['lat']), lng: parseFloat(window['distacePointers' + i]['latLng1']['lng'])};
				var latLng2 = {lat: parseFloat(window['distacePointers' + i]['latLng2']['lat']), lng: parseFloat(window['distacePointers' + i]['latLng2']['lng'])};

				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index=' + selectedFormElement['index'] + ']').find('.distance-container #distance-legend').html('Distance: '+ window['distacePointers' + i]['distance']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index=' + selectedFormElement['index'] + ']').find('.distance-container #distance-legend').attr('data-latLng', JSON.stringify(latLng));
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index=' + selectedFormElement['index'] + ']').find('.distance-container #distance-legend').attr('data-latLng2', JSON.stringify(latLng2));
				var map = new window.google.maps.Map(
					document.getElementById('distance-map-'+selectedFormElement['index']), {zoom: 5, center: latLng}
				);
				var marker = new window.google.maps.Marker({position: latLng, map: map, draggable: true, animation: window.google.maps.Animation.DROP, label: 'A'});
				var marker2 = new window.google.maps.Marker({position: latLng2, map: map, draggable: true, animation: window.google.maps.Animation.DROP, label: 'B'});
				var polyline = new window.google.maps.Polyline({
					path: [latLng, latLng2],
					geodesic: true,
					strokeColor: '#ef5a20',
					strokeOpacity: 0.9,
					strokeWeight: 2
				});
				polyline.setMap(map);
				marker.addListener('dragend', function (event) {
					latLng = {lat: event.latLng.lat(), lng: event.latLng.lng()};
					var path = [latLng, latLng2];
					polyline.setPath(path);
					let distance = getDistance(latLng['lat'], latLng['lng'], latLng2['lat'], latLng2['lng']);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index=' + selectedFormElement['index'] + ']').find('.distance-container #distance-legend').html('Distance: '+ distance);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index=' + selectedFormElement['index'] + ']').find('.distance-container #distance-legend').attr('data-latLng', JSON.stringify(latLng));
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index=' + selectedFormElement['index'] + ']').find('.distance-container #distance-legend').attr('data-latLng2', JSON.stringify(latLng2));
				});
				marker2.addListener('dragend', function (event) {
					latLng2 = {lat: event.latLng.lat(), lng: event.latLng.lng()};
					var path = [latLng, latLng2];
					polyline.setPath(path);
					let distance = getDistance(latLng['lat'], latLng['lng'], latLng2['lat'], latLng2['lng']);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index=' + selectedFormElement['index'] + ']').find('.distance-container #distance-legend').html('Distance: '+ distance);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index=' + selectedFormElement['index'] + ']').find('.distance-container #distance-legend').attr('data-latLng', JSON.stringify(latLng));
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element[data-index=' + selectedFormElement['index'] + ']').find('.distance-container #distance-legend').attr('data-latLng2', JSON.stringify(latLng2));
				});
			} else if(selectedFormElement['type'] == 'correlation') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(coRelationDropdownInput);
				var relationElementOption = '';
				if(selectedFormElement['relationPlaceholder']){
					relationElementOption = '<option value="'+ selectedFormElement['relationPlaceholder'] +'" selected disabled>'+ selectedFormElement['relationPlaceholder'] + '</option>';
				}

				if(selectedFormElement['selectedRelationalResponses'].length){
					for(var j=0; j < selectedFormElement['selectedRelationalResponses'].length; j++){
						relationElementOption += '<option value="'+ selectedFormElement['selectedRelationalResponses'][j] +'">'+ selectedFormElement['selectedRelationalResponses'][j] + '</option>';
					}
				}

				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').find('._relation-container').find('._relation').find('select#relationSelect').last().empty();
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').find('._relation-container').find('._relation').find('select#relationSelect').last().append(relationElementOption);

				if(selectedFormElement['selectMultiple']){
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').find('._relation-container').find('._relation').find('select#relationSelect').attr('multiple', 'multiple')
				}

				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-selectedrelationalelement', selectedFormElement['selectedRelationalElement']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-selectresponses', selectedFormElement['selectedRelationalResponses']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="relationLabel"]').html(selectedFormElement['relationLabel']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="relationLabel"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'scale') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(scaleInput);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="scale-element"]').html(selectedFormElement['scaleLabel']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#scale-element').val(selectedFormElement['scaleCount']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="scale-element"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="scale-element"]').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'signature') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(signatureInput);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="signature-element"]').html(selectedFormElement['signatureLabel']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="signature-element"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="signature-element"]').attr('data-required', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			}  else if(selectedFormElement['type'] == 'barcode') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(barcodeInput);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="barcode-element"]').html(selectedFormElement['barcodeLabel']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="signature-element"]').attr('data-error', selectedFormElement['error']);
				// $(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="signature-element"]').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'name') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(name);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="first_name"]').html(selectedFormElement['fields'][0]['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="last_name"]').html(selectedFormElement['fields'][1]['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="first_name"]').attr('data-error', selectedFormElement['fields'][0]['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="last_name"]').attr('data-error', selectedFormElement['fields'][1]['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#first_name').attr('placeholder', selectedFormElement['fields'][0]['placeholder']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#last_name').attr('placeholder', selectedFormElement['fields'][1]['placeholder']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'choice') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(choiceCheckbox);
				var newChoiceOptions = selectedFormElement['newChoiceOptions'];
				var choiceType = selectedFormElement['choiceType'];
				if(choiceType == 'checkbox') {
					var $choiceCheckbox = $(choiceCheckbox);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice-container').html($choiceCheckbox.find('._choice-container').html());
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice-container').find('input').attr('id', 'option-1-' + selectedFormElement['index']);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice-container').find('label[for^="option-1"]').attr('for', 'option-1-' + selectedFormElement['index']);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice-container').find('label[for="option-1-' + selectedFormElement['index'] + '"]').text(selectedFormElement['choicePlaceholder1']);
					for(var j = 0; j < newChoiceOptions.length; j++) {
						$('<div class="col s4 _choice"><input type="checkbox" class="filled-in" id="option-' + String(j + 2) + '-' + selectedFormElement['index'] + '" /><label for="option-' + String(j + 2) + '-' + selectedFormElement['index'] + '">' + newChoiceOptions[j] + '</label></div>').insertAfter($(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice').last());
					}
				} else if(choiceType == 'radio') {
					var $choiceRadio = $(choiceRadio);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice-container').html($choiceRadio.find('._choice-container').html());
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice-container').find('input').attr('id', 'option-1-' + selectedFormElement['index']);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice-container').find('input#option-1-' + selectedFormElement['index']).attr('name', 'choiceRadio-' + selectedFormElement['index']);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice-container').find('label[for="option-1"]').attr('for', 'option-1-' + selectedFormElement['index']);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice-container').find('label[for="option-1-' + selectedFormElement['index'] + '"]').text(selectedFormElement['choicePlaceholder1']);
					for(var j = 0; j < newChoiceOptions.length; j++) {
						$('<div class="col s4 _choice"><input name="choiceRadio-' + selectedFormElement['index'] + '" type="radio" class="with-gap" id="option-' + String(j + 2) + '-' + selectedFormElement['index'] + '" /><label for="option-' + String(j + 2) + '-' + selectedFormElement['index'] + '">' + newChoiceOptions[j] + '</label></div>').insertAfter($(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice').last());
					}
				} else if(choiceType == 'dropdown') {
					var $choiceDropdown = $(choiceDropdown);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice-container').html($choiceDropdown.find('._choice-container').html());
					for(var j = 0; j < newChoiceOptions.length; j++) {
						$('<option id="option-' + String(j + 2) + '">' + newChoiceOptions[j] + '</option>').insertAfter($(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._choice').find('option').last());
					}
				}
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['choiceType']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-childindex', selectedFormElement['childIndex']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-childanswer', selectedFormElement['childAnswer']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('span#choiceLabel').html(selectedFormElement['choiceLabel']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);

				if(choiceType == 'dropdown') $(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('option#option-1').html(selectedFormElement['choicePlaceholder1']);
				else $(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="option-1"]').html(selectedFormElement['choicePlaceholder1']);
			} else if(selectedFormElement['type'] == 'payment') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(paymentRadioInput);
			  var newpaymentOptions = selectedFormElement['newpaymentOptions'];
			  var paymentType = selectedFormElement['paymentType'];
			  if(paymentType == 'radio') {
					var $paymentRadioInput = $(paymentRadioInput);
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._payment-container').html($paymentRadioInput.find('._payment-container').html());
					if(selectedFormElement['productValue1'] !== ''){
						var html1 = '<div class="_product-label-container"><input name="paymentRadio' + '-' + selectedFormElement['index'] + '" type="radio" class="with-gap" id="option' + '-1' + '-' + selectedFormElement['index'] + '" /><label for="option-1' + '-' + selectedFormElement['index'] + '">' + selectedFormElement['productLabel1'] +'(Rs. ' + selectedFormElement['productValue1'] +')' + '</label></div>';
					} else {
						var html1 = '<div class="_product-label-container"><input name="paymentRadio' + '-' + selectedFormElement['index'] + '" type="radio" class="with-gap" id="option' + '-1' + '-' + selectedFormElement['index'] + '" /><label for="option-1' + '-' + selectedFormElement['index'] + '">' + selectedFormElement['productLabel1'] +'</label></div>';
					}
					$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('.products-container > .product-container').empty().append(html1);
					for(var j = 0; j < newpaymentOptions.length; j++) {
						var stringHtml = '';
						if(newpaymentOptions[j]['productValue'] !== ''){
							stringHtml = '<label for="option-' + String(j + 2) + '-' + selectedFormElement['index'] + '">' + newpaymentOptions[j]['productLabel'] +'(Rs. ' + newpaymentOptions[j]['productValue'] +')' + '</label>'
						} else {
							stringHtml = '<label for="option-' + String(j + 2) + '-' + selectedFormElement['index'] + '">' + newpaymentOptions[j]['productLabel'] + '</label>'
						}
						var html = '<div class="product-container"><div class="_product-label-container"><input name="paymentRadio-' + selectedFormElement['index'] + '" type="radio" class="with-gap" id="option-' + String(j + 2) + '-' + selectedFormElement['index'] + '" />' + stringHtml +'</div></div>';
						$(html).insertAfter($(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('.products-container > .product-container').last());
					}
			  }
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-childindex', selectedFormElement['childIndex']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-childanswer', selectedFormElement['childAnswer']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="payment"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('span#paymentLabel').html(selectedFormElement['paymentLabel']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
				if(choiceType == 'dropdown') $(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('option#option-1').html(selectedFormElement['choicePlaceholder1']);
				else $(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="option-1"]').html(selectedFormElement['choicePlaceholder1']);
			} else if(selectedFormElement['type'] == 'yesno') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(yesno);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="yesnoPlaceholder1"]').attr('for', 'yesnoPlaceholder1-' + selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="yesnoPlaceholder2"]').attr('for', 'yesnoPlaceholder2-' + selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#yesnoPlaceholder1').attr('id', 'yesnoPlaceholder1-' + selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#yesnoPlaceholder2').attr('id', 'yesnoPlaceholder2-' + selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#yesnoPlaceholder1-' + selectedFormElement['index']).attr('name', 'yesnoPlaceholder-' + selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#yesnoPlaceholder2-' + selectedFormElement['index']).attr('name', 'yesnoPlaceholder-' + selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-childindex', selectedFormElement['childIndex']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-childanswer', selectedFormElement['childAnswer']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="yesnoPlaceholder1-' + selectedFormElement['index'] + '"]').html(selectedFormElement['yesnoPlaceholder1']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="yesnoPlaceholder2-' + selectedFormElement['index'] + '"]').html(selectedFormElement['yesnoPlaceholder2']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('span#yesnoLabel').html(selectedFormElement['yesnoLabel']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'address') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(address);
				var $element = $(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last();
				// $.get('https://test.letsendorse.com/getlocation?type=0').then(function(data) {
				// 	for(var j = 0; j < data.length; j++) {
				// 		$($element).find('select#country').append('<option value="' + data[j]['name'] + '" countryId="' + data[j]['id'] + '">' + data[j]['name'] + '</option>');
				// 	}
				// });
				$($element).find('select#country').change(function() {
					var $selectedCountry = $(this).find(':selected');
					var countryId = $selectedCountry.attr('countryId');
					$.get('https://test.letsendorse.com/getlocation?type=1&countryId=' + countryId).then(function(data) {
						for(var k = 0; k < data.length; k++) {
							$($element).find('select#state').append('<option value="' + data[k]['name'] + '" stateId="' + data[k]['id'] + '">' + data[k]['name'] + '</option>');
						}
					});
				});
				$($element).find('select#state').change(function() {
					var $selectedState = $(this).find(':selected');
					var stateId = $selectedState.attr('stateId');
					$.get('https://test.letsendorse.com/getlocation?type=2&stateId=' + stateId).then(function(data) {
						for(var l = 0; l < data.length; l++) {
							$($element).find('select#city').append('<option value="' + data[l]['name'] + '" cityId="' + data[l]['id'] + '">' + data[l]['name'] + '</option>');
						}
					});
				});

				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#zipcode').attr('placeholder', selectedFormElement['pincodePlaceholder']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#area').attr('placeholder', selectedFormElement['areaPlaceholder']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#street').attr('placeholder', selectedFormElement['streetPlaceholder']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('span#addressLabel').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="zipcode"]').html(selectedFormElement['pincodeLabel']);
				var areaLabel = '';
				if(selectedFormElement['geoLocation']) {
					areaLabel = 'Location';
					var $location = $(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#area');
					var autocomplete = new window.google.maps.places.Autocomplete($location[0]);
					autocomplete.addListener('place_changed', function(){
						var place = autocomplete.getPlace();
						var lat = place.geometry.location.lat();
						var lng = place.geometry.location.lng();
						$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#area').attr('data-lat', lat);
						$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#area').attr('data-lng', lng);
					});
				} else {
					areaLabel = selectedFormElement['areaLabel'];
				}
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="area"]').html(areaLabel);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="street"]').html(selectedFormElement['streetLabel']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="pincode"]').attr('data-error', selectedFormElement['pincodeError']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="area"]').attr('data-error', selectedFormElement['areaError']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="street"]').attr('data-error', selectedFormElement['streetError']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'phone') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(phone);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find("#phone").intlTelInput();
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="phone"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('#phoneLabel').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'date') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(date);
				$('.datepicker').pickadate({
					selectMonths: true, // Creates a dropdown to control month
					selectYears: 15 // Creates a dropdown of 15 years to control year
				});
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="datepicker"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('#dateLabel').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'time') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(time);
				$('.timepicker').pickatime({
					default: 'now', // Set default time
					fromnow: 0,       // set default time to * milliseconds from now (using with default = 'now')
					twelvehour: false, // Use AM/PM or 24-hour format
					donetext: 'OK', // text for done-button
					cleartext: 'Clear', // text for clear-button
					canceltext: 'Cancel', // Text for cancel-button
					autoclose: false, // automatic close timepicker
					ampmclickable: true, // make AM PM clickable
					aftershow: function(){} //Function for after opening timepicker
				});
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="timepicker"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('#timeLabel').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'email') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(email);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="email"]').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="email"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#email').attr('placeholder', selectedFormElement['placeholder']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'number') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(number);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="number"]').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="number"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#number').attr('placeholder', selectedFormElement['placeholder']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'website') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(website);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="website"]').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label[for="website"]').attr('data-error', selectedFormElement['error']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#website').attr('placeholder', selectedFormElement['placeholder']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'currency') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(currency);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('span#currencyLabel').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('label._currency-symbol').html(selectedFormElement['currencySymbol']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('input#currency').attr('placeholder', selectedFormElement['placeholder']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'rating') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(rating);
				$(".my-rating").starRating({
					totalStars: parseInt(selectedFormElement.ratingCount),
					starSize: 25,
					disableAfterRate: false,
				});
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('#ratingLabel').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find(".my-rating").starRating('unload');
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
				$('<div class="my-rating"></div>').insertAfter($(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('#ratingLabel'));
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find(".my-rating").starRating({
					starSize: 25,
					disableAfterRate: false,
					totalStars: selectedFormElement['ratingCount']
				});
			} else if(selectedFormElement['type'] == 'file') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(file);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-valid', false);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('title', selectedFormElement['helpText']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-requiredparent', selectedFormElement['required']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-index', selectedFormElement['index']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-hasparent', selectedFormElement['hasParent']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('#fileLabel').html(selectedFormElement['label']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('#fileType').attr('accept', selectedFormElement['fileType']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('._required-star').attr('data-required', selectedFormElement['required']);
			} else if(selectedFormElement['type'] == 'pagebreak') {
				pageCount += 1;
			} else if(selectedFormElement['type'] == 'content') {
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').append(content);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-questionid', selectedFormElement['question_id']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().attr('data-type', selectedFormElement['type']);
				$(formContainer).find('._form-page#form-page-' + pageCount).find('._page-elements').find('._form-element').last().find('p').html(selectedFormElement['contentText']);
			}
		}

		function getDistance(lat1, lon1, lat2, lon2) {
			var R = 6371e3; // metres
			var φ1 = lat1 * Math.PI / 180;
			var φ2 = lat2 * Math.PI / 180;
			var Δφ = (lat2-lat1) * Math.PI / 180;
			var Δλ = (lon2-lon1) * Math.PI / 180;

			var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
							Math.cos(φ1) * Math.cos(φ2) *
							Math.sin(Δλ/2) * Math.sin(Δλ/2);
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
			var d = R * c;

			if(d > 1000) d = (parseFloat(Number(d/1000).toFixed(2))).toLocaleString() + ' km';
			else d = (parseFloat(Number(d).toFixed(2))).toLocaleString() + ' m';
			return d;
		}

		function checkRequired(currentPage) {
			if(!currentPage) {
				currentPage = 0;
			}
			var formContainer = $('._form-holder ._element-container-view');
			var allElements = $('._element-container-view ._form-element');
			var formElements = form['formElements'];
			var requiredElements = $(formContainer).find('._form-page#form-page-' + currentPage).find('._form-element[data-requiredparent="true"]');
			var requiredElementsCount = $(formContainer).find('._form-page#form-page-' + currentPage).find('._form-element[data-requiredparent="true"]').length;
			var pagesLength = $(formContainer).find('._form-page').length;
			pagesLength -= 1;
			var validElementsCount = 0;
			var pageValid = false;

			requiredElements.each(function() {
				if($(this).attr('data-valid') === 'true') {
					validElementsCount++;
				} else {
					return false;
				}
			});

			if(validElementsCount === requiredElementsCount) {
				pageValid = true;
			}

			if(pageValid) {
				if(currentPage == pagesLength) {
					$('#submit-page').attr('disabled', false);
				} else {
					$(formContainer).find('._form-page#form-page-' + currentPage).find('#next-page').attr('disabled', false);
				}
			} else {
				if(currentPage == pagesLength) {
					$('#submit-page').attr('disabled', true);
				} else {
					$(formContainer).find('._form-page#form-page-' + currentPage).find('#next-page').attr('disabled', true);
				}
			}
		}

		$(document).ready(function() {
			$('select#relationSelect').val('');
			$('select#relationSelect').multiselect({
				nonSelectedText: 'None Selected'
			});
			$('#smoothed').each(function() {
				var index = $(this).parents('._form-element').attr('data-index');
				window['signaturepad-' + index] = $(this).signaturePad({
				  drawOnly:true,
				  drawBezierCurves:true,
				  lineTop:200
				});
			});

			$('#submit-page').text(formSubmissionSetting['button_text']);
			$('#next-page').attr('disabled', true);
			$('#submit-page').attr('disabled', true);
			$('._form-element[data-hasparent=true]').hide();


			$('._element-container-view').find('._form-page').each(function() {
				$(this).find('._form-element').each(function() {
					if($(this).data('requiredparent') === true) {
						$(this).attr('data-valid', false);
					}
				});
			});

			var requiredFields = $(formContainer).find('._form-page').find('._form-element[data-requiredparent="true"]');
			var parentFields = $(formContainer).find('._form-page').find('._form-element[data-isparent="true"]');

			// parentFields.each(function() {
			// 	if($(this).attr('data-type') === 'checkbox') {
			// 		$(this).find('input[type=checkbox]').on('change', function() {
			// 			if($(this).prop('checked', true).next('label').text() == $(this).closest('._form-element').attr('data-childanswer')) {
			// 				$('._form-element[data-index=' + $(this).closest('._form-element').attr('data-childindex') + ']').show();
			// 			} else {
			// 				$('._form-element[data-index=' + $(this).closest('._form-element').attr('data-childindex') + ']').hide();
			// 			}
			// 		});
			// 	} else if($(this).attr('data-type') === 'radio') {
			// 		$(this).find('input[type=radio]').on('change', function() {
			// 			if($(this).prop('checked', true).next('label').text() == $(this).closest('._form-element').attr('data-childanswer')) {
			// 				$('._form-element[data-index=' + $(this).closest('._form-element').attr('data-childindex') + ']').show();
			// 			} else {
			// 				$('._form-element[data-index=' + $(this).closest('._form-element').attr('data-childindex') + ']').hide();
			// 			}
			// 		});
			// 	} else if ($(this).attr('data-type') === 'payment') {
			// 	  $(this).find('input[type=radio]').on('change', function() {
			// 		  var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
			// 		  if($(this).prop('checked', true)) {
			// 			$(this).closest('._form-element').attr('data-valid', true);
			// 		  } else {
			// 			$(this).closest('._form-element').attr('data-valid', false);
			// 		  }
			// 		  checkRequired(pageId);
			// 	  });
			// 	} else if($(this).attr('data-type') === 'dropdown') {
			// 		$(this).find('select#choiceSelect').on('change', function() {
			// 			if($(this).val() == $(this).closest('._form-element').attr('data-childanswer')) {
			// 				$('._form-element[data-index=' + $(this).closest('._form-element').attr('data-childindex') + ']').show();
			// 			} else {
			// 				$('._form-element[data-index=' + $(this).closest('._form-element').attr('data-childindex') + ']').hide();
			// 			}
			// 		});
			// 	} else if($(this).attr('data-type') === 'yesno') {
			// 		$(this).find('input[name^=yesnoPlaceholder]').on('change', function() {
			// 			if($(this).prop('checked', true).next('label').text() == $(this).closest('._form-element').attr('data-childanswer')) {
			// 				$('._form-element[data-index=' + $(this).closest('._form-element').attr('data-childindex') + ']').show();
			// 			} else {
			// 				$('._form-element[data-index=' + $(this).closest('._form-element').attr('data-childindex') + ']').hide();
			// 			}
			// 		});
			// 	}
			// });

			Object.keys(autocompletes).map(function(value, index) {
				var autocomplete = Object.values(autocompletes)[index];
				autocomplete.addListener('place_changed', function(){
					var place = autocomplete.getPlace();
					window['formLocationSearch' + value] = {
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng()
					}
				});
			});

			requiredFields.each(function() {
				if($(this).attr('data-type') === 'textbox') {
					$(this).find('input#textbox').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).val() !== '') {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'textarea') {
					$(this).find('textarea#textarea').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).val() !== '') {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'scale') {
						$(this).find('input#scale-element').on('click keyup keydown focusout blur', function() {
							var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
							if($(this).val() !== '') {
								$(this).closest('._form-element').attr('data-valid', true);
							} else {
								$(this).closest('._form-element').attr('data-valid', false);
							}
							checkRequired(pageId);
						});
					} else if ($(this).attr('data-type') === 'signature') {
						$(this).find('.sigWrapper').on('click keyup keydown focusout blur', function() {
							var index = $(this).parents('._form-element').attr('data-index');
							var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
							if(window['signaturepad-' + index] && window['signaturepad-' + index] !== null) {
								var sig = window['signaturepad-' + index].getSignature();
								if(sig.length){
									$(this).closest('._form-element').attr('data-valid', true);
								} else {
									$(this).closest('._form-element').attr('data-valid', false);
								}
							}
							checkRequired(pageId);
						});
					} else if ($(this).attr('data-type') === 'distance') {
						var index = $(this).attr('data-index');
						$(this).find('#distance-map-'+ index).on('click dragend', function() {
							var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
							if($(this).next().attr('data-latlng') && $(this).next().attr('data-latlng2')) {
								$(this).closest('._form-element').attr('data-valid', true);
							} else {
								$(this).closest('._form-element').attr('data-valid', false);
							}
							checkRequired(pageId);
						});
					} else if ($(this).attr('data-type') === 'location') {
						var index = $(this).attr('data-index');
						if($(this).find('input#locationLabel').length){
							$(this).find('input#locationLabel').on('keyup keydown focusout blur', function() {
								var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
								if($(this).attr('data-lat') && $(this).attr('data-lng')) {
									$(this).closest('._form-element').attr('data-valid', true);
								} else {
									$(this).closest('._form-element').attr('data-valid', false);
								}
								checkRequired(pageId);
							});
						} else if($(this).find('#location-map'+ index).length){
							$(this).find('#location-map' + index).on('click', function() {
								var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
								if($(this).attr('data-lat') && $(this).attr('data-lng')) {
									$(this).closest('._form-element').attr('data-valid', true);
								} else {
									$(this).closest('._form-element').attr('data-valid', false);
								}
								checkRequired(pageId);
							});
						}
					} else if ($(this).attr('data-type') === 'name') {
					$(this).find('input#first_name').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).val() !== '' && $(this).parent().siblings().find('input#last_name').val() !== '' ) {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});

					$(this).find('input#last_name').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).parent().siblings().find('input#first_name').val() !== '' && $(this).val() !== '' ) {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'checkbox') {
					$(this).find('input[type=checkbox]').on('change', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).prop('checked', true)) {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'radio') {
					$(this).find('input[type=radio]').on('change', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).prop('checked', true)) {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'dropdown') {
					$(this).find('select#choiceSelect').on('change', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).val() !== '') {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'yesno') {
					$(this).find('input[name^=yesnoPlaceholder]').on('change', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).prop('checked', true)) {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'address') {
					$(this).find('select#country').on('change', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if(($(this).parent().parent().find('input#zipcode').val() !== undefined || $(this).parent().parent().find('input#zipcode').val() !== '') && ($(this).parent().parent().find('input#area').val() !== undefined || $(this).parent().parent().find('input#area').val() !== '') && ($(this).parent().parent().find('input#street').val() !== undefined || $(this).parent().parent().find('input#street').val() !== '') && $(this).parent().parent().find('select#country').val() !== undefined && $(this).parent().parent().find('select#state').val() !== undefined && $(this).parent().parent().find('select#city').val() !== undefined) {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});

					$(this).find('select#state').on('change', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if(($(this).parent().parent().find('input#zipcode').val() !== undefined || $(this).parent().parent().find('input#zipcode').val() !== '') && ($(this).parent().parent().find('input#area').val() !== undefined || $(this).parent().parent().find('input#area').val() !== '') && ($(this).parent().parent().find('input#street').val() !== undefined || $(this).parent().parent().find('input#street').val() !== '') && $(this).parent().parent().find('select#country').val() !== undefined && $(this).parent().parent().find('select#state').val() !== undefined && $(this).parent().parent().find('select#city').val() !== undefined) {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});

					$(this).find('select#city').on('change', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if(($(this).parent().parent().find('input#zipcode').val() !== undefined || $(this).parent().parent().find('input#zipcode').val() !== '') && ($(this).parent().parent().find('input#area').val() !== undefined || $(this).parent().parent().find('input#area').val() !== '') && ($(this).parent().parent().find('input#street').val() !== undefined || $(this).parent().parent().find('input#street').val() !== '') && $(this).parent().parent().find('select#country').val() !== undefined && $(this).parent().parent().find('select#state').val() !== undefined && $(this).parent().parent().find('select#city').val() !== undefined) {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});

					$(this).find('input#zipcode').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if(($(this).parent().parent().find('input#zipcode').val() !== undefined || $(this).parent().parent().find('input#zipcode').val() !== '') && ($(this).parent().parent().find('input#area').val() !== undefined || $(this).parent().parent().find('input#area').val() !== '') && ($(this).parent().parent().find('input#street').val() !== undefined || $(this).parent().parent().find('input#street').val() !== '') && $(this).parent().parent().find('select#country').val() !== undefined && $(this).parent().parent().find('select#state').val() !== undefined && $(this).parent().parent().find('select#city').val() !== undefined) {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});

					$(this).find('input#area').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if(($(this).parent().parent().find('input#zipcode').val() !== undefined || $(this).parent().parent().find('input#zipcode').val() !== '') && ($(this).parent().parent().find('input#area').val() !== undefined || $(this).parent().parent().find('input#area').val() !== '') && ($(this).parent().parent().find('input#street').val() !== undefined || $(this).parent().parent().find('input#street').val() !== '') && $(this).parent().parent().find('select#country').val() !== undefined && $(this).parent().parent().find('select#state').val() !== undefined && $(this).parent().parent().find('select#city').val() !== undefined) {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});

					$(this).find('input#street').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if(($(this).parent().parent().find('input#zipcode').val() !== undefined || $(this).parent().parent().find('input#zipcode').val() !== '') && ($(this).parent().parent().find('input#area').val() !== undefined || $(this).parent().parent().find('input#area').val() !== '') && ($(this).parent().parent().find('input#street').val() !== undefined || $(this).parent().parent().find('input#street').val() !== '') && $(this).parent().parent().find('select#country').val() !== undefined && $(this).parent().parent().find('select#state').val() !== undefined && $(this).parent().parent().find('select#city').val() !== undefined) {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'phone') {
					$(this).find('input#phone').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).val() !== '') {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'date') {
					$(this).find('input#datepicker').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).val() !== '') {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'time') {
					$(this).find('input#timepicker').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).val() !== '') {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'email') {
					$(this).find('input#email').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						// var emailRegex = new RegExp('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$');
						if($(this).val() !== '') {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'number') {
					$(this).find('input#number').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).val() !== '') {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'website') {
					$(this).find('input#website').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						// var websiteRegex = new RegExp('^(?:ftp|http|https):\/\/(?:[\w\.\-\+]+:{0,1}[\w\.\-\+]*@)?(?:[a-z0-9\-\.]+)(?::[0-9]+)?(?:\/|\/(?:[\w#!:\.\?\+=&%@!\-\/\(\)]+)|\?(?:[\w#!:\.\?\+=&%@!\-\/\(\)]+))?$');
						if($(this).val() !== '') {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'currency') {
					$(this).find('input#currency').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).val() !== '') {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				} else if ($(this).attr('data-type') === 'file') {
					$(this).find('input#fileType').on('keyup keydown focusout blur', function() {
						var pageId = $(this).closest('._form-page').attr('id').split('-').pop();
						if($(this).val() !== '') {
							$(this).closest('._form-element').attr('data-valid', true);
						} else {
							$(this).closest('._form-element').attr('data-valid', false);
						}
						checkRequired(pageId);
					});
				}
			});

			checkRequired();

			$('.next-page-btn').click(function() {
				var currentPageId = $(this).parents('._form-page').attr('id');
				var pageCount = currentPageId.split('-').pop();
				pageCount = parseInt(pageCount);
				pageCount += 1;
				$('._form-page').hide();
				$('._form-page#form-page-' + pageCount).show();
				checkRequired(pageCount);
			});

			$('.prev-page-btn').click(function() {
				var currentPageId = $(this).parents('._form-page').attr('id');
				var pageCount = currentPageId.split('-').pop();
				pageCount = parseInt(pageCount);
				pageCount -= 1;
				$('._form-page').hide();
				$('._form-page#form-page-' + pageCount).show();
				checkRequired(pageCount);
			});

			var formId = window.formId;
			$('#submit-page').click(function() {
				submitForm(formId, formSubmissionSetting);
			});
		});

		function deleteOption(){
			console.log('delete called')
		}
	}
}

export function submitForm(formId, formSubmissionSetting) {
	var formJSON = [];
	var allElements = $('._element-container-view ._form-element');
	var promisearr = [];
	for (var i = 0; i < allElements.length; i++) {
	  var elementJSON = {};
	  var selectedQuestionId = $(allElements).eq(i).data('questionid');
	  var selectedQuestionType = $(allElements).eq(i).data('type');

	  var answer, promise;
	  if(selectedQuestionType == 'textbox') {
			answer = $(allElements).eq(i).find('input#textbox').val();
		} else if(selectedQuestionType == 'textarea') {
			answer = $(allElements).eq(i).find('textarea#textarea').val();
		} else if(selectedQuestionType == 'location') {
			answer = {};
			if($(allElements).eq(i).find('input#locationLabel').val() != '' && window['formLocationSearch' + i] != undefined){
				answer['location_latlng'] = {
					lat: window['formLocationSearch' + i].lat,
					lng: window['formLocationSearch' + i].lng
				}
				answer['location_name'] = $(allElements).eq(i).find('input#locationLabel').val();
			} else if($(allElements).eq(i).find('#location-map' + i).attr('data-lat') && $(allElements).eq(i).find('#location-map' + i).attr('data-lng')){
				answer['latitude'] = $(allElements).eq(i).find('#location-map' + i).attr('data-lat');
				answer['longitude'] = $(allElements).eq(i).find('#location-map' + i).attr('data-lng');
			}
		} else if(selectedQuestionType == 'distance') {
			answer = {};
			if($(allElements).eq(i).find('#distance-legend').attr('data-latlng') && $(allElements).eq(i).find('#distance-legend').attr('data-latlng2')){
				answer['latLng1'] = JSON.parse($(allElements).eq(i).find('#distance-legend').attr('data-latlng'));
				answer['latLng2'] = JSON.parse($(allElements).eq(i).find('#distance-legend').attr('data-latlng2'));
				answer['distance'] = $(allElements).eq(i).find('#distance-legend').text().split(':')[1].trim();
			}
		} else if(selectedQuestionType == 'name') {
			var first_name = $(allElements).eq(i).find('input#first_name').val();
			var last_name = $(allElements).eq(i).find('input#last_name').val();
			answer = {
				'first_name': first_name,
				'last_name': last_name
			};
		} else if(selectedQuestionType == 'scale') {
			answer = $(allElements).eq(i).find("input#scale-element").val();
		} else if(selectedQuestionType == 'signature') {
			if(window['signaturepad-' + i] && window['signaturepad-' + i] != null)
			answer = window['signaturepad-' + i].getSignatureImage();
		} else if(selectedQuestionType == 'checkbox') {
			answer = []
			$(allElements).eq(i).find("input[type='checkbox']:checked").each(function() {
				if($(this).next('label').text() !== '') answer.push($(this).next('label').text())
			});
		} else if(selectedQuestionType == 'payment') {
			answer = []
			$(allElements).eq(i).find("input[type='radio']:checked").each(function(key, index) {
				if($(this).next('label').text() !== ''){
					answer.push(($(this).attr('id')).split('-')[1]-1);
				}
			});
		} else if(selectedQuestionType == 'radio') {
			answer = $(allElements).eq(i).find('input[type="radio"]:checked').next('label').text();
		} else if(selectedQuestionType == 'dropdown') {
			answer = $(allElements).eq(i).find('select').val();
		} else if(selectedQuestionType == 'correlation') {
			answer = $(allElements).eq(i).find('select').val();
		} else if(selectedQuestionType == 'yesno') {
			answer = $(allElements).eq(i).find('input[type="radio"]:checked').next('label').text();
		} else if(selectedQuestionType == 'address') {
			var country = $(allElements).eq(i).find('select#country').val();
			var state = $(allElements).eq(i).find('select#state').val();
			var city = $(allElements).eq(i).find('select#city').val();
			var pincode = $(allElements).eq(i).find('input#zipcode').val();
			var area = $(allElements).eq(i).find('input#area').val();
			var street = $(allElements).eq(i).find('input#street').val();
			var lat = $(allElements).eq(i).find('input#area').attr('data-lat');
			var lng = $(allElements).eq(i).find('input#area').attr('data-lng');
			answer = {
				'country': country,
				'state': state,
				'city': city,
				'pincode': pincode,
				'area': area,
				'street': street
			};
			if(lat && lng) {
				answer['latitude'] = lat;
				answer['longitude'] = lng;
			}
		} else if(selectedQuestionType == 'phone') {
			answer = $(allElements).eq(i).find('input#phone').val();
		} else if(selectedQuestionType == 'date') {
			if($(allElements).eq(i).find('input#datepicker').val().trim() !== ''){
				answer = moment($(allElements).eq(i).find('input#datepicker').val(), 'DD MMM YYYY').format('DD/MM/YYYY');
			} else {
				answer = '';
			}
		} else if(selectedQuestionType == 'time') {
			if($(allElements).eq(i).find('input#timepicker').val().trim() !== ''){
				answer = $(allElements).eq(i).find('input#timepicker').val();
			} else {
				answer = '';
			}
		} else if(selectedQuestionType == 'email') {
			if($(allElements).eq(i).find('input#email').val().trim() !== ''){
				answer = $(allElements).eq(i).find('input#email').val();
			} else {
				answer = '';
			}
		} else if(selectedQuestionType == 'number') {
			if($.isNumeric($(allElements).eq(i).find('input#number').val())){
				answer = parseFloat($(allElements).eq(i).find('input#number').val());
			} else {
				answer = ''
			}
		} else if(selectedQuestionType == 'website') {
			if($(allElements).eq(i).find('input#website').val().trim() !== ''){
				answer = $(allElements).eq(i).find('input#website').val();
			} else {
				answer = '';
			}
		} else if(selectedQuestionType == 'currency') {
			if($.isNumeric($(allElements).eq(i).find('input#currency').val())){
				answer = parseFloat($(allElements).eq(i).find('input#currency').val());
			} else {
				answer = ''
			}
		} else if(selectedQuestionType == 'rating') {
			answer = $(allElements).eq(i).find(".my-rating").starRating('getRating');
		} else if(selectedQuestionType == 'file') {
			var multipleFiles = false, files = [];
			if($(allElements).eq(i).find("input#fileType").attr('multiple')) {
				multipleFiles = true;
				files = $(allElements).eq(i).find("input#fileType")[0].files;
			}
			var file = $(allElements).eq(i).find("input#fileType")[0].files[0];
			var latitude = $(allElements).eq(i).find("input#fileType").attr('data-lat');
			var longitude = $(allElements).eq(i).find("input#fileType").attr('data-lng');

			answer = {};
			if(multipleFiles && files.length) {
				var data = new FormData();
				for(var j = 0; j < files.length; j++) {
					data.append('files', files[j]);
				}
				var elemIndex = i;
				promise = fetch(window.fileUrl, {
					method: 'POST',
					body: data
				}).then(function(data) {
					return data.json();
				}).then(function(data) {
					if(data.status === 'ok'){
						answer = {};
						elementJSON = {};
						if(latitude && longitude){
							answer['latitude'] = latitude;
							answer['longitude'] = longitude;
						}
						answer['urls'] = data.urls;
						elementJSON['questionId'] = $(allElements).eq(elemIndex).data('questionid');
						elementJSON['questionType'] = $(allElements).eq(elemIndex).data('type');
						elementJSON['answer'] = answer;
						formJSON[elemIndex] = elementJSON;
					} else {
						swal({
							html: "<h5>Failed to upload File!</h5>",
							type: "error"
						})
					}
				})
				promisearr.push(promise);
			} else if(file){
				var data = new FormData();
				data.append("file", file);
				var elemIndex = i;
				promise = fetch(window.fileUrl, {
					method: 'POST',
					body: data
				}).then(function(data) {
					return data.json();
				}).then(function(data) {
					if(data.status === 'ok'){
						answer = {};
						elementJSON = {};
						if(latitude && longitude){
							answer['latitude'] = latitude;
							answer['longitude'] = longitude;
						}
						answer['url'] = data.url;
						elementJSON['questionId'] = $(allElements).eq(elemIndex).data('questionid');
						elementJSON['questionType'] = $(allElements).eq(elemIndex).data('type');
						elementJSON['answer'] = answer;
						formJSON[elemIndex] = elementJSON;
					} else {
						swal({
							html: "<h5>Failed to upload File!</h5>",
							type: "error"
						})
					}
				})
				promisearr.push(promise);
			} else {
				answer = {};
				if(multipleFiles) answer['urls'] = [];
				else answer['url'] = '';
				elementJSON['questionId'] = selectedQuestionId;
				elementJSON['questionType'] = selectedQuestionType;
				elementJSON['answer'] = answer;
				formJSON[i] = elementJSON;
			}
		}
		if(selectedQuestionType != 'file') {
			elementJSON['questionId'] = selectedQuestionId;
			elementJSON['questionType'] = selectedQuestionType;
			elementJSON['answer'] = answer;
			formJSON[i] = elementJSON;
		}
	}

	Promise.all(promisearr).then(function() {
		let form = window.form;
		for(var i = 0; i < form['form_element'].length; i++) {
			var questionId = form['form_element'][i]['question_id'];
			for (var j = 0; j < Object.values(formJSON).length; j++) {
				if(formJSON[j]['questionId'] == questionId) {
					if(formJSON[j].hasOwnProperty('answer')) form['form_element'][i]['answer'] = formJSON[j]['answer'];
					else form['form_element'][i]['answer'] = '';
					break;
				}
			}
		}

		fetch(window.updateFormUrl, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": window.access_token
			},
			body: JSON.stringify(form)
		}).then(function (response){
			return response.json();
		}).then(function (data){
			if(data.status === 'ok'){
				if(data.hasOwnProperty('url')){
					swal({
						title: 'Your response has been submitted.',
						text: "Your response has been submitted. Proceeding to payment gateway.",
						confirmButtonText: "Ok",
						type: "success"
					}).then(function(){
						if(data.url.includes('http://') || data.url.includes('https://')){
							window.open(data.url);
						} else {
							window.open('http://' + data.url);
						}
					}).catch(swal.noop);
				} else {
					if(formSubmissionSetting['option_type'] === 'message' && formSubmissionSetting['option_details'].trim() !== ''){
						swal({
							title: formSubmissionSetting['option_details'],
							confirmButtonText: "Ok",
							type: "success"
						}).then(function(){
							window.location.reload();
						}).catch(swal.noop);
					} else if(formSubmissionSetting['option_type'] === 'url' && formSubmissionSetting['option_details'].trim() !== '' && formSubmissionSetting.hasOwnProperty('url_open_option')) {
						swal({
							title: 'Your response has been submitted.',
							confirmButtonText: "Ok",
							type: "success"
						}).then(function() {
							if(formSubmissionSetting['url_open_option'] === 'same'){
								if(formSubmissionSetting['option_details'].includes('http://') || formSubmissionSetting['option_details'].includes('https://')) window.location = formSubmissionSetting['option_details'];
								else window.location = 'http://' + formSubmissionSetting['option_details'];
							} else if(formSubmissionSetting['url_open_option'] === 'different'){
								if(formSubmissionSetting['option_details'].includes('http://') || formSubmissionSetting['option_details'].includes('https://')) window.open(formSubmissionSetting['option_details']);
								else window.open('http://' + formSubmissionSetting['option_details']);
							}
						}).catch(swal.noop);
					} else if(formSubmissionSetting['option_type'] === 'url' && formSubmissionSetting['option_details'].trim() !== '') {
						swal({
							title: 'Your response has been submitted.',
							confirmButtonText: "Ok",
							type: "success"
						}).then(function() {
							if(formSubmissionSetting['option_details'].includes('http://') || formSubmissionSetting['option_details'].includes('https://')) window.location = formSubmissionSetting['option_details'];
							else window.location = 'http://' + formSubmissionSetting['option_details'];
						}).catch(swal.noop);
					} else {
						window.location.reload();
					}
				}
			} if (data.status === 'error'){
				swal({
					title: data['message'],
					confirmButtonText: "Ok",
					type: "error"
				}).catch(swal.noop);
			}
	  });
  });
}