import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import moment from 'moment';
import StarRatingComponent from 'react-star-rating-component';
import { Link } from 'react-router-dom';

export default class SingleDonorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasImportantDates: false
    }
  }

  componentWillMount() {
    if(this.props.donor.important_dates.length) this.setState({ hasImportantDates: true });
    else {
      this.props.list.default_member_fields_others.map(function(field, index) {
        if(field.data_type === 'Date' && this.props.donor.hasOwnProperty(field.id)) {
          if(this.props.donor[field.id]) {
            this.setState({ hasImportantDates: true });
          }
        }
      }.bind(this));
    }
  }

  render () {
    return (
      <div className="tab-container">
        <Row>
          <Col md={12}>
            <Row id="personal-details" className="mt20">
              <Col md={12}>
                <p className="section-heading">Personal Details</p>
              </Col>
              <Row>
                <Col md={6} className="mb10">
                  <span className="detail-title">Name:</span>
                  <span className="detail-data">{this.props.donor.title + ' ' + this.props.donor.first_name + ' ' + this.props.donor.last_name}</span>
                </Col>
                <Col md={6} className="mb10">
                  <span className="detail-title">Email:</span>
                  <span className="detail-data email text-lowercase">{this.props.donor.email}</span>
                </Col>
              </Row>

              <Row>
                {this.props.list.default_member_fields.map(function(item, index) {
                  if(this.props.donor.hasOwnProperty(item.id) && (item.id !== 'first_name' && item.id !== 'last_name' && item.id !== 'email' && item.id !== 'title')) {
                    if(this.props.donor[item.id] && this.props.donor[item.id] !== '' && this.props.donor[item.id] !== null) {
                      return (
                        <Col md={6} key={index} className="mb10">
                          <span className="detail-title">{item.label + ':'}</span>
                          {(() => {
                            if(item.id === 'priority') {
                              return <StarRatingComponent name="priority" editing={false} starCount={5} value={this.props.donor[item.id]} className="col-6 priority-star" />
                            } else if(item.id === 'pan_number') {
                              return <span className="detail-data pan">{this.props.donor[item.id]}</span>
                            } else {
                              if(item.data_type === 'Date') {
                                return <span className="detail-data">{moment(this.props.donor[item.id]).format('DD/MM/YYYY')}</span>
                              } else {
                                return <span className="detail-data">{this.props.donor[item.id]}</span>
                              }
                            }
                          })()}
                        </Col>
                      )
                    } else return null;
                  } else return null;
                }.bind(this))}
              </Row>
            </Row>

            {this.props.list.custom_member_fields.length ? (
              <Row id="other-details" className="mt20">
                <Col md={12}>
                  <p className="section-heading">Other Details</p>
                </Col>
                <Row>
                  {this.props.list.custom_member_fields.map(function(item, index) {
                    if(this.props.donor.custom_fields.hasOwnProperty(item.id)) {
                      if(this.props.donor.custom_fields[item.id] && this.props.donor.custom_fields[item.id] !== '' && this.props.donor.custom_fields[item.id] !== null) {
                        return (
                          <Col md={6} key={index} className="mb10">
                            <span className="detail-title">{item.label + ':'}</span>
                            {(() => {
                              if(item.data_type === 'Date') {
                                return <span className="detail-data">{moment(this.props.donor.custom_fields[item.id]).format('DD/MM/YYYY')}</span>
                              } else {
                                return <span className="detail-data">{this.props.donor.custom_fields[item.id]}</span>
                              }
                            })()}
                          </Col>
                        )
                      } else return null;
                    } else return null;
                  }.bind(this))}
                </Row>
              </Row>
            ) : (null)}

            {this.state.hasImportantDates ? (
              <Row id="dates-details" className="mt20">
                <Col md={12}>
                  <p className="section-heading">Important Dates</p>
                </Col>
                <Col md={12} className="mb10 important-dates">
                  {this.props.list.default_member_fields_others.map(function(field, index) {
                    if(field.data_type === 'Date' && this.props.donor.hasOwnProperty(field.id)) {
                      if(this.props.donor[field.id]) {
                        return (
                          <Col md={12} key={index} className="important-date">
                            <Col md={3} className="date">{moment(this.props.donor[field.id]).format('DD/MM/YYYY')}</Col>
                            <Col md={9} className="date-label">{field.label}</Col>
                          </Col>
                        )
                      }
                    }
                  }.bind(this))}
                </Col>
                <Col md={12} className="mb10 important-dates">
                  {this.props.donor.important_dates.map(function(date, index) {
                    return (
                      <Col md={12} key={index} className="important-date">
                        <Col md={3} className="date">{moment(date.date).format('DD/MM/YYYY')}</Col>
                        <Col md={9} className="date-label">{date.details}</Col>
                      </Col>
                    )
                  }.bind(this))}
                </Col>
              </Row>
            ) : (null)}


              {/*<Row>
                {!this.props.donor.dob || this.props.donor.dob === '' ? (null) : (
                  <Col md={6} className="mb10">
                    <span className="detail-title">DOB:</span>
                    <span className="detail-data">{moment(this.props.donor.dob).format('DD/MM/YYYY')}</span>
                  </Col>
                )}
                {!this.props.donor.phone_no || this.props.donor.phone_no === '' ? (null) : (
                  <Col md={6} className="mb10">
                    <span className="detail-title">Phone:</span>
                    <span className="detail-data">{this.props.donor.phone_no}</span>
                  </Col>
                )}
              </Row>
              <Row>
                <Col md={6} className="mb10">
                  <span className="detail-title">Nationality:</span>
                  <span className="detail-data">{this.props.donor.nationality}</span>
                </Col>
                {(() => {
                  if(this.props.donor.pan_number !== '') {
                    return (
                      <Col md={6} className="mb10">
                        <span className="detail-title">Permanent Account Number (PAN):</span>
                        <span className="detail-data">{this.props.donor.pan_number}</span>
                      </Col>
                    );
                  }
                })()}
              </Row>
              {(() => {
                if(this.props.donor.hasOwnProperty('note') && this.props.donor.note !== '') {
                  return (
                    <Row>
                      <Col md={12} className="mb10">
                        <span className="detail-title">note:</span>
                        <span className="detail-data">{this.props.donor.note}</span>
                      </Col>
                    </Row>
                  );
                }
              })()}
              <Row>
                {this.props.donor.hasOwnProperty('is_prospect') ? (
                  <Col md={4} className="mb10">
                    <span className="detail-title">Is Prospect:</span>
                    <span className="detail-data">{this.props.donor.is_prospect ? 'Yes' : 'No'}</span>
                  </Col>
                ) : (null)}
                {this.props.donor.hasOwnProperty('frequency') && this.props.donor.frequency !== '' ? (
                  <Col md={4} className="mb10">
                    <span className="detail-title">Donation Frequency:</span>
                    <span className="detail-data">{this.props.donor.frequency}</span>
                  </Col>
                ) : (null)}
                {this.props.donor.hasOwnProperty('pledged_amount') && this.props.donor.pledged_amount ? (
                  <Col md={4} className="mb10">
                    <span className="detail-title">Pledged Amount:</span>
                    <span className="detail-data">{'INR ' + this.props.donor.pledged_amount.toLocaleString()}</span>
                  </Col>
                ) : (null)}
              </Row>
              <Row>
                {this.props.donor.country !== '' ? (
                  <Col md={3} className="mb10">
                    <span className="detail-title">Country:</span>
                    <span className="detail-data">{this.props.donor.country}</span>
                  </Col>
                ) : (null)}
                {this.props.donor.state !== '' ? (
                  <Col md={3} className="mb10">
                    <span className="detail-title">State:</span>
                    <span className="detail-data">{this.props.donor.state}</span>
                  </Col>
                ) : (null)}
                {this.props.donor.city !== '' ? (
                  <Col md={3} className="mb10">
                    <span className="detail-title">City:</span>
                    <span className="detail-data">{this.props.donor.city}</span>
                  </Col>
                ) : (null)}
                {(() => {
                  if(this.props.donor.zipcode !== '') {
                    return (
                      <Col md={3} className="mb10">
                        <span className="detail-title">Zipcode:</span>
                        <span className="detail-data">{this.props.donor.zipcode}</span>
                      </Col>
                    )
                  }
                })()}
              </Row>
              <Row>
                {(() => {
                  if(this.props.donor.address1 === '' && this.props.donor.address2 === '') {
                    return <div></div>
                  } else {
                    if(this.props.donor.address1 === '') {
                      return (
                        <Col md={12} className="mb10">
                          <span className="detail-title">Address:</span>
                          <span className="detail-data">{this.props.donor.address2}</span>
                        </Col>
                      );
                    } else if(this.props.donor.address2 === '') {
                      return (
                        <Col md={12} className="mb10">
                          <span className="detail-title">Address:</span>
                          <span className="detail-data">{this.props.donor.address1}</span>
                        </Col>
                      );
                    } else {
                      return (
                        <Col md={12} className="mb10">
                          <span className="detail-title">Address:</span>
                          <span className="detail-data">{this.props.donor.address1}, {this.props.donor.address2}</span>
                        </Col>
                      );
                    }
                  }
                })()}
              </Row>
            </Row>

            <Row id="professional-details" className="mt20">
              <Col md={12}>
                <p className="section-heading">Professional Details</p>
              </Col>
              <Row>
                {(() => {
                  if(this.props.donor.org_name !== '') {
                    return (
                      <Col md={4} className="mb10">
                        <span className="detail-title">Organization:</span>
                        <span className="detail-data">{this.props.donor.org_name}</span>
                      </Col>
                    )
                  }
                })()}
                {(() => {
                  if(this.props.donor.org_position !== '') {
                    return (
                      <Col md={4} className="mb10">
                        <span className="detail-title">Position:</span>
                        <span className="detail-data">{this.props.donor.org_position}</span>
                      </Col>
                    )
                  }
                })()}
                <Col md={4} className="mb10">
                  <span className="detail-title">Added By:</span>
                  <span className="detail-data">{this.props.donor.assignee_name}</span>
                </Col>
              </Row>
            </Row>

            <Row id="other-details" className="mt20">
              {(() => {
                if(this.props.donor.groups.length) {
                  return (
                    <Col md={8}>
                      <p className="section-heading">Groups</p>
                      <Row className="mb10">
                      {this.props.donor.groups.map((group, i) => {
                        var groupProfile = "/segments/" + group.id;
                        if(this.props.ngo.plan.name === 'premium') {
                          return (
                            <Link to={groupProfile}><span key={i} className="group-tag-name">{group.name}</span></Link>
                          );
                        } else if(this.props.ngo.plan.name === 'payment_gateway') {
                          return (
                            <span key={i} className="group-tag-name">{group.name}</span>
                          );
                        }
                      })}
                      </Row>
                    </Col>
                  );
                }
              })()}
              <Col md={4}>
                <p className="section-heading">Priority</p>
                <Row className="mb10">
                  <StarRatingComponent name="priority" editing={false} starCount={5} value={this.props.donor.priority} className="col-6 priority-star" />
                </Row>
              </Col>
            </Row>

            <Row id="dates-details" className="mt20">
              {(() => {
                if(this.props.donor.important_dates.length || this.props.donor.dob_father || this.props.donor.dob_mother || this.props.donor.dob_spouse || this.props.donor.dob_first_child || this.props.donor.dob_second_child || this.props.donor.marriage_anniversary) {
                  return (
                    <Col md={12}>
                      <p className="section-heading">Important Dates</p>
                      <Row className="mb10 important-dates">
                        {(() => {
                          if(this.props.donor.dob_father !== '' && this.props.donor.dob_father) {
                            return (
                              <Col md={12} className="important-date">
                                <Col md={3} className="date">{moment(this.props.donor.dob_father).format('DD/MM/YYYY')}</Col>
                                <Col md={9} className="date-label">DOB of Father</Col>
                              </Col>
                            );
                          }
                        })()}
                        {(() => {
                          if(this.props.donor.dob_mother !== '' && this.props.donor.dob_mother) {
                            return (
                              <Col md={12} className="important-date">
                                <Col md={3} className="date">{moment(this.props.donor.dob_mother).format('DD/MM/YYYY')}</Col>
                                <Col md={9} className="date-label">DOB of Mother</Col>
                              </Col>
                            );
                          }
                        })()}
                        {(() => {
                          if(this.props.donor.dob_spouse !== '' && this.props.donor.dob_spouse) {
                            return (
                              <Col md={12} className="important-date">
                                <Col md={3} className="date">{moment(this.props.donor.dob_spouse).format('DD/MM/YYYY')}</Col>
                                <Col md={9} className="date-label">DOB of Spouse</Col>
                              </Col>
                            );
                          }
                        })()}
                        {(() => {
                          if(this.props.donor.dob_first_child !== '' && this.props.donor.dob_first_child) {
                            return (
                              <Col md={12} className="important-date">
                                <Col md={3} className="date">{moment(this.props.donor.dob_first_child).format('DD/MM/YYYY')}</Col>
                                <Col md={9} className="date-label">DOB of 1st Child</Col>
                              </Col>
                            );
                          }
                        })()}
                        {(() => {
                          if(this.props.donor.dob_second_child !== '' && this.props.donor.dob_second_child) {
                            return (
                              <Col md={12} className="important-date">
                                <Col md={3} className="date">{moment(this.props.donor.dob_second_child).format('DD/MM/YYYY')}</Col>
                                <Col md={9} className="date-label">DOB of 2nd Child</Col>
                              </Col>
                            );
                          }
                        })()}
                        {(() => {
                          if(this.props.donor.marriage_anniversary !== '' && this.props.donor.marriage_anniversary) {
                            return (
                              <Col md={12} className="important-date">
                                <Col md={3} className="date">{moment(this.props.donor.marriage_anniversary).format('DD/MM/YYYY')}</Col>
                                <Col md={9} className="date-label">Anniversary</Col>
                              </Col>
                            );
                          }
                        })()}
                        {(() => {
                          if(this.props.donor.important_dates.length) {
                            return this.props.donor.important_dates.map((important_date, i) => {
                              return (
                                <div key={i} className="important-date col-md-12">
                                  <Col md={3} className="date">{moment(important_date.date).format('DD/MM/YYYY')}</Col>
                                  <Col md={9} className="date-label">{important_date.details}</Col>
                                </div>
                              );
                            })
                          }
                        })()}
                      </Row>
                    </Col>
                  );
                }
              })()}
            </Row>

            {/*<Row id="member-reviews" className="mt20">
              <Col md={12}>
                <p className="section-heading">Member Reviews</p>
              </Col>
              */}
          </Col>
        </Row>
      </div>
    );
  }
}
