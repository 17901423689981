import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import $ from 'jquery';
import swal from "sweetalert2";
import { Col, Row, Modal } from "react-bootstrap";
import Multiselect from 'react-bootstrap-multiselect';

import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import "sweetalert2/dist/sweetalert2.min.css";

import AddDonor from './AddDonor';
import ImportDonorsModal from './ImportDonorsModal';
import Loader from '../Loader';

const readCookie = require('../cookie.js').readCookie;

const styles = {
  clear: {
    clear: 'both',
  },
};

function linkFirstNameFormatter(cell, row) {
  var userProfile = "/dm/members/" + row.donor_id;
  var firstName = row['first_name'];
  return (
    <Link title={firstName} to={userProfile}>{firstName}</Link>
  );
}

function linkLastNameFormatter(cell, row) {
  var userProfile = "/dm/members/" + row.donor_id;
  var lastName = row['last_name'];
  return (
    <Link title={lastName} to={userProfile}>{lastName}</Link>
  );
}

function emailFormatter(cell, row) {
  var userProfile = "/dm/members/" + row.donor_id;
  var email = row['email'];
  return (
    <Link title={email} to={userProfile}>{email}</Link>
  );
}

function dateFormatter(value, row, index) {
  if (value === 'Not donated yet') return 'Not donated yet';
  return moment(value).format('DD/MM/YYYY');
}

function addedOnFormatter(value, row, index) {
  if (value) {
    return moment(value).format('DD/MM/YYYY');
  } else return '';
}

function totalContributionFormatter(value, row, index) {
  return 'INR ' + value.toLocaleString();
}
function totalDonationsFormatter(value, row, index) {
  return value.toLocaleString();
}

function locationFormatter(value, row, index) {
  if (row.city !== '') return <span title={row.city}>{row.city}</span>
  else if (row.state !== '') return <span title={row.state}>{row.state}</span>
  else if (row.country !== '') return <span title={row.country}>{row.country}</span>
  else return <span>N/A</span>
}

export default class DonorTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      totalDonors: null,
      showAddDonorModal: false,
      showImportDonorModal: false,
      selectRowProp: {
        mode: 'checkbox',
        onSelect: this.onRowSelect.bind(this),
        onSelectAll: this.onAllRowSelect.bind(this)
      },
      selectedRowCount: 0,
      deleteButtonDisabled: true,
      showLoader: true,
      loaderHiding: '',
      otherColumns: false,
      advancedCustomizations: [],
      donorCategories: [],
      selectedDonorCategory: 'All Donors',
      sizePerPage: 20,
      currentPage: 1,
      searchText: '',
      sortBy: '',
      sortType: '',
      list: null,
      lists: [],
      tableSettingsDropdownVisibility: false,
      defaultTableColumns: {},
      settingsDropdownVisibility: false,
      moveMembersModalVisible: false,
      moveMembers: null,
      moveMemberToList: ''

    };
    this.onSearchChange = this.onSearchChange.bind(this);
    this.getDonorCategoryIdFromName = this.getDonorCategoryIdFromName.bind(this);
  }

  onRowSelect(row, isSelected) {
    if (isSelected) {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length + 1 }, function () {
        if (this.state.selectedRowCount) {
          this.setState({ deleteButtonDisabled: false });
        }
      });
    } else {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length - 1 }, function () {
        if (this.state.selectedRowCount) {
          this.setState({ deleteButtonDisabled: false });
        } else {
          this.setState({ deleteButtonDisabled: true });
        }
      });
    }
  }

  onAllRowSelect(row, isSelected) {
    if (row) {
      this.setState({
        selectedRowCount: this.refs.table.state.data.length,
        deleteButtonDisabled: false
      });
    } else {
      this.setState({
        selectedRowCount: 0,
        deleteButtonDisabled: true
      });
    }
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/lists', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({ lists: data.lists });
    }.bind(this));
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/list/' + window.location.href.split('/lists/')[1], {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      console.log(data);
      let default_member_fields = data.list.default_member_fields;
      let defaultTableColumns = {};
      for (var i = 0; i < default_member_fields.length; i++) {
        defaultTableColumns[default_member_fields[i].id] = default_member_fields[i].label;
      }

      let default_member_fields_readonly = data.list.default_member_fields_readonly;
      let default_member_fields_others = data.list.default_member_fields_others;
      let otherTableColumns = {};
      for (var j = 0; j < default_member_fields_readonly.length; j++) {
        otherTableColumns[default_member_fields_readonly[j].id] = default_member_fields_readonly[j].label;
      }
      for (j = 0; j < default_member_fields_others.length; j++) {
        otherTableColumns[default_member_fields_others[j].id] = default_member_fields_others[j].label;
      }

      this.setState({ list: data.list, defaultTableColumns, otherTableColumns });
    }.bind(this));

    let query = '?page=' + (this.state.currentPage - 1) + '&per_page=' + this.state.sizePerPage;
    if (window.location.href.split('/lists/')[1] !== undefined) query += '&donor_category=' + window.location.href.split('/lists/')[1];
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({
        data: data['donors'],
        totalDonors: data['donors_count'],
        loaderHiding: 'hiding'
      }, function () {
        setTimeout(function () {
          this.setState({ showLoader: false });
        }.bind(this), 400);
      });
    }.bind(this));
    let otherColumns = [];
    this.setState({ otherColumns });
    var that = this;
    $(document).ready(function () {
      $(document).click(function (e) {
        if ($(e.target) !== $('.table-settings-container') && !$(e.target).parents('.table-settings-container').length) {
          that.setState({ settingsDropdownVisibility: false });
        }
      });
      setTimeout(function () {
        $('.donor-table .react-bs-table-search-form > input').keydown(function (e) {
          var keyCode = (e.keyCode ? e.keyCode : e.which);
          if (keyCode == 13) {
            that.onSearchChange($(this).val());
          }
        });
      }, 1000);
    });
  }


  onAddDonorClose = (data) => {
    this.setState({ showAddDonorModal: false });
    let query = '?donor_category=' + window.location.href.split('/lists/')[1];
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({
        data: data['donors'],
        totalDonors: data['donors_count']
      });
    }.bind(this));
  }


  onAddDonorOpen = () => {
    this.setState({ showAddDonorModal: true });
  }

  hideMoveMembersModal() {
    this.setState({ moveMembersModalVisible: false, moveMembers: null });
  }

  onImportOpen = () => {
    this.setState({ showImportDonorModal: true });
  }

  onImportClose = () => {
    this.setState({ showImportDonorModal: false });
    let query = '?donor_category=' + window.location.href.split('/lists/')[1];
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({
        data: data['donors'],
        totalDonors: data['donors_count']
      });
    }.bind(this));
  }

  onDonorCategoriesChange(e) {
    this.setState({ selectedDonorCategory: e.target.options[e.target.selectedIndex].text, showLoader: true, loaderHiding: '', currentPage: 1 });

    let query = '?page=0&per_page=' + this.state.sizePerPage + '&donor_category=' + e.target.value;

    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({
        data: data['donors'],
        totalDonors: data['donors_count'],
        loaderHiding: 'hiding'
      }, function () {
        setTimeout(function () {
          this.setState({ showLoader: false });
        }.bind(this), 400);
      });
    }.bind(this));
  }

  moveMembers() {
    if (this.state.moveMembers !== null && this.state.moveMemberToList !== '') {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Move!',
        cancelButtonText: 'No, Cancel!',
        confirmButtonClass: 'btn',
        cancelButtonClass: 'btn',
      }).then(function (result) {
        if (result) {
          if (this.state.moveMembers === 'all') {
            let data = {};
            data['list_id'] = this.state.moveMemberToList;
            fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/list/' + window.location.href.split('/lists/')[1] + '/move-all-members', {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
              },
              body: JSON.stringify(data)
            }).then((response) => response.json())
              .then((data) => {
                if (data.status === 'ok') {
                  swal({
                    title: 'Members moved successfully!',
                    text: '',
                    type: 'success'
                  });
                  let query = '?page=0&per_page=' + this.state.sizePerPage;
                  if (this.state.sortBy !== '' && this.state.sortType !== '') query += '&sortBy=' + this.state.sortBy + '&sortType=' + this.state.sortType;
                  if (window.location.href.split('/lists/')[1] !== undefined) query += '&donor_category=' + window.location.href.split('/lists/')[1];

                  fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
                    method: "GET",
                    headers: {
                      'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
                    }
                  }).then(function (response) {
                    return response.json();
                  }).then(function (data) {
                    this.setState({
                      data: data['donors'],
                      totalDonors: data['donors_count'],
                      selectedRowCount: 0,
                      deleteButtonDisabled: true,
                      tableSettingsDropdownVisibility: false
                    });
                  }.bind(this));
                  this.hideMoveMembersModal(this);
                } else {
                  swal({
                    title: 'Move members failed!',
                    text: '',
                    type: 'error'
                  });
                }
              });
          } else {
            let data = {};
            data['members'] = this.state.moveMembers;
            data['list_id'] = this.state.moveMemberToList;
            fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/list/' + window.location.href.split('/lists/')[1] + '/move-members', {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
              },
              body: JSON.stringify(data)
            }).then((response) => response.json())
              .then((data) => {
                if (data.status === 'ok') {
                  swal({
                    title: 'Members moved successfully!',
                    text: '',
                    type: 'success'
                  });
                  let query = '?page=0&per_page=' + this.state.sizePerPage;
                  if (this.state.sortBy !== '' && this.state.sortType !== '') query += '&sortBy=' + this.state.sortBy + '&sortType=' + this.state.sortType;
                  if (window.location.href.split('/lists/')[1] !== undefined) query += '&donor_category=' + window.location.href.split('/lists/')[1];

                  fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
                    method: "GET",
                    headers: {
                      'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
                    }
                  }).then(function (response) {
                    return response.json();
                  }).then(function (data) {
                    this.setState({
                      data: data['donors'],
                      totalDonors: data['donors_count'],
                      selectedRowCount: 0,
                      deleteButtonDisabled: true,
                      tableSettingsDropdownVisibility: false
                    });
                  }.bind(this));
                  this.hideMoveMembersModal(this);
                } else {
                  swal({
                    title: 'Move members failed!',
                    text: '',
                    type: 'error'
                  });
                }
              });
          }
        }
      }.bind(this));
    }
  }

  moveAllMembers() {
    this.setState({ moveMembers: 'all', moveMembersModalVisible: true });
  }

  showTableSettingsDropdown() {
    this.setState({ tableSettingsDropdownVisibility: !this.state.tableSettingsDropdownVisibility });
  }


  moveSelectedMembers() {
    var donorIdTemp = [];
    var data = this.state.data;
    var selectedName = this.refs.table.state.selectedRowKeys;
    for (var i = 0; i < selectedName.length; i++) {
      for (var j = 0; j < data.length; j++) {
        if (data[j]['donor_id'] === selectedName[i]) {
          donorIdTemp.push(data[j]['donor_id']);
        }
      }
    }
    this.setState({ moveMembers: donorIdTemp, moveMembersModalVisible: true });
  }


  createCustomButtonGroup(props, onClick) {
    if (this.props.ngo.plan.name === 'premium' || this.props.ngo.plan.name === 'payment_gateway_basic_plus') {
      return (
        <div>
          <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
            <button type='button'
              onClick={this.onAddDonorOpen}
              className={'btn btn-success react-bs-table-add-btn hidden-print'}>
              <span>
                <i className="glyphicon glyphicon-plus"></i>
                Add Member
              </span>
            </button>
            <div className="donor-table-settings-container" disabled={this.state.deleteButtonDisabled}>
              <span className={this.state.deleteButtonDisabled ? "table-settings-btn disabled" : "table-settings-btn"}
                onClick={this.state.deleteButtonDisabled ? null : this.showTableSettingsDropdown.bind(this)}
                disabled={this.state.deleteButtonDisabled}>
                <i className="fa fa-cog"></i> Settings </span>
              {this.state.tableSettingsDropdownVisibility ? (
                <ul className="settings-dropdown">
                  {props.exportCSVBtn}
                  {this.state.list.members_can_be_moved ? (
                    <button className="btn settings-dropdown-item" onClick={this.moveSelectedMembers.bind(this)}>
                      <span>
                        <i className="fa fa-clone"></i>
                        Move Members
                      </span>
                    </button>
                  ) : (null)}
                  <button className="btn settings-dropdown-item" onClick={this.getSelectedRowKeys.bind(this)}>
                    <span>
                      <i className="fa fa-trash"></i>
                      Delete Members
                    </span>
                  </button>
                </ul>
              ) : (null)}
            </div>
            <button type='button'
              onClick={this.onImportOpen}
              className={'btn btn-success react-bs-table-add-btn hidden-print'}>
              <span>
                <i className="glyphicon glyphicon-plus"></i>
                Import Members
              </span>
            </button>
          </ButtonGroup>
          <button className="total-donors">{'Total Members: ' + this.state.totalDonors}</button>
        </div>
      );
    } else {
      return (
        <div>
          <ButtonGroup className='my-custom-class payment-gateway' sizeClass='btn-group-md'>
            {props.exportCSVBtn}

            <button className="total-donors">{'Total Members: ' + this.state.totalDonors}</button>
          </ButtonGroup>
        </div>
      );
    }
  }

  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton disabled={this.state.deleteButtonDisabled} />
    );
  }

  getSelectedRowKeys() {
    var donorIdTemp = [];
    var data = this.state.data;
    var selectedName = this.refs.table.state.selectedRowKeys;
    for (var i = 0; i < selectedName.length; i++) {
      for (var j = 0; j < data.length; j++) {
        if (data[j]['donor_id'] === selectedName[i]) {
          donorIdTemp.push(selectedName[i]);
        }
      }
    }
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!',
      cancelButtonText: 'No, Cancel!',
      confirmButtonClass: 'btn',
      cancelButtonClass: 'btn',
    }).then(function (result) {
      if (result) {
        let query = '?page=0&per_page=' + this.state.sizePerPage;
        if (this.state.sortBy !== '' && this.state.sortType !== '') query += '&sortBy=' + this.state.sortBy + '&sortType=' + this.state.sortType;
        if (window.location.href.split('/lists/')[1] !== undefined) query += '&donor_category=' + window.location.href.split('/lists/')[1];
        // if(window.location.href.split('?donor_category=')[1] !== undefined) query += '&donor_category=' + window.location.href.split('?donor_category=')[1];
        // else query += '&donor_category=' + this.getDonorCategoryIdFromName(this.state.selectedDonorCategory);

        fetch(process.env.REACT_APP_API_URL + '/donor/' + this.props.ngo['ngo_id'] + '/delete', {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify({ to_delete_ids: donorIdTemp })
        }).then((response) => response.json())
          .then((data) => {
            if (data.status === 'ok') {
              swal({
                title: 'Deleted',
                text: data.success_message,
                type: 'success'
              }).then(function () {
                fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
                  method: "GET",
                  headers: {
                    'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
                  }
                }).then(function (response) {
                  return response.json();
                }).then(function (data) {
                  this.setState({
                    data: data['donors'],
                    totalDonors: data['donors_count'],
                    selectedRowCount: 0,
                    deleteButtonDisabled: true,
                    tableSettingsDropdownVisibility: false
                  })
                }.bind(this));
              }.bind(this));
            } else if (data.status === 'error') {
              let html = '';
              if (data.hasOwnProperty('success_message') && data.hasOwnProperty('error_message')) {
                html = `<div style="height: auto;">
                      <div style="display: flex; margin: 5px; align-items: center; background: #fafafa;">
                        <div style="width: 25px; height: 25px; border-radius: 50%; background: green; margin-right: 10px">
                          <i class="fa fa-check-circle" style="color: white; font-size: 20px; padding: 2px 4px;"></i>
                        </div>
                        <div style="font-size: 14px; font-weight: 600;">
                          ${data.success_message}
                        </div>
                      </div>
                      <div style="display: flex; margin: 5px; align-items: center; background: #fafafa;">
                        <div style="width: 25px; height: 25px; border-radius: 50%; background: red;">
                          <i class="fa fa-times" style="color: white; font-size: 20px; padding: 2px 4px;"></i>
                        </div>
                        <div style="font-size: 14px; font-weight: 600;">
                          ${data.error_message}
                        </div>
                      </div>
                    </div>`
              } else {
                html = `<div style="height: auto;">
                      <div style="display: flex; margin: 5px; align-items: center; background: #fafafa;">
                        <div style="width: 25px; height: 25px; border-radius: 50%; background: red;">
                          <i class="fa fa-times" style="color: white; font-size: 20px; padding: 2px 4px;"></i>
                        </div>
                        <div style="font-size: 14px; font-weight: 600;">
                          ${data.error_message}
                        </div>
                      </div>
                    </div>`
              }
              swal({
                title: 'Delete details',
                html: html
              }).then(function () {
                fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
                  method: "GET",
                  headers: {
                    'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
                  }
                }).then(function (response) {
                  return response.json();
                }).then(function (data) {
                  this.setState({
                    data: data['donors'],
                    totalDonors: data['donors_count'],
                    tableSettingsDropdownVisibility: false
                  })
                }.bind(this));
              }.bind(this));
            } else if (data.status === 'error_single') {
              swal({
                title: 'Can not delete.',
                text: data.error_message,
                type: 'error'
              })
            }
          });
      }
    }.bind(this));
  }

  getDonorCategoryIdFromName(selectedDonorCategory) {
    let selectedDonorCategoryId = 0;
    for (var i = 0; i < this.state.donorCategories.length; i++) {
      if (selectedDonorCategory === 'All ' + this.state.donorCategories[i].name + 's') selectedDonorCategoryId = this.state.donorCategories[i].id;
    }
    return selectedDonorCategoryId;
  }

  clearSearchClick(onClick) {
    this.setState({ searchText: '', showLoader: true, loaderHiding: 'data-update' });
    onClick();

    let query = '?page=0&per_page=' + this.state.sizePerPage;
    if (window.location.href.split('/lists/')[1] !== undefined) query += '&donor_category=' + window.location.href.split('/lists/')[1];
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({
        data: data['donors'],
        currentPage: 1,
        loaderHiding: 'hiding'
      }, function () {
        setTimeout(function () {
          this.setState({ showLoader: false });
        }.bind(this), 400);
      });
    }.bind(this));
  }

  showSettingsDropdown() {
    this.setState({ settingsDropdownVisibility: true });
  }

  createCustomClearButton(onClick) {
    return (
      <div className="search-bar-container-donor-table">
        <span className="table-search-btn donor-table" onClick={() => this.onSearchChange($('.donor-table .react-bs-table-search-form > input').val())}>
          <i className="fa fa-search"></i>
        </span>
        <span className="clear-table-search donor-table" onClick={this.clearSearchClick.bind(this, onClick)}>
          Reset
        </span>
        <div className="table-settings-container">
          <span className="table-settings-btn donor-table" onClick={this.showSettingsDropdown.bind(this)}>
            <i className="fa fa-cog"></i>
          </span>
          {this.state.settingsDropdownVisibility ? (
            <ul className="settings-dropdown">
              <li className="settings-dropdown-item" onClick={this.exportAll.bind(this)}>Export All</li>
              {this.state.list.members_can_be_moved ? (
                <li className="settings-dropdown-item" onClick={this.moveAllMembers.bind(this)}>Move All</li>
              ) : (null)}
              <li className="settings-dropdown-item" onClick={this.deleteAll.bind(this)}>Delete All</li>
            </ul>
          ) : (null)}
        </div>
      </div>
    );
  }

  exportAll() {
    swal({
      title: 'Are you sure?',
      text: "You will get an email with all member's details!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Export!',
      cancelButtonText: 'No, Cancel!',
      confirmButtonClass: 'btn',
      cancelButtonClass: 'btn',
    }).then(function (result) {
      if (result) {
        fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/list/' + this.state.list.id + '/members/export', {
          method: "GET",
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
          }
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          if (data.status === 'ok') {
            swal({
              title: 'Email sent successfully!',
              text: '',
              type: 'success'
            });
          } else {
            swal({
              title: 'Email export failed!',
              text: 'There was no data found to be exported.',
              type: 'error'
            });
          }
        });
      }
    }.bind(this));
  }

  deleteAll() {
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!',
      cancelButtonText: 'No, Cancel!',
      confirmButtonClass: 'btn',
      cancelButtonClass: 'btn',
    }).then(function (result) {
      if (result) {
        fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/list/' + this.state.list.id + '/members', {
          method: "DELETE",
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
          }
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          if (data.status === 'ok') {
            let query = '?page=0&per_page=' + this.state.sizePerPage;
            if (this.state.sortBy !== '' && this.state.sortType !== '') query += '&sortBy=' + this.state.sortBy + '&sortType=' + this.state.sortType;
            if (window.location.href.split('/lists/')[1] !== undefined) query += '&donor_category=' + window.location.href.split('/lists/')[1];

            fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
              method: "GET",
              headers: {
                'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
              }
            }).then(function (response) {
              return response.json();
            }).then(function (data) {
              this.setState({
                data: data['donors'],
                totalDonors: data['donors_count'],
                selectedRowCount: 0,
                deleteButtonDisabled: true,
                tableSettingsDropdownVisibility: false
              });
            }.bind(this));
          }
        }.bind(this));
      }
    }.bind(this));
  }

  onAdvancedCustomizationsChange = (value) => {
    let advancedCustomizationsLength = this.state.advancedCustomizations.length;
    this.setState({ advancedCustomizations: $(value).parents('select').val() }, function () {
      let scrollLeft = $('.donor-table.react-bs-table-container').find('.react-bs-table .react-bs-container-body .table').width() - $('.donor-table.react-bs-table-container').find('.react-bs-table .react-bs-container-body').width();
      if (scrollLeft > 0 && advancedCustomizationsLength < this.state.advancedCustomizations.length) {
        $('.donor-table.react-bs-table-container').find('.react-bs-table .react-bs-container-body').scrollLeft(scrollLeft);
        $('.donor-table.react-bs-table-container').find('.react-bs-table .react-bs-container-header').scrollLeft(scrollLeft);
      }
    });
  }

  isProspectFormatter(cell, row) {
    if (cell) return 'Yes';
    else return 'No';
  }

  frequencyFormatter(cell, row) {
    if (cell !== '') return cell.charAt(0).toUpperCase() + cell.substr(1);
    else return 'N/A';
  }

  pledgeAmountFormatter(cell, row) {
    if (cell) return 'INR ' + cell.toLocaleString();
    else return 'N/A';
  }

  donorTypeFormatter(cell, row) {
    if (cell !== '') return cell.charAt(0).toUpperCase() + cell.substr(1);
    else return '';
  }

  onPageChange(page, sizePerPage) {
    this.setState({ showLoader: true, loaderHiding: 'data-update' });

    let query = '?page=' + (page - 1) + '&per_page=' + sizePerPage;
    if (this.state.searchText.length > 3) query += '&query=' + this.state.searchText;
    if (window.location.href.split('/lists/')[1] !== undefined) query += '&donor_category=' + window.location.href.split('/lists/')[1];
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({
        data: data['donors'],
        currentPage: page,
        loaderHiding: 'hiding'
      }, function () {
        setTimeout(function () {
          this.setState({ showLoader: false });
        }.bind(this), 400);
      });
    }.bind(this));
  }

  onSizePerPageList(sizePerPage) {
    console.log('inside size per page', sizePerPage)
    this.setState({ showLoader: true, loaderHiding: 'data-update', currentPage: 1 });

    let query = '?page=0&per_page=' + sizePerPage;
    if (this.state.searchText.length > 3) query += '&query=' + this.state.searchText;
    if (window.location.href.split('/lists/')[1] !== undefined) query += '&donor_category=' + window.location.href.split('/lists/')[1];
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({
        data: data['donors'],
        sizePerPage: sizePerPage,
        loaderHiding: 'hiding'
      }, function () {
        setTimeout(function () {
          this.setState({ showLoader: false });
        }.bind(this), 400);
      });
    }.bind(this));
  }

  onSortChange(sortBy, sortType) {
    this.setState({ sortBy, sortType, showLoader: true, loaderHiding: 'data-update', currentPage: 1, searchText: '' });

    let query = '?page=0&per_page=' + this.state.sizePerPage + '&sortBy=' + sortBy + '&sortType=' + sortType;
    if (window.location.href.split('/lists/')[1] !== undefined) query += '&donor_category=' + window.location.href.split('/lists/')[1];
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({
        data: data['donors'],
        loaderHiding: 'hiding'
      }, function () {
        setTimeout(function () {
          this.setState({ showLoader: false });
        }.bind(this), 400);
      });
    }.bind(this));
  }

  handleMoveListChange(e) {
    this.setState({ moveMemberToList: e.target.value });
  }

  onSearchChange(searchText) {
    if (searchText.length > 3) {
      this.setState({ searchText, showLoader: true, loaderHiding: 'data-update', currentPage: 1 })

      let query = '?page=0&per_page=' + this.state.sizePerPage + '&query=' + searchText;
      if (window.location.href.split('/lists/')[1] !== undefined) query += '&donor_category=' + window.location.href.split('/lists/')[1];
      fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
        method: "GET",
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
        }
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        this.setState({
          data: data['donors'],
          currentPage: 1,
          loaderHiding: 'hiding'
        }, function () {
          setTimeout(function () {
            this.setState({ showLoader: false });
          }.bind(this), 400);
        });
      }.bind(this));
    } else if (searchText.length === 0) {
      this.setState({ currentPage: 1, searchText: '' })

      let query = '?page=0&per_page=' + this.state.sizePerPage;
      if (window.location.href.split('/lists/')[1] !== undefined) query += '&donor_category=' + window.location.href.split('/lists/')[1];
      fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors' + query, {
        method: "GET",
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
        }
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        this.setState({
          data: data['donors'],
          totalDonors: data['donors_count'],
          loaderHiding: 'hiding'
        }, function () {
          setTimeout(function () {
            this.setState({ showLoader: false });
          }.bind(this), 400);
        });
      }.bind(this));
    }
  }

  render() {
    const options = {
      btnGroup: this.createCustomButtonGroup.bind(this),
      clearSearch: true,
      onSortChange: this.onSortChange.bind(this),
      onSearchChange: () => { return null },
      clearSearchBtn: this.createCustomClearButton.bind(this),
      exportCSVBtn: this.createCustomExportCSVButton.bind(this),
      page: this.state.currentPage,
      onPageChange: this.onPageChange.bind(this),
      sizePerPage: this.state.sizePerPage,
      sizePerPageList: [
        { text: '20 Per Page', value: 20 },
        { text: '50 Per Page', value: 50 },
        { text: '100 Per Page', value: 100 }
      ],
      onSizePerPageList: this.onSizePerPageList.bind(this),
      onExportToCSV: () => {
        const selectedRows = this.refs.table.state.selectedRowKeys;
        return this.state.data.filter(i => {
          if (selectedRows.indexOf(i.donor_id) > -1) {
            return i;
          }
        });
      },
    };

    return (
      <div style={styles.clear} className="donor-table-container">
        {this.state.showLoader ? (
          <Loader backgroundColor='rgba(255,255,255,0.7)' loaderHiding={this.state.loaderHiding} />
        ) : ('')}
        <div id="donors-table-advanced-filters">
        </div>
        {!this.state.showAddDonorModal && this.state.list !== null ? (
          <div>
            <span className="back back-to-all-lists mb20 ml15">
              <Link to={'/dm/lists'} className="back-btn"><i className="fa fa-chevron-left"></i> Go to All Lists</Link>
            </span>
            {this.state.list !== null ? (
              <span className="list-name ml10">{this.state.list.name}</span>
            ) : (null)}

            <BootstrapTable data={this.state.data} options={options} className="donor-table" remote={true} fetchInfo={{ dataTotalSize: this.state.totalDonors }}
              striped={false} hover={true} ref='table' selectRow={this.state.selectRowProp} pagination={true}
              csvFileName={this.props.ngo.ngo_name + ' - Members ' + String(moment().unix()) + '.csv'} keyField='donor_id'
              exportCSV search searchPlaceholder="Search by First Name/Last Name/Email/Phone/Loacation">
              <TableHeaderColumn dataField="donor_id" hidden={true} export={false} searchable={false}>Id</TableHeaderColumn>
              {Object.keys(this.state.defaultTableColumns).indexOf("first_name") > -1 ? (<TableHeaderColumn dataField="first_name" width='150px' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={linkFirstNameFormatter}>First Name</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("last_name") > -1 ? (<TableHeaderColumn dataField="last_name" width='150px' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={linkLastNameFormatter}>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("last_name")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("email") > -1 ? (<TableHeaderColumn dataField="email" width='250px' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={emailFormatter} columnTitle>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("email")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("donor_type") > -1 ? (<TableHeaderColumn dataField="donor_type" width='160px' dataSort={true} export={true} searchable={false} columnTitle dataFormat={this.donorTypeFormatter.bind(this)}>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("donor_type")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("phone_no") > -1 ? (<TableHeaderColumn dataField="phone_no" width='140px' dataSort={true} hidden={false} export={true} searchable={true} columnTitle>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("phone_no")]}</TableHeaderColumn>) : (null)}
              {/*Object.keys(this.state.defaultTableColumns).indexOf("city") > -1 ? (<TableHeaderColumn dataField="city"             width='140px' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={locationFormatter}>Location</TableHeaderColumn>) : (null)*/}
              {Object.keys(this.state.otherTableColumns).indexOf("total_donated") > -1 ? (<TableHeaderColumn dataField="total_donated" width='120px' dataSort={false} hidden={false} export={true} searchable={false} dataFormat={totalContributionFormatter}>Total Contributed</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.otherTableColumns).indexOf("total_donations") > -1 ? (<TableHeaderColumn dataField="total_donations" width='120px' dataSort={false} hidden={false} export={true} searchable={false} dataFormat={totalDonationsFormatter}>{Object.values(this.state.otherTableColumns)[Object.keys(this.state.otherTableColumns).indexOf("total_donations")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.otherTableColumns).indexOf("added_on") > -1 ? (<TableHeaderColumn dataField="added_on" width='100px' dataSort={true} hidden={false} export={true} searchable={false} dataFormat={addedOnFormatter}>Added On</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.otherTableColumns).indexOf("last_donated") > -1 ? (<TableHeaderColumn dataField="date" width='120px' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={dateFormatter}>Last Donated</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("dob") > -1 ? (<TableHeaderColumn dataField="dob" width='100px' dataSort={true} export={true} searchable={false} dataFormat={addedOnFormatter}>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("dob")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("nationality") > -1 ? (<TableHeaderColumn dataField="nationality" width='140px' dataSort={true} export={true} searchable={false} columnTitle>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("nationality")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("country") > -1 ? (<TableHeaderColumn dataField="country" width='140px' dataSort={true} export={true} searchable={true} columnTitle>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("country")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("state") > -1 ? (<TableHeaderColumn dataField="state" width='140px' dataSort={true} export={true} searchable={true} columnTitle>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("state")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("city") > -1 ? (<TableHeaderColumn dataField="city" width='140px' dataSort={true} export={true} searchable={true} columnTitle>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("city")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("zipcode") > -1 ? (<TableHeaderColumn dataField="zipcode" width='100px' dataSort={true} export={true} searchable={false} columnTitle>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("zipcode")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("address1") > -1 ? (<TableHeaderColumn dataField="address1" width='100px' dataSort={true} hidden={true} export={true} searchable={false}>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("address1")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("address2") > -1 ? (<TableHeaderColumn dataField="address2" width='100px' dataSort={true} hidden={true} export={true} searchable={false}>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("address2")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("donor_source") > -1 ? (<TableHeaderColumn dataField="donor_source" width='200px' dataSort={true} export={true} searchable={false} columnTitle>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("donor_source")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.otherTableColumns).indexOf("frequency") > -1 ? (<TableHeaderColumn dataField="frequency" width='140px' dataSort={true} export={true} searchable={false} columnTitle dataFormat={this.frequencyFormatter.bind(this)}>Pledge Frequency</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.otherTableColumns).indexOf("pledged_amount") > -1 ? (<TableHeaderColumn dataField="pledged_amount" width='140px' dataSort={true} export={true} searchable={false} columnTitle dataFormat={this.pledgeAmountFormatter.bind(this)}>Pledge Amount</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("pan_number") > -1 ? (<TableHeaderColumn dataField="pan_number" width='100px' dataSort={true} export={true} searchable={false} columnTitle>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("pan_number")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.otherTableColumns).indexOf("org_name") > -1 ? (<TableHeaderColumn dataField="org_name" width='200px' dataSort={true} export={true} searchable={false} columnTitle>{Object.values(this.state.otherTableColumns)[Object.keys(this.state.otherTableColumns).indexOf("org_name")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.otherTableColumns).indexOf("org_position") > -1 ? (<TableHeaderColumn dataField="org_position" width='200px' dataSort={true} export={true} searchable={false} columnTitle>{Object.values(this.state.otherTableColumns)[Object.keys(this.state.otherTableColumns).indexOf("org_position")]}</TableHeaderColumn>) : (null)}
              {Object.keys(this.state.defaultTableColumns).indexOf("priority") > -1 ? (<TableHeaderColumn dataField="priority" width='100px' dataSort={true} export={true} searchable={false}>{Object.values(this.state.defaultTableColumns)[Object.keys(this.state.defaultTableColumns).indexOf("priority")] + ' (Out Of 5)'}</TableHeaderColumn>) : (null)}
            </BootstrapTable>
          </div>
        ) : (null)}
        {(this.props.ngo.plan.name === 'premium' || this.props.ngo.plan.name === 'payment_gateway_basic_plus') && this.state.showAddDonorModal && this.state.list !== null ? (
          <AddDonor ngo={this.props.ngo} list={this.state.list}
            ngo_id={this.props.ngo.ngo_id}
            showAddDonorModal={this.state.showAddDonorModal}
            assignee={this.props.userData.email}
            onHide={this.onAddDonorClose}
            onAddDonorClose={this.onAddDonorClose}
            onAddDonorOpen={this.onAddDonorOpen} />
        ) : ('')}
        {(this.props.ngo.plan.name === 'premium' || this.props.ngo.plan.name === 'payment_gateway_basic_plus') && this.state.showImportDonorModal && this.state.list !== null ? (
          <ImportDonorsModal ngo_id={this.props.ngo.ngo_id}
            showImportDonorModal={this.state.showImportDonorModal}
            onHide={this.onAddDonorClose} ngo={this.props.ngo}
            onImportClose={this.onImportClose}
            onImportOpen={this.onImportOpen} />
        ) : ('')}

        {this.state.moveMembersModalVisible ? (
          <Modal className="move-members-modal" show={this.state.moveMembersModalVisible}
            onHide={this.hideMoveMembersModal.bind(this)} bsSize="large">
            <Modal.Header>
              <Modal.Title className="modal-header-custom">
                Move Member to List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <label className="control-label is-imp">Select List: </label>
                <select className="form-control" value={this.state.moveMemberToList} onChange={this.handleMoveListChange.bind(this)} required>
                  <option disabled value=''>Select List</option>
                  {this.state.lists.map((list, index) => {
                    return (
                      <option value={list.id} key={index} disabled={list.id === this.props.list_id ? true : false}>{list.name}</option>
                    )
                  })}
                </select>

                <Col md={12} className="move-members-container mt20">
                  <button className="btn move-members-save-button mr15" onClick={this.moveMembers.bind(this)}>Save</button>
                  <button className="btn move-members-cancel-button" onClick={this.hideMoveMembersModal.bind(this)}>Cancel</button>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        ) : (null)}
      </div>
    );
  }
}