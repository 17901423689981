import { FETCH_ALL_UPDATES } from "../../src/components/ImpactManagement/1-projects/constants/constants";

const projectUpdates = (state = [], action) => {
  switch(action.type) {
    case 'FETCH_ALL_UPDATES':
      Object.assign(state, action.data);
      let projectUpdates = {
        "projectUpdates" : state
      }
      return projectUpdates;

    default:
      return state;
  }
}

export default projectUpdates;