import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import $ from 'jquery';
import AddNewDonor from '../add-donation/AddNewDonor';
import moment from 'moment';

const readCookie = require('../../cookie.js').readCookie;

export default class EditDonorDonation extends Component {
  constructor(props) {
    super(props);
    this.state={
      haveReceiptToUpload: false,
      customProject: '',
      receiptNo: this.props.donation.receipt_no,
      receiptUrl: this.props.donation.receipt_url,
      sendEmailReceiptCheck: false,
      haveReceiptCheck: false,
      value: '',
      donor_type: "individual",
      title: "mr",
      first_name: "",
      last_name: "",
      phone_no: "",
      dob: "",
      nationality: "India",
      country: 'India',
      state: '',
      city: '',
      address1: "",
      address2: "",
      zipcode: "",
      donor_source: "",
      pan_number: "",
      rating: 1,
      impDateCount: [],
      currentDonor: null,
      projectData: this.props.projectData,
      projectValue: '-1',
      notFoundDonorEmailValue: '',
      foundDonor: true,
      loadingDonors: true,
      foundDonorHasPAN: null,
      maxDate: '',
      contribution_mode: this.props.donation.contribution_mode,
      transaction_reference_number: this.props.donation.transaction_reference_number ? (this.props.donation.transaction_reference_number) : '',
      showFirstNameError: false,
      showLastNameError: false,
      showPhoneError: false,
      showEmailError: false,
      showDobError: false,
      showNationalityError: false
    };
    this.onSubmitDonation = this.onSubmitDonation.bind(this);
    this.handleHaveReceiptChange = this.handleHaveReceiptChange.bind(this);
    this.handleSendEmailReceiptChange = this.handleSendEmailReceiptChange.bind(this);
    this.checkSubmitButtonsValidity = this.checkSubmitButtonsValidity.bind(this);
    this.changeReceiptNo = this.changeReceiptNo.bind(this);
  }

  handleSendEmailReceiptChange() {
    this.setState({ sendEmailReceiptCheck: !this.state.sendEmailReceiptCheck }, function() {this.checkSubmitButtonsValidity()});
  }

  handleHaveReceiptChange() {
    this.setState({ haveReceiptCheck: !this.state.haveReceiptCheck }, function() {this.checkSubmitButtonsValidity()});
  }

  changeReceiptNo(e) {
    this.setState({ receiptNo: e.target.value }, function() {this.checkSubmitButtonsValidity()});
  }

  customProjectChange(e) {
    this.setState({ customProject: e.target.value }, function() {this.checkSubmitButtonsValidity()});
  }

  onDonorTypeChange(e) {
    this.setState({ donor_type: e.target.value });
  }

  onDonorTitleChange(e) {
    this.setState({ title: e.target.value });
  }

  onDonorFnameChange(e) {
    if(e.target.value === '') this.setState({ showFirstNameError: true });
    else this.setState({ showFirstNameError: false });
    this.setState({ first_name: e.target.value });
  }

  onDonorLnameChange(e) {
    if(e.target.value === '') this.setState({ showLastNameError: true });
    else this.setState({ showLastNameError: false });
    this.setState({ last_name: e.target.value });
  }

  onDonorPhoneChange(value) {
    if(value === '') this.setState({ showPhoneError: true });
    else this.setState({ showPhoneError: false });
    this.setState({ phone_no: value });
  }

  onDonorDobChange(e) {
    if(e.target.value === '') this.setState({ showDobError: true });
    else this.setState({ showDobError: false });
    this.setState({ dob: e.target.value });
  }

  selectNationality = val => {
    if(val === '') this.setState({ showNationalityError: true });
    else this.setState({ showNationalityError: false });
    this.setState({ nationality: val });
  }

  selectCountry=(val) => {
    this.setState({ country: val });
  }

  selectState=(val) => {
    this.setState({ state: val });
  }

  onDonorCityChange(value) {
    this.setState({ city: value });
  }

  onDonorSourceChange(e) {
    this.setState({ donor_source: e.target.value });
  }

  onDonorPanChange(e) {
    this.setState({ pan_number: e.target.value });
  }

  onDonorAddress1Change(e) {
    this.setState({ address1: e.target.value });
  }

  onDonorAddress2Change(e) {
    this.setState({ address2: e.target.value });
  }

  onDonorPincodeChange(e) {
    this.setState({ zipcode: e.target.value });
  }

  onEmailCheck(e) {
    $('#submit_donation').attr('disabled', true);
    $('#submit_donation').attr('disabled', true);
    if(!$('.found-donor-PAN-container').hasClass('hidden')) $('.found-donor-PAN-container').addClass('hidden');
    if (e.target.value === '') {
      this.setState({
        foundDonor: true,
        loadingDonors: true
      });
    } else {
      this.setState({
        foundDonor: false,
        notFoundDonorEmailValue: e.target.value
      }, function () {
        fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngoId + '/donor/' + this.state.notFoundDonorEmailValue, {
          method: "GET",
          headers: {
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          }
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          if(data['status'] === 'ok') {
            this.setState({
              currentDonor: data['donor'],
              foundDonor: true
            });
            if(data['donor']['pan_number'] === '') this.setState({ foundDonorHasPAN: false });
            else this.setState({ foundDonorHasPAN: true });
          }
          return this;
        }.bind(this)).then(function (that) {
          that.setState({ loadingDonors: false });
          if (!that.state.foundDonor) {
          }
        });
      });
    }
  }

  onSubmitDonation() {
    if (this.state.foundDonor) {
      let donor_id=this.state.currentDonor.donor_id;
      let donor_email=this.state.currentDonor.email;
      let donor_fname=this.state.currentDonor.first_name;
      let donor_lname=this.state.currentDonor.last_name;
      let donor_pan=this.state.currentDonor.pan_number;
      let donor_ngo_id=this.state.currentDonor.ngo_id;
      let donor_date=document.getElementById('donation_date').value;
      let new_donor_date=donor_date.split('-');
      new_donor_date=new_donor_date[2] + "/" + new_donor_date[1] + "/" + new_donor_date[0];
      let donor_amount=document.getElementById('donation_amount').value;
      let donor_project = '';
      let donor_project_id=this.state.projectValue;
      let donor_tax=document.getElementById('tax_exemption').options[document.getElementById('tax_exemption').selectedIndex].value;
      let duplicate = this.state.haveReceiptCheck;
      let receiptNo = this.state.receiptNo;
      let contribution_mode = this.state.contribution_mode;
      let transaction_reference_number = this.state.transaction_reference_number;

      if(donor_project_id === '0') donor_project = $('#customProject').val();
      else donor_project = document.getElementById('project_id').options[document.getElementById('project_id').selectedIndex].text;

      let donationDataForReceipt = {
        "duplicate": duplicate,
        "donor_email": donor_email,
        "donor_fname": donor_fname,
        "donor_lname": donor_lname,
        "donor_pan": donor_pan,
        "donor_ngo_id": JSON.parse(readCookie('ngo')).le_ngo_id,
        "donor_date": new_donor_date,
        "donor_amount": donor_amount,
        "donor_project": donor_project,
        "donor_project_id": donor_project_id,
        "donor_tax": donor_tax,
        "org_logo": this.props.ngo.ngo_logo
      }

      if(duplicate) donationDataForReceipt['receiptNo'] = receiptNo;

      fetch(process.env.REACT_APP_API_URL + '/donor/' + donor_id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify({ 'only_PAN': donor_pan })
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
      });

      if(this.state.receiptNo && this.state.receiptUrl) {

        let donationDataForCreation={
            "is_duplicate": duplicate,
            "send_email": this.state.sendEmailReceiptCheck,
            "receiptNo": this.state.receiptNo,
            "receiptUrl": this.state.receiptUrl,
            "amount": donor_amount,
            "timestamp": donor_date,
            "ngoId": donor_ngo_id,
            "project_name": donor_project,
            "project_id": donor_project_id,
            "tax_exemption": donor_tax
          }

          if(transaction_reference_number !== '') donationDataForCreation['transaction_reference_number'] = transaction_reference_number;
          if(contribution_mode !== '') donationDataForCreation['contribution_mode'] = contribution_mode;

          fetch(process.env.REACT_APP_API_URL + '/donations/' + donor_ngo_id + '/update/' + this.props.donation.donation_id, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
            },
            body: JSON.stringify(donationDataForCreation)
          }).then(function (response) {
            return response.json();
          }).then(function (data) {
            if(this.state.haveReceiptToUpload) {
              let donation_id = data.donation_id;
              let receiptFile = $('input#donationReceipt');

              if(receiptFile[0].files.length > 0) {
                var file = new FormData();
                file.append('file', receiptFile[0].files[0]);

                fetch(process.env.REACT_APP_API_URL + '/donations/' + donor_ngo_id + '/add_receipt/' + donation_id, {
                  method: 'POST',
                  headers: {
                    'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
                  },
                  body: file
                }).then(function (response) {
                  return response.json();
                }).then(function (data) {
                  if(data.status === 'ok') this.props.onEditDonationClose('refresh');
                }.bind(this));
              }
            } else {
              this.props.onEditDonationClose('refresh');
            }
          }.bind(this));

      } else {

        fetch(process.env.REACT_APP_LE_RECEIPT_URL, {
          method: 'POST',
          body: JSON.stringify(donationDataForReceipt),
          dataType: "json",
          contentType: "application/json"
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          let donationDataForCreation={
            "is_duplicate": duplicate,
            "send_email": this.state.sendEmailReceiptCheck,
            "receiptNo": data.receiptNo,
            "receiptUrl": data.taxReceipt,
            "amount": donor_amount,
            "timestamp": donor_date,
            "ngoId": donor_ngo_id,
            "project_name": donor_project,
            "project_id": donor_project_id,
            "tax_exemption": donor_tax
          }

          if(transaction_reference_number !== '') donationDataForCreation['transaction_reference_number'] = transaction_reference_number;
          if(contribution_mode !== '') donationDataForCreation['contribution_mode'] = contribution_mode;

          fetch(process.env.REACT_APP_API_URL + '/donations/' + donor_ngo_id + '/update/' + this.props.donation.donation_id, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
            },
            body: JSON.stringify(donationDataForCreation)
          }).then(function (response) {
            return response.json();
          }).then(function (data) {
            if(this.state.haveReceiptToUpload) {
              let donation_id = data.donation_id;
              let receiptFile = $('input#donationReceipt');

              if(receiptFile[0].files.length > 0) {
                var file = new FormData();
                file.append('file', receiptFile[0].files[0]);

                fetch(process.env.REACT_APP_API_URL + '/donations/' + donor_ngo_id + '/add_receipt/' + donation_id, {
                  method: 'POST',
                  headers: {
                    'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
                  },
                  body: file
                }).then(function (response) {
                  return response.json();
                }).then(function (data) {
                  if(data.status === 'ok') this.props.onEditDonationClose('refresh');
                }.bind(this));
              }
            } else {
              this.props.onEditDonationClose('refresh');
            }
          }.bind(this));
        }.bind(this));
      }

    } else {

      let donor_ngo_id=this.props.ngoId;

      // Create Donor
      let user_email = this.props.userEmail;
      let donor_type = this.state.donor_type;
      let donor_title = this.state.title;
      let donor_fname = this.state.first_name;
      let donor_lname = this.state.last_name;
      let donor_phone = this.state.phone_no;
      let donor_email = this.props.notFoundDonorEmailValue;
      let donor_dob = this.state.dob;
      let donor_nationality = this.state.nationality;
      let donor_country = this.state.country;
      let donor_state = this.state.state;
      let donor_pincode = this.state.zipcode;
      let donor_city = this.state.city;
      let donor_pan = this.state.pan_number;
      let donor_address1 = this.state.address1;
      let donor_address2 = this.state.address2;
      let contribution_mode = this.state.contribution_mode;
      let transaction_reference_number = this.state.transaction_reference_number;

      // Donation Details
      let donor_date = document.getElementById('donation_date').value;
      let new_donor_date = donor_date.split('-');
      new_donor_date = new_donor_date[2] + "/" + new_donor_date[1] + "/" + new_donor_date[0];
      let donor_amount = document.getElementById('donation_amount').value;
      let donor_project = '';
      let donor_project_id = this.state.projectValue;
      let donor_tax = document.getElementById('tax_exemption').options[document.getElementById('tax_exemption').selectedIndex].text;

      let receiptNo = this.state.receiptNo;

      if(donor_project_id === '0') donor_project = $('#customProject').val();
      else donor_project = document.getElementById('project_id').options[document.getElementById('project_id').selectedIndex].text;

      if(donor_fname !== '' && donor_lname !== '' && donor_phone !== '' && donor_dob !== '' && donor_nationality !== '') {

        let donorDataForAddingOnDonation={
          donor_type: donor_type,
          title: donor_title,
          first_name: donor_fname,
          last_name: donor_lname,
          phone_no: donor_phone,
          email: donor_email,
          dob: donor_dob,
          nationality: donor_nationality,
          pan_number: donor_pan,
          address1: donor_address1,
          address2: donor_address2,
          country: donor_country,
          state: donor_state,
          city: donor_city,
          zipcode: donor_pincode,
          priority: 1,
          org_assignee: user_email,
          org_name: null,
          org_position: null,
          important_dates: []
        }

        fetch(process.env.REACT_APP_API_URL + '/ngo/' + donor_ngo_id + '/donors', {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify(donorDataForAddingOnDonation)
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          let duplicate = this.state.haveReceiptCheck;

          let donationDataForReceiptAfterDonor={
            "duplicate": duplicate,
            "donor_email": donor_email,
            "donor_fname": donor_fname,
            "donor_lname": donor_lname,
            "donor_pan": donor_pan,
            "donor_ngo_id": JSON.parse(readCookie('ngo')).le_ngo_id,
            "donor_date": new_donor_date,
            "donor_amount": donor_amount,
            "donor_project": donor_project,
            "donor_project_id": donor_project_id,
            "donor_tax": donor_tax,
            "org_logo": this.props.ngo.ngo_logo
          }

          if(duplicate) donationDataForReceiptAfterDonor['receiptNo'] = receiptNo;



          fetch(process.env.REACT_APP_LE_RECEIPT_URL, {
            method: 'POST',
            body: JSON.stringify(donationDataForReceiptAfterDonor),
            dataType: "json",
            contentType: "application/json"
          }).then(function (response) {
            return response.json();
          }).then(function (data) {

            let donationDataForCreationAfterDonor={
              "is_duplicate": duplicate,
              "send_email": this.state.sendEmailReceiptCheck,
              "receiptNo": data.receiptNo,
              "receiptUrl": data.taxReceipt,
              "amount": donor_amount,
              "ngoId": donor_ngo_id,
              "project_name": donor_project,
              "project_id": donor_project_id,
              "tax_exemption": donor_tax
            }

            if(transaction_reference_number !== '') donationDataForCreationAfterDonor['transaction_reference_number'] = transaction_reference_number;
            if(contribution_mode !== '') donationDataForCreationAfterDonor['contribution_mode'] = contribution_mode;

            fetch(process.env.REACT_APP_API_URL + '/donations/' + donor_ngo_id + '/update/' + this.props.donation.donation_id, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
              },
              body: JSON.stringify(donationDataForCreationAfterDonor)
            }).then(function (response) {
              return response.json();
            }).then(function (data) {
              if(this.state.haveReceiptToUpload) {
                let donation_id = data.donation_id;
                let receiptFile = $('input#donationReceipt');

                if(receiptFile[0].files.length > 0) {
                  var file = new FormData();
                  file.append('file', receiptFile[0].files[0]);

                  fetch(process.env.REACT_APP_API_URL + '/donations/' + donor_ngo_id + '/add_receipt/' + donation_id, {
                    method: 'POST',
                    headers: {
                      'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
                    },
                    body: file
                  }).then(function (response) {
                    return response.json();
                  }).then(function (data) {
                    if(data.status === 'ok') this.props.onEditDonationClose('refresh');
                  }.bind(this));
                }
              } else {
                this.props.onEditDonationClose('refresh');
              }
            }.bind(this));
          }.bind(this));
        }.bind(this));
      } else {
        if(donor_fname === '') this.setState({ showFirstNameError: true });
        if(donor_lname === '') this.setState({ showLastNameError: true });
        if(donor_phone === '') this.setState({ showPhoneError: true });
        if(donor_dob === '') this.setState({ showDobError: true });
        if(donor_nationality === '') this.setState({ showNationalityError: true });
      }
    }
  }

  onProjectChange(e) {
    let projectValue=$(e.target).find(':selected').attr('data-key');
    this.setState({ projectValue }, function () {
      if (projectValue === '-1') {
        $('#tax_exemption').attr('disabled', false);
        this.checkTaxExemptionWithPAN();
        this.checkSubmitButtonsValidity();
      } else if(projectValue === '0') {
        $('#tax_exemption').attr('disabled', false);
        this.checkTaxExemptionWithPAN();
        this.checkSubmitButtonsValidity();
      } else {
        for (var i=0; i < this.state.projectData.length; i++) {
          if (projectValue === this.state.projectData[i].id) {
            let tax=this.state.projectData[i].tax;
            $('#tax_exemption').attr('disabled', true);
            $('#tax_exemption').val(tax);
            this.checkTaxExemptionWithPAN();
            this.checkSubmitButtonsValidity();
            break;
          }
        }
      }
    })
  }

  checkTaxExemptionWithPAN() {
    var tax_exemption = $('#tax_exemption').val();
    if(this.state.foundDonor) {
      if(tax_exemption === '50' || tax_exemption === '100') {
        if(this.state.currentDonor !== null) {
          if(this.state.currentDonor['pan_number'] === '') {
            $('.found-donor-PAN-container').removeClass('hidden');
          }
        }
      } else {
        if(!$('.found-donor-PAN-container').hasClass('hidden')) $('.found-donor-PAN-container').addClass('hidden');
      }
    }
    this.checkSubmitButtonsValidity();
  }

  onDonationAmountChange() {
    this.checkSubmitButtonsValidity();
  }

  checkSubmitButtonsValidity() {
    let donor_email_field=document.getElementById('donor_email').value;
    let donor_donation_date_field=document.getElementById('donation_date').value;
    let donor_donation_amount_field=document.getElementById('donation_amount').value;
    let contribution_mode = this.state.contribution_mode;

    if(this.state.haveReceiptCheck && this.state.receiptNo !== '' && contribution_mode !== '') {
      $('#submit_donation').attr('disabled', true);
      if (donor_email_field !== '' && donor_donation_date_field !== '' && donor_donation_amount_field !== '') {
        if(this.state.projectValue === '0' && this.state.customProject !== '') {
          if(!$('.found-donor-PAN-container').hasClass('hidden')) {
            if($('#tax_exemption').val() !== '') {
              if($('#tax_exemption').val() === '50' || $('#tax_exemption').val() === '100') {
                if($('#foundDonorPAN').val() === '') $('#submit_donation').attr('disabled', true);
                else $('#submit_donation').attr('disabled', false);
              } else {
                $('#submit_donation').attr('disabled', false);
              }
            } else {
              $('#submit_donation').attr('disabled', true);
            }
          } else {
            $('#submit_donation').attr('disabled', false);
          }
        } else if(this.state.projectValue !== '0') {
          if(!$('.found-donor-PAN-container').hasClass('hidden')) {
            if($('#tax_exemption').val() !== '') {
              if($('#tax_exemption').val() === '50' || $('#tax_exemption').val() === '100') {
                if($('#foundDonorPAN').val() === '') $('#submit_donation').attr('disabled', true);
                else $('#submit_donation').attr('disabled', false);
              } else {
                $('#submit_donation').attr('disabled', false);
              }
            } else {
              $('#submit_donation').attr('disabled', true);
            }
          } else {
            $('#submit_donation').attr('disabled', false);
          }
        } else {
          $('#submit_donation').attr('disabled', true);
        }
      } else {
        $('#submit_donation').attr('disabled', true);
      }
    } else if(!this.state.haveReceiptCheck && contribution_mode !== '') {
      $('#submit_donation').attr('disabled', true);
      if (donor_email_field !== '' && donor_donation_date_field !== '' && donor_donation_amount_field !== '') {
        if(this.state.projectValue === '0' && this.state.customProject !== '') {
          if(!$('.found-donor-PAN-container').hasClass('hidden')) {
            if($('#tax_exemption').val() !== '') {
              if($('#tax_exemption').val() === '50' || $('#tax_exemption').val() === '100') {
                if($('#foundDonorPAN').val() === '') $('#submit_donation').attr('disabled', true);
                else $('#submit_donation').attr('disabled', false);
              } else {
                $('#submit_donation').attr('disabled', false);
              }
            } else {
              $('#submit_donation').attr('disabled', true);
            }
          } else {
            $('#submit_donation').attr('disabled', false);
          }
        } else if(this.state.projectValue !== '0') {
          if(!$('.found-donor-PAN-container').hasClass('hidden')) {
            if($('#tax_exemption').val() !== '') {
              if($('#tax_exemption').val() === '50' || $('#tax_exemption').val() === '100') {
                if($('#foundDonorPAN').val() === '') $('#submit_donation').attr('disabled', true);
                else $('#submit_donation').attr('disabled', false);
              } else {
                $('#submit_donation').attr('disabled', false);
              }
            } else {
              $('#submit_donation').attr('disabled', true);
            }
          } else {
            $('#submit_donation').attr('disabled', false);
          }
        } else {
          $('#submit_donation').attr('disabled', true);
        }
      } else {
        $('#submit_donation').attr('disabled', true);
      }
    } else {
      $('#submit_donation').attr('disabled', true);
    }
  }

  onTaxExemptionChange() {
    this.checkTaxExemptionWithPAN();
  }

  onFoundDonorPANChange() {
    var currentDonor = this.state.currentDonor;
    currentDonor['pan_number'] = $('#foundDonorPAN').val();
    this.setState({ currentDonor });
  }

  componentDidMount() {
    $('#tax_exemption').attr('disabled', false);
    this.checkTaxExemptionWithPAN();

    $('#submit_donation').attr('disabled', true);
    $('#submit_donation').attr('disabled', true);
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd < 10) dd = '0' + dd;
    if(mm < 10) mm = '0' + mm;
    today = yyyy + '-' + mm + '-' + dd;
    this.setState({ maxDate: today });
    if(this.props.donorId !== '') {
      let project = this.props.donation.project;
      if(project === 'General') $('#project_id option[data-key=-1]').prop('selected', true);
      else $('#project_id').val(this.props.donation.project);
      $('#donation_date').val(moment(this.props.donation.date).format('YYYY-MM-DD'));

      fetch(process.env.REACT_APP_API_URL + '/donor/' + this.props.donorId, {
        method: "GET",
        headers: {
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        }
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        var email = data['donor']['email'];
        fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngoId + '/donor/' + email, {
          method: "GET",
          headers: {
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          }
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          if(data['status'] === 'ok') {
            $('#donor_email').val(email)
            this.setState({
              currentDonor: data['donor'],
              foundDonor: true
            });
            if(data['donor']['pan_number'] === '') this.setState({ foundDonorHasPAN: false });
            else this.setState({ foundDonorHasPAN: true });
          }
          return this;
        }.bind(this)).then(function (that) {
          that.setState({ loadingDonors: false });
          if (!that.state.foundDonor) {
          }
        });
      }.bind(this));
    }
  }

  showReceiptUploadButton() {
    $('#cancelUploadReceipt').removeClass('hidden');
    this.setState({ haveReceiptToUpload: true });
  }

  cancelChangeReceipt() {
    $('#cancelUploadReceipt').addClass('hidden');
    $('input#donationReceipt').val('');
    this.setState({ haveReceiptToUpload: false });
  }

  onTransactionReferenceNumberChange(e) {
    this.setState({ transaction_reference_number: e.target.value });
  }

  onContributionModeChange(e) {
    this.setState({ contribution_mode: e.target.value });
    this.checkSubmitButtonsValidity();
  }

  render() {
    console.log(JSON.parse(readCookie('ngo')));
    return (
      <div>
        <Row >
          <Col className="margin-col-50" md={12}>
            <form name="existingDonorForm" ref="vForm">
              <Row>
                  <Col className="form-group" md={8}>
                      <label className="control-label is-imp">Donor Email: </label>
                      {/*<input type="email" className="form-control" id="donor_email" name="donor_email"
                        placeholder="donor@domain.com" onBlur={this.onEmailCheck.bind(this)} />*/}
                        <input type="text" className="form-control" id="donor_email" name="donor_email"
                        placeholder="donor@domain.com" readOnly />
                      <span> <span className="red-color" > Input is too long! </span></span>
                  </Col>

                  <Col className="form-group" md={4} >
                      <label className="control-label is-imp">Donation Date: </label>
                      <input type="date" className="form-control" id="donation_date" max={this.state.maxDate} format='DD-MM-YYYY'
                        name="donation_date" placeholder="22/11/17" required />
                      <span><span className="red-color"> Input is too long! </span></span>
                  </Col>
              </Row>


              {(() => {
                if (this.state.foundDonor === false && !this.state.loadingDonors) {
                  return (
                    <AddNewDonor country={this.state.country} state={this.state.state}
                      nationality={this.state.nationality} selectNationality={this.selectNationality}
                      selectCountry={this.selectCountry} selectState={this.selectState}
                      notFoundDonorEmailValue={this.state.notFoundDonorEmailValue}
                      onDonorTypeChange={this.onDonorTypeChange.bind(this)} donor={this.state}
                      onDonorTitleChange={this.onDonorTitleChange.bind(this)}
                      onDonorFnameChange={this.onDonorFnameChange.bind(this)}
                      onDonorLnameChange={this.onDonorLnameChange.bind(this)}
                      onDonorPhoneChange={this.onDonorPhoneChange.bind(this)}
                      onDonorDobChange={this.onDonorDobChange.bind(this)}
                      onDonorPanChange={this.onDonorPanChange.bind(this)}
                      onDonorSourceChange={this.onDonorSourceChange.bind(this)}
                      onDonorAddress1Change={this.onDonorAddress1Change.bind(this)}
                      onDonorAddress2Change={this.onDonorAddress2Change.bind(this)}
                      onDonorCityChange={this.onDonorCityChange.bind(this)}
                      onDonorPincodeChange={this.onDonorPincodeChange.bind(this)}
                      showFirstNameError={this.state.showFirstNameError}
                      showLastNameError={this.state.showLastNameError}
                      showPhoneError={this.state.showPhoneError}
                      showEmailError={this.state.showEmailError}
                      showDobError={this.state.showDobError}
                      showNationalityError={this.state.showNationalityError} />
                  );
                }
              })()}

              <Row>
                <Col className="form-group" md={8}>
                  <label className="control-label is-imp">Select A Project: </label>
                  <select className="form-control" id="project_id"
                    name="project" required onChange={this.onProjectChange.bind(this)}>
                    {this.state.projectData.ngoProjects.length ? (
                      <optgroup label="Projects of NGO">
                        <option value="Towards NGO" data-key="-1">General</option>
                        {this.state.projectData.ngoProjects.map(function (data) {
                          return (
                            <option key={data.id} data-key={data.id} value={data.title}>
                              {data.title}
                            </option>
                          );
                        })}
                      </optgroup>
                    ) : (null)}
                    <option value="Towards NGO" data-key="0">Create Custom Project</option>
                    {this.state.projectData.customProjects.length ? (
                      <optgroup label="Custom Projects">
                        {this.state.projectData.customProjects.map(function (data) {
                          return (
                            <option key={data.id} data-key={data.id} value={data.title}>
                              {data.title}
                            </option>
                          );
                        })}
                      </optgroup>
                    ) : (null)}
                  </select>
                  {this.state.projectValue === '0' ? (
                    <div>
                      <label className="control-label is-imp">Custom Project Name: </label>
                      <input id="customProject" name="customProject" className="form-control"
                        placeholder="Custom Project" type="text" required value={this.state.customProject} onChange={this.customProjectChange.bind(this)} />
                    </div>
                  ) : ('')}
                </Col>

                <Col className="form-group" md={4} >
                  <label className="control-label is-imp">Select Tax Exemption:</label>
                  <select className="form-control" id="tax_exemption"
                    name="tax" required disabled onChange={this.onTaxExemptionChange.bind(this)}>
                    <option value="0">None</option>
                    <option value="50">80 G</option>
                    <option value="100">35 AC</option>
                  </select>
                </Col>
              </Row>

              <Row>
                <Col className="form-group" md={6} >
                  <label className="control-label is-imp">Mode of Contribution:</label>
                  <select className="form-control" id="contribution_mode" defaultValue={this.props.donation.contribution_mode}
                    required onChange={this.onContributionModeChange.bind(this)}>
                    <option value="" selected disabled>None Selected</option>
                    <option value="Online Gateway">Online Gateway</option>
                    <option value="Cheque">Cheque</option>
                    <option value="Cash">Cash</option>
                    <option value="NEFT">NEFT</option>
                    <option value="Others">Others</option>
                  </select>
                </Col>

                <Col className="form-group" md={6}>
                  <label className="control-label">Transaction Reference Number (if any): </label>
                  <input id="transaction_reference_number" className="form-control"
                    defaultValue={this.props.donation.transaction_reference_number ? (this.props.donation.transaction_reference_number) : ('')}
                    placeholder="Transaction Reference Number" type="text" required
                    onChange={this.onTransactionReferenceNumberChange.bind(this)} />
                </Col>
              </Row>

              <Row >
                <Col className="form-group" md={6} >
                  <label className="control-label is-imp">Donation Amount:</label>
                  <div className="input-group">
                    <span className="input-group-addon"><i className="fa fa-inr"></i></span>
                    <input id="donation_amount" name="donation_amount" className="form-control" defaultValue={this.props.donation.amount}
                      placeholder="5000" type="number" required onChange={this.onDonationAmountChange.bind(this)} />
                  </div>
                </Col>

                <Col className="form-group found-donor-PAN-container hidden" md={6}>
                  <label className="control-label is-imp">PAN:</label>
                  <div className="input-group">
                    <input id="foundDonorPAN" name="foundDonorPAN" className="form-control"
                      placeholder="BJKPC1221X" type="text" required onChange={this.onFoundDonorPANChange.bind(this)} />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="form-group" md={12}>
                  <label className="control-label is-imp"> Send an email with the receipt to donor? </label>
                  <div className="ondisplay">
                    <section>
                      <div className="slideThree">
                        <input type="checkbox" id="sendEmailReceiptCheck" name="check"
                        className="send_email_receipt_check" checked={this.state.sendEmailReceiptCheck} onChange={this.handleSendEmailReceiptChange} />
                        <label htmlFor="sendEmailReceiptCheck"></label>
                      </div>
                    </section>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="form-group" md={12}>
                  <label className="control-label is-imp"> Already have a receipt? </label>
                  <div className="ondisplay">
                    <section>
                      <div className="slideThree">
                        <input type="checkbox" id="haveReceiptCheck" name="check"
                        className="have_receipt_check" checked={this.state.haveReceiptCheck} onChange={this.handleHaveReceiptChange} />
                        <label htmlFor="haveReceiptCheck"></label>
                      </div>
                    </section>
                  </div>
                  <Col className="form-group" md={12} hidden={!this.state.haveReceiptCheck}>
                    <Row>
                      <Col md={6}>
                        <label className="control-label is-imp">Receipt Id: </label>
                        <input id="donationreceiptNo" className="form-control mb10" type="text" placeholder="Receipt Id" value={this.state.receiptNo} onChange={this.changeReceiptNo} />
                      </Col>
                      <Col md={6}>
                        <label className="control-label">Upload Receipt: </label>
                        <div className="upload-receipt">
                          <input id="donationReceipt" className="mb10" type="file" onChange={this.showReceiptUploadButton.bind(this)} />
                          <Button id="cancelUploadReceipt" className="btn-success react-bs-table-add-btn col-md-offset-2 col-md-4 hidden" onClick={this.cancelChangeReceipt.bind(this)}>Cancel</Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Row>

              <div className="form-group btn-container">
                <Button onClick={this.props.onEditDonationClose} className="btn btn-danger brand-color-background">Cancel</Button>
                <Button className="btn btn-warning brand-color-background"
                  id="submit_donation" onClick={() => this.onSubmitDonation()}>
                  Submit
                  <span className="glyphicon glyphicon-send"></span>
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    );
  }
}
