import swal from 'sweetalert2';

const $ = window.$;

export function checkIfCanBeConditional(elementIndex, element) {
	if($('._form-holder ._element-container').find('._form-element._can-be-parent').length) {
		if(element == 'canBeParent' && $('._form-holder ._element-container').find('._form-element._can-be-parent').length > 1) $('#_single-element-settings').find('._single-element-settings-div').find('._has-parent-div').show();
		else if (element == '') $('#_single-element-settings').find('._single-element-settings-div').find('._has-parent-div').show();
		else if(element == 'canBeParent' && $('._form-holder ._element-container').find('._form-element._can-be-parent').length == 1) $('#_single-element-settings').find('._single-element-settings-div').find('._has-parent-div').hide();
	} else {
		$('#_single-element-settings').find('._single-element-settings-div').find('._has-parent-div').hide();
	}

	$('#_single-element-settings').find('._single-element-settings-div').find('input#hasParent').change(function() {
		if($(this).prop('checked')) {
			$('<div class="_select-parent-div mb10"><div class="col-md-12"><label class="active control-label is-imp">Select Parent Question</label><select id="parentQuestionSelect" class="browser-default" required=""><option selected disabled value="">Select A Question</option></select></div></div>').insertBefore($('#_single-element-settings').find('._single-element-settings-div').find('.col-md-12').last());
			var parentList = [];
			fetch(window.conditionalTitleUrl, {
				method: 'GET',
				headers: {
					"Authorization": window.userAuthData
				}
			}).then(function(data) { return data.json() })
			.then(function(data) {
				parentList = data.data;
				for(var i = 0; i < parentList.length; i++){
					if(elementIndex !== parentList[i]['index']) {
						var questionLabel = '';
						if(parentList[i].type !== 'name' && parentList[i].type !== 'address' && parentList[i].type !== 'content' && parentList[i].type !== 'pagebreak' && parentList[i].type !== 'location' && parentList[i].type !== 'distance' && parentList[i].type !== 'signature' && parentList[i].type !== 'scale' && parentList[i].type !== 'areamap' && parentList[i].type !== 'geoLocation'){
							if(parentList[i].type === 'choice') questionLabel = parentList[i].choiceLabel;
							else if(parentList[i].type === 'yesno') questionLabel = parentList[i].yesnoLabel;
							else if(parentList[i].type === 'payment') questionLabel = parentList[i].paymentLabel;
							else if(parentList[i].type === 'correlation') questionLabel = parentList[i].relationLabel;
							else questionLabel = parentList[i].label;
							$('#_single-element-settings').find('._single-element-settings-div').find('._select-parent-div').find('select#parentQuestionSelect').last().append('<option value="' + parentList[i].type + '" data-questionIndex="' + parentList[i].index + '">' + questionLabel + '</option>');
						}
					}
				}
				$('#_single-element-settings').find('._single-element-settings-div').find('._select-parent-div').find('select#parentQuestionSelect').change(function() {
					var selectedIndex = $(this).find(':selected').attr('data-questionIndex');
					var selectedParent = $('._form-holder').find('._element-container').find('._form-element[data-index="' +selectedIndex+'"]');
					var selectedParentJSON = JSON.parse(selectedParent.attr('data-json'));
					var selectedType = $(this).find(':selected').val();
					if(selectedType == 'textbox' || selectedType == 'textarea' || selectedType == 'email' || selectedType == 'website') selectedType = 'text';
					else if(selectedType == 'rating' || selectedType == 'currency') selectedType = 'number';
					else if(selectedType == 'yesno' || selectedType == 'correlation') selectedType = 'choice';

					fetch(window.conditionalOptionUrl, {
						method: 'POST',
						headers: {
							"Authorization": window.userAuthData
						},
						body: JSON.stringify({ type: selectedType })
					}).then(function(data) { return data.json() })
					.then(function(data){
						var options = '<option value="" selected disabled>Select Question Criteria</option>';
						for(var i = 0; i < data.length; i++) {
							if(selectedParentJSON['type'] == 'choice' && (selectedParentJSON['choiceType'] == 'radio'|| selectedParentJSON['choiceType'] == 'dropdown')){
								if(data[i] != 'all' || data[i] != 'any' || data[i] != 'none') options += '<option value="' + data[i] + '">' + data[i] + '</option>';
							} else {
								options += '<option value="' + data[i] + '">' + data[i] + '</option>';
							}
						}

						if($('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div').length) {
							$('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div #parentQuestionCriteriaSelect').html(options);
							$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').remove();
						} else {
							$('<div class="_parent-criteria-div mb10"><div class="col-md-12"><label class="active control-label is-imp">Select Parent Criteria</label><select id="parentQuestionCriteriaSelect" class="browser-default" required="">' + options + '</select></div></div>').insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._select-parent-div'));
						}

						$('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div').find('select#parentQuestionCriteriaSelect').change(function(e) {
							var selectedCriteria = $(this).find(':selected').val();
							if(selectedCriteria == 'equals' || selectedCriteria == 'is not equal' || selectedCriteria == 'is'
								|| selectedCriteria == 'is not' || selectedCriteria == 'starts with' || selectedCriteria == 'does not start with'
								|| selectedCriteria == 'ends with' || selectedCriteria == 'does not end with' || selectedCriteria == 'contains'
								|| selectedCriteria == 'does not contain' || selectedCriteria == 'is less than'
								|| selectedCriteria == 'is greater than' || selectedCriteria == 'is after' || selectedCriteria == 'is before'
								|| selectedCriteria == 'day is' || selectedCriteria == 'month is' || selectedCriteria == 'year is'
								|| selectedCriteria == 'hour is' || selectedCriteria == 'minute is') {
								var element = '';
								if(selectedType == 'text') {
									element = '<input type="text" class="browser-default form-control" />';
								} else if(selectedType == 'number') {
									element = '<input type="number" className="browser-default form-control" />';
								} else if(selectedType == 'date') {
									element = '<input type="date" className="datepicker" />';
								} else if(selectedType == 'time') {
									element = '<input type="time" className="browser-default form-control"/>';
								} else if(selectedType == 'choice') {
									if(selectedParentJSON['choiceType'] == 'radio'){
										element = '<div class="choice-answer-holder"><input type="radio" id="option-0" value="'+selectedParentJSON['choicePlaceholder1']+'" name="choiceradio" /><label for="option-0" class="control-label">'+selectedParentJSON['choicePlaceholder1']+'</label></div>'
										if(selectedParentJSON['newChoiceOptions'].length){
											for(var i = 0; i < selectedParentJSON['newChoiceOptions'].length; i++){
												element += '<div class="choice-answer-holder"><input type="radio" id="option-'+ String(i + 1) +'" value="'+selectedParentJSON['newChoiceOptions'][i]+'" name="choiceradio" /><label for="option-'+ String(i + 1) +'" class="control-label">'+selectedParentJSON['newChoiceOptions'][i]+'</label></div>'
											}
										}
									} else if(selectedParentJSON['choiceType'] == 'dropdown'){
										if(selectedParentJSON['newChoiceOptions'].length){
											element = '<select id="dropdown-type-select" class="browser-default"><option value="'+selectedParentJSON['choicePlaceholder1']+'" selected>'+selectedParentJSON['choicePlaceholder1']+'</option>';
											for(var i = 0; i < selectedParentJSON['newChoiceOptions'].length; i++){
												element += '<option value="'+selectedParentJSON['newChoiceOptions'][i]+'">'+selectedParentJSON['newChoiceOptions'][i]+'</option>'
											}
											element += '</select>';
										} else {
											element = '<select id="dropdown-type-select" class="browser-default"><option value="'+selectedParentJSON['choicePlaceholder1']+'" selected>'+selectedParentJSON['choicePlaceholder1']+'</option></select>'
										}
									} else if(selectedParentJSON['choiceType'] == 'checkbox'){
										element = '<div class="choice-answer-holder"><input type="checkbox" id="option-0" value="'+selectedParentJSON['choicePlaceholder1']+'"/><label for="option-0" class="control-label">'+selectedParentJSON['choicePlaceholder1']+'</label></div>'
										if(selectedParentJSON['newChoiceOptions'].length){
											for(var i = 0; i < selectedParentJSON['newChoiceOptions'].length; i++){
												element += '<div class="choice-answer-holder"><input type="checkbox" id="option-'+ String(i + 1) +'" value="'+selectedParentJSON['newChoiceOptions'][i]+'"/><label for="option-'+ String(i + 1) +'" class="control-label">'+selectedParentJSON['newChoiceOptions'][i]+'</label></div>'
											}
										}
										element += '<div class="checkbox-select-type"><div class="col-md-6"><input name="checkboxselecttype" type="radio" id="checkbox-select-type-and" value="and" name="checkbox-select-type"><label for="checkbox-select-type-and" class="control-label">And</label></div><div class="col-md-6"><input name="checkboxselecttype" type="radio" id="checkbox-select-type-or" value="or" name="checkbox-select-type" /><label for="checkbox-select-type-or" class="control-label">Or</label></div></div>';
									} else if(selectedParentJSON['type'] == 'correlation'){
										element = '<select id="dropdown-type-select" class="browser-default">';
										for(var i = 0; i < selectedParentJSON['selectedRelationalResponses'].length; i++){
											element += '<option value="'+selectedParentJSON['selectedRelationalResponses'][i]+'">'+selectedParentJSON['selectedRelationalResponses'][i]+'</option>'
										}
										element += '</select>';
									} else {
										element = '<div class="choice-answer-holder"><input type="radio" id="option-0" value="'+selectedParentJSON['yesnoPlaceholder1']+'" name="yes/no" /><label for="option-0" class="control-label">'+selectedParentJSON['yesnoPlaceholder1']+'</label><input type="radio" id="option-1" value="'+selectedParentJSON['yesnoPlaceholder2']+'" name="yes/no" /><label for="option-1" class="control-label ml10">'+selectedParentJSON['yesnoPlaceholder2']+'</label></div>'
									}
								}

								if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').length){
									$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').html('<div class="col-md-12"><label class="active control-label is-imp">Enter Criteria Value</label></div>');
									if(selectedType == 'choice' && (selectedParentJSON['choiceType'] == 'checkbox' || selectedParentJSON['choiceType'] == 'radio')){
										if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').length === 0){
											$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
										} else {
											$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').remove();
											if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').length){
												$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').remove();
											}
											$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
										}
									} else if((selectedType == 'choice' && selectedParentJSON['choiceType'] == 'dropdown') || selectedParentJSON['type'] == 'correlation') {
										if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').length === 0){
											$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
										} else {
											$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').remove();
											$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
										}
									} else {
										if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('input').length === 0){
											$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
										} else {
											if(selectedType == 'choice' && (selectedParentJSON['choiceType'] == 'checkbox' || selectedParentJSON['choiceType'] == 'radio')){
												$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').remove();
												if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').length){
													$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').remove();
												}
												$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
											} else if((selectedType == 'choice' && selectedParentJSON['choiceType'] == 'dropdown') || selectedParentJSON['type'] == 'correlation'){
												$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').remove();
												$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
											} else {
												$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('input').remove();
												$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
											}
										}
									}
								} else {
									$('<div class="_answer-value-div mb10"><div class="col-md-12"><label class="active control-label is-imp">Enter Criteria Value</label>' + element + '</div></div>').insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div'));
								}
							} else {
								if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').length){
									$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').remove();
								}
							}
						});
					});
					var elementIndex = $(this).find(':selected').attr('data-questionIndex');
					var selectedElementJSON = $('._form-holder ._element-container').find('._form-element[data-index="' + elementIndex + '"]').data('json');
					if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);
				});
			})
		} else {
			$('#_single-element-settings').find('._single-element-settings-div').find('._select-parent-div').remove();
			$('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div').remove();
			$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').remove();
		}
	});
}

function getConditionalJSON(elementIndex) {
	var parentIndex = $('#_single-element-settings').find('._single-element-settings-div').find('._select-parent-div').find('select').find('option:selected').attr('data-questionindex');
	var parentQuestion = $('#_single-element-settings').find('._single-element-settings-div').find('._select-parent-div').find('select').val();
	var parentCriteriaChoice = $('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div').find('select').val();
	var selectedParent = $('._form-holder').find('._element-container').find('._form-element[data-index="' +parentIndex+'"]');
	var selectedParentJSON = {};
	if(selectedParent.attr('data-json')) selectedParentJSON = JSON.parse(selectedParent.attr('data-json'));
	var parentAnswer = '';
	if(selectedParentJSON['type'] == "choice" && selectedParentJSON['choiceType'] == 'checkbox'){
		parentAnswer = [];
		$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('input[type="checkbox"]:checked').each(function() {
			if($(this).next('label').text() !== '') parentAnswer.push($(this).next('label').text());
		})
	} else if(selectedParentJSON['type'] == "choice" && selectedParentJSON['choiceType'] == 'radio'){
		parentAnswer = [];
		$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('input[type="radio"]:checked').each(function() {
			if($(this).next('label').text() !== '') parentAnswer = ($(this).next('label').text());
		})
	} else if((selectedParentJSON['type'] == "choice" && selectedParentJSON['choiceType'] == 'dropdown') || selectedParentJSON['type'] == "correlation"){
		parentAnswer = $('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').val();
	} else if(selectedParentJSON['type'] == "yesno"){
		$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').find('input[type="radio"]:checked').each(function() {
			if($(this).next('label').text() !== '') parentAnswer = ($(this).val());
		})
	} else {
		parentAnswer = $('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('input').val();
	}

	if(parentIndex !== null && parentQuestion && parentCriteriaChoice){
		if(selectedParentJSON['type'] == "choice" && selectedParentJSON['choiceType'] == 'checkbox'){
			if((parentCriteriaChoice == 'equals' || parentCriteriaChoice == 'does not equal') && parentAnswer.length) {
				parentIndex = parseInt(parentIndex);
				var selectedChoiceConditionType = $('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').find('input[type="radio"]:checked').val();

				var sortedElement = $('._form-element[data-index="' + elementIndex + '"]');
				var sortedParent = $('._form-element[data-index="' + parentIndex + '"]')
				parentIndex = $(sortedParent).data('index');

				var parentJSON = {};
				parentJSON['parentIndex'] = parentIndex;
				parentJSON['parentQuestion'] = parentQuestion;
				parentJSON['parentCriteriaChoice'] = parentCriteriaChoice;
				parentJSON['parentAnswer'] = parentAnswer;
				parentJSON['selectedChoiceConditionType'] = selectedChoiceConditionType;
				parentJSON['selectedParentData'] = selectedParentJSON;
				parentJSON['isParent'] = true;
				var parentJSONString = JSON.stringify(parentJSON);
				return parentJSONString;
			} else if(parentCriteriaChoice == 'all' || parentCriteriaChoice == 'none' || parentCriteriaChoice == 'any'){
				var sortedElement = $('._form-element[data-index="' + elementIndex + '"]');
				var sortedParent = $('._form-element[data-index="' + parentIndex + '"]')
				parentIndex = $(sortedParent).data('index');

				var parentJSON = {};
				parentJSON['parentIndex'] = parentIndex;
				parentJSON['parentQuestion'] = parentQuestion;
				parentJSON['parentCriteriaChoice'] = parentCriteriaChoice;
				parentJSON['parentAnswer'] = '';
				parentJSON['selectedParentData'] = selectedParentJSON;
				parentJSON['isParent'] = true;
				var parentJSONString = JSON.stringify(parentJSON);
				return parentJSONString;
			}
		} else {
			if((parentCriteriaChoice == 'equals' || parentCriteriaChoice == 'is not equal' || parentCriteriaChoice == 'does not equal' || parentCriteriaChoice == 'is' || parentCriteriaChoice == 'is not' || parentCriteriaChoice == 'starts with' || parentCriteriaChoice == 'does not start with' || parentCriteriaChoice == 'ends with' || parentCriteriaChoice == 'does not end with' || parentCriteriaChoice == 'contains' || parentCriteriaChoice == 'does not contain' || parentCriteriaChoice == 'is less than' || parentCriteriaChoice == 'is greater than' || parentCriteriaChoice == 'is after' || parentCriteriaChoice == 'is before' || parentCriteriaChoice == 'day is' || parentCriteriaChoice == 'month is' || parentCriteriaChoice == 'year is' || parentCriteriaChoice == 'is today' || parentCriteriaChoice == 'is in future' || parentCriteriaChoice == 'is in past' || parentCriteriaChoice == 'hour is' || parentCriteriaChoice == 'minute is') && parentAnswer && parentAnswer !== '') {
				parentIndex = parseInt(parentIndex);
				var sortedElement = $('._form-element[data-index="' + elementIndex + '"]');
				var sortedParent = $('._form-element[data-index="' + parentIndex + '"]')
				parentIndex = $(sortedParent).data('index');
				var parentJSON = {};
				parentJSON['parentIndex'] = parentIndex;
				parentJSON['parentQuestion'] = parentQuestion;
				parentJSON['parentCriteriaChoice'] = parentCriteriaChoice;
				parentJSON['parentAnswer'] = parentAnswer;
				parentJSON['selectedParentData'] = selectedParentJSON;
				parentJSON['isParent'] = true;
				var parentJSONString = JSON.stringify(parentJSON);
				return parentJSONString;
			} else if(parentCriteriaChoice == 'all' || parentCriteriaChoice == 'any' || parentCriteriaChoice == 'none'
				|| parentCriteriaChoice == 'is blank/empty' || parentCriteriaChoice == 'is not blank/empty'
				|| parentCriteriaChoice == 'is positive' || parentCriteriaChoice == 'is positive or zero'
				|| parentCriteriaChoice == 'is negative' || parentCriteriaChoice == 'is negative or zero'){
				parentIndex = parseInt(parentIndex);
				var sortedElement = $('._form-element[data-index="' + elementIndex + '"]');
				var sortedParent = $('._form-element[data-index="' + parentIndex + '"]')
				parentIndex = $(sortedParent).data('index');

				var parentJSON = {};
				parentJSON['parentIndex'] = parentIndex;
				parentJSON['parentQuestion'] = parentQuestion;
				parentJSON['parentCriteriaChoice'] = parentCriteriaChoice;
				parentJSON['parentAnswer'] = '';
				parentJSON['selectedParentData'] = selectedParentJSON;
				parentJSON['isParent'] = true;
				var parentJSONString = JSON.stringify(parentJSON);
				return parentJSONString;
			} else {
				swal({
					title: 'Please fill up the conditional fields properly.',
					type: 'error'
				});
			}
		}
	}
}

function changeKeyIndex(index) {
	var selectedElement = $('._element-container').find('._form-element').eq(index);
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	selectedElementJSON['key'] = index;
	selectedElementJSON['index'] = index;

	selectedElementJSON = JSON.stringify(selectedElementJSON);

	$(selectedElement).data('json', selectedElementJSON);
	$(selectedElement).attr('data-json', selectedElementJSON);
}

export function readConditional(elementIndex, parentJSON) {
	if(parentJSON){
		$('<div class="_select-parent-div mb10"><div class="col-md-12"><label class="active control-label is-imp">Select Parent Question</label><select id="parentQuestionSelect" class="browser-default" required=""><option selected disabled value="">Select A Question</option></select></div></div>').insertBefore($('#_single-element-settings').find('._single-element-settings-div').find('.col-md-12').last());
		var parentList = [];
		fetch(window.conditionalTitleUrl, {
			method: 'GET',
			headers: {
				"Authorization": window.userAuthData
			}
		}).then(function(data) {return data.json() })
		.then(function(data) {
			parentList = data.data;
			for(var i = 0; i < parentList.length; i++){
				if(elementIndex !== parentList[i]['index']) {
					var questionLabel = '';
					if(parentList[i].type !== 'name' && parentList[i].type !== 'address' && parentList[i].type !== 'content' && parentList[i].type !== 'pagebreak' && parentList[i].type !== 'location' && parentList[i].type !== 'distance' && parentList[i].type !== 'signature' && parentList[i].type !== 'scale' && parentList[i].type !== 'areamap' && parentList[i].type !== 'geoLocation'){
						if(parentList[i].type === 'choice') questionLabel = parentList[i].choiceLabel;
						else if(parentList[i].type === 'yesno') questionLabel = parentList[i].yesnoLabel;
						else if(parentList[i].type === 'payment') questionLabel = parentList[i].paymentLabel;
						else if(parentList[i].type === 'correlation') questionLabel = parentList[i].relationLabel;
						else questionLabel = parentList[i].label;
						$('#_single-element-settings').find('._single-element-settings-div').find('._select-parent-div').find('select#parentQuestionSelect').last().append('<option value="' + parentList[i].type + '" data-questionIndex="' + parentList[i].index + '">' + questionLabel + '</option>');
						if(parentList[i].index === parentJSON['parentIndex']){
							$('#_single-element-settings').find('._single-element-settings-div').find('._select-parent-div').find('select#parentQuestionSelect').find('option[data-questionIndex='+ parentJSON['parentIndex'] + ']').prop('selected', true);
						}
					}
				}
			}
			if(parentJSON['parentQuestion']){
				var selectedType = '';
				if(parentJSON['parentQuestion'] == 'textbox' || parentJSON['parentQuestion'] == 'textarea' || parentJSON['parentQuestion'] == 'email' || parentJSON['parentQuestion'] == 'website') selectedType = 'text';
				else if(parentJSON['parentQuestion'] == 'rating' || parentJSON['parentQuestion'] == 'currency') selectedType = 'number';
				else if(parentJSON['parentQuestion'] == 'yesno' || parentJSON['parentQuestion'] == 'correlation') selectedType = 'choice';
				else selectedType = parentJSON['parentQuestion'];
				fetch(window.conditionalOptionUrl, {
					method: 'POST',
					headers: {
						"Authorization": window.userAuthData
					},
					body: JSON.stringify({ type: selectedType})
				}).then(function(data) { return data.json() })
				.then(function(data){
					var options = '<option value="" selected disabled>Select Question Criteria</option>';
					for(var i = 0; i < data.length; i++) {
						if(parentJSON['selectedParentData']['type'] == 'choice' && (parentJSON['selectedParentData']['choiceType'] == 'radio'|| parentJSON['selectedParentData']['choiceType'] == 'dropdown')){
							if(data[i] != 'all' || data[i] != 'any' || data[i] != 'none'){
								if(data[i] === parentJSON['parentCriteriaChoice']){
									options += '<option value="' + data[i] + '" selected="true" >' + data[i] + '</option>';
								}	else {
									options += '<option value="' + data[i] + '">' + data[i] + '</option>';
								}
							}
						} else {
							if(data[i] === parentJSON['parentCriteriaChoice']){
								options += '<option value="' + data[i] + '" selected="true" >' + data[i] + '</option>';
							}	else {
								options += '<option value="' + data[i] + '">' + data[i] + '</option>';
							}
						}
					}

					if($('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div').length) {
						$('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div #parentQuestionCriteriaSelect').html(options);
					} else {
						$('<div class="_parent-criteria-div mb10"><div class="col-md-12"><label class="active control-label is-imp">Select Parent Criteria</label><select id="parentQuestionCriteriaSelect" class="browser-default" required="">' + options + '</select></div></div>').insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._select-parent-div'));
						}
					if(parentJSON['parentCriteriaChoice'] === data[i]){
						$('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div #parentQuestionCriteriaSelect').find('option[value='+parentJSON['parentCriteriaChoice']+']').prop('selected', true)
					}

					$('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div').find('select#parentQuestionCriteriaSelect').change(function() {
						var selectedCriteria = $(this).find(':selected').val();
						if(selectedCriteria == 'equals' || selectedCriteria == 'is not equal' || selectedCriteria == 'is'
							|| selectedCriteria == 'is not' || selectedCriteria == 'starts with' || selectedCriteria == 'does not start with'
							|| selectedCriteria == 'ends with' || selectedCriteria == 'does not end with' || selectedCriteria == 'contains'
							|| selectedCriteria == 'does not contain' || selectedCriteria == 'is less than' || selectedCriteria == 'is greater than'
							|| selectedCriteria == 'is after' || selectedCriteria == 'is before' || selectedCriteria == 'day is'
							|| selectedCriteria == 'month is' || selectedCriteria == 'year is' || selectedCriteria == 'hour is'
							|| selectedCriteria == 'minute is') {
							var element = '';
							var selectedParentJSON = window.selectedParentJSON;
							if(selectedType == 'text') {
								element = '<input type="text" class="browser-default form-control" />';
							} else if(selectedType == 'number') {
								element = '<input type="number" className="browser-default form-control" />';
							} else if(selectedType == 'date') {
								element = '<input type="date" className="datepicker" />';
							} else if(selectedType == 'time') {
								element = '<input type="time" className="browser-default form-control"/>';
							} else if(selectedType == 'choice') {
								if(selectedParentJSON['choiceType'] == 'radio'){
									element = '<div class="choice-answer-holder"><input type="radio" id="option-0" value="'+selectedParentJSON['choicePlaceholder1']+'" name="choiceradio"/><label for="option-0" class="control-label">'+selectedParentJSON['choicePlaceholder1']+'</label></div>'
									if(selectedParentJSON['newChoiceOptions'].length){
										for(var i = 0; i < selectedParentJSON['newChoiceOptions'].length; i++){
											element += '<div class="choice-answer-holder"><input type="radio" id="option-'+ String(i + 1) +'" value="'+selectedParentJSON['newChoiceOptions'][i]+'" name="choiceradio" /><label for="option-'+ String(i + 1) +'" class="control-label">'+selectedParentJSON['newChoiceOptions'][i]+'</label></div>'
										}
									}
								} else if(selectedParentJSON['choiceType'] == 'dropdown'){
									if(selectedParentJSON['newChoiceOptions'].length){
										element = '<select id="dropdown-type-select" class="browser-default"><option value="'+selectedParentJSON['choicePlaceholder1']+'">'+selectedParentJSON['choicePlaceholder1']+'</option>';
										for(var i = 0; i < selectedParentJSON['newChoiceOptions'].length; i++){
											element += '<option value="'+selectedParentJSON['newChoiceOptions'][i]+'">'+selectedParentJSON['newChoiceOptions'][i]+'</option>'
										}
										element += '</select>';
									} else {
										element = '<select id="dropdown-type-select" class="browser-default"><option value="'+selectedParentJSON['choicePlaceholder1']+'">'+selectedParentJSON['choicePlaceholder1']+'</option></select>'
									}
								} else if(selectedParentJSON['choiceType'] == 'checkbox'){
									element = '<div class="choice-answer-holder"><input type="checkbox" id="option-0" value="'+selectedParentJSON['choicePlaceholder1']+'"/><label for="option-0" class="control-label">'+selectedParentJSON['choicePlaceholder1']+'</label></div>'
									if(selectedParentJSON['newChoiceOptions'].length){
										for(var i = 0; i < selectedParentJSON['newChoiceOptions'].length; i++){
											element += '<div class="choice-answer-holder"><input type="checkbox" id="option-'+ String(i + 1) +'" value="'+selectedParentJSON['newChoiceOptions'][i]+'"/><label for="option-'+ String(i + 1) +'" class="control-label">'+selectedParentJSON['newChoiceOptions'][i]+'</label></div>'
										}
									}
									element += '<div class="checkbox-select-type"><div class="col-md-6"><input name="checkboxselecttype" type="radio" id="checkbox-select-type-and" value="and"><label for="checkbox-select-type-and" class="control-label">And</label></div><div class="col-md-6"><input name="checkboxselecttype" type="radio" id="checkbox-select-type-or" value="or"><label for="checkbox-select-type-or" class="control-label">Or</label></div></div>';
								} else if(selectedParentJSON['type'] == 'correlation'){
									element = '<select id="dropdown-type-select" class="browser-default">';
									for(var i = 0; i < selectedParentJSON['selectedRelationalResponses'].length; i++){
										element += '<option value="'+selectedParentJSON['selectedRelationalResponses'][i]+'">'+selectedParentJSON['selectedRelationalResponses'][i]+'</option>'
									}
									element += '</select>';
							} else {
									element = '<div class="choice-answer-holder"><input type="radio" id="option-0" value="'+selectedParentJSON['yesnoPlaceholder1']+'" name="yes/no" /><label for="option-0" class="control-label">'+selectedParentJSON['yesnoPlaceholder1']+'</label><input type="radio" id="option-1" value="'+selectedParentJSON['yesnoPlaceholder2']+'" name="yes/no" /><label for="option-1" class="control-label ml10">'+selectedParentJSON['yesnoPlaceholder2']+'</label></div>'
								}
							}
							if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').length){
								$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').html('<div class="col-md-12"><label class="active control-label is-imp">Enter Criteria Value</label></div>');
								if(selectedType == 'choice' && (selectedParentJSON['choiceType'] == 'checkbox' || selectedParentJSON['choiceType'] == 'radio')){
									if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').length === 0){
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									} else {
										$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').remove();
										if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').length){
											$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').remove();
										}
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									}
								} else if((selectedType == 'choice' && selectedParentJSON['choiceType'] == 'dropdown') || selectedParentJSON['type'] == 'correlation') {
									if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').length === 0){
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									} else {
										$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').remove();
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									}
								} else {
									if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('input').length === 0){
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									} else {
										if(selectedType == 'choice' && (selectedParentJSON['choiceType'] == 'checkbox' || selectedParentJSON['choiceType'] == 'radio')){
											$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').remove();
											if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').length){
												$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').remove();
											}
											$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
										} else if((selectedType == 'choice' && selectedParentJSON['choiceType'] == 'dropdown') || selectedParentJSON['type'] == 'correlation'){
											$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').remove();
											$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
										} else {
											$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('input').remove();
											$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
										}
									}
								}
							} else {
								$('<div class="_answer-value-div mb10"><div class="col-md-12"><label class="active control-label is-imp">Enter Criteria Value</label>' + element + '</div></div>').insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div'));
							}
						} else {
							if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').length) {
								$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').remove();
							}
						}
					});

					if(parentJSON['parentCriteriaChoice'] == 'equals' || parentJSON['parentCriteriaChoice'] == 'is not equal'
						|| parentJSON['parentCriteriaChoice'] == 'is' || parentJSON['parentCriteriaChoice'] == 'is not'
						|| parentJSON['parentCriteriaChoice'] == 'starts with' || parentJSON['parentCriteriaChoice'] == 'does not start with'
						|| parentJSON['parentCriteriaChoice'] == 'ends with' || parentJSON['parentCriteriaChoice'] == 'does not end with'
						|| parentJSON['parentCriteriaChoice'] == 'contains' || parentJSON['parentCriteriaChoice'] == 'does not contain'
						|| parentJSON['parentCriteriaChoice'] == 'is less than' || parentJSON['parentCriteriaChoice'] == 'is greater than'
						|| parentJSON['parentCriteriaChoice'] == 'is after' || parentJSON['parentCriteriaChoice'] == 'is before'
						|| parentJSON['parentCriteriaChoice'] == 'day is' || parentJSON['parentCriteriaChoice'] == 'month is'
						|| parentJSON['parentCriteriaChoice'] == 'year is' || parentJSON['parentCriteriaChoice'] == 'hour is'
						|| parentJSON['parentCriteriaChoice'] == 'minute is') {
						var element = '';
						if(selectedType == 'text') {
							element = '<input type="text" class="browser-default form-control" value = "'+ parentJSON['parentAnswer'] + '"/>';
						} else if(selectedType == 'number') {
							element = '<input type="number" className="browser-default form-control" value = "'+ parentJSON['parentAnswer'] + '"/>';
						} else if(selectedType == 'date') {
							element = '<input type="date" className="datepicker" value = "'+ parentJSON['parentAnswer'] + '"/>';
						} else if(selectedType == 'time') {
							element = '<input type="time" className="browser-default form-control" value = "'+ parentJSON['parentAnswer'] + '" />';
						} else if(selectedType == 'choice') {
							if(parentJSON['selectedParentData']['choiceType'] == 'radio'){
								element = '<div class="choice-answer-holder"><input type="radio" id="option-0" value="'+parentJSON['selectedParentData']['choicePlaceholder1']+'" name="choiceradio" /><label for="option-0" class="control-label">'+parentJSON['selectedParentData']['choicePlaceholder1']+'</label></div>'
								if(parentJSON['selectedParentData']['newChoiceOptions'].length){
									for(var i = 0; i < parentJSON['selectedParentData']['newChoiceOptions'].length; i++){
										element += '<div class="choice-answer-holder"><input type="radio" id="option-'+ String(i + 1) +'" value="'+parentJSON['selectedParentData']['newChoiceOptions'][i]+'" name="choiceradio" /><label for="option-'+ String(i + 1) +'" class="control-label">'+parentJSON['selectedParentData']['newChoiceOptions'][i]+'</label></div>'
									}
								}
							} else if(parentJSON['selectedParentData']['choiceType'] == 'dropdown'){
								if(parentJSON['selectedParentData']['newChoiceOptions'].length){
									element = '<select id="dropdown-type-select" class="browser-default"><option value="'+parentJSON['selectedParentData']['choicePlaceholder1']+'">'+parentJSON['selectedParentData']['choicePlaceholder1']+'</option>';
									for(var i = 0; i < parentJSON['selectedParentData']['newChoiceOptions'].length; i++){
										element += '<option value="'+parentJSON['selectedParentData']['newChoiceOptions'][i]+'">'+parentJSON['selectedParentData']['newChoiceOptions'][i]+'</option>'
									}
									element += '</select>';
								} else {
									element = '<select id="dropdown-type-select" class="browser-default"><option value="'+parentJSON['selectedParentData']['choicePlaceholder1']+'">'+parentJSON['selectedParentData']['choicePlaceholder1']+'</option></select>'
								}
							} else if(parentJSON['selectedParentData']['choiceType'] == 'checkbox') {
								element = '<div class="choice-answer-holder"><input type="checkbox" id="option-0" value="'+parentJSON['selectedParentData']['choicePlaceholder1']+'"/><label for="option-0" class="control-label">'+parentJSON['selectedParentData']['choicePlaceholder1']+'</label></div>';
								if(parentJSON['selectedParentData']['newChoiceOptions'].length) {
									for(var i = 0; i < parentJSON['selectedParentData']['newChoiceOptions'].length; i++) {
										element += '<div clas="choice-answer-holder"><input type="checkbox" id="option-'+ String(i + 1) +'" value="'+parentJSON['selectedParentData']['newChoiceOptions'][i]+'"/><label for="option-'+ String(i + 1) +'" class="control-label">'+parentJSON['selectedParentData']['newChoiceOptions'][i]+'</label></div>';
									}
								}
								if(parentJSON.hasOwnProperty('selectedChoiceConditionType')){
									element += '<div class="checkbox-select-type"><div class="col-md-6"><input name="checkboxselecttype" type="radio" id="checkbox-select-type-and" value="and" /><label for="checkbox-select-type-and" class="control-label">And</label></div><div class="col-md-6"><input name="checkboxselecttype" type="radio" id="checkbox-select-type-or" value="or" /><label for="checkbox-select-type-or" class="control-label">Or</label></div></div>';
								}
							} else if(parentJSON['selectedParentData']['type'] === 'correlation'){
								element = '<select id="dropdown-type-select" class="browser-default">';
									for(var i = 0; i < parentJSON['selectedParentData']['selectedRelationalResponses'].length; i++){
										element += '<option value="'+parentJSON['selectedParentData']['selectedRelationalResponses'][i]+'">'+parentJSON['selectedParentData']['selectedRelationalResponses'][i]+'</option>'
									}
								element += '</select>';
							} else {
								if(parentJSON['parentAnswer'] === parentJSON['selectedParentData']['yesnoPlaceholder1']){
									element = '<div class="choice-answer-holder"><input type="radio" id="option-0" value="'+parentJSON['selectedParentData']['yesnoPlaceholder1']+'" checked="true" name="yes/no" /><label for="option-0" class="control-label">'+parentJSON['selectedParentData']['yesnoPlaceholder1']+'</label><input type="radio" id="option-1" value="'+parentJSON['selectedParentData']['yesnoPlaceholder2']+'" name="yes/no"/><label for="option-1" class="control-label ml10">'+parentJSON['selectedParentData']['yesnoPlaceholder2']+'</label></div>'
								} else {
									element = '<div class="choice-answer-holder"><input type="radio" id="option-0" value="'+parentJSON['selectedParentData']['yesnoPlaceholder1']+'" name="yes/no"/><label for="option-0" class="control-label">'+parentJSON['selectedParentData']['yesnoPlaceholder1']+'</label><input type="radio" id="option-1" value="'+parentJSON['selectedParentData']['yesnoPlaceholder2']+'" checked="true" name="yes/no" /><label for="option-1" class="control-label ml10">'+parentJSON['selectedParentData']['yesnoPlaceholder2']+'</label></div>'
								}
							}
						}
						if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').length){
							$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').html('<div class="col-md-12"><label class="active control-label is-imp">Enter Criteria Value</label></div>');
							if(selectedType == 'choice' && (parentJSON['selectedParentData']['choiceType'] == 'checkbox' || parentJSON['selectedParentData']['choiceType'] == 'radio')){
								if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').length === 0){
									$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
								} else {
									$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').remove();
									if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').length){
											$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').remove();
										}
									$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
								}
							} else if((selectedType == 'choice' && parentJSON['selectedParentData']['choiceType'] == 'dropdown') || parentJSON['selectedParentData']['type'] == 'correlation') {
								if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').length === 0){
									$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
								} else {
									$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').remove();
									$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
								}
							} else {
								if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('input').length === 0){
									$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
								} else {
									if(selectedType == 'choice' && (parentJSON['selectedParentData']['choiceType'] == 'checkbox' || parentJSON['selectedParentData']['choiceType'] == 'radio')){
										$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').remove();
										if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').length){
											$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').remove();
										}
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									} else if((selectedType == 'choice' && parentJSON['selectedParentData']['choiceType'] == 'dropdown') || parentJSON['selectedParentData']['type'] == 'correlation'){
										$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').remove();
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									} else {
										$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('input').remove();
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									}
								}
							}
						} else {
							if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').length){
								$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').remove();
							} else {
								$('<div class="_answer-value-div mb10"><div class="col-md-12"><label class="active control-label is-imp">Enter Criteria Value</label>' + element + '</div></div>').insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div'));
							}
						}

						if(selectedType == 'choice' && parentJSON['selectedParentData']['choiceType'] == 'checkbox'){
							$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').find('input[type="checkbox"]').each(function(){
								if(parentJSON['parentAnswer'].indexOf($(this).next('label').text()) > -1){
									$(this).prop('checked', true)
								}
							})
							if(parentJSON.hasOwnProperty('selectedChoiceConditionType')){
								$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').find('input[type="radio"]').each(function(){
									if(parentJSON['selectedChoiceConditionType'] == $(this).val()){
										$(this).prop('checked', true);
									}
								})
							}
						} else if(selectedType == 'choice' && parentJSON['selectedParentData']['choiceType'] == 'radio'){
							$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').find('input[type="radio"]').each(function(){
								if(parentJSON['parentAnswer'] === $(this).next('label').text()){
									$(this).prop('checked', true)
								}
							})
						} else if((selectedType == 'choice' && parentJSON['selectedParentData']['choiceType'] == 'dropdown') || parentJSON['selectedParentData']['type'] == 'correlation'){
							$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('#dropdown-type-select').find('option').each(function(){
								if(parentJSON['parentAnswer'] == $(this).val()){
									$(this).prop('selected', 'selected');
								}
							})
						}
					} else {
						if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').length){
							$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').remove();
						}
					}
				});
			}

			$('#_single-element-settings').find('._single-element-settings-div').find('._select-parent-div').find('select#parentQuestionSelect').change(function() {
				var selectedIndex = $(this).find(':selected').attr('data-questionIndex');
				var selectedParent = $('._form-holder').find('._element-container').find('._form-element[data-index="' +selectedIndex+'"]');
				var selectedType = $(this).find(':selected').val();
				var selectedParentJSON = JSON.parse($('._form-holder').find('._element-container').find('._form-element[data-index="' +selectedIndex+'"]').attr('data-json'));
				if(selectedType == 'textbox' || selectedType == 'textarea' || selectedType == 'email' || selectedType == 'website') selectedType = 'text';
				else if(selectedType == 'rating' || selectedType == 'currency') selectedType = 'number';
				else if(selectedType == 'yesno' || selectedType == 'correlation') selectedType = 'choice';

				// Change parentJSON from current selected #parentQuestionSelect

				fetch(window.conditionalOptionUrl, {
					method: 'POST',
					headers: {
						"Authorization": window.userAuthData
					},
					body: JSON.stringify({ type: selectedType })
				}).then(function(data) { return data.json() })
				.then(function(data){
					var options = '<option value="" selected disabled>Select Question Criteria</option>';
					for(var i = 0; i < data.length; i++) {
						options += '<option value="' + data[i] + '">' + data[i] + '</option>';
					}

					if($('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div').length) {
						$('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div #parentQuestionCriteriaSelect').html(options);
						$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').remove();
					} else {
						$('<div class="_parent-criteria-div mb10"><div class="col-md-12"><label class="active control-label is-imp">Select Parent Criteria</label><select id="parentQuestionCriteriaSelect" class="browser-default" required="">' + options + '</select></div></div>').insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._select-parent-div'));
					}

					$('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div').find('select#parentQuestionCriteriaSelect').change(function() {
						var selectedCriteria = $(this).find(':selected').val();
						if(selectedCriteria == 'equals' || selectedCriteria == 'is not equal' || selectedCriteria == 'is'
							|| selectedCriteria == 'is not' || selectedCriteria == 'starts with' || selectedCriteria == 'does not start with'
							|| selectedCriteria == 'ends with' || selectedCriteria == 'does not end with' || selectedCriteria == 'contains'
							|| selectedCriteria == 'does not contain' || selectedCriteria == 'is less than' || selectedCriteria == 'is greater than'
							|| selectedCriteria == 'is after' || selectedCriteria == 'is before' || selectedCriteria == 'day is'
							|| selectedCriteria == 'month is' || selectedCriteria == 'year is' || selectedCriteria == 'hour is'
							|| selectedCriteria == 'minute is') {
							var element = '';
							if(selectedType == 'text') {
								element = '<input type="text" class="browser-default form-control" />';
							} else if(selectedType == 'number') {
								element = '<input type="number" className="browser-default form-control" />';
							} else if(selectedType == 'date') {
								element = '<input type="date" className="datepicker" />';
							} else if(selectedType == 'time') {
								element = '<input type="time" className="browser-default form-control"/>';
							} else if(selectedType == 'choice') {
								if(selectedParentJSON['choiceType'] == 'radio'){
									element = '<div class="choice-answer-holder"><input type="radio" id="option-0" value="'+selectedParentJSON['choicePlaceholder1']+'" name="choiceradio"/><label for="option-0" class="control-label">'+selectedParentJSON['choicePlaceholder1']+'</label></div>'
									if(selectedParentJSON['newChoiceOptions'].length){
										for(var i = 0; i < selectedParentJSON['newChoiceOptions'].length; i++){
											element += '<div class="choice-answer-holder"><input type="radio" id="option-'+ String(i + 1) +'" value="'+selectedParentJSON['newChoiceOptions'][i]+'" name="choiceradio" /><label for="option-'+ String(i + 1) +'" class="control-label">'+selectedParentJSON['newChoiceOptions'][i]+'</label></div>'
										}
									}
								} else if(selectedParentJSON['choiceType'] == 'dropdown'){
									if(selectedParentJSON['newChoiceOptions'].length){
										element = '<select id="dropdown-type-select" class="browser-default"><option value="'+selectedParentJSON['choicePlaceholder1']+'">'+selectedParentJSON['choicePlaceholder1']+'</option>';
										for(var i = 0; i < selectedParentJSON['newChoiceOptions'].length; i++){
											element += '<option value="'+selectedParentJSON['newChoiceOptions'][i]+'">'+selectedParentJSON['newChoiceOptions'][i]+'</option>'
										}
										element += '</select>';
									} else {
										element = '<select id="dropdown-type-select" class="browser-default"><option value="'+selectedParentJSON['choicePlaceholder1']+'">'+selectedParentJSON['choicePlaceholder1']+'</option></select>'
									}
								} else if(selectedParentJSON['choiceType'] == 'checkbox'){
									element = '<div class="choice-answer-holder"><input type="checkbox" id="option-0" value="'+selectedParentJSON['choicePlaceholder1']+'"/><label for="option-0" class="control-label">'+selectedParentJSON['choicePlaceholder1']+'</label></div>'
									if(selectedParentJSON['newChoiceOptions'].length){
										for(var i = 0; i < selectedParentJSON['newChoiceOptions'].length; i++){
											element += '<div class="choice-answer-holder"><input type="checkbox" id="option-'+ String(i + 1) +'" value="'+selectedParentJSON['newChoiceOptions'][i]+'"/><label for="option-'+ String(i + 1) +'" class="control-label">'+selectedParentJSON['newChoiceOptions'][i]+'</label></div>'
										}
									}
									element += '<div class="checkbox-select-type"><div class="col-md-6"><input name="checkboxselecttype" type="radio" id="checkbox-select-type-and" value="and"><label for="checkbox-select-type-and" class="control-label">And</label></div><div class="col-md-6"><input name="checkboxselecttype" type="radio" id="checkbox-select-type-or" value="or"><label for="checkbox-select-type-or" class="control-label">Or</label></div></div>';
								} else if(selectedParentJSON['type'] == 'correlation'){
									element = '<select id="dropdown-type-select" class="browser-default">';
									for(var i = 0; i < selectedParentJSON['selectedRelationalResponses'].length; i++){
										element += '<option value="'+selectedParentJSON['selectedRelationalResponses'][i]+'">'+selectedParentJSON['selectedRelationalResponses'][i]+'</option>'
									}
									element += '</select>';
							} else {
									element = '<div class="choice-answer-holder"><input type="radio" id="option-0" value="'+selectedParentJSON['yesnoPlaceholder1']+'" name="yes/no" /><label for="option-0" class="control-label">'+selectedParentJSON['yesnoPlaceholder1']+'</label><input type="radio" id="option-1" value="'+selectedParentJSON['yesnoPlaceholder2']+'" name="yes/no" /><label for="option-1" class="control-label ml10">'+selectedParentJSON['yesnoPlaceholder2']+'</label></div>'
								}
							}
							if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').length){
								$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').html('<div class="col-md-12"><label class="active control-label is-imp">Enter Criteria Value</label></div>');
								if(selectedType == 'choice' && (selectedParentJSON['choiceType'] == 'checkbox' || selectedParentJSON['choiceType'] == 'radio')){
									if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').length === 0){
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									} else {
										$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').remove();
										if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').length){
											$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').remove();
										}
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									}
								} else if((selectedType == 'choice' && selectedParentJSON['choiceType'] == 'dropdown') || selectedParentJSON['type'] == 'correlation') {
									if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').length === 0){
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									} else {
										$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').remove();
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									}
								} else {
									if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('input').length === 0){
										$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
									} else {
										if(selectedType == 'choice' && (selectedParentJSON['choiceType'] == 'checkbox' || selectedParentJSON['choiceType'] == 'radio')){
											$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.choice-answer-holder').remove();
											if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').length){
												$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('.checkbox-select-type').remove();
											}
											$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
										} else if((selectedType == 'choice' && selectedParentJSON['choiceType'] == 'dropdown') || selectedParentJSON['type'] == 'correlation'){
											$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('select').remove();
											$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
										} else {
											$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('input').remove();
											$(element).insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').find('label'));
										}
									}
								}
							} else {
								$('<div class="_answer-value-div mb10"><div class="col-md-12"><label class="active control-label is-imp">Enter Criteria Value</label>' + element + '</div></div>').insertAfter($('#_single-element-settings').find('._single-element-settings-div').find('._parent-criteria-div'));
							}
						} else {
							if($('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').length) {
								$('#_single-element-settings').find('._single-element-settings-div').find('._answer-value-div').remove();
							}
						}
					});
				});
			});
			var elementIndex = $(this).find(':selected').attr('data-questionIndex');
			var selectedElementJSON = $('._form-holder ._element-container').find('._form-element[data-index="' + elementIndex + '"]').data('json');
			if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);
		})
	}
}
