import React from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import Icon from 'react-icons-kit';
import { ic_attach_money } from 'react-icons-kit/md/ic_attach_money';
import { ic_money_off } from 'react-icons-kit/md/ic_money_off';

import RepositoryTable from './RepositoryTable';
import FailedRepositoryTable from './FailedRepositoryTable';

export default class Repository extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ngo: null,
      activeKey: 1,
    };
  }

  handleSelect(selectedKey) {
    this.setState({ activeKey: selectedKey });
  }

  render() {
    return (
      <div className="page-container">
        <Tabs activeKey={this.state.activeKey} id="single-donor-tab" onSelect={this.handleSelect.bind(this)}>
          <Tab eventKey={1} title={<div className="single-donor-tab-header"><Icon size={18} icon={ic_attach_money} /><span> Donations</span></div>}>
            <RepositoryTable ngo={this.props.ngo} userData={this.props.userData} />
          </Tab>
          <Tab eventKey={2} title={<div className="single-donor-tab-header"><Icon size={18} icon={ic_money_off} /><span> Failed Donations</span></div>}>
            <FailedRepositoryTable ngo={this.props.ngo} userData={this.props.userData} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}