import React, {Component} from 'react';
import { Button, Row, Col } from "react-bootstrap";
import swal from "sweetalert2";
import EmailEditor from 'react-email-editor';

import "sweetalert2/dist/sweetalert2.min.css";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import Loader from '../Loader';

const readCookie = require('../cookie.js').readCookie;

let editor;

export default class EditTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      subject: '',
      body: '',
      showNameError: false,
      showBodyError: false,
      showSubjectError: false,
      showLoader: true,
      loaderHiding: ''
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleHtmlChange = this.handleHtmlChange.bind(this);
  }

  handleNameChange(e) {
    if(e.target.value === '') this.setState({ showNameError: true });
    else this.setState({ showNameError: false });
    this.setState({ name: e.target.value });
  }

  handleSubjectChange(e) {
    if(e.target.value === '') this.setState({ showSubjectError: true });
    else this.setState({ showSubjectError: false });
    this.setState({ subject: e.target.value });
  }

  handleHtmlChange(value) {
    this.setState({ body: value }, function() {
      if(value === '<p><br></p>') this.setState({ showBodyError: true });
      else this.setState({ showBodyError: false });
    }.bind(this));
  }

  cancelEditTemplate() {
    window.location.pathname = '/dm/campaigns';
  }

  saveEditTemplate() {
    let saveHTML = '';
    editor.exportHtml(data => {
      const { design, html } = data
      saveHTML = html;
      let saveJSON = {};
      editor.saveDesign(design => {
        saveJSON = design;

        let to_update = {};
        to_update['name'] = this.state.name;
        if(this.state.subject !== null) to_update['subject'] = this.state.subject;
        to_update['body'] = saveHTML;
        to_update['bodyJSON'] = saveJSON;

        let template_id = this.props.match.params['template_id'];

        if(this.state.name !== '' && saveHTML !== '') {
          if(this.state.subject !== '') {
            fetch(process.env.REACT_APP_API_URL + '/templates/' + this.props.ngo.ngo_id + '/update', {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
              },
              body: JSON.stringify({
                to_update,
                template_id
              })
            }).then((response) => response.json())
            .then((data) => {
              this.setState({
                showNameError: false,
                showBodyError: false,
                showSubjectError: false
              });
              swal({
                text: 'Template Successfully updated!',
                type: 'success'
              }).then(() => {
                window.location.pathname = '/dm/campaigns';
              });
            });
          } else if (this.state.subject === '') this.setState({ showSubjectError: true });
        } else {
          if(this.state.name === '') this.setState({ showNameError: true });
          if(saveHTML === '') this.setState({ showBodyError: true });
        }
      });
    });
  }

  // startCampaignFromTemplate() {
  //   this.props.startCampaignFromTemplate(this.props.match.params['template_id']);
  //   this.props.onEditTemplateClose();
  // }

  replicateTemplate() {
    fetch(process.env.REACT_APP_API_URL + '/templates/' + this.props.ngo['ngo_id'] + '/replicate/' + this.props.match.params['template_id'], {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data['status'] === 'ok') {
        this.setState({
          name: '',
          subject: '',
          body: '',
          showNameError: false,
          showBodyError: false,
          showSubjectError: false
        });
        swal({
          title: 'Template Successfully replicated!',
          html: 'The last saved version of this template has been replicated as - <b>Copy of ' + this.state.name + '</b>',
          type: 'warning'
        }).then(() => {
          window.location.pathname = '/dm/campaigns';
        });
      } else {
        swal({
          text: 'The chosen Template could not be replicated!',
          type: 'error'
        });
      }
    }.bind(this));
  }

  componentWillMount() {
    fetch(process.env.REACT_APP_API_URL + '/templates/' + this.props.ngo['ngo_id'] + '/' + this.props.match.params['template_id'], {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if('subject' in data['template']) this.setState({ subject: data['template']['subject'] });
      this.setState({
        name: data['template']['name'],
        body: data['template']['body'],
        bodyJSON: data['template']['bodyJSON'],
        loaderHiding: 'hiding'
      }, function() {
        setTimeout(function() {
          this.setState({ showLoader: false });
        }.bind(this), 400);
      });
    }.bind(this));
  }

  onLoad() {
    if (editor !== undefined) {
      editor.loadDesign(this.state.bodyJSON);
    } else {
      setTimeout (() => editor.loadDesign(this.state.bodyJSON), 3000);
    }
  }

  render() {
    let options = {
      projectId: process.env.REACT_APP_UNLAYER_PROJECT_ID || 433,
      mergeTags: [
        {name: "First Name", value: "{{first_name}}"},
        {name: "Last Name", value: "{{last_name}}"},
        {name: "Full Name", value: "{{full_name}}"}
      ]
    };
  	return (
  		<div className="page-container">
        {(() => {
          if (this.props.match.params['template_id'] !== null && !this.state.showLoader) {
            return (
              <div>
                <h2 className="modal-header-custom">
                  Edit Template
                </h2>
                <Row>
                  <Col className="col-md-offset-1 text-center" md={10}>
                    <div className="legend">
                      <div>Legend: </div>
                      <div><span className="tag mr5">{'{{'}<span className="tagname">first_name</span>{'}} '}</span>=&gt; Jon<span className="pipe"></span><span className="tag mr5">{'{{'}<span className="tagname">last_name</span>{'}} '}</span>=&gt; Snow<span className="pipe"></span><span className="tag mr5">{'{{'}<span className="tagname">full_name</span>{'}} '}</span>=&gt; Jon Snow</div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb10">
                  <Col className="form-group no-pad-left" md={12}>
                    <label className="control-label is-imp"> Template Name: </label>
                    <input type="text" className="form-control" id="template_name" name="template_name"
                      placeholder="Name of the template" onChange={this.handleNameChange} value={this.state.name} required />
                    <span hidden={!this.state.showNameError}><span className="required-span">Please enter a valid Name.</span></span>
                  </Col>
                  <Col className="form-group no-pad-left" md={12}>
                    <label className="control-label is-imp"> Template Subject: </label>
                    <input type="text" className="form-control" id="email_subject" name="email_subject"
                      placeholder="Subject of the email" onChange={this.handleSubjectChange} value={this.state.subject} required />
                    <span hidden={!this.state.showSubjectError}><span className="required-span">Please enter a valid Subject.</span></span>
                  </Col>
                  <Col className="form-group no-pad-left" md={12} >
                    <label className="control-label is-imp"> Design your Template: </label>
                    {(() => {
                      if (this.state.body !== '' && this.state.bodyJSON !== undefined && this.state.bodyJSON !== null) {
                        return (
                          <EmailEditor ref={thisEditor => editor = thisEditor} onLoad={this.state.bodyJSON ? this.onLoad() : (null)} options={options} />
                        );
                      } else {
                        return (
                          <div>
                            <EmailEditor ref={thisEditor => editor = thisEditor} options={options} />
                          </div>
                        );
                      }
                    })()}
                    <span hidden={!this.state.showBodyError}><span className="required-span">Please enter a valid Body content.</span></span>
                  </Col>
                </Row>
                <Row className="text-center">
                  {/*<Button className="btn btn-warning mr5" onClick={this.startCampaignFromTemplate.bind(this)}>
                    Start Campaign
                    <span className="glyphicon glyphicon-send"></span>
                  </Button>*/}
                  {/*<Button className="btn btn-warning mr5" onClick={this.replicateTemplate.bind(this)}>
                    Replicate
                    <i className="fa fa-copy"></i>
                  </Button>*/}
                  <Button className="btn btn-danger mr5" onClick={this.cancelEditTemplate.bind(this)}>Cancel</Button>
                  <Button className="btn btn-warning mr5" id="submit_donation" onClick={this.saveEditTemplate.bind(this)}>
                    Save
                    <i className="fa fa-floppy-o"></i>
                  </Button>
                </Row>
              </div>
            );
          } else {
            return <Loader loaderHiding={this.state.loaderHiding} />
          }
        })()}
      </div>
    );
  }
}

EditTemplateModal.modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['link', 'image', 'video'],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ]
}

EditTemplateModal.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
  'list', 'bullet', 'indent', 'script',
  'link', 'image', 'video', 'align', 'color', 'background',
  'clean'
]