import React, {Component} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import SingleDonorQuicklinks from './single-donor/SingleDonorQuicklinks';
import SingleDonorProfile from './single-donor/SingleDonorProfile';
import SingleDonorDonation from './single-donor/SingleDonorDonation';
import SingleDonorCampaigns from './single-donor/SingleDonorCampaigns';
import SingleDonorNotes from './single-donor/SingleDonorNotes';
import SingleDonorTasks from './single-donor/SingleDonorTasks';

import AddFollowModal from '../6-followups/add-followup/AddFollowModal';
import EditDonor from './EditDonor';

const readCookie = require('../cookie.js').readCookie;

export default class SingleDonor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      showEditDonorModal: false,
      refreshNotes: false,
      showFollowModal: false,
      selectedTemplateId: null,
      list: null
    };
    this.onEditDonorClose = this.onEditDonorClose.bind(this);
    this.onEditDonorOpen = this.onEditDonorOpen.bind(this);
  }

  componentDidMount() {
    let id = this.props.match.params['id'];
    fetch(process.env.REACT_APP_API_URL + '/donor/' + id, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ data: data['donor'], list: data['list'] });
    }.bind(this));
  }

  onEditDonorClose() {
    this.setState({ showEditDonorModal: false });
    fetch(process.env.REACT_APP_API_URL + '/donor/' + this.props.match.params.id, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ data: null }, function() {
        this.setState({ data: data['donor'] });
      }.bind(this));
    }.bind(this));
  }

  onEditDonorOpen() {
    this.setState({ showEditDonorModal: true });
  }

  onAddComment() {
    fetch(process.env.REACT_APP_API_URL + '/donor/' + this.props.match.params.id, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ refreshNotes: true }, function() {
        this.setState({ data: data['donor'], refreshNotes: false });
      }.bind(this));
    }.bind(this));
  }

  onFollowClose() {
    this.setState({ showFollowModal: false });
  }

  onFollowOpen() {
    this.setState({ showFollowModal: true });
  }

  render() {
    if(this.state.data !== null && this.state.list !== null) {
      return (
        <div className="page-container top50">
          <p className="single-donor-title">
            <span className="back">
              <Link to={'/dm/lists/' + this.state.list.id} className="back-btn"><i className="fa fa-chevron-left"></i> Go to Member List</Link>
            </span>
            {this.state.data.first_name + ' ' + this.state.data.last_name}
            {this.props.ngo.plan.name === 'premium' || this.props.ngo.plan.name === 'payment_gateway_basic_plus' ? (
              <button type='button' onClick={this.onEditDonorOpen}
                className={'btn btn-success react-bs-table-add-btn hidden-print'}>
                <span>
                  <i className="glyphicon glyphicon-edit"></i>
                  Edit
                </span>
              </button>
            ) : ('')}
          </p>
          {(this.props.ngo.plan.name === 'premium' || this.props.ngo.plan.name === 'payment_gateway_basic_plus') && this.state.showEditDonorModal ? (
            <EditDonor donor={this.state.data}
              ngo_id={this.props.ngo['ngo_id']} list={this.state.list}
              donor_id={this.props.match.params.id} ngo={this.props.ngo}
              showEditDonorModal={this.state.showEditDonorModal}
              assignee={this.props.userData.email} onHide={this.onEditDonorClose}
              onEditDonorClose={this.onEditDonorClose}
              onEditDonorOpen={this.onEditDonorOpen}
              userData={this.state.data} />
          ) : ('')}
          <Tabs defaultActiveKey={1} id="single-donor-tab">
            <Tab eventKey={1} title={<div className="single-donor-tab-header"><i className='fa fa-user'></i><span> Profile</span></div>}>
              <SingleDonorProfile userId={this.props.match.params.id} ngo={this.props.ngo} donor={this.state.data} list={this.state.list} />
            </Tab>
            {this.props.ngo.hasOwnProperty('account_type') ? (
              this.props.ngo.account_type === 'ngo' ? (
                <Tab eventKey={2} title={<div className="single-donor-tab-header"><i className='fa fa-credit-card'></i><span> Donation</span></div>}>
                  <SingleDonorDonation userId={this.props.match.params.id} ngo={this.props.ngo} userData={this.props.userData} userEmail={this.state.data.email} pan_number={this.state.data.pan_number} />
                </Tab>
              ) : (null)
            ) : (
              <Tab eventKey={2} title={<div className="single-donor-tab-header"><i className='fa fa-credit-card'></i><span> Donation</span></div>}>
                <SingleDonorDonation userId={this.props.match.params.id} ngo={this.props.ngo} userData={this.props.userData} userEmail={this.state.data.email} pan_number={this.state.data.pan_number} />
              </Tab>
            )}
            <Tab eventKey={3} title={<div className="single-donor-tab-header"><i className='fa fa-comments-o'></i><span> Notes</span></div>}>
              {this.state.refreshNotes !== true ? (
                <SingleDonorNotes userId={this.props.match.params.id} ngo={this.props.ngo} userNotes={this.state.data.user_notes} onAddComment={this.onAddComment.bind(this)} />
              ) : (null)}
            </Tab>
            {this.props.ngo.plan.name === 'premium' ? (
              <Tab eventKey={4} title={<div className="single-donor-tab-header"><i className='fa fa-flag-o'></i><span> Campaigns</span></div>}>
                <SingleDonorCampaigns userId={this.props.match.params.id} ngo={this.props.ngo} userData={this.props.userData} userEmail={this.state.data.email} campaigns={this.state.data.campaigns} />
              </Tab>
            ) : (null)}
            {this.props.ngo.plan.name === 'premium' ? (
              <Tab eventKey={5} title={<div className="single-donor-tab-header"><i className='fa fa-calendar-o'></i><span> Tasks</span></div>}>
                <SingleDonorTasks userId={this.props.match.params.id} ngo={this.props.ngo} userData={this.props.userData} userEmail={this.state.data.email} tasks={this.state.data.tasks} />
              </Tab>
            ) : (null)}
          </Tabs>
          <SingleDonorQuicklinks onFollowOpen={this.onFollowOpen.bind(this)} />
          {this.state.showFollowModal ? (
            <AddFollowModal
              ngo={this.props.ngo} userData={this.props.userData}
              showFollowModal={this.state.showFollowModal}
              onHide={this.onFollowClose.bind(this)}
              id={this.state.value_id} donor_id={this.props.match.params.id}
              onFollowClose={this.onFollowClose.bind(this)}
              onFollowOpen={this.onFollowOpen.bind(this)} />
          ) : (null)}
        </div>
      );
    } else return null;
  }
}