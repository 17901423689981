import React, {Component} from 'react';
import {Row, Col ,Button} from 'react-bootstrap';
import $ from 'jquery';
import Multiselect from 'react-bootstrap-multiselect';

import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';

const readCookie = require('../../cookie.js').readCookie;

export default class AddFollow extends Component {

	constructor(props){
		super(props);
		this.state = {
			name :  '',
			description : '',
			members : [],
			data : '',
			date : '',
			assignFor: null,
      assignForValue: '',
      assignedTo: null,
      assignedToValue: [],
			donorIdtagged : [],
			donorIds : [],
			donors : [],
			groups : [],
			all_assigned_for_list: [],
			assigned_to_members : [],
			assigned_to_suggestions : [],
			assigned_to_tags : [],
			assigned_to_addedIds: [],
			assigned_to_deletedIds: [],
			showNameError: true,
			showDescError: true,
			showAssignForError: true,
			showAssignedToError: true
		}
		this.handleDelete2 = this.handleDelete2.bind(this);
		this.handleAddition2 = this.handleAddition2.bind(this);
		this.handleDrag2 = this.handleDrag2.bind(this);
	}

	handleDelete2(i) {
		let assigned_to_tags = this.state.assigned_to_tags;
		this.setState({assigned_to_deletedIds : this.state.assigned_to_deletedIds.concat(this.state.assigned_to_tags[i]['text'])});
		assigned_to_tags.splice(i, 1);
		this.setState({assigned_to_tags: assigned_to_tags});
	}

	handleAddition2(tag) {
		let assigned_to_tags = this.state.assigned_to_tags;
		this.setState({assigned_to_addedIds : this.state.assigned_to_addedIds.concat(tag)});
		assigned_to_tags.push({
			id: assigned_to_tags.length + 1,
			text: tag
		});
		this.setState({assigned_to_tags: assigned_to_tags});
	}

	handleDrag2(tag, currPos, newPos) {
		let assigned_to_tags = this.state.assigned_to_tags;

		assigned_to_tags.splice(currPos, 1);
		assigned_to_tags.splice(newPos, 0, tag);

		this.setState({ assigned_to_tags: assigned_to_tags });
	}

	onSubmitGroup(){
		if(this.state.name !== '' && this.state.description !== '' && !this.state.showAssignedToError && !this.state.showAssignForError) {
			let added = {};

			added['user_ids'] = this.state.assignForValue;
			added['group_ids'] = [];

			let assigned_to_added = {};

			assigned_to_added['user_ids'] = this.state.assignedToValue;
			assigned_to_added['group_ids'] = [];

			fetch(process.env.REACT_APP_API_URL + '/task/' + this.props.ngo['ngo_id'], {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify({
					types : 'donor',
					title : this.state.name,
					description : this.state.description,
					date : this.state.date,
					assigned_to : assigned_to_added,
					assigned_for : added
				})
			}).then((response) => response.json())
			.then((data) => {
				if(data['success']) {
					fetch(process.env.REACT_APP_API_URL + '/tasks/'+ this.props.ngo['ngo_id'], {
						method: "GET",
						headers: {
							'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
						}
					}).then(function(response) {
						return response.json();
					}).then(function(data) {
						this.props.onFollowClose('refresh');
					}.bind(this))
				}
			});
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
	  }).then(function(response) {
      return response.json();
	  }).then(function(data) {
      let assignFor = [];
      for(var i = 0; i < data.donors.length; i++) {
      	let member_obj = {};
        member_obj['value'] = data.donors[i].donor_id;
        member_obj['label'] = data.donors[i].first_name + ' ' + data.donors[i].last_name + ' (' + data.donors[i].email + ')';
        assignFor.push(member_obj);
      }
      this.setState({ assignFor });
	  }.bind(this));
		// let toGroupValue = [];
		// fetch(process.env.REACT_APP_API_URL + '/groups_users/' + this.props.ngo.ngo_id, {
  //     method: "GET",
  //     headers: {
  //       'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
  //     }
  //   }).then(function(response) {
  //     return response.json();
  //   }).then(function(data) {
  //     let groups_donors = data.groups_donors;
  //     let groups = [];
  //     for(var i = 0; i < groups_donors.length; i++) {
  //       let group_obj = {};
  //       group_obj['id'] = groups_donors[i].group_id;
  //       group_obj['label'] = groups_donors[i].group_name;
  //       let group_members = [];
  //       for(var j = 0; j < groups_donors[i].group_members.length; j++) {
  //         let group_member_obj = {};
  //         group_member_obj['value'] = groups_donors[i].group_members[j].id;
  //         group_member_obj['label'] = groups_donors[i].group_members[j].name + ' (' + groups_donors[i].group_members[j].email + ')';
  //         if(this.props.donor_id !== undefined && groups_donors[i].group_members[j].id === this.props.donor_id && groups_donors[i].group_name === 'All Donors') {
  //           group_member_obj['selected'] = true;
  //           let assignForValue = [];
  //           assignForValue.push(this.props.donor_id);
  //           this.setState({ assignForValue, showAssignForError: false });
  //         }
  //         if(this.props.group_id !== undefined && groups_donors[i].group_id === this.props.group_id) {
  //           toGroupValue.push(groups_donors[i].group_members[j].id);
  //           group_member_obj['selected'] = true;
  //         }
  //         group_members.push(group_member_obj);
  //       }
  //       group_obj['children'] = group_members;
  //       if(this.props.group_id !== undefined && groups_donors[i].group_id === this.props.group_id) {
  //         this.setState({ assignForValue: toGroupValue, showAssignForError: false });
  //       }
  //       if(group_members.length) groups.push(group_obj);
  //     }
    // }.bind(this));

		fetch(process.env.REACT_APP_API_URL + '/get_ngo_users/' + this.props.ngo['ngo_id'], {
			method: "GET",
			headers: {
				'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
			}
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			let members = [];
			var users = data['users'];
			var assigned_to_suggestions = [];
			for(var i = 0; i < users.length; i++) {
				var member_obj = {};
				member_obj['value'] = users[i]['id'];
				member_obj['label'] = users[i]['first_name'] + ' ' + users[i]['last_name'] + ' (' + users[i]['email'] + ')';
				members.push(member_obj);
				assigned_to_suggestions.push(users[i]['first_name'] + ' ' + users[i]['last_name'])
			}
			this.setState({
				assignedTo: members,
				assigned_to_members: users,
				assigned_to_suggestions: assigned_to_suggestions
			});
		}.bind(this));
	}

	setname(value) {
		this.setState({ name : value }, function() {
			if(value === '') this.setState({ showNameError: true });
	    else this.setState({ showNameError: false });
		}.bind(this));
	}

	setdescription(value) {
		this.setState({ description : value }, function() {
			if(value === '') this.setState({ showDescError: true });
	    else this.setState({ showDescError: false });
		}.bind(this));
	}

	setDate(value) {
		value = String(value);
		value = value.split('-');
		var temp = value[0] + '/' + value[1] + '/' + value[2];
		this.setState({ date: temp });
	}

	handleChange(name, value) {
		if(name === 'assignForValue') {
	    this.setState({ [name]: $('select#assignFor').val() }, function() {
	      if(this.state.assignForValue.length === 0) this.setState({ showAssignForError: true });
	      else this.setState({ showAssignForError: false });
	    });
	  } else if(name === 'assignedToValue') {
	  	this.setState({ [name]: $('select#assignedTo').val() }, function() {
	    	if(this.state.assignedToValue.length === 0) this.setState({ showAssignedToError: true });
	      else this.setState({ showAssignedToError: false });
	    });
		}
  }

	render () {
		return (
      <div className="margin-col-10">
          <Row>
            <Col className="form-group" md={12}>
              <label className="control-label is-imp">Task:</label>
              <input type="text" className="form-control" id="last_name" name="last_name" value={this.state.name}
              	onChange={(e) => {this.setname(e.target.value)}} placeholder="Task Name" required/>
              <span hidden={!this.state.showNameError}><span className="required-span">Please enter a valid Name.</span></span>
            </Col>

            <Col className="form-group" md={12}>
              <label className="control-label is-imp">Task Description:</label>
              <textarea cols="40" rows="5" className="form-control" id="description" name="description"
                value={this.state.description} onChange={(e) => {this.setdescription(e.target.value)}}
                placeholder="Task Description" required/>
              <span hidden={!this.state.showDescError}><span className="required-span">Please enter a valid Description.</span></span>
            </Col>

            <Col className="form-group" md={12}>

            	<Col className="no-pad-left no-pad-right" md={12}>
                <label className="control-label is-imp">Assign for:</label>
                <div>
                	{this.state.assignForValue !== null ? (
                    <Multiselect id="assignFor" defaultValue={this.state.assignForValue}
                    	onChange={this.handleChange.bind(this, 'assignForValue')} includeSelectAllOption
                      buttonWidth={'100%'} data={this.state.assignFor} multiple enableClickableOptGroups
                      enableCollapsibleOptGroups numberDisplayed={5} enableCaseInsensitiveFiltering
                      enableFiltering onSelectAll={this.handleChange.bind(this, 'assignForValue')}
                      onDeselectAll={this.handleChange.bind(this, 'assignForValue')} />
                  ) : (null)}
                </div>
                <span hidden={!this.state.showAssignForError}><span className="required-span">Please select valid Members.</span></span>
              </Col>
            </Col>
          </Row>

          <Row>
          	<Col className="form-group" md={6}>
              <label className="control-label is-imp">Assigned to:</label>
              <div>
	              {this.state.assignToValue !== null ? (
	              	<Multiselect id="assignedTo" defaultValue={this.state.assignedToValue}
	                	onChange={this.handleChange.bind(this, 'assignedToValue')} includeSelectAllOption
	                  buttonWidth={'100%'} data={this.state.assignedTo} multiple enableClickableOptGroups
	                  enableCollapsibleOptGroups numberDisplayed={1} enableCaseInsensitiveFiltering
	                  enableFiltering onSelectAll={this.handleChange.bind(this, 'assignedToValue')}
	                  onDeselectAll={this.handleChange.bind(this, 'assignedToValue')} />
	              ) : (null)}
              </div>
              <span hidden={!this.state.showAssignedToError}><span className="required-span">Please select a valid User.</span></span>
            </Col>

            <Col className="form-group" md={4}>
              <label className="control-label is-imp">Finish by</label>
              <input type="date" className="form-control" id="date"
              	name="date" onChange={(e) => {this.setDate(e.target.value)}}
                placeholder="04/05/2017" required />
              <span className="red-color">Input is too long!</span>
            </Col>
          </Row>

          <Row>
            <div className="form-group btn-container" >
              <Button onClick={ this.props.onFollowClose } className="btn btn-danger brand-color-background">
              	Cancel
              </Button>
              <Button className="btn btn-warning brand-color-background"
              	disabled={!this.state.showNameError && !this.state.showDescError && !this.state.showAssignedToError && !this.state.showAssignForError ? (false) : (true)}
              	id="submit_group" onClick={() => { this.onSubmitGroup()}}>
                Save Details
                <span className="glyphicon glyphicon-save"></span>
              </Button>
            </div>
          </Row>
      </div>
		);
	}
}
