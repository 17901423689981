import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';

export default class LinksTab extends Component {
  checkPage(page) {
    $('.sidebar').removeClass('ab-testing');
    $('.top-menu').removeClass('ab-testing');
  }

  checkIfLinkActive(tab) {
    var pathname = window.location.pathname;
    if(tab === 'dashboard' && pathname.match('dashboard') !== null) return true;
    else if(tab === 'lists' && pathname.match('lists') !== null) return true;
    else if(tab === 'donations' && pathname.match('donations') !== null) return true;
    else if(tab === 'ab-testing' && pathname.match('ab-testing') !== null) return true;
    else if(tab === 'campaigns' && pathname.match('ab-testing') === null && (pathname.match('campaigns') !== null || pathname.match('template') !== null)) return true;
    else if(tab === 'tasks' && pathname.match('tasks') !== null) return true;
    else if(tab === 'settings' && pathname.match('settings') !== null) return true;
    else return false;
  }

  render() {
    return (
      <div className="main-menus">
        <ul className="sidebar-menu-options">
          {this.props.activated_tabs.indexOf("Dashboard") > -1 ? (
            <Link to={'/dm/dashboard'} className={this.checkIfLinkActive('dashboard') ? "active sidebar-menu-items" : "sidebar-menu-items"} onClick={this.checkPage.bind(this, 'dashboard')}>
              <i className="fa fa-desktop sidebar-menu-item-icons"></i>
              Dashboard
            </Link>
          ) : (null)}

          {this.props.activated_tabs.indexOf("Lists") > -1 ? (
            <Link to={'/dm/lists'} className={this.checkIfLinkActive('lists') ? "active sidebar-menu-items" : "sidebar-menu-items"} onClick={this.checkPage.bind(this, 'lists')}>
              <i className="fa fa-th-list sidebar-menu-item-icons"></i>
              Lists
            </Link>
          ) : (null)}

          {this.props.activated_tabs.indexOf("Donations") > -1 ? (
            <Link to={'/dm/donations'} className={this.checkIfLinkActive('donations') ? "active sidebar-menu-items" : "sidebar-menu-items"} onClick={this.checkPage.bind(this, 'donations')}>
              <i className="fa fa-clone sidebar-menu-item-icons"></i>
              Donations
            </Link>
          ) : (null)}

          {this.props.activated_tabs.indexOf("Campaigns") > -1 ? (
            <Link to={'/dm/campaigns'} className={this.checkIfLinkActive('campaigns') ? "active sidebar-menu-items" : "sidebar-menu-items"} onClick={this.checkPage.bind(this, 'campaigns')}>
              <i className="fa fa-flag sidebar-menu-item-icons"></i>
              Campaigns
              {this.props.ngo.plan.name === 'payment_gateway' || this.props.ngo.plan.name === 'payment_gateway_basic_plus' ? (
                <i className="fa fa-lock"></i>
              ) : (null)}
            </Link>
          ) : (null)}

          {true || this.props.activated_tabs.indexOf("Campaigns") > -1 ? (
            <Link to={'/dm/campaigns/ab-testing'} className={this.checkIfLinkActive('ab-testing') ? "active sidebar-menu-items" : "sidebar-menu-items"} onClick={this.checkPage.bind(this, 'ab-testing')}>
              <img src="/images/ab_testing.png" className="sidebar-ab-testing-icon" />
              A/B Testing Campaigns
              {this.props.ngo.plan.name === 'payment_gateway' || this.props.ngo.plan.name === 'payment_gateway_basic_plus' ? (
                <i className="fa fa-lock"></i>
              ) : (null)}
            </Link>
          ) : (null)}

          {this.props.activated_tabs.indexOf("Tasks") > -1 ? (
            <Link to={'/dm/tasks'} className={this.checkIfLinkActive('tasks') ? "active sidebar-menu-items" : "sidebar-menu-items"} onClick={this.checkPage.bind(this, 'tasks')}>
              <i className="fa fa-calendar-o sidebar-menu-item-icons"></i>
              Tasks
              {this.props.ngo.plan.name === 'payment_gateway' || this.props.ngo.plan.name === 'payment_gateway_basic_plus' ? (
                <i className="fa fa-lock"></i>
              ) : (null)}
            </Link>
          ) : (null)}

          {this.props.activated_tabs.indexOf("Settings") > -1 ? (
            <Link to={'/dm/settings'} className={this.checkIfLinkActive('settings') ? "active sidebar-menu-items" : "sidebar-menu-items"} onClick={this.checkPage.bind(this, 'settings')}>
              <i className="fa fa-gear sidebar-menu-item-icons"></i>
              settings
            </Link>
          ) : (null)}
        </ul>
      </div>
      );
    }
  }