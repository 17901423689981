import React, {Component} from 'react';
import Icon from 'react-icons-kit';
import { ic_smartphone } from 'react-icons-kit/md/ic_smartphone';
import { ic_tablet_android } from 'react-icons-kit/md/ic_tablet_android';
import { ic_laptop_mac } from 'react-icons-kit/md/ic_laptop_mac';
import { ic_desktop_windows } from 'react-icons-kit/md/ic_desktop_windows'

const readCookie = require('../cookie.js').readCookie;

export default class SingleCampaignPreview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			body: null,
			selectedDevice: ''
		}
	}

	changeSelectedDevice(device) {
		this.setState({ selectedDevice: device });
	}

	componentWillMount() {
		let body = this.props.contentToPreview;
    this.setState({ body });
	}

	render() {
		return (
			<div className="campaign-previewer-container">
	    	<div className="campaign-previewer-sidebar">
	    		<button onClick={this.props.hideCampaignPreview.bind(this)} title="Close">×</button>
	        <ul className="campaign-previewer-nav">
            <li>
            	<a data-preview="phone" title="Phone"
            		className={this.state.selectedDevice === 'phone' ? 'active' : ''}
            		onClick={this.changeSelectedDevice.bind(this, 'phone')}>
            		<Icon size={30} icon={ic_smartphone} />
            	</a>
            </li>
            <li>
            	<a data-preview="tablet" title="Tablet"
            		className={this.state.selectedDevice === 'tablet' ? 'active' : ''}
            		onClick={this.changeSelectedDevice.bind(this, 'tablet')}>
            		<Icon size={30} icon={ic_tablet_android} />
            	</a>
            </li>
            <li>
            	<a data-preview="laptop" title="Laptop"
            		className={this.state.selectedDevice === 'laptop' ? 'active' : ''}
            		onClick={this.changeSelectedDevice.bind(this, 'laptop')}>
            		<Icon size={30} icon={ic_laptop_mac} />
            	</a>
            </li>
            <li>
            	<a data-preview="desktop" title="Desktop"
            		className={this.state.selectedDevice === 'desktop' ? 'active' : ''}
            		onClick={this.changeSelectedDevice.bind(this, 'desktop')}>
            		<Icon size={30} icon={ic_desktop_windows} />
            	</a>
            </li>
	        </ul>
	    	</div>
	    	<div className={"campaign-previewer-mainbody " + this.state.selectedDevice}>
	    		<div className={"campaign-previewer-mainbody-content " + this.state.selectedDevice}>
	    			{this.state.body !== null ? (
	    				<div className="addCampaignBody" dangerouslySetInnerHTML={{__html: this.state.body}}></div>
	    			) : (null)}
	    		</div>
	    	</div>
			</div>
		);
	}
}