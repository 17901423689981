import React, { Component } from 'react';
import swal from 'sweetalert2';

import Loader from './partials/Loader';

const createCookie = require('./partials/cookie.js').createCookie;

export default class RequestLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaded: false
		}
	}

	componentDidMount() {
		let searchString = this.props.location.search;
		if(searchString) {
			let token = this.getParameterByName('token', searchString),
				userId = this.getParameterByName('userId', searchString),
				leNgoId = this.getParameterByName('ngoId', searchString);

			if(token && userId && leNgoId) {
				fetch(process.env.REACT_APP_API_URL + '/request-login', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ leNgoId, token, userId })
				}).then((response) => response.json())
				.then((data) => {
					if(data.status === 'ok') {
						if(data.invited && data.email) {
							this.setState({ loaded: true });
							swal({
							  type: 'success',
								allowEscapeKey: false,
							  showCancelButton: false,
							  confirmButtonText: 'OK',
							  allowOutsideClick: false,
							  confirmButtonClass: 'btn',
							  title: 'Welcome to SAHAJ',
							  text: 'An invitation mail has been sent to your email address at ' + data.email
							}).then((result) => {
							  window.location.href = window.location.origin;
							});
						} else if(data.user) {
							createCookie('access_token', JSON.stringify(data.access_token), 7);
							createCookie('userData', JSON.stringify(data.user), 7);
							createCookie('ngo', JSON.stringify(data.ngo), 7);
							createCookie('mis_data', JSON.stringify(data.mis), 7);
							createCookie('sahajVersion', process.env.REACT_APP_VERSION, 7);
							window.location.href = window.location.origin;
						} else {
							window.location.href = window.location.origin;
						}
					} else {
						window.location.href = window.location.origin;
					}
				});
			} else {
				window.location.href = window.location.origin;
			}
		} else {
			window.location.href = window.location.origin;
		}
	}

	getParameterByName = (name, url) => {
		if(!url) return '';
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
				results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	render() {
		return (
			<div className="request-login-page">
				<Loader loaderHiding={this.state.loaded} />
			</div>
		);
	}
}