import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import ReactPhoneInput from 'react-phone-input';
import $ from 'jquery';
import { LocaleProvider, DatePicker } from 'antd';
import moment from 'moment';
import en_US from 'antd/lib/locale-provider/en_US';

export default class AddNewDonor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxDate: ''
    }
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  handleNationalityChange() {
    var $this = $('.non_existing_donor_details').find('select#nationality');
    var $selectedNationality = $($this).find(':selected');
    this.props.selectNationality($selectedNationality.val());
  }

  handleCountryChange(state, city) {
    var $this = $('.non_existing_donor_details').find('select#country');
    var $selectedCountry = $($this).find(':selected');
    var countryId = $selectedCountry.attr('countryId');
    this.props.selectCountry($selectedCountry.val());
    fetch(process.env.REACT_APP_LE_URL + '/getlocation?type=1&countryId=' + countryId)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      $('.non_existing_donor_details').find('select#state').html('<option value="" disabled selected>Choose your state</option>');
      $('.non_existing_donor_details').find('select#city').html('<option value="" disabled selected>Choose your city</option>');
      for(var i = 0; i < data.length; i++) {
        $('.non_existing_donor_details').find('select#state').append('<option value="' + data[i]['name'] + '" stateId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
      }
      if(state !== '') {
        $('.non_existing_donor_details').find('select#state').find('option[value=' + state + ']').prop('selected', true);
        var $this2 = $('.non_existing_donor_details').find('select#state');
        var $selectedState = $($this2).find(':selected');
        var stateId = $selectedState.attr('stateId');
        this.props.selectState($selectedState.val());
        fetch(process.env.REACT_APP_LE_URL + '/getlocation?type=2&stateId=' + stateId)
        .then(function(response) {
          return response.json();
        }).then(function(data) {
          $('.non_existing_donor_details').find('select#city').html('<option value="" disabled selected>Choose your city</option>');
          for(var i = 0; i < data.length; i++) {
            $('.non_existing_donor_details').find('select#city').append('<option value="' + data[i]['name'] + '" cityId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
          }
          if(city !== '') {
            $('.non_existing_donor_details').find('select#city').find('option[value=' + city + ']').prop('selected', true);
          }
        });
      }
    }.bind(this));
  }

  handleStateChange() {
    var $this = $('.non_existing_donor_details').find('select#state');
    var $selectedState = $($this).find(':selected');
    var stateId = $selectedState.attr('stateId');
    this.props.selectState($selectedState.val());
    fetch(process.env.REACT_APP_LE_URL + '/getlocation?type=2&stateId=' + stateId)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      $('.non_existing_donor_details').find('select#city').html('<option value="" disabled>Choose your city</option>');
      for(var i = 0; i < data.length; i++) {
        $('.non_existing_donor_details').find('select#city').append('<option value="' + data[i]['name'] + '" cityId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
      }
    });
  }

  handleCityChange() {
    var $this = $('.non_existing_donor_details').find('select#city');
    var $selectedCity = $($this).find(':selected');
    this.props.onDonorCityChange($selectedCity.val());
  }

  disabledEndDate(endValue) {
    if (moment().diff(moment(endValue), 'days') > 0) return false;
    else if(endValue === undefined) return false;
    else return moment(endValue.valueOf());
  }

  handleDateChange(date) {
    let e = {};
    let target = {};
    target['value'] = date;
    e['target'] = target;
    this.props.onDonorDobChange(e);
  }

  componentDidMount() {
    var prevYear = new Date();
    var dd = prevYear.getDate();
    var mm = prevYear.getMonth()+1;
    var yyyy = prevYear.getFullYear();
    if(dd < 10) dd = '0' + dd;
    if(mm < 10) mm = '0' + mm;
    yyyy -= 1;
    prevYear = yyyy + '-' + mm + '-' + dd;

    this.setState({ maxDate: prevYear });

    fetch(process.env.REACT_APP_LE_URL + '/getlocation?type=0')
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      for(var i = 0; i < data.length; i++) {
        $('.non_existing_donor_details').find('select#nationality').append('<option value="' + data[i]['name'] + '">' + data[i]['name'] + '</option>');
        $('.non_existing_donor_details').find('select#country').append('<option value="' + data[i]['name'] + '" countryId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
      }
      if(this.props.donor.nationality !== '') $('.non_existing_donor_details').find('select#nationality').find('option[value=' + this.props.donor.nationality + ']').prop('selected', true);
      if(this.props.donor.country !== '') {
        $('.non_existing_donor_details').find('select#country').find('option[value=' + this.props.donor.country + ']').prop('selected', true);
        this.handleCountryChange(this.props.donor.state, this.props.donor.city);
      }
    }.bind(this));
  }

  render () {
    return (
      <div className="margin-col-12 non_existing_donor_details">
        <Row>
          <Col md={12}>
            <p className="modal-sec-title red mt20">This donor doesn't exist. Kindly fill in the below data</p>
          </Col>

          <Col className="form-group" md={2}>
            <label className="control-label is-imp">Type:</label>
            <select className="form-control" id="type" name="type" required value={this.props.donor.donor_type} onChange={this.props.onDonorTypeChange}>
              <option value="individual">Individual</option>
              <option value="corporate">Corporate</option>
              <option value="corporate_employee">Corporate Employee</option>
            </select>
          </Col>

          <Col className="form-group" md={2}>
            <label className="control-label is-imp">Title:</label>
            <select className="form-control" id="title" name="title" required value={this.props.donor.title} onChange={this.props.onDonorTitleChange}>
              <option value="mr">Mr</option>
              <option value="mrs">Mrs</option>
              <option value="dr">Dr</option>
            </select>
          </Col>

          <Col className="form-group" md={4}>
            <label className="control-label is-imp">First Name:</label>
            <input type="text" className="form-control" id="first_name" name="first_name"
              placeholder="John" required value={this.props.donor.first_name} onChange={this.props.onDonorFnameChange}/>
            <span hidden={!this.props.showFirstNameError}><span className="required-span">Please enter a valid First Name.</span></span>
          </Col>

          <Col className="form-group" md={4}>
            <label className="control-label is-imp">Last Name:</label>
            <input type="text" className="form-control" id="last_name" name="last_name"
              placeholder="Doe" required value={this.props.donor.last_name} onChange={this.props.onDonorLnameChange}/>
            <span hidden={!this.props.showLastNameError}><span className="required-span">Please enter a valid Last Name.</span></span>
          </Col>
        </Row>

        <Row>
          <Col className="form-group" md={4}>
            <label className="control-label">Phone:</label>
            <ReactPhoneInput defaultCountry={'in'} type="tel" id="donor_phone" value={this.props.donor.phone_no} onChange={this.props.onDonorPhoneChange}/>
            <span hidden={!this.props.showPhoneError}><span className="required-span">Please enter a valid Phone number.</span></span>
          </Col>

          <Col className="form-group" md={4}>
            <label className="control-label is-imp">Email:</label>
            <input type="email" className="form-control" id="email" name="email"
              placeholder="john@domain.com" required disabled value={this.props.notFoundDonorEmailValue} />
          </Col>

          <Col className="form-group" md={4}>
            <label className="control-label">DOB:</label>
            <LocaleProvider locale={en_US}>
              <DatePicker value={this.props.donor.dob === '' ? '' : moment(this.props.donor.dob)} placeholder="Click to select a date"
                onChange={this.handleDateChange.bind(this)} format="DD/MM/YYYY" disabledDate={this.disabledEndDate} className="date-picker-dob" />
            </LocaleProvider>
            <span hidden={!this.props.showDobError}><span className="required-span">Please enter a valid Date of Birth.</span></span>
          </Col>
        </Row>

        <Row>
          <Col className="form-group" md={4}>
            <label className="control-label is-imp">Nationality:</label>
            <select id="nationality" className="form-control" onChange={this.handleNationalityChange.bind(this)} defaultValue="">
              <option value="" disabled>Choose your nationality</option>
            </select>
            <span hidden={!this.props.showNationalityError}><span className="required-span">Please enter a valid Nationality.</span></span>
          </Col>

          <Col className="form-group" md={4}>
            <label className="control-label">Country:</label>
            <select id="country" className="form-control" onChange={this.handleCountryChange.bind('', '')} defaultValue="">
              <option value="" disabled>Choose your country</option>
            </select>
          </Col>

          <Col className="form-group" md={4}>
            <label className="control-label">State:</label>
            <select id="state" className="form-control" onChange={this.handleStateChange.bind(this)} defaultValue="">
              <option value="" disabled>Choose your state</option>
            </select>
          </Col>


        </Row>

        <Row>
          <Col className="form-group" md={4}>
            <label className="control-label">City:</label>
            <select id="city" className="form-control" onChange={this.handleCityChange.bind(this)} defaultValue="">
              <option value="" disabled>Choose your city</option>
            </select>
          </Col>

          <Col className="form-group" md={4}>
            <label className="control-label">Street/House/Flat No:</label>
            <input type="text" className="form-control" id="address1" name="address1"
            placeholder="208, Hosur Rd, Sector 6" required value={this.props.donor.address1} onChange={this.props.onDonorAddress1Change}/>
            <span><span className="red-color">Input is too long!</span></span>
          </Col>

          <Col className="form-group" md={4}>
            <label className="control-label">Area/Locality/Colony:</label>
            <input type="text" className="form-control"
            id="address2" name="address2"
            placeholder="HSR Layout" required value={this.props.donor.address2} onChange={this.props.onDonorAddress2Change}/>
            <span><span className="red-color">Input is too long!</span></span>
          </Col>
        </Row>

        <Row>
          <Col className="form-group" md={4}>
            <label className="control-label">Pin/Zip:</label>
            <input type="text" placeholder="Pincode" id="pincode" className="form-control"
              value={this.props.donor.zipcode} onChange={this.props.onDonorPincodeChange}/>
          </Col>

          <Col className="form-group" md={4}>
            <label className="control-label">Donor Source:</label>
            <input type="text" placeholder="Source" id="source" className="form-control"
              value={this.props.donor.donor_source} onChange={this.props.onDonorSourceChange}/>
          </Col>

          <Col className="form-group" md={4}>
            <label className="control-label">PAN:</label>
            <input type="text" className="form-control" id="pan" name="pan"
              placeholder="BJKPC1221X" required value={this.props.donor.pan_number} onChange={this.props.onDonorPanChange}/>
            <span hidden={!this.props.showPanError}><span className="required-span">Please enter a valid PAN.</span></span>
          </Col>
        </Row>
      </div>
    );
  }
}
