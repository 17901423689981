import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";
import {arrayMove} from 'react-sortable-hoc';
import $ from 'jquery';

const readCookie = require('../cookie.js').readCookie;

window.jQuery = $.noConflict();

export default class ImportDonors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importedDonorData: null,
      detected_headers: null,
      system_headers: null,
      custom_system_headers: null
    };
    this.uploadDonorFile = this.uploadDonorFile.bind(this);
  }

  showDonorsFileUploadButton(e) {
    if(e.target.files.length) {
      $('#uploadDonorsFile').removeClass('hidden');
      $('#cancelUploadDonorsFile').removeClass('hidden');
    } else {
      $('#uploadDonorsFile').addClass('hidden');
      $('#cancelUploadDonorsFile').addClass('hidden');
    }
  }

  uploadDonorFile() {
    var donorFile = $('input#importDonorsFile');

    var data = new FormData();
    data.append('data_sheet', donorFile[0].files[0]);

    var list_id = window.location.href.split('/dm/lists/')[1];
    fetch(process.env.REACT_APP_API_URL + '/get_sheet_meta/' + this.props.ngo_id + '/' + list_id, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: data
    }).then((response) => response.json())
    .then(function(data) {
      if(data['status'] === 'ok') {
        this.props.updateSystemHeaders(data['data']['system_headers']);
        this.props.updateCustomSystemHeaders(data['data']['custom_system_headers']);
        this.props.updateDataId(data['data']['data_id']);
        this.setState({
          importedDonorData: data['data'],
          detected_headers: data['data']['detected_headers'],
          system_headers: data['data']['system_headers'],
          custom_system_headers: data['data']['custom_system_headers']
        }, function() {
          let detected_headers = this.state.detected_headers;

          var link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = '/css/jquery-ui.min.css';
          document.head.appendChild(link);

          $(document).ready(function() {
            $.getScript('/js/jquery-ui.min.js', function() {
              $("ul.system-header").sortable({
                placeholder: 'placeholder',
                connectWith: "ul.system-header",
                receive: function(event, ui) {
                  var $this = $(this);
                  if ($this.children('li').length > 1 && $this.hasClass('max-header-one')) {
                    $(ui.sender).sortable('cancel');
                  }
                }
              });
              $("ul.system-header").disableSelection();

              $("ul.custom-system-header").sortable({
                placeholder: 'placeholder',
                connectWith: "ul.system-header",
                receive: function(event, ui) {
                  var $this = $(this);
                  if ($this.children('li').length > 1 && $this.hasClass('max-header-one')) {
                    $(ui.sender).sortable('cancel');
                  }
                }
              });
              $("ul.custom-system-header").disableSelection();

              for(var i = 0; i < detected_headers.length; i++) {
                let detected_header = detected_headers[i];
                if(detected_header.toLowerCase().match('donor') !== null && detected_header.toLowerCase().match('type') !== null) {
                  if($('#system-headers-donor_type ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-donor_type ul.system-header');
                } else if(detected_header.toLowerCase().match('first') !== null && detected_header.toLowerCase().match('name') !== null) {
                  if($('#system-headers-first_name ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-first_name ul.system-header');
                } else if(detected_header.toLowerCase().match('last') !== null && detected_header.toLowerCase().match('name') !== null) {
                  if($('#system-headers-last_name ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-last_name ul.system-header');
                } else if(detected_header.toLowerCase().match('email') !== null) {
                  if($('#system-headers-email ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-email ul.system-header');
                } else if(detected_header.toLowerCase().match('phone') !== null || detected_header.toLowerCase().match('mobile') !== null) {
                  if($('#system-headers-phone_no ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-phone_no ul.system-header');
                } else if(detected_header.toLowerCase().match('pan') !== null) {
                  if($('#system-headers-pan_number ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-pan_number ul.system-header');
                } else if(detected_header.toLowerCase().match('nationality') !== null) {
                  if($('#system-headers-nationality ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-nationality ul.system-header');
                } else if(detected_header.toLowerCase().match('address1') !== null || detected_header.toLowerCase().match('address') !== null) {
                  if($('#system-headers-address1 ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-address1 ul.system-header');
                } else if(detected_header.toLowerCase().match('address2') !== null) {
                  if($('#system-headers-address2 ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-address2 ul.system-header');
                } else if(detected_header.toLowerCase().match('country') !== null) {
                  if($('#system-headers-country ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-country ul.system-header');
                } else if(detected_header.toLowerCase().match('state') !== null) {
                  if($('#system-headers-state ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-state ul.system-header');
                } else if(detected_header.toLowerCase().match('city') !== null) {
                  if($('#system-headers-city ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-city ul.system-header');
                } else if(detected_header.toLowerCase().match('zipcode') !== null || detected_header.toLowerCase().match('pin') !== null || detected_header.toLowerCase().match('postal') !== null) {
                  if($('#system-headers-zipcode ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-zipcode ul.system-header');
                } else if((detected_header.toLowerCase().match('organisation') !== null || detected_header.toLowerCase().match('organization') !== null) && detected_header.toLowerCase().match('name') !== null) {
                  if($('#system-headers-org_name ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-org_name ul.system-header');
                } else if((detected_header.toLowerCase().match('organisation') !== null || detected_header.toLowerCase().match('organization') !== null) && detected_header.toLowerCase().match('position') !== null) {
                  if($('#system-headers-org_position ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-org_position ul.system-header');
                } else if(detected_header.toLowerCase().match('source') !== null || detected_header.toLowerCase().match('channel') !== null) {
                  if($('#system-headers-donor_source ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-donor_source ul.system-header');
                } else if(detected_header.toLowerCase().match('priority') !== null || detected_header.toLowerCase().match('rating') !== null) {
                  if($('#system-headers-priority ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-priority ul.system-header');
                } else if(detected_header.toLowerCase().match('gender') !== null || detected_header.toLowerCase().match('sex') !== null) {
                  if($('#system-headers-gender ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-gender ul.system-header');
                } else if(detected_header.toLowerCase().match('dob') !== null || detected_header.toLowerCase().match('birthday') !== null || (detected_header.toLowerCase().match('birth') !== null && detected_header.toLowerCase().match('date') !== null)) {
                  if($('#system-headers-dob ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-dob ul.system-header');
                } else if(detected_header.toLowerCase().match('anniversary') !== null || detected_header.toLowerCase().match('marriage') !== null) {
                  if($('#system-headers-marriage_anniversary ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-marriage_anniversary ul.system-header');
                } else if(detected_header.toLowerCase().match('note') !== null || detected_header.toLowerCase().match('notice') !== null || detected_header.toLowerCase().match('comment') !== null) {
                  if($('#system-headers-note ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-note ul.system-header');
                }
              }
            });
          });
        }.bind(this));
        $('#uploadDonorsFile').addClass('hidden');
        $('#cancelUploadDonorsFile').addClass('hidden');
        $('input#importDonorsFile').val('');
        this.props.showSubmitButton();
      }
    }.bind(this));
  }

  cancelUploadDonorFile() {
    $('#uploadDonorsFile').addClass('hidden');
    $('#cancelUploadDonorsFile').addClass('hidden');
    $('input#importDonorsFile').val('');
  }

  onSortEnd({oldIndex, newIndex}) {
    this.setState({ detected_headers: arrayMove(this.state.detected_headers, oldIndex, newIndex) });
  }

  render() {
    return (
      <div>
        <Row>
          {(() => {
            if (this.state.importedDonorData === null) {
              return (
                <Row>
                  <Col md={12} className="mt10 mb10 text-center">
                    <input id="importDonorsFile" className="mt10 mb10" type="file" onChange={this.showDonorsFileUploadButton} accept=".csv,.xlsx" />
                  </Col>
                  <Col md={12} className="mt10 mb10 text-center">
                    <note>
                      Note: Maximum file size 15MB, only .csv & .xlsx files supported.
                    </note>
                  </Col>
                  <Col md={12} className="mt10">
                    <Button id="uploadDonorsFile" className="btn-success react-bs-table-add-btn col-md-offset-2 col-md-3 hidden" onClick={this.uploadDonorFile}>Upload</Button>
                    <Button id="cancelUploadDonorsFile" className="btn-danger react-bs-table-add-btn col-md-offset-2 col-md-3 hidden" onClick={this.cancelUploadDonorFile}>Cancel Upload</Button>
                  </Col>
                </Row>
              );
            } else if(this.state.importedDonorData !== null) {
              return (
                <Col md={12} className="select-donor-data-headers">
                  <Col md={12}><p className="modal-sec-title">CSV Headers</p></Col>
                  <div id="root">
                    <ul className="SortableList system-header" onSortEnd={this.onSortEnd.bind(this)} helperClass="SortableHelper">
                      {this.state.detected_headers.map(function (data, index) {
                        return (
                          <li id={'csv-headers-' + index} className="SortableItem" key={index} data-index={index}>
                            {data}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <Col md={12}>
                    <p className="modal-sec-title">Our Headers</p>
                    <p>Note: Drag & Drop the csv headers into our headers to map them.</p>
                  </Col>
                  {Object.keys(this.state.system_headers).map(function(key) {
                    var system_header = this.state.system_headers[key];
                    var value = Object.keys(system_header);
                    return (
                      <div id={'system-headers-' + value} className="system-headers-div" key={value}>
                        <span>{system_header[value]}<i className="fa fa-arrows-h"></i></span>
                        <ul className="system-header max-header-one"></ul>
                      </div>
                    );
                  }.bind(this))}
                  {Object.keys(this.state.custom_system_headers).map(function(key) {
                    var custom_system_header = this.state.custom_system_headers[key];
                    var value = Object.keys(custom_system_header);
                    return (
                      <div id={'system-headers-' + value} className="custom-system-headers-div" key={value}>
                        <span>{custom_system_header[value]}<i className="fa fa-arrows-h"></i></span>
                        <ul className="custom-system-header system-header max-header-one"></ul>
                      </div>
                    );
                  }.bind(this))}
                  <div className="system-headers-div other-headers-container">
                    <span className="other-headers-span">All Other headers<i className="fa fa-arrows-h"></i></span>
                    <ul className="other-headers system-header"></ul>
                  </div>
                </Col>
              );
            }
          })()}
        </Row>
      </div>
    );
  }
}

