import React, {Component} from 'react';
import {Row, Col, Image} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import LinksTab from './SidebarTabs/LinksTab';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  render() {
    return (
      <div className="sidebar">
        <div className="user-section">
          <Row className="user-detail-row">
            <Col md={12} className="user-avatar">
              <Link to={'/dm/settings'} id="2">
                {(() => {
                  if(this.props.ngo['ngo_logo'] === '') {
                    return (
                      <Image src="/images/logo-placeholder.png" height="50" />
                    )
                  } else {
                    return (
                      <img src={this.props.ngo['ngo_logo']} height="50" onError={(event)=>event.target.setAttribute("src","/images/logo.png")} alt="Logo" />
                    )
                  }
                })()}
              </Link>
            </Col>
            <Col md={12} className="user-details">
              {this.props.ngo['ngo_name']}
            </Col>
          </Row>
        </div>

        <div className="open-close-toggle">
          <i className="fa fa-angle-double-right"></i>
        </div>

        <div className="main-sidebar-sec">
          <Row>
              <LinksTab ngo={this.props.ngo} activated_tabs={this.props.ngo.activated_tabs} />
          </Row>
        </div>

        <div className="powered-sec">
          <Row>
            <a href="https://www.letsendorse.com" target="_blank">
              Powered By
              <Image src="/images/le.png" width="150" />
            </a>
          </Row>
        </div>
      </div>
    );
  }
}