import React from 'react';
import { Modal, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const readCookie = require('../../partials/cookie.js').readCookie;

export default class ShareFormModal extends React.Component {
  
  state = {
    copyLink: window.location.host +"/fb/form/publish/" + this.props.selectedForm,
    codeEmbed: `<iframe src="${window.location.host + "/fb/form/publish/" + this.props.selectedForm}" width="640" height="480"/>`,
    selectedFormLink: ''
  }

  shareHandler(type){
    this.setState({selectedFormLink:''}, ()=>{
      if(type === 'fb'){
        window.open("https://www.facebook.com/sharer/sharer.php?u="+ window.location.host +"/fb/form/publish/" + this.props.selectedForm +"", "popupWindow", "width=600,height=600,scrollbars=yes");
      } else if(type === 'tw'){
        window.open('https://twitter.com/home?status='+ window.location.host +"/fb/form/publish/" + this.props.selectedForm + '',"popupWindow","width=600,height=600,scrollbars=yes");
      } else if(type === 'ln'){
        window.open("https://www.linkedin.com/shareArticle?mini=true&url="+ window.location.host + "/fb/form/publish/" + this.props.selectedForm +"","popupWindow","width=600,height=600,scrollbars=yes");
      }
    })
  }

  notifyMsg(type){
    if(type === 'share'){
      this.setState({selectedFormLink: this.state.copyLink}, ()=>{
        this.notifySuccess('URL Copied');
      }); 
    } else if(type === 'embed'){
      this.setState({selectedFormLink: this.state.codeEmbed}, ()=>{
        this.notifySuccess('Snippet Copied');
      });
    }
  }

  notifySuccess = (text) => toast.success(text);

	render() {
		return (
			<Modal className="fb-form-share-modal" show={this.props.showShareModal} onHide={this.props.closeShareModal}>
        <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
        <Modal.Header className="share-modal-header">
          <Modal.Title className="modal-header-custom">Select your platform</Modal.Title>
          <div className="share-cancel" onClick={this.props.closeShareModal}>X</div>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Row className="body-conatiner">
            <Col md={12} className="link-container">
              <div className="share-form-links facebook" onClick={this.shareHandler.bind(this, 'fb')} title="Share the link on Facebook"><i className="fa fa-facebook"></i></div>
              <div className="share-form-links twitter" onClick={this.shareHandler.bind(this, 'tw')} title="Share the link on Twitter" ><i className="fa fa-twitter"></i></div>
              <div className="share-form-links linkedin" onClick={this.shareHandler.bind(this, 'ln')} title="Share the link on Linkedin" ><i className="fa fa-linkedin"></i></div>
              <div className="additionals" title="Embed into your code">
                <CopyToClipboard text={this.state.codeEmbed}>
                  <button className="share-form-links embed" onClick={this.notifyMsg.bind(this, 'embed')}><i className="fa fa-code"></i></button>
                </CopyToClipboard>
              </div>
              <div className="additionals" title="Share your form link">
                <CopyToClipboard text={this.state.copyLink}>
                  <button className="share-form-links share" onClick={this.notifyMsg.bind(this, 'share')}><i className="fa fa-link"></i></button>
                </CopyToClipboard>
              </div>
            </Col>
            {this.state.selectedFormLink !== '' ? (
              <Col md={12} className="link-display">
                <input type="text" value={this.state.selectedFormLink} className="input-link-display"/>
              </Col>
            ) : (null)}
          </Row>  
        </Modal.Body>
      </Modal>
		);
	}
}
