import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import moment from 'moment';

export default class ImportantDateSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(this.props.date).format('YYYY-MM-DD'),
      details: this.props.details
    }
  }

  dateChange(e) {
    this.setState({ date: e.target.value });
  }

  detailsChange(e) {
    this.setState({ details: e.target.value });
  }

  render () {
    return (
      <Row>
        <Row className="important-date-modal">
          <Row>
              <Col className="form-group" md={4}>
                <label className="control-label is-imp">Date:</label>
                <input type="date" placeholder="Date" className="input-text date" value={this.state.date} onChange={this.dateChange.bind(this)} />
              </Col>

              <Col className="form-group" md={7}>
                <label className="control-label is-imp">Detail:</label>
                <input type="text" placeholder="Details" className="input-text details" value={this.state.details} onChange={this.detailsChange.bind(this)} />
              </Col>

              <Col className="form-group" md={1}>
                <label className="control-label">Remove:</label>
                <Button onClick={this.props.removeChild.bind(this, this.props.number)}><i className="fa fa-times"></i></Button>
              </Col>
          </Row>
        </Row>
      </Row>
    );
  }
}
