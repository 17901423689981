import React, {Component} from 'react';
import $ from 'jquery';
import swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const readCookie = require('../../partials/cookie.js').readCookie;

const createFormToView = require('../../../scripts/formBuilder/view.js').createFormToView;
const submitForm = require('../../../scripts/formBuilder/view.js').submitForm;

const FBGoogleMap = compose(
  withProps({
	googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBfvdlr4pZ5UbmIM9KzNSASmDy9pZsLQn0&v=3.exp&libraries=geometry,drawing,places",
	loadingElement: <div style={{ height: `100%` }} />,
	containerElement: <div style={{ height: `400px` }} />,
	mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => <GoogleMap defaultZoom={2} defaultCenter={{ lat: props.defaultLocation.latitude, lng: props.defaultLocation.longitude }}>
	{props.isMarkerShown && <Marker  position={{ lat: props.defaultLocation.latitude, lng: props.defaultLocation.longitude }}
		draggable={true} onDragEnd={(evt, map, coord) => props.onDragEnd(evt)} />}
  </GoogleMap>
);

export default class FBPreviewForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: null,
			copyLink: '',
			codeEmbed: '',
			formStatus: '',
			message: '',
			showLocationInput: false,
			isMarkerShown: true,
			defaultLocation: {
				latitude: 0,
				longitude: 0
			},
			markerLabel: ""
		}
	}

	notifyMsg(type){
		if(type === 'Embedd') this.notifySuccess('Iframe Copied');
		if(type === 'Link') this.notifySuccess('Link Copied');
	}

	shareHandler(type){
		if(type === 'fb' && this.props.match.params['formId'] !== ''){
			window.open("https://www.facebook.com/sharer/sharer.php?u="+ window.location.origin +"/fb/form/publish/" + this.props.match.params['formId'] +"", "popupWindow", "width=600,height=600,scrollbars=yes");
		} else if(type === 'tw' && this.props.match.params['formId'] !== ''){
			window.open('https://twitter.com/home?status='+ window.location.origin +"/fb/form/publish/" + this.props.match.params['formId'] + '',"popupWindow","width=600,height=600,scrollbars=yes");
		} else if(type === 'ln' && this.props.match.params['formId'] !== ''){
			window.open("https://www.linkedin.com/shareArticle?mini=true&url="+ window.location.origin + "/fb/form/publish/" + this.props.match.params['formId'] +"","popupWindow","width=600,height=600,scrollbars=yes");
		}
	}

  notifySuccess = (text) => toast.success(text);

	componentDidMount(){
		var x = $.getScript('/js/formBuilder/viewElements.js');
		document.write('<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.99.0/css/materialize.min.css">');
		fetch(process.env.REACT_APP_API_URL + '/fb/get/form/' + this.props.match.params['formId'], {
			method: 'GET',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			if(data.status === 'ok'){
				var that = this;
				var position = {};
				var showLocationInput = data.data.form_details.geolocation;
				if(showLocationInput){
					if(navigator.geolocation){
						navigator.geolocation.getCurrentPosition(function(pos) {
							position['latitude'] = pos.coords.latitude;
							position['longitude'] = pos.coords.longitude;
							fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + pos.coords.latitude + ',' + pos.coords.longitude + '&key=AIzaSyBfvdlr4pZ5UbmIM9KzNSASmDy9pZsLQn0')
							.then((response) => response.json())
							.then((responseJson) => {
							  let addressObj = {}
							  addressObj['formatted_address'] = responseJson.results[0]["formatted_address"];
							  addressObj['placeId'] = responseJson.results[0]["place_id"];
							  addressObj['latlng'] = {
									lat: pos.coords.longitude,
									lng: pos.coords.longitude
							  }
							  window.addressObj = addressObj;
							  that.setState({markerLabel: responseJson.results[0]["formatted_address"]})
							})
						})
					}
				}
				Promise.all([x]).then(function() {
					that.setState({form: data.data, showLocationInput, formStatus: data.data['status'], codeEmbed: `<iframe src="${window.location.origin}/fb/form/publish/${that.props.match.params['formId']}" width="100%" height="645px" frameborder="0" />`, copyLink: window.location.origin +"/fb/form/publish/" + that.props.match.params['formId']}, function(){
						createFormToView(data.data, 'testing');
						window.formUrl = process.env.REACT_APP_API_URL + '/fb/post/response/' + that.props.match.params['formId'];
						window.fileUrl = process.env.REACT_APP_API_URL + '/fb/uploadfile/' + that.props.match.params['formId'];
						window.formId = that.props.match.params['formId'];
						window.form = data.data;
						window.relationalUrl = process.env.REACT_APP_API_URL + '/fb/relational-data/' + that.props.match.params['formId'];
						if(document.getElementById('testing') !== null) {
						  document.getElementById('testing').addEventListener("click", function() {
							  submitForm(that.props.match.params['formId']);
							});
						}
					});
				});
			} else if (data.status === "error"){
				this.setState({message: data.message});
			}
		});
	}

	onMarkerDragEnd = (evt) => {
		fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + evt.latLng.lat() + ',' + evt.latLng.lng() + '&key=AIzaSyBfvdlr4pZ5UbmIM9KzNSASmDy9pZsLQn0')
		.then((response) => response.json())
		.then((responseJson) => {
		let addressObj = {}
		addressObj['formatted_address'] = responseJson.results[0]["formatted_address"];
		addressObj['placeId'] = responseJson.results[0]["place_id"];
		addressObj['latlng'] = {
				lat: evt.latLng.lat(),
				lng: evt.latLng.lng()
		}
		window.addressObj = addressObj;
		this.setState({markerLabel: responseJson.results[0]["formatted_address"]})
		})
	}

	nextStep(){
		if(window.addressObj !== undefined || window.addressObj !== null){
			this.setState({showLocationInput: false})
		} else {
			swal({
				title: 'Please mark your location first',
				message: 'Can not proceed without marking location first',
				type: 'warning'
			})
		}
	}

	render() {
		return (
			<div className="form-builder-container form-preview">
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				{this.state.message !== '' ? (
					<div className="show-form-error">
						<i className="fa fa-exclamation-triangle fb-error-icon"></i>
						<div className="form-error-message">{this.state.message + ' !!'}</div>
					</div>
				) : (
					this.state.form !== null ? (
						this.state.formStatus === 'Published' ? (
							<div className="preview-form-holder">
								<div id="_form-publish-settings">
									<div className="col-md-12 header-details"> Publish Form</div>
									<div className="col-md-12 publish-setiings-container">
										<div className="col-md-12 sub-header-container">
											<div className="col-md-12 sub-header-lg">Embed in your site</div>
											<div className="col-md-12 sub-header-sm">Simply copy and paste the code below in your html.</div>
										</div>
										<div className="col-md-12 text-holder">
											<input type="text" value={this.state.codeEmbed} className="col-md-12 code-share-input"/>
											<CopyToClipboard text={this.state.codeEmbed}>
												<button onClick={this.notifyMsg.bind(this, 'Embedd')} id="iframe-btn">Copy iframe</button>
											</CopyToClipboard>
										</div>
										<div className="col-md-12 link-share">
											<div className="col-md-12 share-link-header">Share a link</div>
											<div className="col-md-12 share-link-sub-header">Use this URL to create a hyperlink to your form</div>
											<input type="text" value={this.state.copyLink} className="col-md-12 link-share-input"/>
											<CopyToClipboard text={this.state.copyLink}>
												<button onClick={this.notifyMsg.bind(this, 'Link')} id="link-btn">Copy Link</button>
											</CopyToClipboard>
										</div>
										<div className="col-md-12 share-social-link">
											<div className="col-md-12 share-social-header">Share on social media</div>
											<div className="col-md-12 share-socail-sub-header">Share the link to your form on these social media sites</div>
											<div className="col-md-12 share-social-cards">
												<div className="share-form-links facebook" onClick={this.shareHandler.bind(this, 'fb')} title="Share the link on Facebook"><i className="fa fa-facebook"></i></div>
												<div className="share-form-links twitter" onClick={this.shareHandler.bind(this, 'tw')} title="Share the link on Twitter" ><i className="fa fa-twitter"></i></div>
												<div className="share-form-links linkedin" onClick={this.shareHandler.bind(this, 'ln')} title="Share the link on Linkedin" ><i className="fa fa-linkedin"></i></div>
											</div>
										</div>
										<div className="back-to-buttons col-md-12">
											<button className='go-btn' onClick={()=>{window.location.pathname = '/fb/dashboard'}}>Back to dashboard</button>
										</div>
									</div>
								</div>
								<div className="_form-outlet _form-outlet-half">
									<div className="publish-form-location-map" style={this.state.showLocationInput ? {padding: '20px', display: 'block', width: '80%'} : {display: 'none'}}>
										<div style={{width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
											<h5 style={{background: '#efefef', color: '#ef5a20', padding: '10px'}}>Please mark your location to proceed</h5>
										</div>
										{this.state.markerLabel !== '' ? (
											<div className="publish-form-legend" style={{
												left: '145px',
												width: 'auto',
												color: 'white',
												bottom: '110px',
												zIndex: '999999',
												padding: '6px 12px',
												borderRadius: '2px',
												position: 'absolute',
												background: '#ef5a20',
												boxShadow: '2px 2px 2px #999'
												}}>
												{this.state.markerLabel}
											</div>
										) : (null)}
											<FBGoogleMap isMarkerShown={this.state.isMarkerShown}
											onDragEnd={this.onMarkerDragEnd} defaultLocation={this.state.defaultLocation}/>
											<div style={{display: 'flex', justifyContent: 'center', marginTop: '30px', width: '100%'}}>
												<div className="publish-form-submit-loc-btn" onClick={this.nextStep.bind(this)} style={{
													width: '100px',
													color: 'white',
													display: 'flex',
													cursor: 'pointer',
													padding: '6px 12px',
													borderRadius: '2px',
													background: '#ef5a20',
													justifyContent: 'center',
													boxShadow: '1px 1px 1px #999'
												}}>Next <i className="fa fa-arrow-right" style={{margin: '3px 5px'}}></i></div>
											</div>
									</div>
									<div className="_form-holder" style={this.state.showLocationInput ? {display: 'none'} : {display: 'block'}}>
										<div className="_mb10 _form-details">
											<div className="row">
												<div className="col-md-12 _form-name">
													<p className="_form-name-element"></p>
												</div>
											</div>
										</div>
										<div className="_mb10 _header-container-view"></div>
										<div className="_mb10 _element-container-view" id="elementContainer"></div>
									</div>
								</div>
							</div>
						) : (
						<div className="preview-form-holder">
							<div className="publish-form-location-map" style={this.state.showLocationInput ? {padding: '20px', display: 'block'} : {display: 'none'}}>
								<div style={{width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
									<h5 style={{background: '#efefef', color: '#ef5a20', padding: '10px'}}>Please mark your location to proceed</h5>
								</div>
								{this.state.markerLabel !== '' ? (
									<div className="publish-form-legend" style={{
										left: '30px',
										width: 'auto',
										color: 'white',
										bottom: '140px',
										zIndex: '999999',
										padding: '6px 12px',
										borderRadius: '2px',
										position: 'absolute',
										background: '#ef5a20',
										boxShadow: '2px 2px 2px #999'
										}}>
										{this.state.markerLabel}
									</div>
								) : (null)}
									<FBGoogleMap isMarkerShown={this.state.isMarkerShown}
									onDragEnd={this.onMarkerDragEnd} defaultLocation={this.state.defaultLocation}/>
									<div onClick={this.nextStep.bind(this)} style={{
										width: '100px',
										color: 'white',
										display: 'flex',
										cursor: 'pointer',
										padding: '6px 12px',
										borderRadius: '2px',
										margin: '30px 600px',
										background: '#ef5a20',
										justifyContent: 'center',
										boxShadow: '1px 1px 1px #999'
									}}>Next <i className="fa fa-arrow-right" style={{margin: '3px 5px'}}></i></div>
							</div>
							<div className="_form-outlet _form-outlet-full" style={this.state.showLocationInput ? {display: 'none'} : {display: 'inline-flex'}}>
								<div className="_form-holder">
									<div className="_mb10 _form-details">
										<div className="row">
											<div className="col-md-12 _form-name">
												<p className="_form-name-element"></p>
											</div>
										</div>
									</div>
									<div className="_mb10 _header-container-view"></div>
									<div className="_mb10 _element-container-view" id="elementContainer"></div>
								</div>
							</div>
						</div>
						)
					) : (null)
				)}
			</div>
		);
	}
}
