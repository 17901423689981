import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

export default class OrganizationInformation extends Component {
  idExists(array, id) {
    return array.some(function(el) {
      return el.id === id;
    });
  }

  render () {
    if(this.props.list !== null && (this.idExists(this.props.list.default_member_fields_others, 'org_name') || this.idExists(this.props.list.default_member_fields_others, 'org_position'))) {
      return (
        <Row>
          <Col className="margin-col-10" md={12}>
            <Row>
              <Col md={12}>
                <p className="modal-sec-title member-add-edit-modal-subtitle">Organization Information</p>
              </Col>

              {this.props.list.default_member_fields_others.map(function(field, index) {
                if(field.id === 'org_name') {
                  return (
                    <Col className="form-group" md={4} key={index}>
                      <label className="control-label ">Organization Name:</label>
                      <input id="orgName" type="text" placeholder="LetsEndorse" value={this.props.donor.org_name} className="input-text" onChange={this.props.onDonorOrgNameChange}/>
                    </Col>
                  )
                } else if(field.id === 'org_position') {
                  return (
                    <Col className="form-group" md={4} key={index}>
                      <label className="control-label ">Position:</label>
                      <input id="orgPosition" type="text" placeholder="Manager" value={this.props.donor.org_position} className="input-text" onChange={this.props.onDonorOrgPositionChange}/>
                    </Col>
                  )
                }
              }.bind(this))}

            </Row>
          </Col>
        </Row>
      );
    } else return null;
  }
}