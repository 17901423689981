import React, { Component } from 'react';
import $ from 'jquery';
import moment from 'moment';
import swal from 'sweetalert2';
import { diff } from 'json-diff';

const readCookie = require('../../partials/cookie.js').readCookie;
const materializeStyle = `<style> #toast-container {	top: auto !important; right: auto !important; bottom: 10%; left:40%; } </style>`

export default class FBCreateForm extends Component {
  constructor(props) {
		super(props);
		this.state = {
			formId: '',
			added: false,
			lastSavedJson: {},
		}
  }

  componentDidMount() {
		$.getScript('/js/formBuilder/create.js');
		$.getScript('/js/formBuilder/save.js');
		$.getScript('/js/formBuilder/updateSettingsFromElementJSON.js');
		document.write('<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.99.0/css/materialize.min.css">');
		$('body').append(materializeStyle);
		this.autoSave(this);
  }

	autoSave() {
		var that = this;
		setInterval(function() {
			let loc = window.location.href.split('/');
			if(loc[loc.length-1] === 'create'){
				var lastSavedJson = that.state.lastSavedJson;
				var formDetailsData = $('._form-name-element').attr('data-json');
				var submissionData = $('._form-submit-settings-atr').attr('data-json');
				var headerDetailsData = $('._header-container ._form-element').attr('data-json');
				var allElements = $('._element-container ._form-element');
				var obj = {};

				if(formDetailsData) {
					if(formDetailsData.hasOwnProperty('valid_from') && formDetailsData.valid_from !== '') {
						if(typeof $('._form-name-element').data('json').valid_from === 'string') formDetailsData['valid_from'] = moment($('._form-name-element').data('json').valid_from, 'DD MMM, YYYY').unix();
					}

					if(formDetailsData.hasOwnProperty('valid_to') && formDetailsData.valid_to !== '') {
						if(typeof $('._form-name-element').data('json').valid_to === 'string') formDetailsData['valid_to'] = moment($('._form-name-element').data('json').valid_to, 'DD MMM, YYYY').unix();
					}
				} else {
					formDetailsData = {
						'title': 'Untitled',
						'description': 'Description',
						'valid_from': '',
						'valid_to': '',
						'time_from': '',
						'time_to': '',
						'offline_data': false,
						'geolocation': false,
						'deletable': true,
						'resp_editable': false
					};
				}
				if(typeof formDetailsData === 'string'){
					obj['form_details'] = JSON.parse(formDetailsData);
				} else if(typeof formDetailsData === 'object') {
					obj['form_details'] = formDetailsData;
				}

				//submission settings
				if(submissionData){
					if(typeof submissionData === 'string'){
						var settings = JSON.parse(submissionData)
						if(settings['button_text'].trim() !== ''){
							obj['submission_settings'] = settings;
						} else {
							settings['button_text'] = 'submit';
							obj['submission_settings'] = settings;
						}
					} else if(typeof submissionData === 'object'){
						if(submissionData['button_text'].trim() !== ''){
							obj['submission_settings'] = submissionData;
						} else {
							submissionData['button_text'] = 'submit';
							obj['submission_settings'] = submissionData;
						}
					}
				} else {
					submissionData = {
						'button_text': 'submit',
						'option_type': 'message',
						'option_details': 'Your response has been submitted.'
					}
					obj['submission_settings'] = submissionData;
				}

				if(headerDetailsData && typeof headerDetailsData === 'string') obj['form_header'] = JSON.parse(headerDetailsData);
				if(allElements.length > 0) obj['form_element'] = [];

				// formElements
				for (var i = 0; i < allElements.length; i++) {
					var elementsDataJSON = $(allElements).eq(i).attr('data-json');
					var elementsParentJSON = $(allElements).eq(i).attr('data-parent');
					var childIndexJSON = $(allElements).eq(i).attr('data-childindex');
					var isParentJSON = $(allElements).eq(i).attr('data-isparent');
					if(typeof elementsDataJSON === 'string') elementsDataJSON = JSON.parse(elementsDataJSON);
					if(typeof elementsParentJSON === 'string') elementsParentJSON = JSON.parse(elementsParentJSON);
					if(elementsDataJSON && elementsDataJSON['hasParent']) {
						if(elementsParentJSON && elementsParentJSON.hasOwnProperty('parentIndex') && elementsParentJSON.hasOwnProperty('parentQuestion') && elementsParentJSON.hasOwnProperty('parentAnswer') && elementsParentJSON.hasOwnProperty('parentCriteriaChoice') && elementsParentJSON.hasOwnProperty('selectedParentData')) {
							elementsDataJSON['parentIndex'] = elementsParentJSON['parentIndex'];
							elementsDataJSON['parentQuestion'] = elementsParentJSON['parentQuestion'];
							elementsDataJSON['parentAnswer'] = elementsParentJSON['parentAnswer'];
							elementsDataJSON['parentCriteriaChoice'] = elementsParentJSON['parentCriteriaChoice'];
							elementsDataJSON['selectedParentData'] = elementsParentJSON['selectedParentData'];
							if(elementsParentJSON.hasOwnProperty('selectedChoiceConditionType')){
								elementsDataJSON['selectedChoiceConditionType'] = elementsParentJSON['selectedChoiceConditionType'];
							}
						}
					}
					if(isParentJSON) {
						if(childIndexJSON && isParentJSON){
							elementsDataJSON['childIndex'] = childIndexJSON;
							elementsDataJSON['isParent'] = isParentJSON;
						}
					}
					obj.form_element.push(elementsDataJSON);
				}
				if(!that.state.added) {
					if(diff(obj, lastSavedJson) !== undefined) {
						fetch(process.env.REACT_APP_API_URL + '/fb/create/form/' + that.props.ngo.ngo_id, {
							method: 'POST',
							headers: {
								"Content-Type": "application/json",
								"Authorization": JSON.parse(readCookie('access_token')).access_token
							},
							body: JSON.stringify(obj)
						}).then(function (response) {
							return response.json();
						}).then(function (data) {
							if(data.status === 'ok'){
								window.userAuthData = JSON.parse(readCookie('access_token')).access_token;
								window.conditionalOptionUrl = process.env.REACT_APP_API_URL + '/fb/get/conditional/options/' + that.props.ngo.ngo_id + '/' + data['_id'];
								window.conditionalTitleUr = process.env.REACT_APP_API_URL + '/fb/get/conditional/title/' + that.props.ngo.ngo_id + '/' + data['_id'];
								window.formNamesUrl = process.env.REACT_APP_API_URL + '/fb/get/form_names/' + that.props.ngo.ngo_id;
								window.formElementsUrl = process.env.REACT_APP_API_URL + '/fb/get/form_element/' + that.props.ngo.ngo_id + '/';
								window.formResponsesUrl = process.env.REACT_APP_API_URL + '/fb/get/question-response/' + that.props.ngo.ngo_id + '/';
								window.token = JSON.parse(readCookie('access_token')).access_token;
								that.setState({ formId: data['_id'], lastSavedJson: obj, added: true });
							} else {
								that.setState({lastSavedJson: obj});
							}
						});
					}
				} else {
					if(diff(obj, lastSavedJson) !== undefined) {
						fetch(process.env.REACT_APP_API_URL + '/fb/update/form/' + that.props.ngo.ngo_id + '/' + that.state.formId, {
							method: 'PUT',
							headers: {
								"Content-Type": "application/json",
								"Authorization": JSON.parse(readCookie('access_token')).access_token
							},
							body: JSON.stringify(obj)
						}).then(function (response) {
							return response.json();
						}).then(function (data) {
							if(data.status === 'ok'){
								window.userAuthData = JSON.parse(readCookie('access_token')).access_token;
								window.conditionalOptionUrl = process.env.REACT_APP_API_URL + '/fb/get/conditional/options/' + that.props.ngo.ngo_id + '/' + that.state.formId;
								window.conditionalTitleUrl = process.env.REACT_APP_API_URL + '/fb/get/conditional/title/' + that.props.ngo.ngo_id + '/' + that.state.formId;
								window.formNamesUrl = process.env.REACT_APP_API_URL + '/fb/get/form_names/' + that.props.ngo.ngo_id;
								window.formElementsUrl = process.env.REACT_APP_API_URL + '/fb/get/form_element/' + that.props.ngo.ngo_id + '/';
								window.formResponsesUrl = process.env.REACT_APP_API_URL + '/fb/get/question-response/' + that.props.ngo.ngo_id + '/';
								window.token = JSON.parse(readCookie('access_token')).access_token;
								that.setState({lastSavedJson: obj});
							} else {
								that.setState({lastSavedJson: obj});
							}
						});
					}
				}
			}
		}, 3000);
	}

	publishForm() {
		swal({
			title: 'Are you sure?',
			text: 'Are you sure you want to publish this form. you will not be able to revert this action.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonColor: '#EF1313',
			confirmButtonText: 'Yes, I am sure!',
			cancelButtonText: "No, cancel it!",
			closeOnConfirm: true,
			closeOnCancel: true
		}).then((isConfirm) => {
			fetch(process.env.REACT_APP_API_URL + '/fb/publish/form/' + this.props.ngo.ngo_id + '/' + this.state.formId, {
				method: 'PUT',
				headers: {
					'Authorization': JSON.parse(readCookie('access_token')).access_token
				}
			}).then(data => data.json())
			.then(data => {
				if(data.status === 'ok'){
					swal({
						title: 'Your form has been published.',
						type: 'success'
					}).then(()=>{window.location.pathname = '/fb/form/' + this.props.ngo.ngo_id + '/' + this.state.formId + '/preview'});
				} else if(data.status === 'error'){
					swal({
						title: `${data.message}`,
						type: 'error'
					})
				}
			});
		});
	}

  render() {
		return (
		  <div className="form-builder-container">
				<div id="sider-nav-holder">
					<div id="_form-details-settings" className="side-nav">
					  <div className="row _top-menu-holder">
							<div className="_top-menu-settings">
							  <div className="_top-menu-item _open-form-settings">Form</div>
							  <div className="_top-menu-item active _open-settings">Settings</div>
							  {/*<div className="_top-menu-item _open-designs">Designs</div>*/}
							</div>
					  </div>
					  <div className="row">
							<div className="col-md-12 m0">
								<label className="control-label" htmlFor="form_name">Form Name</label>
								<input id="form_name" type="text" className="validate form-control browser-default" />
							</div>
							<div className="col-md-12 m0">
								<label className="control-label" htmlFor="form_description">Form Description</label>
								<textarea id="form_description" className="form-control browser-default"></textarea>
							</div>
							<div className="col-md-6 m0">
								<label className="active control-label" htmlFor="valid_from">Valid From Date</label>
								<input type="date" id="valid_from" className="browser-default form-control" />
							</div>
							<div className="col-md-6 m0">
								<label className="active control-label" htmlFor="valid_to">Valid To Date</label>
								<input type="date" id="valid_to" className="browser-default form-control" />
							</div>
							<div className="col-md-6 m0">
								<label className="active control-label" htmlFor="valid_from">Valid From time</label>
								<input id="time_from" type="time" className="browser-default form-control"/>
							</div>
							<div className="col-md-6 m0">
								<label className="active control-label" htmlFor="valid_to">Valid To time</label>
								<input id="time_to" type="time" className="browser-default form-control" />
							</div>
							<div className="col-md-6 m0">
								<label className="control-label checkmark-label-container" htmlFor="offline_data">
									Offline Data
									<input className="browser-default" type="checkbox" id="offline_data" />
									<span className="checkmark"></span>
								</label>
							</div>
							<div className="col-md-6 m0">
								<label className="control-label checkmark-label-container" htmlFor="geolocation">
									Geolocation
									<input className="browser-default" type="checkbox" id="geolocation" />
									<span className="checkmark"></span>
								</label>
							</div>
							<div className="col-md-6 m0">
								<label className="control-label checkmark-label-container" htmlFor="deletable">
									Deletable
									<input className="browser-default" type="checkbox" id="deletable" />
									<span className="checkmark"></span>
								</label>
							</div>
							<div className="col-md-6 m0">
								<label className="control-label checkmark-label-container" htmlFor="resp-editable">
									Response Editable
									<input className="browser-default" type="checkbox" id="resp-editable" />
									<span className="checkmark"></span>
								</label>
							</div>
						</div>
						<div className="row">
						  <div className="col-md-12 mt10 mb10">
								<label htmlFor="submit-button-label" className="active is-imp submit-header-text control-label">Submit Button Text</label>
								<input type="text" className="validate browser-default form-control" id="submit-button-label" />
							</div>
						  <div className="col-md-12">
								<label htmlFor="confirmationOption" className="active is-imp submit-header-text control-label">Confirmation options</label>
							</div>
						  <div className="col-md-12">
								<input id="_submission-message" name="xyz" type="radio" value="message" />
								<label htmlFor="_submission-message" className="submit-radio-option control-label">Show confirmation message</label>
								<input type="text" className="validate browser-default form-control" id="submit-button-msg" />
							</div>
						  <div className="col-md-12">
							  <input id="_submission-url" name="xyz" type="radio" value="url" />
							  <label htmlFor="_submission-url" className="submit-radio-option control-label">Redirect URL</label>
							  <input type="text" className="validate browser-default form-control" id="submit-button-url" />
							  <div className="_open-url col-md-12" hidden={true}>
								  <input id="_open-url-same" name="openUrl" type="radio" value="same" />
								  <label htmlFor="_open-url-same" className="submit-radio-option control-label">Open URL in same tab</label>
								  <input id="_open-url-diff" name="openUrl" type="radio" value="different" />
								  <label htmlFor="_open-url-diff" className="submit-radio-option control-label">Open URL in different tab</label>
							  </div>
						  </div>
						</div>
						<div className="col-md-12 mt15 center-align">
							<a className="waves-effect waves-light btn mb20" id="_save-form-details-btn">Save</a>
						</div>
					</div>

					<div id="_add-elements-settings" className="side-nav">
					  <div className="row _top-menu-holder">
							<div className="_top-menu-settings">
							  <div className="_top-menu-item active _open-form-settings">Form</div>
							  <div className="_top-menu-item _open-settings">Settings</div>
							  {/*<div className="_top-menu-item _open-designs">Designs</div>*/}
							</div>
					  </div>
					  <div className="row">
							<div className="col-md-12"><p style={{fontSize: 24, fontWeight: 600, margin: '0px'}}>Input</p></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="textboxAddBtn">Textbox</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="textareaAddBtn">Textarea</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="nameAddBtn">Name</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="choiceAddBtn">Choice</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="addressAddBtn">Address</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="yesnoAddBtn">Yes/No</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="phoneAddBtn">Phone</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="dateAddBtn">Date</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="timeAddBtn">Time</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="emailAddBtn">Email</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="numberAddBtn">Number</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="websiteAddBtn">Website</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="currencyAddBtn">Currency</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="paymentAddBtn">Payment</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="coRelationAddBtn">Co-Relation</a></div>
							<div className="col-md-6"><a style={{padding: '0px'}} className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="distanceMapBtn">Distance on map</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="locationAddBtn">Location</a></div>
							{/*<div className="col-md-6"><a style={{padding: '0px'}} className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="areamapAddBtn">Area on Map</a></div>*/}
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="scaleAddBtn">Scale</a></div>
							{/*<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="signatureAddBtn">Signature</a></div>*/}
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="barcodeAddBtn">Barcode</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="ratingAddBtn">Rating</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="fileAddBtn">File Upload</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="pagebreakAddBtn">Page Break</a></div>
					  </div>
					  <div className="row">
							<div className="col-md-12"><p style={{fontSize: 24, fontWeight: 600, margin: '0px'}}>Layout</p></div>
							<div className="col-md-6"> <a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="headerAddBtn" >Header</a></div>
							<div className="col-md-6"> <a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="contentAddBtn" >Content</a></div>
					  </div>
					</div>

					{/*<div id="_elements-design-settings" className="side-nav">
						<div className="row _top-menu-holder">
							<div className="_top-menu-settings">
							  <div className="_top-menu-item _open-form-settings">Form</div>
							  <div className="_top-menu-item _open-settings">Settings</div>
							  <div className="_top-menu-item active _open-designs">Designs</div>
							</div>
					  </div>
						<div className="row">
							<div className="col-md-12"><p style={{fontSize: 24, fontWeight: 600}}>Designs</p></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="layoutAddBtn">Layout</a></div>
							{/*<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="textareaAddBtn">Positioning</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="backgroundAddBtn">Background</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="fontsAddBtn">Fonts</a></div>
							<div className="col-md-6"><a className="waves-effect waves-light btn _mtb5 _w100 _sidebarBtn" id="buttonsAddBtn">Buttons</a></div>
						</div>
					</div>*/}

					{/*<div id="_single-element-design-settings" className="side-nav">
						<div className="row _top-menu-holder">
							<div className="_top-menu-settings">
							<div className="back-to-form-settings"><i className="fa fa-chevron-left"></i></div>
							<div className="_top-menu-item element-settings _open-form-settings">Design Settings</div>
							</div>
					  </div>
					</div>*/}

					<div id="_single-element-settings" className="side-nav">
						<div className="row _top-menu-holder">
							<div className="_top-menu-settings">
							<div className="back-to-form-settings"><i className="fa fa-chevron-left"></i></div>
							<div className="_top-menu-item element-settings _open-form-settings">Element Settings</div>
							</div>
					  </div>
					</div>
				</div>
				<div className="_form-outlet">
					<div className="_form-holder">
						<div className="_mb10 _form-details" data-activates="_form-details-settings">
							<div className="row">
								<div className="col-md-12 _form-name">
									<p className="_form-name-element">Untitled</p>
								</div>
							</div>
						</div>
						<div className="_mb10 _form-designs" data-activates="_elements-design-settings"></div>
						<div className="_mb10 _header-container"></div>
						<div className="_mb10 _element-container" id="elementContainer"></div>
						<div className="_mb10">
							<div className="row">
								<div className="col-md-12 _form-element-add" data-activates="_add-elements-settings">
									<i className="material-icons">add_circle_outline</i>
								</div>
							</div>
						</div>
						{/*<div className="form-submit-btn-container" style={{display: 'flex'}}>
							<button className="form-submit-btn">Submit</button>
						</div>*/}
						<div className="_form-submit-settings">
							<p className="_form-submit-settings-atr"></p>
						</div>
					</div>
				</div>
				<footer className="page-footer _bottom-fixed-footer">
					<div className="footer-copyright">
						<div className="container">
							{this.state.formId === '' ? (
								<a className="grey-text text-lighten-4 btn waves-effect waves-light right not-clickable">
									Preview
								</a>
							) : (
								<a className="grey-text text-lighten-4 btn waves-effect waves-light right"
									id="_preview-modal-trigger-create" target="_blank" href={"/fb/form/" + this.props.ngo.ngo_id + "/" + this.state.formId + "/preview"}>
									Preview
								</a>
							)}
							{this.state.formId === '' ? (
								<a className="grey-text text-lighten-4 btn waves-effect waves-light right not-clickable">
									Publish
								</a>
							) : (
								<a className="grey-text text-lighten-4 btn waves-effect waves-light right"
									id="footer-saveBtn" onClick={this.publishForm.bind(this)}>
									Publish
								</a>
							)}
						</div>
					</div>
				</footer>
			</div>
		);
  }
}
