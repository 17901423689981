import React from 'react';
import _ from 'lodash';
import $ from 'jquery';
//import swal from 'sweetalert2';
import { Row, Col, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

const readCookie = require('../cookie.js').readCookie;
let uri = process.env.REACT_APP_MIS_API_URL + "/project/kpi";
export default class AddKPIForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      unit: null,
      KPI_dataType: null,
      kpiData: null,
      idToDelete: null,
      beneficiaries: [],
      beneficiariesData: {
        type: "",
        potential: 0,
        name: '',
        id: "",
      },
      kpiFormData: {
        type: "",
        targetValue: 0,
        baselineValue: 0,
      },
      custom: '',
      symbol: ""
    }
  }

  componentDidMount() {
    let url = process.env.REACT_APP_MIS_API_URL + "/project/key-assesment/" + this.props.project_id;
    fetch(url, {
      method: 'GET',
      headers: {
        'Auth': JSON.parse(readCookie('mis_data')).access_token
      }
    })
      .then(res => (res.json()))
      .then(res => {

        this.setState({ kpiData: res.data });
      })
      .catch(er => {
        console.log("err", er)
      })
    fetch(process.env.REACT_APP_MIS_API_URL + '/getOtAttributes', {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('mis_data')).access_token
      }
    }).then((data) => data.json())
      .then(function (data) {
        var goals = [], allAgendas = [], beneficiaries = [], locationTypes = [];
        for (var i = 0; i < data.otAttributes.length; i++) {
          if (data.otAttributes[i].type === 'beneficiary') beneficiaries.push(data.otAttributes[i])
          this.setState({ beneficiaries });
        }
      }.bind(this));
  }

  updateChange(name, e, index) {
    let kpiFormData = this.state.kpiFormData;
    if (name === "selectedBeneficiaryType") {
      let beneficiariesData = this.state.beneficiariesData;
      beneficiariesData['type'] = e.target.value;
      this.setState({ beneficiariesData });
    } else if (name === "selectedBeneficiary") {
      let beneficiariesData = this.state.beneficiariesData;
      beneficiariesData['id'] = e.target.value;
      beneficiariesData['name'] = $(e.target).find('option:selected').data('name');
      this.setState({ beneficiariesData });
    } else if (name === "selectedBeneficiaryPotential") {
      let beneficiariesData = this.state.beneficiariesData;
      beneficiariesData['potential'] = e.target.value;
      this.setState({ beneficiariesData });
    } else if (name === "selectedBeneficiaryName") {
      let beneficiariesData = this.state.beneficiariesData;
      beneficiariesData['name'] = e.target.value;
      this.setState({ beneficiariesData });
    } else if (name === "kpiType") {
      kpiFormData["type"] = e.target.value;
      this.setState({ kpiFormData });
    } else if (name === "targetValue") {
      kpiFormData["targetValue"] = e.target.value;
      this.setState({ kpiFormData });
    } else if (name === "baselineValue") {
      kpiFormData["baselineValue"] = e.target.value;
      this.setState({ kpiFormData });
    }
  }

  selectUnit(event) {
    this.setState({ symbol: event.target.value });
    let index = _.findIndex(this.props.getDataTypes, { symbol: event.target.value });
    this.setState({ KPI_dataType: this.props.getDataTypes[index].dataType });
  }

  cancelKPIForm() {
    // this.props.toggleKPIForm();
    window.location.pathname = "/im/projects/" + this.props.project_id + "/impact"
  }

  submitKPIForm() {
    let uri = "";
    {
      this.props.tableName !== "kpi" ?
        (uri = process.env.REACT_APP_MIS_API_URL + "/project/" + this.props.project_id + "/unbudgeted-beneficiary") :
        (uri = process.env.REACT_APP_MIS_API_URL + "/project/" + this.props.project_id + "/kpi")
    }
    let formData = {}
    if (this.props.tableName === "kpi") {
      if (this.state.symbol !== "" && this.KPI_name.value !== "" && this.state.KPI_dataType !== null) {
        formData = {
          dataType: this.state.KPI_dataType,
          name: this.KPI_name.value,
          unit: this.state.symbol,
          _projectId: this.props.project_id,
          isDeleted: false,
          type: this.state.kpiFormData.type,
          targetValue: this.state.kpiFormData.targetValue,
          baselineValue: this.state.kpiFormData.baselineValue
        }
        fetch(uri, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Auth': JSON.parse(readCookie('mis_data')).access_token
          },
          body: JSON.stringify(formData),
        })
        .then(res => res.json())
        .then(res => {
          if (res.status === "ok") {
            toast.success('You have successfully added a new KPI');
            window.location.pathname = '/im/projects/' + this.props.project_id + '/impact';
          }
          if (res.status === "error") {
            toast.error(res.message)
          }
          this.setState({
            unit: null,
            KPI_dataType: null
          })
        })
        .catch((err) => {
          this.notifyError(err);
        })
      } else {
        toast.error("Mandatory Values are Missing");
      }
    } else {
      formData = {
        type: this.state.beneficiariesData.type,
        potential: this.state.beneficiariesData.potential,
        name: this.state.beneficiariesData.name,
        id: this.state.beneficiariesData.id,
      }
      fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Auth': JSON.parse(readCookie('mis_data')).access_token
        },
        body: JSON.stringify(formData),
      })
      .then(res => res.json())
      .then(res => {
        if (res.status === "ok") {
          toast.success('You have successfully added a new KPI');
          window.location.pathname = '/im/projects/' + this.props.project_id + '/impact';
        }
        if (res.status === "error") {
          toast.error(res.message)
        }
        this.setState({
          unit: null,
          KPI_dataType: null
        })
      })
      .catch((err) => {
        this.notifyError(err);
      })
    }
  }

  notifySuccess = (text) => toast.success(text);
  notifyError = (text) => toast.error(text);

  render() {
    return (
      <div>
        <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />

        {
          (this.props.visibility)
            ?
            <Row style={{ background: '#f3f3f3', boxShadow: '0px 0px 2px #999', margin: '5px 5px 25px 5px !important', padding: '30px 10px 30px 10px !important' }}>
              {this.props.tableName === "kpi" ?
                (<Col md={12} >
                  <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
                  <Col className="form-group" md={6}>
                    <label htmlFor="lead-assessor" className="control-label is-imp">Unit</label>
                    <select name="select" id="sample-size" id="input-box-form" className="form-control" onChange={this.selectUnit.bind(this)}>
                      <option>  Select KPI Unit</option>
                      {_.map(this.props.getDataTypes, (el, i) => (
                        <option key={i} value={el.symbol}>{el.unit}</option>))
                      }
                    </select>
                  </Col>
                  <Col className="form-group" md={6}>
                    <label htmlFor="team-member" className="control-label is-imp">Name</label>
                    <input type="text" id="name" placeholder="Enter Name" id="input-box-form" className="form-control"
                      ref={(e) => this.KPI_name = e}
                    />
                  </Col>
                  <Col className="form-group" md={6}>
                    <label className='kpi-label control-label is-imp'>Type:</label>
                    <select className='form-control' style={{ width: '100%' }} onChange={this.updateChange.bind(this, "kpiType")}>
                      <option disabled selected value="">Select Type</option>
                      <option value='Output'>Output</option>
                      <option value='Outcome'>Outcome</option>
                    </select>
                  </Col>
                  <Col className="form-group" md={6}>
                    <label htmlFor="team-member" className="control-label">Baseline Value</label>
                    <input type="number" id="name" placeholder="Enter Baseline value" id="input-box-form" className="form-control"
                      onChange={this.updateChange.bind(this, 'baselineValue')}
                    />
                  </Col>
                  <Col className="form-group" md={6}>
                    <label htmlFor="team-member" className="control-label">Target Value</label>
                    <input type="number" id="name" placeholder="Enter Target Value" id="input-box-form" className="form-control"
                      onChange={this.updateChange.bind(this, 'targetValue')}
                    />
                  </Col>
                </Col>) : null}
              {this.props.tableName !== "kpi" ?
                (<Col md={12} style={{ marginBottom: "15px" }}>
                  <Col className="form-group" md={4}>
                    <label htmlFor="lead-assessor" className="control-label is-imp">Beneficiary Type</label>
                    <select name="select" id="sample-size" id="input-box-form" className="form-control" onChange={this.updateChange.bind(this, 'selectedBeneficiaryType')}>
                      <option>Select Beneficiary Type</option>
                      <option value="direct">Direct</option>
                      <option value="indirect">Indirect</option>
                    </select>
                  </Col>

                  <Col className="form-group" md={4}>
                    <label htmlFor="team-member" className="control-label is-imp">Beneficiary Name</label>
                    <select className="form-control"
                      onChange={this.updateChange.bind(this, 'selectedBeneficiary')}>
                      <option selected disabled value=''>Select Beneficiary</option>
                      {this.state.beneficiaries.map(function (beneficiary, index) {
                        return (
                          <option value={beneficiary._id} data-name={beneficiary.name} key={index}>{beneficiary.name}</option>
                        )
                      })}
                      <option value="0" data-key="Custom">Custom</option>
                    </select>
                    {this.state.beneficiariesData.id === "0" ? (
                      <div>
                        <label className="control-label">Enter Beneficiary Name:</label>
                        <input className='form-control' type="text" placeholder="Enter Beneficiary Name" value={this.state.beneficiariesData.name} onChange={this.updateChange.bind(this, 'selectedBeneficiaryName')} />
                      </div>
                    ) : (null)}
                  </Col>
                  <Col className="form-group" md={4}>
                    <label htmlFor="team-member" className="control-label">Potential</label>
                    <input type="text" id="name" placeholder="Enter Value" id="input-box-form" className="form-control" onChange={this.updateChange.bind(this, 'selectedBeneficiaryPotential')}
                      ref={(e) => this.KPI_name = e}
                    />
                  </Col>
                </Col>) : null}
              <Col className="mt10 mb10 form-buttons" md={12}>
                <button type="button" className="btn btn-default btn-danger pull-right" onClick={this.cancelKPIForm.bind(this)}>
                  Cancel
                </button>
                {this.props.tableName === "kpi" ?
                  (<button type="button" style={{ marginRight: '10px' }} className="btn btn-default  pull-right" onClick={this.submitKPIForm.bind(this)}>
                    Add
                  </button>) :
                  (<button type="button" style={{ marginRight: '10px' }} className="btn btn-default  pull-right" onClick={this.submitKPIForm.bind(this)}>
                    Submit Beneficiary Data
                  </button>)}
              </Col>
            </Row>

            :
            null
        }

      </div>
    )

  }
}