import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import IMPartnershipsSidebar from './IMPartnershipsSidebar';

const readCookie = require('../cookie.js').readCookie;

export default class IMSendProjectInvitations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			corporates: [],
			oldInvitaions: []
		}
	}

	componentDidMount() {
		this.refreshData(this);
	}

	refreshData() {
		fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/project-invitations-received', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				if (data.status === 'ok') {
					this.setState({ corporates: data.data.newInvitations, oldInvitaions: data.data.oldInvitations });
				}
			}.bind(this));
	}

	action(status, projectId) {
		let data = {};
		data['status'] = status;
		fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/project-invitations-received/' + projectId, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			},
			body: JSON.stringify(data)
		}).then((response) => response.json())
			.then(function (data) {
				if (data['status'] === 'ok') {
					let invitationStatus = 'approved';
					if (status === 'reject') invitationStatus = 'rejected';
					this.notifySuccess('This Invitation has been ' + invitationStatus + ' successfully.');
					this.refreshData(this);
				}
			}.bind(this));
	}

	notifySuccess = (text) => toast.success(text);

	render() {
		return (
			<div className="im-container im-partnerships-page">
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<IMPartnershipsSidebar selected="sendProjectInvitations" />
				<div className="im-partnerships-container im-send-project-invitations-container">
					<h3>Partnership Invitations</h3>
					<div className="corporate-invitations-to-send-projects">
						{this.state.corporates.length === 0 ? (
							<h3 className="no-data">There are no Invitations sent by any Corporate.</h3>
						) : (null)}
						{this.state.corporates.map(function (corporate, index) {
							return (
								<div className="corporate-invitation-container" key={index}>
									<div className="corporate-invitation-details">
										<span className="corporate-title">{corporate.name}</span>
										<p className="corporate-note">{corporate.note}</p>
									</div>
									<div className="corporate-invitation-action-container">
										<button className="btn btn-accept" onClick={this.action.bind(this, 'accept', corporate.id)}>
											<i className="fa fa-check"></i>
											Accept
										</button>
										<button className="btn btn-ignore" onClick={this.action.bind(this, 'ignore', corporate.id)}>
											<i className="fa fa-ban"></i>
											Ignore
										</button>
									</div>
								</div>
							)
						}.bind(this))}
						<div className="corporate-container">
							<h2 style={{ textAlign: "center", marginTop: "20px", marginBottom: "30px" }}>Actions Taken</h2>
							<div className="corporate-name-container" style={{ display: "flex" }}>
								<span className="corporate-name-header">Corporate Name</span>
								<span className="corporate-name-header">Note</span>
								<span className="corporate-name-header">Status</span>
							</div>
							{this.state.oldInvitaions.map(function (invitation, index) {
								return (
									<div className="corporate-name-container" style={{ display: "flex" }} title={invitation.name}>
										<span className="corporate-name">{invitation.name}</span>
										<span className="corporate-name">{invitation.note}</span>
										<span className="corporate-name">{invitation.status}</span>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}
}