import React, {Component} from 'react';
import { Button, Row, Col } from "react-bootstrap";
import TimePicker from 'rc-time-picker';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import $ from 'jquery';
import InputRange from 'react-input-range';
import Multiselect from 'react-bootstrap-multiselect';
import EmailEditor from 'react-email-editor';
import TimezonePicker from 'react-timezone';
import { ToastContainer, toast } from 'react-toastify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Icon from 'react-icons-kit';
import { ic_visibility } from 'react-icons-kit/md/ic_visibility';

import 'react-select/dist/react-select.css';
import 'react-tabs/style/react-tabs.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-time-picker/assets/index.css';
import 'react-input-range/lib/css/index.css';
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';

import SingleCampaignPreview from './SingleCampaignPreview';
// import AddTestEmailsModal from './AddTestEmailsModal';

var momentTimezone = require('moment-timezone');

const readCookie = require('../cookie.js').readCookie;

const format = 'hh:mm A';

const previousLocation = '';

let editors = [];

export default class AddABTestingCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      to: null,
      groups : [],
      donors : [],
      bodys: [''],
      toValue: '',
      reply_to: '',
      from_name: '',
      templates: [],
      time: moment(),
      first_name: '',
      bodyJSONs: [{}],
      ngo_domains: [],
      name: 'Untitled',
      selectedTags: [],
      suggestions : [],
      campaign_id: null,
      disableSend: true,
      campaignFiles: [],
      subjects: ['', ''],
      showToError: false,
      replyToCheck: true,
      disableNext: false,
      donorCategories: [],
      startDate: moment(),
      showButtonLoader: '',
      scheduleCheck: false,
      showBodyError: false,
      from_email: 'noreply',
      selectedContentTab: 0,
      contentToPreview: null,
      useTemplateCheck: false,
      unsubscribeStatus: false,
      showFromNameError: false,
      selectedDonorCategory: '',
      showFromEmailError: false,
      campaignSelectedDomain: '',
      socialMediaIconStatus: false,
      showCampaignNameError: false,
      showSubjectError: [false, false],
      timezone: momentTimezone.tz.guess(),
      from_email_domain: 'letsendorse.com',
      selectedTemplateId: this.props.selectedTemplateId,
      test_settings: {
        ab_test_type: 'subject',
        versions_count: 2,
        sample_size: 45,
        duration: 1800
      }
    }
    this.onChange = this.onChange.bind(this);
    this.addCampaign = this.addCampaign.bind(this);
    this.domainChange = this.domainChange.bind(this);
    this.saveCampaign = this.saveCampaign.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.onTemplateChange = this.onTemplateChange.bind(this);
    this.getTemplateOptions = this.getTemplateOptions.bind(this);
    this.handleReplyToChange = this.handleReplyToChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.removeCampaignFiles = this.removeCampaignFiles.bind(this);
    this.handleScheduleChange = this.handleScheduleChange.bind(this);
    this.handleFromNameChange = this.handleFromNameChange.bind(this);
    this.handleFromEmailChange = this.handleFromEmailChange.bind(this);
    this.onCampaignFilesChange = this.onCampaignFilesChange.bind(this);
    this.handleUseTemplateChange = this.handleUseTemplateChange.bind(this);
    this.handleReplyToCheckChange = this.handleReplyToCheckChange.bind(this);
    this.checkIfSendNeedsDisabling = this.checkIfSendNeedsDisabling.bind(this);
  }

  handleScheduleChange() {
    this.setState({ scheduleCheck: !this.state.scheduleCheck });
  }

  handleReplyToCheckChange() {
    this.setState({ replyToCheck: !this.state.replyToCheck });
  }

  handleTagChange = (e) =>{
    this.setState({ selectedTags: $('select#tagsTo').val() });
  }

  handleReplyToChange(e) {
    if(e.target.value === '') this.setState({ showReplyToError: true }, function() { this.checkIfSendNeedsDisabling() });
    else if(!this.validateEmail(e.target.value)) this.setState({ showReplyToError: true }, function() { this.checkIfSendNeedsDisabling() });
    else this.setState({ showReplyToError: false }, function() { this.checkIfSendNeedsDisabling() });
    this.setState({ reply_to: e.target.value });
  }


  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handleUseTemplateChange() {
    this.setState({ useTemplateCheck: !this.state.useTemplateCheck }, function() {
      if(!this.state.useTemplateCheck) this.setState({ selectedTemplateId: null, body: '' });
    });
  }

  checkIfSendNeedsDisabling() {
    if(this.state.showFromEmailError || this.state.showFromNameError || this.state.showSubjectError || this.state.showCampaignNameError || this.state.showBodyError || this.state.showToError || this.state.showReplyToError) {
      this.setState({ disableSend: true });
    } else {
      this.setState({ disableSend: false });
    }
  }

  handleFromEmailChange(e) {
    if(e.target.value === '') this.setState({ showFromEmailError: true }, function() { this.checkIfSendNeedsDisabling() });
    else this.setState({ showFromEmailError: false }, function() { this.checkIfSendNeedsDisabling() });
    this.setState({ from_email: e.target.value });
  }

  handleFromNameChange(e) {
    if(e.target.value === '') this.setState({ showFromNameError: true }, function() { this.checkIfSendNeedsDisabling() });
    else this.setState({ showFromNameError: false }, function() { this.checkIfSendNeedsDisabling() });
    this.setState({ from_name: e.target.value });
  }

  handleSubjectChange(index, e) {
    let subjects = this.state.subjects;
    subjects[index] = e.target.value;
    if(e.target.value === '') this.setState({ showSubjectError: true }, function() { this.checkIfSendNeedsDisabling() });
    else this.setState({ showSubjectError: false }, function() { this.checkIfSendNeedsDisabling() });
    this.setState({ subjects });
  }

  handleNameChange(e) {
    let value = e.target.value;
    if(value === '') this.setState({ showCampaignNameError: true }, function() { this.checkIfSendNeedsDisabling() });
    else this.setState({ showCampaignNameError: false }, function() { this.checkIfSendNeedsDisabling() });
    this.setState({ name: value });
  }

  domainChange(e) {
    var domain = e.target.value;
    fetch(process.env.REACT_APP_API_URL + '/extract-domain', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify({ domain })
    }).then((response) => response.json())
    .then((data) => {
      this.setState({ campaignSelectedDomain: domain, from_email_domain: data.domain });
    });
  }

  handleDateChange(date) {
    this.setState({ startDate: date });
  }

  handleTimeChange(value) {
    this.setState({ time: value });
  }

  onChange = (options) => {
    this.setState({ to: options.map(x => x.value) }, function() {
      if(this.state.to.length === 0) this.setState({ showToError: true });
      else this.setState({ showToError: false });
    });
  }

  onTemplateChange(options) {
    this.setState({ selectedTemplateId: options.value }, function() {
      fetch(process.env.REACT_APP_API_URL + '/templates/' + this.props.ngo_id + '/' + this.state.selectedTemplateId, {
        method: "GET",
        headers: {
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        }
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        this.setState({ body: data['template']['body'], bodyJSON: data['template']['bodyJSON'] });
      }.bind(this));
    });
  }

  onCampaignFilesChange(e) {
    let files = e.target.files;
    this.setState({ campaignFiles: files });
  }

  removeCampaignFiles() {
    this.setState({ campaignFiles: [] });
    $('input#addCampaignModalAttachments').val('')
  }

  sendTestMail(html) {
    this.setState({ showTestEmailModal: true });
  }

  saveCampaign(type) {
    if(type === 'save') this.setState({ showButtonLoader: 'savePage2' });
    else {
      if(this.state.scheduleCheck) this.setState({ showButtonLoader: 'scheduleMail' });
      else this.setState({ showButtonLoader: 'sendMail' });
    }

    editors[this.state.selectedContentTab].exportHtml(editorData => {
      const { design, html } = editorData;
      let saveHTML = html;
      let name = this.state.name;
      let bodys = this.state.bodys;
      let bodyJSONs = this.state.bodyJSONs;
      let subjects = this.state.subjects;
      let from_name = this.state.from_name;
      let from_email = this.state.from_email;
      let sender_domain = this.state.campaignSelectedDomain;
      let replyToCheck = this.state.replyToCheck;
      let reply_to = this.state.reply_to;

      let target_donors = this.state.toValue;
      let target_groups = [];

      bodys[this.state.selectedContentTab] = saveHTML;
      bodyJSONs[this.state.selectedContentTab] = design;

      let socialMediaIconsHTML = '';
      if(this.state.socialMediaIconStatus) {
        socialMediaIconsHTML += `<div style="display: block; text-align: center; margin: 15px 0;">`;
        if(this.props.ngo.ngo_website_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_website_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/website.png" height="36" width="36" />
                                   </a>`;
        }
        if(this.props.ngo.ngo_blog_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_blog_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/blog.png" height="36" width="36" />
                                   </a>`;
        }
        if(this.props.ngo.ngo_fb_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_fb_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/facebook.png" height="36" width="36" />
                                   </a>`;
        }
        if(this.props.ngo.ngo_twitter_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_twitter_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/twitter.png" height="36" width="36" />
                                   </a>`;
        }
        if(this.props.ngo.ngo_linkedin_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_linkedin_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/linkedin.png" height="36" width="36" />
                                   </a>`;
        }
        if(this.props.ngo.ngo_youtube_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_youtube_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/youtube.png" height="36" width="36" />
                                   </a>`;
        }
        if(this.props.ngo.ngo_instagram_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_instagram_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/instagram.png" height="36" width="36" />
                                   </a>`;
        }
        socialMediaIconsHTML += `</div>`;
        for(var i = 0; i < bodys.length; i++) {
          bodys[i] = bodys[i].replace('</body>', socialMediaIconsHTML + '</body>');
        }
      }

      let unsubscribeHTML = '<div style="text-align: center;">If you would prefer not receiving our emails, please <a href="{{unsubscribe_url}}">click here</a> to unsubscribe.</div>';
      if(this.state.unsubscribeStatus) {
        for(var i = 0; i < bodys.length; i++) {
          bodys[i] = bodys[i].replace('</body>', unsubscribeHTML + '</body>');
        }
      }

      let data = {};
      data['name'] = name;
      data['subjects'] = subjects;
      data['content_html'] = bodys;
      data['content_json'] = bodyJSONs;
      data['campaign_tags'] = this.state.selectedTags;
      data['add_unsubscribe'] = this.state.unsubscribeStatus;
      data['add_social_media_icon'] = this.state.socialMediaIconStatus;

      var campaignFiles = $('input#addCampaignModalAttachments');

      if(campaignFiles[0].files.length > 0) {
        data['is_awaiting_attachment'] = true;
      }

      data['from_name'] = from_name;
      data['from_email'] = from_email;
      data['sender_domain'] = sender_domain;
      data['target_groups'] = target_groups;
      data['target_donors'] = target_donors;
      data['test_based_on'] = this.state.test_settings.ab_test_type;
      data['versions_count'] = this.state.test_settings.versions_count;
      data['sample_size'] = this.state.test_settings.sample_size;
      data['duration'] = this.state.test_settings.duration;

      if(this.state.selectedDonorCategory !== '') data['list_id'] = this.state.selectedDonorCategory;
      if(type === 'send') data['send_now'] = true;
      else data['send_now'] = false;
      if(!replyToCheck) data['reply_to'] = reply_to;

      if(this.state.scheduleCheck) {
        var x = moment(this.state.startDate).format('YYYY-MM-DD');
        x = x + ' ' + this.state.time.format('HH:mm:ss');
        data['scheduled_datetime'] = moment(x).toISOString();
        data['is_scheduled'] = true;
      }

      var account_type_ngo = false;
      if(this.props.ngo.hasOwnProperty('account_type')) {
        if(this.props.ngo.account_type === 'ngo') account_type_ngo = true;
      }

      if((name !== '' && subjects.length && from_name !== '' && from_email !== '' && (this.state.selectedDonorCategory !== '' || (target_donors.length || target_groups.length || !account_type_ngo)) && (replyToCheck || (!replyToCheck && reply_to !== ''))) || (type === 'save')) {
        fetch(process.env.REACT_APP_API_URL + '/ab-testing/campaigns/' + this.props.ngo_id + '/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify({ campaign_data: data })
        }).then((response) => response.json())
        .then((data) => {
          let campaign_id = data['campaign_id'];
          this.setState({ campaign_id });
          if(campaignFiles[0].files.length > 0) {
            for(var i = 0; i < campaignFiles[0].files.length; i++) {

              var files = new FormData();
              files.append('attachment', campaignFiles[0].files[i]);

              fetch(process.env.REACT_APP_API_URL + '/ab-testing/campaigns/' + this.props.ngo_id + '/add_attachment/' + campaign_id, {
                method: 'POST',
                headers: {
                  'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
                },
                body: files
              }).then((response) => response.json())
              .then(function(data) {
                if(type === 'save') {
                  this.notifySuccess('Your Campaign has been saved successfully.');
                  this.setState({ showButtonLoader: '' });
                  window.location.pathname = '/dm/campaigns/ab-testing/' + campaign_id;
                } else if (type === 'send') {
                  fetch(process.env.REACT_APP_API_URL + '/cron_wars/ab-testing/campaigns/run_now?id=' + campaign_id, {
                    method: "GET",
                    headers: {
                      'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
                    }
                  }).then(function(response) {
                    return response.json();
                  }).then(function(data) {
                    if(this.state.scheduleCheck) this.notifySuccess('Your Campaign has been scheduled successfully.');
                    else this.notifySuccess('Your Campaign has been sent successfully.');
                    this.setState({ showButtonLoader: '' });
                    this.onAddCampaignClose();
                  }.bind(this));
                }
              }.bind(this));
            }
          } else {
            if(type === 'save') {
              this.notifySuccess('Your Campaign has been saved successfully.');
              this.setState({ showButtonLoader: '' });
              window.location.pathname = '/dm/campaigns/ab-testing/' + campaign_id;
            } else if (type === 'send') {
              fetch(process.env.REACT_APP_API_URL + '/cron_wars/ab-testing/campaigns/run_now?id=' + campaign_id, {
                method: "GET",
                headers: {
                  'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
                }
              }).then(function(response) {
                return response.json();
              }).then(function(data) {
                if(this.state.scheduleCheck) this.notifySuccess('Your Campaign has been scheduled successfully.');
                else this.notifySuccess('Your Campaign has been sent successfully.');
                this.setState({ showButtonLoader: '' });
                this.onAddCampaignClose();
              }.bind(this));
            }
          }
        });
      } else {
        if(name === '') this.setState({ showCampaignNameError: true });
        if(saveHTML === '') this.setState({ showBodyError: true });
        if(from_name === '') this.setState({ showFromNameError: true });
        if(from_email === '') this.setState({ showFromEmailError: true });
        if(this.state.selectedDonorCategory !== '' || (target_donors.length === 0 && target_groups.length === 0)) this.setState({ showToError: true });
        this.setState({ showButtonLoader: '' });
      }
    });
  }

  disableSend() {
    var account_type_ngo = false;
    if(this.props.ngo.hasOwnProperty('account_type')) {
      if(this.props.ngo.account_type === 'ngo') account_type_ngo = true;
    }
    if(this.state.name !== '' && this.state.subject !== '' && this.state.from_name !== '' && this.state.from_email !== '' && (this.state.selectedDonorCategory !== '' || (this.state.toValue.length || !account_type_ngo)) && (this.state.replyToCheck || (!this.state.replyToCheck && this.state.reply_to !== ''))) {
      return false;
    } else return true;
  }

  disableSendTest() {
    if(this.state.name !== '' && this.state.body !== '' && this.state.subject !== '' && this.state.from_name !== '' && this.state.from_email !== '') {
      return false;
    } else return true;
  }

  notifySuccess = (text) => toast.success(text);

  addCampaign(type) {
    if(type === 'save') this.setState({ showButtonLoader: 'savePage2' });
    else {
      if(this.state.scheduleCheck) this.setState({ showButtonLoader: 'scheduleMail' });
      else this.setState({ showButtonLoader: 'sendMail' });
    }

    let name = this.state.name;
    let subject = this.state.subject;
    let from_name = this.state.from_name;
    let from_email = this.state.from_email;
    let sender_domain = this.state.campaignSelectedDomain;
    let replyToCheck = this.state.replyToCheck;
    let reply_to = this.state.reply_to;

    let campaign_id = this.state.campaign_id;

    let target_donors = this.state.toValue;
    let target_groups = [];

    let data = {};
    data['subject'] = subject;
    data['from_name'] = from_name;
    data['from_email'] = from_email;
    data['sender_domain'] = sender_domain;
    data['target_groups'] = target_groups;
    data['target_donors'] = target_donors;
    if(this.state.selectedDonorCategory !== '') data['list_id'] = this.state.selectedDonorCategory;
    if(type === 'send') data['send_now'] = true;
    else data['send_now'] = false;
    if(!replyToCheck) data['reply_to'] = reply_to;

    if(this.state.scheduleCheck) {
      var x = moment(this.state.startDate).format('YYYY-MM-DD');
      x = x + ' ' + this.state.time.format('HH:mm:ss');
      data['scheduled_datetime'] = moment(x).toISOString();
      data['is_scheduled'] = true;
    }

    var account_type_ngo = false;
    if(this.props.ngo.hasOwnProperty('account_type')) {
      if(this.props.ngo.account_type === 'ngo') account_type_ngo = true;
    }

    if((name !== '' && subject !== '' && from_name !== '' && from_email !== '' && (this.state.selectedDonorCategory !== '' || (target_donors.length || target_groups.length || !account_type_ngo)) && (replyToCheck || (!replyToCheck && reply_to !== ''))) || (type === 'save')) {
      fetch(process.env.REACT_APP_API_URL + '/ab-testing/campaigns/' + this.props.ngo.ngo_id + '/' + campaign_id + '/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify({ data_for_update: data })
      }).then((response) => response.json())
      .then((data) => {
        if(type === 'save') {
          this.notifySuccess('Your Campaign has been saved successfully.');
          this.setState({ showButtonLoader: '' });
        } else if (type === 'send') {
          fetch(process.env.REACT_APP_API_URL + '/cron_wars/ab-testing/campaigns/run_now?id=' + campaign_id, {
            method: "GET",
            headers: {
              'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
            }
          }).then(function(response) {
            return response.json();
          }).then(function(data) {
            if(this.state.scheduleCheck) this.notifySuccess('Your Campaign has been scheduled successfully.');
            else this.notifySuccess('Your Campaign has been sent successfully.');
            this.setState({ showButtonLoader: '' });
            this.onAddCampaignClose();
          }.bind(this));
        }
      });
    } else {
      if(name === '') this.setState({ showCampaignNameError: true });
      if(subject === '') this.setState({ showSubjectError: true });
      if(from_name === '') this.setState({ showFromNameError: true });
      if(from_email === '') this.setState({ showFromEmailError: true });
      if(this.state.selectedDonorCategory !== '' || (target_donors.length === 0 && target_groups.length === 0)) this.setState({ showToError: true });
      this.setState({ showButtonLoader: '' });
    }
  }

  onAddCampaignClose() {
    setTimeout(function(){ window.location.pathname = '/dm/campaigns/ab-testing' }, 500);
  }

  getTemplateOptions() {
    return fetch(process.env.REACT_APP_API_URL + '/templates/'+ this.props.ngo_id, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      var options = [];
      for(var i = 0; i < data['templates'].length; i++) {
        var option = {};
        option['value'] = data['templates'][i]['id'];
        option['label'] = data['templates'][i]['name'];
        options.push(option);
      }
      return {options};
    });
  }

  componentWillMount() {
    $(document).ready(function() {
      $('.sidebar').addClass('ab-testing');
      $('.top-menu').addClass('ab-testing');

      $('.sidebar').addClass('closed');

      $('.sidebar.closed .open-close-toggle').click(function() {
        if($('.sidebar').hasClass('closed')) $('.sidebar').removeClass('closed');
        else $('.sidebar').addClass('closed');
      });
    });
    let first_name = JSON.parse(readCookie('userData')).first_name;

    let ngo_domains = this.props.ngo.domains;
    // for(var i = 0; i < ngo_domains.length; i++) {
    //   var ngo_domain = ngo_domains[i];
    //   var split = ngo_domain.split('.');
    //   ngo_domains[i] = split[split.length - 2] + '.' + split[split.length - 1];
    // }

    fetch(process.env.REACT_APP_API_URL + '/extract-domain', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify({ domain: ngo_domains[0] })
    }).then((response) => response.json())
    .then((data) => {
      this.setState({ campaignSelectedDomain: ngo_domains[0], from_email_domain: data.domain });
    });

    this.setState({ first_name, ngo_domains });

    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/lists', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ donorCategories: data.lists });
    }.bind(this));

    let group_id;
    let toGroupValue = [];
    // if(previousLocation !== undefined && previousLocation !== null && previousLocation.search('/donors/') > -1) donor_id = previousLocation.split('/donors/')[1];
    if(previousLocation !== undefined && previousLocation !== null && previousLocation.search('/segments/') > -1) group_id = previousLocation.split('/segments/')[1];
    fetch(process.env.REACT_APP_API_URL + '/groups_users/' + this.props.ngo_id, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      let groups_donors = data.groups_donors;
      this.setState({ groups_donors });
      let groups = [];
      for(var i = 0; i < groups_donors.length; i++) {
        let group_obj = {};
        group_obj['value'] = groups_donors[i].group_id;
        group_obj['label'] = groups_donors[i].group_name + ' (' + groups_donors[i].members_count + ')';
        // let group_members = [];
        // for(var j = 0; j < groups_donors[i].group_members.length; j++) {
          // let group_member_obj = {};
          // group_member_obj['value'] = groups_donors[i].group_members[j].id;
          // group_member_obj['label'] = groups_donors[i].group_members[j].name + ' (' + groups_donors[i].group_members[j].email + ')';
          // if(donor_id !== undefined && groups_donors[i].group_members[j].id === donor_id && groups_donors[i].group_name === 'All Donors') {
            // group_member_obj['selected'] = true;
            // let toValue = [];
            // toValue.push(donor_id);
            // this.setState({ toValue, showToError: false });
          // }
          if(group_id !== undefined && groups_donors[i].group_id === group_id) {
            toGroupValue.push(group_id);
            // group_member_obj['selected'] = true;
          }
          // group_members.push(group_member_obj);
        // }
        // group_obj['children'] = group_members;
        if(group_id !== undefined && groups_donors[i].group_id === group_id) {
          this.setState({ toValue: toGroupValue, showToError: false });
        }
        // if(group_members.length) groups.push(group_obj);
        groups.push(group_obj);
      }
      this.setState({ to: groups });
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/ngo/'+ this.props.ngo_id + '/donors', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({donors: data['donors']});
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/view_groups/'+ this.props.ngo_id, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({groups: data['groups']});
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/templates/'+ this.props.ngo_id, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ templates: data['templates'] });
      if(this.props.selectedTemplateId !== null) {
        this.setState({
          selectedTemplateId: this.props.selectedTemplateId,
          useTemplateCheck: true
        });
        fetch(process.env.REACT_APP_API_URL + '/templates/' + this.props.ngo_id + '/' + this.props.selectedTemplateId, {
          method: "GET",
          headers: {
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          }
        }).then(function(response) {
          return response.json();
        }).then(function(data) {
          this.setState({ body: data['template']['body'], bodyJSON: data['template']['bodyJSON'] });
        }.bind(this));
      }
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/all_campaign_tags/unsubscriber/' + this.props.ngo.ngo_id, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(res => res.json())
    .then(data => {
      console.log(data)
      if(data.status === 'ok'){
        let dataObj = data.data;
        if(dataObj.length) {
          let tags = [];
          for(var i = 0; i < dataObj.length; i++){
            let tagsObj = {};
            tagsObj['value'] = dataObj[i].campaign_tag_id;
            tagsObj['label'] = dataObj[i].campaign_tag_name;
            tags.push(tagsObj);
          }
          this.setState({tags})
        }
      }
    });
  }

  handleChange(name, value) {
    var account_type_ngo = false;
    if(this.props.ngo.hasOwnProperty('account_type')) {
      if(this.props.ngo.account_type === 'ngo') account_type_ngo = true;
    }
    this.setState({ [name]: $('select#campaignTo').val() }, function() {
      if(this.state.toValue.length === 0 && account_type_ngo) this.setState({ showToError: true }, function() { this.checkIfSendNeedsDisabling() });
      else this.setState({ showToError: false }, function() { this.checkIfSendNeedsDisabling() });
    });
  }

  onLoad(index) {
    if (editors !== undefined && editors.length && editors[index] !== null && editors[index] !== undefined) {
      editors[index].loadDesign(this.state.bodyJSONs[index]);
    } else {
      setTimeout (() => editors[index].loadDesign(this.state.bodyJSONs[index]), 3000);
    }
  }

  handleTimezoneChange(timezone) {
    this.setState({ time: momentTimezone.tz(this.state.time, timezone).utc(), timezone });
  }

  onDonorCategoriesChange(e) {
    this.setState({ selectedDonorCategory: e.target.value });

    let query = '';
    if(e.target.value !== '') query = '?donor_category=' + e.target.value;

    fetch(process.env.REACT_APP_API_URL + '/groups_users/' + this.props.ngo_id + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      let groups_donors = data.groups_donors;
      this.setState({ groups_donors });
      let groups = [];
      for(var i = 0; i < groups_donors.length; i++) {
        let group_obj = {};
        group_obj['value'] = groups_donors[i].group_id;
        group_obj['label'] = groups_donors[i].group_name + ' (' + groups_donors[i].members_count + ')';
        // let group_members = [];
        // for(var j = 0; j < groups_donors[i].group_members.length; j++) {
        //   let group_member_obj = {};
        //   group_member_obj['value'] = groups_donors[i].group_members[j].id;
        //   group_member_obj['label'] = groups_donors[i].group_members[j].name + ' (' + groups_donors[i].group_members[j].email + ')';
        //   group_members.push(group_member_obj);
        // }
        // group_obj['children'] = group_members;
        // if(group_members.length)
        groups.push(group_obj);
      }
      this.setState({ to: groups });
    }.bind(this));
  }

  changeUnsubscribeStatus() {
    this.setState({ unsubscribeStatus: !this.state.unsubscribeStatus });
  }

  changeSocialMediaIconStatus() {
    this.setState({ socialMediaIconStatus: !this.state.socialMediaIconStatus });
  }

  showCampaignPreview() {
    if(editors !== undefined && editors.length && editors[this.state.selectedContentTab] !== undefined) {
      editors[this.state.selectedContentTab].exportHtml(exportData => {
        let saveHTML = exportData.html;
        let saveJSON = exportData.design;
        let bodys = this.state.bodys;
        bodys[this.state.selectedContentTab] = saveHTML;
        let bodyJSONs = this.state.bodyJSONs;
        bodyJSONs[this.state.selectedContentTab] = saveJSON;
        this.setState({ bodys, bodyJSONs }, function() {
          this.setState({ contentToPreview: saveHTML });
        }.bind(this));
      });
    } else if(this.state.bodys.length && this.state.bodys[this.state.selectedContentTab] !== '') {
      this.setState({ contentToPreview: this.state.body });
    }
  }

  hideCampaignPreview() {
    this.setState({ contentToPreview: null });
  }

  closeTestEmailModal() {
    this.setState({ showTestEmailModal: false });
  }

  submitTestEmailModal(test_emails) {
    this.closeTestEmailModal(this);
    let name = this.state.name;
    let subjects = this.state.subjects;
    let from_name = this.state.from_name;
    let from_email = this.state.from_email;
    let sender_domain = this.state.campaignSelectedDomain;
    let replyToCheck = this.state.replyToCheck;
    let reply_to = this.state.reply_to;

    let campaign_id = this.state.campaign_id;

    let target_donors = this.state.toValue;
    let target_groups = [];

    let data = {};
    data['campaign_tags'] = this.state.selectedTags;
    if(subjects !== '') data['subjects'] = subjects;
    if(from_name !== '') data['from_name'] = from_name;
    if(from_email !== '') data['from_email'] = from_email;
    if(sender_domain !== '') data['sender_domain'] = sender_domain;
    if(target_groups !== '') data['target_groups'] = target_groups;
    if(target_donors !== '') data['target_donors'] = target_donors;
    if(!replyToCheck) data['reply_to'] = reply_to;

    if(this.state.scheduleCheck) {
      var x = moment(this.state.startDate).format('YYYY-MM-DD');
      x = x + ' ' + this.state.time.format('HH:mm:ss');
      data['scheduled_datetime'] = moment(x).toISOString();
      data['is_scheduled'] = true;
    }

    if(name !== '' && subjects.length && from_name !== '' && from_email !== '') {
      fetch(process.env.REACT_APP_API_URL + '/ab-testing/campaigns/' + this.props.ngo.ngo_id + '/' + campaign_id + '/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify({ data_for_update: data })
      }).then((response) => response.json())
      .then((data) => {
        this.notifySuccess('Your Campaign has been saved successfully.');
        fetch(process.env.REACT_APP_API_URL + '/ab-testing/campaigns/' + this.props.ngo.ngo_id + '/send-test/' + campaign_id, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify({ test_emails })
        }).then((response) => response.json())
        .then(function(data) {
          if(data.status === 'ok') this.notifySuccess('Your Test Email has been sent successfully.');
        }.bind(this));
      });
    }
  }

  changeSampleSize(value) {
    var test_settings = this.state.test_settings;
    test_settings.sample_size = value;
    this.setState({ test_settings });
  }

  changeVersionsCount(e) {
    var test_settings = this.state.test_settings;
    test_settings.versions_count = e.target.value;

    if(test_settings.ab_test_type === 'subject') {
      let subjects = this.state.subjects;
      let newSubjects = [];
      if(subjects.length > e.target.value) {
        for(var i = 0; i < e.target.value; i++) {
          newSubjects.push(subjects[i]);
        }
      } else if (subjects.length < e.target.value) {
        newSubjects = subjects;
        for(i = subjects.length; i < e.target.value; i++) {
          newSubjects.push('');
        }
      }
      this.setState({ subjects: newSubjects }, function() {
        this.setState({ test_settings });
      }.bind(this));
    } else if(test_settings.ab_test_type === 'content') {
      let bodys = this.state.bodys;
      let neweditors = [];
      let newbodys = [];
      if(bodys.length > e.target.value) {
        for(i = 0; i < e.target.value; i++) {
          newbodys.push(bodys[i]);
          neweditors.push(editors[i]);
        }
      } else if (bodys.length < e.target.value) {
        newbodys = bodys;
        neweditors = editors;
        for(i = bodys.length; i < e.target.value; i++) {
          newbodys.push(bodys[bodys.length - 1]);
          neweditors.push(undefined);
        }
      }


      let bodyJSONs = this.state.bodyJSONs;
      let newbodyJSONs = [];
      if(bodyJSONs.length > e.target.value) {
        for(var j = 0; j < e.target.value; j++) {
          newbodyJSONs.push(bodyJSONs[j]);
        }
      } else if (bodyJSONs.length < e.target.value) {
        newbodyJSONs = bodyJSONs;
        for(j = bodyJSONs.length; j < e.target.value; j++) {
          newbodyJSONs.push(bodyJSONs[bodyJSONs.length - 1]);
        }
      }
      this.setState({ bodys: newbodys, bodyJSONs: newbodyJSONs }, function() {
        this.setState({ test_settings });
        editors = neweditors;
      }.bind(this));
    }
  }

  changeTestType(e) {
    var test_settings = this.state.test_settings, i;
    test_settings.ab_test_type = e.target.value;
    if(e.target.value === 'subject') {
      let subjects = this.state.subjects;
      for(i = 1; i < this.state.test_settings.versions_count; i++) {
        subjects.push('');
      }

      let body = this.state.bodys[0];
      let bodyJSON = this.state.bodyJSONs[0];
      editors = [editors[0]];
      this.setState({ subjects, bodys: [body], bodyJSONs: [bodyJSON], selectedContentTab: 0 }, function() {
        this.setState({ test_settings });
      }.bind(this));
    } else if (e.target.value === 'content') {
      let subject = this.state.subjects[0];

      let bodys = this.state.bodys;
      let bodyJSONs = this.state.bodyJSONs;
      for(i = 1; i < this.state.test_settings.versions_count; i++) {
        bodys.push(bodys[0]);
        bodyJSONs.push(bodyJSONs[0]);
        editors.push(undefined);
      }
      this.setState({ subjects: [subject], bodys, bodyJSONs }, function() {
        this.setState({ test_settings });
      }.bind(this));
    }
  }

  changeTestDuration(e) {
    var test_settings = this.state.test_settings;
    test_settings.duration = e.target.value;
    this.setState({ test_settings });
  }

  changeContentTab(index) {
    editors[this.state.selectedContentTab].exportHtml(exportData => {
      let saveHTML = exportData.html;
      let saveJSON = exportData.design;
      let bodys = this.state.bodys;
      bodys[this.state.selectedContentTab] = saveHTML;
      let bodyJSONs = this.state.bodyJSONs;
      bodyJSONs[this.state.selectedContentTab] = saveJSON;

      this.setState({ bodys, bodyJSONs, selectedContentTab: index });
    });
  }

  render() {
    let options = {
      projectId: process.env.REACT_APP_UNLAYER_PROJECT_ID || 433,
      mergeTags: [
        {name: "First Name", value: "{{first_name}}"},
        {name: "Last Name", value: "{{last_name}}"},
        {name: "Full Name", value: "{{full_name}}"}
      ]
    };
    return (
      <div>
        <div className="ab-testing-sidebar">
          <h4>A/B Testing Settings</h4>

          <Col md={12} className="form-group">
            <label className="control-label is-imp"> Test Type: </label>
            <input type="radio" name="ab-test-type" value="subject" checked={this.state.test_settings.ab_test_type === 'subject'} onChange={this.changeTestType.bind(this)} /> Subject Line<br/>
            <input type="radio" name="ab-test-type" value="content" checked={this.state.test_settings.ab_test_type === 'content'} onChange={this.changeTestType.bind(this)} /> Email Content<br/>
          </Col>

          <Col md={12} className="form-group">
            <label className="control-label is-imp"> Number of versions: </label>
            <select className="form-control" defaultValue={this.state.test_settings.versions_count} onChange={this.changeVersionsCount.bind(this)}>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </Col>

          <Col md={12} className="form-group sample-size-container">
            <label className="control-label"> Sample Size: </label>
            <InputRange maxValue={90} minValue={1} value={this.state.test_settings.sample_size}
              onChange={value => this.changeSampleSize(value)} formatLabel={value => `${value}%`} />
          </Col>

          <Col md={12} className="form-group version-percentages-container">
            {(() => {
              var versionPercentages = [];
              for (var i = 0; i < this.state.test_settings.versions_count; i++) {
                versionPercentages.push(i);
              }
              return versionPercentages.map(function(versionPercentage, index) {
                return (
                  <div className="version-percentage-container" key={index}>
                    <div className={"version-name version-" + index}>V<span>{index + 1}</span></div>
                    <div className="percentage">{(this.state.test_settings.sample_size / this.state.test_settings.versions_count).toFixed(2) + '%'}</div>
                  </div>
                )
              }.bind(this))
            })()}
            <div className="winning-version-container">
              <div className="winning-version">Winning Version</div>
              {(100 - this.state.test_settings.sample_size) + '%'}
            </div>
          </Col>

          <Col md={12} className="form-group">
            <label className="control-label is-imp"> Test Duration: </label>
            <select className="form-control" defaultValue={this.state.test_settings.duration} onChange={this.changeTestDuration.bind(this)}>
              <option value={1800}>30 Minutes</option>
              <option value={3600}>1 Hour</option>
              <option value={14400}>4 Hours</option>
              <option value={21600}>6 Hours</option>
              <option value={43200}>12 Hours</option>
              <option value={86400}>24 Hours</option>
            </select>
          </Col>
        </div>
        <div className={this.state.contentToPreview !== null ? "page-container absolute" : "page-container"}>
          {this.state.showTestEmailModal ? (null) : (
            <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
          )}
          {this.state.contentToPreview !== null ? (
            <SingleCampaignPreview contentToPreview={this.state.contentToPreview} hideCampaignPreview={this.hideCampaignPreview.bind(this)} />
          ) : (null)}
          <div className="panel">
            <button className="btn preview-container" onClick={this.showCampaignPreview.bind(this)}>
              <Icon size={20} icon={ic_visibility} />
              Preview
            </button>
          </div>
          <h2 className="modal-header-custom">
            Create New Campaign
          </h2>
          <Row>
            <Col className="col-md-offset-1 text-center" md={10}>
              <div className="legend">
                <div>Legend: </div>
                <div><span className="tag mr5">{'{{'}<span className="tagname">first_name</span>{'}} '}</span>=&gt; Jon<span className="pipe"></span><span className="tag mr5">{'{{'}<span className="tagname">last_name</span>{'}} '}</span>=&gt; Snow<span className="pipe"></span><span className="tag mr5">{'{{'}<span className="tagname">full_name</span>{'}} '}</span>=&gt; Jon Snow</div>
              </div>
            </Col>
          </Row>
          <Row className="mb10">
            <Col md={12}>
              <form className="addCampaignForm" name="addCampaignForm" ref="vForm">
                <Row>
                  <Col className="form-group no-pad-left" md={12}>
                    <label className="control-label is-imp"> Give a title to your Campaign: </label>
                    <input type="text" className="form-control" id="campaign_name" name="campaign_name" value={this.state.name}
                      placeholder="Name of the campaign" onChange={this.handleNameChange} required />
                    <span hidden={!this.state.showCampaignNameError}><span className="required-span">Please enter a valid Campaign Name.</span></span>
                  </Col>

                  <Col className="form-group no-pad-left" md={12}>
                    <label className="control-label is-imp"> Design your Campaign: </label>
                    {this.state.test_settings.ab_test_type === 'content' ? (
                      <Tabs selectedIndex={this.state.selectedContentTab} onSelect={tabIndex => this.changeContentTab(tabIndex)}>
                        <TabList>
                          {this.state.bodys.map(function(body, index) {
                            return (
                              <Tab>{'Content ' + (index + 1)}</Tab>
                            )
                          })}
                        </TabList>
                        {this.state.bodys.map(function(body, index) {
                          return (() => {
                            if (body !== '' && this.state.bodyJSONs[index] !== undefined && this.state.bodyJSONs[index] !== null) {
                              return (
                                <TabPanel>
                                  <EmailEditor ref={thisEditor => editors[index] = thisEditor} onLoad={this.state.bodyJSONs[index] ? this.onLoad(index) : (null)} options={options} />
                                </TabPanel>
                              );
                            } else {
                              return (
                                <TabPanel>
                                  <EmailEditor ref={thisEditor => editors[index] = thisEditor} options={options} />
                                </TabPanel>
                              );
                            }
                          })()
                        }.bind(this))}
                      </Tabs>
                    ) : (() => {
                      if (this.state.bodys.length && this.state.bodys[0] !== '' && this.state.bodyJSONs.length && this.state.bodyJSONs[0] !== null) {
                        return (
                          <EmailEditor ref={thisEditor => editors[0] = thisEditor} onLoad={this.state.bodyJSONs[0] ? this.onLoad(0) : (null)} options={options} />
                        );
                      } else {
                        return (
                          <div>
                            <EmailEditor ref={thisEditor => editors[0] = thisEditor} options={options} />
                          </div>
                        );
                      }
                    })()}
                    <span hidden={!this.state.showBodyError}><span className="required-span">Please enter a valid Email body.</span></span>
                  </Col>

                  <Col md={4} className="form-group">
                    <label className="control-label" style={{ display: 'flex' }}>
                      <input className="form-control mr5" type="checkbox" checked={this.state.unsubscribeStatus}
                        onChange={this.changeUnsubscribeStatus.bind(this)} />
                      Add an Unsubscribe link to Email
                    </label>
                  </Col>

                  <Col md={4} className="form-group">
                    <label className="control-label" style={{ display: 'flex' }}>
                      <input className="form-control mr5" type="checkbox" checked={this.state.socialMediaIconStatus}
                        onChange={this.changeSocialMediaIconStatus.bind(this)} />
                      Add Social Media Icons in footer
                    </label>
                  </Col>

                  <Col md={4} className="form-group tags">
                    <label className="control-label" style={{ display: 'flex' }}>
                      Add tags
                    </label>
                    <Multiselect id="tagsTo" onChange={this.handleTagChange} enableCaseInsensitiveFiltering
                      buttonWidth={'100%'} data={this.state.tags} multiple enableClickableOptGroups enableCollapsibleOptGroups numberDisplayed={3}
                      includeSelectAllOption enableFiltering onSelectAll={this.handleTagChange} onDeselectAll={this.handleTagChange} />
                  </Col>

                  <Col className="form-group no-pad-left" md={12}>
                    <label className="control-label"> Attachments: </label>
                    <div>
                      <input type="file" className="form-control col-md-11" id="addCampaignModalAttachments"
                        name="attachments" multiple required onChange={this.onCampaignFilesChange} />
                      <span className="clearAttachments col-md-1" onClick={this.removeCampaignFiles}>X</span>
                    </div>
                  </Col>
                </Row>

                <Row>
                  {this.state.test_settings.ab_test_type === 'subject' ? (
                    this.state.subjects.map(function(subject, index) {
                      return (
                        <Col className="form-group no-pad-left" md={12}>
                          <label className="control-label is-imp"> {'Subject ' + (index + 1) + ':'} </label>
                          <input type="text" className="form-control" id={"email_subject_" + index} name={"email_subject_" + index} value={this.state.subjects[index]}
                            placeholder="Subject of the email" onChange={this.handleSubjectChange.bind(this, index)} required />
                          <span hidden={!this.state.showSubjectError[index]}><span className="required-span">Please enter a valid Subject.</span></span>
                        </Col>
                      )
                    }.bind(this))
                  ) : (
                    <Col className="form-group no-pad-left" md={12}>
                      <label className="control-label is-imp"> Subject: </label>
                      <input type="text" className="form-control" id="email_subject" name="email_subject" value={this.state.subjects[0]}
                        placeholder="Subject of the email" onChange={this.handleSubjectChange.bind(this, 0)} required />
                      <span hidden={!this.state.showSubjectError[0]}><span className="required-span">Please enter a valid Subject.</span></span>
                    </Col>
                  )}

                  <Col className="form-group no-pad-left" md={4}>
                    <label className="control-label is-imp"> From Name: </label>
                    <input type="text" className="form-control" id="name_from"
                      name="name_from" placeholder={this.state.first_name + ' from ' + this.props.ngo.ngo_name} required value={this.state.from_name} onChange={this.handleFromNameChange} />
                    <span hidden={!this.state.showFromNameError}><span className="required-span">Please enter a valid Name.</span></span>
                  </Col>

                  <Col className="form-group no-pad-left" md={8}>
                    <label className="control-label is-imp"> From Email: </label>
                    <span className="from-email-span">
                      <input type="email" className="form-control" id="email_from"
                        name="email_from" placeholder="ngo" required value={this.state.from_email} onChange={this.handleFromEmailChange} />
                      <label>{'@'}
                        <select id="campaignSelectedDomain" value={this.state.campaignSelectedDomain} onChange={this.domainChange}>
                          {this.props.ngo.domains.map(function(domain, i) {
                            if(i === 0) return <option value={domain} key={i}>{domain}</option>
                            else return <option value={domain} key={i}>{domain}</option>
                          })}
                        </select>
                      </label>
                      <div className="from-email-note-container">
                        {this.state.from_email !== '' && this.state.from_email_domain !== '' ? (
                          <note style={{ fontSize: '12px', color: '#ef5a20', display: 'flex', alignItems: 'center' }}>
                            Your final from email will be {this.state.from_email}@{this.state.from_email_domain}
                          </note>
                        ) : (null)}
                        <note style={{ fontSize: '12px', color: '#ef5a20', display: 'flex', alignItems: 'center' }}>
                          To Add another domain, contact support@letsendorse.com
                        </note>
                      </div>
                    </span>
                    <span hidden={!this.state.showFromEmailError}><span className="required-span">Please enter a valid Email.</span></span>
                  </Col>

                  <Col className="form-group no-pad-left" md={12}>
                    <label className="control-label is-imp"> To: </label>

                    <Col md={2} className="no-pad-left">
                      <select className="form-control" style={{ borderColor: '#ccc', height: '36px' }} onChange={this.onDonorCategoriesChange.bind(this)} defaultValue={this.state.selectedDonorCategory}>
                        <option value="" selected disabled>Select List</option>
                        {this.state.donorCategories.map(function(category, index) {
                          return <option value={category.id} key={index}>{category.name + ' (' + category.groups + ')'}</option>
                        })}
                      </select>
                    </Col>

                    {this.state.toValue !== null ? (
                      <Col md={10}>
                        <Multiselect id="campaignTo" defaultValue={this.state.toValue} onChange={this.handleChange.bind(this, 'toValue')} enableCaseInsensitiveFiltering
                          buttonWidth={'100%'} data={this.state.to} multiple enableClickableOptGroups enableCollapsibleOptGroups numberDisplayed={3}
                          includeSelectAllOption enableFiltering onSelectAll={this.handleChange.bind(this, 'toValue')} onDeselectAll={this.handleChange.bind(this, 'toValue')} />
                        <span hidden={!this.state.showToError}><span className="required-span">Please enter a valid Recipient.</span></span>
                      </Col>
                    ) : (null)}
                  </Col>

                  {/*<Col className="form-group no-pad-left" md={12}>
                    <label className="control-label"> Choose From Template: </label>
                    <div className="ondisplay">
                      <section>
                        <div className="slideThree">
                          <input type="checkbox" id="chooseFromTemplate" name="check"
                          className="schedule_check" checked={this.state.useTemplateCheck} onChange={this.handleUseTemplateChange} />
                          <label htmlFor="chooseFromTemplate"></label>
                        </div>
                      </section>
                    </div>
                    <Col className="form-group no-pad-left" md={5} hidden={!this.state.useTemplateCheck}>
                      <Select.Async value={this.state.selectedTemplateId} loadOptions={this.getTemplateOptions}
                        onChange={this.onTemplateChange} isLoading={true} backspaceRemoves={false} />
                    </Col>
                  </Col>*/}

                  <Col className="form-group no-pad-left" md={4}>
                    <label className="control-label"> Send all Replies to From Email: </label>
                    <div className="ondisplay">
                      <section>
                        <div className="slideThree">
                          <input type="checkbox" id="replyToCheck" name="check"
                          className="schedule_check" checked={this.state.replyToCheck} onChange={this.handleReplyToCheckChange} />
                          <label htmlFor="replyToCheck"></label>
                        </div>
                      </section>
                    </div>
                  </Col>

                  {!this.state.replyToCheck ? (
                    <Col className="form-group no-pad-left" md={6}>
                      <label className="control-label is-imp"> Reply To: </label>
                      <input type="email" className="form-control" id="reply_to"
                        name="reply_to" placeholder={'ngo@' + this.state.campaignSelectedDomain}
                        required value={this.state.reply_to} onChange={this.handleReplyToChange} />
                      <span hidden={!this.state.showReplyToError}><span className="required-span">Please enter a valid Email.</span></span>
                    </Col>
                  ) : (null)}

                  <Col className="form-group no-pad-left" md={12}>
                    <label className="control-label"> Schedule: </label>
                    <div className="ondisplay">
                      <section>
                        <div className="slideThree">
                          <input type="checkbox" id="schedule" name="check"
                          className="schedule_check" checked={this.state.scheduleCheck} onChange={this.handleScheduleChange} />
                          <label htmlFor="schedule"></label>
                        </div>
                      </section>
                    </div>
                    <Col className="form-group no-pad-left" md={3} hidden={!this.state.scheduleCheck}>
                      <DatePicker className="schedule_date" selected={this.state.startDate}
                        onChange={this.handleDateChange} minDate={moment()} isClearable={true}
                        showYearDropdown scrollableYearDropdown showMonthDropdown dateFormat="DD/MM/YYYY"/>
                    </Col>
                    <Col className="form-group no-pad-right" md={2} hidden={!this.state.scheduleCheck}>
                      <TimePicker showSecond={false} value={this.state.time} use12Hours
                        className="schedule_time" format={format} onChange={this.handleTimeChange} />
                    </Col>
                    <Col className="form-group no-pad-right" md={4} hidden={!this.state.scheduleCheck}>
                      <TimezonePicker value={this.state.timezone} onChange={this.handleTimezoneChange.bind(this)}
                        inputProps={{ placeholder: 'Select Timezone...', name: 'timezone' }} />
                    </Col>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
          <Row className="text-center">
            <Button className="btn btn-danger mr10" onClick={this.props.onAddCampaignClose}>
              Discard
              <span className="glyphicon glyphicon-remove"></span>
            </Button>

            <Button className="btn btn-warning mr10" onClick={this.saveCampaign.bind(this, 'save')}>
              Save Changes
              {this.state.showButtonLoader === 'savePage1' ? (
                <span><i className="fa fa-circle-o-notch fa-spin"></i></span>
              ) : (
                <span className="glyphicon glyphicon-saved"></span>
              )}
            </Button>

            {(() => {
              if(!this.state.scheduleCheck) {
                return (
                  <Button disabled={this.disableSend(this)} className="btn btn-warning mr10" id="submit_donation" onClick={this.saveCampaign.bind(this, 'send')}>
                    Send Now
                    {this.state.showButtonLoader === 'sendMail' ? (
                      <span><i className="fa fa-circle-o-notch fa-spin"></i></span>
                    ) : (
                      <span className="glyphicon glyphicon-send"></span>
                    )}
                  </Button>
                );
              } else {
                return (
                  <Button disabled={this.disableSend(this)} className="btn btn-warning mr10" id="submit_donation" onClick={this.saveCampaign.bind(this, 'send')}>
                    Schedule this Campaign
                    {this.state.showButtonLoader === 'scheduleMail' ? (
                      <span><i className="fa fa-circle-o-notch fa-spin"></i></span>
                    ) : (
                      <span className="glyphicon glyphicon-time"></span>
                    )}
                  </Button>
                );
              }
            })()}
          </Row>
        </div>
      </div>
    );
  }
}

AddABTestingCampaign.readOnlyModules = {
  toolbar: []
}

AddABTestingCampaign.modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['link', 'image', 'video'],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ]
}

AddABTestingCampaign.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
  'list', 'bullet', 'indent', 'script',
  'link', 'image', 'video', 'align', 'color', 'background',
  'clean'
]