import React from 'react';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import { Row, Col, Modal } from 'react-bootstrap';
import moment from 'moment';
import $ from 'jquery';
import _ from  'lodash';
import swal from "sweetalert2";
import { Select } from 'antd';
import { ToastContainer, toast } from 'react-toastify';

import "sweetalert2/dist/sweetalert2.min.css";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const Option = Select.Option;

const readCookie = require('../cookie').readCookie;

export default class SettingsMembersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_type: [],
      emp_type: null,
      selectedRow: {},
      openModal: false,
      admin_select: null,
      authorizedForEm: '',
      openEditModal: false,
      user_list: ["admin", "view", "edit"]

    }
    this.removeMember = this.removeMember.bind(this);
    this.editMember = this.editMember.bind(this);
    this.actionsFormatter = this.actionsFormatter.bind(this);
    this.editFormatter = this.editFormatter.bind(this);
  }

  componentDidMount(){
    const user_type=[];
    _.map(this.state.user_list, (element, index) => {
      user_type.push(<Option key={index} value={element}> {element} </Option>)
    })
    let authorizedForIm = JSON.parse(readCookie('userData')).authorizedFor.im;
    this.setState({ user_type: user_type, authorizedForIm })
  }
  nameFormatter(cell, row) {
    let access_type = '';
    if(row.access_type === 'admin') access_type = 'Admin';
    else if(row.access_type === 'view') access_type = 'View';
    else if(row.access_type === 'edit') access_type = 'Edit';
    return (
      <div>
        {row.first_name + ' ' + row.last_name}
        <span className={"access-type-tag " + row.access_type}>{access_type}</span>
      </div>
    )
  }

  dateFormatter(value, row, index) {
    return moment(value).format('DD/MM/YYYY');
  }

  onAddMemberOpen(){
    this.setState({openModal: true})
  }

  onEditOpen(row){
    this.setState({openEditModal: true, selectedRow: row, emp_type: row.access_type});
  }

  createCustomButtonGroup (props, onClick) {
    return (
      <div>
        {this.props.ngo.access_type === 'admin' ? (
          <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
            <button type='button' onClick={this.onAddMemberOpen.bind(this)}
              className={'btn btn-success react-bs-table-add-btn hidden-print'}>
              <span>
                <i className="glyphicon glyphicon-plus"></i>
                Add Member
              </span>
            </button>
          </ButtonGroup>
        ) : (null)}
      </div>
    );
  }

  createCustomClearButton(onClick) {
    return (
      <span onClick={onClick}>
        <i className="clear-table-search glyphicon glyphicon-remove"></i>
      </span>
    );
  }

  actionsFormatter(cell, row) {
    if(row.access_type === 'admin' && this.props.adminCount > 1) {
      return (
        <div>
          <button className="btn admin-action-btn" title="Remove Admin" onClick={this.removeMember.bind(this, row)}><i className="fa fa-user-times" aria-hidden="true"></i>{' Remove Admin'}</button>
        </div>
      );
    } else if(row.access_type === 'member') {
      return (
        <div>
          <button className="btn member-action-btn" title="Remove Member" onClick={this.removeMember.bind(this, row)}><i className="fa fa-user-times" aria-hidden="true"></i>{' Remove Member'}</button>
        </div>
      );
    }
  }

  editFormatter(cell, row) {
    if(row.access_type === 'admin' && this.props.adminCount > 1) {
      return (
        <div>
          <button className="btn admin-action-btn" title="Remove Admin" onClick={this.onEditOpen.bind(this, row)}><i className="fa fa-pencil" aria-hidden="true"></i>{' Edit Admin'}</button>
        </div>
      );
    } else if(row.access_type === 'member') {
      return (
        <div>
          <button className="btn member-action-btn" title="Remove Member" onClick={this.onEditOpen.bind(this, row)}><i className="fa fa-pencil" aria-hidden="true"></i>{' Edit Member'}</button>
        </div>
      );
    }
  }

  editMember(){
    let first_name = this.state.selectedRow.first_name;
    let last_name = this.state.selectedRow.last_name;
    let email = this.state.selectedRow.email;
    if (first_name === '' || last_name === '' || email === '' || this.state.emp_type === '') {
      swal('Oops...',
      "Please enter proper values.",
      'error');
    } else {
      let ngo_id = this.props.ngo.ngo_id;
      fetch(process.env.REACT_APP_API_URL + '/ngo/' + ngo_id + '/member', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify({
          authorizedFor: {im: this.state.emp_type},
          first_name,
          last_name,
          email,
          type: this.state.emp_type,
          ngo_id: ngo_id
        })
      }).then((response) => response.json())
      .then(function(data) {
        this.props.updateMembers();
        this.onCloseEditModal();
        swal({
          'text': 'Member updated successfully',
          'type': 'success'
        })
      }.bind(this));
    }
  }

  removeMember(row) {
    let to_remove_user = row.id;
    let access_type = row.access_type;
    let name = '';
    if(row.first_name !== '') name = row.first_name;
    else name = row.email;
    let to_remove_users = [];
    to_remove_users.push(to_remove_user);

    swal({
      title: 'Are you sure you wish to remove access of ' + name,
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!',
      cancelButtonText: 'No, Cancel!',
      confirmButtonClass: 'btn',
      cancelButtonClass: 'btn',
    }).then(function (result) {
      if(result) {
        fetch(process.env.REACT_APP_API_URL + '/remove_ngo_users/' + this.props.ngo.ngo_id, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify({ to_remove_users, module: "im" })
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          if(data.status === 'ok') {
            this.props.updateMembers();
            this.notifySuccess(access_type.charAt(0).toUpperCase() + access_type.slice(1) + ' successfully removed!');
          }
        }.bind(this));
      }
    }.bind(this));
  }

  notifySuccess = (text) => toast.success(text);

  onCloseModal(){
    this.setState({openModal: false})
  }

  onCloseEditModal(){
    this.setState({openEditModal: false});
  }

  addMember(){
    let val1 = $('#swal-input1').val();
    let val2 = $('#swal-input2').val();
    let val3 = $('#swal-input3').val();
    if (val1 === '' || val2 === '' || val3 === '' || this.state.emp_type === '') {
      swal('Oops...',
      "Please enter proper values.",
      'error');
    } else {
      let ngo_id = this.props.ngo.ngo_id;
      fetch(process.env.REACT_APP_API_URL + '/ngo/' + ngo_id + '/member', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify({
          authorizedFor: {im: this.state.emp_type},
          first_name: val1,
          last_name: val2,
          email: val3,
          type: this.state.emp_type,
          ngo_id: ngo_id,
          mis_access_token: JSON.parse(readCookie('mis_data')).access_token
        })
      }).then((response) => response.json())
      .then(function(data) {
        this.props.updateMembers();
        this.onCloseModal();

        swal({
          'text': 'Member Added successfully',
          'type': 'success'
        })
      }.bind(this));
    }
  }

  selectedType(e){
    this.setState({emp_type: e})
  }

  render() {
    const options = {
      btnGroup: this.createCustomButtonGroup.bind(this),
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      sizePerPage: 20,
      sizePerPageList: [20, 50, 100]
    };

    return (
      <div className="finance-tabs-content">
        {/*Add Member Modal*/}
        <Modal show={this.state.openModal} onHide={this.onCloseModal.bind(this)}  style={{width: '700px', marginLeft: '25%'}} center>
          <div>
            <h1 style={{textAlign: 'center'}}>
              Add member
            </h1>
            <Row>
              <Col md={6}>
                <Col md={12}>
                  <label className="col-md-12 is-imp">First Name</label>
                  <Col md={12}>
                    <input type="text" id="swal-input1"  className="form-control"  placeholder="First Name" />
                  </Col>
                </Col>
              </Col>
              <Col md={6}>
                <Col md={12}>
                  <label className="col-md-12 is-imp">Last Name</label>
                  <Col md={12}>
                    <input type="text" id="swal-input2"  className="form-control"  placeholder="Last Name" />
                  </Col>
                </Col>
              </Col>
              <Col md={6}>
                <Col md={12} className="mt10 mb10">
                  <label className="col-md-12 is-imp">Email</label>
                  <Col md={12}>
                    <input type="text" id="swal-input3"  className="form-control"  placeholder="Email" />
                  </Col>
                </Col>
              </Col>
              <Col md={6}>
                <Col md={12} className="mt10 mb10">
                  <label className="col-md-12 is-imp">Type (Admin/Member)</label>
                  <Col md={12}>
                    <Select style={{ width: '100%' }} placeholder="Select Type"
                    onChange={this.selectedType.bind(this)}>
                    {this.state.user_type}
                  </Select>
                  </Col>
                </Col>
              </Col>
              <Col md={6}>
                <Col md={12} className="mt10 mb10">
                  <button style={{float: 'right', marginRight: '15px'}} className="btn btn-info" onClick={this.addMember.bind(this)}>Add Member</button>
                </Col>
              </Col>
            </Row>
          </div>
				</Modal>

        {/*Edit Member Modal*/}
        <Modal show={this.state.openEditModal} onHide={this.onCloseEditModal.bind(this)}  style={{width: '700px', marginLeft: '25%'}} center>
          <div>
            <h1 style={{textAlign: 'center'}}>
              Edit member
            </h1>
            <Row>
              <Col md={6}>
                <Col md={12}>
                  <label className="col-md-12 is-imp">First Name</label>
                  <Col md={12}>
                    <input type="text" className="form-control" value={this.state.selectedRow.first_name} readOnly />
                  </Col>
                </Col>
              </Col>
              <Col md={6}>
                <Col md={12}>
                  <label className="col-md-12 is-imp">Last Name</label>
                  <Col md={12}>
                    <input type="text" className="form-control" value={this.state.selectedRow.last_name} readOnly />
                  </Col>
                </Col>
              </Col>
              <Col md={6}>
                <Col md={12} className="mt10 mb10">
                  <label className="col-md-12 is-imp">Email</label>
                  <Col md={12}>
                    <input type="text" className="form-control" value={this.state.selectedRow.email} readOnly />
                  </Col>
                </Col>
              </Col>
              <Col md={6}>
                <Col md={12} className="mt10 mb10">
                  <label className="col-md-12 is-imp">Type (Admin/Member)</label>
                  <Col md={12}>
                    <Select style={{ width: '100%' }} defaultValue={this.state.emp_type}
                    onChange={this.selectedType.bind(this)}>
                    {this.state.user_type}
                  </Select>
                  </Col>
                </Col>
              </Col>
              <Col md={6}>
                <Col md={12} className="mt10 mb10">
                  <button style={{float: 'right', marginRight: '15px'}} className="btn btn-info" onClick={this.editMember.bind(this)}>Submit</button>
                </Col>
              </Col>
            </Row>
          </div>
        </Modal>

        <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
      	<BootstrapTable data={this.props.members} options={options} striped={true} hover={true} search searchPlaceholder="Search by Name/Email/Joining Date">
          <TableHeaderColumn dataField="id" isKey={true} hidden={true} export={false} searchable={false}>Sr No</TableHeaderColumn>
          <TableHeaderColumn dataField="first_name" dataSort={true}   hidden={false} searchable={true} dataFormat={this.nameFormatter}>Full Name</TableHeaderColumn>
          <TableHeaderColumn dataField="last_name"  dataSort={true}   hidden={true}  searchable={true}>Last Name</TableHeaderColumn>
          <TableHeaderColumn dataField="email"      dataSort={true}   hidden={false} searchable={true}>Email</TableHeaderColumn>
          <TableHeaderColumn dataField="created_on" dataSort={true}   hidden={false} searchable={false} dataFormat={this.dateFormatter}>Joining Date</TableHeaderColumn>
          {this.state.authorizedForIm === 'admin' ? (
            <TableHeaderColumn dataField="created_on" dataSort={false}  hidden={false} searchable={false} dataFormat={this.actionsFormatter}>Remove Member</TableHeaderColumn>
          ) : (null)}
          {this.state.authorizedForIm === 'admin' ? (
            <TableHeaderColumn dataField="created_on" dataSort={false}  hidden={false} searchable={false} dataFormat={this.editFormatter}>Edit Member</TableHeaderColumn>
          ) : (null)}
        </BootstrapTable>
      </div>
    );
  }
}
