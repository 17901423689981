import React from 'react';
import { BootstrapTable, TableHeaderColumn ,ButtonGroup} from 'react-bootstrap-table';
import {Link} from 'react-router-dom';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';

import EditGroup from './add-group/EditGroup';
import SingleGroupQuicklinks from './SingleGroupQuicklinks';
import AddFollowModal from '../6-followups/add-followup/AddFollowModal';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const readCookie = require('../cookie.js').readCookie;

const styles = {
  clear: {
    clear: 'both',
  },
  groupHeading: {
    overflow: 'auto'
  }
};

function firstNameFormatter(cell, row) {
  var userProfile = "/dm/members/" + row.donor_id;
  var firstName = row['first_name'];
  return (
    <Link to={userProfile}>{firstName}</Link>
  );
}

function lastNameFormatter(cell, row) {
  var userProfile = "/dm/members/" + row.donor_id;
  var lastName = row['last_name'];
  return (
    <Link to={userProfile}>{lastName}</Link>
  );
}

function emailFormatter(cell, row) {
  var userProfile = "/dm/members/" + row.donor_id;
  var email = row['email'];
  return (
    <Link to={userProfile}>{email}</Link>
  );
}

function dateFormatter(value, row, index) {
  if(value === 'Not donated yet') return 'Not donated yet';
  return moment(value).format('DD/MM/YYYY');
}

function donorTypeFormatter(value, row, index) {
  var val = value.replace(/_/g, " ");
  return val.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default class SingleGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showEditGroupModal: false,
      showFollowModal: false,
      group_name: '',
      group_desc: '',
      donor_ids: [],
      selectRowProp: {
        mode: 'checkbox',
        onSelect: this.onRowSelect.bind(this),
        onSelectAll: this.onAllRowSelect.bind(this)
      },
      selectedRowCount: 0,
      deleteButtonDisabled: true
    }
  }

  onRowSelect(row, isSelected) {
    if(isSelected) {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length+1 }, function() {
        if(this.state.selectedRowCount) {
          this.setState({ deleteButtonDisabled: false });
        }
      });
    } else {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length-1 }, function() {
        if(this.state.selectedRowCount) {
          this.setState({ deleteButtonDisabled: false });
        } else {
          this.setState({ deleteButtonDisabled: true });
        }
      });
    }
  }

  onAllRowSelect(row, isSelected) {
    if(row) {
      this.setState({
        selectedRowCount: this.refs.table.state.data.length,
        deleteButtonDisabled: false
      });
    } else {
      this.setState({
        selectedRowCount: 0,
        deleteButtonDisabled: true
      });
    }
  }

  componentWillMount() {
    fetch(process.env.REACT_APP_API_URL + '/view_group_donors/' + this.props.match.params.name, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      var donor_ids = [];
      for(var i = 0; i < data['donors'].length; i++) {
        donor_ids.push(data['donors'][i]['donor_id']);
      }
      this.setState({
        data: data['donors'],
        donor_ids
      });
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/group/'+ this.props.match.params.name, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status === 'ok') {
        this.setState({
          group_name: data.group.group_name,
          group_desc: data.group.group_desc
        });
        if(data.group.hasOwnProperty('group_type')) this.setState({ group_type: data.group.group_type });
        else this.setState({ group_type: 'manual' });
        if(data.group.hasOwnProperty('segment_condition_data')) this.setState({ conditions: data.group.segment_condition_data.conditions, match_type: data.group.segment_condition_data.match_type });
        else this.setState({ conditions: [], match_type: null });
      }
    }.bind(this));
  }

  notifySuccess = (text) => toast.success(text);

  onEditGroupClose = (data) => {
    this.setState({ showEditGroupModal: false });
    if(data){
      if(data === 'refresh'){
        fetch(process.env.REACT_APP_API_URL + '/view_group_donors/'+ this.props.match.params.name, {
          method: "GET",
          headers: {
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          }
        }).then(function(response) {
          return response.json();
        }).then(function(data) {
          var donor_ids = [];
          for(var i = 0; i < data['donors'].length; i++) {
            donor_ids.push(data['donors'][i]['donor_id']);
          }
          this.setState({
            data: data['donors'],
            donor_ids
          });
          fetch(process.env.REACT_APP_API_URL + '/group/'+ this.props.match.params.name, {
            method: "GET",
            headers: {
              'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
            }
          }).then(function(response) {
            return response.json();
          }).then(function(data) {
            if(data.status === 'ok') {
              this.setState({
                group_name: data.group.group_name,
                group_desc: data.group.group_desc
              });
            }
          }.bind(this));
        }.bind(this));
      }
    }
  }

  onEditGroupOpen = () => {
    this.setState({ showEditGroupModal: true });
  }

  onDeleteGroupClose = (data) => {
    this.setState({ showDeleteDonorModal: false });
    if(data) {
      if(data === 'refresh') {
        fetch(process.env.REACT_APP_API_URL + '/view_group_donors/'+ this.props.match.params.name, {
          method: "GET",
          headers: {
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          }
        }).then(function(response) {
          return response.json();
        }).then(function(data) {
          this.setState({ data: data['donors'] });
        }.bind(this));
      }
    }
  }

  getSelectedRowKeys() {
    let selectedDonorIds = this.refs.table.state.selectedRowKeys;
    let donors = this.state.data;
    let deleteDonorIds = [];
    for(var i = 0; i < selectedDonorIds.length; i++) {
      for(var j = 0; j < donors.length; j++) {
        if (donors[j]['donor_id'] === selectedDonorIds[i]) {
          deleteDonorIds.push(donors[j]['donor_id']);
        }
      }
    }

    fetch(process.env.REACT_APP_API_URL + '/update_group/' + this.props.match.params.name, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify({ delete_donors : deleteDonorIds })
    }).then((response) => response.json())
    .then((data) => {
      if(data['status'] === 'ok') {
        this.notifySuccess('The member has been removed successfully.');
        fetch(process.env.REACT_APP_API_URL + '/view_group_donors/'+ this.props.match.params.name, {
          method: "GET",
          headers: {
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          }
        }).then(function(response) {
          return response.json();
        }).then(function(data) {
          this.setState({ data: data['donors'] });
        }.bind(this));
      }
    })
  }

  createCustomButtonGroup(props, onClick) {
    return (
      <ButtonGroup>
        <button type='button'
          onClick={ this.onEditGroupOpen}
          className={'btn btn-success react-bs-table-add-btn hidden-print'}>
          <span>
            <i className="glyphicon glyphicon-edit"></i>
            Edit Segment
          </span>
        </button>

        {this.state.conditions !== undefined && this.state.match_type !== undefined ? (
          this.state.conditions.length === 0 && this.state.match_type === null ? (
            <button type='button' disabled={this.state.deleteButtonDisabled}
              onClick={ this.getSelectedRowKeys.bind(this)}
              className={'btn btn-success react-bs-table-add-btn hidden-print'}>
              <span>
                <i className="glyphicon glyphicon-minus"></i>
                Remove Member
              </span>
            </button>
          ) : (null)
        ) : (null)}
      </ButtonGroup>
    );
  }

  createCustomClearButton(onClick) {
    return (
      <span onClick={onClick}>
        <i className="clear-table-search glyphicon glyphicon-remove"></i>
      </span>
    );
  }

  onFollowClose() {
    this.setState({ showFollowModal: false });
  }

  onFollowOpen() {
    this.setState({ showFollowModal: true });
  }

  render() {
    const options = {
      btnGroup: this.createCustomButtonGroup.bind(this),
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      exportCSVBtn: this.createCustomExportCSVButton,
      sizePerPage: 20,
      sizePerPageList: [20, 50, 100],
    };

    if(this.state.data.length) {
      return (
        <div className="page-container">
          <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
          <div>
            <span className="back">
              <Link to={'/dm/lists/' + this.props.match.params.list_id + '/segments'} className="back-btn"><i className="fa fa-chevron-left"></i> Go to All Segments</Link>
            </span>
          </div>
          <div className="single-group">
            <div style={styles.groupHeading}>
              <h2 className="single-group-name">{this.state.group_name}</h2>
              <button className="total-donors">Total Members: {this.state.data.length}</button>
            </div>
            <p className="single-group-desc">{this.state.group_desc}</p>
          </div>

          <div style={styles.clear}>
            <BootstrapTable data={this.state.data} options={options}
              striped={false} hover={true} ref='table' selectRow={this.state.selectRowProp}
              exportCSV pagination search searchPlaceholder="Search by Name/Email/Phone/Loacation">
              <TableHeaderColumn dataField="donor_id" isKey={true} hidden={true} export={false} searchable={false}>donor_id</TableHeaderColumn>
              <TableHeaderColumn dataField="donor_type"       dataSort={true} hidden={false}  export={true} searchable={true} dataFormat={donorTypeFormatter}>Donor Type</TableHeaderColumn>
              <TableHeaderColumn dataField="first_name"       dataSort={true} hidden={false}  export={true} searchable={true} dataFormat={firstNameFormatter}>First Name</TableHeaderColumn>
              <TableHeaderColumn dataField="last_name"        dataSort={true} hidden={false}   export={true} searchable={true} dataFormat={lastNameFormatter}>Last Name</TableHeaderColumn>
              <TableHeaderColumn dataField="email"            dataSort={true} hidden={false}  export={true} searchable={true} dataFormat={emailFormatter}>Email</TableHeaderColumn>
              <TableHeaderColumn dataField="dob"              dataSort={true} hidden={true}   export={true} searchable={false}>DOB</TableHeaderColumn>
              <TableHeaderColumn dataField="phone_no"         dataSort={true} hidden={false}  export={true} searchable={true}>Phone</TableHeaderColumn>
              <TableHeaderColumn dataField="nationality"      dataSort={true} hidden={true}   export={true} searchable={false}>Nationality</TableHeaderColumn>
              <TableHeaderColumn dataField="country"          dataSort={true} hidden={true}   export={true} searchable={true}>Country</TableHeaderColumn>
              <TableHeaderColumn dataField="state"            dataSort={true} hidden={true}   export={true} searchable={true}>State</TableHeaderColumn>
              <TableHeaderColumn dataField="city"             dataSort={true} hidden={false}  export={true} searchable={true}>Location</TableHeaderColumn>
              <TableHeaderColumn dataField="zipcode"          dataSort={true} hidden={true}   export={true} searchable={false}>Zipcode</TableHeaderColumn>
              <TableHeaderColumn dataField="address1"         dataSort={true} hidden={true}   export={true} searchable={false}>Address1</TableHeaderColumn>
              <TableHeaderColumn dataField="address2"         dataSort={true} hidden={true}   export={true} searchable={false}>Address2</TableHeaderColumn>
              <TableHeaderColumn dataField="date"             dataSort={false} hidden={false}  export={true} searchable={true} dataFormat={dateFormatter}>Last Donated</TableHeaderColumn>
              <TableHeaderColumn dataField="pan_number"       dataSort={true} hidden={true}   export={true} searchable={false}>PAN</TableHeaderColumn>
              <TableHeaderColumn dataField="org_name"         dataSort={true} hidden={true}   export={true} searchable={false}>Organisation Name</TableHeaderColumn>
              <TableHeaderColumn dataField="org_position"     dataSort={true} hidden={true}   export={true} searchable={false}>Organisation Position</TableHeaderColumn>
              <TableHeaderColumn dataField="priority"         dataSort={true} hidden={true}   export={true} searchable={false}>Priority</TableHeaderColumn>
              <TableHeaderColumn dataField="important_dates"  dataSort={true} hidden={true}   export={true} searchable={false}>Important Dates</TableHeaderColumn>
            </BootstrapTable>
            {this.state.showEditGroupModal && this.state.group_type !== undefined && this.state.conditions !== undefined && this.state.match_type !== undefined ? (
              <EditGroup group_desc={this.state.group_desc}
                groupId={this.props.match.params.name} list_id={this.props.match.params.list_id}
                ngo={this.props.ngo} group_name={this.state.group_name}
                userData={this.props.userData} donor_ids={this.state.donor_ids}
                showEditGroupModal={this.state.showEditGroupModal} match_type={this.state.match_type}
                onHide={this.onEditGroupClose} group_type={this.state.group_type}
                onEditGroupClose={this.onEditGroupClose} conditions={this.state.conditions}
                onEditGroupOpen={this.onEditGroupOpen} />
            ) : (null)}

            <SingleGroupQuicklinks onFollowOpen={this.onFollowOpen.bind(this)} />
            {this.state.showFollowModal ? (
              <AddFollowModal
                ngo={this.props.ngo} userData={this.props.userData}
                showFollowModal={this.state.showFollowModal}
                onHide={this.onFollowClose.bind(this)}
                id={this.state.value_id} group_id={this.props.match.params.name}
                onFollowClose={this.onFollowClose.bind(this)}
                onFollowOpen={this.onFollowOpen.bind(this)} />
            ): (null)}
          </div>
        </div>
      );
    } else {
      return (
        <div className="page-container">
          <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
          <div>
            <span className="back">
              <Link to={'/dm/lists/' + this.props.match.params.list_id + '/segments'} className="back-btn"><i className="fa fa-chevron-left"></i> Back</Link>
            </span>
          </div>
          <div className="single-group">
            <div style={styles.groupHeading}>
              <h2 className="single-group-name">{this.state.group_name}</h2>
              <button className="total-donors">Total Members: {this.state.data.length}</button>
            </div>
            <p className="single-group-desc">{this.state.group_desc}</p>
          </div>

          <div style={styles.clear}>
            <BootstrapTable data={this.state.data} options={options} striped={true} hover={true} selectRow={this.state.selectRowProp }>
              <TableHeaderColumn dataField="donor_id" isKey={true} hidden={true} export={false} searchable={false}>donor_id</TableHeaderColumn>
              <TableHeaderColumn dataField="donor_type"       dataSort={true} hidden={false}  export={true} searchable={true} dataFormat={donorTypeFormatter}>Donor Type</TableHeaderColumn>
              <TableHeaderColumn dataField="first_name"       dataSort={true} hidden={false}  export={true} searchable={true} dataFormat={firstNameFormatter}>First Name</TableHeaderColumn>
              <TableHeaderColumn dataField="last_name"        dataSort={true} hidden={false}   export={true} searchable={true} dataFormat={lastNameFormatter}>Last Name</TableHeaderColumn>
              <TableHeaderColumn dataField="email"            dataSort={true} hidden={false}  export={true} searchable={true} dataFormat={emailFormatter}>Email</TableHeaderColumn>
              <TableHeaderColumn dataField="dob"              dataSort={true} hidden={true}   export={true} searchable={false}>DOB</TableHeaderColumn>
              <TableHeaderColumn dataField="phone_no"         dataSort={true} hidden={false}  export={true} searchable={true}>Phone</TableHeaderColumn>
              <TableHeaderColumn dataField="nationality"      dataSort={true} hidden={true}   export={true} searchable={false}>Nationality</TableHeaderColumn>
              <TableHeaderColumn dataField="country"          dataSort={true} hidden={true}   export={true} searchable={true}>Country</TableHeaderColumn>
              <TableHeaderColumn dataField="state"            dataSort={true} hidden={true}   export={true} searchable={true}>State</TableHeaderColumn>
              <TableHeaderColumn dataField="city"             dataSort={true} hidden={false}  export={true} searchable={true}>Location</TableHeaderColumn>
              <TableHeaderColumn dataField="zipcode"          dataSort={true} hidden={true}   export={true} searchable={false}>Zipcode</TableHeaderColumn>
              <TableHeaderColumn dataField="address1"         dataSort={true} hidden={true}   export={true} searchable={false}>Address1</TableHeaderColumn>
              <TableHeaderColumn dataField="address2"         dataSort={true} hidden={true}   export={true} searchable={false}>Address2</TableHeaderColumn>
              <TableHeaderColumn dataField="date"             dataSort={false} hidden={false}  export={true} searchable={true} dataFormat={dateFormatter}>Last Donated</TableHeaderColumn>
              <TableHeaderColumn dataField="pan_number"       dataSort={true} hidden={true}   export={true} searchable={false}>PAN</TableHeaderColumn>
              <TableHeaderColumn dataField="org_name"         dataSort={true} hidden={true}   export={true} searchable={false}>Organisation Name</TableHeaderColumn>
              <TableHeaderColumn dataField="org_position"     dataSort={true} hidden={true}   export={true} searchable={false}>Organisation Position</TableHeaderColumn>
              <TableHeaderColumn dataField="priority"         dataSort={true} hidden={true}   export={true} searchable={false}>Priority</TableHeaderColumn>
              <TableHeaderColumn dataField="important_dates"  dataSort={true} hidden={true}   export={true} searchable={false}>Important Dates</TableHeaderColumn>
            </BootstrapTable>
            {this.state.showEditGroupModal && this.state.group_type !== undefined && this.state.conditions !== undefined && this.state.match_type !== undefined ? (
              <EditGroup group_desc={this.state.group_desc}
                groupId={this.props.match.params.name} list_id={this.props.match.params.list_id}
                ngo={this.props.ngo} group_name={this.state.group_name}
                userData={this.props.userData} donor_ids={this.state.donor_ids}
                showEditGroupModal={this.state.showEditGroupModal} match_type={this.state.match_type}
                onHide={this.onEditGroupClose} group_type={this.state.group_type}
                onEditGroupClose={this.onEditGroupClose} conditions={this.state.conditions}
                onEditGroupOpen={this.onEditGroupOpen} />
            ) : (null)}

            <SingleGroupQuicklinks onFollowOpen={this.onFollowOpen.bind(this)} />
            {this.state.showFollowModal ? (
              <AddFollowModal
                ngo={this.props.ngo} userData={this.props.userData}
                showFollowModal={this.state.showFollowModal}
                onHide={this.onFollowClose.bind(this)}
                id={this.state.value_id} group_id={this.props.match.params.name}
                onFollowClose={this.onFollowClose.bind(this)}
                onFollowOpen={this.onFollowOpen.bind(this)} />
            ) : (null)}
          </div>
        </div>
      )
    }
  }
}