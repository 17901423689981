import React, {Component} from 'react';
import {Button, Modal, Row, Col} from 'react-bootstrap';
import moment from 'moment';
import $ from 'jquery';
import swal from 'sweetalert2';

import PersonalInformation from './add-donor/Personal';
import OrganizationInformation from './add-donor/Organization';
import OtherInformation from './add-donor/Other';
import ImportantDates from './add-donor/ImportantDates';

const readCookie = require('../cookie.js').readCookie;

export default class EditDonor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      donor: null,
      value: '',
      country: this.props.donor.country,
      state: this.props.donor.state,
      rating: this.props.donor.priority,
      impDateCount: 0,
      donor_type: this.props.donor.donor_type,
      title: this.props.donor.title,
      first_name: this.props.donor.first_name,
      last_name: this.props.donor.last_name,
      phone_no: this.props.donor.phone_no,
      email: this.props.donor.email,
      dob: this.props.donor.dob,
      dob_first_child: this.props.donor.dob_first_child,
      dob_second_child: this.props.donor.dob_second_child,
      dob_father: this.props.donor.dob_father,
      dob_mother: this.props.donor.dob_mother,
      dob_spouse: this.props.donor.dob_spouse,
      marriage_anniversary: this.props.donor.marriage_anniversary,
      address1: this.props.donor.address1,
      address2: this.props.donor.address2,
      nationality: this.props.donor.nationality,
      pan_number: this.props.donor.pan_number,
      donor_source: this.props.donor.donor_source,
      city: this.props.donor.city,
      zipcode: this.props.donor.zipcode,
      org_name: this.props.donor.org_name,
      org_position: this.props.donor.org_position,
      groups: this.props.donor.groups,
      group_ids: [],
      pledged_amount: this.props.donor.pledged_amount || 0,
      frequency: this.props.donor.frequency || '',
      important_dates: this.props.donor.important_dates,
      showFirstNameError: false,
      showLastNameError: false,
      showPhoneError: false,
      showEmailError: false,
      showDobError: false,
      showNationalityError: false,
      custom_fields: {}
    };
  }

  componentDidMount() {
    let custom_fields = this.state.custom_fields;
    for(var i = 0; i < this.props.list.custom_member_fields.length; i++) {
      custom_fields[this.props.list.custom_member_fields[i].id] = this.props.donor.custom_fields[this.props.list.custom_member_fields[i].id];
    }
    this.setState({ custom_fields });

    var dob = this.props.donor.dob;
    if(dob !== '' && dob !== false) {
      var parsedDob = moment(dob).format('YYYY-MM-DD');
      this.setState({ dob: parsedDob });
    }
    var dob_first_child = this.props.donor.dob_first_child;
    if(dob_first_child !== '' && dob_first_child !== false) {
      var parsedDob_first_child = moment(dob_first_child).format('YYYY-MM-DD');
      this.setState({ dob_first_child: parsedDob_first_child });
    }
    var dob_second_child = this.props.donor.dob_second_child;
    if(dob_second_child !== '' && dob_second_child !== false) {
      var parsedDob_second_child = moment(dob_second_child).format('YYYY-MM-DD');
      this.setState({ dob_second_child: parsedDob_second_child });
    }
    var dob_father = this.props.donor.dob_father;
    if(dob_father !== '' && dob_father !== false) {
      var parsedDob_father = moment(dob_father).format('YYYY-MM-DD');
      this.setState({ dob_father: parsedDob_father });
    }
    var dob_mother = this.props.donor.dob_mother;
    if(dob_mother !== '' && dob_mother !== false) {
      var parsedDob_mother = moment(dob_mother).format('YYYY-MM-DD');
      this.setState({ dob_mother: parsedDob_mother });
    }
    var dob_spouse = this.props.donor.dob_spouse;
    if(dob_spouse !== '' && dob_spouse !== false) {
      var parsedDob_spouse = moment(dob_spouse).format('YYYY-MM-DD');
      this.setState({ dob_spouse: parsedDob_spouse });
    }
    var marriage_anniversary = this.props.donor.marriage_anniversary;
    if(marriage_anniversary !== '' && marriage_anniversary !== false) {
      var parsed_marriage_anniversary = moment(marriage_anniversary).format('YYYY-MM-DD');
      this.setState({ marriage_anniversary: parsed_marriage_anniversary });
    }
    this.setState({
      donor: this.props.donor,
      rating: this.props.donor.priority,
      country: this.props.donor.country
    });
  }

  onDonorTypeChange(e) {
    this.setState({ donor_type: e.target.value });
  }

  onDonorTitleChange(e) {
    this.setState({ title: e.target.value });
  }

  onDonorFnameChange(e) {
    if(e.target.value === '') this.setState({ showFirstNameError: true });
    else this.setState({ showFirstNameError: false });
    this.setState({ first_name: e.target.value });
  }

  onDonorLnameChange(e) {
    if(e.target.value === '') this.setState({ showLastNameError: true });
    else this.setState({ showLastNameError: false });
    this.setState({ last_name: e.target.value });
  }

  onDonorPhoneChange(value) {
    if(value === '') this.setState({ showPhoneError: true });
    else this.setState({ showPhoneError: false });
    this.setState({ phone_no: value });
  }

  onDonorEmailChange(e) {
    if(e.target.value === '') this.setState({ showEmailError: true });
    else this.setState({ showEmailError: false });
    this.setState({ email: e.target.value });
  }

  onDonorDobChange(e) {
    if(e.target.value === '') this.setState({ showDobError: true });
    else this.setState({ showDobError: false });
    this.setState({ dob: e.target.value });
  }

  onDonorDobFirstChildChange(e) {
    this.setState({ dob_first_child: e.target.value });
  }

  onDonorDobSecondChildChange(e) {
    this.setState({ dob_second_child: e.target.value });
  }

  onDonorDobFatherChange(e) {
    this.setState({ dob_father: e.target.value });
  }

  onDonorDobMotherChange(e) {
    this.setState({ dob_mother: e.target.value });
  }

  onDonorDobSpouseChange(e) {
    this.setState({ dob_spouse: e.target.value });
  }

  onDonorAnniversaryChange(e) {
    this.setState({ marriage_anniversary: e.target.value });
  }

  onDonorPanChange(e) {
    this.setState({ pan_number: e.target.value });
  }

  onDonorSourceChange(e) {
    this.setState({ donor_source: e.target.value });
  }

  onDonorAddress1Change(e) {
    this.setState({ address1: e.target.value });
  }

  onDonorAddress2Change(e) {
    this.setState({ address2: e.target.value });
  }

  onDonorCityChange(value) {
    this.setState({ city: value });
  }

  onDonorPincodeChange(e) {
    this.setState({ zipcode: e.target.value });
  }

  onDonorOrgNameChange(e) {
    this.setState({ org_name: e.target.value });
  }

  onDonorOrgPositionChange(e) {
    this.setState({ org_position: e.target.value });
  }

  selectNationality = val => {
    if(val === '') this.setState({ showNationalityError: true });
    else this.setState({ showNationalityError: false });
    this.setState({ nationality: val });
  };

  selectCountry = (val) => {
    this.setState({ country: val });
  }

  selectState = (val) => {
    this.setState({ state: val });
  }

  updateGroups(group_ids) {
    this.setState({ group_ids });
  }

  onStarClick = (nextValue, prevValue, name) => {
    this.setState({rating: nextValue});
  }

  addChild = () => {
    this.setState({ impDateCount: this.state.impDateCount + 1 });
  }

  removeChild = (key) => {
    this.setState({ impDateCount: this.state.impDateCount - 1 });
  }

  changeCustomFieldsValue(name, e) {
    let custom_fields = this.state.custom_fields;
    custom_fields[name] = e.target.value;
    this.setState({ custom_fields });
  }

  editDonor = () => {
    let donor_type = this.state.donor_type;
    let title = this.state.title;
    let first_name = this.state.first_name;
    let last_name = this.state.last_name;
    let phone_no = this.state.phone_no;
    let email = this.state.email;
    let dob = this.state.dob;
    let nationality = this.state.nationality;
    let pan_number = this.state.pan_number;
    let donor_source = this.state.donor_source;
    let address1 = this.state.address1;
    let address2 = this.state.address2;
    let country = this.state.country;
    let state = this.state.state;
    let city = this.state.city;
    let zipcode = this.state.zipcode;
    let org_name = this.state.org_name;
    let org_position = this.state.org_position;
    let org_assignee = this.state.org_assignee;
    let group_ids = this.state.group_ids;
    let priority = this.state.rating;
    let dob_first_child = this.state.dob_first_child;
    let dob_second_child = this.state.dob_second_child;
    let dob_father = this.state.dob_father;
    let dob_mother = this.state.dob_mother;
    let dob_spouse = this.state.dob_spouse;
    let marriage_anniversary = this.state.marriage_anniversary;
    let custom_fields = this.state.custom_fields;

    let important_dates = [];

    $('.important-date-modal').each(function() {
      let important_date = {};
      important_date['date'] = $(this).find('input.date')[0].value;
      important_date['details'] = $(this).find('input.details')[0].value;
      if(important_date["date"] !== '' && important_date["details"] !== '') important_dates.push(important_date);
    });

    this.setState({ important_dates });

    if((first_name !== '' && last_name !== '' && phone_no !== '' && email !== '' && dob !== '' && nationality !== '') || (first_name !== '' && last_name !== '' && email !== '')) {

      for(var i = 0; i < this.state.groups.length; i++) {
        if(group_ids.indexOf(this.state.groups[i]['id']) === -1) {
          group_ids.push(this.state.groups[i]['id']);
        }
      }

      let data = {
        donor_type,
        title,
        first_name,
        last_name,
        phone_no,
        email,
        nationality,
        pan_number,
        donor_source,
        address1,
        address2,
        country,
        state,
        city,
        zipcode,
        org_name,
        org_position,
        group_ids,
        priority,
        important_dates,
        custom_fields
      }

      if(dob !== null && dob !== '') data['dob'] = dob;
      if(dob_first_child !== '' && dob_first_child !== "Invalid date") data['dob_first_child'] = dob_first_child;
      if(dob_second_child !== '' && dob_second_child !== "Invalid date") data['dob_second_child'] = dob_second_child;
      if(dob_father !== '' && dob_father !== "Invalid date") data['dob_father'] = dob_father;
      if(dob_mother !== '' && dob_mother !== "Invalid date") data['dob_mother'] = dob_mother;
      if(dob_spouse !== '' && dob_spouse !== "Invalid date") data['dob_spouse'] = dob_spouse;
      if(marriage_anniversary !== '' && marriage_anniversary !== "Invalid date") data['marriage_anniversary'] = marriage_anniversary;
      if(this.state.pledged_amount !== 0) data['pledged_amount'] = this.state.pledged_amount;
      if(this.state.frequency !== '') data['frequency'] = this.state.frequency;

      fetch(process.env.REACT_APP_API_URL + '/donor/' +this.props.donor_id , {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify(data)
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        if(data['status'] === 'ok') {
          this.setState({
            country,
            rating: priority,
            donor_type,
            title,
            first_name,
            last_name,
            phone_no,
            email,
            dob,
            address1,
            address2,
            nationality,
            pan_number,
            donor_source,
            state,
            city,
            zipcode,
            org_name,
            org_position,
            group_ids,
            custom_fields
          }, function() {
            this.props.onEditDonorClose();
          })
        } else if(data['donor'] === 'exists') {
          swal({
            title: 'Sorry!',
            html: '<h4>Donor already exists</h4>',
            type: 'error'
          });
        }
      }.bind(this));
    } else {
      if(first_name === '') this.setState({ showFirstNameError: true });
      if(last_name === '') this.setState({ showLastNameError: true });
      if(phone_no === '') this.setState({ showPhoneError: true });
      if(email === '') this.setState({ showEmailError: true });
      if(dob === '') this.setState({ showDobError: true });
      if(nationality === '') this.setState({ showNationalityError: true });
    }
  }

  changeDonationAmount(e) {
    this.setState({ pledged_amount: e.target.value });
  }

  changeDonationFrequency(e) {
    this.setState({ frequency: e.target.value });
  }

  render () {
    return (
      <div className="page-container edit-donor-page">
        {this.props.showEditDonorModal ? (
          <div>
            <h2 className="modal-header-custom">
              Edit Member
            </h2>
            <Row>
              <form name="existingDonorForm" id="existingDonorForm">
                <Col md={12}>
                  {(() => {
                    if (this.state.showFirstNameError || !this.state.showFirstNameError || this.state.showLastNameError || this.state.showPhoneError || this.state.showEmailError || this.state.showDobError || this.state.showNationalityError) {
                      return <PersonalInformation donor={this.state} ngo_id={this.props.ngo_id}
                        onDonorTypeChange={this.onDonorTypeChange.bind(this)}
                        onDonorTitleChange={this.onDonorTitleChange.bind(this)}
                        onDonorFnameChange={this.onDonorFnameChange.bind(this)}
                        onDonorLnameChange={this.onDonorLnameChange.bind(this)}
                        onDonorPhoneChange={this.onDonorPhoneChange.bind(this)}
                        onDonorEmailChange={this.onDonorEmailChange.bind(this)}
                        onDonorDobChange={this.onDonorDobChange.bind(this)}
                        onDonorPanChange={this.onDonorPanChange.bind(this)}
                        onDonorSourceChange={this.onDonorSourceChange.bind(this)}
                        onDonorAddress1Change={this.onDonorAddress1Change.bind(this)}
                        onDonorAddress2Change={this.onDonorAddress2Change.bind(this)}
                        onDonorCityChange={this.onDonorCityChange.bind(this)}
                        onDonorPincodeChange={this.onDonorPincodeChange.bind(this)}
                        country={this.state.country} nationality={this.state.nationality}
                        state={this.state.state} selectNationality={this.selectNationality}
                        selectCountry={this.selectCountry} selectState={this.selectState}
                        showFirstNameError={this.state.showFirstNameError}
                        showLastNameError={this.state.showLastNameError}
                        showPhoneError={this.state.showPhoneError} onStarClick={this.onStarClick}
                        showEmailError={this.state.showEmailError} list={this.props.list}
                        showDobError={this.state.showDobError} onStarClick={this.onStarClick}
                        showNationalityError={this.state.showNationalityError} />
                    }
                  })()}
                </Col>

                {this.props.ngo.hasOwnProperty('account_type') ? (
                  this.props.ngo.account_type === 'ngo' ? (
                    <Col md={12}>
                      <OrganizationInformation assignee={this.props.assignee}
                        donor={this.state} ngo_id={this.props.ngo_id} list={this.props.list}
                        onDonorOrgNameChange={this.onDonorOrgNameChange.bind(this)}
                        onDonorOrgPositionChange={this.onDonorOrgPositionChange.bind(this)} />
                    </Col>
                  ) : (null)
                ) : (
                  <Col md={12}>
                    <OrganizationInformation assignee={this.props.assignee}
                      donor={this.state} ngo_id={this.props.ngo_id} list={this.props.list}
                      onDonorOrgNameChange={this.onDonorOrgNameChange.bind(this)}
                      onDonorOrgPositionChange={this.onDonorOrgPositionChange.bind(this)} />
                  </Col>
                )}

                <Col md={12}>
                  <OtherInformation ngo_id={this.props.ngo_id} changeDonationAmount={this.changeDonationAmount.bind(this)}
                    frequency={this.state.frequency} changeDonationFrequency={this.changeDonationFrequency.bind(this)} donor={this.state}
                    pledged_amount={this.state.pledged_amount} updateGroups={this.updateGroups.bind(this)} list={this.props.list}
                    changeCustomFieldsValue={this.changeCustomFieldsValue.bind(this)} custom_fields={this.state.custom_fields} />
                </Col>

                <Col md={12}>
                  <ImportantDates addChild={this.addChild.bind(this)} ngo={this.props.ngo}
                    removeChild={this.removeChild.bind(this)} donor={this.state} list={this.props.list}
                    onDonorDobFirstChildChange={this.onDonorDobFirstChildChange.bind(this)}
                    onDonorDobSecondChildChange={this.onDonorDobSecondChildChange.bind(this)}
                    onDonorDobFatherChange={this.onDonorDobFatherChange.bind(this)}
                    onDonorDobMotherChange={this.onDonorDobMotherChange.bind(this)}
                    onDonorDobSpouseChange={this.onDonorDobSpouseChange.bind(this)}
                    onDonorAnniversaryChange={this.onDonorAnniversaryChange.bind(this)} />
                </Col>
              </form>
            </Row>
            <Row className="text-center">
              <Button className="btn btn-danger mr10" onClick={this.props.onEditDonorClose}>Cancel</Button>
              <Button className="btn btn-warning mr10" onClick={this.editDonor.bind(this)}>Submit</Button>
            </Row>
          </div>
        ) : (null)}
      </div>
    );
  }
}
