import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export default class FBTopMenu extends Component {
  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  render() {
    return (
      <div className="fb-top-menu">
        <Row className="fb-full-width">
          <div className="mobile-view-sidenav">
            <span className="side-nav-opener" onClick={this.openNav.bind(this)}>&#9776;</span>
            <div id="mySidenav" className="sidenav">
              <a href="#" className="closebtn" onClick={this.closeNav.bind(this)}>&times;</a>
              <a href="/">Home</a>
              <a href="/fb/dashboard">My Forms</a>
              <a href="/fb/responses">Form Responses</a>
              <a href="/em/settings">Settings</a>
              <a href="#" onClick={this.props.logoutUser}>Logout</a>
            </div>
            <div className="current_user_mobile" onClick={()=>{window.location.pathname = "/fb/settings"}}>
              {this.props.userData["first_name"] !== '' && this.props.userData["last_name"] ? (
                <span>{this.props.userData["first_name"] + ' ' + this.props.userData["last_name"]}</span>
              ) : (null)}
              {this.props.userData["profile_picture"] === "" ? (
                <img alt={this.props.userData["first_name"]} src="/images/user.png" className="img-circle"/>
              ) : (
                <img alt={this.props.userData["first_name"]} src={this.props.userData["profile_picture"]}
                  onError={event => event.target.setAttribute("src", "/images/user.png")}/>
              )}
            </div>
          </div>
          <Col md={12} className="fb-org-additional-buttons">
            <div className="fb-current_user">
              <Link to={"/"} className='fb-links'>
                <span>Home</span>
              </Link>
              <Link to={'/fb/dashboard'} className='fb-links'>
                <span>My Forms</span>
              </Link>
              <Link to={'/fb/responses'} className='fb-links'>
                <span>Form Responses</span>
              </Link>
            </div>
          </Col>
          <div className="fb-right-side-content">
            <Link to={'/fb/settings'} className="loggedin_user">
              {(() => {
                if(this.props.userData['profile_picture'] === '') {
                  return (
                    <img alt={this.props.userData['first_name']} src="/images/user.png" className="img-circle" />
                  );
                } else {
                  return (
                    <img alt={this.props.userData['first_name']} src={this.props.userData['profile_picture']} onError={(event)=>event.target.setAttribute("src","/images/user.png")} />
                  );
                }
              })()}
              {(() => {
                if(this.props.userData['first_name'] !== '' || this.props.userData['last_name'] !== '') {
                  return (
                    <span style={{color: '#fff', width: 'auto'}}>{this.props.userData['first_name'] + ' ' + this.props.userData['last_name']}</span>
                  );
                } else {
                  return (
                    <span style={{color: '#fff', width: 'auto'}}>{this.props.userData['email']}</span>
                  );
                }
              })()}
            </Link>
            <div className="fb-logout-button" onClick={this.props.logoutUser}>
              <span><i className="fa fa-power-off"></i> Logout</span>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}
