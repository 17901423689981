import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import $ from 'jquery';
import swal from "sweetalert2";
import Multiselect from 'react-bootstrap-multiselect';
import EmailEditor from 'react-email-editor';
import TimezonePicker from 'react-timezone';
import { ToastContainer, toast } from 'react-toastify';
import Icon from 'react-icons-kit';
import { ic_visibility } from 'react-icons-kit/md/ic_visibility';
import { ic_email } from 'react-icons-kit/md/ic_email';

import 'react-select/dist/react-select.css';
import "sweetalert2/dist/sweetalert2.min.css";
import 'react-datepicker/dist/react-datepicker.css';

import SingleCampaignPreview from './SingleCampaignPreview';
import AddTestEmailsModal from './AddTestEmailsModal';

var momentTimezone = require('moment-timezone');

const readCookie = require('../cookie.js').readCookie;

const format = 'hh:mm A';

let editor;

export default class EditDripCampaignPage2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 2,
      contentToPreview: null,
      name: '',
      subject: '',
      from_name: '',
      from_email: '',
      campaignSelectedDomain: '',
      ngo_domains: [],
      from_email_domain: '',
      body: '',
      campaign: null,
      drip_campaign: null,
      campaign_id: null,
      drip_campaign_id: null,
      template_id: null,
      startDate: moment(),
      timezone: momentTimezone.tz.guess(),
      scheduleCheck: false,
      campaignFiles: [],
      prevAttachments: [],
      deletedAttachments: [],
      showCampaignNameError: false,
      showSubjectError: false,
      showFromNameError: false,
      showFromEmailError: false,
      showRecipientError: false,
      showBodyError: false,
      disableSend: true,
      disableNext: false,
      unsubscribeStatus: false,
      socialMediaIconStatus: false,
      first_name: '',
      showButtonLoader: '',
      selectedTrigger: '',
      dripTriggers: null,
      expectedRecipients: 0,
      showReplyToError: false,
      selectedTags: [],
      tags: []
    }
    this.handleDateChange = this.handleDateChange.bind(this);
    this.updateCampaign = this.updateCampaign.bind(this);
    this.handleScheduleChange = this.handleScheduleChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleFromNameChange = this.handleFromNameChange.bind(this);
    this.handleFromEmailChange = this.handleFromEmailChange.bind(this);
    this.onCampaignFilesChange = this.onCampaignFilesChange.bind(this);
    this.removeCampaignFiles = this.removeCampaignFiles.bind(this);
    this.getCampaignData = this.getCampaignData.bind(this);
    this.domainChange = this.domainChange.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.saveCampaign = this.saveCampaign.bind(this);
  }

  handleScheduleChange() {
    this.setState({ scheduleCheck: !this.state.scheduleCheck });
  }

  handleTagChange = (e) =>{
    console.log($('select#tagsTo').val())
  this.setState({ selectedTags: $('select#tagsTo').val() });
  }

  checkIfSendNeedsDisabling() {
    if(this.state.showFromEmailError || this.state.showFromNameError || this.state.showSubjectError) {
      this.setState({ disableSend: true });
    } else {
      this.setState({ disableSend: false });
    }

    if(this.state.showFromEmailError || this.state.showFromNameError || this.state.showSubjectError) {
      this.setState({ disableNext: true });
    } else {
      this.setState({ disableNext: false });
    }
  }

  handleFromNameChange(e) {
    if(e.target.value === '') this.setState({ showFromNameError: true }, function() { this.checkIfSendNeedsDisabling() });
    else this.setState({ showFromNameError: false }, function() { this.checkIfSendNeedsDisabling() });
    this.setState({ from_name: e.target.value });
  }

  handleFromEmailChange(e) {
    if(e.target.value === '') this.setState({ showFromEmailError: true }, function() { this.checkIfSendNeedsDisabling() });
    else this.setState({ showFromEmailError: false }, function() { this.checkIfSendNeedsDisabling() });
    this.setState({ from_email: e.target.value });
  }

  handleSubjectChange(e) {
    if(e.target.value === '') this.setState({ showSubjectError: true }, function() { this.checkIfSendNeedsDisabling() });
    else this.setState({ showSubjectError: false }, function() { this.checkIfSendNeedsDisabling() });
    this.setState({ subject: e.target.value });
  }

  handleNameChange(e) {
    let value = e.target.value;
    editor.exportHtml(exportData => {
      let saveHTML = exportData.html;
      let saveJSON = exportData.design;
      this.setState({ body: saveHTML, bodyJSON: saveJSON }, function() {
        if(value === '') this.setState({ showCampaignNameError: true }, function() { this.checkIfSendNeedsDisabling() });
        else this.setState({ showCampaignNameError: false }, function() { this.checkIfSendNeedsDisabling() });
        this.setState({ name: value });
      }.bind(this));
    });
  }

  handleDateChange(date) {
    this.setState({ startDate: date });
  }

  onCampaignFilesChange(e) {
    let files = e.target.files;
    editor.exportHtml(exportData => {
      let saveHTML = exportData.html;
      let saveJSON = exportData.design;
      this.setState({ body: saveHTML, bodyJSON: saveJSON }, function() {
        this.setState({ campaignFiles: files });
      }.bind(this));
    });
  }

  removeCampaignFiles() {
    this.setState({ campaignFiles: [] });
    $('input#addCampaignModalAttachments').val('')
  }

  getCampaignData(campaign) {
    let template_id = campaign['template_data']['template_id'];
    let name = campaign['name'] || '';
    let subject = campaign['subject'] || '';
    let body = campaign['template_data']['body'] || '';
    let bodyJSON = campaign['template_data']['bodyJSON'] || null;
    let from_name = campaign['from_name'] || '';
    let selectedTags = [];
    if(campaign['campaign_tags'].length){
      for(var i=0; i < campaign['campaign_tags'].length; i++ ){
        selectedTags.push(campaign['campaign_tags'][i].campaign_tag_id);
      }
      this.setState({selectedTags});
    }
    let from_email = '';
    if(campaign['from_email'] !== false && campaign['from_email'] !== '') {
      from_email = campaign['from_email'].split('@')[0];
      console.log(from_email)
      fetch(process.env.REACT_APP_API_URL + '/extract-domain', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify({ domain: campaign['from_email'].split('@')[1] })
      }).then((response) => response.json())
      .then((data) => {
        this.setState({ campaignSelectedDomain: campaign['from_email'].split('@')[1], from_email_domain: data.domain });
      });
    }
    let startDate = moment(campaign['scheduled_datetime']);
    let prevAttachments = campaign['attachments'];
    let selectedTrigger = '';
    if(campaign['drip_trigger_id'] !== false) {
    	selectedTrigger = campaign['drip_trigger_id'];
    	fetch(process.env.REACT_APP_API_URL + '/drip-trigger/' + selectedTrigger + '/' + this.props.match.params['drip_campaign_id'] + '/recipients', {
	      method: "GET",
	      headers: {
	        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
	      }
	    }).then(function(response) {
	      return response.json();
	    }).then(function(data) {
	      if(data.recipients === 0) this.setState({ expectedRecipients: data.recipients, showRecipientError: true });
	      else this.setState({ expectedRecipients: data.recipients, showRecipientError: false });
	    }.bind(this));
    }

    fetch(process.env.REACT_APP_API_URL + '/drip-triggers', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
    	let dripTriggers = data.triggers;
	    if(bodyJSON !== null) {
	      this.setState({ template_id, name, subject, body, selectedTags, bodyJSON, from_name, from_email, startDate, prevAttachments, dripTriggers, selectedTrigger }, function() {
	        this.setState({ drip_campaign_id: this.props.match.params['drip_campaign_id'], campaign_id: this.props.match.params['name'] });
	      }.bind(this));
	    } else {
	      this.setState({ template_id, name, subject, body, selectedTags, from_name, from_email, startDate, prevAttachments, dripTriggers, selectedTrigger }, function() {
	        this.setState({ drip_campaign_id: this.props.match.params['drip_campaign_id'], campaign_id: this.props.match.params['name'] });
	      }.bind(this));
	    }
	  }.bind(this));
  }

  updateCampaign(type) {
    if(type === 'save') this.setState({ showButtonLoader: 'savePage2' });
    else {
      if(this.state.scheduleCheck) this.setState({ showButtonLoader: 'scheduleMail' });
      else this.setState({ showButtonLoader: 'sendMail' });
    }

    let name = this.state.name;
    let subject = this.state.subject;
    let from_name = this.state.from_name;
    let from_email = this.state.from_email;
    let sender_domain = this.state.campaignSelectedDomain;

    let campaign_id = this.state.campaign_id;

    let drip_trigger_id = this.state.selectedTrigger;

    let data = {};
    data['subject'] = subject;
    data['from_name'] = from_name;
    data['from_email'] = from_email;
    data['sender_domain'] = sender_domain;
    data['drip_trigger_id'] = drip_trigger_id;
    data['campaign_tags'] = this.state.selectedTags;

    if(type === 'send') data['send_now'] = true;
    else data['send_now'] = false;

    if(this.state.scheduleCheck) {
      var x = moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss');
      data['scheduled_datetime'] = moment(x).toISOString();
      data['is_scheduled'] = true;
    }

    if((subject !== '' && from_name !== '' && from_email !== '' && this.state.selectedTrigger !== '' && this.state.expectedRecipients !== 0) || (type === 'save')) {
      fetch(process.env.REACT_APP_API_URL + '/campaigns/' + this.props.ngo.ngo_id + '/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify({ campaign_id, data_for_update: data })
      }).then((response) => response.json())
      .then((data) => {
        if(type === 'save') {
          this.notifySuccess('Your Campaign has been saved successfully.');
          this.setState({ showButtonLoader: '' });
        } else if (type === 'send') {
          fetch(process.env.REACT_APP_API_URL + '/cron_wars/campaigns/run_now?id=' + campaign_id, {
            method: "GET",
            headers: {
              'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
            }
          }).then(function(response) {
            return response.json();
          }).then(function(data) {
            if(this.state.scheduleCheck) this.notifySuccess('Your Campaign has been scheduled successfully.');
            else this.notifySuccess('Your Campaign has been sent successfully.');
            this.setState({ showButtonLoader: '' });
            this.onAddCampaignClose();
          }.bind(this));
        }
      });
    }
  }

  onAddCampaignClose() {
    setTimeout(function(){ window.location.pathname = '/dm/campaigns' }, 500);
  }

  componentWillMount() {
    let first_name = JSON.parse(readCookie('userData')).first_name;

    let ngo_domains = this.props.ngo.domains;
    // for(var i = 0; i < ngo_domains.length; i++) {
    //   var ngo_domain = ngo_domains[i];
    //   var split = ngo_domain.split('.');
    //   ngo_domains[i] = split[split.length - 2] + '.' + split[split.length - 1];
    // }

    this.setState({ first_name, ngo_domains });

    fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/' + this.props.match.params['name'], {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data['campaign']['status'] !== 'Sent' && data['campaign']['status'] !== 'Ongoing') {
        let selectedTags = [];
        if(data['campaign']['campaign_tags'].length){
          for(var i = 0; i < data['campaign']['campaign_tags'].length; i++){
            selectedTags.push(data['campaign']['campaign_tags'][i].campaign_tag_id);
          }
        }
        fetch(process.env.REACT_APP_API_URL + '/all_campaign_tags/unsubscriber/' + this.props.ngo.ngo_id, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          }
        }).then(res => res.json())
        .then(data => {
          if(data.status === 'ok'){
            let dataObj = data.data;
            if(dataObj.length) {
              let tags = [];
              for(var i = 0; i < dataObj.length; i++){
                let tagsObj = {};
                tagsObj['value'] = dataObj[i].campaign_tag_id;
                tagsObj['label'] = dataObj[i].campaign_tag_name;
                if(selectedTags.indexOf(dataObj[i].campaign_tag_id) > -1){
                  tagsObj['selected'] = true;
                }
                tags.push(tagsObj);
              }
              this.setState({ tags });
            }
          }
        });
        this.setState({ campaign: data['campaign'], selectedTags });
        this.getCampaignData(data['campaign']);
      } else {
        window.location.pathname = '/dm/campaigns';
      }
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/' + this.props.match.params['drip_campaign_id'], {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data['campaign']['status'] === 'Sent') {
        this.setState({ drip_campaign: data['campaign'] });
      } else {
        window.location.pathname = '/dm/campaigns';
      }
    }.bind(this));
  }

  openAttachment(attachment) {
    window.open(attachment, '_blank');
  }

  deleteAttachment(e, attachment) {
      if (!e) e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();

      swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Delete!',
          cancelButtonText: 'No, Cancel!',
          confirmButtonClass: 'btn',
          cancelButtonClass: 'btn',
      }).then(function (result) {
          if(result) {
              var prevAttachments = this.state.prevAttachments;
              var deletedAttachments = this.state.deletedAttachments;

              deletedAttachments.push(attachment);

              for (var i = prevAttachments.length-1; i >= 0; i--) {
                  if (prevAttachments[i] === attachment) {
                      prevAttachments.splice(i, 1);
                  }
              }

              this.setState({ deletedAttachments, prevAttachments });
          }
      });
  }

  sendTestMail(html) {
    this.setState({ showTestEmailModal: true });
  }

  saveCampaign(page, type) {
    if(type === 'next') this.setState({ showButtonLoader: 'nextPage' });
    else this.setState({ showButtonLoader: 'savePage1' });

    editor.exportHtml(exportData => {
      let saveHTML = exportData.html;
      let saveJSON = exportData.design;

      let name = this.state.name;

      let campaign_id = this.state.campaign_id;
      let template_id = this.state.template_id;
      let deletedAttachments = this.state.deletedAttachments;

      let socialMediaIconsHTML = '';
      if(this.state.socialMediaIconStatus) {
        socialMediaIconsHTML += `<div style="display: block; text-align: center; margin: 15px 0;">`;
        if(this.props.ngo.ngo_website_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_website_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/website.png" height="36" width="36" />
                                   </a>`;
        }
        if(this.props.ngo.ngo_blog_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_blog_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/blog.png" height="36" width="36" />
                                   </a>`;
        }
        if(this.props.ngo.ngo_fb_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_fb_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/facebook.png" height="36" width="36" />
                                   </a>`;
        }
        if(this.props.ngo.ngo_twitter_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_twitter_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/twitter.png" height="36" width="36" />
                                   </a>`;
        }
        if(this.props.ngo.ngo_linkedin_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_linkedin_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/linkedin.png" height="36" width="36" />
                                   </a>`;
        }
        if(this.props.ngo.ngo_youtube_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_youtube_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/youtube.png" height="36" width="36" />
                                   </a>`;
        }
        if(this.props.ngo.ngo_instagram_url !== '') {
          socialMediaIconsHTML += `<a href="` + this.props.ngo.ngo_instagram_url + `" target="_blank" style="margin: 5px; text-decoration: none;">
                                    <img src="https://assets.letsendorse.com/sahaj/social_media_icons/instagram.png" height="36" width="36" />
                                   </a>`;
        }
        socialMediaIconsHTML += `</div>`;
        saveHTML = saveHTML.replace('</body>', socialMediaIconsHTML + '</body>');
      }

      let unsubscribeHTML = '<div style="text-align: center;">If you would prefer not receiving our emails, please <a href="{{unsubscribe_url}}">click here</a> to unsubscribe.</div>';
      if(this.state.unsubscribeStatus) {
        saveHTML = saveHTML.replace('</body>', unsubscribeHTML + '</body>');
      }

      let data = {};
      data['name'] = name;
      data['body'] = saveHTML;
      data['bodyJSON'] = saveJSON;
      data['add_unsubscribe'] = this.state.unsubscribeStatus;
      data['campaign_tags'] = this.state.selectedTags;

      var campaignFiles = $('input#addCampaignModalAttachments');

      if(campaignFiles[0].files.length > 0) {
        data['is_awaiting_attachment'] = true;
      }

      if(name !== '' && saveHTML !== '') {
        fetch(process.env.REACT_APP_API_URL + '/campaigns/' + this.props.ngo.ngo_id + '/update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify({ campaign_id, template_id, data_for_update: data })
        }).then((response) => response.json())
        .then((data) => {
          if(deletedAttachments.length > 0) {
            fetch(process.env.REACT_APP_API_URL + '/campaigns/' + this.props.ngo.ngo_id + '/remove_attachment/' + campaign_id, {
              method: 'POST',
              headers: {
                'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
              },
              body: JSON.stringify({ attachments: deletedAttachments })
            }).then((response) => response.json())
            .then(function(data) {
            });
          }
          if(campaignFiles[0].files.length > 0) {
            for(var i = 0; i < campaignFiles[0].files.length; i++) {

              var files = new FormData();
              files.append('attachment', campaignFiles[0].files[i]);

              fetch(process.env.REACT_APP_API_URL + '/campaigns/' + this.props.ngo.ngo_id + '/add_attachment/' + campaign_id, {
                method: 'POST',
                headers: {
                  'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
                },
                body: files
              }).then((response) => response.json())
              .then(function(data) {
                this.notifySuccess('Your Campaign has been updated successfully.');
                if(type === 'save') this.setState({ showButtonLoader: '' });
                else this.setState({ currentPage: page, showButtonLoader: '' }, function() {
                  editor = undefined;
                });
              }.bind(this));
            }
          } else {
            this.notifySuccess('Your Campaign has been updated successfully.');
            if(type === 'save') this.setState({ showButtonLoader: '' });
            else this.setState({ currentPage: page, showButtonLoader: '' }, function() {
              editor = undefined;
            });
          }
        });
      } else {
        if(name === '') this.setState({ showCampaignNameError: true });
        if(saveHTML === '') this.setState({ showBodyError: true });
      }
    });
  }

  notifySuccess = (text) => toast.success(text);

  disableSend() {
    if(this.state.subject !== '' && this.state.from_name !== '' && this.state.from_email !== '' && this.state.selectedTrigger !== '' && this.state.expectedRecipients !== 0) {
      return false;
    } else return true;
  }

  disableSendTest() {
    if(this.state.name !== '' && this.state.body !== '' && this.state.subject !== '' && this.state.from_name !== '' && this.state.from_email !== '') {
      return false;
    } else return true;
  }

  changePage(page) {
    if(page === 2 && !this.state.disableNext) {
      this.saveCampaign(page, 'next');
    } else if (page === 2 && this.state.disableNext) {
      editor.exportHtml(exportData => {
        let saveHTML = exportData.html;
        if(this.state.name === '') this.setState({ showCampaignNameError: true });
        if(saveHTML === '') this.setState({ showBodyError: true });
      });
    } else if (page === 1) {
      window.location.pathname = '/dm/campaigns/' + this.props.match.params['name'] + '/drip/' + this.props.match.params['drip_campaign_id'];
    }
  }

  onLoad() {
    if (editor !== undefined && editor !== null) {
      editor.loadDesign(this.state.bodyJSON);
    } else {
      setTimeout (() => editor.loadDesign(this.state.bodyJSON), 3000);
    }
  }

  handleTimezoneChange(timezone) {
    this.setState({ startDate: momentTimezone.tz(this.state.startDate, timezone).utc(), timezone });
  }

  changeUnsubscribeStatus() {
    editor.exportHtml(exportData => {
      let saveHTML = exportData.html;
      let saveJSON = exportData.design;
      this.setState({ body: saveHTML, bodyJSON: saveJSON }, function() {
        this.setState({ unsubscribeStatus: !this.state.unsubscribeStatus });
      }.bind(this));
    });
  }

  changeSocialMediaIconStatus() {
    editor.exportHtml(exportData => {
      let saveHTML = exportData.html;
      let saveJSON = exportData.design;
      this.setState({ body: saveHTML, bodyJSON: saveJSON }, function() {
        this.setState({ socialMediaIconStatus: !this.state.socialMediaIconStatus });
      }.bind(this));
    });
  }

  onSelectedTriggerChange(e) {
  	let selectedTrigger = e.target.value;
  	fetch(process.env.REACT_APP_API_URL + '/drip-trigger/' + selectedTrigger + '/' + this.props.match.params['drip_campaign_id'] + '/recipients', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.recipients === 0) this.setState({ selectedTrigger, expectedRecipients: data.recipients, showRecipientError: true });
      else this.setState({ selectedTrigger, expectedRecipients: data.recipients, showRecipientError: false });
    }.bind(this));
  }

  showCampaignPreview() {
    if(editor !== undefined) {
      editor.exportHtml(exportData => {
        let saveHTML = exportData.html;
        let saveJSON = exportData.design;
        this.setState({ body: saveHTML, bodyJSON: saveJSON }, function() {
          this.setState({ contentToPreview: saveHTML });
        }.bind(this));
      });
    } else if(this.state.body !== '') {
      this.setState({ contentToPreview: this.state.body });
    }
  }

  hideCampaignPreview() {
    this.setState({ contentToPreview: null });
  }

  closeTestEmailModal() {
    this.setState({ showTestEmailModal: false });
  }

  submitTestEmailModal(test_emails) {
    this.closeTestEmailModal(this);
    let name = this.state.name;
    let subject = this.state.subject;
    let from_name = this.state.from_name;
    let from_email = this.state.from_email;
    let sender_domain = this.state.campaignSelectedDomain;

    let campaign_id = this.state.campaign_id;

    let drip_trigger_id = this.state.selectedTrigger;

    let data = {};
    data['campaign_tags'] = this.state.selectedTags;
    if(subject !== '') data['subject'] = subject;
    if(from_name !== '') data['from_name'] = from_name;
    if(from_email !== '') data['from_email'] = from_email;
    if(sender_domain !== '') data['sender_domain'] = sender_domain;
    if(drip_trigger_id !== '') data['drip_trigger_id'] = drip_trigger_id;

    if(this.state.scheduleCheck) {
      var x = moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss');
      data['scheduled_datetime'] = moment(x).toISOString();
      data['is_scheduled'] = true;
    }

    if(name !== '' && subject !== '' && from_name !== '' && from_email !== '') {
      fetch(process.env.REACT_APP_API_URL + '/campaigns/' + this.props.ngo.ngo_id + '/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify({ campaign_id, data_for_update: data })
      }).then((response) => response.json())
      .then((data) => {
        this.notifySuccess('Your Campaign has been saved successfully.');
        fetch(process.env.REACT_APP_API_URL + '/campaigns/' + this.props.ngo.ngo_id + '/send-test/' + campaign_id, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify({ test_emails })
        }).then((response) => response.json())
        .then(function(data) {
          if(data.status === 'ok') this.notifySuccess('Your Test Email has been sent successfully.');
        }.bind(this));
      });
    }
  }

  domainChange(e) {
    var domain = e.target.value;
    fetch(process.env.REACT_APP_API_URL + '/extract-domain', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify({ domain })
    }).then((response) => response.json())
    .then((data) => {
      this.setState({ campaignSelectedDomain: domain, from_email_domain: data.domain });
    });
  }

  render() {
    let options = {
      projectId: process.env.REACT_APP_UNLAYER_PROJECT_ID || 433,
      mergeTags: [
        {name: "First Name", value: "{{first_name}}"},
        {name: "Last Name", value: "{{last_name}}"},
        {name: "Full Name", value: "{{full_name}}"}
      ]
    };
    let backUrl = '/dm/campaigns';

    if(this.state.campaign_id !== null && this.state.drip_campaign_id !== null) {
      return (
        <div className={this.state.contentToPreview !== null ? "page-container absolute" : "page-container"}>
          {this.state.showTestEmailModal ? (null) : (
            <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
          )}
          {this.state.showTestEmailModal ? (
            <div>
              <AddTestEmailsModal showTestEmailModal={this.state.showTestEmailModal}
                submitTestEmailModal={this.submitTestEmailModal.bind(this)}
                closeTestEmailModal={this.closeTestEmailModal.bind(this)} />
            </div>
          ) : (null)}
          {this.state.contentToPreview !== null ? (
            <SingleCampaignPreview contentToPreview={this.state.contentToPreview} hideCampaignPreview={this.hideCampaignPreview.bind(this)} />
          ) : (null)}
          <div className="panel">
            <button className="btn preview-container" onClick={this.showCampaignPreview.bind(this)}>
              <Icon size={20} icon={ic_visibility} />
              Preview
            </button>
            {this.state.currentPage !== 1 ? (
              <button className="btn test-email-container" onClick={this.sendTestMail.bind(this)} disabled={this.disableSendTest(this)}>
                <Icon size={20} icon={ic_email} />
                Send Test Email
              </button>
            ) : (null)}
          </div>
          <div>
            <span className="back">
              <Link to={backUrl} className="back-btn"><i className="fa fa-chevron-left"></i> Go to All campaigns</Link>
            </span>
          </div>
          <h2 className="modal-header-custom">
	          Edit Drip
	        </h2>
          <Row>
          	<Col className="mb10 mt10" md={12}>
          		<h3>
          			{'For Campaign: ' + this.state.drip_campaign.name + ' delivered to '}
          			{this.state.drip_campaign.overview_stats ? this.state.drip_campaign.overview_stats.total.delivered : 0}
          			{' recipients'}
          		</h3>
          	</Col>
            <Col className="col-md-offset-1 text-center" md={10}>
              <div className="legend">
                <div>Legend: </div>
                <div><span className="tag mr5">{'{{'}<span className="tagname">first_name</span>{'}} '}</span>=&gt; Jon<span className="pipe"></span><span className="tag mr5">{'{{'}<span className="tagname">last_name</span>{'}} '}</span>=&gt; Snow<span className="pipe"></span><span className="tag mr5">{'{{'}<span className="tagname">full_name</span>{'}} '}</span>=&gt; Jon Snow</div>
              </div>
            </Col>
          </Row>
          <Row className="mb10">
            <Col md={12}>
              <form className="addCampaignForm" name="addCampaignForm" ref="vForm">
                {this.state.currentPage === 1 ? (
                  <Row>
                    <Col className="form-group no-pad-left" md={12}>
                      <label className="control-label is-imp"> Give a title to your Campaign: </label>
                      <input type="text" className="form-control" id="campaign_name" name="campaign_name" value={this.state.name}
                        placeholder="Name of the campaign" onChange={this.handleNameChange} required />
                      <span hidden={!this.state.showCampaignNameError}><span className="required-span">Please enter a valid Campaign Name.</span></span>
                    </Col>

                    <Col className="form-group no-pad-left" md={12}>
                      <label className="control-label is-imp"> Design your Campaign: </label>
                      {(() => {
                        if (this.state.body !== '' && this.state.bodyJSON !== undefined && this.state.bodyJSON !== null) {
                          return (
                            <EmailEditor ref={thisEditor => editor = thisEditor} onLoad={this.state.bodyJSON ? this.onLoad() : (null)} options={options} />
                          );
                        } else {
                          return (
                            <div>
                              <EmailEditor ref={thisEditor => editor = thisEditor} options={options} />
                            </div>
                          );
                        }
                      })()}
                      <span hidden={!this.state.showBodyError}><span className="required-span">Please enter a valid Email body.</span></span>
                    </Col>

                    <Col md={4} className="form-group">
                      <label className="control-label" style={{ display: 'flex' }}>
                        <input className="form-control mr5" type="checkbox" checked={this.state.unsubscribeStatus}
                          onChange={this.changeUnsubscribeStatus.bind(this)} />
                        Add an Unsubscribe link to Email
                      </label>
                    </Col>

                    <Col md={4} className="form-group">
                      <label className="control-label" style={{ display: 'flex' }}>
                        <input className="form-control mr5" type="checkbox" checked={this.state.socialMediaIconStatus}
                          onChange={this.changeSocialMediaIconStatus.bind(this)} />
                        Add Social Media Icons in footer
                      </label>
                    </Col>

                    <Col md={4} className="form-group tags">
                      <label className="control-label" style={{ display: 'flex' }}>
                        Add tags
                      </label>
                      <Multiselect id="tagsTo" onChange={this.handleTagChange} enableCaseInsensitiveFiltering
                        buttonWidth={'100%'} data={this.state.tags} multiple enableClickableOptGroups enableCollapsibleOptGroups numberDisplayed={3}
                        includeSelectAllOption enableFiltering onSelectAll={this.handleTagChange} onDeselectAll={this.handleTagChange} />
                    </Col>

                    <Col className="form-group no-pad-left" md={12}>
                      <label className="control-label"> Attachments: </label>
                      {(() => {
                        if (this.state.prevAttachments.length) {
                          return (
                            <Col className="campaignDetailsElement attachments">
                              {this.state.prevAttachments.map((attachment, index) => {
                                return (
                                  <div className="attachment" key={index} onClick={() => {this.openAttachment(attachment)}}>
                                    Attachment {index+1}
                                    <div onClick={(e) => {this.deleteAttachment(e, attachment)}}>x</div>
                                  </div>
                                );
                              })}
                            </Col>
                          );
                        }
                      })()}
                      <div>
                        <input type="file" className="form-control col-md-11" id="addCampaignModalAttachments"
                          name="attachments" multiple required onChange={this.onCampaignFilesChange} />
                        <span className="clearAttachments col-md-1" onClick={this.removeCampaignFiles}>X</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col className="form-group no-pad-left" md={12}>
                      <label className="control-label is-imp"> Subject: </label>
                      <input type="text" className="form-control" id="email_subject" name="email_subject" value={this.state.subject}
                        placeholder="Subject of the email" onChange={this.handleSubjectChange} required />
                      <span hidden={!this.state.showSubjectError}><span className="required-span">Please enter a valid Subject.</span></span>
                    </Col>

                    <Col className="form-group no-pad-left" md={4}>
                      <label className="control-label is-imp"> From Name: </label>
                      <input type="text" className="form-control" id="name_from"
                        name="name_from" placeholder={this.state.first_name + ' from ' + this.props.ngo.ngo_name}
                        required value={this.state.from_name} onChange={this.handleFromNameChange} />
                      <span hidden={!this.state.showFromNameError}><span className="required-span">Please enter a valid Name.</span></span>
                    </Col>

                    <Col className="form-group no-pad-left" md={8}>
                      <label className="control-label is-imp"> From Email: </label>
                      <span className="from-email-span">
                        <input type="text" className="form-control" id="email_from"
                          name="email_from" placeholder="ngo" required
                          value={this.state.from_email} onChange={this.handleFromEmailChange} />
                        <label>{'@'}
                          <select id="campaignSelectedDomain" value={this.state.campaignSelectedDomain} onChange={this.domainChange}>
                            {this.props.ngo.domains.map(function(domain, i) {
                              if(i === 0) return <option value={domain} key={i}>{domain}</option>
                              else return <option value={domain} key={i}>{domain}</option>
                            })}
                          </select>
                        </label>
                        <div className="from-email-note-container">
                          {this.state.from_email !== '' && this.state.from_email_domain !== '' ? (
                            <note style={{ fontSize: '12px', color: '#ef5a20', display: 'flex', alignItems: 'center' }}>
                              Your final from email will be {this.state.from_email}@{this.state.from_email_domain}
                            </note>
                          ) : (null)}
                          <note style={{ fontSize: '12px', color: '#ef5a20', display: 'flex', alignItems: 'center' }}>
                            To Add another domain, contact support@letsendorse.com
                          </note>
                        </div>
                      </span>
                      <span hidden={!this.state.showFromEmailError}><span className="required-span">Please enter a valid Email.</span></span>
                    </Col>

                    <Col className="form-group no-pad-left" md={12}>
                      <label className="control-label is-imp"> Trigger: </label>

                      <Col md={6} className="no-pad-left">
                        <select className="form-control" style={{ borderColor: '#ccc', height: '36px' }}
                        	onChange={this.onSelectedTriggerChange.bind(this)} defaultValue={this.state.selectedTrigger}>
                          <option value="" selected disabled>Select a trigger</option>
                          {this.state.dripTriggers.map(function(trigger, index) {
                            return <option value={trigger.id} key={index}>{trigger.name}</option>
                          })}
                        </select>
                        <span hidden={!this.state.showRecipientError}><span className="required-span">Please select another trigger as filtered Recipients are 0.</span></span>
                      </Col>

                      {this.state.selectedTrigger !== '' ? (
                        <Col md={3} className="pull-right">
                          {'Expected Recipients: ' + this.state.expectedRecipients}
                        </Col>
                      ) : (null)}
                    </Col>

                    <Col className="form-group no-pad-left" md={12}>
                      <label className="control-label"> Schedule: </label>
                      <div className="ondisplay">
                        <section>
                          <div className="slideThree">
                            <input type="checkbox" id="schedule" name="check"
                            className="schedule_check" checked={this.state.scheduleCheck} onChange={this.handleScheduleChange} />
                            <label htmlFor="schedule"></label>
                          </div>
                        </section>
                      </div>
                      <Col className="form-group no-pad-left" md={3} hidden={!this.state.scheduleCheck}>
                        <DatePicker className="schedule_date" selected={this.state.startDate} timeIntervals={15}
                          onChange={this.handleDateChange} minDate={moment()} isClearable={true} minTime={moment()}
                          maxTime={moment().hours(23).minutes(45)} showTimeSelect fixedHeight
                          showYearDropdown scrollableYearDropdown showMonthDropdown dateFormat="LLL" />
                      </Col>
                      <Col className="form-group no-pad-right" md={4} hidden={!this.state.scheduleCheck}>
                        <TimezonePicker value={this.state.timezone} onChange={this.handleTimezoneChange.bind(this)}
                          inputProps={{ placeholder: 'Select Timezone...', name: 'timezone' }} />
                      </Col>
                    </Col>
                  </Row>
                )}
              </form>
            </Col>
          </Row>
          <Row className="text-center">
            {this.state.currentPage === 1 ? (
              <Button className="btn btn-danger mr10" onClick={this.onAddCampaignClose}>
                Discard
                <span className="glyphicon glyphicon-remove"></span>
              </Button>
            ) : (null)}
            {this.state.currentPage !== 1 ? (
              <Button className="btn btn-danger mr10" onClick={this.changePage.bind(this, 1)}>
                <span className="glyphicon glyphicon-chevron-left"></span>
                Back To Editing
              </Button>
            ) : (null)}
            {this.state.currentPage === 1 ? (
              <Button className="btn btn-warning mr10" disabled={this.state.disableNext} onClick={this.saveCampaign.bind(this, 2, 'save')}>
                Save Changes
                {this.state.showButtonLoader === 'savePage1' ? (
                  <span><i className="fa fa-circle-o-notch fa-spin"></i></span>
                ) : (
                  <span className="glyphicon glyphicon-saved"></span>
                )}
              </Button>
            ) : (null)}
            {this.state.currentPage !== 1 ? (
              <Button className="btn btn-warning mr10" id="submit_donation" onClick={this.updateCampaign.bind(this, 'save')}>
                Save Changes
                {this.state.showButtonLoader === 'savePage2' ? (
                  <span><i className="fa fa-circle-o-notch fa-spin"></i></span>
                ) : (
                  <span className="glyphicon glyphicon-saved"></span>
                )}
              </Button>
            ) : (null)}
            {(() => {
              if(this.state.currentPage !== 1) {
                if(!this.state.scheduleCheck) {
                  return (
                    <Button disabled={this.disableSend(this)} className="btn btn-warning mr10" id="submit_donation" onClick={this.updateCampaign.bind(this, 'send')}>
                      Send Now
                      {this.state.showButtonLoader === 'sendMail' ? (
                        <span><i className="fa fa-circle-o-notch fa-spin"></i></span>
                      ) : (
                        <span className="glyphicon glyphicon-send"></span>
                      )}
                    </Button>
                  );
                } else {
                  return (
                    <Button disabled={this.disableSend(this)} className="btn btn-warning mr10" id="submit_donation" onClick={this.updateCampaign.bind(this, 'send')}>
                      Schedule this Campaign
                      {this.state.showButtonLoader === 'scheduleMail' ? (
                        <span><i className="fa fa-circle-o-notch fa-spin"></i></span>
                      ) : (
                        <span className="glyphicon glyphicon-time"></span>
                      )}
                    </Button>
                  );
                }
              } else {
                return (
                  <Button className="btn btn-primary mr10" onClick={this.changePage.bind(this, 2)}>
                    Next
                    {this.state.showButtonLoader === 'nextPage' ? (
                      <span><i className="fa fa-circle-o-notch fa-spin"></i></span>
                    ) : (
                      <span className="glyphicon glyphicon-chevron-right"></span>
                    )}
                  </Button>
                )
              }
            })()}
          </Row>
        </div>
      );
    } else {
      return (
        <div className="page-container"></div>
      )
    }
  }
}