import React, { Component } from 'react';
import $ from 'jquery';
import swal from 'sweetalert2';
import ReactPasswordStrength from 'react-password-strength';

import 'sweetalert2/dist/sweetalert2.min.css';

export default class ChangePasswordLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      password: '',
      passwordData: null,
      confirmPassword: '',
      invalidError: false,
      submittedPassword: false
    }
  }

  componentDidMount() {
    this.setLoginForm();
    console.log(this.props)
  }

  change(e){
    this.setState({ error: false, confirmPassword: e.target.value});
    console.log(this.props.match.params.token)
  }

  passwordSubmit(e) {
    e.preventDefault();
    let password = this.state.password;
    let confirmPassword = this.state.confirmPassword;
    let passwordData = this.state.passwordData;
    let token = this.props.match.params.token;
    if(passwordData !== null) {
      if(password === confirmPassword && passwordData.isValid) {
        fetch(process.env.REACT_APP_API_URL + '/reset-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            password: password,
            token: token
          })
        }).then((response) => response.json())
        .then(function(data) {
          if(data['status'] !== 'ok') {
            swal('Oops...',
            'Something went wrong!',
            'error');
          } else if(data.status === 'ok') {
            this.setState({ submittedPassword: true });
            swal({
              title: 'Password saved!',
              html: '<h4>You have successfully updated your password</h4>',
              type: 'success'
            });
            setTimeout(function () {
              this.notifySuccess('Your password has been updated successfully.');
            }.bind(this), 1500);
            window.location.pathname = '/';
          }
        }.bind(this));
      } else {
        if(password !== confirmPassword) this.setState({ error: true });
        if(!passwordData.isValid) this.setState({ invalidError: true });
      }
    } else {
      this.setState({ invalidError: true });
    }
  }

  setLoginForm() {
    $(document).ready(function () {
      $('.form').find('input, textarea').on('keyup blur focus', function (e) {
        var $this = $(this), label = $this.prev('label');
        if ($(this).hasClass('wrong')) $(this).removeClass('wrong');
        if (e.type === 'keyup') {
          if ($this.val() === '') label.removeClass('highlight');
          else label.addClass('active highlight');
        } else if (e.type === 'blur') {
          if ($this.val() === '') label.removeClass('active highlight');
          else label.removeClass('highlight');
        } else if (e.type === 'focus') {
          label.addClass('active highlight');
        }
      });

      $('body').click();

      if ($('input#password').val() !== '') {
        if (!$('input#password').siblings('label').hasClass('active')) $('input#password').siblings('label').addClass('active');
      }
      if ($('input#confirmPassword').val() !== '') {
        if (!$('input#confirmPassword').siblings('label').hasClass('active')) $('input#confirmPassword').siblings('label').addClass('active');
      }
    });
  }

  changeNewPassword(passwordData){
    this.setState({ passwordData, password: passwordData.password, invalidError: false, error: false });
  }

  render() {
    return (
      <div id="change-password-container">
        <div className="sahaj-front">
          <div id="sahaj-heading">Sahaj</div>
          <div id="sahaj-quote">Build, Nurture and Strengthen your valuable relationships</div>
          <div id="points">
            <div className="point">
              <div className="icon"><i className="fa fa-2x fa-cog" aria-hidden="true"></i></div>
              <div className="heading">Instant set-up</div>
              <div className="desc">Sign-up and get going.</div>
            </div>
            <div className="point">
              <div className="icon"><i className="fa fa-2x fa-thumbs-up" aria-hidden="true"></i></div>
              <div className="heading">Friendly to use</div>
              <div className="desc">Easy navigation, neat interfaces, intuitive.</div>
            </div>
            <div className="point">
              <div className="icon"><i className="fa fa-2x fa-inr" aria-hidden="true"></i></div>
              <div className="heading">Affordable</div>
              <div className="desc">Simple pricing plans, no hidden costs.</div>
            </div>
          </div>
          <div id="sahaj-quote-small">Start transforming the way you manage data, relationships and communications from today!</div>
        </div>
        <div className="form">
          <div className="tab-content">
            <div id="login">
              <h1>Reset Password!</h1>
              <form id="login-form" onSubmit={this.passwordSubmit.bind(this)}>
                <ReactPasswordStrength minLength={4} minScore={1}
                  className="new-password"
                  scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                  changeCallback={this.changeNewPassword.bind(this)}
                  inputProps={{ name: "password_input", autoComplete: "off", placeholder: "Enter New Password", className: "form-control" }}
                />
                <span hidden={!this.state.invalidError}><span className="required-span"> Invalid Password! </span></span>
                <div className="field-wrap">
                  <label> Confirm New Password </label>
                  <input id="confirmPassword" type="password" required autoComplete="off" onChange={this.change.bind(this)} />
                </div>
                {this.state.error === true ? (
                  <div className="show-error">
                    <span>Password did not match. Try again!</span>
                  </div>
                ) : (null)}
                <button className="button button-block">
                  <span id="login-span">Submit</span>
                  <i className="fa fa-check" hidden={!this.state.submittedPassword}></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
