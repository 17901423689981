import React, { Component } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import moment from 'moment';

import IMPartnershipsSidebar from './IMPartnershipsSidebar';

const readCookie = require('../cookie.js').readCookie;

export default class IMProjectsSent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			corporateData: [],
			corporates: [],
			invitationData: [],
			addProjectFormVisibility: false,
			selectedCorporate: '',
			selectedProject: '',
			view: "project",
			projects: [],
			showMessageModal: false,
			corporateMessage:'',
			projectMessage:'',
			name:"",
			invitedBy:"",
			companyName:"",
			invitedOn: "",
			subject:"",
			indicators:[]
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/project?status=Submitted', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				if (data.status === 'ok') {
					this.setState({ projects: data.data });
				}
			}.bind(this));

		fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/corporates-for-project-invite', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				if (data.status === 'ok') {
					this.setState({ corporates: data.data });
				}
			}.bind(this));

		this.refreshData(this);
	}

	updateChange(name, e) {
		this.setState({ [name]: e.target.value });
	}

	showAddProject() {
		this.setState({ addProjectFormVisibility: true });
	}

	cancelAddProject() {
		this.setState({ selectedCorporate: '', selectedProject: '', addProjectFormVisibility: false });
	}

	sendProject() {
		if(this.state.selectedCorporate !== "" && this.state.selectedCorporate !== ""){
			let project = this.state.selectedProject;
			let corporate = this.state.selectedCorporate;
			let data = {};
			data['invitations'] = [corporate];
			fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + project + '/assign-to-corporates', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				},
				body: JSON.stringify(data)
			}).then((response) => response.json())
			.then(function (data) {
				if (data['status'] === 'ok') {
					this.cancelAddProject(this);
					this.notifySuccess('Project Successfully submitted.');
					this.refreshData(this);
				}
			}.bind(this));
		} else {
			this.notifyError("Mandatory Values are Missing!")
		}
	}

	refreshData() {
		fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/project-invitations-sent', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				if (data.status === 'ok') {
					this.setState({ corporateData: data.data.corporateData, invitationData: data.data.invitationData });
				}
			}.bind(this));
	}

	handleViewChange() {
		if (this.state.view === 'corporate') {
			this.setState({ view: 'project' });
		} else {
			this.setState({ view: 'corporate' });
		}
	}

	showMessage = (name, data) => {
		console.log(name, data)
		fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/project-invitation/' + data.id, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				console.log(data.data)
				if (data.status === 'ok') {
					let indicators = [];
					for (let i = 0; i < data.data.indicators.length; i++) {
						indicators.push(data.data.indicators[i]);
					}
					this.setState({indicators, reportingFrequency: data.data.reportingFrequency})
				}
			}.bind(this));
		if(name === "corporateMessage"){
			this.setState({
				name: name,
				subject: data.subject,
				showMessageModal: true,
				invitedBy: data.fullName,
				companyName: data.companyName,
				corporateMessage : data.message,
				invitedOn: moment(data.invitedOn).format('DD/MM/YYYY'),
			});
		}
		if(name === "projectMessage" ){
			this.setState({
				name: name,
				subject: data.subject,
				showMessageModal: true,
				invitedBy: data.fullName,
				projectMessage : data.message,
				companyName: data.companyName,
				invitedOn: moment(data.invitedOn).format('DD/MM/YYYY'),
			})
		}
	}

	hideMesageModal = () => {
		this.setState({showMessageModal: false});
	}

	formatColumn = (cell, row) => {
		if (row.format === "input") {
			return (row.format)
		} else {
			return (row.formula)
		}
	}

	notifySuccess = (text) => toast.success(text);
	notifyError = (text) => toast.error(text);

	render() {
		return (
			<div className="im-container im-partnerships-page">
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<IMPartnershipsSidebar selected="projectsSent" />
				<div className="im-partnerships-container im-projects-sent-container">
					<Modal show={this.state.showMessageModal} onHide={this.hideMesageModal.bind(this)} className='kpi-view-modal'>
 						<div className="comment_modal invitation-message-modal">
  						<h3 className="mb20 text-center">Project Solicited by {this.state.companyName}</h3>
  						<h4 style={{fontWeight:"bold", color:"#565656"}}>
  							Invited By : <span style={{fontWeight:"normal", color:"#a9a9a9"}}>{this.state.invitedBy}</span>
  						</h4>
  						<h4 style={{fontWeight:"bold", color:"#565656"}}>
  							Invited on : <span style={{fontWeight:"normal", color:"#a9a9a9"}}>{this.state.invitedOn}</span>
  						</h4>
  						<h4 style={{fontWeight:"bold", color:"#565656"}}>
  							Subject : <span style={{fontWeight:"normal", color:"#a9a9a9"}}>{this.state.subject}</span>
  						</h4>
							<h4 style={{fontWeight:"bold", color:"#565656"}}>
								Message : <span style={{fontWeight:"normal", color:"#a9a9a9", padding: "0px"}} className='comment'>{this.state.name === "corporateMessage" ? this.state.corporateMessage : this.state.projectMessage}</span>
  						</h4>
  						<h4 style={{fontWeight:"bold", color:"#565656", marginBottom: "20px"}}>
  							Frequency of Reporting : <span style={{fontWeight:"normal", color:"#a9a9a9"}}>{this.state.reportingFrequency}</span>
  						</h4>
  						<BootstrapTable data={this.state.indicators} className='indicators-table' id="indicators-table" striped={false}>
								<TableHeaderColumn dataField='indicatorId' width="5%" headerAlign='center' dataAlign='center' isKey={true}>Id</TableHeaderColumn>
					      <TableHeaderColumn dataField='type' headerAlign='center' dataAlign='center' dataFormat={this.outputOutcome}>Output/Outcome</TableHeaderColumn>
					      <TableHeaderColumn dataField='unit' headerAlign='center' dataAlign='center' dataFormat={this.UnitColumn}>Unit</TableHeaderColumn>
					      <TableHeaderColumn dataField='name' width="30%" headerAlign='center' dataAlign='center' dataFormat={this.indicator}>Indicator</TableHeaderColumn>
					      <TableHeaderColumn dataField='format' headerAlign='center' dataAlign='center' dataFormat={this.formatColumn}>Format</TableHeaderColumn>
					      <TableHeaderColumn dataField='computationType' headerAlign='center' dataAlign='center' dataFormat={this.durationalCumulative}>Durational/Cumulative</TableHeaderColumn>
					      {this.state.editVIewClusterModal === true ? (
				      		<TableHeaderColumn headerAlign='center' dataAlign='center' dataFormat={this.editDeleteClusterIndicatorbutton}>Edit/Delete</TableHeaderColumn>
					      ):(null)}
							</BootstrapTable>
  					</div>
 					</Modal>
					<h3>Projects submitted by me</h3>
					<div className="view-toggle-container" style={{ display: "flex", justifyContent: "space-between" }}>
						<div className="add-project-request-container">
							{!this.state.addProjectFormVisibility ? (
								<button className="btn btn-add-project-request" onClick={this.showAddProject.bind(this)}>
									<i className="fa fa-paper-plane mr5"></i>
									Submit Projects to Corporate
								</button>
							) : (null)}
						</div>
						<div style={{display:"flex"}}>
							<span className={this.state.view === 'corporate' ? "activeView" : ""}>Corporate View</span>
							<div className="ondisplay">
								<section>
									<div className="slideThree">
										<input type="checkbox" id="view-toggle" name="check" className="send_email_receipt_check"
											checked={this.state.view === 'corporate' ? false : true} onChange={this.handleViewChange.bind(this)} />
										<label htmlFor="view-toggle"></label>
									</div>
								</section>
							</div>
						<span className={this.state.view === 'project' ? "activeView" : ""}>Project View</span>
						</div>
					</div>
					{this.state.addProjectFormVisibility ? (
						<div className="new-project-form row" style={{border:"solid 1px #ddd", padding:"0px 12px", margin:"20px 0px"}}>
							<h3 className="mb10">Send Projects to corporate</h3>
							<Col className="form-group" md={6} style={{paddingleft:"0px"}}>
								<label className="control-label is-imp">Select Project:</label>
								<select className="form-control" value={this.state.selectedProject}
									onChange={this.updateChange.bind(this, 'selectedProject')}>
									<option disabled value=''>Select Project</option>
									{this.state.projects.map((project, index) => {
										return (
											<option value={project.id} key={index}>{project.title}</option>
										)
									})}
								</select>
							</Col>

							<Col className="form-group" md={6} style={{paddingRight:"0px"}}>
								<label className="control-label is-imp">Select Corporate:</label>
								<select className="form-control" value={this.state.selectedCorporate}
									onChange={this.updateChange.bind(this, 'selectedCorporate')}>
									<option disabled value=''>Select Corporate</option>
									{this.state.corporates.map((corporate, index) => {
										return (
											<option value={corporate.id} key={index}>{corporate.companyName}({corporate.subject})</option>
										)
									})}
								</select>
							</Col>

							<Col md={12} className="mt20 mb20 add-project-btn-container text-center">
								<button className="btn save-project-btn" onClick={this.sendProject.bind(this)}  style={{marginRight:"20px"}}>
									<i className="fa fa-paper-plane"></i>
									Submit
								</button>
								<button className="btn discard-project-btn" onClick={this.cancelAddProject.bind(this)}>
									<i className="fa fa-times"></i>
									Discard
								</button>
							</Col>
						</div>
					) : (null)}
					<div className="projects-sent-by-me">
						<div className="corporate-view-container">
							{this.state.view === "corporate" ? (
								<div className="corporate-view-table-header-container">
									<div className="corporate-view-table-header">
										<h4 style={{ margin: "0", fontWeight: "bold" }} className="corporate-name">Corporate</h4>
									</div>


									<div className='corporate-view-last-header-container'>
										<div className="corporate-view-table-invitation-header">
											<h4 style={{ margin: "0", fontWeight: "bold", width: "100%", textAlign: "center", padding:"10px 20px" }} className="invitation-label">Invitations</h4>
										</div>
										<div className="corporate-view-table-project-header">
											<h4 style={{ margin: "0", fontWeight: "bold", width: "100%", textAlign: "center", padding:"10px 20px" }} className="project-name">Project Submitted</h4>
										</div>
									</div>


								</div>
							) : (
									<div className="corporate-container">
										<div className="corporate-name-container">
											<h4 style={{ margin: "0", fontWeight: "bold" }} className="corporate-name">Invitations</h4>
										</div>
										<div className="corporate-name-container">
											<h4 style={{ margin: "0", fontWeight: "bold" }} className="corporate-name">Project Submitted</h4>
										</div>
										<div className="corporate-projects-container">
											<div className="project-container">
												<div className="project-name-container">
													<h4 style={{ margin: "0", fontWeight: "bold" }} className="project-name">Corporate</h4>
												</div>
											</div>
										</div>
									</div>
								)}
							{this.state.view === "corporate" ? (
								this.state.corporateData.length === 0 ? (
									<h3 className="no-data">There are no projects submitted by you.</h3>
								) : (
										this.state.corporateData.map((corporate, index) => {
											return (
												<div className="corporate-view-table-header-container" key={index}>
													<div className="corporate-view-table-header" title={corporate.companyName}>
														<span className="corporate-name">{corporate.companyName}</span>
													</div>
													<div style={{display:"flex", flexDirection:"column"}} className='corporate-view-last-header-container-main'>
														{corporate.invitations.map((invitation, projectIndex) => {
															return (
																<div className='corporate-view-last-header-container'>
																	<div className="corporate-view-table-invitation-header">
																		{invitation.projectId === "" ? (<p className='new-label'>new</p>) : (null)}
																		<div style={{display:"flex", alignItems:"center", padding:"15px", wordBreak:"break-all", width:"100%", justifyContent:"center", flexDirection:"column"}}>
																			<p className="project-name" style={{margin: 0, textAlign: "center"}}>{invitation.subject}</p>
																			<button className='view-message-button' style={{marginTop: "0px"}} name="corporateMessage" onClick={this.showMessage.bind(this, "corporateMessage",invitation)}>View Message</button>
																		</div>
																	</div>
																	<div className="corporate-view-table-project-header">
																		<div style={{display:"flex", alignItems:"center", padding:"15px", wordBreak:"break-all", width:"100%", justifyContent:"center", flexDirection:"column"}}>
																			{invitation.projectId !== "" ? <p style={{margin: 0}} className="project-name">{invitation.projectTitle}</p> : <p className="project-name">Yet to be Submitted</p>}
																		</div>
																	</div>
																</div>
															)
														})}
													</div>
												</div>
												)
											}))) : (
									this.state.invitationData.length === 0 ? (
										<h3 className="no-data">There are no projects submitted by you.</h3>
									) : (
											this.state.invitationData.map((invitation, index) => {
												return (
														<div className="corporate-container" key={index}>
															<div className="corporate-name-container">
																{invitation.projectId === "" ? (<p style={{margin:"0px"}} className='new-label'>new</p>) : (null)}
																<div style={{display:"flex",alignItems:"center", flexDirection: "column", width:"100%", height:"auto", wordBreak:"break-all", padding:"10px" }}>
																	<p className='invitation-subject' style={{margin:"0px", textAlign:"center"}}>{invitation.subject}</p>
																	<button className='view-message-button' name='projectMessage' onClick={this.showMessage.bind(this, "projectMessage",invitation)}>View message</button>
																</div>
															</div>
															<div className="corporate-name-container">
																{invitation.projectId !== "" ? <p className="project-name" style={{margin:"0px"}}>{invitation.projectTitle}</p> : <p>Yet to be Submitted</p>}
															</div>
															<div className="corporate-projects-container">
																<div className="project-container" style={{width:"100%"}}>
																	<div className="project-name-container">
																		<p className="project-name" style={{margin:"0px"}}>{invitation.companyName}</p>
																	</div>
																</div>
															</div>
														</div>
													)
											})))}
						</div>
					</div>
				</div>
			</div >
		);
	}
}
