import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import swal from "sweetalert2";
import $ from 'jquery';

import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../../cookie.js').readCookie;

export default class UserTag extends Component {
	constructor(props){
		super(props);
		this.state = {
			tags: [],
			selectedTags: [],
			allCheck: false,
			submitted: false,
			loaded: false
		}
	}

	componentDidMount() {
		let campaign_id = this.props.match.params.campaign_id;
		let email = this.props.match.params.user_id;
		let selectedTags = this.state.selectedTags;
		fetch(process.env.REACT_APP_API_URL + '/get/allcampaign/tags', {
      method: 'POST',
      headers: {
      	'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "campaign_id": campaign_id, "email": email })
    }).then(res => res.json())
    .then(data => {
      if(data.status === 'ok' && data.data.length) {
      	console.log(data.data)
        this.setState({ tags: data.data, loaded: true });
      } else {
	  		fetch(process.env.REACT_APP_API_URL + '/post/unsubscriber',{
	  			method: 'POST',
	  			headers: {
	  				'Content-Type': 'application/json',
	  			},
	  			body: JSON.stringify({"email": email, "campaign_tags": selectedTags, "unsubscribedAll": true})
	  		}).then(res=> res.json())
	  		.then((data)=>{
  			if(data.status === 'ok'){
					this.setState({submitted: true, loaded: true}, function(){
						swal({
							title: 'Thank You.',
							text: 'You have been successfully unsubscribed.',
							type: 'success'
						})
					}.bind(this))
				}
			});
		}
	});
}

	scrollDown() {
		$('.user-tag .user-tag-contents').animate({
      scrollTop: $('.user-tag .user-tag-contents').scrollTop() + 120
    }, 600);
	}

	scrollUp() {
		$('.user-tag .user-tag-contents').animate({
      scrollTop: $('.user-tag .user-tag-contents').scrollTop() - 120
    }, 600);
	}

  onChange = (id) => {
  	let selectedTags = this.state.selectedTags;
  	if(selectedTags.indexOf(id) > -1){
  		selectedTags.splice(selectedTags.indexOf(id), 1);
  	} else {
  		selectedTags.push(id);
  	}
  	this.setState({selectedTags}, function() {
	  	if(selectedTags.length === this.state.tags.length){
	  			this.setState({allCheck: true});
	  		} else {
	  			this.setState({allCheck: false});
	  		}
  	}.bind(this));
  }

  selectAll = (e) => {
  	let tags = this.state.tags;
  	if(this.state.allCheck === true){
  		this.setState({selectedTags: [], allCheck: false})	
  	} else if(this.state.allCheck === false){
	  	let selectedTags = [];
	  	for(var i = 0; i < tags.length; i++){
	  		selectedTags.push(tags[i].campaign_tag_id);
	  	}
	  	this.setState({selectedTags, allCheck: true})
  	}
  }

  submitTags = () => {
  	let selectedTags = this.state.selectedTags;
  	let email = this.props.match.params.user_id;
  	let unsubscribedAll = this.state.allCheck;
  	if(selectedTags.length && email !== ''){
  		fetch(process.env.REACT_APP_API_URL + '/post/unsubscriber',{
  			method: 'POST',
  			headers: {
  				'Content-Type': 'application/json',
  			},
  			body: JSON.stringify({"email": email, "campaign_tags": selectedTags, "unsubscribedAll": unsubscribedAll})
  		}).then(res=> res.json())
  		.then((data)=>{
  			if(data.status === 'ok'){
  				this.setState({submitted: true}, function(){
	  				swal({
	  					title: 'You have been successfully unsubscribed.',
	  					text: 'You have been successfully unsubscribed.',
	  					type: 'success'
	  				})
  				}.bind(this))
  			} else {
  				swal({
  					title: 'Something went wrong.',
  					text: 'Something went wrong',
  					type: 'error'
  				})
  			}
  		})
  	}
  }

	render() {
		if(this.state.loaded) {
			if(this.state.submitted === false){
				return(
					<div md={12} className="user-tag">
						<Col md={12} className="user-tag-outer">
							<Col md={12} className="user-tag-header">
								<h2>UNSUBSCRIBE</h2>
								<p>Please note you would not recieve campaigns with following tags once you unsubscribe.</p>
							</Col>
							<Col md={12} className="user-tag-sub-header">
								<h3>Please select the tags you want to unsubscribe from.</h3>
							</Col>
							<button className="btn btn-go-up" onClick={this.scrollUp}>
								<i className="fa fa-chevron-up"></i>
							</button>
							<div className="user-tag-contents">
								<Col md={12} className="user-tag-first">
									<Row className="user-tag-main-container">
										<Col md={12}>
							        {this.state.tags.length ? (
							        	<div className="all-selected-check">
							        		<input type='checkbox' checked={this.state.allCheck ? true : false} onChange={this.selectAll} style={{marginRight: '5px'}}/>
							        		Select All
							        	</div>
							        ) : (null)}
							      </Col>
										<Col md={12} className="tag-list-container">
							        {this.state.tags.map((tags, index)=>{
							        	return(
							        		<div key={index} className="tag-cards">
							        			<input type='checkbox' checked={this.state.selectedTags.indexOf(tags.campaign_tag_id) > -1 ? true : false}
							        			style={{marginRight: '5px'}} onChange={this.onChange.bind(this, tags.campaign_tag_id)}/>
							        			{tags.campaign_tag_name}
							        		</div>
							        	)
							        })}
										</Col>
									</Row>
								</Col>
							</div>
							<button className="btn btn-go-up" onClick={this.scrollDown}>
								<i className="fa fa-chevron-down"></i>
							</button>
							<Col md={12} className="user-tag-btn-container">
								<button className="btn mt15" disabled={this.state.selectedTags.length <= 0 ? true : false} onClick={this.submitTags}>
									Submit
								</button>
							</Col>
						</Col>
					</div>
				)
			}	else {
				return(
					<div className="user-tag-submitted">
						<h1>Thank You. Your request has been submitted.</h1>
					</div>
				)
			}
		} else {
			return null
		}
	}
}