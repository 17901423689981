import React, { Component } from 'react';
import UserProfile from './userProfile';
import {
    CIRCLE, SQUARE, RECTANGLE
} from '../constants/constants';
import EditModelIcon from "./EditModeIcon";

export default class AttachmentBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            background_url: "https://cdn5.vectorstock.com/i/1000x1000/66/94/mail-with-attachment-icon-vector-14076694.jpg",
            //"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6uZ3C-DONo8klLvVrnCot9hYU73O_G5QUynsneEJDB4rsF1UYrw"
            //"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzTHi9-O6VcXu4sbf5kHiesmO3UcXJO0SXKlyrBtvO5rhAGlq",
            hideMe: false
        }
    }

    componentWillUnmount() {
        // this.attachment = this.attachment.destroy();
        console.log("element destroyed")
    }

    hideAttachment(event) {
        console.log("hideAttachment");
        this.setState({ hideMe: true })
        // this.props.deleteAttachment();
    }

    render() {
        console.log("this.props.attachment", this.props.attachment);
        return (

            <div>
                {
                    (!this.state.hideMe)
                        ?
                        <div className="attachment-box" ref="attachment">
                            <EditModelIcon
                                deleteParent={this.hideAttachment.bind(this)}
                                isVisible={this.props.editMode}
                            />
                            <UserProfile
                                scaleComponent={1}
                                backgroundImage={this.state.background_url}
                                boxType={RECTANGLE}
                            />

                            {/* <div className="file-link"><span style={{fontSize: '12px'}}>{this.props.attachment.url.substring(0,3)}</span></div> */}
                        </div>
                        :
                        null
                }
            </div>
        )
    }
}