import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from "react-router-dom";
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ToastContainer, toast } from 'react-toastify';
// import Modal from 'react-responsive-modal';
import ExpenseModal from './ExpenseModal';
import CommentsModal from './CommentsModal';
import swal from 'sweetalert2';
import { Col, Row, Modal } from 'react-bootstrap';
const readCookie = require('../../ImpactManagement/cookie.js').readCookie;

export default class EMExpenseReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reports: [],
			particularReport: [],
			openModal: false,
			selectedIndex: null,
			selectedExpenseId: null,
			openComment: false,
			comment_by: null,
			comment_on: null,
			report: null,
			selectedComment: [],
			showComment: false,
			selectedPayout: null,
			showPayoutModal: false
		}
	}
	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/EM/fetch_expense_lines/' + this.props.match.params.reportId + "/employee", {
			method: 'GET',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(res => (res.json()))
		.then(res => {
			this.setState({ reports: res.data, report: res.report });
		}).catch(err => {
			console.log("err", err);
		})
	}

	selectCategory(event) {
		this.setState({ category: event.target.value })
	}

	expenseCategoryBox(event) {
		return (
			<select className="form-control" value={this.state.category} onChange={this.selectCategory.bind(this)}>
				<option value='' disabled>Select Category</option>
				{
					_.map(this.state.expenseCategory, (cat, index) => (
						<option value={cat} key={cat + index}>
							{cat}
						</option>
					))
				}
			</select>
		)
	}

	expenseDestributionBox(event) {
		return (
			<input type="text" ref={(e) => this.expenseDestribution = e} className="form-control" />
		)
	}
	billDateBox(event) {
		return (
			<input type="date" ref={(e) => this.billDate = e} className="form-control" />
		)
	}
	uploadbillBox(event) {
		return (
			<input type="file" onChange={(e) => this.setState({ bill: e.target.files })} />
		)
	}
	merchantNameBox(event) {
		return (
			<input type="text" ref={(e) => this.merchantName = e} className="form-control" />
		)
	}
	billValueBox(event) {
		return (
			<input type="text" ref={(e) => this.billValue = e} className="form-control" />
		)
	}
	reimbursableValueBox(event) {
		return (
			<input type="text" ref={(e) => this.reimbursableValue = e} className="form-control" />
		)
	}
	statusExpenseBox(event) {
		return (
			<input type="text" ref={(e) => this.statusExpense = e} className="form-control" />
		)
	}
	saveExpenseBox(event) {
		return (
			<button type="button" className="btn btn-primary">save</button>
		)
	}

	actionCheckbox(event) {
		return (
			<input type="checkbox" checked={(this.state.checkedData === true ? true : false)} onChange={this.checkRow.bind(this, event)} />
		)
	}

	actionExpenseBox(event) {
		let filtered_data = _.find(this.state.reports, (el, i) => (el._id === event));
		if (filtered_data.status === "Rejected" || filtered_data.status === "Approved" || filtered_data.status === "Submitted") {
			return (
				"Not allowed"
			);
		}
		else {
			return (
				<div>
					<button type="button" className="btn btn-info"
						style={{
							margin: '2px',
							padding: '5px 0px 5px 5px'
						}}
						onClick={this.editData.bind(this, event)}
					>
						<i className="fa fa-pencil-square-o" style={{ fontSize: 'larger !important' }}></i>
					</button>
					<button type="button" className="btn btn-danger"
						style={{
							margin: '2px',
							padding: '5px 5px 5px 5px'
						}}
						onClick={this.deleteData.bind(this, event)}
					>
						<i className="fa fa-remove" style={{ fontSize: 'larger !important' }}></i>
					</button>
				</div>
			)
		}

	}

	editData(event, row) {
		let index = _.findIndex(this.state.reports, function (o) { return o._id === event });
		this.setState({ selectedIndex: index });
		this.setState({ selectedExpenseId: this.state.reports[index]._id })
		this.onOpenModal();
	}

	deleteData(row) {
		swal({
			title: 'Do you want to delete this Expense Line ?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result === true) {
				fetch(process.env.REACT_APP_API_URL + '/EM/delete_expense_line/' + this.props.match.params.reportId + "/" + row, {
					method: 'DELETE',
					headers: {
						'Authorization': JSON.parse(readCookie('access_token')).access_token
					}
				}).then(res => (res.json()))
				.then(res => {
					let filteredData = _.filter(this.state.reports, (report, index) => (report._id !== row));
					this.setState({ reports: filteredData });
					swal(
						'Deleted!',
						'Your file has been deleted.',
						'success'
					)
				}).catch(err => {
					toast.error('Failed to delete expense row. Please try again')
				})
			} else { return null }
		})
	}

	checkRow(event, data) {
		(this.state.checkedData === true ? this.setState({ checkedData: false }) : this.setState({ checkedData: true }))
	}

	paymentMode(event) {
		return (
			<select className="form-control" value={this.state.category} onChange={this.selectCategory.bind(this)}>
				<option value='' disabled>Select Payment</option>
				{
					_.map(this.state.payments, (payment, index) => (
						<option value={payment} key={payment + index}>
							{payment}
						</option>
					))
				}
			</select>
		)
	}

	createNewExpenseReport(e) {
		window.location.pathname = '/em/expense/my-reports/' + this.props.match.params.reportId + '/new-expense';
	}

	showComments(cell, row) {
		let data = _.filter(this.state.reports, (report, i) => (
			report._id === cell
		))
		if (data[0].status === "Draft" || data[0].status === "Approved" || data[0].status === "Submitted") {
			return "N/A"
		} else {
			if(row.comments.length){
				return (
					<span className="note" style={{cursor: 'pointer'}} onClick={this.showCommentModal.bind(this, row.comments)}>View Comment</span>
				)
			} else {
				return "N/A"
			}
		}
	}

	statusUpdate = (status) => {
		this.setState({ status });
	}

	dataFilter(cell, row) {
		if (cell === undefined || cell === null || cell === '' || cell === "undefined") {
			return "N/A"
		} else {
			return cell;
		}
	}

	statusFilter = (cell, row)=>{
		if(cell === undefined || cell === null || cell === '' || cell === "undefined") {
			return "N/A"
		} else {
			if(cell === 'Approved' && Object.keys(row.payout).length > 0){
				return (
					<span className="note" style={{cursor: 'pointer'}} onClick={this.showPayoutModal.bind(this, row.payout)}>
						Approved & Paid Out
					</span>
				)
			} else {
				return cell
			}
		}
	}
	dateFormatter(cell) {
		if (cell !== undefined || cell !== null || cell !== '') {
			return (moment(cell).format('DD/MM/YYYY'))
		} else {
			return "N/A"
		}
	}
	showBill(cell) {
		if (cell === undefined || cell === null || cell === '' || cell === "undefined") {
			return "N/A"
		} else {
			return <a className="note" href={cell} target="_blank">View</a>;
		}
	}

	submitReport() {
		swal({
			title: 'Do you want to submit this Expense Report?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, Submit it!'
		}).then((result) => {
			fetch(process.env.REACT_APP_API_URL + '/EM/submit_report/' + this.props.match.params.reportId + "/" + this.props.ngo.ngo_id, {
				method: 'PUT',
				headers: {
					'Authorization': JSON.parse(readCookie('access_token')).access_token
				}
			}).then(res => (res.json()))
			.then(res => {
				toast.success('You have successfully submitted this Report');
				window.location.pathname = "/em/expense/my-reports/" + this.props.match.params.reportId;
			}).catch(err => {
				toast.error('Failed to submit report. Please try again')
			})
		}).catch(er => {
			console.log("err", er);
		})
	}

	approvedBy(cell) {
		if(cell) return cell;
		else return 'N/A';
	}

	onOpenModal() {
		this.setState({ openModal: true });
	}

	onCloseModal(event) {
		this.setState({ openModal: false })
	}
	onCloseComment(event) {
		this.setState({ openComment: false });
	}

	showPayoutModal(selectedPayout) {
		this.setState({ showPayoutModal: true, selectedPayout });
	}

	hidePayoutModal() {
		this.setState({ showPayoutModal: false, selectedPayout: null });
	}

	deleteReport(event){
		let report_id = this.props.match.params.reportId;
		swal({
			title: 'Do you want to delete this Expense Report ?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result === true) {
				fetch(process.env.REACT_APP_API_URL + '/EM/delete/report/' + report_id,
				{
					method: 'DELETE',
					headers: {
						'Authorization': JSON.parse(readCookie('access_token')).access_token
					}
				}).then(res => (res.json()))
				.then(res => {
					toast.success('You have successfully deleted this expense report');
					window.location.pathname="/em/expense";
				}).catch(err => {
					toast.error('You have failed to delete this Expense Report Please try again');
				});
			}
		});
	}

	payoutAmountFormatter(cell, row) {
		if(cell && cell.hasOwnProperty('amount')) {
			return cell.amount;
		} else return 'N/A';
	}

	payoutDateFormatter(cell, row) {
		if(cell && cell.hasOwnProperty('date')) {
			return moment(cell.date).format('DD/MM/YYYY');
		} else return 'N/A';
	}

	showCommentModal(selectedComment) {
		this.setState({ showComment: true, selectedComment });
	}

	closeShowComment() {
		this.setState({ showComment: false, selectedComment: [] });
	}

	render() {
		return (
			<div className="im-container im-project-page im-project-finances-page">
				<Modal show={this.state.openModal} onHide={this.onCloseModal.bind(this)}>
					<ExpenseModal
						reports={this.state.reports[this.state.selectedIndex]}
						openModal={this.state.openModal}
						selectedExpenseId={this.state.selectedExpenseId}
						reportId={this.props.match.params.reportId}
					/>
				</Modal>

				<Modal show={this.state.openComment} onHide={this.onCloseComment.bind(this)}>
					<CommentsModal
						comments={this.state.selectedComment}
						comment_by={this.state.comment_by}
						comment_on={this.state.comment_on}
						onClose={this.onCloseComment.bind(this)}
					/>
				</Modal>

				<Modal show={this.state.showComment} onHide={this.closeShowComment.bind(this)}>
					<div className="comment_modal">
						<h3 className="mb20 text-center">Comment</h3>
						<div className="comment">
							<div style={{marginTop:'10px', display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
								{this.state.selectedComment.map((comment, index)=>{
									return (
										<div className="comment" key={index}>{comment}</div>
									)
								})}
							</div>
						</div>
					</div>
				</Modal>

				{this.state.selectedPayout !== null ? (
					<Modal show={this.state.showPayoutModal} onHide={this.hidePayoutModal.bind(this)}>
						<div className="comment_modal">
							<h3 className="mb20 text-center">Payout Information</h3>
							<Col md={12} style={{ marginTop: 20 }}>
								<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}}>Date of Payment :</label>
								<span className="note">{moment(this.state.selectedPayout.date).format('DD/MM/YYYY')}</span>
							</Col>
							<Col md={12} style={{ marginTop: 20 }}>
								<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}}>Mode of Payment :</label>
								<span className="note">{this.state.selectedPayout.mode}</span>
							</Col>
							<Col md={12} style={{ marginTop: 20 }}>
								<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}}>Amount Paid (INR) :</label>
								<span className="note">{this.state.selectedPayout.amount}</span>
							</Col>
							<Col md={12} style={{ marginTop: 20 }}>
								<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}}>Transaction Reference Number :</label>
								<span className="note">{this.state.selectedPayout.transaction_reference_number || 'N/A'}</span>
							</Col>
						</div>
					</Modal>
				) : (null)}

				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />

				{this.state.report !== null ? (
					<div className="im-project-container im-project-finance-container" style={{ width: 'calc(100% - 20px)', left: '0px', marginTop: "5px" }}>
						<div className="finance-tabs-content" style={{ overflowX: 'scroll' }}>
							<Row>
								<Col md={12} className="mb10 details-holder-mobile" style={{ display: "flex", alignItems: "flex-end" }}>
									<Col md={3} className="sub-details-mobile">
										<Col md={12} className="mb10 padding0">
											<Link to={'/em/expense/my-reports'}>
												<button className="btn btn-danger">
													<i className="fa fa-chevron-left"></i>
													Go Back
												</button>
											</Link>
										</Col>
										{this.state.report.id ? (
											<p><note>Expense Report :</note> #{this.state.report.id}</p>
										) : (null)}
										{this.state.report.status ? (
											<p><note>Overall Status :</note> {this.state.report.status}</p>
										) : (null)}
									</Col>
									<Col md={3} className="sub-details-mobile">
										{this.state.report.total_reimbursable ? (
											<p><note>Total Reimbursable Value :</note> {this.state.report.total_reimbursable + ' (INR)'}</p>
										) : (null)}
										{this.state.report.total_reimbursed ? (
											<p><note>Total Reimbursed Value :</note> {this.state.report.total_reimbursed + ' (INR)'}</p>
										) : (null)}
									</Col>

									<Col md={6} className="action-btn-mobile" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
										{this.state.report.status === "Draft" ? (
											this.state.reports.length ? (
												<button className="btn btn-info mb10" onClick={this.submitReport.bind(this)}>
													<i className="fa fa-check"></i>
													Submit Report
												</button>
											) : (
												<button className="btn btn-info mb10" disabled title='Add atleast one expense line to submit report.'>
													<i className="fa fa-check"></i>
													Submit Report
												</button>
											)
										) : (null)}

										{this.state.report.status === "Draft" ? (
											<button className="btn btn-danger mb10" onClick={this.deleteReport.bind(this)}>
												<i className="fa fa-trash"></i>
												Delete Report
											</button>
										) : (null)}

										{this.state.report.status === "Draft" ? (
											<button className="btn btn-info" onClick={this.createNewExpenseReport.bind(this)}>
												<i className="fa fa-plus"></i>
												Add a New Expense Line
											</button>
										) : (null)}
									</Col>
								</Col>
							</Row>
							<div className="tab-content budget-tab-content" style={{ overflowX: 'scroll' }}>
								<BootstrapTable data={this.state.reports} striped={false} ref='table' hover={true} exportCSV
									csvFileName={'Expense Report #' + this.state.report.id + ' ' + String(moment().unix()) + '.csv'}>
									<TableHeaderColumn dataField="_id" isKey={true} hidden={true} export={false} searchable={false}>Id</TableHeaderColumn>
									<TableHeaderColumn dataField="line_id" width='40px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.dataFilter.bind(this)}>Expense Line #</TableHeaderColumn>
									<TableHeaderColumn dataField="expense_category" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.dataFilter.bind(this)}>Expense Category</TableHeaderColumn>
									<TableHeaderColumn dataField="description" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.dataFilter.bind(this)}>Description</TableHeaderColumn>
									<TableHeaderColumn dataField="created_bill_date" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.dateFormatter.bind(this)}>Bill Date</TableHeaderColumn>
									<TableHeaderColumn dataField="uploaded_bill" width='60px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.showBill.bind(this)}>Bill</TableHeaderColumn>
									<TableHeaderColumn dataField="merchant_name" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.dataFilter.bind(this)}>Merchant Name</TableHeaderColumn>
									<TableHeaderColumn dataField="bill_value_amount" width='60px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.dataFilter.bind(this)}>Bill Value (INR)</TableHeaderColumn>
									<TableHeaderColumn dataField="reimbursable_value" width='80px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.dataFilter.bind(this)}>Reimbursable Value (INR)</TableHeaderColumn>
									<TableHeaderColumn dataField="payment_mode" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.dataFilter.bind(this)}>Payment Mode</TableHeaderColumn>
									<TableHeaderColumn dataField="payout" width='100px' headerAlign='center' dataAlign='center' dataSort={true}   hidden={false}   export={false} searchable={false} dataFormat={this.payoutDateFormatter.bind(this)}>Payout Date</TableHeaderColumn>
									<TableHeaderColumn dataField="payout" width='100px' headerAlign='center' dataAlign='center' dataSort={true}   hidden={false}   export={false} searchable={false} dataFormat={this.payoutAmountFormatter.bind(this)}>Payout Amount (INR)</TableHeaderColumn>
									<TableHeaderColumn dataField="Payout Date" hidden={true} export={true} searchable={true}>Payout Date</TableHeaderColumn>
									<TableHeaderColumn dataField="Payout Amount" hidden={true} export={true} searchable={true}>Payout Amount (INR)</TableHeaderColumn>
									<TableHeaderColumn dataField="status" width='80px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.statusFilter.bind(this)}>Status</TableHeaderColumn>
									<TableHeaderColumn dataField="approved_by" width='100px' headerAlign='center' dataAlign='center' dataSort={true}   hidden={false}   export={true} searchable={true} dataFormat={this.approvedBy.bind(this)}>Approved By</TableHeaderColumn>
									<TableHeaderColumn dataField="_id" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={false} searchable={false} dataFormat={this.showComments.bind(this)}>Comments</TableHeaderColumn>
									<TableHeaderColumn dataField="comment" hidden={true} export={true} searchable={true}>Comment</TableHeaderColumn>
									<TableHeaderColumn dataField="_id" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={false} searchable={false} dataFormat={this.actionExpenseBox.bind(this)}>Action</TableHeaderColumn>
								</BootstrapTable>
							</div>
						</div>
					</div>
				) : (null)}
			</div>
		)
	}
}