import _ from 'lodash';
import React from 'react';
import AddKPIForm from "./AddKPIForm";
import Sidebar from './Sidebar/Sidebar';
import ImpactTable from "./ImpactTable";
import ImpactChart from "./ImpactChart";
import BeneficiaryTable from "./BeneficiaryTable";
import AddAccessmentForm from "./AddAccessmentForm";

const readCookie = require('../cookie.js').readCookie;

let fake_impact_data = [
    {
        "indicator": "Girls Enrolled",
        "data_linked": [
            {
                "date": "01/01/2016",
                "count": 320
            },
            {
                "date": "10/03/2016",
                "count": 420
            },
            {
                "date": "10/01/2016",
                "count": 450
            },
            {
                "date": "20/01/2016",
                "count": 920
            }
        ]
    },
    {
        "indicator": "Girls without toilets at home",
        "data_linked": [
            {
                "date": "01/01/2016",
                "count": 320
            },
            {
                "date": "10/03/2016",
                "count": 400
            },
            {
                "date": "10/01/2016",
                "count": 480
            },
            {
                "date": "20/01/2016",
                "count": 720
            }
        ]
    },
    {
        "indicator": "Monthly complaints of UTI",
        "data_linked": [
            {
                "date": "01/01/2016",
                "count": 120
            },
            {
                "date": "10/03/2016",
                "count": 220
            },
            {
                "date": "10/01/2016",
                "count": 350
            },
            {
                "date": "20/01/2016",
                "count": 520
            }
        ]
    }
]

export default class KeyIndicators extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            keyIndicator: null,

            teamMembers: null,//add accessment
            leadAccessor: null,
            location: null,
            tableName: "",
            addNew: "",
            kpiData: null,
            show_table: true,
            show_graph: false,
            graph_data: null,
            showAddAccessmentForm: false,
            showKPIForm: false,
            assessmentButtonText: "Add a new Assessment",
            KPIbuttonText: "Add New Indicator",
            addKPI: true,
            addAssessForm: true,
            sample_size: [
                {
                    "unit": "Number",
                    "symbol": "None",
                    "dataType": "Number"
                },
                {
                    "unit": "kilograms",
                    "symbol": "kg",
                    "dataType": "float"
                },
                {
                    "unit": "litres",
                    "symbol": "L",
                    "dataType": "float"
                },
                {
                    "unit": "grams",
                    "symbol": "gm",
                    "dataType": "float"
                },
                {
                    "unit": "percentage",
                    "symbol": "%",
                    "dataType": "float"
                },
                {
                    "unit": "feet",
                    "symbol": "ft",
                    "dataType": "float"
                },
                {
                    "unit": "inches",
                    "symbol": "in",
                    "dataType": "float"
                },
                {
                    "unit": "centimeter",
                    "symbol": "cm",
                    "dataType": "float"
                },
                {
                    "unit": "meter",
                    "symbol": "m",
                    "dataType": "float"
                },
                {
                    "unit": "kilometer",
                    "symbol": "km",
                    "dataType": "float"
                }
            ],
            allowReporting: false
        }
    }



    getAllImpactData() {
        return fake_impact_data;
        //api call to fetch data
    }

    componentDidMount() {

        let url = process.env.REACT_APP_MIS_API_URL + "/project/" + this.props.project_id + "/beneficiaries";

        fetch(url, {
            method: 'GET',
            headers: {
                'Auth': JSON.parse(readCookie('mis_data')).access_token
            }
        })
            .then(res => (res.json()))
            .then(res => {
                this.setState({ allowReporting: res.allowReporting });
            })
            .catch(er => {
                console.log("err", er)
            })

    }

    plotGraph(graph_data) {
        console.log("graph_data1", graph_data);
        // let array = _.map(graph_data, (el, i) => {
        //     //console.log("graph_data", el);
        //     return {date: el.date, count: el.value[0].unit}
        // });
        this.setState({ graph_data: graph_data, show_table: false, show_graph: true });
        //console.log("this.graph_data", this.state);
        //hides table and displays graph
    }

    ImpactTableVisibilty(value) {
        //this.setState({show_table: value, show_graph: !value});
    }

    showProjectUpdates() {

    }
    showIndicators() {

    }

    toggleAccessmentForm() {
        console.log("toggleAccessmentForm");
        this.setState({
            showAddAccessmentForm: false,
            show_table: true,
            addAssessForm: true,
            addKPI: true,
            assessmentButtonText: "Add a new Accessment",
            KPIbuttonText: "Add a new KPI",
        })
    }

    toggleKPIForm() {
        this.setState({
            showKPIForm: false,
            show_table: true,
            addKPI: true,
            addAssessForm: true,
            assessmentButtonText: "Add a new Accessment",
            KPIbuttonText: "Add a new KPI",
        })
    }

    addAccessment(data, string, accessment) {
        console.log("addAccessment", data, string);
        this.setState(
            {
                show_table: false,
                show_graph: false,
                tableName: string,
                kpiData: data,
                showKPIForm: false,

                teamMembers: (accessment === undefined ? '' : accessment.teamMembers.toString()),//add accessment
                leadAccessor: (accessment === undefined ? '' : accessment.leadAccessor),
                location: (accessment === undefined ? '' : accessment.location),

                showAddAccessmentForm: true,
                assessmentButtonText: "Submit",
                KPIbuttonText: "Add a new KPI",
                addKPI: false,
                addAssessForm: false
            }
        );
        console.log("addAccessment", data, accessment, this.state);
    }

    addKPIForm(string) {
        this.setState(
            {
                show_table: false,
                show_graph: false,
                addNew: string,
                showKPIForm: true,
                addKPI: false,
                showAddAccessmentForm: false,
                assessmentButtonText: "Add a new Assessment",
                KPIbuttonText: "Submit",
                addAssessForm: false

            }
        );
    }

    modalController(accessment_array) {
        this.props.onCloseDataModal(accessment_array);
    }

    graphController(graph_array) {
        console.log(graph_array);
        this.props.openGraphModal(graph_array);
    }


    render() {
        return (
            <div>
                {
                    (this.props.keyIndicators)
                        ?
                        <div style={{ marginTop: '44px' }}>
                            {
                                (this.state.show_table)
                                    ?
                                    (<ImpactTable
                                        getImpactList={this.getAllImpactData.bind(this)}
                                        plotGraph={this.plotGraph.bind(this)}
                                        visibility={this.state.show_table}
                                        tableProps={fake_impact_data}
                                        project_id={this.props.project_id}
                                        addAccessment={this.addAccessment.bind(this)}
                                        onCloseModal={this.modalController.bind(this)}
                                        graphModal={this.graphController.bind(this)}
                                    //ImpactData = {this.state.KeyIndicator}

                                    />)
                                    :
                                    (null)
                            }

                            <ImpactChart
                                graphData={this.state.graph_data}
                                visibility={this.state.show_graph}
                                project_id={this.props.project_id}
                            />
                            {
                                (this.state.showAddAccessmentForm)
                                    ?
                                    <AddAccessmentForm
                                        tableName={this.state.tableName}
                                        project_id={this.props.project_id}
                                        kpiData={this.state.kpiData}
                                        teamMembers={this.state.teamMembers}
                                        leadAccessor={this.state.leadAccessor}
                                        location={this.state.location}
                                        visibility={this.state.showAddAccessmentForm}
                                        getDataTypes={this.state.sample_size}
                                        toggleAccessmentForm={this.toggleAccessmentForm.bind(this)}
                                    />
                                    :
                                    null

                            }


                            <AddKPIForm project_id={this.props.project_id}
                                tableName={this.state.addNew}
                                visibility={this.state.showKPIForm}
                                getDataTypes={this.state.sample_size}
                                toggleKPIForm={this.toggleKPIForm.bind(this)}
                            />
                            <div>
                                {
                                    (this.state.show_graph)
                                        ?
                                        null
                                        :
                                        <div className="text-container">

                                            <div className="row">
                                                <div className="col-sm-4">

                                                </div>
                                                <div className="col-sm-4">
                                                    {/* {
                                            (this.state.addAssessForm)
                                                ?
                                            <div className="row">
                                                <div className="btn-container">
                                                    <button type="button" className="btn btn-info" onClick={this.addAccessment.bind(this)}>
                                                        { this.state.assessmentButtonText }
                                                    </button>
                                                </div>
                                            </div>
                                                :
                                            null
                                        } */}


                                                    {
                                                        (this.state.addKPI)
                                                            ?
                                                            <div className="row">
                                                                <div className="btn-container">
                                                                    <button type="button" className="btn btn-info" onClick={this.addKPIForm.bind(this, "kpi")}>
                                                                        {this.state.KPIbuttonText}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>


                                            </div>

                                        </div>
                                }

                            </div>
                        </div>
                        :
                        null
                }
                {(this.state.show_table) ?
                    (<div style={{ marginTop: "100px" }}>
                        <label style={{ fontSize: "20px" }}>Beneficiaries Table:</label>
                        <BeneficiaryTable
                            getImpactList={this.getAllImpactData.bind(this)}
                            plotGraph={this.plotGraph.bind(this)}
                            visibility={this.state.show_table}
                            tableProps={fake_impact_data}
                            project_id={this.props.project_id}
                            addAccessment={this.addAccessment.bind(this)}
                            onCloseModal={this.modalController.bind(this)}
                            graphModal={this.graphController.bind(this)} />
                    </div>) : (null)}
                {(this.state.show_table) ?
                    (<div className="row">
                        <div className="btn-container">
                            {this.state.allowReporting === true ?
                                (<button type="button" className="btn btn-info" onClick={this.addKPIForm.bind(this, "beneficiary")}>
                                    Add Beneficiary Category
                            </button>) : (null)
                            }
                        </div>
                    </div>) : (null)}

            </div>
        )
    }
}
