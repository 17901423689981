import React from 'react';

import DonorTable from './DonorTable';

export default class Donors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  render() {
    return (
      <div className="page-container">
        <DonorTable ngo={this.props.ngo} userData={this.props.userData} />
      </div>
    );
  }
}
