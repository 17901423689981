import React, { Component } from "react";
import { Row } from "react-bootstrap";

import AddNewGroup from "./add-group/AddNewGroup";

export default class AddGroup extends Component {
  render() {
    return (
      <div className="page-container add-group-page">
        {this.props.showAddGroupModal ? (
          <div>
            <h2 className="modal-header-custom">
              Create New Segment
            </h2>
            <Row>
              <AddNewGroup ngo={this.props.ngo} group_name={this.props.group_name}
                onAddGroupClose={this.props.onAddGroupClose} list_id={this.props.list_id}
                userEmail={this.props.userData.email} donor_ids={this.props.donor_ids}
                group_desc={this.props.group_desc} />
            </Row>
          </div>
        ) : (null)}
      </div>
    );
  }
}