import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from "recharts";

import DashboardDonorChartFilter from "./DashboardDonorChartFilter";

export default class DashboardDonorChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      data: [],
      fromDate: null,
      toDate: null
    };
    this.updateChartFromSelectedDateRange = this.updateChartFromSelectedDateRange.bind(this);
  }

  componentWillMount() {
    let tempData = this.props.chartData;
    let dateArr = [];
    for (let i = 0; i < tempData.length; ++i) {
      let date = new Date(tempData[i]["timestamp"]);
      dateArr.push(date);
    }
    let fromDate = Math.min.apply(null, dateArr);
    let toDate = Math.max.apply(null, dateArr);
    this.setState({
      fromDate,
      toDate
    });
  }

  updateChartFromSelectedDateRange(toDate, fromDate) {
    let tempData = this.props.chartData;
    let tempData2 = [];
    for (let index = 0; index < tempData.length; ++index) {
      let date = new Date(tempData[index]["timestamp"]);
      fromDate = new Date(fromDate);
      toDate = new Date(toDate);
      if (date > fromDate && date < toDate) {
        // tempData2.push(tempData[index]);
      }
    }
    for (var d = new Date(fromDate); d <= toDate; d.setDate(d.getDate() + 1)) {
      var flag = false;

      for (let index = 0; index < tempData.length; ++index) {
        let date = new Date(tempData[index]["timestamp"]);
        var firstTemp = String(d)
        firstTemp = firstTemp.split(" ");
        var secondTemp = String(date);
        secondTemp =secondTemp.split(" ");
        if(firstTemp[1] === secondTemp[1] && firstTemp[2] === secondTemp[2] && firstTemp[3] === secondTemp[3] ){
          tempData2.push(tempData[index]);
          flag = true;
        }
      }
      if(flag === false){
        var obj = {};
        obj['Count'] = 0;
        var tempD = String(d);
        var ff =tempD.split(" ");

        obj['Date'] = ff[2] + ff[1] + ff[3]
        obj['timestamp'] = String(d);
        tempData2.push(obj);
      }
    }    
    if (tempData2.length === 0) this.setState({ data: this.props.chartData });
    else this.setState({ data: tempData2 });
  }

  render() {
    if (this.state.data.length === 0) {
      return (
        <div className="chart-container">

          <DashboardDonorChartFilter
            updateChartFromSelectedDateRange={this.updateChartFromSelectedDateRange}
            fromDate={this.state.fromDate} toDate={this.state.toDate} />

          <div className="clear">
            <ResponsiveContainer height="100%" width="95%">
              <LineChart width={900} height={450} data={this.props.chartData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <XAxis dataKey="Date" />
                <YAxis label="Donors"/>
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Count"
                  stroke="#82ca9d" strokeWidth={3} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    } else {
      return (
        <div className="chart-container">

          <DashboardDonorChartFilter
            updateChartFromSelectedDateRange={this.updateChartFromSelectedDateRange}
            fromDate={this.state.fromDate} toDate={this.state.toDate} />

          <div className="clear">
            <ResponsiveContainer height="100%" width="95%">
              <LineChart width={900} height={450} data={this.state.data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <XAxis dataKey="Date" />
                <YAxis label="Donors"/>
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <Tooltip />
                <Legend />
                <Line type="linear" dataKey="Count"
                  stroke="#82ca9d" strokeWidth={3} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    }
  }
}
