import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import ReactPlayer from 'react-player';
import moment from 'moment';

export default class ImageModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imgArray: this.props.imgArray,
			imgIndex: (this.props.imageIndex !== undefined ? this.props.imageIndex : 0),
			typeOf: (this.props.typeOf !== undefined ? this.props.typeOf : 'image')

		}
	}

	componentDidMount() {
		if(!this.props.openModal) {
			this.setState({ imgIndex: 0 });
		}
	}

	showNext(e) {
		let count = this.state.imgIndex;
		count = count + 1;
		if (count > this.props.imgArray.length - 1) {
			this.setState({ imgIndex: count - this.props.imgArray.length });
		} else {
			this.setState({ imgIndex: count });
		}
	}

	showPrev() {
		let count = this.state.imgIndex;
		count = count - 1;
		if (count < 0) {
			this.setState({ imgIndex: count + this.props.imgArray.length });
		} else {
			this.setState({ imgIndex: count });
		}
	}

	render() {
		return (
			<div className="im-updates-images-modal-container">
				<div className="modal-left">
					<a className="prev" onClick={this.showPrev.bind(this)}>&#10094;</a>
					<a className="next" onClick={this.showNext.bind(this)}>&#10095;</a>
					{this.state.typeOf === 'video' ? (
						<ReactPlayer url={this.state.imgArray[this.state.imgIndex].url} width="100%" height="100%" />
					) : (
							this.state.imgArray[this.state.imgIndex].hasOwnProperty('url') ? (
								<img src={this.state.imgArray[this.state.imgIndex].url} className="modal-img-settings" />
							) : (
									<img src={this.state.imgArray[this.state.imgIndex]} className="modal-img-settings" />
								)
						)}

				</div>
				<div className="modal-right">
					<div className="modal-right-content">
						<div className="modal-img-container"></div>
					</div>
					<div className="modal-user-name">

						{(this.state.imgArray[this.state.imgIndex].title !== undefined
							?
							this.state.imgArray[this.state.imgIndex].title
							:
							null
						)

						}
					</div>
					<div style={{ float: 'right', margin: '10px', fontWeight: '600' }}>
						{(this.state.imgIndex + 1) + "/" + (this.state.imgArray.length)}
					</div>
					<br />
					<div><span className="image-details">{moment(this.state.imgArray[this.state.imgIndex].date).format('MMM Do YY, h:mm a')}</span></div>
					<div className="modal-image-description">

						{
							(this.state.imgArray[this.state.imgIndex].description !== undefined
								?
								this.state.imgArray[this.state.imgIndex].description
								:
								null
							)
						}
					</div>
				</div>

			</div>
		)

	}
}