import React, {Component} from 'react';
import $ from 'jquery';
import moment from 'moment';
// import swal from 'sweetalert2';

const readCookie = require('../../partials/cookie.js').readCookie;

const createFormToView = require('../../../scripts/formBuilder/update.js').createFormToView;
const submitForm = require('../../../scripts/formBuilder/update.js').submitForm;

export default class FBUpdateFormDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: null,
			formDetailsData: null,
			message: ''
		}
	}

	componentDidMount(){
		var x = $.getScript('/js/formBuilder/viewElements.js');
		var y = $.getScript('/js/formBuilder/lib/star-rating.js');
		document.write('<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.99.0/css/materialize.min.css">');
		fetch(process.env.REACT_APP_API_URL + '/fb/getupdate/response/' + this.props.match.params['ngoId'] + '/' + this.props.match.params['formId'] + '/' + this.props.match.params['responseId'], {
			method: 'GET',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			let formDetailsData = data.data
			this.setState({formDetailsData}, ()=>{
				fetch(process.env.REACT_APP_API_URL + '/fb/get/form/' + this.props.match.params['formId'], {
					method: 'GET',
				}).then(data => data.json())
				.then(data => {
					if(data.status === 'ok'){
						var that = this;
						Promise.all([x, y]).then(function() {
							that.setState({form: data.data}, function(){
								that.setFormDataPriorElements(formDetailsData, data.data);
								that.setFormDataPostElements(formDetailsData);
								window.updateFormUrl = process.env.REACT_APP_API_URL + '/fb/update/response/' + that.props.match.params['ngoId'] + '/' + that.props.match.params['formId'] + '/' + that.props.match.params['responseId'];
								window.fileUrl = process.env.REACT_APP_API_URL + '/fb/uploadfile/' + that.props.match.params['formId'];
								window.access_token = JSON.parse(readCookie('access_token')).access_token;
								window.form = data.data;
								if(document.getElementById('testing') !== null) {
									document.getElementById('testing').addEventListener("click", function() {
										submitForm(that.props.match.params['formId']);
									});
								}
							});
						});
					} else if(data.status === 'error'){
						this.setState({message: data.message});
					}
				})
			})
		})
	}

	setFormDataPriorElements(formData, form) {
		if(formData !== null) {
			for(var i = 0; i < formData.length; i++){
				if(formData[i]['type'] === 'location'){
					let currentLocationIndex = i;
					if(formData[i]['locationType'] === "mappointer"){
						window['locationMapPointer' + currentLocationIndex] = {
							lat: formData[i]['answer']['latitude'],
							lng: formData[i]['answer']['longitude']
						}
					} else if(formData[i]['locationType'] === "searchbox" && formData[i].hasOwnProperty('answer')){
						let searchboxIndex = i;
						window['locationSearchBox' + searchboxIndex] = formData[i]['answer']['location_name'];
					}
				} else if(formData[i]['type'] === 'distance'){
					let currentDistanceIndex = i;
					window['distacePointers' + currentDistanceIndex] = {
						latLng1: formData[i]['answer']['latLng1'],
						latLng2: formData[i]['answer']['latLng2'],
						distance: formData[i]['answer']['distance']
					}
				}
			}
			createFormToView(form, 'testing');
		}
	}

	setFormDataPostElements(formData){
		if(formData !== null) {
			for(var i = 0; i < formData.length; i++){
				var $ele = $('._element-container-view ._form-element[data-questionid=' + formData[i]['question_id'] + ']');
				if(formData[i]['type'] === 'textbox') {
					$($ele).find('input#textbox').val(formData[i]['answer']);
				} else if(formData[i]['type'] === 'textarea'){
					$($ele).find('textarea#textarea').val(formData[i]['answer']);
				} else if(formData[i]['type'] === 'name') {
					$($ele).find('input#first_name').val(formData[i]['answer']['first_name']);
					$($ele).find('input#last_name').val(formData[i]['answer']['last_name']);
				} else if(formData[i]['type'] === 'choice'){
					if(formData[i]['choiceType'] === 'checkbox'){
						$($ele).find("input[type='checkbox']").each(function() {
							if(formData[i]['answer'].indexOf($(this).next('label').text()) > -1) {
								$(this).prop('checked', true);
							}
						});
					} else if(formData[i]['choiceType'] === 'radio'){
						$($ele).find('input[type="radio"]').each(function() {
							if(formData[i]['answer'] === $(this).next('label').text()) {
								$(this).prop('checked', true);
							}
						})
					} else if(formData[i]['choiceType'] === 'dropdown'){
						$($ele).find('select option').each(function(){
							if($(this).text() === formData[i]['answer']){
								$(this).prop('selected', 'selected');
							}
						})
					}
				} else if(formData[i]['type'] === 'payment'){
					if(Array.isArray(formData[i]['answer'])){
						$($ele).find("input[type='radio']").each(function(key, index) {
							if(formData[i]['answer'][0] === key) {
								$(this).prop('checked', true);
							}
						});
					} else {
						$($ele).find("input[type='radio']").each(function(key, index) {
							if(formData[i]['answer'] === $(this).next('label').text().replace('Rs. ', '')) {
								$(this).prop('checked', true);
							}
						});
					}
				} else if(formData[i]['type'] === 'yesno') {
					$($ele).find('input[type="radio"]').each(function() {
						if(formData[i]['answer'] === $(this).next('label').text()) {
							$(this).prop('checked', true);
						}
					})
				} else if(formData[i]['type'] === 'correlation') {
					if(typeof formData[i]['answer'] === 'string') {
						$($ele).find('select').val(formData[i]['answer']);
					} else if(Array.isArray(formData[i]['answer'])) {
						console.log($($ele).find('ul.multiselect-container li'));
						$($ele).find('ul.multiselect-container li').each(function() {
							console.log($(this))
							for(var j = 0; j < formData[i]['answer'].length; j++){
								if(formData[i]['answer'][j] === $(this).find('a').find('label').attr('title')){
									$(this).addClass('active');
								}
							}
						})
					}
				} else if(formData[i]['type'] === 'address'){
					$($ele).find('select#country').html('')
					var countryId, stateId, addressAnswer = formData[i]['answer'];
					var addressElement = $ele;
					$.get('https://test.letsendorse.com/getlocation?type=0').then(function(data) {
						for(var countryIndex = 0; countryIndex < data.length; countryIndex++) {
							$(addressElement).find('select#country').append('<option value="' + data[countryIndex]['name'] + '" countryId="' + data[countryIndex]['id'] + '">' + data[countryIndex]['name'] + '</option>');
							if(addressAnswer['country'] === data[countryIndex]['name']) countryId = data[countryIndex]['id'];
						}
						$(addressElement).find('select#country').val(addressAnswer['country']);
						$.get('https://test.letsendorse.com/getlocation?type=1&countryId=' + countryId).then(function(stateData) {
							for(var stateIndex = 0; stateIndex < stateData.length; stateIndex++) {
								$(addressElement).find('select#state').append('<option value="' + stateData[stateIndex]['name'] + '" stateId="' + stateData[stateIndex]['id'] + '">' + stateData[stateIndex]['name'] + '</option>');
								if(addressAnswer['state'] === stateData[stateIndex]['name']) stateId = stateData[stateIndex]['id'];
							}
							$(addressElement).find('select#state').val(addressAnswer['state']);
							$.get('https://test.letsendorse.com/getlocation?type=2&stateId=' + stateId).then(function(cityData) {
								for(var cityIndex = 0; cityIndex < cityData.length; cityIndex++) {
									$(addressElement).find('select#city').append('<option value="' + cityData[cityIndex]['name'] + '" cityId="' + cityData[cityIndex]['id'] + '">' + cityData[cityIndex]['name'] + '</option>');
								}
								$(addressElement).find('select#city').val(addressAnswer['city']);
							});
						});
					});
					$($ele).find('input#zipcode').val(formData[i]['answer']['pincode']);
					$($ele).find('input#area').val(formData[i]['answer']['area']);
					$($ele).find('input#street').val(formData[i]['answer']['street']);
				} else if(formData[i]['type'] === 'phone'){
					$($ele).find('input#phone').val(formData[i]['answer']);
				} else if(formData[i]['type'] === 'date'){
					moment($($ele).find('input#datepicker').val(formData[i]['answer']), 'DD MMM YYYY').format('DD/MM/YYYY');
				} else if(formData[i]['type'] === 'time'){
					$($ele).find('input#timepicker').val(formData[i]['answer']);
				} else if(formData[i]['type'] === 'email'){
					$($ele).find('input#email').val(formData[i]['answer']);
				} else if(formData[i]['type'] === 'number'){
					$($ele).find('input#number').val(formData[i]['answer']);
				} else if(formData[i]['type'] === 'website'){
					$($ele).find('input#website').val(formData[i]['answer']);
				} else if(formData[i]['type'] === 'currency'){
					$($ele).find('input#currency').val(formData[i]['answer']);
				} else if(formData[i]['type'] === 'scale'){
					$($ele).find('input#scale-element').val(formData[i]['answer']);
				} else if(formData[i]['type'] === 'rating'){
					// console.log(formData[i]['answer'], $($ele).find('.my-rating').starRating('getRating'))
					$($ele).find('.my-rating').starRating('setRating', formData[i]['answer']);
				} else if(formData[i]['type'] === 'file') {
					if(formData[i]['answer'].hasOwnProperty('url') && formData[i]['answer'].url !== ''){
						let websiteElement = '<div class="show-file col-md-12" style="margin: 5px 0px; color: #ef5a20"><div>'+ formData[i]['answer'].url +'</div></div>'
						$(websiteElement).insertAfter($($ele).find('div.col-md-12'));
						$(($ele)).find('i.url-delete').attr('onclick', 'deleteOption()');
						// <i class="fa fa-trash url-delete" style="font-size: 20px; margin-left: 10px; cursor: pointer;"></i>
					} else if(formData[i]['answer'].hasOwnProperty('urls') && formData[i]['answer'].urls.length){
						let websiteElement = ''
						for(var m = 0; m < formData[i]['answer'].urls.length; m++){
							websiteElement += '<div class="show-files-'+ m +' col-md-12" style="margin: 5px 0px; color: #ef5a20"><div>'+ formData[i]['answer']['urls'][m] +'</div></div>'
							// <i class="fa fa-trash urls-delete" style="font-size: 20px; margin-left: 10px; cursor: pointer;"></i>
						}
						$(websiteElement).insertAfter($($ele).find('div.col-md-12'));
					}
				}
			}
		}
	}

	render() {
		return (
			<div className="form-builder-container form-publish">
				{this.state.message !== '' ? (
					<div className="show-form-error">
						<i className="fa fa-exclamation-triangle fb-error-icon"></i>
						<div className="form-error-message">{this.state.message + ' !!'}</div>
					</div>
				) : (
					this.state.form !== null ? (
						<div className="_form-outlet">
							<div className="_form-holder">
								<div className="_mb10 _form-details">
									<div className="row">
										<div className="col s12 _form-name"><p className="_form-name-element"></p></div>
									</div>
								</div>
								<div className="_mb10 _header-container-view"></div>
								<div className="_mb10 _element-container-view" id="elementContainer"></div>
							</div>
						</div>
					) : (null)
				)}
			</div>
		);
	}
}
