import React, { Component } from 'react';
import $ from 'jquery';
import Sidebar from './0-menus/Sidebar';
import TopMenu from './0-menus/TopMenu';
import swal from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

const createCookie = require('./cookie.js').createCookie;
const readCookie = require('./cookie.js').readCookie;
const eraseCookie = require('./cookie.js').eraseCookie;

// const email_validator = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
const email_validator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ngo: null,
      time: null,
      date: null,
      show: false,
      userData: null,
      portalLocked: false,
      knowMoreError: false,
      forgotPassword: false,
      showContactSuccess: false,
      forgotPasswordEmail: null,
      submittedForgotPassword: false,
      forgotPasswordEmailValid: true,
      donorDonationSwitchChecked: true,
    };
    this.checkUser = this.checkUser.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.tickTimer = this.tickTimer.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.signupUser = this.signupUser.bind(this);
    this.lockPortal = this.lockPortal.bind(this);
    this.unlockPortal = this.unlockPortal.bind(this);
    this.updateNgoData = this.updateNgoData.bind(this);
  }

  checkUser() {
    if (readCookie('userData') !== null && readCookie('ngo') !== null) {
      if (readCookie('sahajVersion') !== null) {
        if (readCookie('sahajVersion') === process.env.REACT_APP_VERSION) {
          this.setState({
            userData: JSON.parse(readCookie('userData')),
            ngo: JSON.parse(readCookie('ngo'))
          });
        } else {
          eraseCookie('userData');
          eraseCookie('ngo');
          eraseCookie('access_token');
          eraseCookie('sahajVersion');
          this.setState({ userData: null, ngo: null });
          window.location.pathname = '';
        }
      } else {
        eraseCookie('userData');
        eraseCookie('ngo');
        eraseCookie('access_token');
        eraseCookie('sahajVersion');
        this.setState({ userData: null, ngo: null });
        window.location.pathname = '';
      }
      if (readCookie('portalLocked') !== null) {
        this.setState({
          portalLocked: readCookie('portalLocked')
        })
      }
    } else {
      fetch(process.env.REACT_APP_API_URL + '/check_user')
        .then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw Error(response.statusText);
          }
        }).then(function (data) {
          this.setState({
            userData: data['userData'],
            ngo: data['ngo'],
          });
        }.bind(this)).catch(function (error) {
          this.setState({ userData: null });
        }.bind(this));
    }
  }

  signupUser(e) {
    e.preventDefault();
    let ngoName = $('#signup-form #ngo-name').val();
    let orgType = $('#signup-form #org-type').val();
    let email = $('#signup-form #new-user-email').val();
    let mobile = $('#signup-form #new-user-mobile').val();

    if (ngoName !== '' && orgType !== '' && email !== '' && email.match(email_validator) !== null && mobile !== '') {
      this.setState({ knowMoreError: false });
      $('#signup-spinner').removeClass('hidden');
      $('#signup-span').addClass('hidden');
      let data = {
        'ngoName': ngoName,
        'orgType': orgType,
        'email': email,
        'mobile': mobile
      }
      fetch(process.env.REACT_APP_API_URL + '/new_user_contact', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        if (data['success']) {
          swal({
            title: 'Hi!',
            html: '<h4>We appreciate your interest</h4><br />We will get back to you within the next <b>24hrs</b>',
            type: 'success'
          });
          $('#signup-spinner').addClass('hidden');
          $('#signup-span').removeClass('hidden');
        }
      });
    } else {
      this.setState({ knowMoreError: true });
    }
  }

  updateUser(first_name, last_name, profile_picture) {
    var userData = this.state.userData;
    if (first_name !== '') userData['first_name'] = first_name;
    if (last_name !== '') userData['last_name'] = last_name;
    if (profile_picture !== '') userData['profile_picture'] = profile_picture;
    this.setState({ userData: userData });
    eraseCookie('userData');
    createCookie('userData', JSON.stringify(userData), 7);
  }

  lockPortal() {
    this.setState({ portalLocked: true });
    createCookie('portalLocked', true, 7);
  }

  unlockPortal() {
    // fetch(process.env.REACT_APP_API_URL + '/check_user')
    this.setState({ portalLocked: false });
    createCookie('portalLocked', false, 7);
  }

  loginUser(e) {
    e.preventDefault();
    let email = $('#login-form #email').val();
    let password = $('#login-form #password').val();

    if (email !== '' && email.match(email_validator) !== null && password !== '') {
      $('#login-spinner').removeClass('hidden');
      $('#login-span').addClass('hidden');
      let data = {
        'email': email,
        'password': password
      };
      //http://sahajdevapi.letsendorse.com
      fetch(process.env.REACT_APP_API_URL + '/login', {
        //fetch('http://sahajdevhapi.letsendorse.com' + '/login', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        if (data['user'] !== undefined) {
          createCookie('access_token', JSON.stringify(data['access_token']), 7);
          createCookie('userData', JSON.stringify(data['user']), 7);
          createCookie('ngo', JSON.stringify(data['ngo']), 7);
          createCookie('mis_data', JSON.stringify(data['mis']), 7);
          createCookie('sahajVersion', process.env.REACT_APP_VERSION, 7);
          window.location.reload(true);
        } else if (data['invalid']) {
          if (data['invalid'] === 'Password') {
            $('#login-form #password').addClass('wrong');
            $('#login-form #password').siblings('label').addClass('wrong');
            $('#login-spinner').addClass('hidden');
            $('#login-span').removeClass('hidden');
            $('.wrong-password').css('display', 'block');
            $('.wrong-email').hide();
          } else if (data['invalid'] === 'Email') {
            $('#login-form #email').addClass('wrong');
            $('#login-form #email').siblings('label').addClass('wrong');
            $('#login-spinner').addClass('hidden');
            $('#login-span').removeClass('hidden');
            $('.wrong-email').css('display', 'block');
            $('.wrong-password').hide();
          }
        }
      });
    } else {
      if (email === '' || email.match(email_validator) === null) {
        $('#login-form #email').addClass('wrong');
        $('#login-form #email').siblings('label').addClass('wrong');
        $('#login-spinner').addClass('hidden');
        $('#login-span').removeClass('hidden');
        $('.wrong-email').css('display', 'block');
        $('.wrong-password').hide();
      } else if (password === '') {
        $('#login-form #password').addClass('wrong');
        $('#login-form #password').siblings('label').addClass('wrong');
        $('#login-spinner').addClass('hidden');
        $('#login-span').removeClass('hidden');
        $('.wrong-password').css('display', 'block');
        $('.wrong-email').hide();
      }
    }
  }

  logoutUser() {
    fetch(process.env.REACT_APP_API_URL + '/logout', {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      eraseCookie('userData');
      eraseCookie('ngo');
      eraseCookie('mis_data');
      eraseCookie('access_token');
      eraseCookie('sahajVersion');
      this.setState({
        userData: null,
        ngo: null
      });
      window.location.pathname = '';
    }.bind(this)).catch(function (error) {
      console.log('There has been a problem with your fetch operation: ' + error.message);
    });
  }

  updateNgoData() {
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.state.ngo.ngo_id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      var ngo = data['ngo'];
      this.setState({ ngo });
      createCookie('ngo', JSON.stringify(ngo), 7);
    }.bind(this));
  }

  tickTimer() {
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var newTime = new Date().toLocaleTimeString();
    var newDate = new Date().toLocaleDateString('en-US', options);
    this.setState({
      time: newTime,
      date: newDate
    });
  }

  setLoginForm() {
    $(document).ready(function () {
      $('.form').find('input, textarea').on('keyup blur focus', function (e) {
        var $this = $(this), label = $this.prev('label');
        if ($(this).hasClass('wrong')) $(this).removeClass('wrong');
        if (e.type === 'keyup') {
          if ($this.val() === '') label.removeClass('highlight');
          else label.addClass('active highlight');
        } else if (e.type === 'blur') {
          if ($this.val() === '') label.removeClass('active highlight');
          else label.removeClass('highlight');
        } else if (e.type === 'focus') {
          label.addClass('active highlight');
        }
      });

      $('body').click();

      if ($('input#email').val() !== '') {
        if (!$('input#email').siblings('label').hasClass('active')) $('input#email').siblings('label').addClass('active');
      }
      if ($('input#password').val() !== '') {
        if (!$('input#password').siblings('label').hasClass('active')) $('input#password').siblings('label').addClass('active');
      }
    });
  }

  componentDidMount() {
    this.setLoginForm();
  }

  componentDidUpdate() {
    this.setLoginForm();
  }

  change() {
    if ($('#login-form #email').siblings('label').hasClass('wrong')) {
      $('#login-form #email').siblings('label').removeClass('wrong');
      $('.wrong-email').hide();
    }
    if ($('#login-form #password').siblings('label').hasClass('wrong')) {
      $('#login-form #password').siblings('label').removeClass('wrong');
      $('.wrong-password').hide();
    }
  }

  onChangeSwitch() {
    console.log('switch triggered')
    this.setState({ donorDonationSwitchChecked: !this.state.donorDonationSwitchChecked })
  }

  forgotPassword() {
    this.setState({ forgotPassword: !this.state.forgotPassword });
  }

  changeForgotPasswordEmail(e) {
    console.log(e.target.value)
    this.setState({ forgotPasswordEmail: e.target.value, forgotPasswordEmailValid: email_validator.test(e.target.value) });
  }

  forgotPasswordSubmit() {
    if (this.state.forgotPasswordEmail !== '' && email_validator.test(this.state.forgotPasswordEmail)) {
      let data = {
        email: this.state.forgotPasswordEmail,
        resetUrl: window.location.href + 'reset_password/'
      }
      this.setState({ submittedForgotPassword: true });
      fetch(process.env.REACT_APP_API_URL + '/forgot_password', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        if (data.status === 'ok') {
          swal({
            title: 'Request approved!',
            text: 'Please check your email. We have sent you a link for resetting your password.',
            type: 'success'
          });
        }
        setTimeout(function () {
          this.setState({
            forgotPassword: false,
            forgotPasswordEmail: null,
            submittedForgotPassword: false
          });
        }.bind(this), 1000);
      }.bind(this));
    } else {
      this.setState({ forgotPasswordEmailValid: false });
    }
  }

  render() {
    if (this.state.userData === null && this.state.ngo === null) {
      return (
        <div id="login-container">
          <div className="sahaj-front sahaj-front-mobile">
            <div id="sahaj-heading">Sahaj</div>
            <div id="sahaj-quote">Build, Nurture and Strengthen your valuable relationships</div>
            <div id="points">
              <div className="point point-mobile">
                <div className="icon icon-mobile"><i className="fa fa-2x fa-cog" aria-hidden="true"></i></div>
                <div className="heading heading-mobile">Instant set-up</div>
                <div className="desc desc-mobile">Sign-up and get going.</div>
              </div>
              <div className="point point-mobile">
                <div className="icon icon-mobile"><i className="fa fa-2x fa-thumbs-up" aria-hidden="true"></i></div>
                <div className="heading heading-mobile">Friendly to use</div>
                <div className="desc desc-mobile">Easy navigation, neat interfaces, intuitive.</div>
              </div>
              <div className="point point-mobile">
                <div className="icon icon-mobile"><i className="fa fa-2x fa-inr" aria-hidden="true"></i></div>
                <div className="heading heading-mobile">Affordable</div>
                <div className="desc desc-mobile">Simple pricing plans, no hidden costs.</div>
              </div>
            </div>
            <div id="sahaj-quote-small">Start transforming the way you manage data, relationships and communications from today!</div>
          </div>
          {(() => {
            if (this.state.forgotPassword === false) {
              return (
                <div className="form form-mobile">
                  <ul className="tab-group tab-group-mobile">
                    <li className="tab active">
                      <a href="#login">Log In</a>
                    </li>
                    <li className="tab">
                      <a href="https://www.letsendorse.com/sahaj-for-ngos?utm_source=Home&utm_medium=Menu&utm_campaign=redir#Know-more">Know More</a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="login">
                      <h1>Welcome Back!</h1>
                      <form id="login-form" onSubmit={this.loginUser}>
                        <div className="field-wrap">
                          <label> Email Address<span className="req">*</span> </label>
                          <input id="email" type="email" required autoComplete="off" onChange={this.change} />
                        </div>
                        <div className="field-wrap">
                          <label> Password<span className="req">*</span> </label>
                          <input id="password" type="password" required autoComplete="off" onChange={this.change} />
                        </div>
                        <div className="field-wrap">
                          <span className="red-color wrong-email">Wrong email. Try again!</span>
                          <span className="red-color wrong-password">Wrong password. Try again!</span>
                        </div>
                        <button className="button button-block">
                          <i id="login-spinner" className="fa fa-spinner fa-spin hidden"></i>
                          <span id="login-span">Log In</span>
                        </button>
                        <a className="forgot">
                          <span id="forgot-span" onClick={this.forgotPassword.bind(this)}>Forgot Password?</span>
                        </a>
                      </form>
                    </div>
                    <div id="signup">
                      <h1>Know Much More</h1>
                      <form id="signup-form" onSubmit={this.signupUser}>
                        <div className="field-wrap">
                          <label> Name of your organization<span className="req">*</span> </label>
                          <input id="ngo-name" type="text" required autoComplete="off" />
                        </div>
                        <div className="field-wrap">
                          <select id="org-type" required>
                            <option value="" selected disabled>Select Type Of Organisation *</option>
                            <option value="NGO">NGO</option>
                            <option value="Social Enterprise">Social Enterprise</option>
                            <option value="Business">Business</option>
                          </select>
                        </div>
                        <div className="field-wrap">
                          <label> Your Email Address<span className="req">*</span> </label>
                          <input id="new-user-email" type="email" required autoComplete="off" />
                        </div>
                        <div className="field-wrap">
                          <label> Your Mobile Number<span className="req">*</span> </label>
                          <input id="new-user-mobile" type="tel" required autoComplete="off" />
                        </div>
                        <div className="field-wrap text-center" hidden={!this.state.knowMoreError}>
                          <span className="required-span">Invalid details entered!</span>
                        </div>
                        <button type="submit" className="button button-block">
                          <i id="signup-spinner" className="fa fa-spinner fa-spin hidden"></i>
                          <span id="signup-span">Contact</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="form forgot form-mobile">
                  <div className="tab-content">
                    <h2>Forgot Password</h2>
                    <div id="forgot-password">
                      <div className="field-wrap">
                        <label> Email Address<span className="req">*</span> </label>
                        <input id="email" type="email" required autoComplete="off" onChange={this.changeForgotPasswordEmail.bind(this)} />
                        <span hidden={this.state.forgotPasswordEmailValid}><span className="required-span"> Incorrect Email entered! </span></span>
                      </div>
                      <button className="button button-block" onClick={this.forgotPasswordSubmit.bind(this)}>
                        <span id="login-span">Submit</span>
                        <i className="fa fa-check" hidden={!this.state.submittedForgotPassword}></i>
                      </button>
                      <h4>Enter your registered email and a new password link will be sent to the registered email.</h4>
                      <a className="forgot">
                        <span id="forgot-span" onClick={this.forgotPassword.bind(this)}>
                          <i className="fa fa-chevron-left"></i> Back
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              );
            }
          })()}
        </div>
      );
    } else {
      return (
        <div>
          <Sidebar userData={this.state.userData}
            lockPortal={this.lockPortal}
            ngo={this.state.ngo} />
          <TopMenu userData={this.state.userData}
            logoutUser={this.logoutUser}
            ngo={this.state.ngo} />
          {React.cloneElement(this.props.children, { onChangeSwitch: this.onChangeSwitch.bind(this), donorDonationSwitchChecked: this.state.donorDonationSwitchChecked, ngo: this.state.ngo, userData: this.state.userData, updateUser: this.updateUser, updateNgoData: this.updateNgoData })}
        </div>
      );
    }
  }
}
