import React, { Component } from 'react';

export default class ImageComponents extends Component{
    /* 
    1. props passed are: 
     [scaleComponent, backgroundImage, boxType]

    2. images specifications for scaleComponent
        0 -> inside chips (very small images)
        1 -> inside new update and modal (smaller images)
        2 -> inside attachment /thumbnail (small images)
        3 -> profile updates images (medium size images)
    
    3. boxType props specification
        'CIRCLE', 'SQUARE', 'RECTANGLE'
    
    4. no functions attached to this component

    */

    constructor(props){
        super(props);
        this.state = {
            style: null,
            
        }
    }

    checkBoxType(){
        console.log("boxType", this.props.boxType)
        switch (this.props.boxType) {
            case 'CIRCLE':
                this.setState({
                    style: {
                        border: '0.1em solid #FA4600',
                        width: (this.props.scaleComponent === 3 ? '5em' : '50px'),
                        height: (this.props.scaleComponent === 3 ? '5em' : '50px'),
                        borderRadius: '50%',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: "url(" + this.props.backgroundImage + ")",
                //"url('http://bpic.588ku.com/element_origin_min_pic/17/09/19/28e7312d32356cabb08cb36d5d232492.jpg')",
              
                    }
                })
                return this;

            case 'SQUARE':
                this.setState({
                    style: {
                        border: '0.1em solid #FA4600',
                        width: (this.props.scaleComponent === 3 ? '5em' : '50px'),
                        height: (this.props.scaleComponent === 3 ? '5em' : '50px'),
                        borderRadius: '4px',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: "url(" + this.props.backgroundImage + ")",
                //"url('http://bpic.588ku.com/element_origin_min_pic/17/09/19/28e7312d32356cabb08cb36d5d232492.jpg')",
            
                    }
                })
                return this;

            case 'RECTANGLE':
                this.setState({
                    style: {
                        border: '0.1em solid #FA4600',
                        width: (this.props.scaleComponent === 3 ? '5em' : '50px'),
                        height: (this.props.scaleComponent === 3 ? '5em' : '50px'),
                        borderRadius: '50%',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: "url(" + this.props.backgroundImage + ")",
                //"url('http://bpic.588ku.com/element_origin_min_pic/17/09/19/28e7312d32356cabb08cb36d5d232492.jpg')",
            
                    }
                })
                return this;

            default:
                return this;
          }
    }

    componentDidMount(){
        //first check for boxType props
        //this.checkBoxType.bind(this);
        switch (this.props.boxType) {
            case 'CIRCLE':
                
                this.setState({
                    style: {
                        border: '0.1em solid #FA4600',
                        width: (this.props.scaleComponent === 3 ? '5em' : '50px'),
                        height: (this.props.scaleComponent === 3 ? '5em' : '50px'),
                        borderRadius: '50%',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: "url(" + this.props.backgroundImage + ")",
                //"url('http://bpic.588ku.com/element_origin_min_pic/17/09/19/28e7312d32356cabb08cb36d5d232492.jpg')",
              
                    }
                })
                return this;

            case 'SQUARE':
                this.setState({
                    style: {
                        border: '0.1em solid #FA4600',
                        width: (this.props.scaleComponent === 3 ? '5em' : '50px'),
                        height: (this.props.scaleComponent === 3 ? '5em' : '50px'),
                        borderRadius: '4px',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: "url(" + this.props.backgroundImage + ")",
                //"url('http://bpic.588ku.com/element_origin_min_pic/17/09/19/28e7312d32356cabb08cb36d5d232492.jpg')",
            
                    }
                })
                return this;

            case 'RECTANGLE':
                this.setState({
                    style: {
                        border: '0.1em solid #FA4600',
                        width: (this.props.scaleComponent === 3 ? '5em' : '50px'),
                        height: (this.props.scaleComponent === 3 ? '5em' : '50px'),
                        borderRadius: '50%',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: "url(" + this.props.backgroundImage + ")",
                //"url('http://bpic.588ku.com/element_origin_min_pic/17/09/19/28e7312d32356cabb08cb36d5d232492.jpg')",
            
                    }
                })
                return this;

            default:
                return 0;
        }
       
    }

    render(){
        return(
            <div style={this.state.style} />
        )
    }
}