import React, {Component} from 'react';
import {Row, Col, ButtonGroup} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import swal from "sweetalert2";

import EditTaskModal from '../EditTaskModal';
import Comments from './Comments';

const readCookie = require('../../cookie.js').readCookie;

export default class ViewTask extends Component {

	constructor(props) {
		super(props);
		this.state = {
			taskId: null,
			task: null,
			name :  '',
			description : '',
			members : [],
			data : '',
			date : '',
			assignFor: null,
      assignForValue: '',
			assigned_to_members : [],
			assigned_to_addedIds: [],
			showEditModal: false
		}
	}

	getEndDateFormat(date) {
		date = date.split(" ")
		return(date[0] + " " +date[1] +" "+ date[2] + " " + date[3])
	}

	onEditClose = (data) => {
		this.setState({ showEditModal: false });
		if(data === 'refresh') {
			fetch(process.env.REACT_APP_API_URL + '/tasks/'+ this.props.ngo['ngo_id'], {
				method: "GET",
				headers: {
					'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
				}
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				this.setState({ data: data['tasks'], filteredData: data['tasks'].slice(0) });
			}.bind(this))
		}
	}

	onEditOpen = () => {
		this.setState({ showEditModal: true });
	}

	componentWillMount() {
		this.setState({ taskId: this.props.match.params['id'] });
		fetch(process.env.REACT_APP_API_URL + '/get_task/' + this.props.ngo['ngo_id'] + '/' + this.props.match.params['id'], {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      var task = data['task'];
      this.setState({ task });
    }.bind(this));
	}

	updateTask() {
		this.setState({ task: null }, function() {
			fetch(process.env.REACT_APP_API_URL + '/get_task/' + this.props.ngo['ngo_id'] + '/' + this.props.match.params['id'], {
	      method: "GET",
	      headers: {
	        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
	      }
	    }).then(function(response) {
	      return response.json();
	    }).then(function(data) {
	      var task = data['task'];
	      this.setState({ task });
	    }.bind(this));
  	}.bind(this));
	}

	markCompleted(value) {
		this.setState({ value_id: value });
		fetch(process.env.REACT_APP_API_URL + '/update_task/' + value, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify({ status : "completed" })
		}).then((response) => response.json())
		.then((data) => {
			fetch(process.env.REACT_APP_API_URL + '/tasks/'+ this.props.ngo['ngo_id'], {
				method: "GET",
				headers: {
					'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
				}
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				this.setState({ data: data['tasks'], filteredData: data['tasks'].slice(0) });
			}.bind(this));
		});
	}

	onDelete(value) {
		this.setState({ value_id: value.id });
		swal({
			title: 'Are you sure?',
	    text: "You won't be able to revert this!",
	    type: 'warning',
	    showCancelButton: true,
	    confirmButtonText: 'Yes, Delete!',
	    cancelButtonText: 'No, Cancel!',
	    confirmButtonClass: 'btn',
	    cancelButtonClass: 'btn',
		}).then(function (result) {
			if(result) {
				fetch(process.env.REACT_APP_API_URL + '/update_task/' + value.id, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify({ is_deleted : true })
				}).then((response) => response.json())
				.then((data) => {
					if(data) {
						fetch(process.env.REACT_APP_API_URL + '/tasks/'+ this.props.ngo['ngo_id'], {
							method: "GET",
							headers: {
								'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
							}
						}).then(function(response) {
							return response.json();
						}).then(function(data) {
							if(data['tasks']) {
								this.setState({ data: data['tasks'], filteredData: data['tasks'].slice(0) });
							} else {
								this.setState({ data: [], filteredData: [] });
							}
						}.bind(this));
					}
				})
			}
		}.bind(this));
	}

	onEdit(value) {
		this.setState({ value_id: value.id });
		this.onEditOpen();
	}


	getCompletedButton(value, id) {
		if(value !== 'Completed') {
			return(
				<button onClick={() => {this.markCompleted(id)}}
					type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'}>
					<span>Mark As Complete</span>
				</button>
			)
		}
	}

	getEditButton(value, id) {
		if(value !== 'Completed') {
			return(
				<button type='button' onClick={() => {this.onEdit(id)}}
				  className={'btn btn-success react-bs-table-add-btn hidden-print'}>
          <span>
            Edit
          </span>
				</button>
			)
		}
	}

	getStopButton(value, id) {
		if(value !== 'Completed') {
			return(
				<button type='button' onClick={() => {this.onStop(id)}}
				  className={'btn btn-success react-bs-table-add-btn hidden-print'}>
          <span>
            Stop
          </span>
				</button>
			)
		}
	}

	onStop(id) {
		swal({
			title: 'Are you sure?',
	    text: "You won't be able to revert this!",
	    type: 'warning',
	    showCancelButton: true,
	    confirmButtonText: 'Yes, Delete!',
	    cancelButtonText: 'No, Cancel!',
	    confirmButtonClass: 'btn',
	    cancelButtonClass: 'btn',
		}).then(function (result) {
			if(result) {
				fetch(process.env.REACT_APP_API_URL + '/tasks/' + this.props.ngo['ngo_id'] + '/stop_sys_task/' + id, {
					method: 'GET',
					headers: {
						'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
					},
				}).then((response) => response.json())
				.then((data) => {
					fetch(process.env.REACT_APP_API_URL + '/tasks/'+ this.props.ngo['ngo_id'], {
						method: "GET",
						headers: {
							'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
						}
					}).then(function(response) {
						return response.json();
					}).then(function(data) {
						this.setState({ data: data.tasks, filteredData: data.tasks.slice(0) });
					}.bind(this));
				});
			}
		}.bind(this));
	}

	render () {
		let backUrl = '/dm/tasks';
		let value = this.state.task;
		if(this.state.task !== null && this.state.taskId !== null) {
			return (
	      <div className="task-detail-container task-box">
	      	<Row className="mb10">
		        <span className="back">
	            <Link to={backUrl} className="back-btn"><i className="fa fa-chevron-left"></i> Go to All Tasks</Link>
		        </span>
	        </Row>
	        <Row className="mt20 mb10 single-task-container">
	          <Col md={12} className="task-header-container">
							<span className="task-title">{value.title}</span>
							<span className="task-date">
								<span className="assigned-title">Due Date: </span>
								{this.getEndDateFormat(value.due_on)}
							</span>
						</Col>

	          <Col md={12}>
	            <p className="task-desc">
								{value.description}
							</p>
	          </Col>

	          <Col md={12}>
	          	{(() => {
	              if(Object.keys(value.assigned_for).length) {
	              	if(value.assigned_for.hasOwnProperty('groups') && value.assigned_for.hasOwnProperty('users')) {
	              		if(value.assigned_for.groups.length || value.assigned_for.users.length) {
			              	return (
												<Col md={12} className="mb10">
													<Row>
			                      <div className="assigned-for assigned-desc">
			                        <span className="assigned-title">Assigned For:</span>
			                        {(() => {
			                          if('groups' in value.assigned_for) {
			                            return value.assigned_for.groups.map((group, i) => {
			                            	return (
			                            		<span key={i} className="group-tag-name" data-group-id={group.id}>{group.name}</span>
			                            	);
			                            })
			                          }
			                        })()}
			                        {(() => {
			                          if('users' in value.assigned_for) {
			                            return value.assigned_for.users.map((user, i) => {
			                            	return (
			                            		<span key={i} className="user-tag-name" data-user-id={user.id}>{user.name}</span>
			                            	);
			                            })
			                          }
			                        })()}
			                      </div>
													</Row>
												</Col>
											);
			              }
			            }
								}
							})()}
	          </Col>

	          <Col md={12} className="mt20">
	          	<Row>
	          		<Col md={4}>
	          			<div className="assigned-to assigned-desc">
										<span className="assigned-title">Assigned To:</span>
										{(() => {
		                  if('groups' in value.assigned_to) {
		                    return value.assigned_to.groups.map((group, i) => {
		                    	return (
		                    		<span key={i} className="group-tag-name" data-group-id={group.id}>{group.name}</span>
		                    	);
		              			})
		                	}
		                })()}
		                {(() => {
		                  if('users' in value.assigned_to) {
		                    return value.assigned_to.users.map((user, i) => {
		                    	return (
		                    		<span key={i} className="user-tag-name" data-user-id={user.id}>{user.name}</span>
		                    	);
		                    })
		                  }
		                })()}
		                {(() => {
		                  if('id' in value.assigned_to) {
		                    if(!value.assigned_to.id) {
		                    	return (
		                    		<span className="user-tag-name" data-user-id={value.assigned_to.id}>{value.assigned_to.name}</span>
		                    	);
		                    }
		                  }
		                })()}
									</div>
								</Col>

								<Col md={4}>
									<span className="created-by">
										<span className="assigned-title">Creator: </span>
										<span className={"assigned-desc creator"}>{value.creator_user_id['name']}</span>
									</span>
								</Col>

								<Col md={4}>
									<span className="created-by">
										<span className="assigned-title">Current Status:</span>
										<span className="assigned-desc">
	                    <span>{' ' + value.status}</span>
										</span>
	                </span>
								</Col>
	          	</Row>
	          </Col>

	          <Col md={12}>
	          	{(() => {
								if('is_system' in value) {
									return (
										<ButtonGroup className='my-custom-class task-buttons btn-group-md'>
											{this.getStopButton(value.status, value.id)}
										</ButtonGroup>
									);
								} else {
									return (
										<ButtonGroup className='my-custom-class task-buttons btn-group-md'>
											{this.getCompletedButton(value.status, value.id)}
											{this.getEditButton(value.status, value)}
											{(() => {
												if(this.props.userData.user_id === value.creator_user_id.id) {
													return (
														<button type='button' onClick={() => {this.onDelete(value)}}
													  	className={'btn btn-success react-bs-table-add-btn hidden-print'}>
															<span>Delete</span>
														</button>
													);
												}
											})()}
										</ButtonGroup>
									);
								}
							})()}
	          </Col>
	        </Row>

	        <Row>
	        	<Col md={12} className="mt20">
	        		<h4 className="assigned-title">Comments :</h4>
	        		<Comments ngo={this.props.ngo} comments={value.comments}
	        			taskId={this.state.taskId} updateTask={this.updateTask.bind(this)} />
	        	</Col>
	        </Row>

	        {this.state.showEditModal ? (
		        <EditTaskModal ngo={this.props.ngo} userData={this.props.userData} onHide={this.onEditClose}
							id={this.state.taskId} onEditClose={this.onEditClose} onEditOpen={this.onEditOpen} />
					) : (null)}
	      </div>
			);
		} else {
			return null
		}
	}
}
