import React, { Component } from 'react';
import Loader from '../../partials/Loader';
import swal from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

const readCookie = require('../cookie.js').readCookie;

export default class IMSingleProjectSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			loaderHiding: '',
			projectStatus: ""
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_MIS_API_URL + '/get-project/' + this.props.project_id, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				this.setState({ projectStatus: data.data.status });
			}.bind(this));
	}


	gotoLink(key) {
		var project_id = this.props.project_id;
		if (key !== this.props.selected) {
			if (key === 'overview') window.location.pathname = '/im/projects/' + project_id;
			else if (key === 'finances') window.location.pathname = '/im/projects/' + project_id + '/finances';
			else if (key === 'milestones') window.location.pathname = '/im/projects/' + project_id + '/milestones';
			else if (key === 'impact') window.location.pathname = '/im/projects/' + project_id + '/impact';
			else if (key === 'resources') window.location.pathname = '/im/projects/' + project_id + '/resources';
			else if (key === 'discussion') window.location.pathname = '/im/projects/' + project_id + '/discussion';
			else if (key === 'edit-project') window.location.pathname = '/im/projects/' + project_id + '/edit';
			else if (key === 'export-project') window.location.pathname = '/im/projects/' + project_id + '/export';
			else if (key === 'go-back') window.location.pathname = '/im/projects';
		}
	}

	exportProject = () => {
		this.setState({ showLoader: true, loaderHiding: '' });
		fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.project_id + '/export-project', {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				if (data.status === 'ok') {
					this.setState({ loaderHiding: 'hiding' }, function () {
						this.setState({ showLoader: false }, function () {
							swal({
								html: '<img src="/images/report_image.png" style="height: 120px; margin: 20px 0;" />' +
									'<div style="font-size: 26px; letter-spacing: 0.5px; margin: 0 0 20px;">The exported project is ready to be downloaded!</div>' +
									'<a class="btn" style="background: #3085d6; color: #fff; font-size: 22px !important; letter-spacing: 0.5px; padding: 10px 20px; width: 240px; margin: 0 0 10px;" href="' + data.pdfUrl + '" target="_blank">Download</a>',
								showCancelButton: false,
								showConfirmButton: false
							});
						});
					}.bind(this));
				}
			}.bind(this));
	}

	render() {
		let flag;
		if ((this.state.projectStatus === "Submitted") || (this.state.projectStatus === "Draft")) {
			flag = true
		} else {
			flag = false
		}
		return (
			<div className="im-project-sidebar">
				{this.state.showLoader ? (
					<Loader loaderHiding={this.state.loaderHiding} />
				) : ('')}
				<ul className="projects-sidebar-menu-items">
					<li className={this.props.selected === 'overview' ? "projects-sidebar-menu-item selected" : "projects-sidebar-menu-item"}
						onClick={this.gotoLink.bind(this, 'overview')}>
						<span className="menu-item">Overview</span>
					</li>
					<li className={this.props.selected === 'finances' ? "projects-sidebar-menu-item selected" : "projects-sidebar-menu-item"}
						onClick={this.gotoLink.bind(this, 'finances')}>
						<span className="menu-item">Finances</span>
					</li>
					<li className={this.props.selected === 'milestones' ? "projects-sidebar-menu-item selected" : "projects-sidebar-menu-item"}
						onClick={this.gotoLink.bind(this, 'milestones')}>
						<span className="menu-item">Milestones</span>
					</li>
					<li className={this.props.selected === 'impact' ? "projects-sidebar-menu-item selected" : "projects-sidebar-menu-item"}
						onClick={this.gotoLink.bind(this, 'impact')}>
						<span className="menu-item">Impact</span>
					</li>
					<li className={this.props.selected === 'resources' ? "projects-sidebar-menu-item selected" : "projects-sidebar-menu-item"}
						onClick={this.gotoLink.bind(this, 'resources')}>
						<span className="menu-item">Resources</span>
					</li>
					<li className={this.props.selected === 'discussion' ? "projects-sidebar-menu-item selected" : "projects-sidebar-menu-item"}
						onClick={this.gotoLink.bind(this, 'discussion')}>
						<span className="menu-item">Discussion</span>
					</li>

					{flag === true ?
						(<li className="projects-sidebar-menu-item" onClick={this.gotoLink.bind(this, 'edit-project')}>
							<span className="menu-item">Edit Project</span>
						</li>) : null}
				</ul>

				<ul className="projects-sidebar-footer-menu-items">
					<li className="projects-sidebar-footer-menu-item" onClick={this.exportProject}>
						<span className="menu-item">Export Project</span>
					</li>
					<li className="projects-sidebar-footer-menu-item" onClick={this.gotoLink.bind(this, 'go-back')}>
						<span className="menu-item">Go Back</span>
					</li>
				</ul>

				<div className="projects-sidebar-footer">
					<a href="https://www.letsendorse.com" target="_blank">
						<img src="/images/le_black.png" width="150" />
					</a>
				</div>
			</div>
		);
	}
}