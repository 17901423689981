import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";

import $ from "jquery";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import PersonalInformation from "./add-donor/Personal";
import OrganizationInformation from "./add-donor/Organization";
import OtherInformation from "./add-donor/Other";
import ImportantDates from "./add-donor/ImportantDates";

const readCookie = require('../cookie.js').readCookie;

export default class AddDonor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      country: "India",
      rating: 1,
      impDateCount: 0,
      donor_type: "individual",
      title: "mr",
      first_name: "",
      last_name: "",
      phone_no: "",
      email: "",
      dob: "",
      dob_first_child: "",
      dob_second_child: "",
      dob_father: "",
      dob_mother: "",
      dob_spouse: "",
      marriage_anniversary: "",
      address1: "",
      address2: "",
      nationality: "India",
      pan_number: "",
      state: "",
      city: "",
      zipcode: "",
      donor_source: "",
      org_name: "",
      org_position: "",
      org_assignee: this.props.assignee,
      important_dates: [],
      group_ids: [],
      groups: [],
      pledged_amount: 0,
      frequency: '',
      pledge_start: '',
      showFirstNameError: false,
      showLastNameError: false,
      showPhoneError: false,
      showEmailError: false,
      showDobError: false,
      showNationalityError: false,
      custom_fields: {}
    };
    this.addDonor = this.addDonor.bind(this);
    this.updateGroups = this.updateGroups.bind(this);
    this.saveDonor = this.saveDonor.bind(this);
  }

  onDonorTypeChange(e) {
    this.setState({ donor_type: e.target.value });
  }

  onDonorTitleChange(e) {
    this.setState({ title: e.target.value });
  }

  onDonorFnameChange(e) {
    if(e.target.value === '') this.setState({ showFirstNameError: true });
    else this.setState({ showFirstNameError: false });
    this.setState({ first_name: e.target.value });
  }

  onDonorLnameChange(e) {
    if(e.target.value === '') this.setState({ showLastNameError: true });
    else this.setState({ showLastNameError: false });
    this.setState({ last_name: e.target.value });
  }

  onDonorPhoneChange(value) {
    // if(value === '') this.setState({ showPhoneError: true });
    // else this.setState({ showPhoneError: false });
    this.setState({ phone_no: value });
  }

  onDonorEmailChange(e) {
    if(e.target.value === '') this.setState({ showEmailError: true });
    else this.setState({ showEmailError: false });
    this.setState({ email: e.target.value });
  }

  onDonorDobChange(e) {
    // if(e.target.value === '') this.setState({ showDobError: true });
    // else this.setState({ showDobError: false });
    this.setState({ dob: e.target.value });
  }

  onDonorDobFirstChildChange(e) {
    this.setState({ dob_first_child: e.target.value });
  }

  onDonorDobSecondChildChange(e) {
    this.setState({ dob_second_child: e.target.value });
  }

  onDonorDobFatherChange(e) {
    this.setState({ dob_father: e.target.value });
  }

  onDonorDobMotherChange(e) {
    this.setState({ dob_mother: e.target.value });
  }

  onDonorDobSpouseChange(e) {
    this.setState({ dob_spouse: e.target.value });
  }

  onDonorAnniversaryChange(e) {
    this.setState({ marriage_anniversary: e.target.value });
  }

  onDonorSourceChange(e) {
    this.setState({ donor_source: e.target.value });
  }

  onDonorPanChange(e) {
    this.setState({ pan_number: e.target.value });
  }

  onDonorAddress1Change(e) {
    this.setState({ address1: e.target.value });
  }

  onDonorAddress2Change(e) {
    this.setState({ address2: e.target.value });
  }

  onDonorCityChange(value) {
    this.setState({ city: value });
  }

  onDonorPincodeChange(e) {
    this.setState({ zipcode: e.target.value });
  }

  onDonorOrgNameChange(e) {
    this.setState({ org_name: e.target.value });
  }

  onDonorOrgPositionChange(e) {
    this.setState({ org_position: e.target.value });
  }

  updateGroups(group_ids) {
    this.setState({ group_ids });
  }

  selectCountry = val => {
    this.setState({ country: val });
  };

  selectNationality = val => {
    if(val === '') this.setState({ showNationalityError: true });
    else this.setState({ showNationalityError: false });
    this.setState({ nationality: val });
  };

  selectState = val => {
    this.setState({ state: val });
  };

  onStarClick = (nextValue, prevValue, name) => {
    this.setState({ rating: nextValue });
  };

  addChild() {
    this.setState({ impDateCount: this.state.impDateCount + 1 });
  }

  removeChild() {
    this.setState({ impDateCount: this.state.impDateCount - 1 });
  }

  changeDonationAmount(e) {
    this.setState({ pledged_amount: e.target.value });
  }

  changeDonationFrequency(e) {
    this.setState({ frequency: e.target.value });
  }

  changeCustomFieldsValue(name, e) {
    // console.log(name, e.target.value)
    let custom_fields = this.state.custom_fields;
    custom_fields[name] = e.target.value;
    this.setState({ custom_fields });
  }

  // onDonorCategoriesChange(e) {
  //   this.setState({ donor_category: e.target.value, donor_category_name: e.target.options[e.target.selectedIndex].text });
  // }

  componentWillMount() {
    // fetch(process.env.REACT_APP_API_URL + '/donor-categories', {
    //   method: "GET",
    //   headers: {
    //     'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
    //   }
    // }).then(function(response) {
    //   return response.json();
    // }).then(function(data) {
    //   for(var i = 0; i < data.categories.length; i++) {
    //     if(data.categories[i].name === 'Donor') this.setState({ donor_category: data.categories[i].id, donor_category_name: data.categories[i].name });
    //   }
    //   this.setState({ donorCategories: data.categories });
    // }.bind(this));
    let custom_fields = this.state.custom_fields;
    for(var i = 0; i < this.props.list.custom_member_fields.length; i++) {
      custom_fields[this.props.list.custom_member_fields[i].id] = '';
    }
    this.setState({ custom_fields });
  }

  addDonor = () => {
    let donor_type = this.state.donor_type;
    let title = this.state.title;
    let first_name = this.state.first_name;
    let last_name = this.state.last_name;
    let phone_no = this.state.phone_no;
    let email = this.state.email;
    let dob = this.state.dob;
    let nationality = this.state.nationality;
    let pan_number = this.state.pan_number;
    let donor_source = this.state.donor_source;
    let address1 = this.state.address1;
    let address2 = this.state.address2;
    let country = this.state.country;
    let state = this.state.state;
    let city = this.state.city;
    let zipcode = this.state.zipcode;
    let org_name = this.state.org_name;
    let org_position = this.state.org_position;
    let org_assignee = this.state.org_assignee;
    let group_ids = this.state.group_ids;
    let priority = this.state.rating;
    let dob_first_child = this.state.dob_first_child;
    let dob_second_child = this.state.dob_second_child;
    let dob_father = this.state.dob_father;
    let dob_mother = this.state.dob_mother;
    let dob_spouse = this.state.dob_spouse;
    let marriage_anniversary = this.state.marriage_anniversary;
    let custom_fields = this.state.custom_fields;

    let donor_category_obj = {};
    donor_category_obj['id'] = window.location.href.split('/lists/')[1];

    let important_dates = [];

    $(".important-date-modal").each(function() {
      let important_date = {};
      important_date["date"] = $(this).find("input.date")[0].value;
      important_date["details"] = $(this).find("input.details")[0].value;
      if(important_date["date"] !== '' && important_date["details"] !== '') important_dates.push(important_date);
    });

    this.setState({ important_dates });

    if((first_name !== '' && last_name !== '' && email !== '' && nationality !== '') || (first_name !== '' && last_name !== '' && email !== '')) {

      let data = {
        donor_type,
        title,
        first_name,
        last_name,
        phone_no,
        donor_category: donor_category_obj,
        email,
        nationality,
        pan_number,
        donor_source,
        address1,
        address2,
        country,
        state,
        city,
        zipcode,
        org_name,
        org_position,
        org_assignee,
        priority,
        important_dates,
        custom_fields
      };

      if(dob !== null && dob !== '') data['dob'] = dob;
      if(dob_first_child !== '' && dob_first_child !== "Invalid date") data['dob_first_child'] = dob_first_child;
      if(dob_second_child !== '' && dob_second_child !== "Invalid date") data['dob_second_child'] = dob_second_child;
      if(dob_father !== '' && dob_father !== "Invalid date") data['dob_father'] = dob_father;
      if(dob_mother !== '' && dob_mother !== "Invalid date") data['dob_mother'] = dob_mother;
      if(dob_spouse !== '' && dob_spouse !== "Invalid date") data['dob_spouse'] = dob_spouse;
      if(marriage_anniversary !== '' && marriage_anniversary !== "Invalid date") data['marriage_anniversary'] = marriage_anniversary;
      if(this.state.pledged_amount !== 0) data['pledged_amount'] = this.state.pledged_amount;
      if(this.state.frequency !== '') data['frequency'] = this.state.frequency;

      group_ids.push(this.props.ngo.all_users_group_id);

      let x = 0;

      if(city === '') x += 1;
      else {
        fetch(process.env.REACT_APP_API_URL + '/get_system_group_id/' + this.props.ngo_id, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify({ name: city })
        }).then(function(response) {
          return response.json();
        }).then(function(response) {
          if(response.status === 'ok') {
            x += 1;
            group_ids.push(response.group_id);
            this.saveDonor(data, group_ids, x);
          }
        }.bind(this));
      }

      if(state === '') {
        this.saveDonor(data, group_ids, 2);
      } else {
        fetch(process.env.REACT_APP_API_URL + '/get_system_group_id/' + this.props.ngo_id, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify({ name: state })
        }).then(function(response) {
          return response.json();
        }).then(function(response) {
          if(response.status === 'ok') {
            x += 1;
            group_ids.push(response.group_id);
            this.saveDonor(data, group_ids, x);
          }
        }.bind(this));
      }
    } else {
      if(first_name === '') this.setState({ showFirstNameError: true });
      if(last_name === '') this.setState({ showLastNameError: true });
      // if(phone_no === '') this.setState({ showPhoneError: true });
      if(email === '') this.setState({ showEmailError: true });
      // if(dob === '') this.setState({ showDobError: true });
      if(nationality === '') this.setState({ showNationalityError: true });
    }
  };

  saveDonor(data, group_ids, x) {
    if(x === 2) {
      data['group_ids'] = group_ids;
      fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo_id + "/donors", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify(data)
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        if (data["success"]) {
          swal({
            title: "Saved!",
            html: "<h4>Successfully added Member to your database</h4>",
            type: "success"
          });
          this.props.onAddDonorClose('refresh');
        } else if (data["donor"] === "exists") {
          swal({
            title: "Sorry!",
            html: "<h4>Member already exists</h4>",
            type: "error"
          });
        } else if(data['status'] === 'error') {
          swal({
            title: "Sorry!",
            html: "<h4>" + data.message + "</h4>",
            type: "error"
          });
        }
      }.bind(this));
    }
  }

  handleDateChange(date) {
    this.setState({ pledge_start: date });
  }

  render() {
    return (
      <div className="page-container add-donor-page">
        {this.props.showAddDonorModal ? (
          <div>
            <h2 className="modal-header-custom">
              Add New Member
            </h2>
            <Row>
              <form name="existingDonorForm" id="existingDonorForm">
                <Col md={12}>
                  {(() => {
                    if (this.state.showFirstNameError || !this.state.showFirstNameError || this.state.showLastNameError || this.state.showEmailError || this.state.showNationalityError) {
                      return <PersonalInformation country={this.state.country}
                        state={this.state.state} nationality={this.state.nationality}
                        selectNationality={this.selectNationality} selectCountry={this.selectCountry}
                        selectState={this.selectState} donor={this.state}
                        onDonorTypeChange={this.onDonorTypeChange.bind(this)}
                        onDonorTitleChange={this.onDonorTitleChange.bind(this)}
                        onDonorFnameChange={this.onDonorFnameChange.bind(this)}
                        onDonorLnameChange={this.onDonorLnameChange.bind(this)}
                        onDonorPhoneChange={this.onDonorPhoneChange.bind(this)}
                        onDonorEmailChange={this.onDonorEmailChange.bind(this)}
                        onDonorDobChange={this.onDonorDobChange.bind(this)}
                        onDonorPanChange={this.onDonorPanChange.bind(this)}
                        onDonorSourceChange={this.onDonorSourceChange.bind(this)}
                        onDonorAddress1Change={this.onDonorAddress1Change.bind(this)}
                        onDonorAddress2Change={this.onDonorAddress2Change.bind(this)}
                        onDonorCityChange={this.onDonorCityChange.bind(this)}
                        onDonorPincodeChange={this.onDonorPincodeChange.bind(this)}
                        showFirstNameError={this.state.showFirstNameError}
                        showLastNameError={this.state.showLastNameError} onStarClick={this.onStarClick}
                        showPhoneError={this.state.showPhoneError} list={this.props.list}
                        showEmailError={this.state.showEmailError} showDobError={this.state.showDobError}
                        showNationalityError={this.state.showNationalityError} />
                    }
                  })()}
                </Col>

                {this.props.ngo.hasOwnProperty('account_type') ? (
                  this.props.ngo.account_type === 'ngo' ? (
                    <Col md={12}>
                      <OrganizationInformation assignee={this.props.assignee} donor={this.state}
                        onDonorOrgNameChange={this.onDonorOrgNameChange.bind(this)} list={this.props.list}
                        onDonorOrgPositionChange={this.onDonorOrgPositionChange.bind(this)}
                      />
                    </Col>
                  ) : (null)
                ) : (
                  <Col md={12}>
                    <OrganizationInformation assignee={this.props.assignee} donor={this.state}
                      onDonorOrgNameChange={this.onDonorOrgNameChange.bind(this)} list={this.props.list}
                      onDonorOrgPositionChange={this.onDonorOrgPositionChange.bind(this)}
                    />
                  </Col>
                )}

                <Col md={12}>
                  <OtherInformation rating={this.state.rating} changeDonationAmount={this.changeDonationAmount.bind(this)}
                    pledged_amount={this.state.pledged_amount} frequency={this.state.frequency} ngo_id={this.props.ngo_id}
                    handleDateChange={this.handleDateChange.bind(this)} pledge_start={this.state.pledge_start} list={this.props.list}
                    donor={this.state} updateGroups={this.updateGroups} changeDonationFrequency={this.changeDonationFrequency.bind(this)}
                    changeCustomFieldsValue={this.changeCustomFieldsValue.bind(this)} custom_fields={this.state.custom_fields} />
                </Col>

                <Col md={12}>
                  <ImportantDates ngo={this.props.ngo} addChild={this.addChild.bind(this)}
                    removeChild={this.removeChild.bind(this)} donor={this.state} list={this.props.list}
                    onDonorDobFirstChildChange={this.onDonorDobFirstChildChange.bind(this)}
                    onDonorDobSecondChildChange={this.onDonorDobSecondChildChange.bind(this)}
                    onDonorDobFatherChange={this.onDonorDobFatherChange.bind(this)}
                    onDonorDobMotherChange={this.onDonorDobMotherChange.bind(this)}
                    onDonorDobSpouseChange={this.onDonorDobSpouseChange.bind(this)}
                    onDonorAnniversaryChange={this.onDonorAnniversaryChange.bind(this)} />
                </Col>
              </form>
            </Row>
            <Row className="text-center">
              <Button className="btn btn-danger mr10" onClick={this.props.onAddDonorClose}>Cancel</Button>
              <Button className="btn btn-warning mr10" onClick={this.addDonor.bind(this)}>Submit</Button>
            </Row>
          </div>
        ) : (null)}
      </div>
    );
  }
}
