import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import moment from 'moment';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { DatePicker } from 'antd';

import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';

export default class DashboardDonationChartFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment(),
            previousDate: moment().subtract(30, 'days'),
            focused: false
        };
    }

    handleDateChange = (value) => {
        this.setState({ date: value });
        this.props.updateChartFromSelectedDateRange(value, this.state.previousDate);
    }

    handlePrevDateChange = (value) => {
        this.setState({ previousDate: value });
        this.props.updateChartFromSelectedDateRange(this.state.date, value);
    }

    componentDidMount = () => {
        this.props.updateChartFromSelectedDateRange(this.state.date, this.state.previousDate);
    }

    render () {
        return (
            <div className="chart-filter">
                <Row>
                    <Col md={2} className="flex-center-cont-analytics">
                        <p className="dashboard-chart-title">Donors Graph</p>
                    </Col>
                    <Col md={10}>
                        <Row className="filters">
                            <Col md={4}>
                                <div>
                                </div>
                            </Col>
                            <Col md={1}>
                                <p className="to-text">From</p>
                            </Col>
                            <Col md={3}>
                                <LocaleProvider locale={enUS}>
                                    <DatePicker locale={enUS} size='large' defaultValue={this.state.previousDate} id="donorDatePickerFrom"
                                        format="DD/MM/YYYY" allowClear={false} onChange={this.handlePrevDateChange} />
                                </LocaleProvider>
                            </Col>
                            <Col md={1}>
                                <p className="to-text">To</p>
                            </Col>
                            <Col md={3}>
                                <LocaleProvider locale={enUS}>
                                    <DatePicker locale={enUS} size='large' defaultValue={this.state.date} id="donorDatePickerTo"
                                        format="DD/MM/YYYY" allowClear={false} onChange={this.handleDateChange} />
                                </LocaleProvider>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row>
                    <Col md={2} id="currency-type"><span>Donors</span></Col>
                </Row>
            </div>
        );
    }
}
