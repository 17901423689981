import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend
} from "recharts";

// import {LineChart} from 'react-easy-chart';

export default class ImpactChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    goBack() {
        window.location.href = "/im/projects/" + this.props.project_id + "/impact";
    }

    render() {
        console.log("this.props", this.props.graphData);
        //let data = [{date: "12/09/2018", count: 1}, {date: "13/09/2018", count: 10}]
        return (
            <div>
                {
                    (this.props.visibility)
                        ?
                        <div className="clear">
                            <button className="btn btn-default btn-danger pull-right" onClick={this.goBack.bind(this)}>
                                Go Back
                    </button>
                            <ResponsiveContainer>
                                <LineChart data={this.props.graphData} width={400}
                                    margin={{ top: 5, right: 30, left: 30, bottom: 5 }}>
                                    <XAxis dataKey="date" />
                                    <YAxis label="Count" dataKey="count" />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <Tooltip />
                                    {/* <Legend /> */}
                                    <Line type="monotone" dataKey="count"
                                        stroke="#8884d8" strokeWidth={3} />
                                    {/* <Line type="monotone" dataKey="date" stroke="#82ca9d" strokeWidth={3} /> */}
                                </LineChart>

                            </ResponsiveContainer>
                        </div>
                        :
                        null
                }

            </div>
        )
    }
}