import React, {Component} from 'react';
import $ from 'jquery';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import Linkify from 'react-linkify';

const readCookie = require('../../cookie.js').readCookie;

var Comment = React.createClass({
  formatter(x, y, z) {
    if(y === 'second') return 'few seconds ago';
    else if (x === 1) return x + ' ' + y + ' ' + z;
    else return x + ' ' + y + 's ' + z;
  },
  render() {
    return (
      <div className="comment">
      	<img className="commentUser" alt={this.props.comment.created_by.name} src={this.props.comment.created_by.profile_picture} title={this.props.comment.created_by.name} />
        <div className="dialogbox">
        	<div className="body">
			      <span className="tip tip-left"></span>
			      <div className="message">
			        <Linkify properties={{target: '_blank'}}>
                <span>{this.props.comment.body}</span>
              </Linkify>
			      </div>
            <div className="timestamp">
              <span><TimeAgo date={moment(this.props.comment.created_at).toDate()} formatter={this.formatter} /></span>
            </div>
			    </div>
        </div>
      </div>
    );
  }
});

var CommentList = React.createClass({
  render() {
    var commentNodes = this.props.data.map(function(comment, index) {
      return (
        <Comment key={index} comment={comment} />
      );
    });
    return (
      <div className="commentList">
        {commentNodes}
      </div>
    );
  }
});

var CommentForm = React.createClass({
  handleSubmit(e) {
    e.preventDefault();
    var text = $('#comment-input').val().trim();
    if (!text) return;
    this.props.onCommentSubmit(text);
    $('#comment-input').val('');
    return;
  },

  render() {
    return (
      <form className="commentForm" onSubmit={this.handleSubmit}>
        <textarea placeholder="Say something..." id="comment-input"></textarea>
        <input className="btn btn-success react-bs-table-add-btn postComment" type="submit" value="Post" />
      </form>
    );
  }
});

export default class SingleDonorNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
			data: this.props.userNotes
    };
  }

	handleCommentSubmit(comment) {
		fetch(process.env.REACT_APP_API_URL + '/' + this.props.ngo.ngo_id + '/donor/' + this.props.userId + '/add_note', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify({ body: comment })
    }).then((response) => response.json())
    .then((data) => {
      if(data.status === 'ok') this.props.onAddComment();
    });
  }

  render () {
    return (
      <div className="tab-container">
      	<div className="commentBox">
	        <CommentList data={this.state.data} />
	        <CommentForm onCommentSubmit={this.handleCommentSubmit.bind(this)} />
	      </div>
      </div>
    );
  }
}