import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import swal from "sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../ImpactManagement/cookie').readCookie;
const eraseCookie = require('../ImpactManagement/cookie').eraseCookie;

export default class ClassificationModule extends Component {
	constructor(props){
		super(props);
		this.state = {
			authorizedFor: JSON.parse(readCookie('userData')).authorizedFor
		}
	}

	gotoLanding(type) {
		if(this.state.authorizedFor && this.state.authorizedFor[type] !== undefined) window.location.pathname = '/' + type;
	}

	logout() {
		swal({
		  title: 'Are you sure you wish to Logout?',
		  type: 'warning',
		  showCancelButton: true,
		  confirmButtonText: 'Yes, Logout!',
		  cancelButtonText: 'No, Cancel!',
		  confirmButtonClass: 'btn',
		  cancelButtonClass: 'btn',
		}).then(function (result) {
		  if(result) {
				fetch(process.env.REACT_APP_API_URL + '/logout', {
					method: "POST",
					headers: {
						'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
					}
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					eraseCookie('userData');
					eraseCookie('ngo');
					eraseCookie('access_token');
					eraseCookie('mis_data');
					window.location.pathname = '/';
				}).catch(function (error) {
					console.log("Couldn't logout because: " + error.message);
				});
			}
		}.bind(this));
  }

	render() {
		return (
			<DocumentTitle title="SAHAJ">
				<div className="classification-modules">
					<div className="logout-btn logout-btn-mobile" title="Logout" onClick={this.logout.bind(this)}><i className="fa fa-power-off fa-3x"></i></div>
					<Col md={12} className="classification-modules-container classification-modules-container-mobile">
						<div className="classification-modules-cards classification-modules-cards-mobile">
							<div className="classification-module classification-module-mobile sahaj" onClick={this.gotoLanding.bind(this, 'dm')}>
								<div className="cards-icon-container"></div>
								<h4 style={{fontWeight: 600}}>Sahaj Connect</h4>
								<p style={{fontSize: '14px'}}>For all your supporters, contacts, donations and campaign management.</p>
								{this.state.authorizedFor !== undefined	? (
									this.state.authorizedFor.dm !== undefined ? (null) : (
										<div>
											<i className="fa fa-lock fa-2x"></i>
										</div>
									)
								) : (
									<div>
										<i className="fa fa-lock fa-2x"></i>
									</div>
								)}
							</div>
						</div>
						<div className="classification-modules-cards classification-modules-cards-mobile">
							<div className="classification-module classification-module-mobile mis-csr" onClick={this.gotoLanding.bind(this, 'im')}>
								<div className="cards-icon-container"></div>
								<h4 style={{fontWeight: 600}}>Sahaj Impact</h4>
								<p style={{fontSize: '14px'}}>For project and related communication, expense and impact management.</p>
								{this.state.authorizedFor !== undefined	? (
									this.state.authorizedFor.im !== undefined ? (null) : (
										<div>
											<i className="fa fa-lock fa-2x"></i>
										</div>
									)
								) : (
									<div>
										<i className="fa fa-lock fa-2x"></i>
									</div>
								)}
							</div>
						</div>
						<div className="classification-modules-cards classification-modules-cards-mobile">
							<div className="classification-module classification-module-mobile expense" onClick={this.gotoLanding.bind(this, 'em')}>
								<div className="cards-icon-container"></div>
								<h4 style={{fontWeight: 600}}>Sahaj Expense</h4>
								<p style={{fontSize: '14px'}}>For claims and reimbursements approval management and settlement.</p>
								{this.state.authorizedFor !== undefined ? (
									this.state.authorizedFor.em !== undefined ? (null) : (
										<div><i className="fa fa-lock fa-2x"></i></div>
									)) : (
									<div><i className="fa fa-lock fa-2x"></i></div>
								)}
								</div>
							</div>
							<div className="classification-modules-cards classification-modules-cards-mobile">
								<div className="classification-module classification-module-mobile form" onClick={this.gotoLanding.bind(this, 'fb')}>
									<div className="cards-icon-container"></div>
									<h4 style={{fontWeight: 600}}>Sahaj Converge</h4>
									<p style={{fontSize: '14px'}}>For launching personalized advanced forms, collating data and tracking your metrics.</p>
									{/*<div className="outer-info"><div className="info-txt">Coming soon</div></div>*/}
									{this.state.authorizedFor !== undefined	? (
										this.state.authorizedFor.fb !== undefined ? (null) : (
											<div>
												<i className="fa fa-lock fa-2x"></i>
											</div>
										)
									) : (
										<div>
											<i className="fa fa-lock fa-2x"></i>
									</div>
									)}
								</div>
							</div>
							<div className="classification-modules-cards classification-modules-cards-mobile">
								<div className="classification-module classification-module-mobile volunteer">
									<div className="cards-icon-container"></div>
									<h4 style={{fontWeight: 600}}>Sahaj Volunteer</h4>
									<p style={{fontSize: '14px'}}>For managing volunteering events calendars, applications and process.</p>
									<div className="outer-info"><div className="info-txt">Coming soon</div></div>
								</div>
							</div>
							<div className="classification-modules-cards classification-modules-cards-mobile">
								<div className="classification-module classification-module-mobile beneficiary">
									<div className="cards-icon-container"></div>
									<h4 style={{fontWeight: 600}}>Sahaj People</h4>
									<p style={{fontSize: '14px'}}>For beneficiary data management and record-keeping.</p>
									<div className="outer-info"><div className="info-txt">Coming soon</div></div>
								</div>
							</div>
					</Col>
				</div>
			</DocumentTitle>
		);
	}
}