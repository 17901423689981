import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import moment from 'moment';
import swal from 'sweetalert2';
import Alert from 'react-s-alert';
import Icon from 'react-icons-kit';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import enUS from 'antd/lib/locale-provider/en_US';
import { ToastContainer, toast } from 'react-toastify';
import { LocaleProvider, DatePicker, Menu } from 'antd';
import { Card, CardHeader, CardBody, CardFooter } from "react-simple-card";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { ic_add_box } from 'react-icons-kit/md/ic_add_box';
import { ic_edit } from 'react-icons-kit/md/ic_edit';
import { ic_delete } from 'react-icons-kit/md/ic_delete';

const readCookie = require('../cookie.js').readCookie;

let cssClasses = {
  input: 'location form-control ',
  autocompleteContainer: 'my-autocomplete-container',
  formControl: 'form-control'
};

export default class AddAccessmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sample_size: this.props.getDataTypes,
      teamMembers: '',
      kpi_list: null,
      kpi_id: null,
      kpi_unit: null,
      kpi_name: null,
      assessment_date: null,
      location: null,
      address: '',
      unitValue: "",
      warning: false
    }
    this.onChange = (address) => this.setState({ address })
  }

  componentDidMount() {
    this.setState({
      teamMembers: this.props.teamMembers,
      address: this.props.location,
      leadAccessor: this.props.leadAccessor
    })
    // if(this.props.addAccessment !== null || this.props.addAccessment !== undefined){
    //     let teamMembers = this.props.addAccessment.teamMembers.toString();
    //     this.setState({teamMembers: teamMembers})

    // }
    const uri = process.env.REACT_APP_MIS_API_URL + "/project/" + this.props.project_id + "/kpi";

    fetch(uri, {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('mis_data')).access_token
      }
    })
      .then(res => (res.json()))
      .then((res) => {
        console.log("kpi_list", res);
        this.setState({ kpi_list: res.data });
      })
      .catch((err) => {
        console.log("err", err);
      })

  }

  goBack(event) {
    window.location.pathname = '/im/projects/' + this.props.project_id + '/impact';
  }

  geoLocationSelect(event, data) {

    geocodeByAddress(event.target.value)
      //.then(res => (res.json())
      .then(res => {
        console.log("geoLocationSelect", res);
        this.setState({ location: res })
      })
      .catch(err => {
        console.log("err", err);
      })
  }

  // sampleData(event){
  //     //console.log("event---", event.target.value);
  //     this.setState({kpi_id: event.target.value});
  //     let datatype_index = _.findIndex(this.state.kpi_list, {"_id" : event.target.value});
  //    // console.log("ywevfuwegvf", this.state.kpi_list[datatype_index].dataType);
  //     this.setState(
  //         {
  //             kpi_unit: this.state.kpi_list[datatype_index].unit,
  //             kpi_name: this.state.kpi_list[datatype_index].name
  //         }
  //     );

  // }

  cancelForm() {
    // this.props.toggleAccessmentForm();
    window.location.pathname = '/im/projects/' + this.props.project_id + '/impact'
    // this.setState({})
  }

  submitForm() {
    let teamMembers = this.state.teamMembers.split(/[ ,]+/);
    let addForm_data = {}
    if (this.props.tableName === "kpi") {
      addForm_data = {
        "date": this.state.assessment_date,
        "value": this.state.unitValue,
        "leadAccessor": this.state.leadAccessor,
        "teamMembers": teamMembers,
        "location": this.state.address,
        "kpiId": this.props.kpiData.id,
      }
    } else {
      addForm_data = {
        "date": this.state.assessment_date,
        "value": this.state.unitValue,
        "leadAccessor": this.state.leadAccessor,
        "teamMembers": teamMembers,
        "location": this.state.address,
        "beneficiaryId": this.props.kpiData.id,
      }
    }
    let uri = "";
    { this.props.tableName !== "kpi" ? (uri = process.env.REACT_APP_MIS_API_URL + "/project/" + this.props.project_id + "/report-beneficiary-assesment") : (uri = process.env.REACT_APP_MIS_API_URL + "/project/addAssessment/" + this.props.project_id) }

    fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Auth': JSON.parse(readCookie('mis_data')).access_token
      },
      body: JSON.stringify(addForm_data),
    })
      .then(res => res.json())
      .then(res => {
        console.log("data---------", res);
        if (res.status === "ok") {
          // Alert.success('Form added',{
          //     position: 'bottom-right',
          //     timeout: 500
          // });
          this.notifySuccess('This Project has been submitted successfully.');
          window.location.pathname = '/im/projects/' + this.props.project_id + '/impact';
          //this.props.toggleAccessmentForm();
        }
        else {
          Alert.error('Failed to add Form', {
            position: 'bottom-right',
            timeout: 500
          });
        }
        //this.refs.KPI_name = null;
        // this.setState({
        //     unit: null,
        //     KPI_dataType: null
        // })
        //this.setState({result: res.result});
        // console.log("state", this.state.result);
      })
      .catch((err) => {
        console.log("err", err);
        Alert.error('Failed to add Form', {
          position: 'bottom-right',
          timeout: 500
        });
      })

  }

  changeNewMilestone(event, date, data) {
    this.setState({ assessment_date: date })
  }

  handleFormSubmit = (event) => {
    event.preventDefault()

    geocodeByAddress(this.state.address)
      .then(results => getLatLng(results[0]))
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error))
  }

  notifySuccess = (text) => toast.success(text);
  notifyError = (text) => toast.error(text);

  modifyLeadAccessor(e) {
    this.setState({ leadAccessor: e.target.value });
  }
  modifyTeamMembers(e) {
    this.setState({ teamMembers: e.target.value });
  }

  unitValue() {
    this.setState({ unitValue: this.unit_value.value });
    if (this.unit_value.value > this.props.kpiData.potential) {
      this.setState({ warning: true });
    } else {
      this.setState({ warning: false });
    }
  }

  render() {
    const inputProps = {
      value: this.state.address,
      onChange: this.onChange,
    }
    return (
      <div>
        <Alert stack={{ limit: 3 }} />
        <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />

        {
          (this.props.visibility)
            ?

            <div>
              <Col md={12} id="milestones" className="section pt30">
                {
                  (this.state.kpi_list !== null)
                    ?
                    <div className="milestones-container">

                      <Col md={12} className="add-milestone-form">
                        <Col md={6} className="mt10 mb10">
                          <label className="col-md-12 is-imp">Enter Location Name</label>
                          <Col md={12}>
                            <PlacesAutocomplete inputProps={inputProps} classNames={cssClasses}

                              onChange={this.geoLocationSelect.bind(this)}
                            />
                          </Col>
                        </Col>

                        <Col md={6} className="mt10 mb10">
                          <label className="col-md-12">Enter Name of Lead Accessor</label>
                          <Col md={12}>
                            <input type="text" id="lead-assessor" placeholder="Enter Lead Assessor Name"
                              id="input-box-form" className="form-control"
                              value={this.state.leadAccessor}
                              onChange={this.modifyLeadAccessor.bind(this)}
                              ref={(event) => this.assessment_leadAssessor = event}
                            />
                          </Col>
                        </Col>

                        <Col md={6} className="mt10 mb10">
                          <label className="col-md-12">Enter the Name of team Members (comma separated)</label>
                          <Col md={12}>
                            <input type="text" id="team-member" placeholder="Enter Team members or volunteers Name (comma separated)"
                              id="input-box-form" className="form-control"
                              value={this.state.teamMembers}
                              onChange={this.modifyTeamMembers.bind(this)}
                              ref={(event) => this.assessment_team = event}
                            />
                          </Col>
                        </Col>


                        {this.props.tableName === "kpi" ?
                          (<Col md={6} className="mt10 mb10">
                            <label className="col-md-12 is-imp">Unit</label>
                            <Col md={12}>
                              <div className="input-group" style={{ width: "100%" }}>
                                <input id="x_card_code" name="x_card_code" type="number" value={this.state.unitValue} className="form-control" data-val="true"
                                  data-val-required="Please enter the security code" placeholder='Enter value' onChange={this.unitValue.bind(this)}
                                  ref={(event) => this.unit_value = event}
                                />
                              </div>
                            </Col>
                          </Col>) :
                          (<Col md={6} className="mt10 mb10">
                            <label className="col-md-12 is-imp">Please input the no. of people impacted so far</label>
                            <Col md={12}>
                              <div className="input-group" style={{ width: "100%" }}>
                                <input id="x_card_code" name="x_card_code" type="number" value={this.state.unitValue} className="form-control" data-val="true"
                                  data-val-required="Please enter the security code" placeholder='Enter value' onChange={this.unitValue.bind(this)}
                                  ref={(event) => this.unit_value = event}
                                />
                                {this.state.warning ? <p style={{ color: "ef5a20", fontWeight: "bold", marginTop: "3px", fontSize: "12px" }}>WARNING: <span style={{ color: "#a9a9a9", fontSize: "12px" }}>Your Value is greater than Potential value!</span></p> : null}
                              </div>
                            </Col>
                          </Col>)
                        }

                        {this.props.tableName === "kpi" ?
                          (<Col md={6} className="mt10 mb10">
                            <label className="col-md-12 is-imp">Selected KPI</label>
                            <Col md={12}>
                              <input type="text" value={this.props.kpiData.name} className="form-control" readOnly></input>
                            </Col>
                          </Col>) :
                          (<Col md={6} className="mt10 mb10">
                            <label className="col-md-12 is-imp">Selected Beneficiary</label>
                            <Col md={12}>
                              <input type="text" value={this.props.kpiData.name} className="form-control" readOnly></input>
                            </Col>
                          </Col>)
                        }

                        <Col md={6} className="mt10 mb10">
                          <label className="col-md-12 is-imp">Enter the Date Of Assessment:</label>
                          <Col md={12}>
                            <LocaleProvider locale={enUS}>
                              <DatePicker size="large" locale={enUS} format={'DD/MM/YYYY'} style={{ width: "100%" }}
                                id="assessment-date" placeholder="Enter Date of Assessment"
                                onChange={this.changeNewMilestone.bind(this)}
                              // onChange={(event) => {this.assessment_date =  event.target;console.log("7", event.target)}}
                              //ref={(event) => this.assessment_date = event}
                              />
                            </LocaleProvider>
                          </Col>
                        </Col>

                        <Col md={12} className="mt10 mb10">
                          <Col md={8} style={{ display: "flex" }}>
                            <button type="button" className="btn btn-danger" onClick={this.cancelForm.bind(this)}>
                              Cancel
                            </button>
                            <button type="button" className="btn btn-info ml10" onClick={this.submitForm.bind(this)}>
                              Submit Assessment Data
                            </button>
                          </Col>

                        </Col>

                      </Col>
                    </div>
                    :
                    <div className="milestones-container">

                      <Col md={12} id="milestones" className="section pt30">
                        Please Add KPIs then create Assessment
                                                <button type="button" className="btn btn-info" onClick={this.goBack.bind(this)}>Go Back</button>
                      </Col>
                    </div>
                }

              </Col>
            </div>
            :
            null
        }
      </div>
    )

  }
}