import {BootstrapTable, TableHeaderColumn, ButtonGroup} from 'react-bootstrap-table';
import { ToastContainer, toast } from 'react-toastify';
import React,{Component} from 'react';
import {Col} from 'react-bootstrap';
import swal from 'sweetalert2';
import $ from 'jquery';

import ShareFormModal from './ShareFormModal';

const readCookie = require('../../partials/cookie.js').readCookie;

export default class FBDashboard extends Component {
	constructor(props){
		super(props);
		this.state = {
			formTableData:[],
			published_forms: 0,
			status: '',
			searchValue: '',
			total_forms: 0,
			selectRowProp: {
				mode: 'checkbox',
				onSelect: this.onRowSelect.bind(this),
				onSelectAll: this.onAllRowSelect.bind(this)
			},
			selectedRowCount: 0,
			deleteButtonDisabled: true,
			deletableForm: [],
			showShareModal: false,
			selectedForm: '',
			sizePerPage: 20,
			currentPage: 1,
			totalDataSize: 0,
			nonDeletable: []
		};
	}

	onRowSelect(row, isSelected) {
		let deletableForm = this.state.deletableForm;
		let id = row['_id'];
		let name = row['name'];
		if(isSelected) {
			deletableForm.push(id);
			if(row['deletable'] === false) toast.error(name + ' is non-deletable.');
			this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length+1, deletableForm }, function() {
				if(this.state.selectedRowCount) {
					this.setState({ deleteButtonDisabled: false });
				}
			});
		} else {
			deletableForm.splice(deletableForm.indexOf(id), 1);
			this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length-1, deletableForm }, function() {
				if(this.state.selectedRowCount) {
					this.setState({ deleteButtonDisabled: false });
				} else {
				this.setState({ deleteButtonDisabled: true });
				}
			});
		}
	}

	onAllRowSelect(row, isSelected) {
		if(row) {
			let count = 0;
			let formData = this.state.formTableData;
			let deletableForm = [];
			for(var i = 0; i < formData.length; i++){
				if(formData[i]['deletable'] === false) count++;
				deletableForm.push(formData[i]['_id']);
			}
			let deleteButtonDisabled = true;
			if(deletableForm.length) deleteButtonDisabled = false
			this.setState({
				selectedRowCount: this.refs.table.state.data.length,
				deleteButtonDisabled,
				deletableForm
			}, function(){
				if(count === 1) toast.error(count + ' form can not be deleted');
				if(count > 1) toast.error(count + ' forms can not be deleted');
			});
		} else {
			this.setState({
			selectedRowCount: 0,
			deleteButtonDisabled: true,
			deletableForm: []
			});
		}
	}

	componentDidMount(){
		let query = '?limit=20&skip=0';
		this.getAllforms(query);
	}

	getAllforms(query){
		fetch(process.env.REACT_APP_API_URL + '/fb/get/all_form_dashboard/' + this.props.ngo.ngo_id + query, {
			method: 'GET',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			if(data.status === 'ok'){
				this.setState({formTableData: data.data, published_forms: data.published_forms, total_forms: data.total_forms});
			}
		});
	}

	nameFormatter(cell, row) {
		if(row.status === 'Published'){
			return(
				<div onClick={() => {window.open('/fb/form/publish/' + row['_id'])}}>
					<span style={{color: '#108ee9', cursor: 'pointer', textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', overflow: 'hidden'}} title={cell.charAt(0).toUpperCase() + cell.slice(1)}>{cell.charAt(0).toUpperCase() + cell.slice(1)}</span>
				</div>
			)
		} else {
			return(
				<div onClick={() => {window.open('/fb/form/' + this.props.ngo.ngo_id + '/' + row['_id'] + '/preview')}}>
					<span style={{color: '#108ee9', cursor: 'pointer', textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', overflow: 'hidden'}} title={cell.charAt(0).toUpperCase() + cell.slice(1)}>{cell.charAt(0).toUpperCase() + cell.slice(1)}</span>
				</div>
			)
		}
	}

	openShareModal(id){
		this.setState({selectedForm: null}, ()=>{
			this.setState({selectedForm: id, showShareModal: true})
		});
	}

	closeShareModal(){
		this.setState({showShareModal: false, selectedForm: ''});
	}

	shareEditFormatter(cell, row) {
		if(row.status === 'Expired'){
			return null;
		} else {
			if(row.status === 'Published' && row.response_count > 0){
				return(
					<div style={{color: '#fff', width: '80px', height: '25px', padding: '3px', margin: 'auto', fontSize: '12px', cursor: 'pointer', textAlign: 'center', borderRadius: '2px', background: '#ef5a20', boxShadow: '1px 1px 1px #555'}}
						onClick={()=>{window.location.pathname='/fb/form/' + this.props.ngo.ngo_id + '/' + cell + '/preview'}}>
						Share form
					</div>
				)
			} else if(row.status === 'Published' && row.response_count === 0) {
				return(
					<div>
						<div style={{color: '#fff', width: '80px', height: '25px', padding: '3px', margin: 'auto', fontSize: '12px', cursor: 'pointer', textAlign: 'center', borderRadius: '2px', marginBottom: '10px', background: '#ef5a20', boxShadow: '1px 1px 1px #555'}}
						onClick={()=>{window.location.pathname='/fb/form/'+ cell +'/edit'}}>
							Edit
						</div>
						<div style={{color: '#fff', width: '80px', height: '25px', padding: '3px', margin: 'auto', fontSize: '12px', cursor: 'pointer', textAlign: 'center', borderRadius: '2px', background: '#ef5a20', boxShadow: '1px 1px 1px #555'}}
						 onClick={()=>{window.location.pathname='/fb/form/' + this.props.ngo.ngo_id + '/' + cell + '/preview'}}>
							Share form
						</div>
					</div>
				)
			} else {
				return(
					<div style={{color: '#fff', width: '80px', height: '25px', padding: '3px', margin: 'auto', fontSize: '12px', cursor: 'pointer', textAlign: 'center', borderRadius: '2px', background: '#ef5a20', boxShadow: '1px 1px 1px #555'}}
						onClick={()=>{window.location.pathname='/fb/form/'+ cell +'/edit'}}>
						Edit
					</div>
				)
			}
		}
	}

	cloneForm(id){
		swal({
			title: 'Are you sure?',
			text: `Are you sure you want to clone this form?.`,
			type: 'warning',
			showCancelButton: true,
			cancelButtonColor: '#EF1313',
			confirmButtonText: 'Yes, I am sure!',
			cancelButtonText: "No, cancel it!",
			closeOnConfirm: true,
			closeOnCancel: true
		}).then((isConfirm) => {
			fetch(process.env.REACT_APP_API_URL + '/fb/clone/form/' + this.props.ngo.ngo_id + '/' + id, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify({isClone: true})
			}).then(data => data.json())
			.then(data => {
				if(data.status === 'ok'){
					window.location.pathname= '/fb/form/'+ data['_id'] +'/edit'
				} else {
					swal({
						title: 'Request Failed.',
						text: 'Failed to create clone. Please try again.',
						type: 'error'
					});
				}
			});
		});
	}

	downloadResponses(id){
		fetch(process.env.REACT_APP_API_URL + '/fb/download/responses/' + this.props.ngo.ngo_id + '/' + id,{
			method: 'GET',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			if(data.status === 'ok'){
				window.open(data.fileUrl);
			}
		})
	}

	cloneDownloadFormatter(cell, row) {
		if(row.response_count > 0){
			return(
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
					<div style={{color: '#fff', height: '25px', width: '120px', margin: '0 5px', fontSize: '12px', cursor: 'pointer', padding: '3px 6px', textAlign: 'center', borderRadius: '2px', background: '#ef5a20', boxShadow: '1px 1px 1px #555'}}
						onClick={this.cloneForm.bind(this, cell)}>
						Clone as Draft
					</div>
					<div style={{color: '#fff', width: '120px', height: '25px', margin: '0 5px', fontSize: '12px', marginTop: '10px', cursor: 'pointer', padding: '3px 6px', textAlign: 'center', borderRadius: '2px', background: '#ef5a20', boxShadow: '1px 1px 1px #555'}}
						onClick={this.downloadResponses.bind(this, cell)}>
						Download as xls/csv
					</div>
				</div>
			)
		} else {
			return (
				<div style={{display: 'flex', justifyContent: 'center'}}>
					<div style={{color: '#fff', height: '25px', width: '120px', padding: '3px 6px', fontSize: '12px', cursor: 'pointer', textAlign: 'center', borderRadius: '2px', background: '#ef5a20', boxShadow: '1px 1px 1px #555'}}
						onClick={this.cloneForm.bind(this, cell)}>
						Clone as Draft
					</div>
				</div>
			)
		}
	}

	deleteForm(){
		let query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage;
		if(this.state.deletableForm.length > 0){
			swal({
				title: 'Are you sure?',
				text: `You won't be able to revert this action.`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonColor: '#EF1313',
				confirmButtonText: 'Yes, I am sure!',
				cancelButtonText: "No, cancel it!",
				closeOnConfirm: true,
				closeOnCancel: true
			}).then((isConfirm) => {
				let delRes = false;
				let data = {};
				data['ids'] = this.state.deletableForm;
				for(var i=0; i < this.state.formTableData.length; i++){
					for(var j=0; j < data.ids.length; j++){
						if(data.ids[j] === this.state.formTableData[i]['_id']){
							if(this.state.formTableData[i]['response_count'] > 0){
								delRes = true;
								break;
							}
						}
					}
				}
				if(delRes){
					swal({
						title: 'Do you want to delete responses too?',
						text: `You won't be able to revert this action.`,
						type: 'warning',
						showCancelButton: true,
						cancelButtonColor: '#EF1313',
						confirmButtonText: 'Yes, delete responses!',
						cancelButtonText: "No, keep responses!",
						closeOnConfirm: true,
						closeOnCancel: true
					}).then((isConfirm) => {
						fetch(process.env.REACT_APP_API_URL + '/fb/delete/form/' + this.props.ngo.ngo_id + '?is_response_delete=true', {
							method: 'DELETE',
							headers: {
								'Content-Type': 'application/json',
								'Authorization': JSON.parse(readCookie('access_token')).access_token
							},
							body: JSON.stringify(data)
						}).then(data => data.json())
						.then(data => {
							if(data.status === 'ok'){
								swal({
									title: 'Delete details.',
									text: 'Total deleted forms ' + data['deleted_form'] + ' and total non-deleted forms ' + data['not_deleted_form'],
									type: 'success'
								}).then(function(){
									this.setState({selectedRowCount: 0, deleteButtonDisabled: true, deletableForm: []}, function(){
										this.getAllforms(query);
									}.bind(this));
								}.bind(this))
							} else {
								swal({
									title: 'Failed to delete.',
									text: 'Please try again.',
									type: 'error'
								});
							}
						});
					}).catch((err)=>{
						fetch(process.env.REACT_APP_API_URL + '/fb/delete/form/' + this.props.ngo.ngo_id + '?is_response_delete=false', {
							method: 'DELETE',
							headers: {
								'Content-Type': 'application/json',
								'Authorization': JSON.parse(readCookie('access_token')).access_token
							},
							body: JSON.stringify(data)
						}).then(data => data.json())
						.then(data => {
							if(data.status === 'ok'){
								swal({
									title: 'Delete details.',
									text: 'Total deleted forms ' + data['deleted_form'] + ' and total non-deleted forms ' + data['not_deleted_form'],
									type: 'success'
								}).then(function(){
									window.location.reload();
								});
							} else {
								swal({
									title: 'Failed to delete.',
									text: 'Please try again.',
									type: 'error'
								});
							}
						});
					});
				} else {
					fetch(process.env.REACT_APP_API_URL + '/fb/delete/form/' + this.props.ngo.ngo_id + '?is_response_delete=false', {
						method: 'DELETE',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': JSON.parse(readCookie('access_token')).access_token
						},
						body: JSON.stringify(data)
					}).then(data => data.json())
					.then(data => {
						if(data.status === 'ok'){
							swal({
								title: 'Delete details.',
								text: 'Total deleted forms ' + data['deleted_form'] + ' and total non-deleted forms ' + data['not_deleted_form'],
								type: 'success'
							}).then(() => {
								window.location.reload();
							})
						} else {
							swal({
								title: 'Failed to delete.',
								text: 'Please try again.',
								type: 'error'
							});
						}
					});
				}
			});
		}
	}

	filterBystatus = (e) => {
		let query = '';
		if(e.target.value !== '') {
			if(this.state.searchValue !== '' && this.state.searchValue.length > 3) query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage + '&status=' + e.target.value + '&search=' + this.state.searchValue;
			else query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage + '&status=' + e.target.value;
		} else {
			if(this.state.searchValue !== '' && this.state.searchValue.length > 3) query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage + '&search=' + this.state.searchValue;
			else query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage;
		}
		this.getAllforms(query);
		this.setState({selectedRowCount: 0, deleteButtonDisabled: true, deletableForm: [], status: e.target.value});
	}

	onPageChange(page, sizePerPage) {
		let query = '?limit=' + sizePerPage + '&skip=' + (page - 1) * sizePerPage;
		if(this.state.searchValue !== '' && this.state.searchValue.length > 3) query += '&search=' + this.state.searchValue;
		if(this.state.status !== '') query += '&status=' + this.state.status;
		this.getAllforms(query);
		this.setState({selectedRowCount: 0, deleteButtonDisabled: true, deletableForm: [], currentPage: page, sizePerPage: sizePerPage});
	}

	onSizePerPageList(sizePerPage) {
		this.setState({currentPage: 1});
		let query = '?limit=' + sizePerPage + '&skip=' + (this.state.currentPage - 1) * sizePerPage;
		if(this.state.searchValue !== '' && this.state.searchValue.length > 3) query += '&search=' + this.state.searchValue;
		if(this.state.status !== '') query += '&status=' + this.state.status;
		this.getAllforms(query);
		this.setState({selectedRowCount: 0, deleteButtonDisabled: true, deletableForm: [], sizePerPage: sizePerPage});
	}

	createCustomButtonGroup =  (props, onClick) => {
		return (
			<ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
				<div className="fb-create-btn mr10" onClick={()=> {window.location.pathname = '/fb/form/template'}}><i className="fa fa-plus" style={{marginRight: '5px'}}></i>Create Form</div>
				<button type='button' disabled={this.state.deleteButtonDisabled}
					className={'btn btn-success react-bs-table-add-btn hidden-print'}
					onClick={ this.deleteForm.bind(this)}>
					<span><i className="glyphicon glyphicon-minus"></i>Delete Forms</span>
				</button>
				<div className="fb-select-status-container" style={{marginLeft: '10px', padding: '5px', background: '#ddd'}}>
					<div className="form-group fb-select-holder" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<label for="status-filter" style={{marginTop: '5px', marginRight: '5px', width: '100%', fontSize: '13px'}}>Filter by status:</label>
						<select style={{border: '1px solid #ccc', fontSize: '14px'}} id="status-filter" onClick={this.filterBystatus.bind(this)}>
							<option value="" disabled selected>Select</option>
							<option value="">All</option>
							<option value="Draft">Draft</option>
							<option value="Published">Published</option>
							<option value="Expired">Expired</option>
						</select>
					</div>
				</div>
				<div className="fb-dashboard-text-container no-flex ml10" style={{padding: '0px'}}>
					<div className="text-container med-mb-10 med-w-50">
						<div className="fb-text-title">Published Forms: </div><div className="fb-text-value">{this.state.published_forms}</div>
					</div>
					<div className="text-container med-w-50">
						<div className="fb-text-title">Total Forms: </div><div className="fb-text-value">{this.state.total_forms}</div>
					</div>
				</div>
			</ButtonGroup>
		)
	}

	onSearchChange = (searchValue) => {
		let query = '';
		if (searchValue.length >= 3) {
			if(this.state.status !== ''){
				 query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage + '&status=' + this.state.status + '&search=' + searchValue;
			} else {
				query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage + '&search=' + searchValue;
			}
			this.getAllforms(query);
			this.setState({selectedRowCount: 0, deleteButtonDisabled: true, deletableForm: []});
		} else {
			if(this.state.status !== ''){
				 query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage + '&status=' + this.state.status;
			} else {
				query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage;
			}
			this.getAllforms(query);
			this.setState({selectedRowCount: 0, deleteButtonDisabled: true, deletableForm: []}, function(){
				toast.error('Minimum three characters required');
			});
		}
		this.setState({searchValue})
	}

	onSearchClear = (e) => {
		$('.react-bs-table-search-form > input').val('');
		let query = '';
		this.setState({searchValue: ''}, function(){
			if(this.state.status !== '') query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage + '&status=' + this.state.status;
			else query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage;
			this.getAllforms(query);
			this.setState({selectedRowCount: 0, deleteButtonDisabled: true, deletableForm: []});
		}.bind(this));
	}

	createCustomClearButton = (onClick) => {
		return (
			<div className="search-bar-container-donor-table">
				<span className="table-search-btn donor-table" onClick={() => this.onSearchChange($('.react-bs-table-search-form > input').val())}><i className="fa fa-search"></i></span>
				<span><i className="clear-table-search glyphicon glyphicon-remove" onClick={() => this.onSearchClear()}></i></span>
			</div>
		);
	}

	render(){
		const options = {
			btnGroup: this.createCustomButtonGroup.bind(this),
			clearSearch: true,
			clearSearchBtn: this.createCustomClearButton,
			onSearchChange: () => { return null },
			sizePerPage: this.state.sizePerPage,
			onPageChange: this.onPageChange.bind(this),
			page: this.state.currentPage,
			sizePerPageList: [
				{ text: '20 Per Page', value: 20 },
				{ text: '50 Per Page', value: 50 },
				{ text: '100 Per Page', value: 100 }
			],
			onSizePerPageList: this.onSizePerPageList.bind(this)
		}
		return(
			<div className="fb-dashboard-container">
			<ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<Col md={12} style={{padding: '0px'}}>
					<div className="fb-dashboard-page-title">My Forms</div>
				</Col>
				<Col md={12} className="fb-dashboard-form-response-table" style={{padding: '0px'}}>
					<BootstrapTable data={this.state.formTableData} striped={false} ref='table' fetchInfo={{dataTotalSize: this.state.total_forms}}
						remote={true} pagination={true} hover={true} options={options}
						selectRow={this.state.selectRowProp} search searchPlaceholder="Search by First Name/Response Status/Last Updated by">
						<TableHeaderColumn dataField="_id" width='0px' isKey={true} hidden={true}></TableHeaderColumn>
						<TableHeaderColumn dataField="name" width='130px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.nameFormatter.bind(this)}>Name</TableHeaderColumn>
						<TableHeaderColumn dataField="description" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Description</TableHeaderColumn>
						<TableHeaderColumn dataField="response_count" width='80px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true}>Responses</TableHeaderColumn>
						<TableHeaderColumn dataField="status" width='80px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true}>Form Status</TableHeaderColumn>
						<TableHeaderColumn dataField="last_updated_on" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} >Last updated on</TableHeaderColumn>
						<TableHeaderColumn dataField="last_updated_by" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} >Last updated by</TableHeaderColumn>
						<TableHeaderColumn dataField="_id" width='70px' headerAlign='center' dataSort={false} hidden={false} export={false} searchable={true} dataFormat={this.shareEditFormatter.bind(this)}>Share/Edit</TableHeaderColumn>
						<TableHeaderColumn dataField="_id" width='130px' headerAlign='center' dataSort={false} hidden={false} export={false} searchable={true} dataFormat={this.cloneDownloadFormatter.bind(this)}>Clone/Download Response</TableHeaderColumn>
					</BootstrapTable>
				</Col>
				{this.state.selectedForm !== null ? (
					<ShareFormModal showShareModal={this.state.showShareModal} closeShareModal={this.closeShareModal.bind(this)} selectedForm={this.state.selectedForm}/>
				) : (null)}
			</div>
		)
	}
}