import React, {Component} from 'react';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import {Link} from 'react-router-dom';
import moment from 'moment';
import swal from "sweetalert2";
import $ from 'jquery';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import "sweetalert2/dist/sweetalert2.min.css";

import AddCampaignModal from './AddCampaignModal';

const readCookie = require('../cookie.js').readCookie;

const styles = {
  clear: {
    clear: 'both',
  },
};

function linkFormatter(cell, row) {
  let id = row.id;
  var campaignProfile;
  if(cell) {
    if (row.status === 'Sent') {
      campaignProfile = "/dm/campaigns/details/" + id;
      return (
        <div>
          <Link to={campaignProfile}>{cell}</Link>
          {row.drip_campaign_id ? (
            <div className="pull-right btn generate-receipt-btn drip-tag">DRIP</div>
          ) : (null)}
        </div>
      );
    } else if (row.status === 'Ongoing') {
      if(row.drip_campaign_id) {
        return (
          <div>
            <span>{cell}</span>
            <div className="pull-right btn generate-receipt-btn drip-tag">DRIP</div>
          </div>
        );
      } else return cell;
    } else {
      if(row.drip_campaign_id) {
        campaignProfile = "/dm/campaigns/" + id + '/drip/' + row.drip_campaign_id;
        return (
          <div>
            <Link to={campaignProfile}>{cell}</Link>
            <div className="pull-right btn generate-receipt-btn drip-tag">DRIP</div>
          </div>
        );
      } else {
        campaignProfile = "/dm/campaigns/" + id;
        return (
          <Link to={campaignProfile}>{cell}</Link>
        );
      }
    }
  }
}

function datetimeFormatter(value, row, index) {
  if(row.status === 'Sent') return moment(value, 'YYYYMMDD').format('DD/MM/YYYY');
  else if(row.status === 'Scheduled') return moment(row.scheduled_date, 'YYYYMMDD').format('DD/MM/YYYY');
  else return 'Not Sent Yet';
}

function creatorFormatter(cell, row) {
  return cell['created_by']['created_by_name'];
}

export default class CampaignsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: this.props.campaigns,
      showAddCampaignModal: this.props.showAddCampaignModal,
      selectRowProp: {
        mode: 'checkbox',
        onSelect: this.onRowSelect.bind(this),
        onSelectAll: this.onAllRowSelect.bind(this)
      },
      selectedRowCount: 0,
      deleteButtonDisabled: true
    };
    this.sentFormatter = this.sentFormatter.bind(this);
    this.deliveredFormatter = this.deliveredFormatter.bind(this);
    this.openedFormatter = this.openedFormatter.bind(this);
  }

  onRowSelect(row, isSelected) {
    if(isSelected) {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length+1 }, function() {
        if(this.state.selectedRowCount) {
          this.setState({ deleteButtonDisabled: false });
        }
      });
    } else {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length-1 }, function() {
        if(this.state.selectedRowCount) {
          this.setState({ deleteButtonDisabled: false });
        } else {
          this.setState({ deleteButtonDisabled: true });
        }
      });
    }
  }

  onAllRowSelect(row, isSelected) {
    if(row) {
      this.setState({
        selectedRowCount: this.refs.table.state.data.length,
        deleteButtonDisabled: false
      });
    } else {
      this.setState({
        selectedRowCount: 0,
        deleteButtonDisabled: true
      });
    }
  }

  getSelectedRowKeys() {
    var campaignIdTemp = [];
    var campaigns = this.state.campaigns;
    var selectedId =  this.refs.table.state.selectedRowKeys;
    var undeletableCampaigns = [];

    for(var i = 0; i < selectedId.length; i++) {
      for(var j = 0; j < campaigns.length; j++) {
        if(campaigns[j]['id'] === selectedId[i] && (campaigns[j]['status'] === "Scheduled" || campaigns[j]['status'] === "Draft")) {
          campaignIdTemp.push(campaigns[j]['id']);
        } else if (campaigns[j]['id'] === selectedId[i] && campaigns[j]['status'] !== "Scheduled") {
          undeletableCampaigns.push(campaigns[j]['id']);
        }
      }
    }

    if(campaignIdTemp.length !== 0) {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Cancel!',
        confirmButtonClass: 'btn',
        cancelButtonClass: 'btn',
      }).then(function (result) {
        if(result) {
          fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo['ngo_id'] + '/delete', {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
            },
            body: JSON.stringify({ campaign_ids_to_delete: campaignIdTemp })
          }).then((response) => response.json())
          .then((responseJSON) => {
            fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo['ngo_id'], {
              method: "GET",
              headers: {
                'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
              }
            }).then(function(response) {
              return response.json();
            }).then(function(data) {
              if(undeletableCampaigns.length !== 0) {
                swal({
                  text: 'Successfully deleted ' + campaignIdTemp.length + ' campaign(s). The other ' + undeletableCampaigns.length + ' campaign(s) could not be deleted beacuse they have already been sent.',
                  type: 'warning',
                  showCancelButton: false,
                })
              }
              this.props.onAddCampaignClose();
              this.setState({
                campaigns: data['campaigns'],
                selectedRowCount: 0,
                deleteButtonDisabled: true
              });
            }.bind(this))
          });
        }
      }.bind(this));
    } else if(undeletableCampaigns.length !== 0) {
      swal({
        text: 'Deletion was unsuccessful beacuse ' + undeletableCampaigns.length + ' campaign(s) could not be deleted as they have already been sent.',
        type: 'warning',
        showCancelButton: false,
      })
      this.setState({
        selectedRowCount: 0,
        deleteButtonDisabled: true
      });
    }
  }

  onAddCampaignOpen() {
    window.location.pathname = '/dm/campaigns/new';
  }

  createCustomButtonGroup (props, onClick) {
    return (
      <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
        <button type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'} onClick={this.onAddCampaignOpen}>
          <span>
            <i className="glyphicon glyphicon-plus"></i>
            New Campaign
          </span>
        </button>
        <button type='button' disabled={this.state.deleteButtonDisabled}
        className={'btn btn-success react-bs-table-add-btn hidden-print'}
        onClick={ this.getSelectedRowKeys.bind(this)}>
          <span>
            <i className="glyphicon glyphicon-minus"></i>
            Delete Campaign
          </span>
        </button>
      </ButtonGroup>
    );
  }

  createCustomClearButton(onClick) {
    return (
      <span onClick={onClick}>
        <i className="clear-table-search glyphicon glyphicon-remove"></i>
      </span>
    );
  }

  sentFormatter(cell, row) {
    return (
      <div className="text-center" id={'campaign-sent-' + row.id}><i className="fa fa-spinner fa-spin"></i></div>
    )
  }

  deliveredFormatter(cell, row) {
    return (
      <div className="text-center" id={'campaign-delivered-' + row.id}><i className="fa fa-spinner fa-spin"></i></div>
    )
  }

  openedFormatter(cell, row) {
    return (
      <div className="text-center" id={'campaign-opened-' + row.id}><i className="fa fa-spinner fa-spin"></i></div>
    )
  }

  createDuplicate(row) {
    window.location.pathname = '/dm/campaigns/duplicate/' + row.id;
  }

  actionFormatter(cell, row) {
    var ngo_id = this.props.ngo.ngo_id;
    setTimeout(function() {
      fetch(process.env.REACT_APP_API_URL + '/campaigns/' + ngo_id + '/overview-stats/' + row.id, {
        method: "GET",
        headers: {
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        }
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        if(!data.stats || !data.stats.total) {
          $('#campaign-sent-' + row.id).html('--');
        } else {
          $('#campaign-sent-' + row.id).html(data.stats.total.sent);
        }
        if(!data.stats || !data.stats.total) {
          $('#campaign-delivered-' + row.id).html('--');
        } else {
          $('#campaign-delivered-' + row.id).html(data.stats.total.delivered);
        }
        if(!data.stats || !data.stats.unique) {
          $('#campaign-opened-' + row.id).html('--');
        } else if(!isNaN(data.stats.unique.opened)) {
          $('#campaign-opened-' + row.id).html(data.stats.unique.opened);
        }
      });
    }, 1000);
    return (
      <div className="btn generate-receipt-btn" onClick={this.createDuplicate.bind(this, row)}>
        Duplicate
        <i className="fa fa-spinner fa-spin hidden"></i>
      </div>
    )
  }

  createDrip(row) {
    window.location.pathname = '/dm/campaigns/' + row.id + '/drip/new';
  }

  dripFormatter(cell, row) {
    if(cell === 'Sent') {
      return (
        <div className="btn generate-receipt-btn" onClick={this.createDrip.bind(this, row)}>
          Create Drip
          <i className="fa fa-spinner fa-spin hidden"></i>
        </div>
      )
    } else {
      return 'N/A';
    }
  }

  render() {
    const options = {
      btnGroup: this.createCustomButtonGroup.bind(this),
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      sizePerPage: 20,
      sizePerPageList: [
        { text: '20 Per Page', value: 20 },
        { text: '50 Per Page', value: 50 },
        { text: '100 Per Page', value: 100 }
      ]
    };

    return (
      <div>
        {(() => {
          if (this.props.showAddCampaignModal) {
            return (
              <AddCampaignModal ngo_id={this.props.ngo.ngo_id} ngo={this.props.ngo}
                showAddCampaignModal={this.props.showAddCampaignModal}
                onAddCampaignClose={this.props.onAddCampaignClose}
                selectedTemplateId={this.props.selectedTemplateId}
                onAddCampaignOpen={this.props.onAddCampaignOpen} />
            );
          } else {
            return (
              <div style={styles.clear}>
                <BootstrapTable data={this.props.campaigns} options={options} striped={true} hover={true} ref='table'
                  selectRow={this.state.selectRowProp} pagination search searchPlaceholder="Search by Name/Subject/Creator/Status">
                  <TableHeaderColumn dataField="id" isKey={true} hidden>Id</TableHeaderColumn>
                  <TableHeaderColumn dataField="name"                             dataSort={true}   hidden={false} export={true}  searchable={true}  dataFormat={linkFormatter}>Campaign Name</TableHeaderColumn>
                  <TableHeaderColumn dataField="subject"                          dataSort={true}   hidden={true}  export={true}  searchable={false}>Subject</TableHeaderColumn>
                  <TableHeaderColumn dataField="overview_stats"     width='80px'  dataSort={true}   hidden={false} export={false} searchable={false} dataFormat={this.sentFormatter}>Sent</TableHeaderColumn>
                  <TableHeaderColumn dataField="overview_stats"     width='100px' dataSort={true}   hidden={false} export={false} searchable={false} dataFormat={this.deliveredFormatter}>Delivered</TableHeaderColumn>
                  <TableHeaderColumn dataField="overview_stats"     width='90px'  dataSort={true}   hidden={false} export={false} searchable={false} dataFormat={this.openedFormatter}>Opened</TableHeaderColumn>
                  <TableHeaderColumn dataField="sent_at"            width='100px' dataSort={true}   hidden={false} export={true}  searchable={true}  dataFormat={datetimeFormatter}>Sent/Scheduled</TableHeaderColumn>
                  <TableHeaderColumn dataField="template_data"      width='120px' dataSort={true}   hidden={false} export={true}  searchable={true}  dataFormat={creatorFormatter}>Creator</TableHeaderColumn>
                  <TableHeaderColumn dataField="status"             width='80px'  dataSort={true}   hidden={false} export={true}  searchable={true}>Status</TableHeaderColumn>
                  <TableHeaderColumn dataField="status"             width='90px'  dataSort={true}   hidden={false} export={true}  searchable={true}  dataFormat={this.actionFormatter.bind(this)}>Action</TableHeaderColumn>
                  <TableHeaderColumn dataField="status"             width='100px' dataSort={true}   hidden={false} export={true}  searchable={true}  dataFormat={this.dripFormatter.bind(this)}>Drip</TableHeaderColumn>
                </BootstrapTable>
              </div>
            )
          }
        })()}
      </div>
    );
  }
}