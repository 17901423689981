import React, { Component } from 'react';

export default class TextBox extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){
        console.log("this.props::textBox", this.props)
        return(
            <div contentEditable={this.props.contenteditable} 
                ref="description"
                style={this.props.editableClass}
            >

            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            
            </div>
        )
    }
    
}