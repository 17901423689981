import React from 'react';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import AddDonation from '../../3-repository/AddDonation';
import EditDonation from '../../3-repository/EditDonation';
import moment from 'moment';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import $ from 'jquery';
import swal from "sweetalert2";
import Multiselect from 'react-bootstrap-multiselect';

import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../../cookie.js').readCookie;

const styles = {
  clearBoth: {
    clear: 'both',
  }
};

function receiptDownload(cell, row) {
  if (row.receipt_url) {
    return (
      <div>
        <a href={row.receipt_url} download>Download / </a>
        <a href={row.receipt_url} target="_blank">View</a>
      </div>
    );
  } else {
    return 'N/A'
  }
}

function dateFormatter(value, row, index) {
  return moment(value).format('DD/MM/YYYY');
}

export default class SingleDonorDonation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showAddDonationModal: false,
      showEditDonationModal: false,
      selectRowProp: {
        mode: 'checkbox',
        onSelect: this.onRowSelect.bind(this),
        onSelectAll: this.onAllRowSelect.bind(this)
      },
      selectedRowCount: 0,
      donationToEdit: null,
      exportButtonDisabled: true,
      advancedCustomizations: [],
      otherColumns: false
    };
    this.handleGenerateClick = this.handleGenerateClick.bind(this);
    this.sendReceipt = this.sendReceipt.bind(this);
  }

  sendReceipt(cell, row, enumObject, index) {
    if (row.receipt_url) {
      return (
        <div className="btn generate-receipt-btn" onClick={(e) => this.handleSendEmailClick(row, e, index, this)}>
          {row.email_sent === false ? "Send Email" : "Resend"}
          <i className="fa fa-spinner fa-spin hidden"></i>
        </div>
      );
    } else {
      return 'N/A'
    }
  }

  onAdvancedCustomizationsChange(value) {
    let adv_mem_change = { advancedOpt: $(value).parents('select').val() };
    localStorage.setItem('adv_mem_change', JSON.stringify(adv_mem_change));
    let advancedCustomizationsLength = this.state.advancedCustomizations.length;
    this.setState({ advancedCustomizations: $(value).parents('select').val() }, function () {
      let scrollLeft = $('.donor-table.react-bs-table-container').find('.react-bs-table .react-bs-container-body .table').width() - $('.donor-table.react-bs-table-container').find('.react-bs-table .react-bs-container-body').width();
      if (scrollLeft > 0 && advancedCustomizationsLength < this.state.advancedCustomizations.length) {
        $('.donor-table.react-bs-table-container').find('.react-bs-table .react-bs-container-body').scrollLeft(scrollLeft);
        $('.donor-table.react-bs-table-container').find('.react-bs-table .react-bs-container-header').scrollLeft(scrollLeft);
      }
    });
  }

  receiptId(cell, row) {
    if (cell === false) {
      return (
        <div className="btn generate-receipt-btn" onClick={(e) => this.handleGenerateClick(row, e)}>
          Generate
          <i className="fa fa-spinner fa-spin hidden"></i>
        </div>
      );
    } else {
      return cell;
    }
  }

  handleSendEmailClick(donation, e, index, that) {
    let data = {};
    data['donor_email'] = this.props.userEmail;
    data['donor_name'] = donation.first_name + ' ' + donation.last_name;
    data['receipt_url'] = donation.receipt_url;

    fetch(process.env.REACT_APP_API_URL + '/donations/' + this.props.ngo.ngo_id + '/send_receipt/' + donation.donation_id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (data.status === 'ok') {
        swal({
          title: "Mail Sent Successfully!",
          html: "<h4>Successfully sent the receipt to your donor.</h4>",
          type: "success"
        });
        let data = that.state.data;
        data[index].email_sent = true;
        that.setState({ data });
      }
    });
  }

  handleGenerateClick(donation, e) {
    var target = $(e.target).find('i');
    $(target).removeClass('hidden');
    if (!donation.tax_exemption && donation.project === 'General') donation.tax_exemption = '0';
    donation.date = moment(donation.date).format('DD/MM/YYYY');
    let donationDataForReceipt = {
      "donor_email": this.props.userEmail,
      "donor_fname": donation.first_name,
      "donor_lname": donation.last_name,
      "donor_pan": this.props.pan_number,
      "donor_ngo_id": this.props.ngo.ngo_id,
      "donor_date": donation.date,
      "donor_amount": donation.amount,
      "donor_project": donation.project,
      "donor_tax": donation.tax_exemption,
      "org_logo": this.props.ngo.ngo_logo
    }

    if (donation.tax_exemption !== '0' && donation.donor_pan_number === '') {
      swal({
        title: 'Please add a PAN number for the donor',
        text: "Please add a PAN number for the donor!",
        type: 'warning',
      }).then(function () {
        $(target).addClass('hidden');
      })
    } else {
      fetch(process.env.REACT_APP_LE_RECEIPT_URL, {
        method: 'POST',
        body: JSON.stringify(donationDataForReceipt),
        dataType: "json",
        contentType: "application/json"
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        fetch(process.env.REACT_APP_API_URL + '/donations/' + this.props.ngo.ngo_id + '/update/' + donation.donation_id, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify({
            receipt_no: data.receiptNo,
            receipt_url: data.taxReceipt
          })
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          this.onGenerateReceipt(this);
          $(target).addClass('hidden');
        }.bind(this));
      }.bind(this));
    }
  }

  onGenerateReceipt() {
    fetch(process.env.REACT_APP_API_URL + '/donor/' + this.props.userId + '/donations', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({ data: data['donations'] });
    }.bind(this));
  }

  onRowSelect(row, isSelected) {
    if (isSelected) {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length + 1 }, function () {
        if (this.state.selectedRowCount) {
          this.setState({ exportButtonDisabled: false });
        }
      });
    } else {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length - 1 }, function () {
        if (this.state.selectedRowCount) {
          this.setState({ exportButtonDisabled: false });
        } else {
          this.setState({ exportButtonDisabled: true });
        }
      });
    }
  }

  onAllRowSelect(row, isSelected) {
    if (row) {
      this.setState({
        selectedRowCount: this.refs.table.state.data.length,
        exportButtonDisabled: false
      });
    } else {
      this.setState({
        selectedRowCount: 0,
        exportButtonDisabled: true
      });
    }
  }

  createCustomButtonGroup(props, onClick) {
    return (
      <div>
        <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
          {this.props.ngo.plan.name === 'premium' || this.props.ngo.plan.name === 'payment_gateway_basic_plus' ? (
            <button type='button'
              onClick={this.onAddDonationOpen}
              className={'btn btn-success react-bs-table-add-btn hidden-print'}>
              <span>
                <i className="glyphicon glyphicon-plus"></i>
                Add Donation
              </span>
            </button>
          ) : ('')}

          {props.exportCSVBtn}
        </ButtonGroup>
        <button className="total-donors">{'Total Donations: ' + this.state.data.length}</button>
      </div>
    );
  }

  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton disabled={this.state.exportButtonDisabled} />
    );
  }

  createCustomClearButton(onClick) {
    return (
      <span onClick={onClick}>
        <i className="clear-table-search glyphicon glyphicon-remove"></i>
      </span>
    );
  }

  onAddDonationClose = () => {
    this.setState({ showAddDonationModal: false });
    fetch(process.env.REACT_APP_API_URL + '/donor/' + this.props.userId + '/donations', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({ data: data['donations'] });
    }.bind(this))
  }

  onEditDonationClose = () => {
    this.setState({ showEditDonationModal: false, donationToEdit: null });
    fetch(process.env.REACT_APP_API_URL + '/donor/' + this.props.userId + '/donations', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({ data: data['donations'] });
    }.bind(this))
  }

  onAddDonationOpen = () => {
    this.setState({ showAddDonationModal: true });
  }

  onEditDonationOpen = () => {
    this.setState({ showEditDonationModal: true });
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/donor/' + this.props.userId + '/donations', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({ data: data['donations'] });

      let otherColumns = [];
      let advancedCustomizations = [];
      if (localStorage.hasOwnProperty('adv_mem_change') && localStorage.getItem('adv_mem_change') !== null) {
        let localData = JSON.parse(localStorage.getItem('adv_mem_change'));
        advancedCustomizations = localData['advancedOpt'];
      }

      let project = {
        label: 'Donated Towards',
        value: 'project'
      };

      let utm_campaign = {
        label: 'Campaign',
        value: 'utm_campaign'
      };

      let utm_medium = {
        label: 'Medium',
        value: 'utm_medium'
      };

      let utm_source = {
        label: 'Source',
        value: 'utm_source'
      };

      otherColumns.push(project);
      otherColumns.push(utm_campaign);
      otherColumns.push(utm_medium);
      otherColumns.push(utm_source);

      if (advancedCustomizations.length > 0) {
        for (var i = 0; i < advancedCustomizations.length; i++) {
          for (var j = 0; j < otherColumns.length; j++) {
            if (advancedCustomizations[i] === otherColumns[j].value) {
              otherColumns[j]['selected'] = true;
            }
          }
        }
      }

      this.setState({ otherColumns, advancedCustomizations });
    }.bind(this))
  }

  contributionModeFormatter(value, row, index) {
    if (value) {
      return <span>{value}</span>
    } else return <span>Others</span>
  }

  editDonation(value, row, index) {
    if (!row.receipt_no) {
      return (
        <div>
          <div className="btn edit-delete-donation-btn mr10" onClick={this.handleEditDonation.bind(this, row)}>
            Edit
          </div>
          <div className="btn edit-delete-donation-btn mr10" onClick={this.handleDeleteDonation.bind(this, row.donation_id)}>
            Delete
          </div>
        </div>
      )
    } else return <span>N/A</span>;
  }

  handleEditDonation(donation) {
    this.setState({ donationToEdit: donation }, function () {
      this.onEditDonationOpen();
    })
  }

  handleDeleteDonation(donation_id) {
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/donation/' + donation_id, {
      method: "DELETE",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (data.status === 'ok') {
        fetch(process.env.REACT_APP_API_URL + '/donor/' + this.props.userId + '/donations', {
          method: "GET",
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
          }
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          this.setState({ data: data['donations'] });
        }.bind(this));
      }
    }.bind(this));
  }

  utm(cell, row) {
    if (cell) return cell;
    else return 'N/A';
  }

  render() {
    const options = {
      btnGroup: this.createCustomButtonGroup.bind(this),
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      exportCSVBtn: this.createCustomExportCSVButton,
      sizePerPage: 20,
      sizePerPageList: [20, 50, 100],
      onExportToCSV: () => {
        const selectedRows = this.refs.table.state.selectedRowKeys;
        return this.state.data.filter(i => {
          if (selectedRows.indexOf(i.donation_id) > -1) {
            return i;
          }
        });
      },
    };

    return (
      <div style={styles.clearBoth}>
        <div id="donors-table-advanced-filters">
          {this.state.otherColumns ? (
            <Multiselect id="donors-table-advanced-filters-select" buttonText={function (options, select) { return 'Advanced Customizations' }}
              buttonWidth={'100%'} data={this.state.otherColumns} multiple onChange={this.onAdvancedCustomizationsChange.bind(this)} />
          ) : (null)}
        </div>
        <BootstrapTable data={this.state.data} options={options}
          striped={false} ref='table' selectRow={this.state.selectRowProp} hover={true}
          pagination search exportCSV searchPlaceholder="Search by Name/Amount/Donated Towards/Receipt Id">
          <TableHeaderColumn dataField="donation_id" isKey={true} hidden={true} export={false} searchable={false}>Donation Id</TableHeaderColumn>
          <TableHeaderColumn dataField="amount" dataSort={true} hidden={false} export={true} searchable={true}>Amount ({this.props.ngo.currency})</TableHeaderColumn>
          <TableHeaderColumn dataField="contribution_mode" dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.contributionModeFormatter}>Mode of Contribution</TableHeaderColumn>
          <TableHeaderColumn dataField="project" width='200px' dataSort={false} hidden={this.state.advancedCustomizations.includes('project') ? false : true} export={true} searchable={true}>Donated Towards</TableHeaderColumn>
          <TableHeaderColumn dataField="date" dataSort={true} hidden={false} export={true} searchable={true} dataFormat={dateFormatter}>Date of Donation</TableHeaderColumn>
          <TableHeaderColumn dataField="receipt_no" dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.receiptId.bind(this)}>Receipt Id</TableHeaderColumn>
          <TableHeaderColumn dataField="receipt_url" dataSort={true} hidden={true} export={true} searchable={false}>Receipt Url</TableHeaderColumn>
          <TableHeaderColumn dataField="receipt_url" dataSort={false} hidden={false} export={false} searchable={false} dataFormat={receiptDownload}>Receipt Url</TableHeaderColumn>
          <TableHeaderColumn dataField="receipt_url" dataSort={false} hidden={false} export={false} searchable={false} dataFormat={this.sendReceipt}>Email Receipt</TableHeaderColumn>
          <TableHeaderColumn dataField="utm_campaign" width='120px' dataSort={false} hidden={this.state.advancedCustomizations.includes('utm_campaign') ? false : true} export={true} searchable={false} dataFormat={this.utm.bind(this)} columnTitle>Campaign</TableHeaderColumn>
          <TableHeaderColumn dataField="utm_medium" width='120px' dataSort={true} hidden={this.state.advancedCustomizations.includes('utm_medium') ? false : true} export={true} searchable={false} dataFormat={this.utm.bind(this)} columnTitle>Medium</TableHeaderColumn>
          <TableHeaderColumn dataField="utm_source" width='120px' dataSort={true} hidden={this.state.advancedCustomizations.includes('utm_source') ? false : true} export={true} searchable={false} dataFormat={this.utm.bind(this)} columnTitle>Source</TableHeaderColumn>
          <TableHeaderColumn dataField="is_manual" dataSort={false} hidden={false} export={false} searchable={false} dataFormat={this.editDonation.bind(this)}>Edit / Delete</TableHeaderColumn>
        </BootstrapTable>

        <AddDonation ngo={this.props.ngo} userData={this.props.userData}
          showAddDonationModal={this.state.showAddDonationModal}
          onHide={this.onAddDonationClose} donorId={this.props.userId}
          onAddDonationClose={this.onAddDonationClose}
          onAddDonationOpen={this.onAddDonationOpen} />
        {this.state.donationToEdit !== null ? (
          <EditDonation ngo={this.props.ngo} userData={this.props.userData}
            showEditDonationModal={this.state.showEditDonationModal}
            onHide={this.onEditDonationClose} donorId={this.props.userId}
            onEditDonationClose={this.onEditDonationClose} donation={this.state.donationToEdit}
            onEditDonationOpen={this.onEditDonationOpen} />
        ) : (null)}
      </div>
    );
  }
}
