import React, { Component } from "react";
import { Modal, Row, Button } from "react-bootstrap";

export default class EmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  updateEmail(e){
    this.setState({email: e.target.value})
  }

  render() {
    return (
      <div>
        <Modal show={this.props.showEmailModal} onHide={this.props.onHide} bsSize="medium" >
          <Modal.Header>
            <Modal.Title className="modal-header-custom">Add NGO Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <label className="control-label">Email</label>
              <input type="email" className="form-control" value={this.state.email} placeholder="Enter NGO email.." 
                onChange={this.updateEmail.bind(this)}/>
              <div className="email-modal-btns mt10" style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button className="btn mr10" onClick={this.props.submitMailDetails.bind(this, this.state.email)}>Submit</Button>
                <Button className="btn" onClick={this.props.onEmailModalClose}>Cancel</Button>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
