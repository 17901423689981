import React, { Component } from 'react';

import TaskList from './TaskList';

export default class Followups extends Component {
  render () {
    return (
      <div className="page-container">
        <TaskList ngo={this.props.ngo} userData={this.props.userData} />
      </div>
    );
  }
}
