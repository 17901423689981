import $ from 'jquery';
import moment from 'moment';
import swal from 'sweetalert2';
import React, {Component} from 'react';

import { compose, withProps } from "recompose"
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const createFormToView = require('../../../scripts/formBuilder/view.js').createFormToView;
const submitForm = require('../../../scripts/formBuilder/view.js').submitForm;

const FBGoogleMap = withGoogleMap(props =>
	<GoogleMap defaultZoom={10} defaultCenter={{ lat: parseFloat(props.defaultLocation.latitude), lng: parseFloat(props.defaultLocation.longitude) }}>
		<Marker position={{ lat: parseFloat(props.defaultLocation.latitude), lng: parseFloat(props.defaultLocation.longitude) }}
			draggable={true} onDragEnd={(evt, map, coord) => props.onDragEnd(evt)} />
	</GoogleMap>
);

export default class FBPublishForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: null,
			message: '',
			showLocationInput: false,
			defaultLocation: {
				latitude: 0,
				longitude: 0
			},
			addressObj: {},
			markerLabel: "",
			mapLoaded: false
		}
	}

	componentDidMount(){
		var x = $.getScript('/js/formBuilder/viewElements.js'), getResponse, answers;
		document.write('<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.99.0/css/materialize.min.css">');
		fetch(process.env.REACT_APP_API_URL + '/fb/get/form/' + this.props.match.params['formId'], {
			method: 'GET',
		}).then(data => data.json())
		.then(data => {
			if(data.status === 'ok') {
				var that = this, showLocationInput = data.data.form_details.geolocation, position = {};
				if(this.props.match.params['responseId']) {
					if(data.data.form_details.resp_editable) {
						getResponse = fetch(process.env.REACT_APP_API_URL + '/fb/get/individual/response/' + this.props.match.params['responseId'], {
							method: 'GET',
						}).then(respData => respData.json())
						.then(respData => {
							if(respData.status === 'ok') {
								answers = respData.answers;
							} else {
								swal({
									title: 'Sorry!',
									text: "You cannot edit the form once submitted",
									type: 'error'
								}).then(function(res){
									window.location.pathname = "/fb/form/publish/" + this.props.match.params['formId'];
								}).catch(function(res){
									window.location.pathname = "/fb/form/publish/" + this.props.match.params['formId'];
								});
							}
						});
					} else {
						swal({
							title: 'Sorry!',
							text: "You cannot edit the form once submitted",
							type: 'error'
						}).then(function(res){
							window.location.pathname = "/fb/form/publish/" + this.props.match.params['formId'];
						}).catch(function(res){
							window.location.pathname = "/fb/form/publish/" + this.props.match.params['formId'];
						});
					}
				}
				if(showLocationInput){
					if(navigator.geolocation){
						navigator.geolocation.getCurrentPosition(function(pos) {
							position['latitude'] = parseFloat(pos.coords.latitude);
							position['longitude'] = parseFloat(pos.coords.longitude);
							fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + pos.coords.latitude + ',' + pos.coords.longitude + '&key=AIzaSyBfvdlr4pZ5UbmIM9KzNSASmDy9pZsLQn0')
							.then((response) => response.json())
							.then((responseJson) => {
								let addressObj = {}
								addressObj['formatted_address'] = responseJson.results[0]["formatted_address"];
								addressObj['placeId'] = responseJson.results[0]["place_id"];
								addressObj['latlng'] = {
									latitude: parseFloat(pos.coords.latitude),
									longitude: parseFloat(pos.coords.longitude)
								}
								window.addressObj = addressObj;
								that.setState({markerLabel: responseJson.results[0]["formatted_address"], addressObj});
							});
						}, function(error) {
							switch(error.code) {
								case error.PERMISSION_DENIED:
									console.log("User denied the request for Geolocation.");
									break;
								case error.POSITION_UNAVAILABLE:
									console.log("Location information is unavailable.");
									break;
								case error.TIMEOUT:
									console.log("The request to get user location timed out.");
									break;
								case error.UNKNOWN_ERROR:
									console.log("An unknown error occurred.");
									break;
							}
							let addressObj = {
								"placeId": "ChIJbbs1rNoWrjsRYs3zdkjvJg4",
								"formatted_address": "Unnamed Road, Bennigana Halli, Bengaluru, Karnataka 560033, India",
								"latlng": { "latitude": 13.003161599999999, "longitude": 77.6421376 }
							}
							position = addressObj["latlng"];
							window.addressObj = addressObj;
							that.setState({markerLabel: addressObj["formatted_address"], addressObj});
						});
					}
				}
				Promise.all([x, getResponse]).then(function() {
					that.setState({form: data.data, showLocationInput, defaultLocation: position, mapLoaded: true}, function(){
						window.formUrl = process.env.REACT_APP_API_URL + '/fb/post/response/' + that.props.match.params['formId'];
						window.fileUrl = process.env.REACT_APP_API_URL + '/fb/uploadfile/' + that.props.match.params['formId'];
						window.formId = that.props.match.params['formId'];
						window.responseId = that.props.match.params['responseId'];
						window.form = data.data;
						window.relationalUrl = process.env.REACT_APP_API_URL + '/fb/relational-data/';
						createFormToView(data.data, 'testing');
						if(answers) {
							let promisearr = [], countryPromise, statePromise, cityPromise;
							Object.keys(answers).map((ques_id, index) => {
								let $elem = $('#elementContainer ._form-page ._form-element[data-questionid="' + ques_id + '"]'),
									dataType = $($elem).attr('data-type');
								if($elem.length) {
									if(answers[ques_id]) {
										if(typeof answers[ques_id] === "object") {
											if(Array.isArray(answers[ques_id])) {
												if(answers[ques_id].length) $($elem).attr('data-valid', true);
											} else {
												if(answers[ques_id].hasOwnProperty('first_name') && answers[ques_id].hasOwnProperty('last_name')) {
													if(answers[ques_id].first_name && answers[ques_id].last_name) $($elem).attr('data-valid', true);
												} else if(answers[ques_id].hasOwnProperty('country') && answers[ques_id].hasOwnProperty('state') && answers[ques_id].hasOwnProperty('city')) {
													if(answers[ques_id].country && answers[ques_id].state && answers[ques_id].city && answers[ques_id].pincode && answers[ques_id].area && answers[ques_id].street) $($elem).attr('data-valid', true);
												}
											}
										} else $($elem).attr('data-valid', true);
									}
									if(dataType === "phone" || dataType === "email" || dataType === "date" || dataType === "textbox" || dataType === "number" || dataType === "website") {
										if(dataType === "date") {
											if(moment(answers[ques_id], 'DD/MM/YYYY').isValid()) $($elem).find('input').val(moment(answers[ques_id], 'DD/MM/YYYY').format('DD/MM/YYYY'));
											else if(moment(answers[ques_id], 'D/M/YYYY').isValid()) $($elem).find('input').val(moment(answers[ques_id], 'D/M/YYYY').format('DD/MM/YYYY'));
											else if(moment(answers[ques_id]).isValid()) $($elem).find('input').val(moment(answers[ques_id]).format('DD/MM/YYYY'));
										} else $($elem).find('input').val(answers[ques_id]);
									} else if(dataType === "dropdown") $($elem).find('select').val(answers[ques_id]);
									else if(dataType === "textarea") $($elem).find('textarea#textarea').val(answers[ques_id]);
									else if(dataType === "radio" || dataType === "yesno") {
										$($elem).find('input').each(function() {
									    if($(this).siblings('label').text() === answers[ques_id]) $(this).prop('checked', true);
										});
									} else if(dataType === "address") {
										let countryId, stateId, cityId;
										countryPromise = $.get('https://www.letsendorse.com/getlocation?type=0').then(function(data) {
											for(let i = 0; i < data.length; i++) {
												$($elem).find('select#country').append('<option value="' + data[i]['name'] + '" countryId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
												if(data[i]['name'] === answers[ques_id]['country']) countryId = data[i]['id'];
											}
											$($elem).find('select#country').val(answers[ques_id]['country']);
											return statePromise = $.get('https://www.letsendorse.com/getlocation?type=1&countryId=' + countryId).then(function(data) {
												for(let j = 0; j < data.length; j++) {
													$($elem).find('select#state').append('<option value="' + data[j]['name'] + '" stateId="' + data[j]['id'] + '">' + data[j]['name'] + '</option>');
													if(data[j]['name'] === answers[ques_id]['state']) stateId = data[j]['id'];
												}
												$($elem).find('select#state').val(answers[ques_id]['state']);
												return cityPromise = $.get('https://www.letsendorse.com/getlocation?type=2&stateId=' + stateId).then(function(data) {
													for(let k = 0; k < data.length; k++) {
														$($elem).find('select#city').append('<option value="' + data[k]['name'] + '" cityId="' + data[k]['id'] + '">' + data[k]['name'] + '</option>');
													}
													$($elem).find('select#city').val(answers[ques_id]['city']);
												});
											});
										});
										promisearr.push(countryPromise);
										$($elem).find('input#zipcode').val(answers[ques_id]['pincode']);
										$($elem).find('input#street').val(answers[ques_id]['street']);
										$($elem).find('input#area').val(answers[ques_id]['area']);
									} else if(dataType === "name") {
										$($elem).find('input#first_name').val(answers[ques_id]['first_name']);
										$($elem).find('input#last_name').val(answers[ques_id]['last_name']);
									} else if(dataType === "checkbox") {
										$($elem).find('input[type="checkbox"]').each(function() {
									    if(answers[ques_id].indexOf($(this).siblings('label').text()) > -1) $(this).prop('checked', true);
									    else $(this).prop('checked', false);
										});
									}
								}
							});
							const checkRequired = require('../../../scripts/formBuilder/view.js').checkRequired;
							checkRequired($('._form-page:visible').attr('id').split('-').pop());
						}
						if(document.getElementById('testing') !== null) {
							document.getElementById('testing').addEventListener("click", function() {
								submitForm(that.props.match.params['formId']);
							});
						}
					});
				});
			} else if(data.status === 'error'){
				this.setState({message: data.message});
			}
		});
	}

	onMarkerDragEnd = (evt) => {
		fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + evt.latLng.lat() + ',' + evt.latLng.lng() + '&key=AIzaSyBfvdlr4pZ5UbmIM9KzNSASmDy9pZsLQn0')
		.then((response) => response.json())
		.then((responseJson) => {
			let addressObj = this.state.addressObj;
			addressObj['marked_formatted_address'] = responseJson.results[0]["formatted_address"];
			addressObj['marked_placeId'] = responseJson.results[0]["place_id"];
			addressObj['marked_latlng'] = {
				latitude: parseFloat(evt.latLng.lat()),
				longitude: parseFloat(evt.latLng.lng())
			}
			window.addressObj = addressObj;
			this.setState({markerLabel: responseJson.results[0]["formatted_address"], addressObj})
		})
	}

	nextStep(){
		if(window.addressObj !== undefined || window.addressObj !== null){
			this.setState({showLocationInput: false})
		} else {
			swal({
				title: 'Please mark your location first',
				message: 'Can not proceed without marking location first',
				type: 'warning'
			})
		}
	}

	render() {
		return(
			<div className="form-builder-container form-publish">
				<div className="publish-form-location-map" style={this.state.showLocationInput ? {padding: '20px', display: 'block'} : {display: 'none'}}>
					<div style={{width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
						<h5 style={{background: '#efefef', color: '#ef5a20', padding: '10px'}}>Please mark your location to proceed</h5>
					</div>
					{this.state.markerLabel !== '' && this.state.mapLoaded ? (
						<div className="publish-form-legend" style={{
							left: '30px',
							width: 'auto',
							color: 'white',
							bottom: '130px',
							zIndex: '999999',
							padding: '6px 12px',
							borderRadius: '2px',
							position: 'absolute',
							background: '#ef5a20',
							boxShadow: '2px 2px 2px #999'
						}}>
							{this.state.markerLabel}
						</div>
					) : (null)}
					{this.state.mapLoaded ? (
						<FBGoogleMap onDragEnd={this.onMarkerDragEnd} defaultLocation={this.state.defaultLocation}
							loadingElement={<div style={{ height: `100%` }} />} containerElement={<div style={{ height: `450px` }} />} mapElement={<div style={{ height: `100%` }} />} />
					) : (
						<div style={{ height: '450px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<i className="fa fa-spin fa-spinner fa-3x" style={{color: '#ef5a20'}}></i>
						</div>
					)}
					{this.state.mapLoaded ? (
						<div style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '30px'}}>
							<div className="publish-form-submit-loc-btn" onClick={this.nextStep.bind(this)} style={{
								width: '100px',
								color: 'white',
								display: 'flex',
								cursor: 'pointer',
								padding: '6px 12px',
								borderRadius: '2px',
								background: '#ef5a20',
								justifyContent: 'center',
								boxShadow: '1px 1px 1px #999'
							}}>Next <i className="fa fa-arrow-right" style={{margin: '3px 5px'}}></i>
							</div>
						</div>
					) : (null)}
				</div>
				{this.state.message !== '' ? (
					<div className="show-form-error">
						<i className="fa fa-exclamation-triangle fb-error-icon"></i>
						<div className="form-error-message">{this.state.message + ' !!'}</div>
					</div>
				) : (
					this.state.form !== null ? (
						<div className="_form-outlet" style={this.state.showLocationInput ? {display: 'none'} : {display: 'inline-flex'}}>
							<div className="_form-holder">
								<div className="_mb10 _form-details">
									<div className="row">
										<div className="col s12 _form-name"><p className="_form-name-element"></p></div>
									</div>
								</div>
								<div className="_mb10 _header-container-view"></div>
								<div className="_mb10 _element-container-view" id="elementContainer"></div>
							</div>
						</div>
					) : (null)
				)}
			</div>
		)
	}
}
