import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import swal from 'sweetalert2';
import { LocaleProvider, DatePicker } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import moment from 'moment';

const readCookie = require('../../ImpactManagement/cookie.js').readCookie;

export default class EMNewExpense extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bill: '',
			categoryList: ["Meal", "Travel", "Entertainment", "Mobile", "Internet", "Sundry", "Others"],
			payments: ["Wallet", "UPI", "Cash", "Debit Card", "Credit Card", "Netbanking"],
			paymentMode: '',
			created_bill_date: "",
			file: [],
		}
	}

	billCategory(e) {
		this.setState({ bill: e.target.value })
	}
	paymentMode(e) {
		this.setState({ paymentMode: e.target.value })
	}

	submitForm(e) {
		let data = new FormData();

		if(Number(this.bill_value.value) < Number(this.reimbursable_value.value)){
			toast.error('Reimbursable value cannot be greater than bill amount');
			return;
		}
		data.append('expense_category', this.state.bill);
		data.append('description', this.description.value);
		data.append('merchant_name', this.merchant_name.value);
		data.append('bill_value_amount', this.bill_value.value);
		data.append('reimbursable_value', this.reimbursable_value.value);
		data.append('payment_mode', this.state.paymentMode);
		data.append('bill_number', this.bill_number.value);
		data.append("created_bill_date", moment(this.state.created_bill_date, 'DD/MM/YYYY').format('YYYY-MM-DD'));
		data.append('bill_file', this.state.file[0]);
		if (this.state.bill !== "" && this.state.created_bill_date !== "" && this.description.value.trim() !== "" && this.merchant_name.value.trim() !== "" && this.bill_value.value !== '' && this.reimbursable_value.value !== '' && this.state.paymentMode !== "" && (this.state.paymentMode === "Cash" || (this.state.paymentMode !== "Cash" && this.bill_number.value.trim() !== "")) && this.state.file.length > 0) {
			swal({
				title: 'Do you want to add this Expense Line?',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, Submit it!'
			}).then((result) => {
				fetch(process.env.REACT_APP_API_URL + '/EM/create_expense_line/' + this.props.match.params.reportId, {
					method: 'POST',
					headers: {
						'Authorization': JSON.parse(readCookie('access_token')).access_token
					},
					body: data
				}).then(res => (res.json()))
				.then(res => {
					toast.success('You have successfully added a new expense row');
					window.location.pathname = "/em/expense/my-reports/" + this.props.match.params.reportId;
				}).catch(err => {
					toast.error('Failed to create new expense row');
				})
			})
		} else {
			toast.error('Please fill up the mandatory fields.');
		}
	}

	cancelForm(e) {
		window.location.pathname = "/em/expense/my-reports/" + this.props.match.params.reportId
	}

	uploadBill(e) {
		this.setState({ file: e.target.files })
	}

	setDate(date, dateString) {
		this.setState({ created_bill_date: dateString })
	}

	render() {
		return (
			<div className="im-container im-project-page im-project-finances-page">
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />

				<div className="im-project-container im-project-finance-container" style={{
					width: 'calc(100% - 20px)',
					left: '0px'
				}}>
					<div className="finance-tabs-content">
						<h3 className="heading-mobile">Create New Expense Line</h3>
						<Row>
							<Col className="create-expense-mobile" md={12}>
								<Col md={6} className="mt10 mb10 input-fields-mobile">
									<label className="col-md-12 is-imp">Select Expense Category</label>
									<Col md={12}>
										<select value={this.state.bill} className="form-control" onChange={this.billCategory.bind(this)}>
											<option value='' disabled> Select category</option>
											{_.map(this.state.categoryList, (cat, index) => (
												<option value={cat} key={cat + index}>{cat}</option>
											))}
										</select>
									</Col>
								</Col>
								<Col md={6} className="mt10 mb10 input-fields-mobile">
									<label className="col-md-12 is-imp">Purpose/Description</label>
									<Col md={12}>
										<input type="text" placeholder="Enter Description" id="input-box-form" className="form-control" ref={(event) => { this.description = event }} />
									</Col>
								</Col>
								<Col md={6} className="mt10 mb10 input-fields-mobile">
									<label className="col-md-12 is-imp">Bill Date</label>
									<Col md={12}>
										<LocaleProvider locale={enUS}>
											<DatePicker locale={enUS} style={{ width: "100%" }} format="DD/MM/YYYY" placeholder="Enter Bill Date"	onChange={this.setDate.bind(this)}/>
										</LocaleProvider>
									</Col>
								</Col>

								<Col md={6} className="mt10 mb10 input-fields-mobile">
									<label className="col-md-12 is-imp">Select Payment Mode</label>
									<Col md={12}>
										<select value={this.state.paymentMode} className="form-control" onChange={this.paymentMode.bind(this)}>
											<option value='' disabled> Select Payment Method</option>
											{_.map(this.state.payments, (payment, index) => (
												<option value={payment} key={index + payment}>{payment}</option>
											))}
										</select>
									</Col>
								</Col>

								<Col md={6} className="mt10 mb10 input-fields-mobile">
									<label className="col-md-12 is-imp">Merchant Name</label>
									<Col md={12}>
										<input type="text" placeholder="Enter Merchant Name" id="input-box-form" className="form-control" ref={(event) => this.merchant_name = event} />
									</Col>
								</Col>
								<Col md={6} className="mt10 mb10 input-fields-mobile">
									<label className="col-md-12 is-imp">Enter Bill Amount (INR)</label>
									<Col md={12}>
										<input type="number" placeholder="Enter Bill Amount" id="input-box-form" className="form-control" min={0} ref={(event) => this.bill_value = event} />
									</Col>
								</Col>
								<Col md={6} className="mt10 mb10 input-fields-mobile">
									<label className={this.state.paymentMode === "Cash" ? "col-md-12" : "col-md-12 is-imp"}>Enter Bill Number</label>
									<Col md={12}>
										<input type="text" placeholder="Enter Bill Number" id="input-box-form" className="form-control" ref={(event) => this.bill_number = event} />
									</Col>
								</Col>
								<Col md={6} className="mt10 mb10 input-fields-mobile">
									<label className="col-md-12 is-imp">Enter Reimbursable Value (INR)</label>
									<Col md={12}>
										<input type="number" placeholder="Enter Reimbursable Value (INR)" id="input-box-form" className="form-control" min={0} ref={(event) => this.reimbursable_value = event} />
									</Col>
								</Col>
								<Col md={6} className="mt10 mb10 input-fields-mobile">
									<label className="col-md-12 is-imp">Upload Bill</label>
									<Col md={12}>
										<input type="file" onChange={this.uploadBill.bind(this)} />
									</Col>
								</Col>
								<Col md={12} className="mt10 mb10 input-fields-mobile">
									<Col md={10}>
										<button type="button" className="btn btn-danger" onClick={this.cancelForm.bind(this)}>
											Cancel
										</button>
									</Col>
									<Col md={2}>
										<button type="button" className="btn btn-info btn-info-mobile" onClick={this.submitForm.bind(this)}>
											Add Expense Line
										</button>
									</Col>
								</Col>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		)
	}
}