import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import ClassificationModule from './components/ClassificationModule/ClassificationModule';

import RequestLogin from './components/RequestLogin';

// FB
import FBTopMenu from './components/FormBuilder/0-menus/FBTopMenu';
import FBDashboard from './components/FormBuilder/1-dashboard/FBDashboard';
import FBCreateForm from './components/FormBuilder/2-form/FBCreateForm';
import FBEditForm from './components/FormBuilder/2-form/FBEditForm';
import FBPreviewForm from './components/FormBuilder/2-form/FBPreviewForm';
import FBPublishForm from './components/FormBuilder/2-form/FBPublishForm';
import FBUpdateFormDetails from './components/FormBuilder/2-form/FBUpdateFormDetails';
import FBCreateFormFromTemplate from './components/FormBuilder/2-form/FBCreateFormFromTemplate';
import FormResponse from './components/FormBuilder/3-responses/FormResponse';
import IndividualResponse from './components/FormBuilder/3-responses/IndividualResponse';
import FBAdminSettings from './components/FormBuilder/4-settings/FBAdminSettings';

// MIS - CSR
import IMTopMenu from './components/ImpactManagement/0-menus/IMTopMenu';
import IMNotifications from './components/ImpactManagement/0-menus/IMNotifications';
import IMProjects from './components/ImpactManagement/1-projects/IMProjects';
import IMSingleProject from './components/ImpactManagement/1-projects/IMSingleProject';
import IMSingleProjectFinances from './components/ImpactManagement/1-projects/IMSingleProjectFinances';
import IMAddProject from './components/ImpactManagement/1-projects/IMAddProject';
import IMEditProject from './components/ImpactManagement/1-projects/IMEditProject';
import ParticularDiscussion from './components/ImpactManagement/1-projects/ParticularDiscussion';
import IMCloneProject from './components/ImpactManagement/1-projects/IMCloneProject';
import Impact from './components/ImpactManagement/1-projects/Impact';
import IMMilestones from './components/ImpactManagement/1-projects/IMMilestones'
import IMAdminSettings from './components/ImpactManagement/3-settings/IMAdminSettings';
import IMSendProjectInvitations from './components/ImpactManagement/2-partnerships/IMSendProjectInvitations';
import IMFundProjectInvitations from './components/ImpactManagement/2-partnerships/IMFundProjectInvitations';
import IMProjectsSent from './components/ImpactManagement/2-partnerships/IMProjectsSent';

//EM
import EMTopMenu from './components/ExpenseManagement/0-menus/EMTopMenu';
import EMExpense from './components/ExpenseManagement/1-reports/EMExpense';
import EMNewExpense from './components/ExpenseManagement/1-reports/EMNewExpense';
import EMExpenseReport from './components/ExpenseManagement/1-reports/EMExpenseReport';
import EMAdminExpense from './components/ExpenseManagement/2-approvals/EMAdminExpense';
import EMAdminParticularExpense from './components/ExpenseManagement/2-approvals/EMAdminParticularExpense';
import EMAdminSettings from './components/ExpenseManagement/3-settings/EMAdminSettings';

// Sahaj
import Sidebar from './components/partials/0-menus/Sidebar';
import TopMenu from './components/partials/0-menus/TopMenu';
import Lists from './components/partials/lists/Lists';
import Layout from './components/partials/Layout';
import ChangePasswordLayout from './components/partials/ChangePasswordLayout';
import Dashboard from './components/partials/1-dashboard/Dashboard';
import Donors from './components/partials/2-donors/Donors';
import SingleDonor from './components/partials/2-donors/SingleDonor';
import Repository from './components/partials/3-repository/Repository';
import Groups from './components/partials/4-groups/Groups';
import SingleGroup from './components/partials/4-groups/SingleGroup';
import Campaigns from './components/partials/5-campaigns/Campaigns';
import TagList from './components/partials/5-campaigns/TagList';
import AddCampaignModal from './components/partials/5-campaigns/AddCampaignModal';
import ABTestingCampaigns from './components/partials/5-campaigns/ABTestingCampaigns';
import AddABTestingCampaign from './components/partials/5-campaigns/AddABTestingCampaign';
import EditABTestingCampaign from './components/partials/5-campaigns/EditABTestingCampaign';
import DuplicateCampaign from './components/partials/5-campaigns/DuplicateCampaign';
import AddDripCampaign from './components/partials/5-campaigns/AddDripCampaign';
import EditDripCampaign from './components/partials/5-campaigns/EditDripCampaign';
import EditDripCampaignPage2 from './components/partials/5-campaigns/EditDripCampaignPage2';
import SingleCampaign from './components/partials/5-campaigns/SingleCampaign';
import SingleCampaignPage2 from './components/partials/5-campaigns/SingleCampaignPage2';
import SingleCampaignPreview from './components/partials/5-campaigns/SingleCampaignPreview';
import SingleCampaignDetails from './components/partials/5-campaigns/SingleCampaignDetails';
import SingleCampaignDetailsStats from './components/partials/5-campaigns/SingleCampaignDetailsStats';
import SingleCampaignDetailsStatsType from './components/partials/5-campaigns/SingleCampaignDetailsStatsType';
import SingleCampaignDetailsDonorStats from './components/partials/5-campaigns/SingleCampaignDetailsDonorStats';
import EditTemplateModal from './components/partials/5-campaigns/EditTemplateModal';
import Followups from './components/partials/6-followups/Followups';
import ViewTask from './components/partials/6-followups/view-task/ViewTask';
import Settings from './components/partials/7-settings/Settings';
import Premium from './components/partials/9-premium/Premium';
import NotFound from './components/NotFound';
import ImResources from './components/ImpactManagement/1-projects/IMResources';
import UserTag from './components/partials/5-campaigns/User/UserTag';

const createCookie = require('./components/partials/cookie.js').createCookie;
const readCookie = require('./components/partials/cookie.js').readCookie;
const eraseCookie = require('./components/partials/cookie.js').eraseCookie;
const createHistory = require('history').createBrowserHistory;
const history = createHistory();

const DefaultIMLayout = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest} render={matchProps => (
			<div className="mis-csr">
				<DocumentTitle title='Sahaj - Impact Management' />
				<IMTopMenu userData={rest.userData} logoutUser={rest.logoutUser} ngo={rest.ngo} misData={rest.misData} />
				<Component {...matchProps} ngo={rest.ngo} misData={rest.misData} userData={rest.userData} updateUser={rest.updateUser} updateNgoData={rest.updateNgoData} />
			</div>
		)} />
	)
}

const DefaultEMLayout = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest} render={matchProps => (
			<div className="mis-csr">
				<DocumentTitle title='Sahaj - Expense Management' />
				<EMTopMenu userData={rest.userData} logoutUser={rest.logoutUser} ngo={rest.ngo} misData={rest.misData} />
				<Component {...matchProps} ngo={rest.ngo} misData={rest.misData} userData={rest.userData} updateUser={rest.updateUser} updateNgoData={rest.updateNgoData} />
			</div>
		)} />
	)
}

const DefaultFBLayout = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest} render={matchProps => (
			<div className="mis-csr">
				<DocumentTitle title='Sahaj - Form Builder' />
				<FBTopMenu userData={rest.userData} logoutUser={rest.logoutUser} ngo={rest.ngo} misData={rest.misData} />
				<Component {...matchProps} ngo={rest.ngo} misData={rest.misData} userData={rest.userData} updateUser={rest.updateUser} updateNgoData={rest.updateNgoData} />
			</div>
		)} />
	)
}

const DefaultDMLayout = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest} render={matchProps => (
			<div className="sahaj">
				<DocumentTitle title='Sahaj' />
				<Sidebar userData={rest.userData} ngo={rest.ngo} misData={rest.misData} />
				<TopMenu userData={rest.userData} logoutUser={rest.logoutUser} ngo={rest.ngo} />
				{rest.hasOwnProperty('selectedTemplateId') && rest.hasOwnProperty('onAddCampaignClose') ? (
					<Component {...matchProps} userData={rest.userData} ngo={rest.ngo} ngo_id={rest.ngo.ngo_id} updateNgoData={rest.updateNgoData}
						selectedTemplateId={rest.selectedTemplateId} onAddCampaignClose={rest.onAddCampaignClose} updateUser={rest.updateUser} />
				) : (
						rest.hasOwnProperty('background') && rest.hasOwnProperty('premiumText') ? (
							<Component {...matchProps} userData={rest.userData} ngo={rest.ngo} ngo_id={rest.ngo.ngo_id} background={rest.background} premiumText={rest.premiumText} />
						) : (
								<Component {...matchProps} userData={rest.userData} ngo={rest.ngo} ngo_id={rest.ngo.ngo_id} updateNgoData={rest.updateNgoData} updateUser={rest.updateUser} />
							)
					)}
			</div>
		)} />
	)
}

const UserTagLayout = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest} render={matchProps => (
			<div className="sahaj">
				<DocumentTitle title='Tags Unsubscribe' />
				<Component {...matchProps} userData={rest.userData} ngo={rest.ngo} ngo_id={rest.ngo.ngo_id} updateNgoData={rest.updateNgoData} />
			</div>
		)} />
	)
}

export default class Routes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authorizedFor: (JSON.parse(readCookie('userData')) !== null ? JSON.parse(readCookie('userData')).authorizedFor : null),
			userData: null,
			ngo: '',
			misData: '',
			plan: null
		};
	}

	componentWillMount() {
		if (readCookie('sahajVersion') !== null && readCookie('sahajVersion') === process.env.REACT_APP_VERSION) {
			if (readCookie('userData') !== null) {
				this.setState({ userData: JSON.parse(readCookie('userData')) });
			}
			if (readCookie('ngo') !== null) {
				this.setState({ plan: JSON.parse(readCookie('ngo')).plan.name });
			}
			this.setState({ ngo: JSON.parse(readCookie('ngo')) });
			this.setState({ misData: JSON.parse(readCookie('mis_data')) });
		} else {
			eraseCookie('userData');
			eraseCookie('ngo');
			eraseCookie('access_token');
			eraseCookie('mis_data');
			eraseCookie('sahajVersion');
			this.setState({ userData: null, ngo: '', misData: '' });
		}
	}

	logoutUser() {
		fetch(process.env.REACT_APP_API_URL + '/logout', {
			method: "POST",
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
			}
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			eraseCookie('userData');
			eraseCookie('ngo');
			eraseCookie('access_token');
			eraseCookie('mis_data');
			this.setState({
				userData: null,
				ngo: null,
				misData: null,
				plan: null
			}, function () {
				window.location.pathname = '/';
			});
		}.bind(this)).catch(function (error) {
			console.log('There has been a problem with your fetch operation: ' + error.message);
		});
	}

	updateNgoData() {
		fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.state.ngo.ngo_id, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
			}
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			var ngo = data['ngo'];
			this.setState({ ngo });
			createCookie('ngo', JSON.stringify(ngo), 7);
		}.bind(this));
	}

	updateUser(first_name, last_name, profile_picture, others) {
		var userData = this.state.userData;
		if (first_name !== '') userData['first_name'] = first_name;
		if (last_name !== '') userData['last_name'] = last_name;
		if (profile_picture !== '') userData['profile_picture'] = profile_picture;
		if (others) {
			if (others.hasOwnProperty('bank_name')) userData['bank_name'] = others['bank_name'];
			if (others.hasOwnProperty('bank_account_number')) userData['bank_account_number'] = others['bank_account_number'];
			if (others.hasOwnProperty('ifsc')) userData['ifsc'] = others['ifsc'];
			if (others.hasOwnProperty('upi')) userData['upi'] = others['upi'];
			if (others.hasOwnProperty('mobile')) userData['mobile'] = others['mobile'];
		}
		this.setState({ userData: userData });
		eraseCookie('userData');
		createCookie('userData', JSON.stringify(userData), 7);
	}

	render() {
		if (this.state.userData !== null && this.state.plan !== null && this.state.misData !== '' && this.state.ngo !== '') {
			if (this.state.plan === 'payment_gateway' || this.state.plan === 'payment_gateway_basic_plus') {
				return (
					<Router history={history}>
						<Switch>
							{this.state.ngo !== null ? (
								<Route exact path="/" component={ClassificationModule} />
							) : (null)}

							{/*IM routes*/}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<Redirect exact from="/im" to="/im/projects" />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects" component={IMProjects} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/new" component={IMAddProject} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/notifications" component={IMNotifications} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/settings" component={IMAdminSettings} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} updateUser={this.updateUser.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id" component={IMSingleProject} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/finances" component={IMSingleProjectFinances} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/resources" component={ImResources} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/resources/:resource_type" component={ImResources} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/resources/:resource_type/:resource_file_type" component={ImResources} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{/* {this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/resources/documents" component={ImResources} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/resources/images" component={ImResources} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/resources/images/albums" component={ImResources} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)} */}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/resources/images/albums/:album_id/videos" component={ImResources} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/edit" component={IMEditProject} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/clone" component={IMCloneProject} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/impact" component={Impact} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/discussion" component={ParticularDiscussion} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/milestones" component={IMMilestones} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{/* IM Partnerships */}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<Redirect exact from="/im/partnerships" to="/im/partnerships/project-invitations" />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/partnerships/project-invitations" component={IMSendProjectInvitations} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/partnerships/funding-invitations" component={IMFundProjectInvitations} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/partnerships/projects-submitted" component={IMProjectsSent} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}

							{/* EM Routes */}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<Redirect exact from="/em" to="/em/expense/my-reports" />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<Redirect exact from="/em/expense" to="/em/expense/my-reports" />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<DefaultEMLayout exact path="/em/expense/my-reports" component={EMExpense} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<DefaultEMLayout exact path="/em/expense/my-reports/:reportId" component={EMExpenseReport} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<DefaultEMLayout exact path="/em/expense/my-reports/:reportId/new-expense" component={EMNewExpense} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<DefaultEMLayout exact path="/em/expense/report-for-approval" component={EMAdminExpense} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<DefaultEMLayout exact path="/em/expense/report-for-approval/:expenseId" component={EMAdminParticularExpense} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.em !== undefined ? (
								<DefaultEMLayout exact path="/em/settings" component={EMAdminSettings} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} updateUser={this.updateUser.bind(this)} />
							) : (null)}

							{this.state.authorizedFor.fb !== undefined ? (
								<Redirect exact from="/fb" to="/fb/dashboard" />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/dashboard" component={FBDashboard} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/responses" component={FormResponse} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/individual/:id/response" component={IndividualResponse} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/form/create" component={FBCreateForm} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<Route exact path="/fb/form/:ngoId/:formId/preview" component={FBPreviewForm} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<Route exact path="/fb/update/form/details/:ngoId/:formId/:responseId" component={FBUpdateFormDetails} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}

							<Route exact path="/fb/form/publish/:formId" component={FBPublishForm} />
							<Route exact path="/fb/form/publish/:formId/edit-response/:responseId" component={FBPublishForm} />

							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/form/:id/edit" component={FBEditForm} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/form/template" component={FBCreateFormFromTemplate} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/form/create/:template_id" component={FBCreateFormFromTemplate} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/settings" component={FBAdminSettings} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} updateUser={this.updateUser.bind(this)} />
							) : (null)}

							{/* DM Routes */}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<Redirect exact from="/dm" to="/dm/dashboard" />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/dashboard" component={Dashboard} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/lists" component={Lists} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/lists/:list_id" component={Donors} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/lists/:list_id/segments" component={Groups} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/lists/:list_id/segments/:name" component={SingleGroup} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}

							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/members/:id" component={SingleDonor} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/donations" component={Repository} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns" component={Premium} background={'campaign-premium.png'}
									userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)}
									premiumText={`
										<h3 style="text-align: center;">
											This feature enables creation and delivery of powerful and engaging emails to your user base, advanced analytics, automated action and much more!
										</h3>
										<h3>You do not have a premium account to utilize this feature.</h3>
										<h3>To know more about this, get in touch with us at
											<a href="mailto:team@letsendorse.com" style="color: inherit; font-size: inherit !important; text-decoration: underline !important;">
												team@letsendorse.com
											</a>
										</h3>`} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/ab-testing" component={Premium} background={'ab-premium.png'}
									userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)}
									premiumText={`
										<h3 style="text-align: center;">
											This feature enables creation and delivery of A/B testing campaigns for subject lines and content variations, show insights on their respective performances and enables you to take subsequent action.
										</h3>
										<h3>You do not have a premium account to utilize this feature.</h3>
										<h3>To know more about this, get in touch with us at
											<a href="mailto:team@letsendorse.com" style="color: inherit; font-size: inherit !important; text-decoration: underline !important;">
												team@letsendorse.com
											</a>
										</h3>`} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/tasks" component={Premium} background={'tasks-premium.png'}
									userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)}
									premiumText={`
										<h3 style="text-align: center;">
											This feature enables creation and assignment of user-level/group-level tasks to your team members, monitor their progress, get alerts and more!
										</h3>
										<h3>You do not have a premium account to utilize this feature.</h3>
										<h3>To know more about this, get in touch with us at
											<a href="mailto:team@letsendorse.com" style="color: inherit; font-size: inherit !important; text-decoration: underline !important;">
												team@letsendorse.com
											</a>
										</h3>`} />
							) : (null)}

							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/settings" component={Settings} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} updateUser={this.updateUser.bind(this)} />
							) : (null)}

							<Route path="*" component={NotFound} />
						</Switch>
					</Router>
				)
			} else if (this.state.plan === 'premium') {
				return (
					<Router history={history}>
						<Switch>
							{this.state.misData !== null && this.state.ngo !== null ? (
								<Route exact path="/" component={ClassificationModule} />
							) : (null)}

							{/* IM Routes */}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<Redirect exact from="/im" to="/im/projects" />
							) : (null)}
							{/* IM Projects */}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects" component={IMProjects} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/new" component={IMAddProject} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/notifications" component={IMNotifications} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/settings" component={IMAdminSettings} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} updateUser={this.updateUser.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id" component={IMSingleProject} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/finances" component={IMSingleProjectFinances} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/resources" component={ImResources} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/resources/:resource_type" component={ImResources} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/resources/:resource_type/:resource_file_type" component={ImResources} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{/* {this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/resources/:resource_type/:resource_file_type/:resource_file" component={ImResources} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)} */}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/edit" component={IMEditProject} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/clone" component={IMCloneProject} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/impact" component={Impact} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/discussion" component={ParticularDiscussion} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/projects/:project_id/milestones" component={IMMilestones} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}

							{/* IM Partnerships */}

							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<Redirect exact from="/im/partnerships" to="/im/partnerships/project-invitations" />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/partnerships/project-invitations" component={IMSendProjectInvitations} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/partnerships/funding-invitations" component={IMFundProjectInvitations} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.im !== undefined ? (
								<DefaultIMLayout exact path="/im/partnerships/projects-submitted" component={IMProjectsSent} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}


							{/* EM Routes */}

							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<Redirect exact from="/em" to="/em/expense/my-reports" />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<Redirect exact from="/em/expense" to="/em/expense/my-reports" />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<DefaultEMLayout exact path="/em/expense/my-reports" component={EMExpense} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<DefaultEMLayout exact path="/em/expense/my-reports/:reportId" component={EMExpenseReport} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<DefaultEMLayout exact path="/em/expense/my-reports/:reportId/new-expense" component={EMNewExpense} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<DefaultEMLayout exact path="/em/expense/report-for-approval" component={EMAdminExpense} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.misData !== null && this.state.authorizedFor.em !== undefined ? (
								<DefaultEMLayout exact path="/em/expense/report-for-approval/:expenseId" component={EMAdminParticularExpense} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.em !== undefined ? (
								<DefaultEMLayout exact path="/em/settings" component={EMAdminSettings} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} updateUser={this.updateUser.bind(this)} />
							) : (null)}

							{/*FB Routes*/}

							{this.state.authorizedFor.fb !== undefined ? (
								<Redirect exact from="/fb" to="/fb/dashboard" />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/dashboard" component={FBDashboard} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/responses" component={FormResponse} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/individual/:id/response" component={IndividualResponse} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/form/create" component={FBCreateForm} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<Route exact path="/fb/form/:ngoId/:formId/preview" component={FBPreviewForm} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<Route exact path="/fb/update/form/details/:ngoId/:formId/:responseId" component={FBUpdateFormDetails} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}

							<Route exact path="/fb/form/publish/:formId" component={FBPublishForm} />
							<Route exact path="/fb/form/publish/:formId/edit-response/:responseId" component={FBPublishForm} />

							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/form/:id/edit" component={FBEditForm} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/form/template" component={FBCreateFormFromTemplate} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/form/create/:template_id" component={FBCreateFormFromTemplate} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.authorizedFor.fb !== undefined ? (
								<DefaultFBLayout exact path="/fb/settings" component={FBAdminSettings} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} updateUser={this.updateUser.bind(this)} />
							) : (null)}

							{/* DM Routes */}

							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								(!this.state.ngo.hasOwnProperty('account_type') || (this.state.ngo.hasOwnProperty('account_type') && this.state.ngo.account_type === 'ngo')) ? (
									<Redirect exact from="/dm" to="/dm/dashboard" />
								) : (
										<Redirect exact from="/dm" to="/dm/lists" />
									)
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								(!this.state.ngo.hasOwnProperty('account_type') || (this.state.ngo.hasOwnProperty('account_type') && this.state.ngo.account_type === 'ngo')) ? (
									<DefaultDMLayout exact path="/dm/dashboard" component={Dashboard} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
								) : (null)
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/lists" component={Lists} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/lists/:list_id" component={Donors} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/lists/:list_id/segments" component={Groups} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/lists/:list_id/segments/:name" component={SingleGroup} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}

							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/members/:id" component={SingleDonor} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}

							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								(!this.state.ngo.hasOwnProperty('account_type') || (this.state.ngo.hasOwnProperty('account_type') && this.state.ngo.account_type === 'ngo')) ? (
									<DefaultDMLayout exact path="/dm/donations" component={Repository} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
								) : (null)
							) : (null)}

							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/ab-testing" component={ABTestingCampaigns} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/ab-testing/new" component={AddABTestingCampaign} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} selectedTemplateId={null} onAddCampaignClose={history.goBack} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/ab-testing/:id" component={EditABTestingCampaign} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} selectedTemplateId={null} onAddCampaignClose={history.goBack} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns" component={Campaigns} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/new" component={AddCampaignModal} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} selectedTemplateId={null} onAddCampaignClose={history.goBack} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}

							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/duplicate/:id" component={DuplicateCampaign} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/details/:name" component={SingleCampaignDetails} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/details/:name/stats" component={SingleCampaignDetailsStats} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/details/:name/stats/:type" component={SingleCampaignDetailsStatsType} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/details/:campaign_id/:donor_id/stats" component={SingleCampaignDetailsDonorStats} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/:name/drip/new" component={AddDripCampaign} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/:name/drip/:drip_campaign_id" component={EditDripCampaign} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/:name/drip/:drip_campaign_id/page-2" component={EditDripCampaignPage2} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/:name" component={SingleCampaign} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/:name/page-2" component={SingleCampaignPage2} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/campaigns/:name/preview" component={SingleCampaignPreview} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}

							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/template/:template_id" component={EditTemplateModal} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}

							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/tasks" component={Followups} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}
							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/tasks/details/:id" component={ViewTask} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}

							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/settings" component={Settings} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} updateUser={this.updateUser.bind(this)} />
							) : (null)}

							{this.state.ngo !== null && this.state.authorizedFor.dm !== undefined ? (
								<DefaultDMLayout exact path="/dm/tag_list/:id" component={TagList} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} logoutUser={this.logoutUser.bind(this)} updateNgoData={this.updateNgoData.bind(this)} />
							) : (null)}

							<UserTagLayout exact path="/dm/unsubscribe/:campaign_id/:user_id" component={UserTag} userData={this.state.userData} ngo={this.state.ngo} misData={this.state.misData} />
							<Route path="*" component={NotFound} />
						</Switch>
					</Router>
				)
			} else return null;
		} else {
			return (
				<Router history={history}>
					<Switch>
						<Route exact path="/reset_password/:token" component={ChangePasswordLayout} />
						<Route exact path="/dm/unsubscribe/:campaign_id/:user_id" component={UserTag} />
						<Route exact path="/fb/form/publish/:formId" component={FBPublishForm} />
						<Route exact path="/fb/form/publish/:formId/edit-response/:responseId" component={FBPublishForm} />
						<Route exact path="/request-login" component={RequestLogin} />
						<Route exact path="/" component={Layout} />
						<Redirect exact from="/*" to="/" />
					</Switch>
				</Router>
			)
		}
	}
}
