import React, { Component } from 'react';
import { Row, Col, Tab, Tabs } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import moment from 'moment';
import swal from "sweetalert2";
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import _ from 'lodash';
import IMSingleProjectSidebar from './IMSingleProjectSidebar';
import AddResourceBox from './components/AddResourceBox';
import Modal from 'react-responsive-modal';
import {bin} from 'react-icons-kit/icomoon/bin'
import {globe} from 'react-icons-kit/ikons/globe'
import {lock} from 'react-icons-kit/ikons/lock'

import "sweetalert2/dist/sweetalert2.min.css";
import Icon from 'react-icons-kit';
import ImageModal from "../1-projects/components/ImageModal";
import AlbumModal from "../1-projects/components/AlbumModal";

const readCookie = require('../cookie.js').readCookie;

export default class IMResources extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fileType: null,
			showAlbum: false,
			openAlbumModal: false,
			openModal: false,
			typeOf: "image",
			image_index: 0,
			docType: 'doc',
			album_id: null,
			addResource: false,
			img_arr: [],
			img_resources: [],
			doc_resources: [],
			album_resources: [],
			album_list: [],
			addAlbum: false,
			perPage: 10,
			offset: 0,
			totalPages: 0,
			expenses: [],
			allHistories: [],
			histories: [],
			activeTabKey: 1,
			reportExpenseModalVisibility: false,
			reportType: '',
			expenseData: null,
			editExpense: false,
			historyExpenses: null,
			activeTab: 1,
		}
	}

	componentDidMount() {
		console.log(222, this.props.match)
		if (this.props.match.params.hasOwnProperty('resource_type') && this.props.match.params.resource_type === 'documents') {
			this.setState({ activeTabKey: 1 });
		} else if (this.props.match.params.hasOwnProperty('resource_type') && this.props.match.params.resource_type === 'images' && !(this.props.match.params.hasOwnProperty('resource_file_type'))) {
			this.setState({ activeTabKey: 2 });
		} else if (this.props.match.params.hasOwnProperty('resource_type') && this.props.match.params.hasOwnProperty('resource_file_type') && this.props.match.params.resource_file_type === 'albums') {
			this.setState({ activeTabKey: 2, activeTab: 2 });
		} else if (this.props.match.params.hasOwnProperty('resource_type')) {
			window.location.pathname = '/404';
		}

		this.getResources();
	}

	getResources() {
		fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/resources?type=doc', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(res => {
				this.setState({ doc_resources: res.data });
			})
			.catch(err => {
				console.log("err::", err);
			})
		fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/resources?type=image', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(res => {
				this.setState({ img_resources: res.data });
				fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/resources?type=album', {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					}
				}).then((data1) => data1.json())
					.then(res1 => {
						this.setState({ album_resources: res1.data });
					})
					.catch(err => {
						console.log("err::", err);
					})
			})
			.catch(err => {
				console.log("err::", err);
			})
	}
	reloadPageAndCancel() {
		this.getResources();
		this.setState({ addResource: false, addAlbum: false, showAlbum: false, fileType: null, album_id: null });
	}
	cancelForm() {
		this.setState({ addResource: false, addAlbum: false, showAlbum: false, fileType: null, album_id: null })
	}

	loadHistories() {
		this.setState({ histories: this.state.allHistories.slice(this.state.offset, (this.state.offset + this.state.perPage)) });
	}

	handlePageClick(data) {
		let selected = data.selected;
		let offset = Math.ceil(selected * this.state.perPage);

		this.setState({ offset: offset, historyExpenses: null }, () => {
			this.loadHistories();
		});
	}

	changeActiveTab(key) {
		if (key !== this.state.activeTabKey) {
			if (key === 1) {
				window.location.pathname = '/im/projects/' + this.props.match.params.project_id + '/resources/documents';
			} else if (key === 2) {
				window.location.pathname = '/im/projects/' + this.props.match.params.project_id + '/resources/images';
			}
		}
	}

	openAlbum(data, event, type) {
		this.setState({ showAlbum: true });
		this.setState({ album_id: data.id });
	}

	dateFormatter = (cell, row) => {
		if (row.date) {
			return (
				moment(row.date).format('l')
			)
		}
	}
	deleteResource = (id)=>{
		fetch(process.env.REACT_APP_MIS_API_URL + '/resources/'+id, {
			method: "DELETE",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(res => {
				if(res.status==='ok'){
					swal(
						'Success!',
						'Document Deleted!',
						'success'
					  )
					fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/resources?type=doc', {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('mis_data')).access_token
						}
					}).then((data) => data.json())
						.then(res => {
							this.setState({ doc_resources: res.data });
						})
						.catch(err => {
							console.log("err::", err);
						})
				}
			})
			.catch(err => {
				console.log("err::", err);
			})
	}
	deleteFormatter = (cell, row) => {
		
		return (
			row.createdBy.orgName===this.props.misData.data.organisationData.name ?  <button
			title="Delete this document"
			className="btn"
			onClick={()=>{swal({
            title: 'Are you sure?',
            text: "This document will be deleted!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
			if(result){
				this.deleteResource(row.id);
			}
          
        }).catch(err=>{//Doing nothing
		});
    }}
		  >
			<Icon icon={bin}/>
		  </button>: 
		  <button title='You cannot delete this document.' style={{cursor:"not-allowed"}} ref={(el) => el && el.style.setProperty('background', 'gray', "important")} className="btn"> <Icon icon={bin} /></button>
	//<p title="You cannot delete this document."></p>
		);
	  }
	createdbyFormatter = (cell, row) => {
		if (row.createdBy) {
			return (
				<p>{row.createdBy.fullName} <span style={{ color: "#ef5a20" }}>( {row.createdBy.orgName} )</span> </p>
			)
		}
	}
	visibilityFormatter = (cell, row) => {

		return row.visibility === 'public' ? <Icon icon={globe} title='Public'/> : <Icon icon={lock} title="Private"/> 
	}

	urlFormatter = (cell, row) => {
		if (row.url) {
			return (
				<a href={row.url} target="_blank">Click to view</a>
			)
		}
		else {
			return (<button type="button" className="btn btn-info" onClick={this.openAlbum.bind(this, row)}>Click to view Album</button>)
		}

	}

	costHeadFormatter(cell, row) {
		return cell.name;
	}

	typeFormatter(cell, row) {
		return cell.type;
	}

	budgetedFormatter(cell, row) {
		if (cell) return 'Yes';
		else if (row.isTotal) return '';
		else return 'No';
	}

	proposedAmountFormatter(cell, row) {
		return cell.toLocaleString();
	}

	reportedAmountFormatter(cell, row) {
		return cell.toLocaleString();
	}

	expenseStatusFormatter(cell, row) {
		if (cell !== '') return cell + ' Payment';
		else return '';
	}

	percentageUtilizedFormatter(cell, row) {
		if (row.budgeted || row.isTotal) return cell + '%';
		else return 'N/A';
	}

	unitEconomicsFormatter(cell, row) {
		if (row.unitEconomics && cell) return cell;
		else return '';
	}

	editDetailsFormatter(cell, row) {
		if (row.isTotal) return null;
		else return (
			<button className="btn table-btn" onClick={this.editExpense.bind(this, 'edit', row)}>
				<i className="fa fa-pencil"></i>
				Edit
			</button>
		)
	}

	editExpense(reportType, expenseData) {
		this.setState({ expenseData, editExpense: true }, function () {
			this.showReportExpenseModal(reportType);
		}.bind(this));
	}

	viewDetailsFormatter(cell, row) {
		if (row.isTotal) return null;
		else return (
			<button className="btn table-btn" onClick={this.editExpense.bind(this, 'view', row)}>
				<i className="fa fa-file-text-o"></i>
				View
			</button>
		)
	}

	billsCountFormatter(cell, row) {
		if (cell === undefined) return '';
		if (cell.length) return cell.length;
		else return row.billStatus;
	}

	openHistory(index) {
		let histories = this.state.histories;
		for (var i = 0; i < histories.length; i++) {
			histories[i]['selected'] = false;
		}
		histories[index]['selected'] = true;
		this.setState({ historyExpenses: null, histories }, function () {
			this.getHistoryExpensesData(histories[index].id);
		}.bind(this));
	}

	getHistoryExpensesData(history_id) {
		fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/reported-expenses/history/' + history_id, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				this.setState({ historyExpenses: data.expenseItems });
			}.bind(this));
	}

	showReportExpenseModal(reportType) {
		this.setState({ reportExpenseModalVisibility: true, reportType });
	}

	hideReportExpenseModal(type) {
		if (type === 'closeView') {
			this.setState({ reportExpenseModalVisibility: false, reportType: '', editExpense: false });
		} else {
			this.setState({ reportExpenseModalVisibility: false, reportType: '', editExpense: false, activeTabKey: 1, historyExpenses: null });
		}
		if (type === 'refresh') {
			fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/reported-expenses', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					this.setState({ expenses: data.expenseItems });
				}.bind(this));
		} else if (type === 'restore') {
			fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/reported-expenses', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					this.setState({ expenses: data.expenseItems });
				}.bind(this));

			fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/reported-expenses/history', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					let allHistories = data.logs;
					for (var i = 0; i < allHistories.length; i++) {
						allHistories[i]['selected'] = false;
					}
					this.setState({ allHistories, totalPages: Math.ceil(allHistories.length / this.state.perPage) }, function () {
						this.loadHistories(this);
					}.bind(this));
				}.bind(this));
		}
	}

	notifySuccess = (text) => toast.success(text)

	showAddResources(event) {
		this.setState({ addResource: true })
	}

	addNewAlbums(data, event) {
		this.setState({ openAlbumModal: true })
	}

	showAddAlbums(data, event) {
		this.setState({ addResource: true, fileType: data, showAlbum: false, showAddAlbums: false });
	}

	addExistingAlbum(event, data) {
		this.setState({ addResource: true, album_id: event.id })
	}
	resetAlbum(event) {
		this.setState({ album_id: null })
	}
	onCloseModal() {
		this.setState({ openModal: false });
	}
	onOpenModal(data, index, type) {
		this.setState({ img_arr: data, image_index: index, typeOf: type.type });
		this.setState({ openModal: true })
	}

	onCloseAlbumModal() {
		this.setState({ openAlbumModal: false });
		window.location.pathname = '/im/projects/' + this.props.match.params.project_id + '/resources/images/albums'
	}
	onCancelAlbumModal() {
		this.setState({ openAlbumModal: false });
	}
	keyChange(key) {
		if (key !== this.state.activeTab) {
			if (key === 1) {
				window.location.pathname = '/im/projects/' + this.props.match.params.project_id + '/resources/images';
			} else if (key === 2) {
				window.location.pathname = '/im/projects/' + this.props.match.params.project_id + '/resources/images/albums';
			}
		}
	}

	render() {
		return (
			<div className="im-container im-project-page im-project-finances-page">
				<Modal open={this.state.openModal} onClose={this.onCloseModal.bind(this)} center>
					<ImageModal
						imgArray={this.state.img_arr}
						imageIndex={this.state.image_index}
						openModal={this.state.openModal}
						typeOf={this.state.typeOf}
					/>
				</Modal>
				<Modal open={this.state.openAlbumModal} onClose={this.onCloseAlbumModal.bind(this)} center>
					<AlbumModal
						project_id={this.props.match.params.project_id}
						cancel={this.onCancelAlbumModal.bind(this)}
						close={this.onCloseAlbumModal.bind(this)}
					/>
				</Modal>
				<IMSingleProjectSidebar selected='resources' project_id={this.props.match.params.project_id} />
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<div className="im-project-container im-project-finance-container">
					<div className="finance-tabs-container" style={{ display: 'inline' }}>
						<button className={this.state.activeTabKey === 1 ? "btn finance-tab-btn selected" : "btn finance-tab-btn"} onClick={this.changeActiveTab.bind(this, 1, 'documents')}>
							Documents
						</button>
						<button style={{ marginLeft: "12px" }} className={this.state.activeTabKey === 2 ? "btn finance-tab-btn selected" : "btn finance-tab-btn"} onClick={this.changeActiveTab.bind(this, 2, 'photos/albums')}>
							Photos / Albums
						</button>
					</div>
					<div className="finance-tabs-content">
						{this.state.activeTabKey === 1 ? (
							<div className="tab-content budget-tab-content">
								<BootstrapTable data={this.state.doc_resources} striped={false} ref='table' hover={true}>
									<TableHeaderColumn dataField="id" isKey={true} hidden={true} export={false} searchable={false}>Id</TableHeaderColumn>
									<TableHeaderColumn dataField="title" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Title</TableHeaderColumn>
									<TableHeaderColumn dataField="url" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.urlFormatter}>Resource Url</TableHeaderColumn>
									<TableHeaderColumn dataField="description" width='120px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Description</TableHeaderColumn>
									<TableHeaderColumn dataField="date" width='80px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.dateFormatter}>Uploaded On</TableHeaderColumn>
									<TableHeaderColumn dataField="date" width='120px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.createdbyFormatter}>Uploaded By</TableHeaderColumn>
									<TableHeaderColumn dataField="visibility" width='80px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.visibilityFormatter}>Visibility</TableHeaderColumn>
									<TableHeaderColumn dataField="delete" width='50px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.deleteFormatter}></TableHeaderColumn>
								</BootstrapTable>
								<div className="budget-tab-buttons-container">
									<button className="btn" onClick={this.showAddAlbums.bind(this, 'docs')}>Add a new document</button>
								</div>
							</div>
						) : (null)}
						{this.state.activeTabKey === 2 ? (
							<div>
								<Tabs activeKey={this.state.activeTab} onSelect={this.keyChange.bind(this)} id="resources-tabs-container">
									<Tab eventKey={1} title="Images">
										<Row className="tab-content history-tab-content" style={{ height: 'auto' }}>
											<div className="history-finance-data">
												<Row>
													<div
														className="albums-container"
														style={{ backgroundColor: "#FCFCFC", width: "100%", height: "inherit", marginTop: "0px", padding: "20px", display: "flex", overflowX: "scroll", flexDirection: "column", alignItems: "center" }}>
														<div className="albums-container-header" style={{ textAlign: "center" }}>
															<h4 style={{ marginTop: "0", marginBottom: "20px", fontWeight: "bold" }}>
																IMAGES
															</h4>
														</div>
														<div className="albums-row" style={{ width: "inherit", display: "flex", flexDirection: "column" }}>
															<div style={{ flexWrap: "wrap", width: '100%', display: 'flex' }}>
																{(this.state.img_resources.length > 0) ?
																	_.map(this.state.img_resources, (album, index) => (
																		album.type !== "doc" ?(<div key={index + "kk"} className="album-1-container" style={{ margin: "0 10px 0 0", width: "150px", height: "150px", display: "flex", flexDirection: "column", alignItems: "center", cursor: 'pointer' }}
																			onClick={this.onOpenModal.bind(this, this.state.img_resources, index, { type: 'image' })}>
																			<div className="album-1">
																				<img src={album.url} alt="album" style={{ width: "inherit", height: "inherit" }} />
																			</div>
																		</div>): (null)
																	)) : (<p style={{ width: "100%", textAlign: "center" }}>No images to show</p>)}
															</div>
														</div>
														<div className="upload-image-container" onClick={this.showAddAlbums.bind(this, 'image')}>
															<div className="add-image-button">
																Add Images
															</div>
														</div>
													</div>
												</Row>
											</div>
										</Row>
									</Tab>
									<Tab eventKey={2} title="Albums">
										<Row>
											<div className="albums-container" style={{ backgroundColor: "#FCFCFC", width: "100%", height: "83%", padding: "20px", display: "flex", overflowY: "scroll", flexDirection: "column", alignItems: "center", border: "1px solid #e3e3e3" }}>
												<div className="albums-container-header" style={{ textAlign: "center" }} >
													<h4 style={{ marginTop: "0", marginBottom: "20px", fontWeight: "bold" }}> ALBUMS </h4>
												</div>
												<div className="albums-row" style={{ display: "flex", flexDirection: "column", width: "100%" }}>
													<div style={{ flexWrap: "wrap", width: '100%', display: 'flex' }}>
														{this.state.album_resources.length ? (
															_.map(this.state.album_resources, (album, index) => (
																<div className="album-1-container" style={{ margin: "0 10px 0 0", width: "150px", height: "200px", display: "flex", flexDirection: "column", alignItems: "center", cursor: 'pointer' }} onClick={this.openAlbum.bind(this, album)} >
																	<div className="album-1" style={{ backgroundColor: "#F3F3F3", width: "150px", height: "150px", borderRadius: "7px", overflow: "hidden" }}>
																		<img src={album.url} alt="album" style={{ width: "140%", height: "100%" }} />
																	</div>
																	<p className="album-1-title">{album.title}</p>
																</div>
															))) : (<p style={{ width: "100%", textAlign: "center" }}>No albums to show</p>)}
													</div>
													<div style={{ display: "flex", justifyContent: "center" }}>
														<button className="upload-image-container" style={{ background: "#ef5a20", border: "none", width: "auto", color: "#fff", borderRadius: "4px", padding: "5px 10px", position: "absolute", bottom: "40px" }} onClick={this.addNewAlbums.bind(this, 'album')}>
															Add Albums
														</button>
													</div>
												</div>
											</div>
										</Row>
									</Tab>
								</Tabs>
							</div>
						) : (null)}
					</div>

					{this.state.addResource === true ? (
						<AddResourceBox hideReportExpenseModal={this.hideReportExpenseModal.bind(this)} type={this.state.reportType}
							cancelResourcesForm={this.cancelForm.bind(this)}
							reloadPageAndCancel={this.reloadPageAndCancel.bind(this)}
							album_id={this.state.album_id}
							fileType={this.state.fileType}
							resetAlbum={this.resetAlbum.bind(this)}
							project_id={this.props.match.params.project_id} rout={this.props.match.params} editExpense={this.state.editExpense} expenseData={this.state.expenseData} />
					) : (null)}

					{this.state.showAlbum ?
						(<AddResourceBox hideReportExpenseModal={this.hideReportExpenseModal.bind(this)} type={this.state.reportType}
							cancelResourcesForm={this.cancelForm.bind(this)}
							reloadPageAndCancel={this.reloadPageAndCancel.bind(this)}
							openAlbum={this.openAlbum.bind(this)}
							album_id={this.state.album_id}
							fileType={this.state.fileType}
							resetAlbum={this.resetAlbum.bind(this)}
							onOpenModal={this.onOpenModal.bind(this)}
							onCloseModal={this.onCloseModal.bind(this)}
							project_id={this.props.match.params.project_id}
							rout={this.props.match.params}
							editExpense={this.state.editExpense}
							expenseData={this.state.expenseData} />
						) : (null)}
				</div>
			</div>
		);
	}
}