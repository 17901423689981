import React from 'react';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import $ from 'jquery';
import swal from "sweetalert2";
import Multiselect from 'react-bootstrap-multiselect';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import "sweetalert2/dist/sweetalert2.min.css";

import AddDonation from './AddDonation';
import EditDonation from './EditDonation';
import ImportDonationsModal from './ImportDonationsModal';
import EmailModal from './EmailModal';
import EmailPromptModal from './EmailPromptModal';
import RepositoryFilter from './RepositoryFilter';
import Loader from '../Loader';

const readCookie = require('../cookie.js').readCookie;

const styles = {
  clearBoth: {
    clear: 'both',
  }
};

function linkFormatter(cell, row) {
  var userProfile = "/dm/members/" + row.donor_id;
  var fullName = row['first_name'] + ' ' + row['last_name'];
  return (
    <Link to={userProfile}>{fullName}</Link>
  );
}

function receiptDownload(cell, row, ) {
  if (!row.receipt_url) {
    return (
      <div>N/A</div>
    );
  } else {
    return (
      <div>
        <a href={row.receipt_url} download>Download / </a>
        <a href={row.receipt_url} target="_blank">View</a>
      </div>
    );
  }
}


function dateFormatter(value, row, index) {
  return moment(value).format('DD/MM/YYYY');
}

export default class RepositoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      totalDonations: null,
      toDate: '',
      fromDate: '',
      showAddDonationModal: false,
      showEditDonationModal: false,
      selectRowProp: {
        mode: 'checkbox',
        onSelect: this.onRowSelect.bind(this),
        onSelectAll: this.onAllRowSelect.bind(this)
      },
      selectedRowCount: 0,
      exportButtonDisabled: true,
      showLoader: true,
      loaderHiding: '',
      donationToEdit: null,
      donorId: null,
      advancedCustomizations: [],
      otherColumns: false,
      showImportDonationsModal: false,
      sendReceiptData: {},
      selectedIndex: null,
      emailModal: false,
      promptModal: false
    };
    this.handleGenerateClick = this.handleGenerateClick.bind(this);
    this.sendReceipt = this.sendReceipt.bind(this);
    this.getDonations = this.getDonations.bind(this);
    this.onAddDonationClose = this.onAddDonationClose.bind(this);
    this.onEditDonationClose = this.onEditDonationClose.bind(this);
    this.onAddDonationOpen = this.onAddDonationOpen.bind(this);
    this.onEditDonationOpen = this.onEditDonationOpen.bind(this);
    this.onImportDonationsOpen = this.onImportDonationsOpen.bind(this);
    this.onImportDonationsClose = this.onImportDonationsClose.bind(this);
  }

  sendReceipt(cell, row, enumObject, index) {
    if (row.receipt_url) {
      return (
        <div className="btn generate-receipt-btn" onClick={(e) => this.handleSendEmailClick(row, e, index)}>
          {row.email_sent === false ? "Send Email" : "Resend"}
          <i className="fa fa-spinner fa-spin hidden"></i>
        </div>
      );
    } else {
      return 'N/A'
    }
  }

  receiptId(cell, row) {
    if (!row.receipt_url) {
      return (
        <div className="btn generate-receipt-btn" onClick={(e) => this.handleGenerateClick(row, e)}>
          Generate
          <i className="fa fa-spinner fa-spin hidden"></i>
        </div>
      );
    } else {
      return cell;
    }
  }

  closeEmailModal = ()=> {
    this.setState({ emailModal: false });
  }

  closePromptModal = ()=> {
    this.setState({ promptModal: false });
  }

  openEmailModal = ()=> {
    this.setState({ emailModal: true });
  }

  openPromptModal = ()=> {
    this.setState({ promptModal: true });
  }

  submitMailDetails = (email) =>{
    let data = {};
    data['ngo_email'] = email;
    fetch(process.env.REACT_APP_API_URL + '/update/' + this.props.ngo.ngo_id + '/email', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token 
      },
      body: JSON.stringify({data})
    })
    this.setState({emailModal: false})
  }

  mailforDetails = (email) =>{
    let filteredData = this.state.filteredData;
    if(email.trim() !== ''){
      fetch(process.env.REACT_APP_API_URL + '/donations/send_receipt/' + this.props.ngo.ngo_id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token 
        },
        body: JSON.stringify({
          donor_email: this.state.sendReceiptData.donor_email,
          donor_name: this.state.sendReceiptData.donor_email,
          ngo_name: this.state.sendReceiptData.ngo_name,
          receipt_no: this.state.sendReceiptData.receipt_no,
          receipt_url: this.state.sendReceiptData.receipt_url,
          sender_email: email
        })
      }).then(data => data.json())
      .then(data => {
        if(data.status === 'ok'){
          this.closePromptModal();
          filteredData[this.state.selectedIndex].email_sent = true;
          this.setState({ filteredData }, function(){
            swal({
              title: data.message,
              text: data.message,
              type: 'success'
            })
          }.bind(this));
        } else if(data.status === 'error'){
          this.closePromptModal();
          swal({
            title: data.message,
            text: data.message,
            type: 'error'
          })
        }
      })
    } else {
      swal({
        title: 'Please select an email first.',
        text: 'Please select an email to send the reciept with.',
        type: 'warning'
      })
    }
  }

  handleSendEmailClick(donation, e, index) {
    let data = {};
    data['donor_email'] = donation.donor_email;
    data['donor_name'] = donation.first_name + ' ' + donation.last_name;
    data['receipt_url'] = donation.receipt_url;

    fetch(process.env.REACT_APP_API_URL + '/donations/' + donation.ngo_id + '/send_receipt/' + donation.donation_id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (data.status === 'ok') {
        this.setState({sendReceiptData: data.data, selectedIndex: index}, function(){
          this.openPromptModal();
        }.bind(this))
      } else if(data.status == 'error' && data.message == 'mail error'){
        swal({
          title: "Update your email details.",
          html: "<h4>Please add an official email to proceed.</h4>",
          confirmButtonText: "Ok",
          type: "error"
        });
        $('.swal2-confirm').click(function(){
          this.setState({emailModal: true});
        }.bind(this));
      }
    }.bind(this));
  }

  handleGenerateClick(donation, e) {
    var target = $(e.target).find('i');
    $(target).removeClass('hidden');
    if (!donation.tax_exemption && donation.project === 'General') donation.tax_exemption = '0';
    let donationDataForReceipt = {
      "donor_email": donation.donor_email,
      "donor_fname": donation.first_name,
      "donor_lname": donation.last_name,
      "donor_pan": donation.donor_pan_number,
      "donor_ngo_id": this.props.ngo.le_ngo_id,
      "donor_date": moment(donation.date).format('DD/MM/YYYY'),
      "donor_amount": donation.amount,
      "donor_project": donation.project,
      "donor_tax": donation.tax_exemption,
      "org_logo": this.props.ngo.ngo_logo
    }

    if (donation.tax_exemption !== '0' && donation.donor_pan_number === '') {
      swal({
        title: 'Please add a PAN number for the donor',
        text: "Please add a PAN number for the donor!",
        type: 'warning',
      }).then(function () {
        $(target).addClass('hidden');
      })
    } else {
      fetch(process.env.REACT_APP_LE_RECEIPT_URL, {
        method: 'POST',
        body: JSON.stringify(donationDataForReceipt),
        dataType: "json",
        contentType: "application/json"
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        fetch(process.env.REACT_APP_API_URL + '/donations/' + donation.ngo_id + '/update/' + donation.donation_id, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify({
            receipt_no: data.receiptNo,
            receipt_url: data.taxReceipt
          })
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          this.onGenerateReceipt(this);
          $(target).addClass('hidden');
        }.bind(this));
      }.bind(this));
    }
  }

  onGenerateReceipt() {
    this.getDonations();
  }

  onRowSelect(row, isSelected) {
    if (isSelected) {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length + 1 }, function () {
        if (this.state.selectedRowCount) {
          this.setState({ exportButtonDisabled: false });
        }
      });
    } else {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length - 1 }, function () {
        if (this.state.selectedRowCount) {
          this.setState({ exportButtonDisabled: false });
        } else {
          this.setState({ exportButtonDisabled: true });
        }
      });
    }
  }

  onAllRowSelect(row, isSelected) {
    if (row) {
      this.setState({
        selectedRowCount: this.refs.table.state.data.length,
        exportButtonDisabled: false
      });
    } else {
      this.setState({
        selectedRowCount: 0,
        exportButtonDisabled: true
      });
    }
  }

  componentDidMount() {
    let advancedCustomizations = [];
    if (localStorage.hasOwnProperty('adv_change') && localStorage.getItem('adv_change') !== null) {
      let localData = JSON.parse(localStorage.getItem('adv_change'));
      advancedCustomizations = localData['advancedOpt'];
    }

    this.getDonations();

    let otherColumns = [];

    let project = {
      label: 'Donated Towards',
      value: 'project'
    };

    let utm_campaign = {
      label: 'Campaign',
      value: 'utm_campaign'
    };

    let utm_medium = {
      label: 'Medium',
      value: 'utm_medium'
    };

    let utm_source = {
      label: 'Source',
      value: 'utm_source'
    };

    otherColumns.push(project);
    otherColumns.push(utm_campaign);
    otherColumns.push(utm_medium);
    otherColumns.push(utm_source);

    if (advancedCustomizations.length > 0) {
      for (var i = 0; i < advancedCustomizations.length; i++) {
        for (var j = 0; j < otherColumns.length; j++) {
          if (advancedCustomizations[i] === otherColumns[j].value) {
            otherColumns[j]['selected'] = true;
          }
        }
      }
    }

    this.setState({ otherColumns, advancedCustomizations });
  }

  onAddDonationClose() {
    this.setState({ showAddDonationModal: false });

    this.getDonations();
  }

  onImportDonationsClose() {
    this.setState({ showImportDonationsModal: false });
  }

  onEditDonationClose() {
    this.setState({ showEditDonationModal: false, donationToEdit: null, currentPage: 0 }, function () {
      this.getDonations();
    }.bind(this));
  }

  onAddDonationOpen() {
    /*let max_donors = this.props.ngo.plan.max_donors;

    if(this.props.ngo.plan.hasOwnProperty('check_max_donors') && this.props.ngo.plan.check_max_donors && this.state.totalDonors > max_donors) {
      swal({
        title: 'Donor Limit reached.',
        text: "You have reached your donor limit. Please upgrade your plan or contact us if you have any queries.",
        type: 'warning',
      });
    } else {*/
      this.setState({ showAddDonationModal: true });
    // }
  }

  onImportDonationsOpen() {
    /*let max_donors = this.props.ngo.plan.max_donors;

    if(this.props.ngo.plan.hasOwnProperty('check_max_donors') && this.props.ngo.plan.check_max_donors && this.state.totalDonors > max_donors) {
      swal({
        title: 'Donor Limit reached.',
        text: "You have reached your donor limit. Please upgrade your plan or contact us if you have any queries.",
        type: 'warning',
      });
    } else {*/
      this.setState({ showImportDonationsModal: true });
    // }
  }

  onEditDonationOpen() {
    this.setState({ showEditDonationModal: true });
  }

  createCustomButtonGroup(props, onClick) {
    return (
      <div>
        <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
          {this.props.ngo.plan.name === 'premium' || this.props.ngo.plan.name === 'payment_gateway_basic_plus' ? (
            <button type='button'
              onClick={this.onAddDonationOpen}
              className={'btn btn-success react-bs-table-add-btn hidden-print'}>
              <span>
                <i className="glyphicon glyphicon-plus"></i>
                Add Donation
              </span>
            </button>
          ) : ('')}

          {props.exportCSVBtn}

          {this.props.ngo.plan.name === 'premium' || this.props.ngo.plan.name === 'payment_gateway_basic_plus' ? (
            <button type='button'
              onClick={this.onImportDonationsOpen}
              className={'btn btn-success react-bs-table-add-btn hidden-print'}>
              <span>
                <i className="glyphicon glyphicon-plus"></i>
                Import Donations
              </span>
            </button>
          ) : ('')}
        </ButtonGroup>
        <button className="total-donors">{'Total Donations: ' + this.state.totalDonations}</button>
      </div>
    );
  }

  createCustomExportCSVButton(onClick) {
    return (
      <ExportCSVButton disabled={this.state.exportButtonDisabled} />
    );
  }

  createCustomClearButton(onClick) {
    return (
      <span onClick={onClick}>
        <i className="clear-table-search glyphicon glyphicon-remove"></i>
      </span>
    );
  }

  contributionModeFormatter(value, row, index) {
    if (value) {
      return <span>{value}</span>
    } else return <span>Others</span>
  }

  editDonation(value, row, index) {
    if (value === undefined || value === null || value === '' || value === false) {
      return (
        <div>
          <div className="btn edit-delete-donation-btn mr10" onClick={this.handleEditDonation.bind(this, row)}>
            Edit
          </div>
          <div className="btn edit-delete-donation-btn mr10" onClick={this.handleDeleteDonation.bind(this, row.donation_id)}>
            Delete
          </div>
        </div>
      )
    } else return <span>N/A</span>;
  }

  handleEditDonation(donation) {
    this.setState({ donationToEdit: donation, donorId: donation.donor_id }, function () {
      this.onEditDonationOpen();
    })
  }

  handleDeleteDonation(donation_id) {
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/donation/' + donation_id, {
      method: "DELETE",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (data.status === 'ok') {
        swal({
          title: "Deleted Successfully",
          html: "<h4>Successfully deleted the donation</h4>",
          type: "success"
        });
        this.getDonations();
      }
    }.bind(this));
  }

  handleToDateChange(value, dateString) {
    this.setState({ toDate: dateString });
  }

  handleFromDateChange(value, dateString) {
    this.setState({ fromDate: dateString });
  }

  applyFilters() {
    let data = this.state.data;
    let filteredData = [];
    for (var i = 0; i < data.length; i++) {
      var selectedDate = moment(data[i].date, 'YYYYMMDD').format('YYYY-MM-DD');
      var toDate = moment(this.state.toDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      var fromDate = moment(this.state.fromDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      if (moment(selectedDate).isBetween(fromDate, toDate) || moment(selectedDate).isSame(fromDate) || moment(selectedDate).isSame(toDate)) {
        filteredData.push(data[i]);
      }
    }
    this.setState({ filteredData });
  }

  resetFilters() {
    this.setState({ fromDate: null, toDate: null }, function () {
      this.setState({ filteredData: this.state.data, fromDate: '', toDate: '' });
    }.bind(this));
  }

  onAdvancedCustomizationsChange(value) {
    let adv_change = {};
    adv_change['advancedOpt'] = $(value).parents('select').val();
    localStorage.setItem('adv_change', JSON.stringify(adv_change));
    let advancedCustomizationsLength = this.state.advancedCustomizations.length;
    this.setState({ advancedCustomizations: $(value).parents('select').val() }, function () {
      let scrollLeft = $('.donor-table.react-bs-table-container').find('.react-bs-table .react-bs-container-body .table').width() - $('.donor-table.react-bs-table-container').find('.react-bs-table .react-bs-container-body').width();
      if (scrollLeft > 0 && advancedCustomizationsLength < this.state.advancedCustomizations.length) {
        $('.donor-table.react-bs-table-container').find('.react-bs-table .react-bs-container-body').scrollLeft(scrollLeft);
        $('.donor-table.react-bs-table-container').find('.react-bs-table .react-bs-container-header').scrollLeft(scrollLeft);
      }
    });
  }

  getDonations() {
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/repositories?status=success', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({
        data: data,
        filteredData: data,
        totalDonations: data.length,
        loaderHiding: 'hiding'
      }, function () {
        setTimeout(function () {
          this.setState({ showLoader: false });
        }.bind(this), 400);
      });
    }.bind(this));
  }

  utm(cell, row) {
    if (cell) return cell;
    else return 'N/A';
  }

  render() {
    const options = {
      btnGroup: this.createCustomButtonGroup.bind(this),
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton.bind(this),
      exportCSVBtn: this.createCustomExportCSVButton.bind(this),
      sizePerPage: 20,
      sizePerPageList: [
        { text: '20 Per Page', value: 20 },
        { text: '50 Per Page', value: 50 },
        { text: '100 Per Page', value: 100 }
      ],
      onExportToCSV: () => {
        const selectedRows = this.refs.table.state.selectedRowKeys;
        return this.state.filteredData.filter(i => {
          if (selectedRows.indexOf(i.donation_id) > -1) {
            return i;
          }
        });
      },
    };
    return (
      <div style={styles.clearBoth}>
        {this.state.showLoader ? (
          <Loader loaderHiding={this.state.loaderHiding} />
        ) : ('')}
        {this.state.fromDate !== null && this.state.toDate !== null ? (
          <RepositoryFilter toDate={this.state.toDate} handleToDateChange={this.handleToDateChange.bind(this)} applyFilters={this.applyFilters.bind(this)}
            fromDate={this.state.fromDate} handleFromDateChange={this.handleFromDateChange.bind(this)} resetFilters={this.resetFilters.bind(this)} />
        ) : (null)}
        <div id="donors-table-advanced-filters">
          {this.state.otherColumns ? (
            <Multiselect id="donors-table-advanced-filters-select" buttonText={function (options, select) { return 'Advanced Customizations' }}
              buttonWidth={'100%'} data={this.state.otherColumns} multiple onChange={this.onAdvancedCustomizationsChange.bind(this)} />
          ) : (null)}
        </div>
        <BootstrapTable data={this.state.filteredData} className="donation-table" options={options} csvFileName={this.props.ngo.ngo_name + ' - Donations ' + String(moment().unix()) + '.csv'}
          striped={false} ref='table' selectRow={this.state.selectRowProp} hover={true}
          pagination search exportCSV searchPlaceholder="Search by Name/Amount/Mode of Contribution/Receipt Id">
          <TableHeaderColumn dataField="donation_id" isKey={true} hidden={true} export={false} searchable={false}>Donation Id</TableHeaderColumn>
          <TableHeaderColumn dataField="first_name" width='120px' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={linkFormatter}>Name</TableHeaderColumn>
          <TableHeaderColumn dataField="last_name" width='120px' dataSort={true} hidden={true} export={true} searchable={true}>Last Name</TableHeaderColumn>
          <TableHeaderColumn dataField="amount" width='100px' dataSort={true} hidden={false} export={true} searchable={true}>Amount ({this.props.ngo.currency})</TableHeaderColumn>
          <TableHeaderColumn dataField="contribution_mode" width='120px' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.contributionModeFormatter}>Mode of Contribution</TableHeaderColumn>
          <TableHeaderColumn dataField="project" width='200px' dataSort={false} hidden={this.state.advancedCustomizations.includes('project') ? false : true} export={true} searchable={true}>Donated Towards</TableHeaderColumn>
          <TableHeaderColumn dataField="date" width='120px' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={dateFormatter}>Date of Donation</TableHeaderColumn>
          <TableHeaderColumn dataField="receipt_no" width='120px' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.receiptId.bind(this)}>Receipt Id</TableHeaderColumn>
          <TableHeaderColumn dataField="receipt_url" width='120px' dataSort={true} hidden={true} export={true} searchable={false}>Receipt Url</TableHeaderColumn>
          <TableHeaderColumn dataField="receipt_url" width='120px' dataSort={false} hidden={false} export={false} searchable={false} dataFormat={receiptDownload}>Receipt Url</TableHeaderColumn>
          <TableHeaderColumn dataField="receipt_url" width='120px' dataSort={false} hidden={false} export={false} searchable={false} dataFormat={this.sendReceipt.bind(this)}>Email Receipt</TableHeaderColumn>
          <TableHeaderColumn dataField="utm_campaign" width='120px' dataSort={false} hidden={this.state.advancedCustomizations.includes('utm_campaign') ? false : true} export={true} searchable={false} dataFormat={this.utm.bind(this)} columnTitle>Campaign</TableHeaderColumn>
          <TableHeaderColumn dataField="utm_medium" width='120px' dataSort={true} hidden={this.state.advancedCustomizations.includes('utm_medium') ? false : true} export={true} searchable={false} dataFormat={this.utm.bind(this)} columnTitle>Medium</TableHeaderColumn>
          <TableHeaderColumn dataField="utm_source" width='120px' dataSort={true} hidden={this.state.advancedCustomizations.includes('utm_source') ? false : true} export={true} searchable={false} dataFormat={this.utm.bind(this)} columnTitle>Source</TableHeaderColumn>
          <TableHeaderColumn dataField="receipt_url" width='120px' dataSort={false} hidden={false} export={false} searchable={false} dataFormat={this.editDonation.bind(this)}>Edit / Delete</TableHeaderColumn>
        </BootstrapTable>
        
        <EmailModal showEmailModal={this.state.emailModal}
          ngo={this.props.ngo}
          onHide={this.closeEmailModal}
          onEmailModalClose={this.closeEmailModal}
          onEmailModalOpen={this.openEmailModal} 
          submitMailDetails ={this.submitMailDetails} />

        <EmailPromptModal showPromptModal={this.state.promptModal}
          sendReceiptData={this.state.sendReceiptData}
          userData={this.props.userData}
          onHide={this.closeEmailModal}
          onPromptModalClose={this.closePromptModal}
          onPromptModalOpen={this.openPromptModal} 
          mailforDetails ={this.mailforDetails} />

        {this.props.ngo.plan.name === 'premium' || this.props.ngo.plan.name === 'payment_gateway_basic_plus' ? (
          <AddDonation ngo={this.props.ngo} userData={this.props.userData}
            showAddDonationModal={this.state.showAddDonationModal}
            onHide={this.onAddDonationClose} donorId=''
            onAddDonationClose={this.onAddDonationClose}
            onAddDonationOpen={this.onAddDonationOpen} />
        ) : ('')}
        {this.props.ngo.plan.name === 'premium' || this.props.ngo.plan.name === 'payment_gateway_basic_plus' ? (
          <ImportDonationsModal ngo={this.props.ngo} userData={this.props.userData}
            showImportDonationsModal={this.state.showImportDonationsModal}
            onHide={this.onImportDonationsClose} donorId=''
            onImportDonationsClose={this.onImportDonationsClose} getDonations={this.getDonations}
            onImportDonationsOpen={this.onImportDonationsOpen} />
        ) : ('')}
        {this.state.donationToEdit !== null && this.state.donorId !== null ? (
          <EditDonation ngo={this.props.ngo} userData={this.props.userData}
            showEditDonationModal={this.state.showEditDonationModal}
            onHide={this.onEditDonationClose} donorId={this.state.donorId}
            onEditDonationClose={this.onEditDonationClose} donation={this.state.donationToEdit}
            onEditDonationOpen={this.onEditDonationOpen} />
        ) : (null)}
      </div>
    );
  }
}
