import React, {Component} from 'react';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import swal from "sweetalert2";

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import "sweetalert2/dist/sweetalert2.min.css";

import AddTagModal from './AddTagModal';
const readCookie = require('../cookie.js').readCookie;

const styles = {
  clear: {
    clear: 'both',
  },
};

export default class TagsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      tagIdToEdit: null,
      tagLabelToEdit: '',
      selectedRowCount: 0,
      showAddTagModal: false,
      deleteButtonDisabled: true,
      selectRowProp: {
        mode: 'checkbox',
        onSelect: this.onRowSelect.bind(this),
        onSelectAll: this.onAllRowSelect.bind(this)
      },
    }
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/all_campaign_tags/unsubscriber/' + this.props.ngo.ngo_id, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(res => res.json())
    .then(data => {
      if(data.status === 'ok'){
        this.setState({tags: data.data})
      }
    });
  }

  onRowSelect(row, isSelected) {
    if(isSelected) {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length+1 }, function() {
        if(this.state.selectedRowCount) {
          this.setState({ deleteButtonDisabled: false });
        }
      });
    } else {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length-1 }, function() {
        if(this.state.selectedRowCount) {
          this.setState({ deleteButtonDisabled: false });
        } else {
          this.setState({ deleteButtonDisabled: true });
        }
      });
    }
  }

  onAllRowSelect(row, isSelected) {
    if(row) {
      this.setState({
        selectedRowCount: this.refs.table.state.data.length,
        deleteButtonDisabled: false
      });
    } else {
      this.setState({
        selectedRowCount: 0,
        deleteButtonDisabled: true
      });
    }
  }

  onShowAddTag = () => {
    this.setState({showAddTagModal: true});
  }

  onCloseAddTag = () => {
    this.setState({showAddTagModal: false});
    fetch(process.env.REACT_APP_API_URL + '/all_campaign_tags/unsubscriber/' + this.props.ngo.ngo_id, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(res => res.json())
    .then(data => {
      if(data.status === 'ok'){
        this.setState({tags: data.data})
      }
    })
  }

  createCustomButtonGroup (props, onClick) {
    return (
      <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
        <button type='button' disabled={!this.state.deleteButtonDisabled}
        className={'btn btn-success react-bs-table-add-btn hidden-print'}
        onClick={this.onShowAddTag.bind(this)}>
          <span>
            <i className="glyphicon glyphicon-plus"></i>
            Add Tag
          </span>
        </button>
        <button type='button' disabled={this.state.deleteButtonDisabled}
        className={'btn btn-success react-bs-table-add-btn hidden-print'}
        onClick={this.getSelectedRowKeys.bind(this)}>
          <span>
            <i className="glyphicon glyphicon-minus"></i>
            Delete Tag
          </span>
        </button>
      </ButtonGroup>
    );
  }

  getSelectedRowKeys() {
    var tagsIdTemp = [];
    var tags = this.state.tags;
    var selectedId =  this.refs.table.state.selectedRowKeys;
    // var undeletableTags = [];

    for(var i = 0; i < selectedId.length; i++) {
      for(var j = 0; j < tags.length; j++) {
        /*if(tags[j]['campaign_tag_id'] === selectedId[i] && tags[j]['unsubscriber_count'] > 0) {
          tagsIdTemp.push(tags[j]['campaign_tag_id']);
        } else { */
        if(tags[j]['campaign_tag_id'] === selectedId[i] && tags[j]['unsubscriber_count'] >= 0) {
          tagsIdTemp.push(tags[j]['campaign_tag_id']);
        }
      }
    }

    if(tagsIdTemp.length !== 0) {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Cancel!',
        confirmButtonClass: 'btn',
        cancelButtonClass: 'btn',
      }).then(function (result) {
        if(result){
          swal({
            title: 'Deletable Tags',
            text: "Tags with 0 unsubscriber can only be deleted.",
            type: 'info',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Back to selection',
            confirmButtonClass: 'btn',
            cancelButtonClass: 'btn',
          }).then(function (result) {
            if(result) {
              fetch(process.env.REACT_APP_API_URL + '/delete/campaign_tag/'+ this.props.ngo.ngo_id, {
                method: "DELETE",
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
                },
                body: JSON.stringify({ campaign_tag_ids: tagsIdTemp })
              }).then((response) => response.json())
              .then((data) => {
                if(data.status === 'ok'){
                  swal({
                    title: 'Deleted successfully',
                    text: data.message,
                    type: 'success'
                  })
                  fetch(process.env.REACT_APP_API_URL + '/all_campaign_tags/unsubscriber/' + this.props.ngo.ngo_id, {
                    method: 'GET',
                    headers: {
                      'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
                    }
                  }).then(res => res.json())
                  .then(data => {
                    if(data.status === 'ok'){
                      this.setState({tags: data.data, deleteButtonDisabled: true})
                    }
                  })
                }
              });
            }
          }.bind(this))
        }
      }.bind(this));
    }
  }
  changeTagName = (id, label) => {
    this.setState({ tagIdToEdit: id, tagLabelToEdit: label });
  }

  changeLabel = (e) => {
    this.setState({ tagLabelToEdit: e.target.value });
  }

  editTagName = (cell, row) => {
    return (
      <div className="tag-edit">
        {this.state.tagIdToEdit !== null && this.state.tagIdToEdit === row.campaign_tag_id ? (
          <input type="text" defaultValue={row.campaign_tag_name} onChange={this.changeLabel.bind(this)} />
        ) : (
          <div>{cell}</div>
        )}

        {this.state.tagIdToEdit !== null && this.state.tagIdToEdit === row.campaign_tag_id ? (
          <button onClick={this.saveTag.bind(this)} className="btn"><i className="fa fa-check"></i></button>
        ) : (
          <button onClick={this.changeTagName.bind(this, row.campaign_tag_id, row.campaign_tag_name)} className="btn"><i className="fa fa-pencil"></i></button>
        )}
      </div>
    )
  }

  seeList = (cell, row) => {
    return (
      <div className="see-list">
        <div>{cell}</div>
        <button className="btn" onClick={this.gotoList.bind(this, row.campaign_tag_id)}><i className="fa fa-eye"></i></button>
      </div>
    )
  }

  gotoList(id) {
      window.location.pathname = '/dm/tag_list/' + id;
  }

  saveTag() {
    let tagIdToEdit = this.state.tagIdToEdit;
    let name = this.state.tagLabelToEdit;
    if(tagIdToEdit !== '' && name !== '') {
    fetch(process.env.REACT_APP_API_URL + '/edit/campaign_tag/' + tagIdToEdit + '/' + this.props.ngo.ngo_id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify({name})
    }).then(res => res.json())
    .then(data => {
      if(data.status === 'ok'){
        fetch(process.env.REACT_APP_API_URL + '/all_campaign_tags/unsubscriber/' + this.props.ngo.ngo_id, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          }
        }).then(res => res.json())
        .then(data => {
          if(data.status === 'ok'){
            this.setState({tags: data.data}, function(){
              swal({
                title: 'Table has been updated',
                text: 'Table has been updated',
                type: 'success'
              })
            }.bind(this))
          }
        });
      } else {
        swal({
          title: 'Something went wrong try again later',
          text: 'Something went wrong try again later',
          type: 'error'
        })
      }
    })
    this.setState({ tagIdToEdit: null, tagLabelToEdit: '' });
    }
  }

  render() {
    const options = { btnGroup: this.createCustomButtonGroup.bind(this) };
    return (
      <div className="mt20">
        <BootstrapTable data={this.state.tags} options={options} striped={true} hover={true} ref='table'
          selectRow={this.state.selectRowProp}>
          <TableHeaderColumn dataField="campaign_tag_id" isKey={true} hidden={true}>Id</TableHeaderColumn>
          <TableHeaderColumn dataField="campaign_tag_name"  dataSort={true} hidden={false} export={true} dataFormat={this.editTagName.bind(this)} >Tag Name</TableHeaderColumn>
          <TableHeaderColumn dataField="unsubscriber_count"  dataSort={true} hidden={false} export={true} dataFormat={this.seeList.bind(this)}>Total Unsubscribe</TableHeaderColumn>
        </BootstrapTable>

        <AddTagModal showAddTagModal={this.state.showAddTagModal} onCloseAddTag={this.onCloseAddTag} ngo={this.props.ngo}/>
      </div>
    )
  }
}