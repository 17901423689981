import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn, ButtonGroup} from 'react-bootstrap-table';
import Multiselect from 'react-bootstrap-multiselect';
import swal from 'sweetalert2';
import moment from 'moment';
import $ from 'jquery';

import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
const readCookie = require('../../partials/cookie.js').readCookie;

export default class SingleResponseTable extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formData: [],
			defaultCols: [],
			formElements:[],
			formDataCols: [],
			selectedCols: [],
			selectRowProp: {
				mode: 'checkbox',
				onSelect: this.onRowSelect.bind(this),
				onSelectAll: this.onAllRowSelect.bind(this)
			},
			currentPage: 1,
			sizePerPage: 20,
			selectedForm: {},
			totalDataSize: 0,
			deletableForm: [],
			questionTypes: {},
			selectedRowCount: 0,
			searchFilterElems: [],
			tableWidthHalf: false,
			deleteButtonDisabled: true,
		}
	}

	componentDidMount(){
		let query = '?limit=20&skip=0';
		fetch(process.env.REACT_APP_API_URL + '/fb/get/responses/' + this.props.ngo.ngo_id + '/' + this.props.formId + query,{
			method: 'POST',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			if(data.status === 'ok'){
				let formData = data.data;
				let formDataCols = [], defaultCols = [];
				Object.keys(formData[0]).map(function(key, index) {
					if(key !== '_id'){
						if(key === 'serial_number'){
							formDataCols.splice(0, 0, key).join()
						} else if(key === 'responded_by'){
							formDataCols.splice(1, 0, key).join()
						} else {
							formDataCols.push(key);
						}
					}
				})
				for(var i = 0; i < formDataCols.length; i++){
					var key = '';
					let defaultCol = {};
					if(formDataCols[i] !== 'serial_number'){
						if(formDataCols[i] === 'Submitted_on'){
							key = 'Submitted on';
							defaultCol['label'] = key;
							defaultCol['selected'] = true;
							defaultCols.push(defaultCol)
					  } else if(formDataCols[i] === 'responded_by'){
							key = 'Responded by';
							defaultCol['label'] = key;
							defaultCol['selected'] = true;
							defaultCols.push(defaultCol)
					  } else {
							key = formDataCols[i];
							defaultCol['label'] = key;
							defaultCol['selected'] = true;
							defaultCols.push(defaultCol);
					  }
					}
				}
				this.setState({formData, totalDataSize: data['total_count'], formDataCols, defaultCols, selectedCols: defaultCols, questionTypes: data['question_types']}, function(){
					fetch(process.env.REACT_APP_API_URL + '/fb/get/form/' + this.props.formId, {
						method: 'GET',
					}).then(data => data.json())
					.then(data => {
						if(data.status === 'ok'){
							this.setState({formElements: data['data']['form_element']})
						}
					})
				}.bind(this));
			}
		})
	}

	handleColChange = (e) => {
		this.setState({ selectedCols: $('select#ColsTo').val() }, ()=>{
			this.filterBycol($('select#ColsTo').val())
		});
  }

	onPageChange(page, sizePerPage) {
		let query = '?limit=' + sizePerPage + '&skip=' + (page - 1) * sizePerPage;
		fetch(process.env.REACT_APP_API_URL + '/fb/get/responses/' + this.props.ngo.ngo_id + '/' + this.props.formId + query,{
			method: 'POST',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			if(data.status === 'ok'){
				let formData = data.data;
				this.setState({formData, currentPage: page, sizePerPage: sizePerPage});
			}
		});
	}

	onSizePerPageList(sizePerPage) {
		this.setState({currentPage: 1});
		let query = '?limit=' + sizePerPage + '&skip=' + (this.state.currentPage - 1) * sizePerPage;
		fetch(process.env.REACT_APP_API_URL + '/fb/get/responses/' + this.props.ngo.ngo_id + '/' + this.props.formId + query,{
			method: 'POST',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			if(data.status === 'ok'){
				let formData = data.data;
				this.setState({formData, sizePerPage: sizePerPage});
			}
		});
	}

	downloadResponses(){
		fetch(process.env.REACT_APP_API_URL + '/fb/download/responses/' + this.props.ngo.ngo_id + '/' + this.props.formId,{
			method: 'GET',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			if(data.status === 'ok'){
				window.open(data.fileUrl);
			}
		})
	}

	onRowSelect(row, isSelected) {
		let deletableForm = this.state.deletableForm;
		let id = row['_id'];
		if(isSelected) {
			deletableForm.push(id);
		  this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length+1, deletableForm }, function() {
				if(this.state.selectedRowCount) {
				  this.setState({ deleteButtonDisabled: false });
				}
		  });
		} else {
			deletableForm.splice(deletableForm.indexOf(id), 1);
		  this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length-1, deletableForm }, function() {
				if(this.state.selectedRowCount) {
				  this.setState({ deleteButtonDisabled: false });
				} else {
				this.setState({ deleteButtonDisabled: true });
				}
		  });
		}
	}

  onAllRowSelect(row, isSelected) {
		if(row) {
			let formData = this.state.formData;
			let deletableForm = [];
			for(var i = 0; i<formData.length; i++){
				deletableForm.push(formData[i]['_id']);
			}
		  this.setState({
				selectedRowCount: this.refs.table.state.data.length,
				deleteButtonDisabled: false,
				deletableForm
		  });
		} else {
		  this.setState({
				selectedRowCount: 0,
				deleteButtonDisabled: true,
			  deletableForm: []
		  });
		}
  }


	createCustomButtonGroup (props, onClick) {
		return (
		  <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
				<button type='button' disabled={this.state.deleteButtonDisabled}
					className={'btn btn-success react-bs-table-add-btn hidden-print'}
					onClick={ this.delResponse.bind(this, 'multiple')}>
				  <span>
					<i className="glyphicon glyphicon-minus"></i>
					 Delete Responses
				  </span>
				</button>
				<button type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'} onClick={this.downloadResponses.bind(this)}>
				  <span>
						Download Responses
				  </span>
				</button>
		  </ButtonGroup>
		);
  }

  closeSideView = () =>{
		if(this.state.edit === true){
			swal({
				title: 'Please save your changes first.',
				type: 'warning'
			})
		} else {
			this.setState({tableWidthHalf: false});
		}
  }

  switchResponse(type){
		let selectedForm = this.state.selectedForm;
		let formData = this.state.formData;
		if(type === 'next'){
			let id = selectedForm['serial_number'];
			if(id < formData.length){
				id += 1;
				for(var i=0; i < formData.length; i++){
						if(id === formData[i].serial_number){
							selectedForm = formData[i];
							break;
						}
				}
			}
		} else if(type === 'prev'){
			let id = selectedForm['serial_number'];
			if(id > 0){
				id -= 1;
				for(var i=0; i < formData.length; i++){
					if(id === formData[i].serial_number){
						selectedForm = formData[i];
						break;
					}
				}
			}
		}
		this.setState({selectedForm});
  }

	selectedResponse(id) {
		let formData = this.state.formData;
		let selectedForm = {};
		for(var i=0; i < formData.length; i++){
			if(id === formData[i].serial_number){
				selectedForm = formData[i];
				break;
			}
		}
		this.setState({selectedForm, tableWidthHalf: true});
	}

	editSelected(cell, row){
		return(
			<div style={{color: '#108ee9', cursor: 'pointer'}} onClick={this.selectedResponse.bind(this, cell)}>{cell}</div>
		)
	}

	openTableUrl(cell, row){
		if(cell.trim() !== ''){
			return(
				<a className="open-table-url" onClick={()=>{window.open(cell)}}>{cell}</a>
			)
		}
	}

	submittedOn(cell, row){
		return cell;
	}

	dynamicTableData(cell, row){
		if(cell !== undefined && (cell.hasOwnProperty('name') || cell.hasOwnProperty('email'))){
			if(cell.name !== ''){
				return(
					<div style={{color: '#108ee9', cursor: 'pointer'}} title={cell.name} className="dynamic-table-data" onClick={this.selectedResponse.bind(this, row.serial_number)}>{cell.name}</div>
				)
			} else if(cell.email !== '') {
				return(
					<div style={{color: '#108ee9', cursor: 'pointer'}} title={cell.email} className="dynamic-table-data" onClick={this.selectedResponse.bind(this, row.serial_number)}>{cell.email}</div>
				)
			} else {
				return(
					<div className="dynamic-table-data">Anonymous</div>
				)
			}
		} else if(cell !== undefined && cell.hasOwnProperty('response') && cell.response !== null && cell.response !== undefined){
			if(typeof cell === 'object') {
				if(cell.hasOwnProperty('response')) {
					let response = cell.response;
					if(Array.isArray(response)){
						return response.join(', ');
					} else if(typeof response === 'object'){
						if(response.hasOwnProperty('urls')) {
							if(response.hasOwnProperty('latitude') && response.hasOwnProperty('longitude')) {
								return (
									<span>
										{response.urls.map(function(url, index) {
											return (
												<span key={index}>
													{index > 0 ? ', ' : null}
													<a href={url} target="_blank">{index === 0 ? 'File' : 'File ' + (index + 1)}</a>
												</span>
											)
										}.bind(this))}
										<br />
										<span>{'(' + response.latitude + ',' + response.longitude + ')'}</span>
									</span>
								)
							} else {
								return (
									<span>
										{response.urls.map(function(url, index) {
											return (
												<span key={index}>
													{index > 0 ? ', ' : null}
													<a href={url} target="_blank">{index === 0 ? 'File' : 'File ' + (index + 1)}</a>
												</span>
											)
										}.bind(this))}
									</span>
								)
							}
						} else if(response.hasOwnProperty('url')) {
							if(response.hasOwnProperty('latitude') && response.hasOwnProperty('longitude')) {
								return (
									<span>
										<a href={response.url} target="_blank">File</a>
										<span>{'(' + response.latitude + ',' + response.longitude + ')'}</span>
									</span>
								)
							} else {
								return (
									<span>
										<a href={response.url} target="_blank">File</a>
									</span>
								)
							}
						} else if(response.hasOwnProperty('location_name')) {
							return(
								<div style={{color: '#108ee9', cursor: 'pointer'}} title={response.location_name} className="dynamic-table-data" onClick={this.selectedResponse.bind(this, row.serial_number)}>{response.location_name}</div>
							)
						} else if(response.hasOwnProperty('latitude') && response.hasOwnProperty('longitude')) {
							return(
								<div style={{color: '#108ee9', cursor: 'pointer'}} title={'lat: ' + response.latitude + 'lng: ' + response.longitude} className="dynamic-table-data" onClick={this.selectedResponse.bind(this, row.serial_number)}>lat: {response.latitude} lng: {response.longitude}</div>
							)
						} else if(response.hasOwnProperty('distance')) {
							return(
								<div style={{color: '#108ee9', cursor: 'pointer'}} title={response.distance} className="dynamic-table-data" onClick={this.selectedResponse.bind(this, row.serial_number)}>{response.distance}</div>
							)
						} else if(response.hasOwnProperty('formatted_address')){
							if(response.hasOwnProperty('formatted_address') && response.hasOwnProperty('marked_formatted_address')){
								return(
									<div>
										{response.formatted_address !== '' ? (
											<div style={{color: '#108ee9', cursor: 'pointer'}} title={response.formatted_address} className="dynamic-table-data" onClick={this.selectedResponse.bind(this, row.serial_number)}><span style={{marginRight: '5px'}}>Actual Location:</span>{response.formatted_address}</div>
										) : (null)}
										{response.marked_formatted_address !== '' ? (
											<div style={{color: '#108ee9', cursor: 'pointer'}} title={response.marked_formatted_address} className="dynamic-table-data" onClick={this.selectedResponse.bind(this, row.serial_number)}><span style={{marginRight: '5px'}}>Marked Location:</span>{response.marked_formatted_address}</div>
										) : (null)}
									</div>
								)
							} else if(response.hasOwnProperty('formatted_address')) {
								if(response.formatted_address !== ''){
									return(
										<div style={{color: '#108ee9', cursor: 'pointer'}} title={response.formatted_address} className="dynamic-table-data" onClick={this.selectedResponse.bind(this, row.serial_number)}>{response.formatted_address}</div>
									)
								} else return null;
							} else {
								return(
									<div style={{color: '#108ee9', cursor: 'pointer'}} title={response.latlng.latitude} className="dynamic-table-data" onClick={this.selectedResponse.bind(this, row.serial_number)}>{response.latlng.latitude + ', ' +  response.latlng.longitude}</div>
								)
							}
						} else return null;
					} else if(typeof response === 'string' && (response.includes('http://') || response.includes('https://'))){
						return (
							<span>
								<a style={{color: '#108ee9', cursor: 'pointer'}} title={response} href={response} target="_blank">{response}</a>
							</span>
						)
					} else {
						return (
							<div style={{color: '#108ee9', cursor: 'pointer'}} title={response} className="dynamic-table-data" onClick={this.selectedResponse.bind(this, row.serial_number)}>{response}</div>
						)
					}
				} else {
					return null;
				}
			} else return cell;
		} else if(cell !== undefined && typeof cell === 'object' && Object.keys(cell).length === 0){
			return ('')
		}
	}

  filterBycol(value){
		let formDataCols = this.state.formDataCols;
		let newFormDataCols = [];
		newFormDataCols = ['serial_number'];
		if(value.length) {
			for(var i = 0; i < value.length; i++){
				if(value[i] === 'Submitted on') {
					newFormDataCols.push('Submitted_on');
				} else if(value[i] === 'Responded by'){
					newFormDataCols.push('responded_by');
				} else {
					let formKeys = Object.keys(this.state.formData[0]);
					for(var j = 0; j < formKeys.length; j++) {
						let compareValue = value[i].toLowerCase();
						if(formKeys[j].toLowerCase() === compareValue) {
							newFormDataCols.push(formKeys[j]);
						}
					}
				}
			}
		}
		this.setState({formDataCols: newFormDataCols});
	}

	delResponse(type){
		swal({
			title: 'Are you sure? You will not be able to revert this action.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonColor: '#EF1313',
			confirmButtonText: 'Yes, I am sure!',
			cancelButtonText: "No, cancel it!",
			closeOnConfirm: true,
			closeOnCancel: true
		}).then((isConfirm)=> {
			if(type === 'single'){
				let selectedForm = this.state.selectedForm;
				if (Object.keys(selectedForm).length > 0) {
					fetch(process.env.REACT_APP_API_URL + '/fb/delete/response/' + this.props.ngo.ngo_id + '/' + this.props.formId + '/' + selectedForm['_id'], {
						method: 'DELETE',
						headers: {
							'Authorization': JSON.parse(readCookie('access_token')).access_token
						}
					}).then(data => data.json())
					.then(data => {
						if(data.status === 'ok'){
							this.setState({tableWidthHalf: false, selectedForm: {}}, function() {
								let query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage;
								fetch(process.env.REACT_APP_API_URL + '/fb/get/responses/' + this.props.ngo.ngo_id + '/' + this.props.formId + query,{
									method: 'POST',
									headers: {
										'Authorization': JSON.parse(readCookie('access_token')).access_token
									}
								}).then(data => data.json())
								.then(data => {
									if(data.status === 'ok'){
										let formData = data.data;
										this.setState({formData, totalDataSize: data['total_count']});
									}
								});
							}.bind(this));
						}
					});
				}
			} else if(type === 'multiple'){
				if(this.state.deletableForm.length > 0){
					let data = {};
					data['response_ids'] = this.state.deletableForm;
					fetch(process.env.REACT_APP_API_URL + '/fb/delete/responses/' + this.props.ngo.ngo_id + '/' + this.props.formId,{
						method: 'DELETE',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': JSON.parse(readCookie('access_token')).access_token
						},
						body: JSON.stringify(data)
					}).then(data => data.json())
					.then(data => {
						if(data.status === 'ok'){
							this.setState({tableWidthHalf: false, selectedForm: {}}, function() {
								let query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage;
								fetch(process.env.REACT_APP_API_URL + '/fb/get/responses/' + this.props.ngo.ngo_id + '/' + this.props.formId + query,{
									method: 'POST',
									headers: {
										'Authorization': JSON.parse(readCookie('access_token')).access_token
									}
								}).then(data => data.json())
								.then(data => {
									if(data.status === 'ok'){
										let formData = data.data;
										this.setState({formData, totalDataSize: data['total_count'], selectedRowCount: 0, deleteButtonDisabled: true, deletableForm: []}, function(){
											swal({
												title: 'Successfully deleted.',
												text: 'Selected forms have been deleted.',
												type: 'success'
											})
										}.bind(this));
									}
								});
							}.bind(this));
						} else {
							swal({
								title: 'Failed to delete.',
								text: 'Please try again.',
								type: 'error'
							})
						}
					})
				}
			}
		});
	}

	setSearchValue(i, type, e){
		let searchFilterElems = this.state.searchFilterElems;
		if(type === 'text'){
			searchFilterElems[i]['answer'] = e.target.value;
			this.setState({searchFilterElems});
		} else if(type === 'number'){
			searchFilterElems[i]['answer'] = e.target.value;
			this.setState({searchFilterElems});
		} else if(type === 'multiple'){
			let multipleAnsList = $('select#answerList-' + i).val();
			searchFilterElems[i]['answer'] = multipleAnsList;
			for(let k = 0; k < searchFilterElems[i]['dropdownList'].length; k++){
				if(multipleAnsList.indexOf(searchFilterElems[i]['dropdownList'][k]['label']) > -1) {
					searchFilterElems[i]['dropdownList'][k]['selected'] = true;
				}
			}
			this.setState({searchFilterElems});
		} else if(type === 'date'){
			let answer = moment(e.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY');
			searchFilterElems[i]['answer'] = answer;
			this.setState({searchFilterElems});
		} else if(type === 'time'){
			searchFilterElems[i]['answer'] = e.target.value;
			this.setState({searchFilterElems});
		}
	}

	selectQuestionType(i, e){
		let searchFilterElems = this.state.searchFilterElems, formElements = this.state.formElements, questionTypes = this.state.questionTypes, value = e.target.value, selectedType = '', questionId = '', dropdownAnswerList = [];

		Object.keys(questionTypes).map((type, l)=>{
			if(value === type){
				selectedType = questionTypes[type];
				questionId = type;
			}
		})

		for(let j = 0; j < formElements.length; j++){
			if(formElements[j]['question_id'] === questionId && (formElements[j]['type'] === 'choice' || formElements[j]['type'] === 'yesno' || formElements[j]['type'] === 'correlation' || formElements[j]['type'] === 'payment')){
				if(formElements[j]['type'] === 'choice'){
					for(let k = 0; k < formElements[j]['newChoiceOptions'].length; k++){
						let dropdownAnswer = {};
						dropdownAnswer['label'] = formElements[j]['newChoiceOptions'][k];
						dropdownAnswer['selected'] = false;
						dropdownAnswerList.push(dropdownAnswer);
					}
					let dropdownAnswer1 = {};
					dropdownAnswer1['label'] = formElements[j]['choicePlaceholder1'];
					dropdownAnswer1['selected'] = false;

					dropdownAnswerList.splice(0, 0, dropdownAnswer1).join()
				} else if(formElements[j]['type'] === 'yesno'){
					let dropdownAnswer1 = {};
					dropdownAnswer1['label'] = formElements[j]['yesnoPlaceholder1'];
					dropdownAnswer1['selected'] = false;

					dropdownAnswerList.push(dropdownAnswer1);

					let dropdownAnswer2 = {};
					dropdownAnswer2['label'] = formElements[j]['yesnoPlaceholder2'];
					dropdownAnswer2['selected'] = false;

					dropdownAnswerList.push(dropdownAnswer2);

				} else if(formElements[j]['type'] === 'payment'){
					if(formElements[j]['newpaymentOptions'].length){
						for(let k = 0; k < formElements[j]['newpaymentOptions'].length; k++){
							let dropdownAnswer = {};
							if(formElements[j]['newpaymentOptions'][k]['productValue'] !== ''){
								let tempString = '';
								tempString = formElements[j]['newpaymentOptions'][k]['productLabel'] + '(Rs. ' + formElements[j]['newpaymentOptions'][k]['productValue'] + ')';
								dropdownAnswer['label'] = tempString;
								dropdownAnswer['selected'] = false;
								dropdownAnswerList.push(dropdownAnswer);
							} else {
								dropdownAnswer['label'] = formElements[j]['newpaymentOptions'][k]['productLabel'];
								dropdownAnswer['selected'] = false;
								dropdownAnswerList.push(dropdownAnswer);
							}
						}
					}
					if(formElements[j]['productValue1'].trim() !== ''){
						let dropdownAnswer = {};
						let tempString2 = formElements[j]['productLabel1'] +'(Rs. ' + formElements[j]['productValue1'] +')';
						dropdownAnswer['label'] = tempString2;
						dropdownAnswer['selected'] = false;
						dropdownAnswerList.splice(0, 0, dropdownAnswer).join();
					} else {
						let dropdownAnswer = {};
						dropdownAnswer['label'] = formElements[j]['productLabel1'];
						dropdownAnswer['selected'] = false;
						dropdownAnswerList.splice(0, 0, dropdownAnswer).join();
					}
				} else if(formElements[j]['type'] === 'correlation'){
					for(let k = 0; k < formElements[j]['selectedRelationalResponses'].length; k++){
						let dropdownAnswer = {};
						dropdownAnswer['label'] = formElements[j]['selectedRelationalResponses'][k];
						dropdownAnswer['selected'] = false;
						dropdownAnswerList.push(dropdownAnswer);
					}
				}
			}
		}

		if(selectedType !== 'name' || selectedType !== 'address' || selectedType !== 'location' || selectedType !== 'distance' || selectedType !== 'scale' || selectedType !== 'content' || selectedType !== 'areamap' || selectedType !== 'signature'){
			if(selectedType === 'textbox' || selectedType === 'textarea' || selectedType === 'email' || selectedType === 'website') selectedType = 'text';
			else if(selectedType === 'rating' || selectedType === 'currency') selectedType = 'number';
			else if(selectedType === 'yesno' || selectedType === 'correlation' || selectedType === 'payment') selectedType = 'choice';

			fetch(process.env.REACT_APP_API_URL + '/fb/get/conditional/options/' + this.props.ngo.ngo_id + '/' + this.props.formId, {
				method: 'POST',
				headers: {
					'Authorization': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify({ type: selectedType})
			}).then(data => data.json())
			.then(data => {
				searchFilterElems[i]['question_id'] = questionId;
				searchFilterElems[i]['type'] = selectedType;
				searchFilterElems[i]['criteriaList'] = data;
				searchFilterElems[i]['dropdownList'] = dropdownAnswerList;
				searchFilterElems[i]['criteria'] = '';
				searchFilterElems[i]['answer'] = '';
				this.setState({ searchFilterElems })
			})
		}
	}

	selectCriteria(i, e){
		let searchFilterElems = this.state.searchFilterElems;
		searchFilterElems[i]['criteria'] = e.target.value;
		this.setState({searchFilterElems})
	}

	addFilterElems(){
		let searchFilterElems = this.state.searchFilterElems;
		let filterElem = {};
		filterElem['question_id'] = '';
		filterElem['type'] = '';
		filterElem['criteria'] = '';
		filterElem['answer'] = '';
		filterElem['criteriaList'] = [];
		filterElem['dropdownList'] = [];
		searchFilterElems.push(filterElem);
		this.setState({searchFilterElems});
	}

	applyFilterElems(){
		let searchFilterElems = this.state.searchFilterElems, filters = [], flag = false;
		for(var i = 0; i < searchFilterElems.length; i++){
			let filterObj = {};
			if(searchFilterElems[i]['question_id'].trim() !== '' && searchFilterElems[i]['type'].trim() !== '' && searchFilterElems[i]['criteria'].trim() !== ''){
				if((searchFilterElems[i]['criteria'] === 'equals' || searchFilterElems[i]['criteria'] === 'is not equal' || searchFilterElems[i]['criteria'] === 'does not equal' || searchFilterElems[i]['criteria'] === 'is' || searchFilterElems[i]['criteria'] === 'is not' || searchFilterElems[i]['criteria'] === 'starts with' || searchFilterElems[i]['criteria'] === 'does not start with' || searchFilterElems[i]['criteria'] === 'ends with' || searchFilterElems[i]['criteria'] === 'does not end with' || searchFilterElems[i]['criteria'] === 'contains' || searchFilterElems[i]['criteria'] === 'does not contain' || searchFilterElems[i]['criteria'] === 'is less than' || searchFilterElems[i]['criteria'] === 'is greater than' || searchFilterElems[i]['criteria'] === 'is after' || searchFilterElems[i]['criteria'] === 'is before' || searchFilterElems[i]['criteria'] === 'day is' || searchFilterElems[i]['criteria'] === 'month is' || searchFilterElems[i]['criteria'] === 'year is' || searchFilterElems[i]['criteria'] === 'hour is' || searchFilterElems[i]['criteria'] === 'minute is') && searchFilterElems[i]['answer'] !== null){
					if((Array.isArray(searchFilterElems[i]['answer']) && searchFilterElems[i]['answer'].length !== 0) || (typeof searchFilterElems[i]['answer'] === 'string' && searchFilterElems[i]['answer'].trim() !== '') || ($.isNumeric(searchFilterElems[i]['answer']))){
						filterObj['question_id'] = searchFilterElems[i]['question_id'];
						filterObj['type'] = searchFilterElems[i]['type'];
						filterObj['criteria'] = searchFilterElems[i]['criteria'];
						filterObj['answer'] = searchFilterElems[i]['answer'];
						filters.push(filterObj);
					} else {
						flag = true;
						break;
					}
				} else if((searchFilterElems[i]['criteria'] === 'equals' || searchFilterElems[i]['criteria'] === 'is not equal' || searchFilterElems[i]['criteria'] === 'does not equal' || searchFilterElems[i]['criteria'] === 'is' || searchFilterElems[i]['criteria'] === 'is not' || searchFilterElems[i]['criteria'] === 'starts with' || searchFilterElems[i]['criteria'] === 'does not start with' || searchFilterElems[i]['criteria'] === 'ends with' || searchFilterElems[i]['criteria'] === 'does not end with' || searchFilterElems[i]['criteria'] === 'contains' || searchFilterElems[i]['criteria'] === 'does not contain' || searchFilterElems[i]['criteria'] === 'is less than' || searchFilterElems[i]['criteria'] === 'is greater than' || searchFilterElems[i]['criteria'] === 'is after' || searchFilterElems[i]['criteria'] === 'is before' || searchFilterElems[i]['criteria'] === 'day is' || searchFilterElems[i]['criteria'] === 'month is' || searchFilterElems[i]['criteria'] === 'year is' || searchFilterElems[i]['criteria'] === 'hour is' || searchFilterElems[i]['criteria'] === 'minute is') && searchFilterElems[i]['answer'] === null){
					flag = true;
					break;
				} else {
					filterObj['question_id'] = searchFilterElems[i]['question_id'];
					filterObj['type'] = searchFilterElems[i]['type'];
					filterObj['criteria'] = searchFilterElems[i]['criteria'];
					filters.push(filterObj);
				}
			} else {
				flag = true;
				break;
			}
		}
		if(flag){
			swal({
				title: 'Please enter all the filter fields.',
				type: 'warning'
			})
		} else {
			if(filters.length){
				let query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage;
				fetch(process.env.REACT_APP_API_URL + '/fb/get/responses/' + this.props.ngo.ngo_id + '/' + this.props.formId + query,{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify({filters})
				}).then(data => data.json())
				.then(data => {
					if(data.status === 'ok'){
						let formData = data.data;
						this.setState({formData, totalDataSize: data['total_count'], selectedRowCount: 0, deleteButtonDisabled: true, deletableForm: []});
					}
				});
			}
		}
	}

	deleteFilterElem(index){
		let searchFilterElems = this.state.searchFilterElems;
		searchFilterElems.splice(index, 1);
		this.setState({searchFilterElems: []}, function(){
			this.forceUpdate();
			this.setState({ searchFilterElems }, function() {
				this.forceUpdate();
			}.bind(this));
			if(!searchFilterElems.length){
				let query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage;
				fetch(process.env.REACT_APP_API_URL + '/fb/get/responses/' + this.props.ngo.ngo_id + '/' + this.props.formId + query,{
					method: 'POST',
					headers: {
						'Authorization': JSON.parse(readCookie('access_token')).access_token
					}
				}).then(data => data.json())
				.then(data => {
					if(data.status === 'ok'){
						let formData = data.data;
						this.setState({formData, totalDataSize: data['total_count'], selectedRowCount: 0, deleteButtonDisabled: true, deletableForm: []});
					}
				});
			}
		}.bind(this));
	}

	resetFilterElems(){
		let searchFilterElems = this.state.searchFilterElems;
		searchFilterElems = [];
		this.setState({searchFilterElems}, ()=>{
			let query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage;
			fetch(process.env.REACT_APP_API_URL + '/fb/get/responses/' + this.props.ngo.ngo_id + '/' + this.props.formId + query,{
				method: 'POST',
				headers: {
					'Authorization': JSON.parse(readCookie('access_token')).access_token
				}
			}).then(data => data.json())
			.then(data => {
				if(data.status === 'ok'){
					let formData = data.data;
					let filterElem = {};
					filterElem['question_id'] = '';
					filterElem['type'] = '';
					filterElem['criteria'] = '';
					filterElem['answer'] = '';
					filterElem['criteriaList'] = [];
					filterElem['dropdownList'] = [];
					searchFilterElems.push(filterElem);
					this.setState({formData, totalDataSize: data['total_count'], searchFilterElems, selectedRowCount: 0, deleteButtonDisabled: true, deletableForm: []});
				}
			});
		})
	}

  createCustomClearButton(onClick) {
		return (
		  <span onClick={onClick}>
				<i className="clear-table-search glyphicon glyphicon-remove"></i>
		  </span>
		);
  }

	render() {
		const options = {
			btnGroup: this.createCustomButtonGroup.bind(this),
			sizePerPage: this.state.sizePerPage,
			onPageChange: this.onPageChange.bind(this),
			page: this.state.currentPage,
		  sizePerPageList: [
				{ text: '20 Per Page', value: 20 },
				{ text: '50 Per Page', value: 50 },
				{ text: '100 Per Page', value: 100 }
			],
			onSizePerPageList: this.onSizePerPageList.bind(this)
		};
		return (
			<div className="single-response-container">
				<div className="filterandsearch-container">
					<div className="response-filter col-md-2">
						<label className="control-label">Select by columns</label>
						<Multiselect id="colsTo" onChange={this.handleColChange} enableCaseInsensitiveFiltering defaultValue={this.state.selectedCols}
						buttonWidth={'100%'} data={this.state.defaultCols} multiple enableClickableOptGroups enableCollapsibleOptGroups numberDisplayed={3}
						includeSelectAllOption enableFiltering onSelectAll={this.handleColChange} onDeselectAll={this.handleColChange} />
					</div>
					{/*<button className={this.state.searchFilterElems.length ? "btn le-btn hide" : "btn le-btn ml15"} onClick={this.addFilterElems.bind(this)}>Filter by question-type</button>*/}
					<div className="response-search col-md-12">
						<label className={this.state.searchFilterElems.length ? "control-label" : "control-label hide"}>Filter by columns</label>
						{this.state.searchFilterElems.length ? (
							this.state.searchFilterElems.map((elem, index)=>{
								return(
									<div key={index} className="response-search-container col-md-12 mb10">
										<div className="response-search-elements-holder">
											<div className="response-select-elements-container">
												<div>
													<select className="response-column-select form-control" onChange={this.selectQuestionType.bind(this, index)} value={elem.question_id}>
														<option value='' disabled selected>Select Question</option>
														{this.state.formData.length ? (
															Object.keys(this.state.formData[0]).map((col, i)=>{
																if(col === 'serial_number' || col === 'Submitted_on' || col === '_id' || col === 'responded_by' || col === 'Geo Location'){
																	return null;
																} else {
																	return Object.keys(this.state.questionTypes).map((key, index) =>{
																		if(key === this.state.formData[0][col].question_id && this.state.questionTypes[key] !== 'name'){
																			return (
																				<option value={this.state.formData[0][col].question_id} key={i}>{col.charAt(0).toUpperCase() + col.slice(1)}</option>
																			)
																		} else {
																			return null
																		}
																	})
																}
															})
														) : (null)}
													</select>
												</div>
												{elem.question_id ? (
													<div className="ml10">
														{elem.criteriaList.length ? (
															<select className="response-criteria-select form-control" onChange={this.selectCriteria.bind(this, index)} style={{height: '36px'}} value={elem.criteria}>
																<option value='' selected disabled>Select Criteria</option>
																	{elem.criteriaList.map((criteria, i)=>{
																		return (
																			<option value={criteria} key={i}>{criteria}</option>
																		)
																	})}
															</select>
														) : (null)}
													</div>
												) : (null)}
												{elem.question_id && elem.criteria ? (
													<div className="ml10">
														{elem.type === 'text' ? (
															elem.criteria === 'is blank/empty' || elem.criteria === 'is not blank/empty' ?
															(
																<input type="text" placeholder="Enter search argument.." className="form-control" disabled />
															) : (
																<input type="text" placeholder="Enter search argument.." onChange={this.setSearchValue.bind(this, index, 'text')} className="form-control" value={elem.answer} />
															)
														) : (null)}
														{elem.type === 'number' ? (
															elem.criteria === 'is blank/empty' || elem.criteria === 'is not blank/empty' || elem.criteria === 'is positive' || elem.criteria === 'is negative' || elem.criteria === 'is positive or zero' || elem.criteria === 'is negative or zero' ? (
																<input type="number" placeholder="Enter search argument.." className="form-control" disabled />
															) : (
																<input type="number" placeholder="Enter search argument.." onChange={this.setSearchValue.bind(this, index, 'number')} className="form-control" value={elem.answer} />
															)
														) : (null)}
														{(elem.type === 'choice' || elem.type === 'payment' || elem.type === 'yesno' ||  elem.type === 'correlation') && elem.dropdownList.length ? (
															elem.criteria === 'all' || elem.criteria === 'none' ? (null) : (
																<Multiselect id={"answerList-" + index} onChange={this.setSearchValue.bind(this, index, 'multiple')} buttonWidth={'100%'} data={elem.dropdownList} multiple enableClickableOptGroups enableCollapsibleOptGroups numberDisplayed={3} includeSelectAllOption onSelectAll={this.setSearchValue.bind(this, index, 'multiple')} onDeselectAll={this.setSearchValue.bind(this, index, 'multiple')} />
															)
														) : (null)}
														{elem.type === 'date' ? (
															elem.criteria === 'is blank/empty' || elem.criteria === 'is not blank/empty' || elem.criteria === 'is in future' || elem.criteria === 'is in past' || elem.criteria === 'is today' ? (
																<input type="date" placeholder="Enter search argument.." className="form-control" disabled />
															) : (
																<input type="date" placeholder="Enter search argument.." onChange={this.setSearchValue.bind(this, index, 'date')} className="datepicker" value={moment(elem.answer, 'DD/MM/YYYY').format('YYYY-MM-DD')} />
															)
														) : (null)}
														{elem.type === 'time' ? (
															elem.criteria === 'is blank/empty' || elem.criteria === 'is not blank/empty' ? (
																<input type="date" placeholder="Enter search argument.." className="form-control" disabled />
															) : (
																<input type="time" placeholder="Enter search argument.." onChange={this.setSearchValue.bind(this, index, 'time')} className="form-control" value={elem.answer} />
															)
														) : (null)}
													</div>
												) : (null)}
											</div>
										</div>
										<div className="del-btn-conatainer">
											<button className="btn le-btn ml10" style={{height: '35px'}} onClick={this.deleteFilterElem.bind(this, index)}><i className="fa fa-trash"></i></button>
										</div>
									</div>
								)
							})
						) : (null)}
						<div className="col-md-12 filters-add-apply-btn-container">
							<button className={this.state.searchFilterElems.length ? "btn le-btn mr10" : "btn le-btn hide"} onClick={this.resetFilterElems.bind(this)}><i className="fa fa-refresh"></i> Reset</button>
							<button className={this.state.searchFilterElems.length ? "btn le-btn mr10" : "btn le-btn hide"} onClick={this.applyFilterElems.bind(this)}><i className="fa fa-check-circle"></i> Apply</button>
							<button className={this.state.searchFilterElems.length ? "btn le-btn" : "btn le-btn hide"} onClick={this.addFilterElems.bind(this)}><i className="fa fa-plus-circle"></i> Add more</button>
						</div>
					</div>
				</div>
				<div className="flex-view">
					<div className={this.state.tableWidthHalf === false ? "single-response-table" : "single-response-table small"}>
						<BootstrapTable data={this.state.formData} striped={false} ref='table' fetchInfo={{dataTotalSize: this.state.totalDataSize}} remote={true} pagination={true} options={options} hover={true} selectRow={this.state.selectRowProp} keyField="_id">
							{this.state.formDataCols.map((col, index) => {
								if(col === 'serial_number'){
									return (
										<TableHeaderColumn dataField={col} width='150px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} dataFormat={this.editSelected.bind(this)}>Serial number</TableHeaderColumn>
									)
								} else if(col === 'Submitted_on'){
									return (
										<TableHeaderColumn dataField={col} width='150px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} dataFormat={this.submittedOn.bind(this)}>Submitted on</TableHeaderColumn>
									)
								} else if(col === 'responded_by'){
									return(
										<TableHeaderColumn dataField={col} width='150px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} dataFormat={this.dynamicTableData.bind(this)}>Responded by</TableHeaderColumn>
									)
								} else if(col === 'geoLocation'){
									return(
										<TableHeaderColumn dataField={col} width='150px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} dataFormat={this.dynamicTableData.bind(this)}>Geo Location</TableHeaderColumn>
									)
								} else if(col === 'Website'){
									return (
										<TableHeaderColumn dataField={col} width='150px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} dataFormat={this.openTableUrl.bind(this)}>{col.charAt(0).toUpperCase() + col.slice(1)}</TableHeaderColumn>
									)
								} else {
									return (
										<TableHeaderColumn dataField={col} width='150px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} dataFormat={this.dynamicTableData.bind(this)}>{col}</TableHeaderColumn>
									)
								}
							})}
						</BootstrapTable>
					</div>
					<div className={this.state.tableWidthHalf === true ? "sideview-form" : "sideview-form hide-form"}>
						<Row className="single-response-navbar">
							<button className="btn le-btn3 left-close" onClick={this.closeSideView}>Close</button>
							<button className="btn le-btn right-prev" onClick={this.switchResponse.bind(this, 'prev')}>Prev</button>
							<button className="btn le-btn right-next" onClick={this.switchResponse.bind(this, 'next')}>Next</button>
						</Row>
						<Row className="single-response-mininav">
							<Col md={6} className="mininav-entry">Entry # :{this.state.selectedForm['serial_number']}</Col>
							<Col md={6} className="mininav-date">Submitted on :{this.state.selectedForm['Submitted_on']}</Col>
						</Row>
						<Row className="response-details">
							{Object.keys(this.state.selectedForm).length > 0 ? (
								Object.keys(this.state.selectedForm).map((index)=>{
									if(index === 'serial_number' || index === 'Submitted_on' || index === '_id') {
										if(index === '_id') {
											return (
												<Col md={12}>
													<label>{'Response Id : '}</label>
													<div>{this.state.selectedForm[index]}</div>
												</Col>
											)
										} else return null;
									} else {
										if(Array.isArray(this.state.selectedForm[index].response)){
											return(
												<Col md={12} className="label-bold" key={index}>
													<label>{index.charAt(0).toUpperCase() + index.slice(1) + ' : '}</label>
													<div className="label-small">
														{this.state.selectedForm[index].response.map((i)=>{
															if(this.state.selectedForm[index].response.indexOf(i) !== 0){
																return ", " + i
															} else {
																return i
															}
														})}
													</div>
												</Col>
											)
										} else if(index === 'Website' || index === 'file'){
											return (
												<Col md={12} className="label-bold">
													<label>{index.charAt(0).toUpperCase() + index.slice(1) + ' : '}</label>
													<a className="label-small" href={this.state.selectedForm[index].response} target="_blank" >{this.state.selectedForm[index].response}</a>
												</Col>
											)
										} else if(index === 'responded_by'){
											if(this.state.selectedForm[index].hasOwnProperty('name') && this.state.selectedForm[index].name !== ''){
												return(
													<Col md={12} className="label-bold">
														<label>{'Responded by : '}</label>
														<div className="label-small">{this.state.selectedForm[index].name}</div>
													</Col>
												)
											} else if(this.state.selectedForm[index].hasOwnProperty('email') && this.state.selectedForm[index].email !== ''){
												return(
													<Col md={12} className="label-bold">
														<label>{'Responded by : '}</label>
														<div className="label-small">{this.state.selectedForm[index].email}</div>
													</Col>
												)
											} else {
												return null;
											}
										} else {
											if(this.state.selectedForm[index].response && typeof this.state.selectedForm[index].response === 'object') {
												if(this.state.selectedForm[index].response.hasOwnProperty('location_name')){
													return(
														<Col md={12} className="label-bold">
															<label>{index.charAt(0).toUpperCase() + index.slice(1) + ' : '}</label>
															<div className="label-small">{this.state.selectedForm[index].response.location_name}</div>
														</Col>
													)
												} else if(this.state.selectedForm[index].response.hasOwnProperty('latitude') && this.state.selectedForm[index].response.hasOwnProperty('longitude') && this.state.selectedForm[index].response.hasOwnProperty('url') === false){
													return(
														<Col md={12} className="label-bold">
															<label>{index.charAt(0).toUpperCase() + index.slice(1) + ' : '}</label>
															<div className="label-small">lat: {this.state.selectedForm[index].response.latitude}, lng: {this.state.selectedForm[index].response.longitude}</div>
														</Col>
													)
												} else if(this.state.selectedForm[index].response.hasOwnProperty('urls')) {
													if(this.state.selectedForm[index].response.hasOwnProperty('latitude') && this.state.selectedForm[index].response.hasOwnProperty('longitude')) {
														return (
															<Col md={12} className="label-bold">
																<label>{index.charAt(0).toUpperCase() + index.slice(1) + ' : '}</label>
																{this.state.selectedForm[index].response.urls.map(function(url, index) {
																	return (
																		<span key={index}>
																			{index > 0 ? ', ' : null}
																			<a href={url} target="_blank">{index === 0 ? 'File' : 'File ' + (index + 1)}</a>
																		</span>
																	)
																}.bind(this))}
																<br />
																<span>{'(' + this.state.selectedForm[index].response.latitude + ',' + this.state.selectedForm[index].response.longitude + ')'}</span>
															</Col>
														)
													} else {
														return (
															<Col md={12} className="label-bold">
																<label>{index.charAt(0).toUpperCase() + index.slice(1) + ' : '}</label>
																{this.state.selectedForm[index].response.urls.map(function(url, index) {
																	return (
																		<span key={index}>
																			{index > 0 ? ', ' : null}
																			<a href={url} target="_blank">{index === 0 ? 'File' : 'File ' + (index + 1)}</a>
																		</span>
																	)
																}.bind(this))}
															</Col>
														)
													}
												} else if(this.state.selectedForm[index].response.hasOwnProperty('url')) {
													if(this.state.selectedForm[index].response.hasOwnProperty('latitude') && this.state.selectedForm[index].response.hasOwnProperty('longitude')) {
														return (
															<Col md={12} className="label-bold">
																<label>{index.charAt(0).toUpperCase() + index.slice(1) + ' : '}</label>
																<a href={this.state.selectedForm[index].response.url} target="_blank" style={{'marginRight': '5px', 'marginLeft': '5px', 'marginTop': '2px' }}>File</a>
																<span>{'(' + this.state.selectedForm[index].response.latitude + ',' + this.state.selectedForm[index].response.longitude + ')'}</span>
															</Col>
														)
													} else {
														return (
															<Col md={12} className="label-bold">
																<label>{index.charAt(0).toUpperCase() + index.slice(1) + ' : '}</label>
																<a href={this.state.selectedForm[index].response.url} target="_blank">File</a>
															</Col>
														)
													}
												} else if(this.state.selectedForm[index].response.hasOwnProperty('distance')) {
													return (
														<Col md={12} className="label-bold">
															<label>{index.charAt(0).toUpperCase() + index.slice(1) + ' : '}</label>
															<span>{this.state.selectedForm[index].response.distance}</span>
														</Col>
													)
												} else if(this.state.selectedForm[index].response.hasOwnProperty('formatted_address')){
													if(this.state.selectedForm[index].response.hasOwnProperty('formatted_address') && this.state.selectedForm[index].response.hasOwnProperty('marked_formatted_address')) {
														return (
															<div>
																{this.state.selectedForm[index].response.formatted_address ? (
																	<Col md={12} className="label-bold">
																		<label>{'Actual Location : '}</label>
																		<a target="_blank" href={'https://www.google.com/maps/place/?q=place_id:' + this.state.selectedForm[index].response.placeId}> {this.state.selectedForm[index].response.formatted_address}</a>
																	</Col>
																) : (null)}
																{this.state.selectedForm[index].response.marked_formatted_address ? (
																	<Col md={12} className="label-bold">
																		<label>{'Marked Location : '}</label>
																		<a target="_blank" href={'https://www.google.com/maps/place/?q=place_id:' + this.state.selectedForm[index].response.marked_placeId}> {this.state.selectedForm[index].response.marked_formatted_address}</a>
																	</Col>
																) : (null)}
															</div>
														)
													} else if(this.state.selectedForm[index].response.formatted_address) {
														return(
															<Col md={12} className="label-bold">
																<label>{index.charAt(0).toUpperCase() + index.slice(1) + ' : '}</label>
																<a target="_blank" href={'https://www.google.com/maps/place/?q=place_id:' + this.state.selectedForm[index].response.placeId}> {this.state.selectedForm[index].response.formatted_address}</a>
															</Col>
														)
													} else {
														return(
															<Col md={12} className="label-bold">
																<label>{'Latitude-Longitude : '}</label>
																<a target="_blank" href={`https://maps.google.com/?q=${this.state.selectedForm[index].response.latlng.latitude},${this.state.selectedForm[index].response.latlng.longitude}`}> {this.state.selectedForm[index].response.latlng.latitude + ', ' + this.state.selectedForm[index].response.latlng.longitude}</a>
															</Col>
														)
													}
												} else {
													return(
														<Col md={12} className="label-bold">
															<label>{index.charAt(0).toUpperCase() + index.slice(1) + ' : '}</label>
															<div className="label-small">{this.state.selectedForm[index].response}</div>
														</Col>
													)
												}
											} else {
												return(
													<Col md={12} className="label-bold">
														<label>{index.charAt(0).toUpperCase() + index.slice(1) + ' : '}</label>
														<div className="label-small">{this.state.selectedForm[index].response}</div>
													</Col>
												)
											}
										}
									}
								})
							) : (null)}
						</Row>
						<Row className="single-response-footer">
							<button className="btn le-btn left-dnld" onClick={this.delResponse.bind(this, 'single')}>Delete</button>
							<button className="btn le-btn right-edt" onClick={()=>{window.open('/fb/update/form/details/'+ this.props.ngo.ngo_id + '/' + this.props.formId + '/' + this.state.selectedForm['_id'])}}>Edit</button>
						</Row>
					</div>
				</div>
			</div>
		)
	}
}
