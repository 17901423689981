import React, {Component} from 'react';
import {Row, Col, Modal} from 'react-bootstrap';

const readCookie = require('../cookie.js').readCookie;

export default class BudgetEditorModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			costHeads: [],
			selectedColumnValue: this.props.selectedColumnValue
		}
	}

	componentDidMount() {
		if(this.props.selectedColumn === 'costHead') {
			fetch(process.env.REACT_APP_MIS_API_URL + '/cost-attributes', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				}
			}).then((data) => data.json())
			.then(function(data) {
				let costHeads = [];
				for(var i = 0; i < data.data.length; i++) {
					for(var j = 0; j < data.data[i].costHeads.length; j++) {
						let costHead = {};
						costHead.id = data.data[i].costHeads[j].key;
						costHead.name = data.data[i].costHeads[j].name;
						costHeads.push(costHead);
					}
				}
				this.setState({ costHeads });
			}.bind(this));
		}
	}

	updateChange(e) {
		var selectedColumnValue = e.target.value;
		this.setState({ selectedColumnValue });
	}

	saveSelectedColumnValue() {
		this.props.saveBudgetEditorValue(this.props.selectedColumn, this.state.selectedColumnValue, this.props.selectedRowIndex);
	}

	render() {
		return (
			<Modal className="budget-editor-modal" show={this.props.budgetEditorModalVisibility}
				onHide={this.props.hideBudgetEditorModal.bind(this)} bsSize="large">
				<Modal.Header>
					<Modal.Title className="modal-header-custom">
						{'Edit ' + this.props.selectedColumnName}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<label className="control-label is-imp">{this.props.selectedColumnName + ':'}</label>
						{(() => {
							if(this.props.selectedColumn === 'costHead') {
								return (
									<select className="form-control" value={this.state.selectedColumnValue} onChange={this.updateChange.bind(this)}>
										<option disabled value=''>Select Expense Head</option>
										{this.state.costHeads.map(function(costHead, index) {
											return (
												<option value={costHead.id} key={index}>{costHead.name}</option>
											)
										})}
									</select>
								)
							} else if(this.props.selectedColumn === 'expenseItem') {
								return (
									<input className="form-control" type="text" value={this.state.selectedColumnValue} onChange={this.updateChange.bind(this)} maxLength="100" />
								)
							} else if(this.props.selectedColumn === 'comments') {
								return (
									<textarea className="form-control" value={this.state.selectedColumnValue} onChange={this.updateChange.bind(this)} maxLength="200"></textarea>
								)
							}
						})()}

						<Col md={12} className="budget-editor-buttons-container mt20 mb10">
							<button className="btn budget-editor-save-button" onClick={this.saveSelectedColumnValue.bind(this)}>Save</button>
							<button className="btn budget-editor-cancel-button" onClick={this.props.hideBudgetEditorModal}>Cancel</button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		)
	}
}