import React, { Component } from "react";
import { Modal, Row } from "react-bootstrap";

import EditTask from "./EditTask";

const readCookie = require('../cookie.js').readCookie;

export default class EditTaskModal extends Component {
	render() {
		return (
      <div className="page-container edit-task-page">
        <h2 className="modal-header-custom">
          Edit Task
        </h2>
        <Row>
          <EditTask id={this.props.id} onEditClose={this.props.onEditClose} ngo={this.props.ngo} userEmail={this.props.userData.email} />
        </Row>
      </div>
		);
	}
}
