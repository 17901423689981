import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import swal from 'sweetalert2';
import Alert from 'react-s-alert';
import enUS from 'antd/lib/locale-provider/en_US';
import { DatePicker, LocaleProvider  } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import { Col, Row, Tab, Tabs, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';

moment.locale('en');

const dateFormat = 'YYYY/MM/DD';
const readCookie = require('../cookie.js').readCookie;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

export default class ImpactTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      impactList: [],
      columns: 0,
      meta_length: 0,
      meta_data: [],
      keyIndicator: null,
      kpiData: null,
      sortedAssessmentData: null,
      dateArray: [],
      sortByDate: [],
      assessmentdata: [],
      allowReporting: false,
      showReportingForm: false,
      reportingFormDate:""
    }
  }

    componentDidMount() {
      const uri = process.env.REACT_APP_MIS_API_URL + "/project/key-assesment/" + this.props.project_id;
      fetch(uri, {
        method: 'GET',
        headers: {
          'Auth': JSON.parse(readCookie('mis_data')).access_token
        }
      })
      .then(res => (res.json()))
      .then(res => {
        let assessmentdata = []
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < res.data[i].assessments.length; j++) {
            if (!assessmentdata.push[res.data[i].assessments[j].date]) {
              assessmentdata.push(res.data[i].assessments[j])
              assessmentdata.push[res.data[i].assessments[j].date] = true
            }
          }
        } assessmentdata = _.sortBy(assessmentdata, function (node) {
          return (new Date(node.internalDate).getTime());
        });
        this.setState({ kpiData: res.data, assessmentdata, allowReporting: res.allowReporting });
      })
      .catch(er => {
        console.log("err", er)
      })
      let list = Object.assign(this.state.impactList, this.props.getImpactList());
      this.setState({ impactList: list })
      let length = 0;
      let length_array = [];
      _.map(this.state.impactList, (el, index) => {
        if (el.data_linked.length > length) {
          length = el.data_linked.length;
          length_array[0] = length;
          length_array[1] = index;
        }
      })
      let meta_data = list[length_array[1]].data_linked;
      let new_list = Object.assign(this.state.meta_data, meta_data);
      this.setState({ meta_data: new_list, meta_length: length_array[0] - 1 });
    }

    setplotChart(data, event) {
      let graph_arr = [];
      _.map(data, (graph, index) => {
          //console.log("graph", graph);
        graph_arr.push({ date: graph.date, count: Number(graph.value[0].unit) })
      })
      //console.log("setplotChart", graph_arr);
      Alert.info('Graph plotted', {
        position: 'top-right',
        timeout: 500
      })
      this.props.plotGraph(graph_arr);
    }

    addAccessmentForm(cell, string, row) {
      let kpi_array = _.find(this.state.kpiData, (el, i) => {
        if (el.id === cell) {
          return el.name
        }
      });
      // let assessmentdata = _.find(this.state.assessmentdata, (el, i) => { el.value.kpi === row, console.log(el); });
      this.props.addAccessment(kpi_array, string);
    }

    showTableData(row) {
      let accessment_array = _.filter(this.state.keyIndicator, (el, i) => (row === el.value[0].kpi))
      if (accessment_array.length > 0) {
        this.props.onCloseModal(accessment_array);
      }
      else {
        Alert.info("There is no Accessment Data to show. Please Add one");
      }
    }

    showGraphTable(cell, row) {
      let graph_data = cell.map((el, i) => {
        return (el)
      })
      if (graph_data.length > 0) {
        this.props.graphModal(graph_data);
      }
      else {
        Alert.info("There is no Accessment Data to show. Please Add one");
      }
    }

    addNewButton = (row) => {
      if (this.state.allowReporting === true) {
        return (
          <button className="btn btn-info" onClick={this.addAccessmentForm.bind(this, row, "kpi")}>Add New</button>
        )
      } else {
        return (
          <button className="btn btn-info" title="You can add assesment only when it is funded!" disabled>Add New</button>
        )
      }
    }

    addViewButton = (row) => {
      return (
        <button className="btn btn-info" onClick={this.showTableData.bind(this, row)}>View</button>
      )
    }

    addPlotGraphButton = (row) => {
      if (this.state.assessmentdata.length) {
        return (
          <button className="btn btn-info" onClick={this.showGraphTable.bind(this, row)}>Plot Graph</button>
        )
      } else {
        return (
          <button className="btn btn-info" title='There is no data to plot' disabled>Plot Graph</button>
        )
      }
    }

    deleteButton = (row) => {
      return (
        <button className="btn btn-default btn-danger" onClick={this.deleteKPI.bind(this, row)}>Delete</button>
      )
    }

    deleteKPI(kpi_id, data) {
      const uri = process.env.REACT_APP_MIS_API_URL + "/project/" + this.props.project_id + "/kpi/" + kpi_id;
      swal({
        title: 'Do you want to delete this KPI?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then((result) => {
        fetch(uri, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Auth': JSON.parse(readCookie('mis_data')).access_token
          },
        })
        .then(res => (res.json()))
        .then(res => {
          if (res.status === "ok") {
            let active_kpi = _.filter(this.state.kpiData, (el, i) => (el._id !== kpi_id));
            this.setState({
              kpiData: active_kpi
            })
            this.notifySuccess('This assessment has been deleted successfully.')
            window.location.pathname = '/im/projects/' + this.props.project_id + '/impact';
          }
        })
        .catch(err => {
          console.log("err", err)
        })
      });
    }

    showAssessmentData(date, assessments) {
      for (var i = 0; i < assessments.length; i++) {
        if (assessments[i].hasOwnProperty('date') && assessments[i].date === date) return assessments[i].value;
      }
      return "-";
    }

    notifySuccess = (text) => toast.success(text);

    hideReportingForm = () => {
      this.setState({showReportingForm: false, reportingFormDate: ""});
    }

    reportingFormDate = (date, dateString) => {
      this.setState({reportingFormDate: dateString})
    }

    render() {
      return (
        <div>
          <Alert stack={{ limit: 3 }} />
          <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
          <div style={{display:"flex", alignnItems:"center", marginBottom: "15px", justifyContent: "space-between"}}>
            <div style={{display: "flex"}}>
              <div style={{marginRight: "15px", display: "flex", alignItems: "center"}}>
                <h4 style={{margin: "0px", fontWeight: "bold"}}>Frequency:</h4>
                <p style={{margin: "0 0 0 10px"}}>Weekly</p>
              </div>
              <div style={{marginRight: "15px", display: "flex", alignItems: "center"}}>
                <h4 style={{margin: "0px", fontWeight: "bold"}}>Assessment:</h4>
                <p style={{margin: "0 0 0 10px"}}>Cumulative</p>
              </div>
            </div>
            {/* <button
              className='impact-start-reporting-button'
              style={{fontWeight: "normal", letterSpacing: "1px", color: "#fff", border: "#ef5a20", background: "#ef5a20", padding: "5px 10px", borderRadius: "4px"}}
              onClick={() => {this.setState({showReportingForm: true})}}>
              Start Reporting
             </button> */}
          </div>
          <Modal className="mis-content-container invitations-modal" style={{width:"70%", margin: "0 auto"}} show={this.state.showReportingForm} onHide={this.hideReportingForm} bsSize="large">
            <Modal.Header>
              <h3 style={{textAlign:"center", margin: "0px"}}>Start Reporting</h3>
            </Modal.Header>
            <Modal.Body className='invitations-modal-main-container'>
              <div style={{width:"100%", display:"flex", margin:"20px 0"}}>
                <LocaleProvider locale={enUS}>
                  <DatePicker locale={enUS} style={{ width: "40%", margin: "0 auto" }} format="DD/MM/YYYY" type="date" onChange={this.reportingFormDate} />
                </LocaleProvider>
              </div>
              {this.state.reportingFormDate !== "" ? (
                <p style={{margin: "20px auto 0", textAlign:"center", width:"60%" }}>You will have to report <b>Weekly</b> starting from this date and would not be able to change this date in future. Are you sure you want to start reporting from <b>{this.state.reportingFormDate}</b> .</p>
              ):(null)}
            </Modal.Body>
            <Modal.Footer>
              <Row>
                <Col md={12} style={{display:"flex", justifyContent:"center"}} className="solicit-buttons-container">
                  <button className={this.state.isLoading === true ? "btn solicit-modal-btn-loading" : "btn solicit-modal-btn"} onClick={this.handleSubmit}>{this.state.isLoading === true ? <img src="/img/Spinner-white.svg" style={{width: "27px"}} alt="loading"/> : "Yes, I confirm"}</button>
                  <button className="btn solicit-cancel-button" onClick={this.hideReportingForm}>No, Later</button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
          {this.props.visibility ?
            (<div style={{ overflowX: 'scroll', overflowY: 'scroll' }}>
              <BootstrapTable data={this.state.kpiData} striped={false} ref='table' hover={true}>
                <TableHeaderColumn dataField="id" isKey={true} hidden={true} export={false} searchable={false}>Id</TableHeaderColumn>
                <TableHeaderColumn dataField="name" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>KEY INDICATORS</TableHeaderColumn>
                <TableHeaderColumn dataField="type" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>TYPE</TableHeaderColumn>
                <TableHeaderColumn dataField="baselineValue" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>BASELINE VALUES</TableHeaderColumn>
                <TableHeaderColumn dataField="targetValue" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>TARGET VALUES</TableHeaderColumn>
                {/* <TableHeaderColumn dataField="_id"   width='140px' headerAlign='center' dataAlign='center' dataSort={false}   hidden={false}  export={true} searchable={true} dataFormat={this.addNewButton}>ADD NEW ASSESSMENT</TableHeaderColumn>*/}
                {_.map(this.state.assessmentdata, (el, i) => (
                  <TableHeaderColumn key={i} dataField="assessments" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.showAssessmentData.bind(this, el.date)}>{el.date}</TableHeaderColumn>)
                )}
                <TableHeaderColumn dataField="id" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.addNewButton.bind(this)}>ADD NEW ASSESSMENT</TableHeaderColumn>
                <TableHeaderColumn dataField="assessments" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.addPlotGraphButton}>PLOT GRAPH</TableHeaderColumn>
                <TableHeaderColumn dataField="id" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.deleteButton}>DELETE KPI</TableHeaderColumn>
              </BootstrapTable>
            </div>
            ) : (null)}
        </div>
      )
    }
}