import React, { Component } from "react";
import _ from 'lodash';

export default class CommentsModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            comments: this.props.comments
        }
    }
    render(){
        return(
            <div className="comment_modal">
                <div className="comment">
                    {this.state.comments[0]}
                </div>
                <div className="comment_by">
                   -- by {this.props.comment_by} on  {this.props.comment_on}
                </div>
                <div>
                <button style={{
                    right: '50px',
                    color: 'white',
                    bottom: '30px',
                    border: 'none',
                    fontSize: '14px',
                    borderRadius: '5px',
                    padding: '6px 12px',
                    position: 'absolute',
                    background: '#ef5a20'
                    }} onClick={this.props.onClose}>
                    Okay
                </button>
                </div>
            </div>
        )
    }
}