import React, { Component } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import moment from 'moment';
import { LocaleProvider, DatePicker } from 'antd';
import FileViewer from 'react-file-viewer';
import swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import enUS from 'antd/lib/locale-provider/en_US';

import 'antd/dist/antd.css';
import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../cookie.js').readCookie;

export default class ReportExpenseModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: '',
			bills: [],
			fileType: null,
			reportAmount: 0,
			expenseHead: '',
			expenseItem: '',
			haveBills: true,
			budgeted: false,
			expenseHeads: [],
			expenseItems: [],
			proposedAmount: 0,
			selectedBill: null,
			filePreviewUrl: null,
			percentageUtilized: 0,
			unreportedExpenses: [],
			expenseStatus: 'Partial',
			submittedby: "",
			createdAt: '',
			billAmount: 0,
			addedBy: ""
		}
	}

	componentWillMount() {
		if(this.props.expenseData !== null) {
			let bills = this.props.expenseData.bills;
			if(bills && bills.length) {
				this.setState({
					createdAt: moment(bills[bills.length - 1].createdAt).format('DD/MM/YYYY'),
					addedBy: bills[bills.length - 1].addedBy,
					submittedby: bills[bills.length - 1].submittedby
				});
			}
		}
		if(this.props.type === 'reportBudgeted') {
			fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.project_id + '/unreported-expenses', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					this.setState({ expenseHeads: data.expenseHeads, unreportedExpenses: data.expenseItems });
				}.bind(this));
		} else if (this.props.type === 'reportUnbudgeted') {
			fetch(process.env.REACT_APP_MIS_API_URL + '/cost-attributes', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					let expenseHeads = [];
					for (var i = 0; i < data.data.length; i++) {
						let type = data.data[i].name;
						for (var j = 0; j < data.data[i].costHeads.length; j++) {
							let expenseHead = {};
							expenseHead['name'] = data.data[i].costHeads[j].name;
							expenseHead['key'] = data.data[i].costHeads[j].key;
							expenseHead['type'] = type;
							expenseHeads.push(expenseHead);
						}
					}
					this.setState({ expenseHeads });
				}.bind(this));
		} else if (this.props.type === 'edit' || this.props.type === 'view') {
			if (this.props.editExpense && this.props.expenseData !== null) {
				let expenseData = this.props.expenseData;
				this.setState({
					expenseHead: expenseData.costHead.name,
					expenseItem: expenseData.expenseItem,
					type: expenseData.costHead.type,
					proposedAmount: expenseData.proposedAmount,
					reportAmount: expenseData.reportedAmount,
					expenseStatus: expenseData.expenseStatus,
					haveBills: expenseData.haveBills || false,
					bills: expenseData.bills
				});
			}
		}
	}

	onChange(name, e) {
		if (name === 'expenseHead') {
			let type = '', expenseItems = [];
			for (var i = 0; i < this.state.expenseHeads.length; i++) {
				if (this.state.expenseHeads[i].key === e.target.value) type = this.state.expenseHeads[i].type;
			}
			if (this.props.type === 'reportBudgeted') {
				for (var i = 0; i < this.state.unreportedExpenses.length; i++) {
					if (this.state.unreportedExpenses[i].costHead.key === e.target.value) {
						var expenseItem = {};
						expenseItem.id = this.state.unreportedExpenses[i].id;
						expenseItem.name = this.state.unreportedExpenses[i].expenseItem;
						expenseItems.push(expenseItem);
					}
				}
				this.setState({ type, expenseItems });
			} else if (this.props.type === 'reportUnbudgeted') {
				this.setState({ type });
			}
		} else if (name === 'expenseItem' && this.props.type !== 'reportUnbudgeted') {
			let proposedAmount = 0;
			for (var i = 0; i < this.state.unreportedExpenses.length; i++) {
				if (this.state.unreportedExpenses[i].id === e.target.value) {
					proposedAmount = this.state.unreportedExpenses[i].amount;
					this.setState({ proposedAmount });
				}
			}
		}
		if (name === 'haveBills') {
			this.setState({ haveBills: !this.state.haveBills });
		} else {
			this.setState({ [name]: e.target.value });
		}
	}

	createNewBill() {
		let bills = this.state.bills;
		for (var i = 0; i < bills.length; i++) {
			bills[i].selected = false;
		}
		let bill = {};
		bill.title = 'Bill ' + (bills.length + 1);
		bill.date = moment();
		bill.file = null;
		bill.description = '';
		bill.selected = true;
		bills.push(bill);
		this.setState({ selectedBill: null }, function () {
			this.setState({ bills, selectedBill: bill });
		});
	}

	onChangeSelectedBill(name, e) {
		let selectedBill = this.state.selectedBill;
		if (name === 'title') selectedBill[name] = e.target.value;
		else if (name === 'description') selectedBill[name] = e.target.value;
		else if (name === 'amount') selectedBill[name] = e.target.value;
		else if (name === 'date') selectedBill[name] = moment(e).format("DD/MM/YYYY");
		else if (name === 'file') {
			let file = e.target.files[0];
			this.setState({ filePreviewUrl: null, fileType: null }, function () {
				let type = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
				selectedBill[name] = file;
				var that = this;
				var reader = new FileReader();
				reader.onloadend = function () {
					that.setState({ filePreviewUrl: reader.result });
				}
				reader.readAsDataURL(file);
				this.setState({ fileType: type });
				delete selectedBill['billUrl'];
			}.bind(this));
		}
		else selectedBill[name] = e;
		this.setState({ selectedBill });
	}

	changeSelectedBill(index) {
		let bills = this.state.bills;
		for (var i = 0; i < bills.length; i++) {
			bills[i].selected = false;
		}
		bills[index].selected = true;
		if (bills[index].hasOwnProperty('billUrl')) {
			let file = bills[index].billUrl;
			let type = file.slice((file.lastIndexOf(".") - 1 >>> 0) + 2);
			this.setState({ fileType: type });
		} else if (bills[index].hasOwnProperty('file') && bills[index].file !== null) {
			let file = bills[index].file;
			let type = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
			this.setState({ fileType: type });
		}
		this.setState({ selectedBill: null }, function () {
			this.setState({ bills, selectedBill: bills[index] });
		});
	}

	removeBill(index) {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, Delete!',
			cancelButtonText: 'No, Cancel!',
			confirmButtonClass: 'btn',
			cancelButtonClass: 'btn',
		}).then(function (result) {
			if (result) {
				let bills = this.state.bills;
				bills.splice(index, 1);
				this.setState({ bills, selectedBill: null });
			}
		}.bind(this)).catch(function () {
		});
	}

	reportExpense() {
		if (this.props.type === 'reportBudgeted') {
			let expenseHead = this.state.expenseHead;
			let expenseId = this.state.expenseItem;
			let amount = this.state.reportAmount || 0;
			let expenseStatus = this.state.expenseStatus;
			let billsArray = this.state.bills;
			let haveBills = this.state.haveBills;

			if (expenseHead !== '' && expenseId !== '' && amount !== 0) {
				let data = new FormData();
				data.append('expenseHead', expenseHead);
				data.append('expenseId', expenseId);
				data.append('amount', amount);
				data.append('expenseStatus', expenseStatus);
				data.append('haveBills', haveBills);

				let bills = [];
				for (var i = 0; i < billsArray.length; i++) {
					if (billsArray[i].title !== '' && billsArray.file !== null && billsArray[i].amount !== '') {
						let bill = {};
						bill['title'] = billsArray[i].title;
						bill['date'] = moment(billsArray[i].date, 'DD/MM/YYYY').format('DD/MM/YYYY');
						bill['description'] = billsArray[i].description;
						bill['amount'] = billsArray[i].amount;
						bills.push(bill);
						data.append('file[' + i + ']', billsArray[i].file);
					}
				}
				if (haveBills) data.append('bills', JSON.stringify(bills));

				fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.project_id + '/report-expenses', {
					method: 'POST',
					headers: {
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					},
					body: data
				}).then((response) => response.json())
					.then(function (data) {
						if (data['status'] === 'ok') {
							this.notifySuccess('This Expense has been reported successfully.');
							let that = this;
							setTimeout(function () {
								that.props.hideReportExpenseModal('refresh');
							}, 2000);
						}
					}.bind(this));
			} else {
				this.notifyError("This Expense haven't been reported. Please fill the required fields.");
			}
		} else if (this.props.type === 'reportUnbudgeted') {
			let expenseHead = this.state.expenseHead;
			let expenseItem = this.state.expenseItem;
			let amount = this.state.reportAmount;
			let expenseStatus = this.state.expenseStatus;
			let billsArray = this.state.bills;
			let haveBills = this.state.haveBills;

			if (expenseHead !== '' && expenseItem !== '' && amount !== 0) {
				let data = new FormData();
				data.append('expenseHead', expenseHead);
				data.append('expenseItem', expenseItem);
				data.append('amount', amount);
				data.append('expenseStatus', expenseStatus);
				data.append('haveBills', haveBills);

				let bills = [];
				for (var i = 0; i < billsArray.length; i++) {
					if (billsArray[i].title !== '' && billsArray.file !== null && billsArray[i].amount !== '') {
						let bill = {};
						bill['title'] = billsArray[i].title;
						bill['date'] = moment(billsArray[i].date, 'DD/MM/YYYY').format('DD/MM/YYYY');
						bill['description'] = billsArray[i].description;
						bill['amount'] = billsArray[i].amount;
						bills.push(bill);
						data.append('file[' + i + ']', billsArray[i].file);
					}
				}
				if (haveBills) data.append('bills', JSON.stringify(bills));

				fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.project_id + '/report-expenses', {
					method: 'POST',
					headers: {
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					},
					body: data
				}).then((response) => response.json())
					.then(function (data) {
						if (data['status'] === 'ok') {
							this.notifySuccess('This Expense has been reported successfully.');
							let that = this;
							setTimeout(function () {
								that.props.hideReportExpenseModal('refresh');
							}, 2000);
						}
					}.bind(this));
			} else {
				this.notifyError("This Expense haven't been reported. Please fill the required fields.");
			}
		} else if (this.props.type === 'edit') {
			let amount = this.state.reportAmount;
			let expenseStatus;
			if(this.state.expenseStatus === 'N/A') expenseStatus = 'Partial';
			else expenseStatus = this.state.expenseStatus;
			let billsArray = this.state.bills;
			let haveBills = this.state.haveBills;

			if (this.props.expenseData.id && amount !== 0) {
				let data = new FormData();
				data.append('amount', amount);
				data.append('expenseStatus', expenseStatus);
				data.append('haveBills', haveBills);

				let bills = [];
				for (var i = 0; i < billsArray.length; i++) {
					if (billsArray[i].title !== '' && billsArray[i].amount !== '' && ((billsArray[i].hasOwnProperty('file') && billsArray[i].file !== null) || (billsArray[i].hasOwnProperty('billUrl') && billsArray[i].billUrl !== ''))) {
						let bill = {};
						bill['title'] = billsArray[i].title;
						bill['amount'] = billsArray[i].amount;
						bill['date'] = moment(billsArray[i].date, 'DD/MM/YYYY').format('DD/MM/YYYY');
						bill['description'] = billsArray[i].description;
						if (billsArray[i].addedBy) {
							bill['addedBy'] = billsArray[i].addedBy;
						}
						if (billsArray[i].createdAt) {
							bill['createdAt'] = billsArray[i].createdAt;
						}
						if (billsArray[i].hasOwnProperty('file')) data.append('file[' + i + ']', billsArray[i].file);
						else if (billsArray[i].hasOwnProperty('billUrl')) bill['billUrl'] = billsArray[i].billUrl;
						bills.push(bill);
					}
				}
				if (haveBills) data.append('bills', JSON.stringify(bills));

				fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.project_id + '/reported-expenses/' + this.props.expenseData.id, {
					method: 'PUT',
					headers: {
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					},
					body: data
				}).then((response) => response.json())
				.then(function (data) {
					if (data['status'] === 'ok') {
						this.notifySuccess('This Expense has been reported successfully.');
						let that = this;
						setTimeout(function () {
							that.props.hideReportExpenseModal('refresh');
						}, 2000);
					}
				}.bind(this));
			} else {
				this.notifyError("This Expense haven't been reported. Please fill the required fields.");
			}
		}
	}



	notifySuccess = (text) => toast.success(text);

	notifyError = (text) => toast.error(text);

	render() {
		return (
			<div className="im-project-container im-project-report-expense-container">
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<Modal.Header>
					<Modal.Title className="modal-header-custom">
						{(() => {
							if (this.props.type === 'reportBudgeted') {
								return 'Report Expense'
							} else if (this.props.type === 'reportUnbudgeted') {
								return 'Add Unbudgeted Expense'
							} else if (this.props.type === 'edit') {
								return 'Edit Expense'
							} else if (this.props.type === 'view') {
								return 'Expense Details'
							}
						})()}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="padding0">
					<Row>
						<Col md={12} className="mt20 mb10 padding0">
							{(() => {
								if (this.props.type === 'edit' && this.state.expenseHead !== '') {
									return (
										<Col className="form-group" md={4}>
											<label className="control-label is-imp">Expense Head:</label>
											<input className="form-control" type="text" placeholder="Expense Head" disabled value={this.state.expenseHead} />
										</Col>
									)
								} else if (this.props.type === 'view' && this.state.expenseHead !== '') {
									return (
										<Col className="form-group" md={4}>
											<label className="control-label is-imp">Expense Head:</label>
											<input className="form-control" type="text" placeholder="Expense Head" disabled value={this.state.expenseHead} />
										</Col>
									)
								} else {
									return (
										<Col className="form-group" md={4}>
											<label className="control-label is-imp">Select Expense Head:</label>
											<select className="form-control" required value={this.state.expenseHead} onChange={this.onChange.bind(this, 'expenseHead')}>
												<option value='' disabled>Select Expense Head</option>
												{this.state.expenseHeads.map(function (expenseHead, index) {
													return (
														<option key={index} value={expenseHead.key}>{expenseHead.name}</option>
													)
												})}
											</select>
										</Col>
									)
								}
							})()}

							{(() => {
								if (this.props.type === 'reportBudgeted') {
									return (
										<Col className="form-group" md={4}>
											<label className="control-label is-imp">Select Expense Item:</label>
											<select className="form-control" required value={this.state.expenseItem} onChange={this.onChange.bind(this, 'expenseItem')}>
												<option value='' disabled>Select Expense Item</option>
												{this.state.expenseItems.map(function (expenseItem, index) {
													return (
														<option key={index} value={expenseItem.id}>{expenseItem.name}</option>
													)
												})}
											</select>
										</Col>
									)
								} else if (this.props.type === 'reportUnbudgeted') {
									return (
										<Col className="form-group" md={4}>
											<label className="control-label is-imp">Enter Expense Item:</label>
											<input className="form-control" type="text" placeholder="Expense Item" value={this.state.expenseItem} onChange={this.onChange.bind(this, 'expenseItem')} />
										</Col>
									)
								} else if (this.props.type === 'edit' && this.state.expenseItem !== '') {
									return (
										<Col className="form-group" md={4}>
											<label className="control-label is-imp">Expense Item:</label>
											<input className="form-control" type="text" placeholder="Expense Item" disabled value={this.state.expenseItem} />
										</Col>
									)
								} else if (this.props.type === 'view' && this.state.expenseItem !== '') {
									return (
										<Col className="form-group" md={4}>
											<label className="control-label is-imp">Expense Item:</label>
											<input className="form-control" type="text" placeholder="Expense Item" disabled value={this.state.expenseItem} />
										</Col>
									)
								}
							})()}

							<Col className="form-group" md={4}>
								<label className="control-label is-imp">Type:</label>
								<input className="form-control" type="text" placeholder="Type" value={this.state.type} disabled />
							</Col>

							<Col className="form-group" md={4}>
								<label className="control-label is-imp">Proposed Amount:</label>
								<input className="form-control" type="number" placeholder="Proposed Amount" value={this.state.proposedAmount} disabled />
							</Col>

							{this.props.type === 'view' ? (
								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Report Amount:</label>
									<input className="form-control" type="number" placeholder="Report Amount" value={this.state.reportAmount} disabled />
								</Col>
							) : (
									<Col className="form-group" md={4}>
										<label className="control-label is-imp">Report Amount:</label>
										<input className="form-control" type="number" placeholder="Report Amount" value={this.state.reportAmount} onChange={this.onChange.bind(this, 'reportAmount')} />
									</Col>
								)}

							{this.props.type === 'view' ? (
								<Col className="form-group" md={4}>
									<label className="control-label is-imp">Expense Status:</label>
									<input className="form-control" type="text" value={this.state.expenseStatus + ' Payment'} disabled />
								</Col>
							) : (
									<Col className="form-group" md={4}>
										<label className="control-label is-imp">Expense Status:</label>
										<select className="form-control" required value={this.state.expenseStatus} onChange={this.onChange.bind(this, 'expenseStatus')}>
											<option value="Partial">Partial Payment</option>
											<option value="Complete">Complete Payment</option>
										</select>
									</Col>
								)}

							<Col className="bills-container mt20" md={12}>
								<h4>Bills</h4>
								<div className="has-bill-container">
									<label className={this.props.type === 'view' ? "label-container disabled" : "label-container"}>Have Bills for the expense?
										<input type="checkbox" checked={this.state.haveBills} disabled={this.props.type === 'view' ? true : false} onChange={this.onChange.bind(this, 'haveBills')} />
										<span className="checkmark"></span>
									</label>
								</div>

								{this.state.haveBills ? (
									<div className="add-bill-container">
										{this.state.bills.length ? (
											<div className="show-bills-container">
												<div className="bills-sidebar">
													{this.state.bills.map(function (bill, index) {
														return (
															<div className={bill.selected ? "bill-container selected" : "bill-container"} key={index} onClick={this.changeSelectedBill.bind(this, index)}>
																<span className="bill-title">{bill.title}</span>
																<span className="bill-date">{moment(bill.date, 'DD/MM/YYYY').format('DD/MM/YYYY')}</span>
																{bill.selected && this.props.type !== 'view' ? (
																	<div className="bill-delete-container" onClick={this.removeBill.bind(this, index)}>
																		<i className="fa fa-trash"></i>
																	</div>
																) : (null)}
															</div>
														)
													}.bind(this))}
												</div>
												{this.state.selectedBill !== null ? (
													<div className="selected-bill">
														<Col md={12} className="mb10">
															<Col className="form-group" md={6}>
																<label className="control-label is-imp">Bill Title:</label>
																{this.props.type === 'view' ? (
																	<input className="form-control" type="text" placeholder="Bill Title" value={this.state.selectedBill.title} disabled />
																) : (
																		<input className="form-control" type="text" placeholder="Bill Title" value={this.state.selectedBill.title} onChange={this.onChangeSelectedBill.bind(this, 'title')} />
																	)}
															</Col>



															<Col className="form-group" md={6}>
																<label className="control-label is-imp">Bill Date:</label>
																<LocaleProvider locale={enUS}>
																	{this.props.type === 'view' ? (
																		<DatePicker locale={enUS} size='large' value={moment(this.state.selectedBill.date, 'DD/MM/YYYY')} format="DD/MM/YYYY"
																			allowClear={false} disabled />
																	) : (
																			<DatePicker locale={enUS} size='large' value={moment(this.state.selectedBill.date, 'DD/MM/YYYY')} format="DD/MM/YYYY"
																				allowClear={false} onChange={this.onChangeSelectedBill.bind(this, 'date')} />
																		)}
																</LocaleProvider>
															</Col>

															<Col className="form-group" md={12} style={{ paddingLeft: "0px" }}>
																<Col className="form-group" md={6}>
																	<label className="control-label is-imp">Bill Amount:</label>
																	{this.props.type === 'view' ? (
																		<input className="form-control" type="number" placeholder="Enter Bill Amount" value={this.state.selectedBill.amount} disabled />
																	) : (
																			<input className="form-control" type="number" placeholder="Enter Bill Amount" value={this.state.selectedBill.amount} onChange={this.onChangeSelectedBill.bind(this, 'amount')} />
																		)}
																</Col>
																<Col className="form-group" md={6}>
																	<Col className="form-group" md={3} style={{ width: "50%" }}>
																		<label className="control-label is-imp">Submitted By:</label>
																		<input className="form-control" type="text" placeholder="Submitted By" style={{ border: "solid 1px #a9a9a9 !important" }} value={this.state.submittedby === "" ? this.state.submittedby : JSON.parse(readCookie('mis_data')).fullName} disabled />
																	</Col>
																	<Col className="form-group" md={3} style={{ width: "50%" }}>
																		<label className="control-label is-imp">Submitted On:</label>
																		<LocaleProvider locale={enUS}>
																			{this.props.type === 'view' && this.state.submittedby !== null ? (
																				<DatePicker locale={enUS} size='large' value={moment(this.state.createdAt, "DD/MM/YYYY")} format="DD/MM/YYYY"
																					allowClear={false} disabled />
																			) : (
																					<DatePicker locale={enUS} size='large' value={this.state.createdAt ? moment(this.state.createdAt, "DD/MM/YYYY") : moment(this.state.selectedBill.date, 'DD/MM/YYYY')} format="DD/MM/YYYY"
																						allowClear={false} style={{ border: "solid 1px #a9a9a9 !important" }} disabled />
																				)}
																		</LocaleProvider>
																	</Col>
																</Col>
															</Col>

															<Col className="form-group upload-bill-container" md={12}>
																<label className="control-label is-imp">Upload Bill:</label>
																{this.state.selectedBill.file === null && this.props.type !== 'view' ? (
																	<input type="file" onChange={this.onChangeSelectedBill.bind(this, 'file')} accept="image/*, .pdf" />
																) : (
																		<div>
																			{this.state.selectedBill.hasOwnProperty('billUrl') ? (
																				<FileViewer fileType={this.state.fileType} filePath={this.state.selectedBill.billUrl.replace('https://assets.letsendorse.com', 'https://le-uploaded-image-bucket.s3.amazonaws.com')} />
																			) : (
																					this.state.fileType === 'pdf' ? (
																						<div className="pdf-placeholder mb10"></div>
																					) : (
																							<img className="mb10 bill-image" src={this.state.filePreviewUrl} />
																						)
																				)}
																			{!this.state.selectedBill.hasOwnProperty('billUrl') && this.state.fileType === 'pdf' ? (
																				<note className="mt5">Save Bill to get preview!</note>
																			) : (null)}
																			{this.state.selectedBill.hasOwnProperty('billUrl') ? (
																				<div className="image-input-download-container">
																					{this.props.type !== 'view' ? (
																						<input type="file" className="mt10" onChange={this.onChangeSelectedBill.bind(this, 'file')} accept="image/*, .pdf" />
																					) : (null)}
																					<a href={this.state.selectedBill.billUrl.replace('https://assets.letsendorse.com', 'https://le-uploaded-image-bucket.s3.amazonaws.com')} target="_blank">Download</a>
																				</div>
																			) : (
																					this.props.type !== 'view' ? (
																						<input type="file" className="mt10" onChange={this.onChangeSelectedBill.bind(this, 'file')} accept="image/*, .pdf" />
																					) : (null)
																				)}
																		</div>
																	)}
															</Col>

															<Col className="form-group" md={12}>
																<label className="control-label">Bill Description:</label>
																{this.props.type === 'view' ? (
																	<textarea className="form-control bill-description" placeholder="Bill Description" value={this.state.selectedBill.description} disabled></textarea>
																) : (
																		<textarea className="form-control bill-description" placeholder="Bill Description" value={this.state.selectedBill.description} onChange={this.onChangeSelectedBill.bind(this, 'description')}></textarea>
																	)}
															</Col>
														</Col>
													</div>
												) : (
														<div className="no-selected-bill">
															Please select a bill to view its details.
													</div>
													)}
											</div>
										) : (
												<div className="no-bills">
													<h4>No Bills to Show</h4>
												</div>
											)}
										{this.props.type !== 'view' ? (
											<div className="add-bill mt10">
												<button className="btn add-bill-btn" onClick={this.createNewBill.bind(this)}>
													<i className="fa fa-plus"></i>
													Add New Bill
												</button>
											</div>
										) : (null)}
									</div>
								) : (null)}
							</Col>
						</Col>

						<Col md={12} className="report-expense-buttons-container mt20 mb10">
							{this.props.type !== 'view' ? (
								<button className="btn report-expense-save-button" onClick={this.reportExpense.bind(this)}>
									<i className="fa fa-check"></i>
									Save
								</button>
							) : (null)}

							{this.props.type !== 'view' ? (
								<button className="btn report-expense-cancel-button" onClick={this.props.hideReportExpenseModal}>
									<i className="fa fa-times"></i>
									Cancel
								</button>
							) : (
									<button className="btn report-expense-cancel-button" onClick={() => this.props.hideReportExpenseModal('closeView')}>
										<i className="fa fa-times"></i>
										Close
								</button>
								)}
						</Col>
					</Row>
				</Modal.Body>
			</div>
		);
	}
}