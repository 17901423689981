import React, { Component } from "react";
import _ from "lodash";
import moment from 'moment';
import TimeAgo from 'react-timeago';
// import socketIOClient from "socket.io-client";
import InfiniteScroll from 'react-infinite-scroll-component';

const readCookie = require('../cookie.js').readCookie;

export default class IMTopMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notification: false,
			badge: false,
			socket: null,
			messages: [],
			badgeCounts: 0,
			showBadge: true,
			selectedTab: '',
			notifications: [],
			loader: false,
			tracks: [],
			hasMoreItems: false,
			nextHref: null,
			totalNotifications: 0,
			perPage: 5,
			page: 1
		};
	}

	componentWillMount() {
		if (this.state.notification === false) {
			let notifications = [];
			fetch(process.env.REACT_APP_MIS_API_URL + '/notifications?perPage=' + this.state.perPage, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				}
			}).then(res => res.json())
				.then(res => {
					if (res.status === "ok") {
						notifications = res.data.notifications;
						let hasMoreItems = this.state.hasMoreItems;
						if (notifications.length < res.data.total) {
							hasMoreItems = true;
						}
						sessionStorage.setItem('notificationCount', 0);

						this.setState({ notifications, notification: true, hasMoreItems, showBadge: false, totalNotifications: res.data.total });
					}
				})
		} else {
			this.setState({ notification: false });
		}
	}

	loadMoreNotifications = () => {
		let notifications = [];
		let page = this.state.page;
		page += 1;
		if (this.state.notifications.length >= this.state.totalNotifications) {
			this.setState({ hasMoreItems: false });
			return;
		}
		fetch(process.env.REACT_APP_MIS_API_URL + '/notifications?page=' + this.state.page + '&perPage=' + this.state.perPage, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then(res => res.json())
			.then(res => {
				if (res.status === "ok") {
					notifications = this.state.notifications.concat(res.data.notifications);
					this.setState({ notifications, totalNotifications: res.data.total, page });
				}
			})
	}

	formatter(x, y, z) {
		if (y === 'second') return 'few seconds ago';
		else if (x === 1) return x + ' ' + y + ' ' + z;
		else return x + ' ' + y + 's ' + z;
	}

	render() {
		const loader = <div className="loader" style={{ display: "flex", justifyContent: "center" }}><img src="/images/spinner.gif" style={{ width: "60px", height: "60px" }} alt="spinner" /></div>;
		var items = [];
		this.state.notifications.map((notification, index) => {
			items.push(
				<div className="messageDiv" key={index + "notifs"} style={{ display: "flex", padding: "10px", borderRadius: "4px", border: "solid 1px #eee", cursor:"pointer" }} onClick={() => { window.location.pathname = notification.redirectUrl }}>
					<img src="http://bpic.588ku.com/element_origin_min_pic/17/09/19/28e7312d32356cabb08cb36d5d232492.jpg" alt="" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
					<div className='notifyInfo' style={{ display: "flex", flexDirection: "column", marginLeft: "8px", marginRight: "8px", width: "100%" }}>
						<h3 style={{ color: "#515356", fontSize: "22px", marginTop: "0px", marginBottom: "0px" }} className="my-2">{notification.msg}</h3>
						<p style={{ color: "#a9a9a9", margin: "5px 0" }}><TimeAgo style={{ fontSize: "14px" }} date={moment(notification.createdAt).toDate()} formatter={this.formatter} /></p>
						<button className='viewNotification-button' onClick={() => { window.location.pathname = notification.redirectUrl }}>View</button>
					</div>
				</div>
			);
		});
		return (
			<div className="im-container notification-container">
				{this.state.notification ? (
					<div id="notification" className="notification" style={{ width: "50%", margin: "0 auto" }}>
						<h2 className="notificationHeader" style={{ margin: "15px 0px 0px 0px", textAlign: "center" }}>
							Notifications
						</h2>
						{/* <hr style={{ border: "solid .5px #ef5a20", marginBottom: "0", marginTop: "5px" }} /> */}
						<div id='tracks' style={{ height: "78vh", overflowY: "scroll", paddingTop: "12px" }} className="tip">
							{this.state.notifications.length ? (
								<InfiniteScroll
									dataLength={this.state.notifications.length}
									next={this.loadMoreNotifications}
									hasMore={this.state.hasMoreItems}
									loader={loader}
									scrollableTarget={"tracks"}
									endMessage={
										<p style={{ textAlign: "center", color: "#a9a9a9", marginTop: "12px" }}>
											You don't have new notifications any more.
										</p>
									}
									style={{ height: "78vh" }}
								>
									{items}
									{this.state.hasMoreItems === true ? <p style={{ textAlign: "center", color: "#a9a9a9", marginTop: "12px" }}>
										Scroll down to load more notifications.
										</p> : null}
								</InfiniteScroll>
							) : (<div className="messageDivEmpty" style={{ height: "100%" }}>
								<div className='notifyInfo' style={{ display: "flex", flexDirection: "column", marginLeft: "8px", marginRight: "8px", width: "100%" }}>
									<h3 style={{ color: "#a9a9a9", fontSize: "16px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }} className="my-2">You don't have any new Notifications.</h3>
								</div>
							</div>)}
						</div>
					</div>
				) : (null)}
			</div>
		);
	}
}