import React, {Component} from 'react';
import {Row, Col ,Button} from 'react-bootstrap';
import $ from "jquery";
import Multiselect from 'react-bootstrap-multiselect';

import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';

const readCookie = require('../cookie.js').readCookie;

export default class EditTask extends Component {

	constructor(props){
		super(props);
		this.state = {
			name :  '',
			description : '',
			members : [],
			data : '',
			date : '',
			assignFor: null,
      assignForValue: '',
      assignedTo: null,
      assignedToValue: [],
			donorIdtagged : [],
			donorIds : [],
			donors : [],
			groups : [],
			all_assigned_for_list: [],
			assigned_to_members : [],
			assigned_to_suggestions : [],
			assigned_to_tags : [],
			assigned_to_addedIds: [],
			assigned_to_deletedIds: [],
			showAssignForError: false,
			showAssignedToError: false
		}
		this.handleDelete2 = this.handleDelete2.bind(this);
		this.handleAddition2 = this.handleAddition2.bind(this);
		this.handleDrag2 = this.handleDrag2.bind(this);
	}

	handleDelete2(i) {
		let assigned_to_tags = this.state.assigned_to_tags;
		var assigned_to_addedIds = this.state.assigned_to_addedIds;
		assigned_to_addedIds = assigned_to_addedIds.filter(function( obj ) {
		  return obj.field !== this.state.assigned_to_tags[i]['text'];
		}.bind(this));
		this.setState({ assigned_to_addedIds: assigned_to_addedIds })
		this.setState({assigned_to_deletedIds : this.state.assigned_to_deletedIds.concat(this.state.assigned_to_tags[i]['text'])});
		assigned_to_tags.splice(i, 1);
		this.setState({assigned_to_tags: assigned_to_tags});
	}

	handleAddition2(tag) {
		let assigned_to_tags = this.state.assigned_to_tags;
		this.setState({assigned_to_addedIds : this.state.assigned_to_addedIds.concat(tag)});
		assigned_to_tags.push({
			id: assigned_to_tags.length + 1,
			text: tag
		});
		this.setState({assigned_to_tags: assigned_to_tags});
	}

	handleDrag2(tag, currPos, newPos) {
		let assigned_to_tags = this.state.assigned_to_tags;

		assigned_to_tags.splice(currPos, 1);
		assigned_to_tags.splice(newPos, 0, tag);

		this.setState({ assigned_to_tags: assigned_to_tags });
	}

	onSubmitGroup() {
		if(this.state.name !== '' && this.state.description !== '' && !this.state.showAssignedToError && !this.state.showAssignForError) {
			let added = {};

			added['user_ids'] = this.state.assignForValue;
			added['group_ids'] = [];

			let assigned_to_added = {};

			assigned_to_added['user_ids'] = this.state.assignedToValue;
			assigned_to_added['group_ids'] = [];

			fetch(process.env.REACT_APP_API_URL + '/update_task/' + this.props.id, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify({
					types : 'donor',
					title : this.state.name,
					description : this.state.description,
					due_on : this.state.date,
					assigned_to : assigned_to_added,
					assigned_for : added
				})
			}).then((response) => response.json())
			.then((data) => {
				if(data['status'] === 'ok') {
					fetch(process.env.REACT_APP_API_URL + '/tasks/'+ this.props.ngo['ngo_id'], {
						method: "GET",
						headers: {
							'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
						}
					}).then(function(response) {
						return response.json();
					}).then(function(data) {
						this.props.onEditClose('refresh');
					}.bind(this))
				}
			});
		}
	}

	componentDidMount() {
		// fetch(process.env.REACT_APP_API_URL + '/groups_users/' + this.props.ngo.ngo_id, {
  //     method: "GET",
  //     headers: {
  //       'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
  //     }
  //   }).then(function(response) {
  //     return response.json();
  //   }).then(function(data) {
  //     let groups_donors = data.groups_donors;
  //     let groups = [];
  //     for(var i = 0; i < groups_donors.length; i++) {
  //       let group_obj = {};
  //       group_obj['id'] = groups_donors[i].group_id;
  //       group_obj['label'] = groups_donors[i].group_name;
  //       let group_members = [];
  //       for(var j = 0; j < groups_donors[i].group_members.length; j++) {
  //         let group_member_obj = {};
  //         group_member_obj['value'] = groups_donors[i].group_members[j].id;
  //         group_member_obj['label'] = groups_donors[i].group_members[j].name + ' (' + groups_donors[i].group_members[j].email + ')';
  //         group_members.push(group_member_obj);
  //       }
  //       group_obj['children'] = group_members;
  //       if(group_members.length) groups.push(group_obj);
  //     }
  	fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
	  }).then(function(response) {
      return response.json();
	  }).then(function(data) {
      let assignFor = [];
      for(var i = 0; i < data.donors.length; i++) {
      	let member_obj = {};
        member_obj['value'] = data.donors[i].donor_id;
        member_obj['label'] = data.donors[i].first_name + ' ' + data.donors[i].last_name + ' (' + data.donors[i].email + ')';
        assignFor.push(member_obj);
      }
      this.setState({ assignFor }, function() {
				fetch(process.env.REACT_APP_API_URL + '/get_task/' + this.props.ngo['ngo_id'] + '/' + this.props.id, {
					method: "GET",
					headers: {
						'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
					}
				}).then(function(response) {
					return response.json();
				}).then(function(data) {
					var task = data['task'];
					var assigned_for = task['assigned_for'];
					var assigned_to = task['assigned_to'];

					var assigned_to_tags = [];
					var assigned_to_tagsCount = 0;

					var assignFor = this.state.assignFor;
					var assignForValue = [];
					for(var i = 0; i < assigned_for['users'].length; i++) {
						assignForValue.push(assigned_for['users'][i].id);
					}
					for(i = 0; i < assignFor.length; i++) {
						if(assignForValue.indexOf(assignFor[i].value) > -1) assignFor[i].selected = true;
					}
					// var assignForValue = [];
					// for(var i = 0; i < assigned_for['users'].length; i++) {
					// 	assignForValue.push(assigned_for['users'][i].id);
					// 	for(var j = 0; j < assignFor.length; j++) {
					// 		for(var k = 0; k < assignFor[j].children.length; k++) {
					// 			if(assignFor[j].children[k]['value'] === assigned_for['users'][i]['id']) {
					// 				assignFor[j].children[k]['selected'] = true;
					// 				k = assignFor[j].children.length;
					// 			}
					// 		}
					// 	}
					// }

					var assigned_to_addedIds = this.state.assigned_to_addedIds;

					for(i = 0; i < assigned_to['groups'].length; i++) {
						assigned_to_tagsCount += 1;
						let tagObj = {};
						tagObj['id'] = assigned_to_tagsCount;
						tagObj['text'] = assigned_to['groups'][i]['name'];
						assigned_to_tags.push(tagObj);
						assigned_to_addedIds.push(tagObj['text']);
					}
					for(i = 0; i < assigned_to['users'].length; i++) {
						assigned_to_tagsCount += 1;
						let tagObj = {};
						tagObj['id'] = assigned_to_tagsCount;
						tagObj['text'] = assigned_to['users'][i]['name'];
						assigned_to_tags.push(tagObj);
						assigned_to_addedIds.push(tagObj['text']);
					}

					this.setState({ assignFor }, function() {
						this.setState({
							name: task['title'],
							description: task['description'],
							date: task['due_on'],
							assigned_to_tags: assigned_to_tags,
							assignForValue: assignForValue
						});
					}.bind(this));

					var d = new Date(task['due_on']);
					var year = d.getFullYear();
					var month = d.getMonth()+1;
					var day = d.getDate();

					if(month <= 9) month = '0' + month;
					if(day <= 9) day = '0' + day;
					var formattedDate = year + "-" + month + "-" + day;
					$('input#date').val(formattedDate);
				}.bind(this));
      }.bind(this));
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/get_ngo_users/' + this.props.ngo['ngo_id'], {
			method: "GET",
			headers: {
				'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
			}
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			let members = [];
			var users = data['users'];
			var assigned_to_suggestions = [];
			for(var i = 0; i < users.length; i++) {
				var member_obj = {};
				member_obj['value'] = users[i]['id'];
				member_obj['label'] = users[i]['first_name'] + ' ' + users[i]['last_name'] + ' (' + users[i]['email'] + ')';
				members.push(member_obj);
				assigned_to_suggestions.push(users[i]['first_name'] + ' ' + users[i]['last_name'])
			}
			this.setState({
				assigned_to_members: users,
				assigned_to_suggestions: assigned_to_suggestions
			}, function() {
				fetch(process.env.REACT_APP_API_URL + '/get_task/' + this.props.ngo['ngo_id'] + '/' + this.props.id, {
					method: "GET",
					headers: {
						'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
					}
				}).then(function(response) {
					return response.json();
				}).then(function(data) {
					for(var i = 0; i < data.task.assigned_to['users'].length; i++) {
						for(var j = 0; j < members.length; j++) {
							if(members[j]['value'] === data.task.assigned_to['users'][i]['id']) {
								members[j]['selected'] = true;
							}
						}
					}
					this.setState({ assignedTo: members });
				}.bind(this));
			}.bind(this));
		}.bind(this));
	}

	setname(value) {
		this.setState({ name : value });
	}

	setdescription(value) {
		this.setState({ description : value });
	}

	setDate(value) {
		value = String(value);
		value = value.split('-');
		var temp = value[0] + '/' + value[1] + '/' + value[2];
		this.setState({ date: temp })
	}

	// getDate(){
	//   let new_donor_date=this.state.date;
	//   new_donor_date=new_donor_date[2] + "/" + new_donor_date[1] + "/" + new_donor_date[0];
	//   return new_donor_date
	// }

	handleChange(name, value) {
		if(name === 'assignForValue') {
	    this.setState({ [name]: $('select#assignFor').val() }, function() {
      	if(this.state.assignForValue.length === 0) this.setState({ showAssignForError: true });
      	else this.setState({ showAssignForError: false });
    	});
    } else if(name === 'assignedToValue') {
    	this.setState({ [name]: $('select#assignedTo').val() }, function() {
      	if(this.state.assignedToValue.length === 0) this.setState({ showAssignedToError: true });
      	else this.setState({ showAssignedToError: false });
    	});
    }
  }

	render () {
		return (
      <div className="margin-col-10">
        <Row>
          <Col className="form-group" md={12}>
            <label className="control-label is-imp">Task:</label>
            <input type="text" className="form-control" id="edit_task_name" value={this.state.name}
              onChange={(e) => {this.setname(e.target.value)}} placeholder="Group 1" required/>
            <span><span className="red-color">Input is too long!</span></span>
          </Col>

          <Col className="form-group" md={12}>
            <label className="control-label is-imp">Task Description:</label>
            <textarea cols="40" rows="5" className="form-control" id="edit_task_description" name="description"
              value={this.state.description} onChange={(e) => {this.setdescription(e.target.value)}}
              placeholder="Group description" required/>
            <span><span className="red-color">Input is too long!</span></span>
          </Col>

          <Col className="form-group" md={12}>

          	<Col className="no-pad-left no-pad-right" md={12}>
              <label className="control-label is-imp">Assign for:</label>
              <div>
                {this.state.assignFor !== null && this.state.assignForValue !== null && this.state.assignForValue !== '' ? (
                  <Multiselect id="assignFor" value={this.state.assignForValue}
                  	onChange={this.handleChange.bind(this, 'assignForValue')} includeSelectAllOption
                    buttonWidth={'100%'} data={this.state.assignFor} multiple enableClickableOptGroups
                    enableCollapsibleOptGroups numberDisplayed={5} enableCaseInsensitiveFiltering
                    enableFiltering onSelectAll={this.handleChange.bind(this, 'assignForValue')}
                    onDeselectAll={this.handleChange.bind(this, 'assignForValue')} />
                ) : (null)}
              </div>
              <span hidden={!this.state.showAssignForError}><span className="required-span">Please select a valid Donor/Group.</span></span>
            </Col>
          </Col>
        </Row>

        <Row>
          <Col className="form-group" md={6}>
            <label className="control-label is-imp">Assigned to:</label>
            <div>
              {this.state.assignToValue !== null && this.state.assignedToValue !== '' ? (
              	<Multiselect id="assignedTo" defaultValue={this.state.assignedToValue}
                	onChange={this.handleChange.bind(this, 'assignedToValue')} includeSelectAllOption
                  buttonWidth={'100%'} data={this.state.assignedTo} multiple enableClickableOptGroups
                  enableCollapsibleOptGroups numberDisplayed={1} enableCaseInsensitiveFiltering
                  enableFiltering onSelectAll={this.handleChange.bind(this, 'assignedToValue')}
                  onDeselectAll={this.handleChange.bind(this, 'assignedToValue')} />
              ) : (null)}
            </div>
            <span hidden={!this.state.showAssignForError}><span className="required-span">Please select a valid User.</span></span>
          </Col>

          <Col className="form-group" md={4}>
            <label className="control-label is-imp">Finish by:</label>
            <input type="date" className="form-control" id="date" name="date"
              onChange={(e) => {this.setDate(e.target.value)}}
              placeholder="04/05/2017" required/>
            <span><span className="red-color">Input is too long!</span></span>
          </Col>
        </Row>

        <Row>
          <div className="form-group btn-container" >
            <Button onClick={() => { this.props.onEditClose()}} className="btn btn-danger brand-color-background">
            	Cancel
            </Button>
            <Button className="btn btn-warning brand-color-background"
            	disabled={!this.state.showAssignedToError && !this.state.showAssignForError ? (false) : (true)}
            	id="submit_group" onClick={() => {  this.onSubmitGroup()}}>
              Save Details
              <span className="glyphicon glyphicon-save"></span>
            </Button>
          </div>
        </Row>
      </div>
		);
	}
}
