import React, { Component } from "react";
import { Button, Row } from "react-bootstrap";

import ImportDonations from './ImportDonations';
import $ from 'jquery';

import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../cookie.js').readCookie;

export default class ImportDonationsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubmitButton: false,
      systemHeaders: null,
      data_id: null,
      donorCategories: [],
      donor_category: '',
      donor_category_name: '',
      ngo_80g_certificate_number: null,
      showSubmittingLoader: false,
      additionalDetails: []
    };
  }

  componentDidMount() {
    this.setState({ ngo_80g_certificate_number: JSON.parse(readCookie('ngo')).ngo_80g_certificate_number });
  }

  showSubmitButton() {
    this.setState({ showSubmitButton: true });
  }

  updateSystemHeaders(systemHeaders) {
    this.setState({ systemHeaders });
  }

  updateCustomSystemHeaders(customSystemHeaders) {
    this.setState({ customSystemHeaders });
  }

  updateDataId(data_id) {
    this.setState({ data_id });
  }

  checkMailsAndReceipts = (val, e)=>{
    let additionalDetails = this.state.additionalDetails;
    if(val === 'receipts') {
      if(additionalDetails.indexOf(e.target.value) === -1) {
        additionalDetails.push(e.target.value);
      } else {
        additionalDetails = [];          
      }
    } else if(val === 'emails') {
      if(additionalDetails.indexOf(e.target.value) === -1){
        additionalDetails.push(e.target.value);
      } else {
        additionalDetails.splice(additionalDetails.indexOf(e.target.value), 1);        
      }
    }
    this.setState({ additionalDetails });
  }

  submitHeaderMappings() {
    this.setState({ showSubmittingLoader: true });
    let systemHeaders = this.state.systemHeaders;
    let additionalDetails = this.state.additionalDetails;
    let mappings = {};

    systemHeaders.map(function(systemHeader, index) {
      let key_used_in_csv = $('.select-donor-data-headers').find('.system-headers-div').eq(index).find('ul > li').html();
      let system_key = systemHeader['id'];
      if(key_used_in_csv !== undefined) mappings[system_key] = key_used_in_csv;
    });

    let data = {};
    data['data_id'] = this.state.data_id;
    data['mappings'] = mappings;
    if(additionalDetails.indexOf('send_automated_receipts') > -1) {
     data['send_automated_receipts'] = true ;
    } else {
      data['send_automated_receipts'] = false;
    } 
    if(additionalDetails.indexOf('send_emails') > -1) {
      data['send_emails'] = true;
    } else {
      data['send_emails'] = false;
    }

    fetch(process.env.REACT_APP_API_URL + '/save_sheet/donation/' + this.props.ngo.ngo_id, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify(data)
    }).then((response) => response.json())
    .then(function(data) {
      if(data.status === 'ok'){
        this.props.onImportDonationsClose();
        this.setState({ showSubmittingLoader: false, ngo_80g_certificate_number: null });
        this.props.getDonations();
        if(data.message){
          swal({
            text: data['message'],
            type: 'success'
          });  
        }
      } else if(data.status === 'error') {
        this.setState({ showSubmittingLoader: false });
        let html = "<ol className='message' style='list-style: decimal; margin-left: 10px;'>";
        for(var i=0 ; i < data['message'].length; i++){
          html += "<li style='list-style: decimal !important; text-align: left;'>" + data['message'][i] + "</li>";
        }
        html += "</ol>";
        swal({
          html: html,
          type: 'error'
        });
      } 
    }.bind(this));
  }

  closeModal = () =>{
    this.setState({
      showSubmitButton: false,
      systemHeaders: null,
      data_id: null,
      donorCategories: [],
      donor_category: '',
      donor_category_name: '',
      showSubmittingLoader: false,
      additionalDetails: [],
      ngo_80g_certificate_number: null
    }, function() {
      this.props.onImportDonationsClose();
    }.bind(this));
  }

  render() {
    return (
      <div className={this.props.showImportDonationsModal ? "page-container import-donations-page" : "import-donations-page"}>
        {this.props.showImportDonationsModal ? (
          <div>
            <h2 className="modal-header-custom">
              Import Donations
            </h2>
            <Row>
              <ImportDonations ngo={this.props.ngo} ngo_id={this.props.ngo_id} showSubmitButton={this.showSubmitButton.bind(this)}
                updateSystemHeaders={this.updateSystemHeaders.bind(this)} updateCustomSystemHeaders={this.updateCustomSystemHeaders.bind(this)}
                updateDataId={this.updateDataId.bind(this)} checkMailsAndReceipts={this.checkMailsAndReceipts} additionalDetails={this.state.additionalDetails} 
                certificate={this.state.ngo_80g_certificate_number}/>
            </Row>
            <Row className="text-center mt30">
              <Button disabled={this.state.showSubmittingLoader} className="btn-danger mr5 ml5 mt30" onClick={this.closeModal}>Cancel Import</Button>
              {this.state.showSubmitButton ? (
                !this.state.showSubmittingLoader ? (
                  <Button className="mr5 ml5 mt30" onClick={this.submitHeaderMappings.bind(this)}>
                    Submit
                  </Button>
                ) : (
                  <Button disabled className="mr5 ml5 mt30" onClick={this.submitHeaderMappings.bind(this)}>
                    Submitting
                    <i className="fa fa-spin fa-spinner"></i>
                  </Button>
                )
              ) : (null)}
            </Row>
          </div>
        ) : (null)}
      </div>
    );
  }
}