import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import { Link } from "react-router-dom";
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col, Modal } from 'react-bootstrap';
import { LocaleProvider, DatePicker } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import swal from 'sweetalert2';

import UserDetailsModal from './UserDetailsModal';

const readCookie = require('../../ImpactManagement/cookie.js').readCookie;
const billOverdueDays = 44; // 45 - 1 days

export default class EMAdminParticularExpense extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openComment: false,
			warning: false,
			selectedId: null,
			rejectResponse: '',
			reports: [],
			expenseCategory: ["Meal", "Travel", "Entertainment", "Mobile", "Internet", "Sundry", "Others"],
			payments: ["Wallet", "UPI", "Cash", "Debit Card", "Credit Card", "Netbanking"],
			category: '',
			bill: null,
			checkedData: true,
			report: null,
			userDetailsModalVisibility: false,
			issuePayoutModalVisibility: false,
			selectedExpenseLine: null,
			newPayout: {
				paymentDate: "",
				paymentMode: "",
				amount: "",
				txnRefNo: ""
			},
			selectedComment: [],
			showComment: false,
			selectedPayout: null,
			showPayoutModal: false
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/EM/fetch_expense_lines/' + this.props.match.params.expenseId + "/admin", {
			method: 'GET',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(res => (res.json()))
		.then(res => {
			this.setState({ reports: res.data, report: res.report });
		})
	}

	expenseLineBox(event) {
		return (
			<input type="text" ref={(e) => this.expenseLine = e} className="form-control" />
		)
	}

	selectCategory(event) {
		this.setState({ category: event.target.value })
	}

	expenseCategoryBox(event) {
		return (
			<select className="form-control" value={this.state.category} onChange={this.selectCategory.bind(this)}>
				<option value='' disabled>Select Category</option>
				{_.map(this.state.expenseCategory, (cat, index) => (
					<option value={cat} key={cat + index}>
						{cat}
					</option>
				))}
			</select>
		)
	}

	expenseDestributionBox(event) {
		return (
			<input type="text" ref={(e) => this.expenseDestribution = e} className="form-control" />
		)
	}
	billDateBox(event) {
		return (
			<input type="date" ref={(e) => this.billDate = e} className="form-control" />
		)
	}
	uploadbillBox(event) {
		return (
			<input type="file" onChange={(e) => this.setState({ bill: e.target.files })} />
		)
	}
	merchantNameBox(event) {
		return (
			<input type="text" ref={(e) => this.merchantName = e} className="form-control" />
		)
	}
	billValueBox(event) {
		return (
			<input type="text" ref={(e) => this.billValue = e} className="form-control" />
		)
	}
	reimbursableValueBox(event) {
		return (
			<input type="text" ref={(e) => this.reimbursableValue = e} className="form-control" />
		)
	}
	statusExpenseBox(event) {
		return (
			<input type="text" ref={(e) => this.statusExpense = e} className="form-control" />
		)
	}
	saveExpenseBox(event) {
		return (
			<button type="button" className="btn btn-primary">save</button>
		)
	}

	actionCheckbox(event) {
		return (
			<input type="checkbox" checked={(this.state.checkedData === true ? true : false)} onChange={this.checkRow.bind(this)} />
		)
	}

	actionExpenseBox(event) {
		return (
			<div>
				<button type="button" className="btn btn-info"
					style={{
						margin: '2px',
						padding: '5px 0px 5px 5px'
					}}
					onClick={this.editData.bind(this)}
				>
					<i className="fa fa-pencil-square-o" style={{ fontSize: 'larger !important' }}></i>
				</button>
				<button type="button" className="btn btn-danger"
					style={{
						margin: '2px',
						padding: '5px 5px 5px 5px'
					}}
					onClick={this.deleteData.bind(this)}
				>
					<i className="fa fa-remove" style={{ fontSize: 'larger !important' }}></i>
				</button>
			</div>
		)
	}

	editData(event) {
		console.log("edit", event, this.statusExpense.value)
	}
	deleteData(event) {
		console.log("delete", event)
	}
	checkRow(event) {
		this.state.checkedData === true ? this.setState({ checkedData: false }) : this.setState({ checkedData: true })
	}
	paymentMode(event) {
		return (
			<select className="form-control" value={this.state.category} onChange={this.selectCategory.bind(this)}>
				<option value='' disabled>Select Payment</option>
				{_.map(this.state.payments, (payment, index) => (
					<option value={payment} key={payment + index}>
						{payment}
					</option>
				))}
			</select>
		)
	}

	addExpenseRow(event) {
		let newReport = [];
		let prevReport = this.state.reports;
		this.setState({ reports: prevReport.concarowt(newReport) })
	}

	submitReport(event) {
		toast.error('Cannot submit the report')
	}
	viewBill(row) {
		return (
			<a href={row} className="note" target="_blank">View bill</a>
		)
	}
	rejectCommentExpense(selectedId) {
		this.setState({ openComment: true, selectedId });
	}

	rejectComment(row) {
		if(this.state.rejectResponse.trim() === ''){
			this.setState({warning: false});
		} else {
			let payload = {
				"comments": this.state.rejectResponse
			}
			fetch(process.env.REACT_APP_API_URL + '/EM/reject_expense_line/' + this.state.selectedId + "/" + this.props.match.params.expenseId, {
				method: 'PUT',
				headers: {
					'Authorization': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(payload)
			}).then(res => (res.json()))
			.then(res => {
				if (res.status === "ok") {
					toast.success('The expense row has been rejected successfully');
					this.setState({ openComment: false });
					window.location.pathname = "/em/expense/report-for-approval/" + this.props.match.params.expenseId;
				}
			}).catch(err => {
				console.log("err", err);
			})
		}
	}

	rejectExpense(row, data) {
		fetch(process.env.REACT_APP_API_URL + '/EM/reject_expense_line/' + this.props.match.params.expenseId + "/" + row, {
			method: 'PUT',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			},
			//body: JSON.stringify(payload)
		}).then(res => (res.json()))
		.then(res => {
			if (res.status === "ok") {
				toast.success('You have successfully rejected this expense row');
				window.location.pathname = "/em/expense/report-for-approval/" + this.props.match.params.expenseId;
			} else {
				toast.error('You have failed to reject this expense row');
			}
		}).catch(err => {
			console.log("err", err);
		})
	}

	approveExpense(row, data) {
		swal({
			title: 'Do you want to approve this Expense Line?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, Approve it!'
		}).then((result) => {
			if (result === true) {
				fetch(process.env.REACT_APP_API_URL + '/EM/approve_expense_line/' + row + "/" + this.props.match.params.expenseId, {
					method: 'PUT',
					headers: {
						'Authorization': JSON.parse(readCookie('access_token')).access_token
					},
					//body: JSON.stringify(payload)
				}).then(res => (res.json()))
				.then(res => {
					if (res.status === "ok") {
						swal(
							'Approved!',
							'This Expense Line has been Approved.',
							'success'
						).then((result) => { console.log(result) })
						window.location.pathname = "/em/expense/report-for-approval/" + this.props.match.params.expenseId;
					} else {
						toast.error('You have failed to  approve this expense row. Please try again');
					}
					//this.setState({reports: res.data});
				}).catch(err => {
					console.log("err", err);
				})
			} else {
				return null
			}
		})
	}

	actionButton(cell, row) {
		// let findData = _.filter(this.state.reports, (report, index) => (report._id === cell));
		if (row.status === "Submitted") {
			return (
				<div>
					<button className="btn btn-success" style={{ marginRight: '10px' }} onClick={this.approveExpense.bind(this, cell)}>Approve</button>
					<button className="btn btn-danger" onClick={this.rejectCommentExpense.bind(this, cell)}>Reject</button>
				</div>
			)
		} else if(row.status === 'Approved' && Object.keys(row.payout).length === 0) {
			return (
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<span>Approved</span>
					<button className="btn btn-danger" style={{ marginLeft: '10px' }} onClick={this.showIssuePayoutModal.bind(this, row)}>Issue Payout</button>
				</div>
			)
		} else if(row.status === 'Approved' && Object.keys(row.payout).length > 0) {
			return (
				<span className="note" style={{cursor: 'pointer'}} onClick={this.showPayoutModal.bind(this, row.payout)}>
					Approved & Paid Out
				</span>
			)
		} else {
			return row.status;
		}
	}

	showIssuePayoutModal(row) {
		let newPayout = {
			"paymentDate": "",
			"paymentMode": "",
			"amount": row.reimbursable_value,
			"txnRefNo": ""
		}
		this.setState({ issuePayoutModalVisibility: true, selectedExpenseLine: row, newPayout });
	}

	hideIssuePayoutModal() {
		let newPayout = {
			"paymentDate": "",
			"paymentMode": "",
			"amount": "",
			"txnRefNo": ""
		}
		this.setState({ issuePayoutModalVisibility: false, selectedExpenseLine: null, newPayout });
	}

	showUserDetailsModal() {
		this.setState({ userDetailsModalVisibility: true });
	}

	hideUserDetailsModal() {
		this.setState({ userDetailsModalVisibility: false });
	}

	onCloseComment(e) {
		this.setState({ openComment: false, selectedId: null });
	}

	fillUpTextarea(e) {
		this.setState({ rejectResponse: e.target.value, warning: true })
	}

	dateFormatter(cell, row) {
		if (cell !== undefined || cell !== null || cell !== '') {
			if(moment(row.approved_date, 'MMM DD, YYYY').diff(moment(cell), 'days') > billOverdueDays) {
				return (
					<span title={'The Bill date is ' + moment(row.approved_date).diff(moment(cell), 'days') + ' days old.'}>
						<span className="note mr5">{moment(cell).format('DD/MM/YYYY')}</span>
						<i className="fa fa-exclamation-triangle note"></i>
					</span>
				)
			} else return (moment(cell).format('DD/MM/YYYY'))
		} else {
			return "NA"
		}
	}

	showComments(cell, row) {
		let data = _.filter(this.state.reports, (report, i) => (
			report._id === cell
		))
		if (data[0].status === "Draft" || data[0].status === "Approved" || data[0].status === "Submitted") {
			return "N/A"
		} else {
			if(row.comments.length){
				return (
					<span className="note" style={{cursor: 'pointer'}} onClick={this.showCommentModal.bind(this, row.comments)}>View Comment</span>
				)
			} else {
				return "N/A"
			}
		}
	}

	submitPayout() {
		let newPayout = this.state.newPayout;
		if(newPayout.paymentDate !== '' && newPayout.paymentMode !== '' && newPayout.amount !== '' && parseFloat(newPayout.amount) > 0) {
			let payout = {
				date: newPayout.paymentDate,
				amount: newPayout.amount,
				mode: newPayout.paymentMode,
				transaction_reference_number: newPayout.txnRefNo
			};
			swal({
			  title: 'Are you sure you wish to mark this as paid out?',
			  text: "You won't be able to revert this!",
			  type: 'warning',
			  showCancelButton: true,
			  confirmButtonText: 'Yes, Submit!',
			  cancelButtonText: 'No, Cancel!',
			  confirmButtonClass: 'btn',
			  cancelButtonClass: 'btn',
			}).then(function (result) {
			  if(result) {
				fetch(process.env.REACT_APP_API_URL + '/EM/payout_expense_line/' + this.state.selectedExpenseLine._id + "/" + this.props.match.params.expenseId, {
					  method: 'POST',
					  headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
					  },
					  body: JSON.stringify(payout)
					}).then((response) => response.json())
					.then(function(data) {
						if(data.status === 'ok') {
							this.notifySuccess('Payout successfully submitted!');
							this.hideIssuePayoutModal();
							window.location.pathname = "/em/expense/report-for-approval/" + this.props.match.params.expenseId;
						} else {
							this.hideIssuePayoutModal();
							swal({
								title: 'Payout Submission Failed!',
								text: data.message,
								type: 'error'
							});
						}
					}.bind(this));
				}
			}.bind(this));
		} else {
			if(newPayout.paymentDate === '') this.notifyError('Please select a proper payment date.');
			if(newPayout.paymentMode === '') this.notifyError('Please select a proper mode of payment.');
			if(newPayout.amount === '' || parseFloat(newPayout.amount) <= 0) this.notifyError('Please select a proper payment amount.');
		}
	}

	changePayoutData(name, value) {
		console.log(name, value)
		let newPayout = this.state.newPayout;
		if(name === 'paymentDate') newPayout[name] = moment(value).format('DD/MM/YYYY');
		else newPayout[name] = value.target.value;
		this.setState({ newPayout })
	}

	notifyError = (text) => toast.error(text);

	notifySuccess = (text) => toast.success(text);

	disabledDate = (current) => {
	return current && current > moment();
	}

	payoutAmountFormatter(cell, row) {
		if(cell && cell.hasOwnProperty('amount')) {
			return cell.amount;
		} else return 'N/A';
	}

	payoutDateFormatter(cell, row) {
		if(cell && cell.hasOwnProperty('date')) {
			return moment(cell.date).format('DD/MM/YYYY');
		} else return 'N/A';
	}

	showCommentModal(selectedComment) {
		this.setState({ showComment: true, selectedComment });
	}

	closeShowComment() {
		this.setState({ showComment: false, selectedComment: [] });
	}

	showPayoutModal(selectedPayout) {
		this.setState({ showPayoutModal: true, selectedPayout });
	}

	hidePayoutModal() {
		this.setState({ showPayoutModal: false, selectedPayout: null });
	}

	render() {
		return (
			<div className="im-container im-project-page im-project-finances-page">
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />

				<Modal show={this.state.openComment} onHide={this.onCloseComment.bind(this)}>
					<div className="comment_modal">
						<div className="comment">
							<textarea style={{ width: '100%', border: '1px solid gray', borderRadius: '4px', height: '45%' }} onChange={this.fillUpTextarea.bind(this)}></textarea>
							<div style={{marginTop:'10px'}}>
								<button className="btn btn-info"
									onClick={this.rejectComment.bind(this)}>
									Submit Comment & Reject
								</button>
								<button style={{ marginLeft: "10px" }} className="btn btn-danger" onClick={this.onCloseComment.bind(this)}>
									Cancel
								</button>
								<div hidden={this.state.warning} style={{ color: 'red', padding: '10px 0px', fontWeight: 600 }}>
									Please input your reasons for rejection. This is mandatory
								</div>
							</div>
						</div>
					</div>
				</Modal>

				<Modal show={this.state.showComment} onHide={this.closeShowComment.bind(this)}>
					<div className="comment_modal">
						<h3 className="mb20 text-center">Comment</h3>
						<div className="comment">
							<div style={{marginTop:'10px', display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
								{this.state.selectedComment.map((comment, index)=>{
									return(
										<div className="comment" key={index}>{comment}</div>
									)
								})}
							</div>
						</div>
					</div>
				</Modal>

				{this.state.selectedPayout !== null ? (
					<Modal show={this.state.showPayoutModal} onHide={this.hidePayoutModal.bind(this)}>
						<div className="comment_modal">
							<h3 className="mb20 text-center">Payout Information</h3>
							<Col md={12} style={{ marginTop: 20 }}>
								<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}}>Date of Payment :</label>
								<span className="note">{moment(this.state.selectedPayout.date).format('DD/MM/YYYY')}</span>
							</Col>
							<Col md={12} style={{ marginTop: 20 }}>
								<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}}>Mode of Payment :</label>
								<span className="note">{this.state.selectedPayout.mode}</span>
							</Col>
							<Col md={12} style={{ marginTop: 20 }}>
								<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}}>Amount Paid (INR) :</label>
								<span className="note">{this.state.selectedPayout.amount}</span>
							</Col>
							<Col md={12} style={{ marginTop: 20 }}>
								<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}}>Transaction Reference Number :</label>
								<span className="note">{this.state.selectedPayout.transaction_reference_number || 'N/A'}</span>
							</Col>
						</div>
					</Modal>
				) : (null)}

				{this.state.selectedExpenseLine !== null ? (
					<Modal show={this.state.issuePayoutModalVisibility} onHide={this.hideIssuePayoutModal.bind(this)}>
						<Row className="payout_modal">
							<Col md={12} className="mb10">
								<h3 className="text-center">Issue Payout</h3>
							</Col>
							<Col md={12}>
								<Col md={12} className="flex-view-mobile" style={{ display: 'flex', marginTop: 20 }}>
									<div style={{ flex: 1, marginRight: "10px" }}>
										<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}} className="is-imp">Date of Payment :</label>
										<LocaleProvider locale={enUS}>
											<DatePicker disabledDate={this.disabledDate} locale={enUS} style={{ width: "100%" }} format="DD/MM/YYYY" onChange={this.changePayoutData.bind(this, 'paymentDate')} />
										</LocaleProvider>
									</div>
									<div style={{ flex: 1, marginRight: "10px" }}>
										<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}} className="is-imp">Mode of Payment :</label>
										<select value={this.state.newPayout.paymentMode} className="form-control" onChange={this.changePayoutData.bind(this, 'paymentMode')}>
											<option value='' disabled> Select Payment Method</option>
											{_.map(this.state.payments, (payment, index) => (
												<option value={payment} key={index + payment}>{payment}</option>
											))}
										</select>
									</div>
								</Col>
								<Col md={12} className="flex-view-mobile" style={{ display: 'flex', marginTop: 20 }}>
									<div style={{ flex: 1, marginRight: "10px" }}>
										<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}} className="is-imp">Amount Paid (INR) :</label>
										<input type="number" className="form-control" placeholder="Amount Paid (INR)" value={this.state.newPayout.amount} onChange={this.changePayoutData.bind(this, 'amount')} />
									</div>
									<div style={{ flex: 1, marginRight: "10px" }}>
										<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}}>Transaction Reference Number :</label>
										<input type="text" className="form-control" onChange={this.changePayoutData.bind(this, 'txnRefNo')} />
									</div>
								</Col>
								<Col md={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px', marginBottom: 20}}>
									<button className="btn btn-info" onClick={this.submitPayout.bind(this)}>
										Submit Payout
									</button>
									<button style={{ marginLeft: "10px" }} className="btn btn-danger" onClick={this.hideIssuePayoutModal.bind(this)}>
										Cancel
									</button>
								</Col>
							</Col>
						</Row>
					</Modal>
				) : (null)}

				{this.state.report !== null && this.state.userDetailsModalVisibility ? (
					<UserDetailsModal userId={this.state.report.submitter_id} ngo_id={this.props.ngo.ngo_id}
						userDetailsModalVisibility={this.state.userDetailsModalVisibility}
						hideUserDetailsModal={this.hideUserDetailsModal.bind(this)} />
				) : (null)}

				{this.state.report !== null ? (
					<div className="im-project-container im-project-finance-container" style={{ width: 'calc(100% - 20px)', left: '0px' }}>
						<div className="finance-tabs-content" style={{ overflowX: 'scroll' }}>
							<Row>
								<Col md={12} className="mb10 sub-btn-mobile">
									<Link to={'/em/expense/report-for-approval'}>
										<button className="btn btn-danger">
											<i className="fa fa-chevron-left"></i>
											Go Back
										</button>
									</Link>
								</Col>
								<Col md={12} className="mb10 details-holder-mobile">
									<Col md={3} className="sub-details-mobile">
										{this.state.report.id ? (
											<p><note>Expense Report :</note> #{this.state.report.id}</p>
										) : (null)}
										{this.state.report.status ? (
											<p><note>Overall Status :</note> {this.state.report.status}</p>
										) : (null)}
									</Col>
									<Col md={3} className="sub-details-mobile">
										{this.state.report.total_reimbursable ? (
											<p><note>Total Reimbursable Value :</note> {this.state.report.total_reimbursable + ' (INR)'}</p>
										) : (null)}
										{this.state.report.total_reimbursed ? (
											<p><note>Total Reimbursed Value :</note> {this.state.report.total_reimbursed + ' (INR)'}</p>
										) : (null)}
									</Col>
									<Col md={3} className="sub-details-mobile">
										{this.state.report.submitted_by ? (
											<p><note>Submitted By :</note> <u style={{cursor: 'pointer'}} onClick={this.showUserDetailsModal.bind(this)}>{this.state.report.submitted_by}</u></p>
										) : (null)}
									</Col>
								</Col>
							</Row>

							<div className="tab-content budget-tab-content" style={{ overflowX: 'scroll' }}>
								<BootstrapTable data={this.state.reports} striped={false} ref='table' hover={true} exportCSV
										csvFileName={'Report Approval #' + this.state.report.id + ' ' + String(moment().unix()) + '.csv'}>
									<TableHeaderColumn dataField="_id" isKey={true} hidden={true} export={false} searchable={false}>Id</TableHeaderColumn>
									<TableHeaderColumn dataField="line_id" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Expense Line #</TableHeaderColumn>
									<TableHeaderColumn dataField="expense_category" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true}>Expense Category</TableHeaderColumn>
									<TableHeaderColumn dataField="description" width='180px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Purpose / Description</TableHeaderColumn>
									<TableHeaderColumn dataField="created_bill_date" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.dateFormatter.bind(this)}>Bill Date</TableHeaderColumn>
									<TableHeaderColumn dataField="uploaded_bill" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={false} dataFormat={this.viewBill.bind(this)}>Bill Copy</TableHeaderColumn>
									<TableHeaderColumn dataField="bill_value_amount" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true}>Bill Value (INR)</TableHeaderColumn>
									<TableHeaderColumn dataField="reimbursable_value" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true}>Reimbursable Amount (INR)</TableHeaderColumn>
									<TableHeaderColumn dataField="payout" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={false} searchable={false} dataFormat={this.payoutDateFormatter.bind(this)}>Payout Date</TableHeaderColumn>
									<TableHeaderColumn dataField="payout" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={false} searchable={false} dataFormat={this.payoutAmountFormatter.bind(this)}>Payout Amount (INR)</TableHeaderColumn>
									<TableHeaderColumn dataField="Payout Date" hidden={true} export={true} searchable={true}>Payout Date</TableHeaderColumn>
									<TableHeaderColumn dataField="Payout Amount" hidden={true} export={true} searchable={true}>Payout Amount (INR)</TableHeaderColumn>
									<TableHeaderColumn dataField="approved_by" width='180px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true}>Approved / Rejected By</TableHeaderColumn>
									<TableHeaderColumn dataField="_id" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={false} searchable={false} dataFormat={this.showComments.bind(this)}>Comments</TableHeaderColumn>
									<TableHeaderColumn dataField="comment" hidden={true} export={true} searchable={true}>Comment</TableHeaderColumn>
									<TableHeaderColumn dataField="_id" width='190px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={false} searchable={true} dataFormat={this.actionButton.bind(this)}>Status</TableHeaderColumn>
									<TableHeaderColumn dataField="status" hidden={true} export={true} searchable={true}>Status</TableHeaderColumn>
								</BootstrapTable>
							</div>
						</div>
					</div>
				) : (null)}
			</div>
		)
	}
}