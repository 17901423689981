import React from 'react';

import SettingsMembersTable from './SettingsMembersTable';
import _ from 'lodash';
import { Select } from 'antd';
const Option = Select.Option;

const readCookie = require('../../ImpactManagement/cookie').readCookie;

export default class SettingsMembers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			members: [],
			children: [],
			adminCount: 0,
			adminList: []
		}
	}

	componentWillMount() {
		this.updateMembers();
	}

	updateMembers() {
		fetch(process.env.REACT_APP_API_URL + '/get_ngo_users/' + this.props.ngo['ngo_id'] + "?module=em", {
			method: "GET",
			headers: {
				'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
			}
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			let adminCount = 0;
	    for(var i=0; i < data['users'].length; i++){
	      if(data['users'][i]['access_type'] === 'admin'){
	        adminCount += 1;
	      }
	    }
	    let children = [];
	    let adminList = _.filter(data.users, (user, i)=>(user.access_type === "admin"))
	    _.map(adminList, (admin, index) => {
	    	children.push({
	    		value: admin.id,
	    		first_name: admin.first_name,
	    		last_name: admin.last_name
	    	})
    	});
		this.setState({ members: data['users'], children, adminCount, adminList });
		}.bind(this));
	}

  render() {
  	if(this.state.members.length === 0) {
  		return null;
  	} else {
	    return (
	      <div className="mt20">
	        <SettingsMembersTable ngo={this.props.ngo} members={this.state.members} adminCount={this.state.adminCount} children={this.state.children} adminList={this.state.adminList} updateMembers={this.updateMembers.bind(this)}/>
	      </div>
	    );
	  }
  }
}
