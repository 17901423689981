import React, { Component } from 'react';
import _ from 'lodash';

export default class ImageContainer extends Component{

    constructor(props){
        super(props);
        this.state={}

    }

    displayMoreImages(event){
        //console.log("displayMoreImages", event);
        this.props.showMoreImages(this.props.ImageList);
    }

    render(){
        console.log("ImageList", this.props.ImageList);
        return(
            <div>
                {
                    (this.props.ImageList.length > 0)
                        ?
                        <div className="image-container" 
                            onClick={this.displayMoreImages.bind(this)}
                        >
                            <span>Click here to view images</span>
                        {/* {
                    
                            _.map(this.props.ImageList, (img, index) => {
                                if(index === 2){
                                    return (
                                            <div className="image-box-last" key={index+"b"}
                                                onClick={this.displayMoreImages.bind(this)}
                                            >
                                                <img src={img.url}
                                                //src="https://www.festivalsherpa.com/wp-content/uploads/2017/10/fest.jpg" 
                                                    className="img-settings" 
                                                    style={{opacity: 0.5}}
                                                    
                                                />
                            
                                                <span className="image-text-click">{"+"}{this.props.ImageList.length-2}</span>
                                            </div>
                                        )
                                }
                                if(index<2){
                                    return(
                                            <div className="image-box" key={index}>
                                                <img src={img.url} 
                                                //src="https://www.festivalsherpa.com/wp-content/uploads/2017/10/fest.jpg" 
                                                className="img-settings" />
                                            </div>
                                    )
                                }
                                    
                            }
                                
                            )
                        }
                         */}
        
        
                    </div>
                        :
                    null
                }
            </div>            
        )
    }
}