import React, { Component } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";

export default class EmailPromptModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    }
  }

  selectMail(e){
    this.setState({ email: e.target.value});
  }

  render() {
    return (
      <div>
        <Modal show={this.props.showPromptModal} onHide={this.props.onHide} bsSize="medium" >
          <Modal.Header>
            <Modal.Title className="modal-header-custom">Select an email to send the receipt with.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <label style={{fontSize: '16px', fontWeight: 600, textDecoration: 'underline'}}>Ngo Email</label>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <input type="radio" value={this.props.sendReceiptData.ngo_email} name="email" onClick={this.selectMail.bind(this)}/>
                  <label style={{marginTop: '7px', marginLeft: '5px', fontSize: '16px'}}>{this.props.sendReceiptData.ngo_email}</label>
                </div>
                <label style={{fontSize: '16px', fontWeight: 600, textDecoration: 'underline'}}>User Email</label>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <input type="radio" value={this.props.userData.email} name="email" onClick={this.selectMail.bind(this)} />
                  <label style={{marginTop: '7px', marginLeft: '5px', fontSize: '16px'}}>{this.props.userData.email}</label>
                </div>
              </Col>
              <div className="email-modal-btns mt10" style={{marginLeft: '70%'}}>
                <Button className="btn mr10" onClick={this.props.mailforDetails.bind(this, this.state.email)}>Submit</Button>
                <Button className="btn" onClick={this.props.onPromptModalClose}>Cancel</Button>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
