import React, { Component } from 'react';

export default class IMPartnershipsSidebar extends Component {
	constructor(props) {
		super(props);
	}

	gotoLink(key) {
		if (key !== this.props.selected) {
			if (key === 'sendProjectInvitations') window.location.pathname = '/im/partnerships/project-invitations';
			else if (key === 'fundProjectInvitations') window.location.pathname = '/im/partnerships/funding-invitations';
			else if (key === 'projectsSent') window.location.pathname = '/im/partnerships/projects-submitted';
			else window.location.pathname = '/im/partnerships/send-project-invitations';
		}
	}

	render() {
		return (
			<div className="im-partnerships-sidebar">
				<ul className="partnerships-sidebar-menu-items">
					<li className={this.props.selected === 'sendProjectInvitations' ? "partnerships-sidebar-menu-item selected" : "partnerships-sidebar-menu-item"}
						onClick={this.gotoLink.bind(this, 'sendProjectInvitations')}>
						<span className="menu-item">Partner Invitations</span>
					</li>
					<li className={this.props.selected === 'fundProjectInvitations' ? "partnerships-sidebar-menu-item selected" : "partnerships-sidebar-menu-item"}
						onClick={this.gotoLink.bind(this, 'fundProjectInvitations')}>
						<span className="menu-item">Funding Invitations</span>
					</li>
					<li className={this.props.selected === 'projectsSent' ? "partnerships-sidebar-menu-item selected" : "partnerships-sidebar-menu-item"}
						onClick={this.gotoLink.bind(this, 'projectsSent')}>
						<span className="menu-item">Submitted Projects</span>
					</li>
				</ul>

				<div className="partnerships-sidebar-footer">
					<a href="https://www.letsendorse.com" target="_blank">
						<img src="/images/le_black.png" width="150" />
					</a>
				</div>
			</div>
		);
	}
}
