import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import ReactPhoneInput from 'react-phone-input';
import $ from 'jquery';
import { LocaleProvider, DatePicker } from 'antd';
import moment from 'moment';
import StarRatingComponent from 'react-star-rating-component';
import en_US from 'antd/lib/locale-provider/en_US';

export default class PersonalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxDate: ''
    }
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  handleNationalityChange() {
    var $this = $('.personal').find('select#nationality');
    var $selectedNationality = $($this).find(':selected');
    this.props.selectNationality($selectedNationality.val());
  }

  handleCountryChange(state, city) {
    var $this = $('.personal').find('select#country');
    var $selectedCountry = $($this).find(':selected');
    var countryId = $selectedCountry.attr('countryId');
    this.props.selectCountry($selectedCountry.val());
    fetch(process.env.REACT_APP_LE_URL + '/getlocation?type=1&countryId=' + countryId)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      $('.personal').find('select#state').html('<option value="" disabled selected>Choose your state</option>');
      $('.personal').find('select#city').html('<option value="" disabled selected>Choose your city</option>');
      for(var i = 0; i < data.length; i++) {
        $('.personal').find('select#state').append('<option value="' + data[i]['name'] + '" stateId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
      }
      if(state !== '') {
        $('.personal').find('select#state').find('option[value=' + state + ']').prop('selected', true);
        var $this2 = $('.personal').find('select#state');
        var $selectedState = $($this2).find(':selected');
        var stateId = $selectedState.attr('stateId');
        this.props.selectState($selectedState.val());
        fetch(process.env.REACT_APP_LE_URL + '/getlocation?type=2&stateId=' + stateId)
        .then(function(response) {
          return response.json();
        }).then(function(data) {
          $('.personal').find('select#city').html('<option value="" disabled selected>Choose your city</option>');
          for(var i = 0; i < data.length; i++) {
            $('.personal').find('select#city').append('<option value="' + data[i]['name'] + '" cityId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
          }
          if(city !== '') {
            $('.personal').find('select#city').find('option[value=' + city + ']').prop('selected', true);
          }
        });
      }
    }.bind(this));
  }

  handleStateChange() {
    var $this = $('.personal').find('select#state');
    var $selectedState = $($this).find(':selected');
    var stateId = $selectedState.attr('stateId');
    this.props.selectState($selectedState.val());
    fetch(process.env.REACT_APP_LE_URL + '/getlocation?type=2&stateId=' + stateId)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      $('.personal').find('select#city').html('<option value="" disabled>Choose your city</option>');
      for(var i = 0; i < data.length; i++) {
        $('.personal').find('select#city').append('<option value="' + data[i]['name'] + '" cityId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
      }
    });
  }

  handleCityChange() {
    var $this = $('.personal').find('select#city');
    var $selectedCity = $($this).find(':selected');
    this.props.onDonorCityChange($selectedCity.val());
  }

  handleDateChange(date) {
    let e = {};
    let target = {};
    target['value'] = date;
    e['target'] = target;
    this.props.onDonorDobChange(e);
  }

  componentDidMount() {
    var prevYear = new Date();
    var dd = prevYear.getDate();
    var mm = prevYear.getMonth()+1;
    var yyyy = prevYear.getFullYear();
    if(dd < 10) dd = '0' + dd;
    if(mm < 10) mm = '0' + mm;
    yyyy -= 1;
    prevYear = yyyy + '-' + mm + '-' + dd;

    this.setState({ maxDate: prevYear });

    fetch(process.env.REACT_APP_LE_URL + '/getlocation?type=0')
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      for(var i = 0; i < data.length; i++) {
        $('.personal').find('select#nationality').append('<option value="' + data[i]['name'] + '">' + data[i]['name'] + '</option>');
        $('.personal').find('select#country').append('<option value="' + data[i]['name'] + '" countryId="' + data[i]['id'] + '">' + data[i]['name'] + '</option>');
      }
      if(this.props.donor.nationality !== '') $('.personal').find('select#nationality').find('option[value=' + this.props.donor.nationality + ']').prop('selected', true);
      if(this.props.donor.country !== '') {
        $('.personal').find('select#country').find('option[value=' + this.props.donor.country + ']').prop('selected', true);
        this.handleCountryChange(this.props.donor.state, this.props.donor.city);
      }
    }.bind(this));
  }

  disabledEndDate(endValue) {
    if (moment().diff(moment(endValue), 'days') > 0) return false;
    else if(endValue === undefined) return false;
    else return moment(endValue.valueOf());
  }

  render () {
    return (
      <Row>
        <Col className="margin-col-10 personal" md={12}>
          <Row>
            <Col md={12}>
              <p className="modal-sec-title member-add-edit-modal-subtitle">Personal Information</p>
            </Col>

            {this.props.list.default_member_fields.map(function(field, index) {
              if(field.id === 'donor_type') {
                return (
                  <Col className="form-group" md={2} key={index}>
                    <label className="control-label is-imp">Type:</label>
                    <select className="form-control" id="type" name="type" required value={this.props.donor.donor_type} onChange={this.props.onDonorTypeChange}>
                      <option value="individual">Individual</option>
                      <option value="corporate">Corporate</option>
                      <option value="corporate_employee">Corporate Employee</option>
                    </select>
                  </Col>
                )
              } else if(field.id === 'title') {
                return (
                  <Col className="form-group" md={2} key={index}>
                    <label className="control-label is-imp">Title:</label>
                    <select className="form-control" id="title" name="title" required value={this.props.donor.title} onChange={this.props.onDonorTitleChange}>
                      <option value="mr">Mr</option>
                      <option value="mrs">Mrs</option>
                      <option value="miss">Miss</option>
                      <option value="dr">Dr</option>
                    </select>
                  </Col>
                )
              } else if(field.id === 'first_name') {
                return (
                  <Col className="form-group" md={4} key={index}>
                    <label className="control-label is-imp">First Name:</label>
                    <input type="text" className="form-control" id="first_name" name="first_name"
                      placeholder="John" required value={this.props.donor.first_name} onChange={this.props.onDonorFnameChange}/>
                    <span hidden={!this.props.showFirstNameError}><span className="required-span">Please enter a valid First Name.</span></span>
                  </Col>
                )
              } else if(field.id === 'last_name') {
                return (
                  <Col className="form-group" md={4} key={index}>
                    <label className="control-label is-imp">Last Name:</label>
                    <input type="text" className="form-control" id="last_name" name="last_name"
                      placeholder="Doe" required value={this.props.donor.last_name} onChange={this.props.onDonorLnameChange}/>
                    <span hidden={!this.props.showLastNameError}><span className="required-span">Please enter a valid Last Name.</span></span>
                  </Col>
                )
              }
            }.bind(this))}
          </Row>
          <Row>
            {this.props.list.default_member_fields.map(function(field, index) {
              if(field.id === 'phone_no') {
                return (
                  <Col className="form-group" md={4} key={index}>
                    <label className="control-label">Phone:</label>
                    <ReactPhoneInput id="phone" defaultCountry={'in'} type="tel" value={this.props.donor.phone_no} onChange={this.props.onDonorPhoneChange}/>
                    <span hidden={!this.props.showPhoneError}><span className="required-span">Please enter a valid Phone number.</span></span>
                  </Col>
                )
              } else if(field.id === 'email') {
                return (
                  <Col className="form-group" md={4} key={index}>
                    <label className="control-label is-imp">Email:</label>
                    <input type="email" className="form-control" id="email" name="email"
                      placeholder="john@domain.com" required value={this.props.donor.email} onChange={this.props.onDonorEmailChange}/>
                    <span hidden={!this.props.showEmailError}><span className="required-span">Please enter a valid Email.</span></span>
                  </Col>
                )
              } else if(field.id === 'dob') {
                return (
                  <Col className="form-group" md={4} key={index}>
                    <label className="control-label">DOB:</label>
                    <LocaleProvider locale={en_US}>
                      {!this.props.donor.dob || this.props.donor.dob === "" ? (
                        <DatePicker placeholder="Click to select a date" disabledDate={this.disabledEndDate} className="date-picker-dob"
                          onChange={this.handleDateChange.bind(this)} format="DD/MM/YYYY" />
                      ) : (
                        <DatePicker value={moment(this.props.donor.dob)} placeholder="Click to select a date" className="date-picker-dob"
                          onChange={this.handleDateChange.bind(this)} format="DD/MM/YYYY" disabledDate={this.disabledEndDate} />
                      )}
                    </LocaleProvider>
                    <span hidden={!this.props.showDobError}><span className="required-span">Please enter a valid Date of Birth.</span></span>
                  </Col>
                )
              }
            }.bind(this))}
          </Row>
          <Row>
            {this.props.list.default_member_fields.map(function(field, index) {
              if(field.id === 'nationality') {
                return (
                  <Col className="form-group" md={4} key={index}>
                    <label className={this.props.donor_category_name !== 'Prospect' ? "control-label is-imp" : "control-label"}>Nationality:</label>
                    <select id="nationality" onChange={this.handleNationalityChange.bind(this)} defaultValue="">
                      <option value="" disabled>Choose your nationality</option>
                    </select>
                    <span hidden={!this.props.showNationalityError}><span className="required-span">Please enter a valid Nationality.</span></span>
                  </Col>
                )
              } else if(field.id === 'country') {
                return (
                  <Col className="form-group" md={4} key={index}>
                    <label className="control-label">Country:</label>
                    <select id="country" onChange={this.handleCountryChange.bind('', '')} defaultValue="">
                      <option value="" disabled>Choose your country</option>
                    </select>
                  </Col>
                )
              } else if(field.id === 'state') {
                return (
                  <Col className="form-group" md={4} key={index}>
                    <label className="control-label">State:</label>
                    <select id="state" onChange={this.handleStateChange.bind(this)} defaultValue="">
                      <option value="" disabled>Choose your state</option>
                    </select>
                  </Col>
                )
              }
            }.bind(this))}
          </Row>
          <Row>
            {this.props.list.default_member_fields.map(function(field, index) {
              if(field.id === 'city') {
                return (
                  <Col className="form-group" md={4} key={index}>
                    <label className="control-label">City:</label>
                    <select id="city" onChange={this.handleCityChange.bind(this)} defaultValue="">
                      <option value="" disabled>Choose your city</option>
                    </select>
                  </Col>
                )
              } else if(field.id === 'address1') {
                return (
                  <Col className="form-group" md={4} key={index}>
                    <label className="control-label">Street/House/Flat No:</label>
                    <input type="text" className="form-control"
                    id="address1" name="address1"
                    placeholder="208, Hosur Rd, Sector 6" value={this.props.donor.address1} onChange={this.props.onDonorAddress1Change}/>
                    <span><span className="red-color">Input is too long!</span></span>
                  </Col>
                )
              } else if(field.id === 'address2') {
                return (
                  <Col className="form-group" md={4} key={index}>
                    <label className="control-label ">Area/Locality/Colony:</label>
                    <input type="text" className="form-control"
                    id="address2" name="address2"
                    placeholder="HSR Layout" value={this.props.donor.address2} onChange={this.props.onDonorAddress2Change}/>
                    <span><span className="red-color">Input is too long!</span></span>
                  </Col>
                )
              }
            }.bind(this))}
          </Row>
          <Row>
            {this.props.list.default_member_fields.map(function(field, index) {
              if(field.id === 'zipcode') {
                return (
                  <Col className="form-group" md={3} key={index}>
                    <label className="control-label ]">Pin/Zip:</label>
                    <input id="pin" type="text" placeholder="Pincode" className="input-text" value={this.props.donor.zipcode} onChange={this.props.onDonorPincodeChange}/>
                  </Col>
                )
              } else if(field.id === 'donor_source') {
                return (
                  <Col className="form-group" md={3} key={index}>
                    <label className="control-label ]">Source:</label>
                    <input id="source" type="text" placeholder="Source" className="input-text" value={this.props.donor.donor_source} onChange={this.props.onDonorSourceChange}/>
                  </Col>
                )
              } else if(field.id === 'pan_number') {
                return (
                  <Col className="form-group" md={3} key={index}>
                    <label className="control-label">PAN:</label>
                    <input type="text" className="form-control"
                    id="PAN" name="PAN"
                    placeholder="BJKPC1221X" required value={this.props.donor.pan_number} onChange={this.props.onDonorPanChange}/>
                    <span><span className="red-color">Input is too long!</span></span>
                  </Col>
                )
              } else if(field.id === 'priority') {
                return (
                  <Col className="form-group" md={3} key={index}>
                    <label className="control-label">Donor Importance:</label>
                    <StarRatingComponent name="priority" starCount={5} value={this.props.donor.rating}
                      onStarClick={this.props.onStarClick} className="col-6 priority-star" />
                  </Col>
                )
              }
            }.bind(this))}
          </Row>
        </Col>
      </Row>
    );
  }
}