import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import configureStore from './store/configure-store';
import injectTapEventPlugin from 'react-tap-event-plugin';

import Routes from './routes';

import 'sweetalert2/dist/sweetalert2.min.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

require('dotenv').config();
injectTapEventPlugin();
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root')
);