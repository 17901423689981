import React, {Component} from 'react';
import $ from 'jquery';
import { Col, Row } from "react-bootstrap";
import swal from 'sweetalert2';

const createFormToView = require('../../../scripts/formBuilder/view.js').createFormToView;
const submitForm = require('../../../scripts/formBuilder/view.js').submitForm;

const readCookie = require('../../partials/cookie.js').readCookie;

export default class FBCreateFormFromTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allforms: [],
			selectedForm: {},
			selectedFormKey: ''
		}
	}

	componentDidMount(){
		$.getScript('/js/formBuilder/viewElements.js');
		document.write('<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.99.0/css/materialize.min.css">');
		fetch(process.env.REACT_APP_API_URL + '/fb/get/default/forms/' + this.props.ngo.ngo_id, {
			method: 'GET',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			if(data.status === 'ok'){
				this.setState({ allforms: data['data'] });
			}
		});
	}

	getThisLayout(formKey){
		$('._form-holder').find('._form-details').find('div:first-child').find('._form-name').find('._form-name-element').empty();
		$('._form-holder').find('._header-container-views').empty();
		$('._form-holder').find('._element-container-view').empty();

		let selectedForm = {};

		for(var i = 0; i < this.state.allforms.length; i++){
			if(formKey === 'custom' && this.state.allforms[i].form_details.title === 'My Custom Form') selectedForm = this.state.allforms[i];
			else if(formKey === 'contact' && this.state.allforms[i].form_details.title === 'Contact Us Form') selectedForm = this.state.allforms[i];
			else if(formKey === 'mail' && this.state.allforms[i].form_details.title === 'Join our Mailing List') selectedForm = this.state.allforms[i];
			else if(formKey === 'appoint' && this.state.allforms[i].form_details.title === 'Appointment Request') selectedForm = this.state.allforms[i];
			else if(formKey === 'contest' && this.state.allforms[i].form_details.title === 'Vote Now') selectedForm = this.state.allforms[i];
			else if(formKey === 'poll' && this.state.allforms[i].form_details.title === 'Quick Poll') selectedForm = this.state.allforms[i];
			else if(formKey === 'feedback' && this.state.allforms[i].form_details.title === 'Feedback Form') selectedForm = this.state.allforms[i];
		}

		this.setState({ selectedForm, selectedFormKey: formKey}, function(){
			createFormToView(selectedForm, 'testing');
			window.formUrl = process.env.REACT_APP_API_URL + '/fb/post/response/' + selectedForm['_id'];
			window.form = selectedForm;
			if(document.getElementById('testing') !== null) {
				document.getElementById('testing').addEventListener("click", function() {
					submitForm(selectedForm['_id']);
				});
			}
		});
	}

	setThisLayout(){
		fetch(process.env.REACT_APP_API_URL + '/fb/clone/form/' + this.props.ngo.ngo_id + '/' + this.state.selectedForm['_id'], {
			method: 'POST',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify({isClone: false})
		}).then(data => data.json())
		.then(data => {
			if(data.status === 'ok'){
				window.location.pathname= '/fb/form/'+ data['_id'] +'/edit'
			} else {
				swal({
					title: 'Request Failed.',
					text: 'Failed to create clone. Please try again.',
					type: 'error'
				});
			}
		});
	}

	render() {
		return (
			<div className="form-builder-container form-preview">
				<div className="preview-form-holder">
					<div className={this.state.selectedFormKey === '' ? "_hide-select-layout" : "_select-layout-btn"} onClick={this.setThisLayout.bind(this)}>
						Select this template
					</div>
					<div id="_form-layout">
						<Row>
							<Col md={12} className="template-sidenav-title">
								<div className="_form-layout-title"> What type of form would you like to build?</div>
							</Col>
							<Col md={12} className="_form-layout-btn-container">
								<div className="_go-to-edit-btn" onClick={()=>{window.location.pathname='/fb/form/create'}}> Create from scratch</div>
							</Col>
							<div id="_layout-list">
							{this.state.selectedFormKey === 'custom' ? (
								<div className="_selected-layout-list-cards" onClick={this.getThisLayout.bind(this, 'custom')}>
									<div className="_selected-layout-icon-container"><i className="fa fa-envelope"></i></div>
									<div className="_selected-layout-card-detail">
										<div className="_selected-layout-card-detail-title"> Basic Form</div>
										<div className="_selected-layout-card-detail-brief"> Start with something simple.</div>
									</div>
								</div>
							) : (
								<div className="_layout-list-cards" onClick={this.getThisLayout.bind(this, 'custom')}>
									<div className="_layout-icon-container"><i className="fa fa-envelope"></i></div>
									<div className="_layout-card-detail">
										<div className="_layout-card-detail-title"> Basic Form</div>
										<div className="_layout-card-detail-brief"> Start with something simple.</div>
									</div>
								</div>
							)}
							{this.state.selectedFormKey === 'contact' ? (
								<div className="_selected-layout-list-cards" onClick={this.getThisLayout.bind(this, 'contact')}>
									<div className="_selected-layout-icon-container"><i className="fa fa-address-book"></i></div>
									<div className="_selected-layout-card-detail">
										<div className="_selected-layout-card-detail-title"> Contact Form</div>
										<div className="_selected-layout-card-detail-brief"> Help people get in touch with you.</div>
									</div>
								</div>
							) : (
								<div className="_layout-list-cards" onClick={this.getThisLayout.bind(this, 'contact')}>
									<div className="_layout-icon-container"><i className="fa fa-address-book"></i></div>
									<div className="_layout-card-detail">
										<div className="_layout-card-detail-title"> Contact Form</div>
										<div className="_layout-card-detail-brief"> Help people get in touch with you.</div>
									</div>
								</div>
							)}
								{this.state.selectedFormKey === 'mail' ? (
									<div className="_selected-layout-list-cards" onClick={this.getThisLayout.bind(this, 'mail')}>
										<div className="_selected-layout-icon-container"> <i className="fa fa-list"></i></div>
										<div className="_selected-layout-card-detail">
											<div className="_selected-layout-card-detail-title"> Mailing list form</div>
											<div className="_selected-layout-card-detail-brief"> Get more subscribers.</div>
										</div>
									</div>
								) : (
									<div className="_layout-list-cards" onClick={this.getThisLayout.bind(this, 'mail')}>
										<div className="_layout-icon-container"> <i className="fa fa-list"></i></div>
										<div className="_layout-card-detail">
											<div className="_layout-card-detail-title"> Mailing list form</div>
											<div className="_layout-card-detail-brief"> Get more subscribers.</div>
										</div>
									</div>
								)}
								{this.state.selectedFormKey === 'appoint' ? (
									<div className="_selected-layout-list-cards" onClick={this.getThisLayout.bind(this, 'appoint')}>
										<div className="_selected-layout-icon-container"> <i className="fa fa-calendar"></i></div>
										<div className="_selected-layout-card-detail">
											<div className="_selected-layout-card-detail-title"> Appointment form</div>
											<div className="_selected-layout-card-detail-brief"> Schedule sessions through emails.</div>
										</div>
									</div>
								) : (
									<div className="_layout-list-cards" onClick={this.getThisLayout.bind(this, 'appoint')}>
										<div className="_layout-icon-container"> <i className="fa fa-calendar"></i></div>
										<div className="_layout-card-detail">
											<div className="_layout-card-detail-title"> Appointment form</div>
											<div className="_layout-card-detail-brief"> Schedule sessions through emails.</div>
										</div>
									</div>
								)}
								{/*<div className="_layout-list-cards" onClick={this.getThisLayout.bind(this, 'contest')}>
									<div className="_layout-icon-container"> <i className="fa fa-trophy"></i></div>
									<div className="_layout-card-detail">
										<div className="_layout-card-detail-title"> Contest Form</div>
										<div className="_layout-card-detail-brief"> You have to enter to win.</div>
									</div>
								</div>*/}
								{this.state.selectedFormKey === 'poll' ? (
									<div className="_selected-layout-list-cards" onClick={this.getThisLayout.bind(this, 'poll')}>
										<div className="_selected-layout-icon-container"> <i className="fa fa-users"></i></div>
										<div className="_selected-layout-card-detail">
											<div className="_selected-layout-card-detail-title"> Poll</div>
											<div className="_selected-layout-card-detail-brief"> Conduct a survey on your site.</div>
										</div>
									</div>
								) : (
									<div className="_layout-list-cards" onClick={this.getThisLayout.bind(this, 'poll')}>
										<div className="_layout-icon-container"> <i className="fa fa-users"></i></div>
										<div className="_layout-card-detail">
											<div className="_layout-card-detail-title"> Poll</div>
											<div className="_layout-card-detail-brief"> Conduct a survey on your site.</div>
										</div>
									</div>
								)}
								{this.state.selectedFormKey === 'feedback' ? (
									<div className="_selected-layout-list-cards" onClick={this.getThisLayout.bind(this, 'feedback')}>
										<div className="_selected-layout-icon-container"> <i className="fa fa-comment"></i></div>
										<div className="_selected-layout-card-detail">
											<div className="_selected-layout-card-detail-title"> Feedback form</div>
											<div className="_selected-layout-card-detail-brief"> Find out how you are doing.</div>
										</div>
									</div>
								) : (
									<div className="_layout-list-cards" onClick={this.getThisLayout.bind(this, 'feedback')}>
										<div className="_layout-icon-container"> <i className="fa fa-comment"></i></div>
										<div className="_layout-card-detail">
											<div className="_layout-card-detail-title"> Feedback form</div>
											<div className="_layout-card-detail-brief"> Find out how you are doing.</div>
										</div>
									</div>
								)}
							</div>
						</Row>
					</div>
					<div className="_form-outlet create-view">
						<div className="_form-holder form-half template-view">
							<div className="container _mb10 _form-details">
								<div className="row">
									<div className="col-md-12 _form-name">
										<p className="_form-name-element"></p>
									</div>
								</div>
							</div>
							<div className="container _mb10 _header-container-view"></div>
							<div className="container _mb10 _element-container-view" id="elementContainer"></div>
							{this.state.selectedFormKey === '' ? (
								<div style={{display: 'flex', justifyContent: 'center'}}>
									<h4 style={{color: '#ef5a20'}}>Select a form</h4>
								</div>
							) : (null)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
