import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Button } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import swal from 'sweetalert2';

import IMPartnershipsSidebar from './IMPartnershipsSidebar';

const readCookie = require('../cookie.js').readCookie;

export default class IMFundProjectInvitations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			view: 'project',
			corporateData: [],
			projectData: [],
			disbursementType: "",
			showModal: false,
			tranches: [],
			tableData: []
		}
	}

	handleViewChange() {
		if (this.state.view === 'corporate') {
			this.setState({ view: 'project' });
		} else {
			this.setState({ view: 'corporate' });
		}
	}

	componentDidMount() {
		this.refreshData(this);
	}

	refreshData() {
		let tranches = [];
		let disbursementType;
		fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/project-funding-invitations', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				if (data.status === 'ok') {
					for (let i = 0; i < data.data.projectData.length; i++) {
						for (let j = 0; j < data.data.projectData[i].corporates.length; j++) {
							disbursementType = data.data.projectData[i].corporates[j].disbursementType;
							tranches.push(data.data.projectData[i].corporates[j].tranches);
						}
					}
					this.setState({ corporateData: data.data.corporateData, projectData: data.data.projectData, disbursementType, tranches });
				}
			}.bind(this));
	}

	action(status, corporateName, projectId) {
		let data = {};
		swal({
			title: 'Are you sure?',
			html: status === "approve" ? "On accepting this funding invitation, only <b>" + corporateName + "</b> can invest funds in this project. This project shall not be available anymore to other corporates you have shared the project with." : "On declining this funding invitation,this project shall not be available to <b>" + corporateName + "</b> anymore. You will have re-submit the project to <b>" + corporateName + "</b> again if you wish to get another funding invitation from them.",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: status === "approve" ? 'Yes, Accept Invitaion' : 'Yes, Decline Invitaion',
			cancelButtonText: 'No, Cancel!',
			confirmButtonClass: 'btn',
			cancelButtonClass: 'btn',
		}).then(function (result) {
			if (result) {
				data['status'] = status;
				fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/project-funding-invitations/' + projectId, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					},
					body: JSON.stringify(data)
				}).then((response) => response.json())
					.then(function (data) {
						if (data['status'] === 'ok') {
							let invitationStatus = 'approved';
							if (status === 'reject') invitationStatus = 'rejected';
							this.notifySuccess('This Invitation has been ' + invitationStatus + ' successfully.');
							this.refreshData(this);
						}
					}.bind(this));
			}
		}.bind(this)).catch(function () {
		});
	}

	notifySuccess = (text) => toast.success(text);

	plannedAmount(cell, row) {
		return (
			<p>INR {cell}</p>
		)
	}

	showModal(id) {
		let tableData = this.state.tableData;
		let projectData = this.state.projectData;
		for(var i = 0; i < projectData.length; i++) {
			let project = projectData[i];
			for(var j = 0; j < project.corporates.length; j++) {
				let corporate = project.corporates[j];
				if (corporate.id === id) {
					tableData = corporate.tranches;
				}
			}
		}
		this.setState({ showModal: true, tableData });
	}

	hideModal(e) {
		this.setState({ showModal: false });
	}

	render() {
		return (
			<div className="im-container im-partnerships-page">
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />

				<Modal bsSize="large" className="edit-org-details-modal"
					show={this.state.showModal}
					onHide={this.hideModal.bind(this)} >
					<Modal.Header>
						<Modal.Title className="modal-header-custom">
							Tranch Disbursements
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.tableData.length > 0 ? (
							<BootstrapTable data={this.state.tableData} striped={false} ref='table' hover={true}>
								<TableHeaderColumn dataField="amount" isKey={true} hidden={true} export={false} searchable={false}>Tranches</TableHeaderColumn>
								<TableHeaderColumn dataField="amount" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.plannedAmount.bind(this)}>Planned Amount</TableHeaderColumn>
								<TableHeaderColumn dataField="disbursementCondition" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} >Disbursement Condition</TableHeaderColumn>
								<TableHeaderColumn dataField="disbursementCriteria" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} >Disbursement Criteria</TableHeaderColumn>
								<TableHeaderColumn dataField="date" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} >Tentative Disbursement Date</TableHeaderColumn>
							</BootstrapTable>
						) : (null)}
					</Modal.Body>
					<Modal.Footer className="footer-center">
						<Button className="btn btn-danger brand-color-background" onClick={this.hideModal.bind(this)}>Cancel</Button>
					</Modal.Footer>
				</Modal>


				<IMPartnershipsSidebar selected="fundProjectInvitations" />
				<div className="im-partnerships-container im-fund-project-invitations-container">
					<h3>Funding Invitations</h3>
					<div className="view-toggle-container">
						<span className={this.state.view === 'corporate' ? "active" : ""}>Corporate View</span>
						<div className="ondisplay">
							<section>
								<div className="slideThree">
									<input type="checkbox" id="view-toggle" name="check" className="send_email_receipt_check"
										checked={this.state.view === 'corporate' ? false : true} onChange={this.handleViewChange.bind(this)} />
									<label htmlFor="view-toggle"></label>
								</div>
							</section>
						</div>
						<span className={this.state.view === 'project' ? "active" : ""}>Project View</span>
					</div>
					<div className="corporate-invitations-to-fund-projects">
						{this.state.view === 'corporate' ? (
							<div className="corporate-view-container">
								<div className="corporate-container">
									<div className="corporate-name-container">
										<div className="corporate-name-container">
											<h4 style={{ margin: "0", fontWeight: "bold" }} className="project-name">Corporate</h4>
										</div>
									</div>
									<div className="corporate-projects-container">
										<div className="project-container">
											<div className="project-name-container">
												<h4 style={{ margin: "0", fontWeight: "bold" }} className="project-name">Project</h4>
											</div>
											<div className="project-project-status-container" title="Project Status">
												<h4 style={{ margin: "0", fontWeight: "bold" }} className="project-status">Disbursement Type</h4>
											</div>
											<div className="project-project-status-container" title="Project Status">
												<h4 style={{ margin: "0", fontWeight: "bold" }} className="project-status">Project Status</h4>
											</div>
											<div className="project-ngo-status-container">
												<h4 style={{ margin: "0", fontWeight: "bold", textAlign: "center" }} className="ngo-status">Invitation Status</h4>
											</div>
										</div>
									</div>
								</div>
								{this.state.corporateData.length === 0 ? (
									<h3 className="no-data">There are no Invitations sent by any Corporate.</h3>
								) : (null)}
								{this.state.corporateData.map(function (corporate, index) {
									return (
										<div className="corporate-container" key={index}>
											<div className="corporate-name-container">
												<div className="corporate-name-container" title={corporate.name} style={{width:"143px", padding:"10px", wordBreak:"break-all"}}>
													<span style={{ color: "#ef5a20" }} className="corporate-name">{corporate.name}</span>
												</div>
											</div>
											<div className="corporate-projects-container">
												{corporate.projects.map(function (project, projectIndex) {
													return (
														<div className="project-container" key={projectIndex}>
															<div className="project-name-container" title={project.title}>
																<span className="project-name">{project.title}</span>
															</div>
															<div className="project-project-status-container" title="Project Status">
																{project.disbursementType === "One Time" ? <span className="project-status">{project.disbursementType} complete Disbursement</span> : <span className="project-status">{project.disbursementType} Disbursement</span>}
															</div>
															<div className="project-project-status-container" title="Project Status">
																<span className="project-status">{project.projectStatus}</span>
															</div>
															<div className="project-ngo-status-container">
																{project.ngoStatus === false ? (
																	<div className="ngo-status-buttons-container">
																		<button className="btn btn-accept" onClick={this.action.bind(this, 'approve', corporate.name, project.id)}>Accept Funds</button>
																		<button className="btn btn-decline" onClick={this.action.bind(this, 'reject', corporate.name, project.id)}>Decline</button>
																	</div>
																) : (
																		<span className="ngo-status">{project.ngoStatus}</span>
																	)}
															</div>
														</div>
													)
												}.bind(this))}
											</div>
										</div>
									)
								}.bind(this))}
							</div>
						) : (null)}

						{this.state.view === 'project' ? (
							<div className="project-view-container">
								<div className="project-container">
									<div className="project-name-container">
										<h4 style={{ margin: "0", fontWeight: "bold" }} className="project-name">Project</h4>
									</div>
									<div className="project-corporates-container">
										<div className="corporate-container">
											<div className="corporate-name-container">
												<h4 style={{ margin: "0", fontWeight: "bold" }} className="corporate-name">Corporate</h4>
											</div>
											<div className="corporate-disbursement-container">
												<h4 style={{ margin: "0", fontWeight: "bold" }}>Disbursement Type</h4>
											</div>
											<div className="corporate-ngo-status-container">
												<h4 style={{ margin: "0", fontWeight: "bold" }}>Status</h4>
											</div>
										</div>
									</div>
								</div>
								{this.state.projectData.length === 0 ? (
									<h3 className="no-data">There are no Invitations sent by any Corporate.</h3>
								) : (null)}
								{this.state.projectData.map(function (project, index) {
									return (
										<div className="project-container" key={index}>
											<div className="project-name-container" title={project.title}>
												<span className="project-name">{project.title}</span>
												<span className="project-status">{project.projectStatus}</span>
											</div>
											<div className="project-corporates-container">
												{project.corporates.map(function (corporate, corporateIndex) {
													return (
														<div className="corporate-container" key={corporateIndex}>
															<div className="corporate-name-container" title={corporate.name}>
																<span className="corporate-name">{corporate.name}</span>
															</div>
															<div className="corporate-disbursement-container" title={corporate.disbursementType}>
																{corporate.disbursementType !== "One Time" ? <span className="corporate-disbursement-type"><span className="corporate-disbursement-type-label">{corporate.disbursementType} Disbursement</span></span> : <span className="corporate-disbursement-type"><span className="corporate-disbursement-type-label">{corporate.disbursementType} complete Disbursement</span></span>}
																{corporate.disbursementType !== "One Time" ? <button className='tranches-button' onClick={this.showModal.bind(this, corporate['id'])}>View</button> : null}
															</div>
															<div className="corporate-ngo-status-container">
																{corporate.ngoStatus === false ? (
																	<div className="ngo-status-buttons-container">
																		<button className="btn btn-accept" onClick={this.action.bind(this, 'approve', corporate.name, corporate.id)}>Accept</button>
																		<button className="btn btn-decline" onClick={this.action.bind(this, 'reject', corporate.name, corporate.id)}>Decline</button>
																	</div>
																) : (
																		<span className="ngo-status">{corporate.ngoStatus}</span>
																	)}
															</div>
														</div>
													)
												}.bind(this))}
											</div>
										</div>
									)
								}.bind(this))}
							</div>
						) : (null)}
					</div>
				</div>
			</div>
		);
	}
}
