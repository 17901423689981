import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import AddGroup from './AddGroup';
import swal from "sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const readCookie = require('../cookie.js').readCookie;

const styles = {
  clear: {
    clear: 'both',
  },
};

function linkFormatter(cell, row) {
  var groupProfile = window.location.pathname + '/' + row.group_id;
  return (
    <Link className="groups-table-name" to={groupProfile}>{cell}</Link>
  );
}

function descFormatter(cell, row) {
  return (
    <div className="groups-table-desc">{cell}</div>
  );
}

export default class GroupsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      selectRowProp: {
        mode: 'checkbox',
        onSelect: this.onRowSelect.bind(this),
        onSelectAll: this.onAllRowSelect.bind(this),
      },
      selectedRowCount: 0,
      deleteButtonDisabled: true,
      list: null,
      showAddGroupModal: false
    }
  }

  onRowSelect(row, isSelected) {
    if(isSelected) {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length+1 }, function() {
        if(this.state.selectedRowCount) {
          this.setState({ deleteButtonDisabled: false });
        }
      });
    } else {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length-1 }, function() {
        if(this.state.selectedRowCount) {
          this.setState({ deleteButtonDisabled: false });
        } else {
          this.setState({ deleteButtonDisabled: true });
        }
      });
    }
  }

  onAllRowSelect(row, isSelected) {
    if(row) {
      this.setState({
        selectedRowCount: this.refs.table.state.data.length,
        deleteButtonDisabled: false
      });
    } else {
      this.setState({
        selectedRowCount: 0,
        deleteButtonDisabled: true
      });
    }
  }

  getSelectedRowKeys() {
    var deletedIds = [];

    let deleteGroupID = this.refs.table.state.selectedRowKeys;
    for(var i = 0; i < deleteGroupID.length; i++){
      deletedIds.push(deleteGroupID[i]);
    }
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!',
      cancelButtonText: 'No, Cancel!',
      confirmButtonClass: 'btn',
      cancelButtonClass: 'btn',
    }).then(function (result) {
      if(result) {
        fetch(process.env.REACT_APP_API_URL + '/delete_group/' + this.props.ngo['ngo_id'], {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify({ group_ids : deletedIds })
        }).then((response) => response.json())
        .then((data) => {
          if(data['status'] === 'ok'){
            this.setState({
              selectedRowCount: 0,
              deleteButtonDisabled: true
            });
            this.getGroupsData();
          }
        })
      }
    }.bind(this));
  }

  createCustomClearButton(onClick) {
    return (
      <span onClick={onClick}>
        <i className="clear-table-search glyphicon glyphicon-remove"></i>
      </span>
    );
  }

  componentDidMount() {
    this.getGroupsData();

    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/list/' + this.props.params.list_id, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ list: data.list });
    }.bind(this));
  }

  onAddGroupClose = (data) => {
    this.setState({ showAddGroupModal: false });
    if(data === 'refresh') {
      this.getGroupsData();
    }
  }

  getGroupsData() {
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/list/' + this.props.params.list_id + '/groups', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data['status'] === 'ok') {
        this.setState({ data: data['groups'] });
      }
    }.bind(this));
  }

  onAddGroupOpen = () => {
    this.setState({ showAddGroupModal: true });
  }

  createCustomButtonGroup (props, onClick) {
    return (
      <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
        <button type='button'
          onClick={ this.onAddGroupOpen}
          className={'btn btn-success react-bs-table-add-btn hidden-print'}>
          <span>
            <i className="glyphicon glyphicon-plus"></i>
            Add Segment
          </span>
        </button>
        <button type='button' disabled={this.state.deleteButtonDisabled}
          onClick={ this.getSelectedRowKeys.bind(this)}
          className={'btn btn-success react-bs-table-add-btn hidden-print'}>
          <span>
            <i className="glyphicon glyphicon-minus"></i>
            Delete Segment
          </span>
        </button>
      </ButtonGroup>
    );
  }

  render () {
    const options = {
      btnGroup: this.createCustomButtonGroup.bind(this),
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      exportCSVBtn: this.createCustomExportCSVButton,
      sizePerPage: 20,
      sizePerPageList: [
        { text: '20 Per Page', value: 20 },
        { text: '50 Per Page', value: 50 },
        { text: '100 Per Page', value: 100 }
      ]
    };

    if(this.state.list !== null) {
      return (
        <div style={styles.clear}>
          <span className="heading list-name ml10">{this.state.list.name}</span>
          <BootstrapTable data={this.state.data} striped={false} options={options}
            hover={true} ref='table' selectRow={this.state.selectRowProp} pagination search searchPlaceholder="Search by Segment Name / No. of members">
            <TableHeaderColumn dataField="group_id"   dataSort={true} isKey={true} hidden={true} export={false} searchable={false}>Id</TableHeaderColumn>
            <TableHeaderColumn dataField="group_name" dataSort={true} hidden={false} export={true} searchable={true} dataFormat={linkFormatter}>Name</TableHeaderColumn>
            <TableHeaderColumn dataField="group_desc" dataSort={true} hidden={false} export={true} searchable={true} dataFormat={descFormatter}>Description</TableHeaderColumn>
            <TableHeaderColumn dataField="members"    dataSort={true} hidden={false} export={true} searchable={true}>Members</TableHeaderColumn>
          </BootstrapTable>
          {this.state.showAddGroupModal ? (
            <AddGroup ngo={this.props.ngo} userData={this.props.userData}
              showAddGroupModal={this.state.showAddGroupModal} list_id={this.state.list.id}
              onHide={this.onAddGroupClose} group_name='' group_desc=''
              onAddGroupClose={this.onAddGroupClose} donor_ids={[]}
              onAddGroupOpen={this.onAddGroupOpen} />
          ) : (null)}
        </div>
      );
    } else return null;
  }
}