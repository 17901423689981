import React, { Component } from "react";
import { Modal, Row } from "react-bootstrap";

import EditGroupDetails from "./EditGroupDetails";

const readCookie = require('../../cookie.js').readCookie;

export default class EditGroup extends Component {
  render() {
    return (
      <div className="page-container edit-group-page">
        {this.props.showEditGroupModal ? (
          <div>
            <h2 className="modal-header-custom">
              Edit Segment
            </h2>
            <Row>
              <EditGroupDetails ngo={this.props.ngo} group_name={this.props.group_name}
                userEmail={this.props.userData.email} donor_ids={this.props.donor_ids}
                group_desc={this.props.group_desc} list_id={this.props.list_id}
                match_type={this.props.match_type} group_type={this.props.group_type}
                conditions={this.props.conditions} groupId={this.props.groupId}
                onEditGroupClose={this.props.onEditGroupClose} />
            </Row>
          </div>
        ) : (null)}
      </div>
    );
  }
}