import React from 'react';

import SettingsMembersTable from './SettingsMembersTable';
import _ from 'lodash';

const readCookie = require('../../partials/cookie.js').readCookie;

export default class SettingsMembers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			members: [],
			admin_list: [],
			adminCount: 0
		}
	}

	componentWillMount() {
		this.updateMembers();
	}

	updateMembers() {
		fetch(process.env.REACT_APP_API_URL + '/get_ngo_users/' + this.props.ngo['ngo_id']+"?module=fb", {
			method: "GET",
			headers: {
				'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
			}
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			let adminCount = 0;
		for(var i=0; i < data['users'].length; i++){
		  if(data['users'][i]['access_type'] === 'admin'){
			adminCount += 1;
		  }
		}
		let admin_list = _.filter(data.users, (user, i)=>(user.access_type === "admin"))
		this.setState({ members: data['users'], admin_list: admin_list, adminCount });
		}.bind(this));
	}

  render() {
	if(this.state.members.length === 0) {
		return null;
	} else {
		return (
		  <div className="mt20">
			<SettingsMembersTable ngo={this.props.ngo} members={this.state.members} adminCount={this.state.adminCount} adminList={this.state.admin_list} updateMembers={this.updateMembers.bind(this)}/>
		  </div>
		);
	  }
  }
}
