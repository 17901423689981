import $ from 'jquery';
import _ from 'lodash';
import Alert from 'react-s-alert';
import { Row } from 'react-bootstrap';
import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import UserProfile from "./userProfile";
import EditModelIcon from './EditModeIcon';
import { RECTANGLE } from "../constants/constants";

const readCookie = require('../../cookie.js').readCookie;

export default class CreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projArr: null,
            loading: false,
            file: {},
            editMode: true,
            img_count: null,
            img_arr: [],
            FileList: null,
            file_count: null,
            file_arr: [],
            img_array: [],
            textAreaStyle: {
                background: "#eee",
                borderRadius: "4px",
                overflowY: "scroll",
                padding: "10px 20px",
                fontSize: "15px",
                position: "relative"

            },
            customTextAreaStyle: {
                background: "#eee",
                height: "40%",
                borderRadius: "4px",
                overflowY: "scroll",
                padding: "10px 20px",
                fontSize: "10px"
            }
        }
    }

    componentDidMount() {
        fetch("http://www.json-generator.com/api/json/get/cpqZeVcMzm?indent=2")
            .then(res => (res.json()))
            .then(res => {
                this.setState({
                    projArr: res
                })
            })
            .catch(err => {
                console.log("err", err)
            })
    }

    handleImageSelect(e) {
        this.setState({
            textAreaStyle: {
                height: '40%',
                border: '2px solid #AAB5C7',
                borderRadius: '4px',
                overflowY: 'scroll',
            }
        });
    }

    showPostImage(e) {
        this.setState(
            {
                textAreaStyle: {
                    height: '40%',
                    border: '2px solid #AAB5C7',
                    borderRadius: '4px',
                    overflowY: 'scroll',
                }
            }
        )
        this.props.displayPostImage();
    }

    addNewStory(e) {
        let newUpdate = {
            "projectId": this.props.project_id,
            "type": "project",
            "images": this.state.img_array,
            "text": this.refs["comment"].innerText,
        };
        fetch(process.env.REACT_APP_MIS_API_URL + "/post", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Auth': JSON.parse(readCookie('mis_data')).access_token
            },
            body: JSON.stringify(newUpdate)
        }).then(res => (res.json()))
            .then(res => {
                if(res.status === "ok"){
                    this.props.addNewUpdate(res.data);
                    toast.success("You have successfully created a new update");
                    this.refs["comment"].innerText = "";
                    this.setState({ img_array: [], loading: false, data: res.data })      ;
                }else {
                    toast.error('Please enter text');
                }
            }).catch(err => {
                toast.error('Please enter text');
            });
    }

    cancel() {
        this.props.showCreateUpdates();
    }

    hideAttachment(event) {
        this.setState({ hideMe: true })
    }

    // uploadFile(event) {
    //     let docUpload = $('#upload_file').click();
    // }

    uploadImage(event) {
        if (this.state.img_array.length > 9) {
            Alert.info('You cannot upload more than 10 images')
            return;
        }
    }

    uploadCoverImage(file) {
        var data = new FormData();
        data.append('image', file);
        this.setState({ loading: true });
        fetch(process.env.REACT_APP_MIS_API_URL + '/uploadImage', {
            method: 'POST',
            headers: {
                'Auth': JSON.parse(readCookie('mis_data')).access_token
            },
            body: data
        }).then((response) => response.json())
            .then(function (data) {
                let img_arr = [];
                img_arr.push(data.url);
                let array = img_arr.concat(this.state.img_array);
                this.setState({
                    img_array: array, loading: false,
                    textAreaStyle: {
                        background: "#eee",
                        borderRadius: "4px",
                        overflowY: "scroll",
                        padding: "10px 20px",
                        fontSize: "15px",
                        position: "relative"
                    }
                });
            }.bind(this));
    }


    imgUploaded(name, e) {
        var file = e.target.files[0];
        var that = this;
        var reader = new FileReader();
        reader.onloadend = function () {
            that.setState({ img_arr: reader.result });
        }
        reader.readAsDataURL(file);
        this.uploadCoverImage(file);
    }

    docsUploaded(event, e) {
        var file = e.target.files[0];
        var that = this;
        var reader = new FileReader();
        reader.onloadend = function () {
            that.setState({ file_arr: reader.result });
        }
        reader.readAsDataURL(file);
        this.uploadCoverImage(file);
    }
    resetForm() {
        this.setState({
            img_array: [], loading: false, textAreaText: null,
            textAreaStyle: {
                background: "#eee",
                borderRadius: "4px",
                overflowY: "scroll",
                padding: "10px 20px",
                fontSize: "15px",
                position: "relative"

            },
            textAreaText: <h3>Create your updates here</h3>
        })
    }

    render() {
        return (
            <Row>
                <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />

                <div className="col-sm-12">

                    <div className="data-container" style={{
                        width: '100%',
                        marginBottom: '50px',
                        padding: '0px',
                        borderRadius: '4px',
                        display: "flex",
                        alignItems: "center",
                        position: "relative"
                    }}>
                        <div className="col-sm-10">
                            <h3>Create your updates here</h3>
                            <div className="add-update-editable">
                                <div contentEditable="true" ref="comment" onClick={() => this.setState({ textAreaText: null })} style={this.state.textAreaStyle}></div>
                                {(this.state.img_array.length > 0
                                    ?
                                    <div className="upload-image-container" style={{ display: 'flex', float: "left", alignItems: "center" }}>
                                        {_.map(this.state.img_array, (img, index) => (
                                            <div key={index + "thumb"} className="uploaded-image" style={{ backgroundSize: '50%', backgroundImage: 'url(' + img + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center" }}> <img id="image" /></div>
                                        ))}
                                        <div className="add-image-button" style={{ padding: "5px 7px", borderRadius: "100%" }} onClick={this.uploadImage.bind(this)}>
                                            <div
                                                style={{
                                                    margin: '5px',
                                                    fontWeight: '600',
                                                    color: '#fff'
                                                }}
                                            >
                                                <div>
                                                    <i className="fa fa-plus"></i>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    :

                                    null
                                )
                                }

                                {
                                    (this.state.loading)
                                        ?
                                        <p style={{ position: "absolute", marginTop: "8px", bottom: "-30px" }}>Loading Images...</p>
                                        :
                                        <p style={{ display: "none" }}>Loading Images...</p>
                                    // null
                                }

                            </div>


                        </div>


                        <div className="col-sm-2" style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", position: "absolute", right: "0", top: "6px" }}>
                            <input type="file" style={{ display: 'none' }} accept="image/*" id="upload_img" onChange={this.imgUploaded.bind(this, 'image')} />
                            {
                                (this.state.img_array.length > 0
                                    ?
                                    <button type="button" className="btn btn-default btn-danger pull-right"
                                        style={{
                                            marginTop: '35px',
                                        }}
                                        onClick={this.resetForm.bind(this)}
                                    > Clear </button>
                                    :
                                    (
                                        <div className="attachment-box" style={{ border: 'none' }} ref="attachment" onClick={this.uploadImage.bind(this)}>
                                            <EditModelIcon
                                                deleteParent={this.hideAttachment.bind(this)}
                                                isVisible={this.state.editMode}
                                                attachmentCount={this.state.img_count}
                                            />
                                            <UserProfile
                                                scaleComponent={1}
                                                boxType={RECTANGLE}
                                            />
                                            <i className="fa fa-paperclip" style={{ fontSize: "20px" }}></i>
                                        </div>
                                    )
                                )
                            }

                            <button className="btn btn-info"
                                style={{
                                    marginTop: '35px',
                                }}
                                onClick={this.addNewStory.bind(this)}
                            >

                                POST
                            </button>

                        </div>

                    </div>
                    <h3 style={{ margin: "0 0 0 15px" }}>Projects Updates :</h3>

                </div>

            </Row>
        )
    }
}