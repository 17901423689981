import swal from 'sweetalert2';
import { checkIfCanBeConditional, readConditional } from './conditionalElements';

const $ = window.$;

// Update Form Settings
$('._form-details').on('click', function() {
	var selectedElement = $(this).find('._form-name-element');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') {
		selectedElementJSON = JSON.parse(selectedElementJSON);
	} else {
		selectedElementJSON = {
			'title': 'Untitled',
			'description': 'Description',
			'valid_from': '',
			'valid_to': '',
			'time_from': '',
			'time_to': '',
			'offline_data': false,
			'geolocation': false,
			'deletable': true,
			'resp_editable': false
		}
	}
	$('#_form-details-settings').find('#form_name').val(selectedElementJSON['title']);
	$('#_form-details-settings').find('#form_description').val(selectedElementJSON['description']);
	$('#_form-details-settings').find('#valid_from').val(selectedElementJSON['valid_from']);
	$('#_form-details-settings').find('#valid_to').val(selectedElementJSON['valid_to']);
	$('#_form-details-settings').find('#time_from').val(selectedElementJSON['time_from']);
	$('#_form-details-settings').find('#time_to').val(selectedElementJSON['time_to']);
	$('#_form-details-settings').find('#offline_data').prop('checked', selectedElementJSON['offline_data']);
	$('#_form-details-settings').find('#geolocation').prop('checked', selectedElementJSON['geolocation']);
	$('#_form-details-settings').find('#deletable').prop('checked', selectedElementJSON['deletable']);
	$('#_form-details-settings').find('#resp-editable').prop('checked', selectedElementJSON['resp_editable']);

	var selectedElementSettingsJSON = $('._form-holder').find('._form-submit-settings-atr').data('json');
	if(typeof selectedElementSettingsJSON === 'string') {
		selectedElementSettingsJSON = JSON.parse(selectedElementSettingsJSON);
	} else {
		selectedElementSettingsJSON = {
			'button_text': 'submit',
			'option_details': 'Your response has been submitted',
			'option_type': 'message'
		}
	}
	$('#_form-details-settings').find('#submit-button-label').val(selectedElementSettingsJSON['button_text']);
	if(selectedElementSettingsJSON['option_type'] !== ''){
		if(selectedElementSettingsJSON['option_type'] === 'message'){
			$('#_form-details-settings').find('#_submission-message').prop('checked', true);
			$('#_form-details-settings').find('#submit-button-msg').val(selectedElementSettingsJSON['option_details']);
			$('#_form-details-settings').find('._open-url').attr('hidden', true);
		} else if(selectedElementSettingsJSON['option_type'] === 'url'){
			$('#_form-details-settings').find('._open-url').attr('hidden', false);
			$('#_form-details-settings').find('#_submission-url').prop('checked', true);
			$('#_form-details-settings').find('#submit-button-url').val(selectedElementSettingsJSON['option_details']);
			if(selectedElementSettingsJSON['url_open_option'] === 'same'){
				$('#_form-details-settings').find('#_open-url-same').prop('checked', true);
			} else if(selectedElementSettingsJSON['url_open_option'] === 'different') {
				$('#_form-details-settings').find('#_open-url-diff').prop('checked', true);
			}
		}
	} else {
		$('#_form-details-settings').find('#_submission-message').prop('checked', true);
		$('#_form-details-settings').find('#submit-button-msg').val('Your response has been submitted.');
		$('#_form-details-settings').find('._open-url').attr('hidden', true);
	}
	$('#_submission-url').on('click', function(){
		$('#_form-details-settings').find('._open-url').attr('hidden', false);
		$('#_form-details-settings').find('#submit-button-url').attr('disabled', false);
		$('#_form-details-settings').find('#submit-button-msg').attr('disabled', true);
	})

	$('#_submission-message').on('click', function(){
		$('#_form-details-settings').find('._open-url').attr('hidden', true);
		$('#_form-details-settings').find('#submit-button-url').attr('disabled', true);
		$('#_form-details-settings').find('#submit-button-msg').attr('disabled', false);
	})
});

// Update Settings From Element JSON

export function updateSettingsFromTextboxJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');
	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#textboxLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#textboxPlaceholder').val(selectedElementJSON['placeholder']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#textboxError').val(selectedElementJSON['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);
	if(selectedElementJSON.hasOwnProperty('minChar')) {
		$('#_single-element-settings').find('._single-element-settings-div').find('#textboxMinLength').siblings('label').addClass('active');
		$('#_single-element-settings').find('._single-element-settings-div').find('#textboxMinLength').val(selectedElementJSON['minChar']);
	}
	if(selectedElementJSON.hasOwnProperty('maxChar')) {
		$('#_single-element-settings').find('._single-element-settings-div').find('#textboxMaxLength').siblings('label').addClass('active');
		$('#_single-element-settings').find('._single-element-settings-div').find('#textboxMaxLength').val(selectedElementJSON['maxChar']);
	}
	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromNumberJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#numberLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#numberPlaceholder').val(selectedElementJSON['placeholder']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#numberError').val(selectedElementJSON['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);
	if(selectedElementJSON.hasOwnProperty('minChar')) {
		$('#_single-element-settings').find('._single-element-settings-div').find('#numberMinLength').siblings('label').addClass('active');
		$('#_single-element-settings').find('._single-element-settings-div').find('#numberMinLength').val(selectedElementJSON['minChar']);
	}
	if(selectedElementJSON.hasOwnProperty('maxChar')) {
		$('#_single-element-settings').find('._single-element-settings-div').find('#numberMaxLength').siblings('label').addClass('active');
		$('#_single-element-settings').find('._single-element-settings-div').find('#numberMaxLength').val(selectedElementJSON['maxChar']);
	}
	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromTextareaJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');
	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#textareaLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#textareaPlaceholder').val(selectedElementJSON['placeholder']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#textareaError').val(selectedElementJSON['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);
	if(selectedElementJSON.hasOwnProperty('minChar')) {
		$('#_single-element-settings').find('._single-element-settings-div').find('#textareaMinLength').siblings('label').addClass('active');
		$('#_single-element-settings').find('._single-element-settings-div').find('#textareaMinLength').val(selectedElementJSON['minChar']);
	}
	if(selectedElementJSON.hasOwnProperty('maxChar')) {
		$('#_single-element-settings').find('._single-element-settings-div').find('#textareaMaxLength').siblings('label').addClass('active');
		$('#_single-element-settings').find('._single-element-settings-div').find('#textareaMaxLength').val(selectedElementJSON['maxChar']);
	}
	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromNameJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#textboxLabel1').val(selectedElementJSON['fields'][0]['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#textboxLabel2').val(selectedElementJSON['fields'][1]['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#textboxPlaceholder1').val(selectedElementJSON['fields'][0]['placeholder']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#textboxPlaceholder2').val(selectedElementJSON['fields'][1]['placeholder']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#textboxError1').val(selectedElementJSON['fields'][0]['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#textboxError2').val(selectedElementJSON['fields'][1]['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);
	if(selectedElementJSON.hasOwnProperty('hasParent')) $('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, '');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromChoiceJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');
	var selectedElementChildIndex = $(selectedElement).data('childindex');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);


	for(var i = 0; i < selectedElementJSON['newChoiceOptions'].length; i++) {
		var newChoice = $('<div class="col-md-12 _choice-option _new-choice-option"><label for="choicePlaceholder' + String(i + 2) + '" class="active is-imp control-label">Option ' + String(i + 2) + ':</label><div class="_new-choice-option-input-container"><input class="browser-default form-control " id="choicePlaceholder' + String(i + 2) + '" name="choicePlaceholder" required="" type="text" value="' + selectedElementJSON['newChoiceOptions'][i] + '"><div class="_add-choice-option"><a class="btn-floating waves-effect waves-light btn" onclick="deleteThisChoice(this, '+selectedElementChildIndex+', '+elementIndex+')"><i class="material-icons">delete</i></a></div></div></div>');
		$(newChoice).insertBefore($('#_single-element-settings').find('._single-element-settings-div').find('._add-choice-option-div'));
	}

	$('#_single-element-settings').find('._single-element-settings-div').find('#choiceLabel').val(selectedElementJSON['choiceLabel']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#choicePlaceholder1').val(selectedElementJSON['choicePlaceholder1']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('select#choiceType').val(selectedElementJSON['choiceType']);
	if(selectedElementJSON.hasOwnProperty('hasParent')) $('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromLocationJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');
	var selectedElementChildIndex = $(selectedElement).data('childindex');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#locationLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('input[type="radio"]').each(function(){
		if($(this).val() == selectedElementJSON['locationType']) $(this).prop('checked', true);
	});
	if(selectedElementJSON.hasOwnProperty('hasParent')) $('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromDistanceJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');
	var selectedElementChildIndex = $(selectedElement).data('childindex');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#distanceLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	if(selectedElementJSON.hasOwnProperty('hasParent')) $('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromPaymentJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');
	var selectedElementJSONattr = $(selectedElement).attr('data-json');
	var selectedElementChildIndex = $(selectedElement).data('childindex');

	if(typeof selectedElementJSONattr === 'string') selectedElementJSONattr = JSON.parse(selectedElementJSONattr);


	for(var i = 0; i < selectedElementJSONattr['newpaymentOptions'].length; i++) {
		var newProduct = $('<div class="col-md-12 product-container _new-payment-option"><div class="col-md-6 _payment-option" style="padding: 0px;"><label for="productLabel' + String(i + 2) + '" class="active is-imp control-label">Enter Product ' + String(i + 2) + ':</label><input id="productLabel ' + String(i + 2) + '" class="validate browser-default form-control ' + String(i + 2) + '" name="productLabel' + String(i + 2) + '" required="" type="text" value="' + selectedElementJSONattr['newpaymentOptions'][i].productLabel + '"></div><div class="col-md-6 _payment-value-option" style="padding: 0 0 0 10px;"><label for="productValue' + String(i + 2) + '" class="active control-label">Value:</label><input id="productValue'+ String(i + 2) + '" class="validate browser-default form-control ' + String(i + 2) + '" name="productValue' + String(i + 2) + '" required="" type="text" value="' + selectedElementJSONattr['newpaymentOptions'][i].productValue + '"></div><div class="col-md-12 _payment-url-option" style="padding: 0px;"><label for="productUrl' + String(i + 2) + '" class="active is-imp control-label">Payment gateway url:</label><input class="validate browser-default form-control" id="productUrl' + String(i + 2) + '" name="productUrl' + String(i + 2) + '" required="" type="text" value="' + selectedElementJSONattr['newpaymentOptions'][i].productUrl + '"></div><div class="_add-payment-option"><a class="btn-floating waves-effect waves-light btn" onclick="deleteThisProduct(this, ' + selectedElementChildIndex + ', ' + elementIndex + ')"><i class="material-icons">delete</i></a></div></div>');
		if(selectedElementJSONattr['newpaymentOptions'][i].enableQuantity) $(newProduct).find('input#enable' + (i + 2)).prop('checked', true);
		$(newProduct).insertBefore($('#_single-element-settings').find('._single-element-settings-div').find('._add-payment-option-div'));
	}
	$('#_single-element-settings').find('._single-element-settings-div').find('#paymentLabel').val(selectedElementJSONattr['paymentLabel']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#productLabel1').val(selectedElementJSONattr['productLabel1']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#productValue1').val(selectedElementJSONattr['productValue1']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#productUrl1').val(selectedElementJSONattr['productUrl1']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSONattr['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#paymentError').val(selectedElementJSONattr['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSONattr['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSONattr['hasParent']);
	if(selectedElementJSONattr.hasOwnProperty('hasParent')) $('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSONattr['hasParent']);

	checkIfCanBeConditional(elementIndex, '');

	if(selectedElementJSONattr['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

function addNewChoice() {
	var index = $('#_single-element-settings').find('._single-element-settings-div').find('._choice-option').length;
	var newChoice = $('<div class="col-md-12 _choice-option _new-choice-option"><label for="choicePlaceholder' + index + '" class="active is-imp control-label">Option ' + String(index + 1) + ':</label><div class="_new-choice-option-input-container"><input class="browser-default form-control ' + index + '" name="choicePlaceholder" required="" type="text" value="Option ' + String(index + 1) + '"><div class="_add-choice-option"><a class="btn-floating waves-effect waves-light btn" onclick="deleteThisChoice(this)"><i class="material-icons">delete</i></a></div></div></div>');
	$(newChoice).insertBefore($('#_single-element-settings').find('._single-element-settings-div').find('._add-choice-option-div'));
}

function addNewProduct() {
	var index = $('#_single-element-settings').find('._single-element-settings-div').find('._payment-option').length;
	var newProduct = $('<div class="col-md-12 product-container _new-payment-option"><div class="col-md-6 _payment-option" style="padding: 0px;"><label for="productLabel' + index + '" class="active is-imp control-label">Enter Product ' + String(index + 1) + ':</label><input id="productLabel ' + index + '" class="validate browser-default form-control ' + index + '" name="productLabel' + index + '" required="" type="text" value="Product ' + String(index + 1) + '"></div><div class="col-md-6 _payment-value-option" style="padding: 0 0 0 10px;"><label for="productValue' + index + '" class="active control-label">Value:</label><input id="productValue'+ index + '" class="validate browser-default form-control ' + index + '" name="productValue' + index + '" required="" type="text" value=""></div><div class="col-md-12 _payment-url-option" style="padding: 0px;"><label for="productUrl' + index + '" class="active is-imp control-label">Payment gateway url:</label><input class="validate browser-default form-control" id="productUrl' + index + '" name="productUrl' + index + '" required="" type="text" value="http://www.example.com/"></div><div class="_add-payment-option"><a class="btn-floating waves-effect waves-light btn" onclick="deleteThisProduct(this)"><i class="material-icons">delete</i></a></div></div>');
	$(newProduct).insertBefore($('#_single-element-settings').find('._single-element-settings-div').find('._add-payment-option-div'));

}

function deleteThisProduct(selector, selectedElementChildIndex, elementIndex) {
	if(selectedElementChildIndex !== undefined) {
		var childElement = $('._form-element[data-index='+selectedElementChildIndex+']');
		var childElementJSON = childElement.data('json');
		childElementJSON = JSON.parse(childElementJSON);
		var childElementLabel = childElementJSON['label'];
		var currentElement = $('._form-element[data-index='+ elementIndex +']');

		swal({
			title: 'Are you sure?',
			html: "<h5>Deleting this will also delete!</h5><h6>"+ childElementLabel +"</h6>",
			showCancelButton: true,
			confirmButtonText: 'Yes, Delete!',
			cancelButtonText: 'No, Cancel!',
			type: "warning"
		}).then(function () {
			var parentOfSelector = $(selector).closest('._single-element-settings-div');
			$(selector).parent().parent().remove();
			$(currentElement).removeAttr('data-childindex');
			$(currentElement).removeAttr('data-childanswer');
			$(currentElement).removeAttr('data-isparent');

			$(parentOfSelector).find('#saveBtn').trigger('click');

			$(childElement).remove();

		}).catch(swal.noop);
	} else {
		$(selector).parent().parent().remove();
	}
}

function deleteThisChoice(selector, selectedElementChildIndex, elementIndex) {
	if(selectedElementChildIndex !== undefined) {
		var childElement = $('._form-element[data-index='+selectedElementChildIndex+']');
		var childElementJSON = childElement.data('json');
		childElementJSON = JSON.parse(childElementJSON);
		var childElementLabel = childElementJSON['label'];
		var currentElement = $('._form-element[data-index='+ elementIndex +']');

		swal({
			title: 'Are you sure?',
			html: "<h5>Deleting this will also delete!</h5><h6>"+ childElementLabel +"</h6>",
			showCancelButton: true,
			confirmButtonText: 'Yes, Delete!',
			cancelButtonText: 'No, Cancel!',
			type: "warning"
		}).then(function () {
			var parentOfSelector = $(selector).closest('._single-element-settings-div');
			$(selector).parent().parent().parent().remove();
			$(currentElement).removeAttr('data-childindex');
			$(currentElement).removeAttr('data-childanswer');
			$(currentElement).removeAttr('data-isparent');

			$(parentOfSelector).find('#saveBtn').trigger('click');

			$(childElement).remove();

		}).catch(swal.noop);
	} else {
		$(selector).parent().parent().parent().remove();
	}
}

export function updateSettingsFromYesnoJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#yesnoLabel').val(selectedElementJSON['yesnoLabel']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#yesnoPlaceholder1').val(selectedElementJSON['yesnoPlaceholder1']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#yesnoPlaceholder2').val(selectedElementJSON['yesnoPlaceholder2']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);
	if(selectedElementJSON.hasOwnProperty('hasParent')) $('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromCoRelationJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	fetch(window.formNamesUrl,{
		'method': 'GET',
		'headers': {
			'Authorization': window.token
		}
	}).then(function(data) { return data.json() })
	.then(function(data) {
		if(data.status === "ok"){
			var allForms = data.data;
			var formTitleElements = '';
			for(var i = 0; i < allForms.length; i++){
				if(selectedElementJSON['formId'] !== '' && selectedElementJSON['formId'] == allForms[i]['_id']){
					formTitleElements += '<option value="'+ allForms[i]['_id'] +'" selected>'+ allForms[i]['form_details']['title'] + '</option>';
					fetch(window.formElementsUrl + selectedElementJSON['formId'], {
						'method': 'GET',
						'headers': {
							'Authorization': window.token
						}
					}).then(function(data) { return data.json() })
					.then(function(data) {
						if(data.status == 'ok'){
							var allElements = data.data.form_element;
							var elementsTitle = '';
							var elementLabel = '';
							if(allElements.length){
								for(var i = 0; i < allElements.length; i++){
									if(allElements[i]['type'] !== 'payment' && allElements[i]['type'] !== 'choice' && allElements[i]['type'] !== 'yesno' && allElements[i]['type'] !== 'correlation'){
										if(allElements[i]['type'] == 'name') elementLabel = 'Name';
										else elementLabel = allElements[i]['label'];
										if(selectedElementJSON['selectedRelationalElement'] !== '' && selectedElementJSON['selectedRelationalElement'] == allElements[i]['question_id']){
											elementsTitle += '<option value="'+ allElements[i]['question_id'] +'" selected>'+ elementLabel + '</option>';
										} else {
											elementsTitle += '<option value="'+ allElements[i]['question_id'] +'">'+ elementLabel + '</option>';
										}
									}
								}
								$('#_single-element-settings').find('._single-element-settings-div').find('#relationColumns').empty();
								$('#_single-element-settings').find('._single-element-settings-div').find('#relationColumns').last().append(elementsTitle);
							}
							fetch(window.formResponsesUrl + selectedElementJSON['formId'] + '/' + selectedElementJSON['selectedRelationalElement'], {
								'method': 'GET',
								'headers': {
									'Authorization': window.token
								}
							}).then(function(data) { return data.json() })
							.then(function(data) {
								if(data.status == 'ok'){
									var answerList = data.data;
									var anserlistString = JSON.stringify(answerList)
									var elementsResponses = '';
									elementsResponses += '<option value="'+ selectedElementJSON['relationPlaceholder'] +'" disabled>'+ selectedElementJSON['relationPlaceholder'] + '</option>';
									for(var i=0; i < answerList.length; i++){
										if(answerList[i] !== ''){
											elementsResponses += '<option value="'+ answerList[i] +'" selected>'+ answerList[i] + '</option>';
										}
									}
									$('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').find('._relation-container').find('._relation').find('select#relationSelect').last().empty();
									$('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').find('._relation-container').find('._relation').find('select#relationSelect').last().append(elementsResponses);
									$('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').attr('data-answerList', anserlistString);
								}
							})
						}
					})
				} else {
					formTitleElements += '<option value="'+ allForms[i]['_id'] +'">'+ allForms[i]['form_details']['title'] + '</option>';
				}
			}
			$('#_single-element-settings').find('._single-element-settings-div').find('#relationForm').last().append(formTitleElements);
		}
	});

	var newFormId = selectedElementJSON['formId'];
	$('#_single-element-settings').find('._single-element-settings-div').find('#relationForm').on('change', function(){
		newFormId = this.value;
		fetch(window.formElementsUrl + this.value, {
			'method': 'GET',
			'headers': {
				'Authorization': window.token
			}
		}).then(function(data) { return data.json() })
		.then(function(data) {
			if(data.status == 'ok'){
				var allElements = data.data.form_element;
				var elementsTitle = '';
				var elementLabel = '';
				if(allElements.length){
					for(var i = 0; i < allElements.length; i++){
						if(allElements[i]['type'] !== 'payment' && allElements[i]['type'] !== 'choice' && allElements[i]['type'] !== 'yesno' && allElements[i]['type'] !== 'correlation'){
							if(allElements[i]['type'] == 'name') elementLabel = 'Name';
							else elementLabel = allElements[i]['label'];
							elementsTitle += '<option value="'+ allElements[i]['question_id'] +'">'+ elementLabel + '</option>';
						}
					}
				}
				$('#_single-element-settings').find('._single-element-settings-div').find('#relationColumns').empty();
				$('#_single-element-settings').find('._single-element-settings-div').find('#relationColumns').last().append(elementsTitle);
				if(allElements.length == 1){
					fetch(window.formResponsesUrl + newFormId + '/' + allElements[0]['question_id'], {
						'method': 'GET',
						'headers': {
							'Authorization': window.token
						}
					}).then(function(data) { return data.json() })
					.then(function(data) {
						if(data.status == 'ok'){
							var answerList = data.data;
							var anserlistString = JSON.stringify(answerList)
							var elementsResponses = '';
							elementsResponses += '<option value="'+ selectedElementJSON['relationPlaceholder'] +'" disabled>'+ selectedElementJSON['relationPlaceholder'] + '</option>';
							for(var i=0; i < answerList.length; i++){
								if(answerList[i] !== ''){
									elementsResponses += '<option value="'+ answerList[i] +'" selected>'+ answerList[i] + '</option>';
								}
							}
							$('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').find('._relation-container').find('._relation').find('select#relationSelect').last().empty();
							$('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').find('._relation-container').find('._relation').find('select#relationSelect').last().append(elementsResponses);
							$('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').attr('data-answerList', anserlistString);
						}
					})
				}
			}
		})
	})
	$('#_single-element-settings').find('._single-element-settings-div').find('#relationColumns').on('change', function(){
		fetch(window.formResponsesUrl + newFormId + '/' + this.value, {
			'method': 'GET',
			'headers': {
				'Authorization': window.token
			}
		}).then(function(data) { return data.json() })
		.then(function(data) {
			if(data.status == 'ok'){
				var answerList = data.data;
				var anserlistString = JSON.stringify(answerList)
				var elementsResponses = '';
				elementsResponses += '<option value="'+ selectedElementJSON['relationPlaceholder'] +'" disabled>'+ selectedElementJSON['relationPlaceholder'] + '</option>';
				for(var i=0; i < answerList.length; i++){
					if(answerList[i] !== ''){
						elementsResponses += '<option value="'+ answerList[i] +'" selected>'+ answerList[i] + '</option>';
					}
				}
				$('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').find('._relation-container').find('._relation').find('select#relationSelect').last().empty();
				$('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').find('._relation-container').find('._relation').find('select#relationSelect').last().append(elementsResponses);
				$('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').attr('data-answerList', anserlistString);
			}
		})
	})

	$('#_single-element-settings').find('._single-element-settings-div').find('#relationLabel').val(selectedElementJSON['relationLabel']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#relationPlaceholder').val(selectedElementJSON['relationPlaceholder']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#relationForm').val(selectedElementJSON['formId']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#relationColumns').val(selectedElementJSON['selectedRelationalElement']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#selectMultiple').prop('checked', selectedElementJSON['selectMultiple']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#answerParent').prop('checked', selectedElementJSON['answerParent']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#relationError').val(selectedElementJSON['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	if(selectedElementJSON.hasOwnProperty('hasParent')) $('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromAddressJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#addressLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#pincodeLabel').val(selectedElementJSON['fields'][3]['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#areaLabel').val(selectedElementJSON['fields'][4]['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#streetLabel').val(selectedElementJSON['fields'][5]['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#pincodePlaceholder').val(selectedElementJSON['fields'][3]['placeholder']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#areaPlaceholder').val(selectedElementJSON['fields'][4]['placeholder']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#streetPlaceholder').val(selectedElementJSON['fields'][5]['placeholder']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#pincodeError').val(selectedElementJSON['fields'][3]['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#areaError').val(selectedElementJSON['fields'][4]['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#streetError').val(selectedElementJSON['fields'][5]['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#geoLocation').prop('checked', selectedElementJSON['geoLocation']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);
	if(selectedElementJSON.hasOwnProperty('hasParent')) $('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, '');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromEmailJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#emailLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#emailPlaceholder').val(selectedElementJSON['placeholder']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#emailError').val(selectedElementJSON['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromWebsiteJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#websiteLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#websitePlaceholder').val(selectedElementJSON['placeholder']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#websiteError').val(selectedElementJSON['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromCurrencyJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$.get('https://www.letsendorse.com/currencies').then(function(data) {
		for (var key in data) {
			if (data.hasOwnProperty(key)) {
				$('#_single-element-settings').find('._single-element-settings-div').find('select#currencySymbol').append('<option decimal="' + data[key]['decimal_digits'] + '" value="' + data[key]['symbol'] + '">' + data[key]['symbol'] + ' (' + data[key]['name'] + ')</option>');
			}
		}
	}).then(function() {
		$('#_single-element-settings').find('._single-element-settings-div').find('select#currencySymbol').val(selectedElementJSON['currencySymbol']);
	});
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#currencyLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromPhoneJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#phoneLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#phoneError').val(selectedElementJSON['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromDateJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#dateLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#dateError').val(selectedElementJSON['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromTimeJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#timeLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#timeError').val(selectedElementJSON['error']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromRatingJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#ratingLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#ratingCount').val(selectedElementJSON['ratingCount']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromScaleJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#scaleLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('input#scale-element').val(selectedElementJSON['scaleCount']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromSignatureJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');
	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#signatureLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromAreaJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');
	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#signatureLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromBarcodeJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#scaleLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('input#scale-element').val(selectedElementJSON['scaleCount']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);

	checkIfCanBeConditional(elementIndex, 'canBeParent');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromFileJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);
	var fileTypes = selectedElementJSON['fileType'].split(',');
	$('#_single-element-settings').find('._single-element-settings-div').find('#fileType').val(fileTypes);
	$('#_single-element-settings').find('._single-element-settings-div').find('#fileLabel').val(selectedElementJSON['label']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#helpTextLabel').val(selectedElementJSON['helpText']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#required').prop('checked', selectedElementJSON['required']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#enableGeoTag').prop('checked', selectedElementJSON['geoTag']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#hasParent').prop('checked', selectedElementJSON['hasParent']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#select-multiple').prop('checked', selectedElementJSON['multipleSelect']);

	checkIfCanBeConditional(elementIndex, '');

	if(selectedElementJSON['hasParent']) {
		var selectedElementParentJSON = $(selectedElement).data('parent');
		if(typeof selectedElementParentJSON === 'string') selectedElementParentJSON = JSON.parse(selectedElementParentJSON);
		readConditional(elementIndex, selectedElementParentJSON);
	}
}

export function updateSettingsFromHeaderJSON() {
	var selectedElement = $('._form-holder ._header-container').find('._form-element').first();
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#headerTitle').val(selectedElementJSON['headerTitle']);
	$('#_single-element-settings').find('._single-element-settings-div').find('#headerSubtitle').val(selectedElementJSON['headerSubtitle']);
	if(selectedElementJSON['headerBackground'] !== '') {
		$('#headerBackgroundPreview').show();
		$('#noBackground').show();
		$('#_single-element-settings').find('._single-element-settings-div').find('#headerBackgroundPreview').attr('src', selectedElementJSON['headerBackground']);
	} else {
		$('#headerBackgroundPreview').hide();
		$('#noBackground').hide();
	}
}

export function updateSettingsFromContentJSON(elementIndex) {
	var selectedElement = $('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']');
	var selectedElementJSON = $(selectedElement).data('json');

	if(typeof selectedElementJSON === 'string') selectedElementJSON = JSON.parse(selectedElementJSON);

	$('#_single-element-settings').find('._single-element-settings-div').find('#contentText').val(selectedElementJSON['contentText']);
}

export function updateDesignsFromLayoutJSON(element) {
	var selectedDesignData = $('._form-outlet').find('._form-holder').find('._form-designs').find('._form-designs-atr').attr('layout-json');
	if(typeof selectedDesignData === 'string') selectedDesignData = JSON.parse(selectedDesignData);
	if(selectedDesignData.hasOwnProperty['titleAlignment']){
		if(selectedDesignData['titleAlignment'] === 'Left') $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.left-align-div').addClass('active-align-item');
		if(selectedDesignData['titleAlignment'] === 'Center') $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.center-align-div').addClass('active-align-item');
		if(selectedDesignData['titleAlignment'] === 'Left') $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.right-align-div').addClass('active-align-item');
	}
	if(selectedDesignData.hasOwnProperty['inputFieldBorderRadius']){
		var brClass = 'borderradius' + selectedDesignData['inputFieldBorderRadius'];
		$('._form-holder').find('._element-container').find('input').each(function(){
			var classes = $(this).attr('class').split(' ')
			for(var i = 0; i < classes.length; i++){
				var currentClass = classes[i];
				if(currentClass.includes('borderradius')){
					$(this).removeClass(currentClass)
				}
			}
			$(this).addClass(brClass);
		})
		$('._form-holder').find('._element-container').find('textarea').each(function(){
			var classes = $(this).attr('class').split(' ')
			for(var i = 0; i < classes.length; i++){
				var currentClass = classes[i];
				if(currentClass.includes('borderradius')){
					$(this).removeClass(currentClass)
				}
			}
			$(this).addClass(brClass);
		})
	}
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.left-align-div').on('click', function(){
		if($('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.center-align-div').hasClass('active-align-item')) $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.center-align-div').removeClass('active-align-item');
		if($('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.right-align-div').hasClass('active-align-item')) $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.right-align-div').removeClass('active-align-item');
		$(this).addClass('active-align-item');
		$('._form-holder').find('._form-details').find('._form-name').find('._form-name-element').css('text-align', 'left');
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.center-align-div').on('click', function(){
		if($('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.left-align-div').hasClass('active-align-item')) $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.left-align-div').removeClass('active-align-item');
		if($('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.right-align-div').hasClass('active-align-item')) $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.right-align-div').removeClass('active-align-item');
		$(this).addClass('active-align-item');
		$('._form-holder').find('._form-details').find('._form-name').find('._form-name-element').css('text-align', 'center');
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.right-align-div').on('click', function(){
		if($('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.center-align-div').hasClass('active-align-item')) $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.center-align-div').removeClass('active-align-item');
		if($('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.left-align-div').hasClass('active-align-item')) $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-align-btn-container').find('.left-align-div').removeClass('active-align-item');
		$(this).addClass('active-align-item');
		$('._form-holder').find('._form-details').find('._form-name').find('._form-name-element').css('text-align', 'right');
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.layout-input-radius-container').find('.input-field-border-slider').find('.range-slider-container').find('input[type="range"]').on('change', function(){
		var brClass = 'borderradius' + $(this).val();
		$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.layout-input-radius-container').find('.input-field-border-slider').find('.range-slider-container').find('#input-border-value').text($(this).val() + ' px');
		$('._form-holder').find('._element-container').find('input').each(function(){
			var classes = $(this).attr('class').split(' ')
			for(var i = 0; i < classes.length; i++){
				var currentClass = classes[i];
				if(currentClass.includes('borderradius')){
					$(this).removeClass(currentClass)
				}
			}
			$(this).addClass(brClass);
		})
		$('._form-holder').find('._element-container').find('textarea').each(function(){
			var classes = $(this).attr('class').split(' ')
			for(var i = 0; i < classes.length; i++){
				var currentClass = classes[i];
				if(currentClass.includes('borderradius')){
					$(this).removeClass(currentClass)
				}
			}
			$(this).addClass(brClass);
		})
	})
}

export function updateDesignsFromBackgroundJSON(element) {
	var selectedDesignData = $('._form-outlet').find('._form-holder').find('._form-designs').find('._form-designs-atr').attr('background-json');
	if(typeof selectedDesignData === 'string') selectedDesignData = JSON.parse(selectedDesignData);
	if(selectedDesignData.hasOwnProperty('formBackgroundColor')) $('._form-outlet').find('._form-holder').css('background', selectedDesignData['formBackgroundColor']);
	if(selectedDesignData.hasOwnProperty('formBorderColor')) $('._form-outlet').find('._form-holder').css({'border-color': selectedDesignData['formBorderColor'], 'border-style': 'solid'});
	if(selectedDesignData.hasOwnProperty('formBorderRadius'))	$('._form-outlet').find('._form-holder').css('border-radius', selectedDesignData['formBorderRadius'] + 'px');
	if(selectedDesignData.hasOwnProperty('formBorderThickness')) $('._form-outlet').find('._form-holder').css({'border-width': selectedDesignData['formBorderThickness'], 'border-style': 'solid'});
	if(selectedDesignData.hasOwnProperty('inputFieldBackgroundColor')) {
		$('._form-holder').find('._element-container').find('input').each(function(){
			$(this).css('background', selectedDesignData['inputFieldBackgroundColor']);
		})
		$('._form-holder').find('._element-container').find('textarea').each(function(){
			$(this).css('background', selectedDesignData['inputFieldBackgroundColor']);
		})
	}
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.form-background-color-container').find('#form-background-color').on('change', function(){
		$('._form-outlet').find('._form-holder').css('background', $(this).val());
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.input-background-color-container').find('#input-background-color').on('change', function(){
		var colour = $(this).val();
		$('._form-holder').find('._element-container').find('input').each(function(){
			$(this).css('background', colour);
		})
		$('._form-holder').find('._element-container').find('textarea').each(function(){
			$(this).css('background', colour);
		})
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.form-border-container').find('#form-border-thickness').on('change', function(){
		$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.form-border-container').find('.form-border-slider').find('.range-slider-container').find('#form-border-thickness-value').text($(this).val() + ' px');
		$('._form-outlet').find('._form-holder').css({'border-width': $(this).val(), 'border-style': 'solid'});
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.form-border-color-container').find('#form-border-color').on('change', function(){
		$('._form-outlet').find('._form-holder').css({'border-color': $(this).val(), 'border-style': 'solid'});
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.form-border-radius-container').find('#form-border-radius').on('change', function(){
		$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.form-border-radius-container').find('.form-border-radius-slider').find('.range-slider-container').find('#form-border-radius-value').text($(this).val() + ' px');
		var radius = $(this).val() + 'px';
		$('._form-outlet').find('._form-holder').css('border-radius', radius);
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.form-width-container').find('#form-width').on('change', function(){
		$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.form-width-container').find('.form-width-slider').find('.range-slider-container').find('#form-width-value').text($(this).val() + ' %');
		var width = $(this).val() + '%';
		$('._form-outlet').find('._form-holder').css('width', width);
	})
}

export function updateDesignsFromFontsJSON(element) {
	var selectedDesignData = $('._form-outlet').find('._form-holder').find('._form-designs').find('._form-designs-atr').attr('fonts-json');
	if(typeof selectedDesignData === 'string') selectedDesignData = JSON.parse(selectedDesignData);
	if(selectedDesignData.hasOwnProperty('inputFontColor')) $('._form-holder').find('._element-container').find('input').css('color', selectedDesignData['inputFontColor'])
	if(selectedDesignData.hasOwnProperty('inputFontSize')) $('._form-holder').find('._element-container').find('input').css('font-size', selectedDesignData['inputFontColor'])
	if(selectedDesignData.hasOwnProperty('inputFontStyle')) $('._form-holder').find('._element-container').find('input').css('font-family', selectedDesignData['inputFontColor'])
	if(selectedDesignData.hasOwnProperty('labelFontColor')) $('._form-holder').find('._element-container').find('label').css('color', selectedDesignData['labelFontColor'])
	if(selectedDesignData.hasOwnProperty('labelFontSize')) $('._form-holder').find('._element-container').find('label').css('font-size', selectedDesignData['labelFontColor'])
	if(selectedDesignData.hasOwnProperty('labelFontStyle')) $('._form-holder').find('._element-container').find('label').css('font-family', selectedDesignData['labelFontColor'])
	if(selectedDesignData.hasOwnProperty('titleFontColor')) $('._form-holder').find('._element-container').find('._form-name-element').css('color', selectedDesignData['titleFontColor'])
	if(selectedDesignData.hasOwnProperty('titleFontSize')) $('._form-holder').find('._element-container').find('._form-name-element').css('font-size', selectedDesignData['titleFontColor'])
	if(selectedDesignData.hasOwnProperty('titleFontStyle')) $('._form-holder').find('._element-container').find('._form-name-element').css('font-family', selectedDesignData['titleFontColor'])
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-settings').find('#title-font-color').on('change', function(){
		$('._form-holder').find('._form-details').find('._form-name').find('._form-name-element').css('color', $(this).val());
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-settings').find('#title-fontstyle').on('change', function(){
		$('._form-holder').find('._form-details').find('._form-name').find('._form-name-element').css('font-family', $(this).val())
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.title-settings').find('#title-font-size').on('change', function(){
		$('._form-holder').find('._form-details').find('._form-name').find('._form-name-element').css('font-size', $(this).val())
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.label-text-settings').find('#label-font-color').on('change', function(){
		$('._form-holder').find('._element-container').find('label').css('color', $(this).val())
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.label-text-settings').find('#label-fontstyle').on('change', function(){
		$('._form-holder').find('._element-container').find('label').css('font-family', $(this).val())
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.label-text-settings').find('#label-font-size').on('change', function(){
		$('._form-holder').find('._element-container').find('label').css('font-size', $(this).val())
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.input-text-settings').find('#input-font-color').on('change', function(){
		$('._form-holder').find('._element-container').find('input').css('color', $(this).val())
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.input-text-settings').find('#input-fontstyle').on('change', function(){
		$('._form-holder').find('._element-container').find('input').css('font-family', $(this).val())
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.input-text-settings').find('#input-font-size').on('change', function(){
		$('._form-holder').find('._element-container').find('input').css('font-size', $(this).val())
	})
}

export function updateDesignsFromButtonsJSON(element) {
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.left-align-div').on('click', function(){
		if($('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.center-align-div').hasClass('active-align-item')) $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.center-align-div').removeClass('active-align-item');
		if($('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.right-align-div').hasClass('active-align-item')) $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.right-align-div').removeClass('active-align-item');
		$(this).addClass('active-align-item');
		$('._form-holder').find('.form-submit-btn-container').css('justify-content', 'flex-start');
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.center-align-div').on('click', function(){
		if($('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.left-align-div').hasClass('active-align-item')) $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.left-align-div').removeClass('active-align-item');
		if($('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.right-align-div').hasClass('active-align-item')) $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.right-align-div').removeClass('active-align-item');
		$(this).addClass('active-align-item');
		$('._form-holder').find('.form-submit-btn-container').css('justify-content', 'center');
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.right-align-div').on('click', function(){
		if($('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.center-align-div').hasClass('active-align-item')) $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.center-align-div').removeClass('active-align-item');
		if($('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.left-align-div').hasClass('active-align-item')) $('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-align-container').find('.left-align-div').removeClass('active-align-item');
		$(this).addClass('active-align-item');
		$('._form-holder').find('.form-submit-btn-container').css('justify-content', 'flex-end');
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-background-color-container').find('#button-background-color').on('change', function(){
		$('._form-outlet').find('._form-holder').find('.form-submit-btn-container').find('.form-submit-btn').css('background', $(this).val());
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-text-color-container').find('#button-text-color').on('change', function(){
		$('._form-outlet').find('._form-holder').find('.form-submit-btn-container').find('.form-submit-btn').css('color', $(this).val());
	})
	$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-border-radius-container').find('#button-border-radius').on('change', function(){
		$('#_single-element-design-settings').find('._single-element-design-settings-div').find('.button-border-radius-container').find('.button-border-radius-slider').find('.range-slider-container').find('#button-border-radius-value').text($(this).val() + ' px');
		var radius = $(this).val() + 'px';
		$('._form-outlet').find('._form-holder').find('.form-submit-btn-container').find('.form-submit-btn').css('border-radius', radius);
	})
}
