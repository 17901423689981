import $ from 'jquery';
import React, {Component} from 'react';
import { Button, Modal } from "react-bootstrap";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { WithContext as ReactTags } from 'react-tag-input';
import { ToastContainer, toast } from 'react-toastify';

export default class AddTestEmailsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
      tags: [],
      suggestions: [],
      delimiters: [188, 32]
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  handleDelete(i) {
    let tags = this.state.tags;
    tags.splice(i, 1);
    this.setState({tags: tags});
  }

  handleAddition(tag) {
    if(this.validateEmail(tag)) {
	    let tags = this.state.tags;
	    tags.push({
	      id: tags.length + 1,
	      text: tag
	    });
	    this.setState({tags: tags});
	  } else {
	  	this.notifyError('Please enter a valid Email Id!');
	  }
  }

  handleDrag(tag, currPos, newPos) {
    let tags = this.state.tags;
    tags.splice(currPos, 1);
    tags.splice(newPos, 0, tag);
    this.setState({ tags: tags });
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

	submitTestEmailModal() {
		let emails = [];
		for(var i = 0; i < this.state.tags.length; i++) {
			emails.push(this.state.tags[i].text);
		}
		this.props.submitTestEmailModal(emails);
	}

  notifyError = (text) => toast.error(text);

  componentDidMount() {
    var that = this;
    $('.add-test-emails-modal #test-emails-tags-input').focusout(function() {
      var tag = $(this).val();
      that.handleAddition(tag);
      if(that.validateEmail(tag)) $(this).val('');
    });
  }

	render() {
		return (
			<div className="add-test-emails-modal">
				<Modal show={this.props.showTestEmailModal} className="add-test-emails-modal"
          onHide={this.props.closeTestEmailModal} bsSize="large">
        	<ToastContainer position="top-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
          <Modal.Header>
            <Modal.Title className="modal-header-custom">
              Add Test Emails
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          	<MuiThemeProvider>
          		<ReactTags id="test-emails-tags-input" tags={this.state.tags}
                suggestions={this.state.suggestions}
                handleDelete={this.handleDelete}
                handleAddition={this.handleAddition}
                handleDrag={this.handleDrag}
                placeholder={'Add Test Emails(comma/space separated)'}
                delimiters={this.state.delimiters} />
          	</MuiThemeProvider>
          </Modal.Body>
          <div className="mb20 mt20 text-center">
            <Button className="mr10 btn-danger" onClick={this.props.closeTestEmailModal}>Cancel</Button>
            <Button onClick={this.submitTestEmailModal.bind(this)}>Send Test Email</Button>
          </div>
        </Modal>
			</div>
		);
	}
}