import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import moment from 'moment';

const readCookie = require('../cookie.js').readCookie;

export default class SingleCampaignDetails extends React.Component {
		constructor(props) {
				super(props)
				this.state = {
						name: '',
						subject: '',
						from_name: '',
						from_email: '',
						sender_domain: '',
						from_email_domain: '',
						body: '',
						campaign: null,
						campaignId: null,
						to: '',
						reply_to: '',
						campaignFiles: [],
						sentDate: null,
						attachments: [],
						stats: null,
						drip_campaign: null
				}
				this.getCampaign = this.getCampaign.bind(this);
		}

		componentWillMount() {
				this.setState({ campaignId: this.props.match.params['id'] });
				fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/' + this.props.match.params['name'], {
					method: "GET",
					headers: {
						'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
					}
				}).then(function(response) {
					return response.json();
				}).then(function(data) {
					console.log(data)
						if(data['campaign']['drip_campaign_id']) {
							this.getDripCampaign(data['campaign']['drip_campaign_id']);
						}

						let campaign_data = data['campaign'];

						fetch(process.env.REACT_APP_API_URL + '/extract-domain', {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
								'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
							},
							body: JSON.stringify({ domain: data['campaign']['sender_domain'] })
						}).then((response) => response.json())
						.then((data) => {
							let reply_to = '';
							if(campaign_data.hasOwnProperty('reply_to')) {
								if(campaign_data['reply_to']) {
									if(campaign_data['reply_to'].match('@') === null) reply_to = campaign_data['reply_to'] + '@' + data.domain;
									else reply_to = campaign_data['reply_to'];
								}
							}
							this.setState({ from_email_domain: data.domain, reply_to });
						});

						this.setState({
							name: data['campaign']['name'],
							subject: data['campaign']['subject'],
							from_name: data['campaign']['from_name'],
							from_email: data['campaign']['from_email'],
							body: data['campaign']['template_data']['body'],
							sender_domain: data['campaign']['sender_domain'],
							attachments: data['campaign']['attachments'],
							sentDate: data['campaign']['scheduled_datetime'],
							campaign: data['campaign']
						}, function() {
							this.getCampaign();
						});
				}.bind(this));
		}

		getDripCampaign(campaign_id) {
			fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/' + campaign_id, {
				method: "GET",
				headers: {
					'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
				}
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				this.setState({ drip_campaign: data['campaign'] });
			}.bind(this));
		}

		getCampaign() {
			var campaign = this.state.campaign;
			var target_donors = campaign['target_donors'];
			var target_groups = campaign['target_groups'];

			var to = '<span class="list-tag-name">';
			for(var i = 0; i < target_groups.length; i++) {
					if(i > 0 && i < target_groups.length - 1) to += ', ';
					to += target_groups[i]['name'];
			}
			if(campaign.hasOwnProperty('list_id') && campaign['list_id']) {
					if(target_groups.length) to += ' in ';
					to += '<span class="list-link" onclick="window.location.pathname=\'/dm/lists/' + campaign['list_id'] + '\'">' + campaign['list_name'] + '</span>';
			}
			to += '</span>';

			this.setState({ to });

			fetch(process.env.REACT_APP_API_URL + '/campaigns/' + this.props.ngo.ngo_id + '/overview-stats/' + campaign.id, {
				method: "GET",
				headers: {
					'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
				}
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				if(data.stats) {
					var stats = {};
					stats['sent'] = data['stats']['total']['sent'];
					stats['delivered'] = data['stats']['total']['delivered'];
					stats['opened'] = data['stats']['unique']['opened'];
					stats['clicked'] = data['stats']['unique']['clicked'];
					this.setState({ stats });
				}
			}.bind(this));
		}

		gotoDetailedStats() {
				let pathname = window.location.pathname;
				window.location.pathname = pathname + '/stats';
		}

		gotoList(list_id) {
				window.location.pathname = '/dm/lists/' + list_id;
		}

		render() {
				let backUrl = '';
				if(document.referrer && document.referrer !== '') {
			    if(document.referrer === window.location.href) backUrl = '/dm/campaigns';
			    else backUrl = document.referrer;
				} else backUrl = '/dm/campaigns';
				return (
						<div className="page-container single-campaign-details">
								<div>
										<span className="back">
												<Link to={backUrl} className="back-btn"><i className="fa fa-chevron-left"></i> Go to All campaigns</Link>
										</span>
								</div>
								{this.state.drip_campaign !== null && this.state.campaign.drip_campaign_id ? (
										<Row className="mb10 mt20 drip-details">
												<Col className="margin-col-50" md={10} mdOffset={1}>
														<span>
																{'This drip was sent on '}<date>{moment(this.state.campaign.scheduled_datetime).format('DD/MM/YYYY')}</date>{' to recipients of '}
																<a href={"/dm/campaigns/details/" + this.state.drip_campaign.id}>{this.state.drip_campaign.name}</a>
																{console.log(this.state.drip_campaign.id, 'name', this.state.drip_campaign.name)}
														</span>
														<br />
														<span><label>Trigger: </label>{this.state.campaign.drip_trigger.name}</span>
												</Col>
										</Row>
								) : (null)}
								<Row className="mb10 mt20">
										{(() => {
												if (this.state.stats !== null) {
														return (
																<Col className="margin-col-50" md={10} mdOffset={1}>
																		<Row className="campaignStats">
																				<p className="modal-sec-title">
																						Stats
																						<div className="btn detailed-stats-btn pull-right" onClick={this.gotoDetailedStats.bind(this)}>Show Detailed Stats</div>
																				</p>
																				<Col className="campaignStatsElement">
																						<label>Sent: </label>{this.state.stats.sent}
																				</Col>
																				<Col className="campaignStatsElement">
																						<label>Delivered: </label>{this.state.stats.delivered}
																				</Col>
																				<Col className="campaignStatsElement">
																						<label>Opened: </label>{this.state.stats.opened}
																				</Col>
																				<Col className="campaignStatsElement">
																						<label>Clicked: </label>{this.state.stats.clicked}
																				</Col>
																		</Row>
																</Col>
														);
												}
										})()}
								</Row>
								<Row className="mb10 mt10">
										<Col className="margin-col-50" md={10} mdOffset={1}>
												<p className="modal-sec-title">Details</p>
												<Row className="campaignDetails">
														<Col className="campaignDetailsElement">
																<label> Campaign Name: </label>{this.state.name}
														</Col>

														<Col className="campaignDetailsElement" >
																<label> Subject: </label>{this.state.subject}
														</Col>

														<Col className="campaignDetailsElement width50">
																<label> From Name: </label>{this.state.from_name}
														</Col>

														<Col className="campaignDetailsElement width50">
																<label> From Email: </label>{this.state.from_email + '@' + this.state.from_email_domain}
														</Col>

														{this.state.drip_campaign === null ? (
																<Col className="campaignDetailsElement to">
																		<label> To: </label>
																		<div className="campaign-to-div" dangerouslySetInnerHTML={{__html: this.state.to}}></div>
																</Col>
														) : (null)}

														{this.state.reply_to !== '' ? (
																<Col className="campaignDetailsElement">
																		<label> Reply To: </label>{this.state.reply_to}
																</Col>
														) : (null)}

														<Col className="campaignDetailsElement">
																<label> Body: </label>
																<div className="addCampaignBody" dangerouslySetInnerHTML={{__html: this.state.body}}></div>
														</Col>

														{(() => {
																if (this.state.attachments.length) {
																		return (
																				<Col className="campaignDetailsElement attachments">
																						<label> Attachments: </label>
																						<div>
																								{this.state.attachments.map((attachment, index) => {
																										return (
																												<a key={index} className="attachment" target="_blank" href={attachment}>Attachment {index+1}</a>
																										);
																								})}
																						</div>
																				</Col>
																		);
																}
														})()}

														<Col className="campaignDetailsElement">
																<label> Sent Date & Time: </label>{moment(this.state.sentDate).format('DD/MM/YYYY hh:mm A')}
														</Col>
												</Row>
										</Col>
								</Row>
						</div>
				);
		}
}