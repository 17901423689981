import { combineReducers } from 'redux'
import todos from './followups'
import visibilityFilter from './visibilityFilter'
import projectUpdates from "./impact.js";
const todoApp = combineReducers({
  todos,
  visibilityFilter,
  projectUpdates
})

export default todoApp

// const initialState = {
//   currentView: 'dashboard',
//   currentTab: 'dashboard'
// }
