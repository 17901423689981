import React, { Component } from "react";
import io from "socket.io-client";
import _ from 'lodash';
import moment from 'moment';
import { send } from "react-icons-kit/fa/send";
import SvgIcon from "react-icons-kit";
import IMSingleProjectSidebar from '../1-projects/IMSingleProjectSidebar';
import $ from 'jquery';

const readCookie = require('../cookie.js').readCookie;

export default class ParticularDiscussion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChat: false,
      corporate_name: null,
      sender_name: this.props.userData.first_name + " " + this.props.userData.last_name,
      _ngoId: this.props.misData.data._organisationId,
      _companyId: '',
      _projectInterest: '',
      _conversationId: '',
      discussion: '',
      messageArray: [],
      selectedProjectName: '',
      start_date: '',
      conversation_status: '',
      chat: [],
      message: "",
      user_pik: "http://bpic.588ku.com/element_origin_min_pic/17/09/19/28e7312d32356cabb08cb36d5d232492.jpg",
      showthreadsInfoCol: true,
      showStatusDropDown: false,
      socket: null,
      user: null,
      recent_chat: [],
      chatInfoColVisibility: false
    };
  }

  componentWillMount() {
    this.initSocket();

    fetch(process.env.REACT_APP_MIS_API_URL + "/chat/" + this.state._ngoId, {
      method: 'GET',
      headers: {
        'Auth': JSON.parse(readCookie('mis_data')).access_token
      }
    }).then(res => (res.json()))
    .then(res => {
      fetch(process.env.REACT_APP_MIS_API_URL + "/chat/ping/" + this.state._ngoId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Auth': JSON.parse(readCookie('mis_data')).access_token
        }
      }).then(response => (response.json()))
      .then(response => {
        this.setState({ messageArray: response.message });
        let conversation_array = [];
        _.map(response.message, (msg, index) => {
          let id_found = _.filter(res.message_unseen, (el, i) => (el._conversationId === msg._conversationId));
          conversation_array.push(id_found[0]);
        });
        let particular_proj = _.filter(conversation_array, (conv, index) => (conv._projectId === this.props.match.params.project_id))
        this.setState({ chat: particular_proj }, function() {
          this.startChat(particular_proj[0]);
        }.bind(this));
      }).catch(err => {
        // console.log("eerr", err);
      });
    }).catch(err => {
      // console.log("err", err);
    });
  }

  initSocket = () => {
    const socket = io(process.env.REACT_APP_MIS_API_URL)
    socket.on('connect', () => {
      let token = JSON.parse(readCookie('mis_data')).access_token;
      socket.emit('authentication', { token: token });
      socket.on('authenticated', function () {
        this.setState({ socket });

        let ngo_namespace = "NGO_" + this.state._ngoId;
        socket.on(ngo_namespace, (data) => {
          if(data._conversationId === this.state._conversationId) {
            let new_chat = this.state.recent_chat;
            new_chat.push(data);
            let uniq = _.uniq(new_chat, '_id');
            this.setState({ recent_chat: uniq, message: '' });
          }
          if(this.checkIfMessageReceived(data)) this.showNewMessageToast();
          else {
            var elem = $('.im-container.mis-content-container > .ChatMessageContainer > .chat > .chat-scroll')[0];
            elem.scrollTop = elem.scrollHeight;
          }
        });
      }.bind(this));
    });
  }

  checkIfMessageReceived(data) {
    if (data.length === undefined && data.hasOwnProperty('receiver') && data.receiver === JSON.parse(readCookie('mis_data')).data._organisationId) {
      return true;
    } else if(data.length) {
      var foundMessage = false;
      for(var i = 0; i < data.length; i++) {
        if(data[i].receiver === JSON.parse(readCookie('mis_data')).data._organisationId) foundMessage = true;
      }
      return foundMessage;
    } else return false;
  }

  showNewMessageToast() {
    $('.im-container.mis-content-container > .ChatMessageContainer > .chat').append(`
      <div class="new-message-below-toast">
        New Message Below
        <i class="fa fa-angle-double-down"></i>
      </div>`);

    $('.new-message-below-toast').click(function() {
      var elem = $('.im-container.mis-content-container > .ChatMessageContainer > .chat > .chat-scroll');
      $(elem).animate({ scrollTop: elem[0].scrollHeight }, 500);
      $(this).remove();
    });

    $('.im-container.mis-content-container > .ChatMessageContainer > .chat > .chat-scroll').scroll(function() {
      if(($(this)[0].scrollTop + $('.im-container.mis-content-container > .ChatMessageContainer > .chat').height()) === $(this)[0].scrollHeight) $('.new-message-below-toast').remove();
    });
  }

  typeYourMessage = e => {
    this.setState({ message: e.target.value });
  }

  toggleChatInfoColVisibility = e => {
    this.setState({ chatInfoColVisibility: !this.state.chatInfoColVisibility });
  }

  sendMessage(event) {
    if(this.state.message !== '') {
      let { socket } = this.state;
      let data = {
        "case": "saveLastChat",
        "_ngoId": this.state._ngoId,
        "_conversationId": this.state._conversationId,
        "_companyId": this.state._companyId,
        "discussion": this.state.message,
        "sender_name": this.state.sender_name,
        "sender": "NGO"
      }
      socket.emit("CHAT_HANDLERS", data);
      this.setState({ message: '' });
    }
  }

  sendMessageByEnterKey(event) {
    if (event.key === 'Enter' && this.state.message !== '') {
      let { socket } = this.state;
      let data = {
        "case": "saveLastChat",
        "_ngoId": this.state._ngoId,
        "_conversationId": this.state._conversationId,
        "_companyId": this.state._companyId,
        "discussion": this.state.message,
        "sender_name": this.state.sender_name,
        "sender": "NGO"
      }
      socket.emit("CHAT_HANDLERS", data);
      this.setState({ message: '' });
    }
  }


  startChat(event, data) {
    let corporate_name = _.find(this.state.messageArray, (msg, index) => (msg._conversationId === event._conversationId));
    this.setState({
      showChat: true,
      chatInfoColVisibility: false,
      recent_chat: [],
      _conversationId: event._conversationId,
      start_date: event.date,
      conversation_status: event.status,
      _projectInterest: event._projectId,
      _companyId: event._companyId,
      selectedProjectName: event.name,
      corporate_name: corporate_name.sender_name[0]
    });

    fetch(process.env.REACT_APP_MIS_API_URL + "/chat/read/" + this.state._ngoId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Auth': JSON.parse(readCookie('mis_data')).access_token
      },
      body: JSON.stringify({ "_conversationId": event._conversationId })
    }).then(res => (res.json()))
    .then(res => {
      this.setState({ recent_chat: res.data }, function() {
        $(document).ready(function() {
          var elem = $('.im-container.mis-content-container > .ChatMessageContainer > .chat > .chat-scroll')[0];
          elem.scrollTop = elem.scrollHeight;

          $('.im-container.mis-content-container > .ChatMessageContainer > .chat > .chat-scroll').scroll(function() {
            if(($(this)[0].scrollTop + $('.im-container.mis-content-container > .ChatMessageContainer > .chat').height()) === $(this)[0].scrollHeight) $('.new-message-below-toast').remove();
          });
        });
      });
    }).catch(err => {
      console.log("err", err);
    });
  }

  getChatTime(timestamp) {
    if(moment().format('DD/MM/YYYY') === moment.unix(timestamp / 1000).format('DD/MM/YYYY')) return moment.unix(timestamp / 1000).format('HH:mm a');
    else if(moment().format('YYYY') !== moment.unix(timestamp / 1000).format('YYYY')) return moment.unix(timestamp / 1000).format('DD/MM/YYYY HH:mm a');
    else return moment.unix(timestamp / 1000).format('DD/MM HH:mm a')
  }

  render() {
    return (
      <div className="im-container im-project-page im-project-overview-page">
        <IMSingleProjectSidebar selected='discussion' project_id={this.props.match.params.project_id} />
        <div className="im-container mis-content-container">
          <div className="threadsCol">
            <div className="projectTitleDiv">Corporates</div>
            <div className="col-md-12 threadDiv">
              <div className="col-md-12 NgoNameCol">
                {_.map(this.state.chat, (chat, index) => (
                  <div className="projectTitleContainer" style={{ cursor: "pointer" }} key={index + "chat"} onClick={this.startChat.bind(this, chat)}>
                    <div className="projectTitle">
                      <h4 className="NgoName">{chat.companyName}</h4>
                      {/*<span className="statusIndicators" />*/}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={this.state.chatInfoColVisibility ? "ChatMessageContainer" : "ChatMessageContainer chat-info-col-hidden"}>
            <div className="projectTitleDiv">
              <h3 className="projectTitle">
                {this.state.selectedProjectName}
              </h3>
            </div>
            <div className={this.state.showChat ? "chat" : "chat no-chat"}>
              <div className="chat-scroll">
                {this.state.showChat ? (null) : (
                  <div className="row user-message-incoming">
                    <p className="incoming-message">Click on any of the messages on the left side to begin your chat</p>
                  </div>
                )}
                {this.state.recent_chat.length === 0 ? (null) : (
                  _.map(this.state.recent_chat, (chat, index) => {
                    if (chat.sender === JSON.parse(readCookie('mis_data')).data._organisationId && chat.discussion) {
                      return (
                        <div className="row user-message-reply" key={index + "reply"}>
                          <div>
                            {this.state.recent_chat[index - 1] === undefined ? (
                              <p className="mb5" style={{ fontSize: 12 }}>{(chat.sender_name !== null || chat.sender_name !== undefined) ? chat.sender_name : "Anonymous"}</p>
                            ) : (
                              this.state.recent_chat[index - 1].sender !== chat.sender ? (
                                <p className="mb5" style={{ fontSize: 12 }}>{(chat.sender_name !== null || chat.sender_name !== undefined) ? chat.sender_name : "Anonymous"}</p>
                              ) : (null)
                            )}
                            <p className="reply-message">{chat.discussion}<span>{this.getChatTime(chat.time)}</span></p>
                          </div>
                        </div>
                      )
                    } else if (chat.discussion) {
                      return (
                        <div className="row user-message-incoming" key={index + "incoming"}>
                          <div>
                            {this.state.recent_chat[index - 1] === undefined ? (
                              <p className="mb5" style={{ fontSize: 12 }}>{(chat.sender_name !== null || chat.sender_name !== undefined) ? chat.sender_name : "Anonymous"}</p>
                            ) : (
                              this.state.recent_chat[index - 1].sender !== chat.sender ? (
                                <p className="mb5" style={{ fontSize: 12 }}>{(chat.sender_name !== null || chat.sender_name !== undefined) ? chat.sender_name : "Anonymous"}</p>
                              ) : (null)
                            )}
                            <p className="incoming-message">{chat.discussion}<span>{this.getChatTime(chat.time)}</span></p>
                          </div>
                        </div>
                      )
                    } else return null;
                  })
                )}
              </div>
            </div>

            {this.state.showChat ? (
              <div className="row chatForm">
                <div className="col-md-1 attach-button-col">
                  <button className="chat-attach" type="button"></button>
                </div>
                <div className="col-md-10 chat-input-col">
                  <input value={this.state.message} className="chat-input" type="text" placeholder="Enter your text...." ref="message" onChange={this.typeYourMessage} onKeyPress={this.sendMessageByEnterKey.bind(this)} />
                </div>
                <div className="col-md-1 chat-button-col">
                  <button className="chat-send" type="button" onClick={this.sendMessage.bind(this)}>
                    <SvgIcon className="sendIcon" size={25} icon={send} />
                  </button>
                </div>
              </div>
            ) : (null)}
          </div>

          {this.state.showthreadsInfoCol ? (
            <div className={this.state.chatInfoColVisibility ? "threadsInfoCol" : "threadsInfoCol hide-info"}>
              <div className="chat-info-col-toggler-container" onClick={this.toggleChatInfoColVisibility.bind(this)} title={this.state.chatInfoColVisibility ? (null) : "Show NGO Details"}>
                <i className={this.state.chatInfoColVisibility ? "fa fa-chevron-right" : "fa fa-chevron-right inverted"}></i>
              </div>

              <h3 className="projectTitleinfo">{this.state.corporate_name}</h3>
              <hr className="infoDevider" />
              <div className="projectInfoContainer">
                {this.state.showChat ? (
                  <div className="projectInfo">
                    <span className="bulletPoints" />
                    <h4 className="MessageExchanged">
                      {this.state.recent_chat.length} Messages Exchanged
                    </h4>
                  </div>
                ) : (null)}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}