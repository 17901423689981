import React, { Component } from 'react';
import {Col} from 'react-bootstrap';
import Multiselect from 'react-bootstrap-multiselect';
import $ from 'jquery';

import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';

export default class TaskFilters extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignedForData: [],
			assignedToData: [],
			creatorData: [],
			assignedForValue: [],
			assignedToValue: [],
			creatorValue: []
		}
	}

	handleChange(name, value) {
		this.setState({ [name]: $(value).parents('select').val() });
	}

	componentDidMount() {
		let data = this.props.data;
		let assignedForDataObj = {};
		let assignedToUsersDataObj = {};
		let assignedToGroupsDataObj = {};
		let creatorDataObj = {};

		for(var i = 0; i < data.length; i++) {
			if(data[i].assigned_for.hasOwnProperty('users')) {
				for(var j = 0; j < data[i].assigned_for.users.length; j++) {
					if(!assignedForDataObj.hasOwnProperty(data[i].assigned_for.users[j].id)) {
						assignedForDataObj[data[i].assigned_for.users[j].id] = {};
						assignedForDataObj[data[i].assigned_for.users[j].id]['value'] = data[i].assigned_for.users[j].id;
						assignedForDataObj[data[i].assigned_for.users[j].id]['label'] = data[i].assigned_for.users[j].name;
					}
				}
			}
			if(data[i].assigned_to.hasOwnProperty('users')) {
				for(j = 0; j < data[i].assigned_to.users.length; j++) {
					if(!assignedToUsersDataObj.hasOwnProperty(data[i].assigned_to.users[j].id)) {
						assignedToUsersDataObj[data[i].assigned_to.users[j].id] = {};
						assignedToUsersDataObj[data[i].assigned_to.users[j].id]['value'] = data[i].assigned_to.users[j].id;
						assignedToUsersDataObj[data[i].assigned_to.users[j].id]['label'] = data[i].assigned_to.users[j].name;
					}
				}
			}
			if(data[i].assigned_to.hasOwnProperty('groups')) {
				for(j = 0; j < data[i].assigned_to.groups.length; j++) {
					if(!assignedToGroupsDataObj.hasOwnProperty(data[i].assigned_to.groups[j].id)) {
						assignedToGroupsDataObj[data[i].assigned_to.groups[j].id] = {};
						assignedToGroupsDataObj[data[i].assigned_to.groups[j].id]['value'] = data[i].assigned_to.groups[j].id;
						assignedToGroupsDataObj[data[i].assigned_to.groups[j].id]['label'] = data[i].assigned_to.groups[j].name;
					}
				}
			}
			if(!creatorDataObj.hasOwnProperty(data[i].creator_user_id.id)) {
				creatorDataObj[data[i].creator_user_id.id] = {};
				creatorDataObj[data[i].creator_user_id.id]['value'] = data[i].creator_user_id.id;
				creatorDataObj[data[i].creator_user_id.id]['label'] = data[i].creator_user_id.name;
			}
		}
		
		this.setState({ assignedForData: Object.values(assignedForDataObj), creatorData: Object.values(creatorDataObj) });

		let assignedToData = [];
		if(Object.values(assignedToUsersDataObj).length) {
			let assignedToUsersData = {};
			assignedToUsersData['label'] = 'Users';
			assignedToUsersData['children'] = Object.values(assignedToUsersDataObj);
			assignedToData.push(assignedToUsersData);
		}

		if(Object.values(assignedToGroupsDataObj).length) {
			let assignedToGroupsData = {};
			assignedToGroupsData['label'] = 'Groups';
			assignedToGroupsData['children'] = Object.values(assignedToGroupsDataObj);
			assignedToData.push(assignedToGroupsData);
		}

		this.setState({ assignedToData });
	}

	resetFilters() {
		this.setState({ assignedForValue: '', assignedToValue: '', creatorValue: '' }, function() {
			this.setState({ assignedForValue: [], assignedToValue: [], creatorValue: [] });
		}.bind(this));
		this.props.resetFilters();
		$('select#assignedForData').val([]);
		$('select#assignedToData').val([]);
		$('select#creatorData').val([]);
	}

	render() {
		return (
			<Col md={12} className="task-filters">
				{this.state.assignedForValue !== '' ? (
					<Col md={3}>
						<label className="filter-label">Assigned For :</label>
						<Multiselect id="assignedForData" onChange={this.handleChange.bind(this, 'assignedForValue')} enableCaseInsensitiveFiltering enableFiltering
		          buttonWidth={'100%'} data={this.state.assignedForData} multiple numberDisplayed={3} value={this.state.assignedForValue} />
					</Col>
				) : (null)}
				{this.state.assignedToValue !== '' ? (
					<Col md={3}>
						<label className="filter-label">Assigned To :</label>
		        <Multiselect id="assignedToData" onChange={this.handleChange.bind(this, 'assignedToValue')} enableCaseInsensitiveFiltering enableFiltering
		          buttonWidth={'100%'} data={this.state.assignedToData} multiple numberDisplayed={3} value={this.state.assignedToValue} />
					</Col>
	      ) : (null)}
        {this.state.creatorValue !== '' ? (
					<Col md={3}>
						<label className="filter-label">Creator :</label>
	        	<Multiselect id="creatorData" onChange={this.handleChange.bind(this, 'creatorValue')} enableCaseInsensitiveFiltering enableFiltering
	          	buttonWidth={'100%'} data={this.state.creatorData} multiple numberDisplayed={3} value={this.state.creatorValue} />
					</Col>
        ) : (null)}
				<Col md={3} className="filter-buttons">
					<button type='button' className={'btn btn-success react-bs-table-add-btn'}
						disabled={this.state.assignedForValue.length || this.state.assignedToValue.length || this.state.creatorValue.length ? (false) : (true)}
						onClick={this.props.applyFilters.bind(this, this.state.assignedForValue, this.state.assignedToValue, this.state.creatorValue)}>
						<span>Apply</span>
					</button>
					<button type='button' className={'btn btn-success react-bs-table-add-btn'} onClick={this.resetFilters.bind(this)}>
						<span>Reset</span>
					</button>
				</Col>
			</Col>
		)
	}
}