import React, { Component } from "react";
import { Modal, Row } from "react-bootstrap";

import AddFollow from "./AddFollow";

const readCookie = require('../../cookie.js').readCookie;

export default class AddFollowModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/tasks/'+ this.props.ngo['ngo_id'], {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ data });
    }.bind(this))
  }

  render() {
    return (
      <div className="page-container add-task-page">
        {this.props.showFollowModal ? (
          <div>
            <h2 className="modal-header-custom">
              Add Task
            </h2>
            <Row>
              <AddFollow
                id={this.props.id}
                onFollowClose={this.props.onFollowClose}
                ngo={this.props.ngo}
                donor_id={this.props.donor_id ? this.props.donor_id : undefined}
                group_id={this.props.group_id ? this.props.group_id : undefined}
                userEmail={this.props.userData.email}
                projectData={this.state.projectData}
              />
            </Row>
          </div>
        ) : (null)}
      </div>
    );
  }
}
