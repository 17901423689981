import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
// import moment from 'moment';
// import TimeAgo from 'react-timeago';
import socketIOClient from "socket.io-client";
// import InfiniteScroll from 'react-infinite-scroll-component';

const socket = socketIOClient(process.env.REACT_APP_MIS_API_URL);
const readCookie = require('../cookie.js').readCookie;

export default class IMTopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: false,
      badge: false,
      socket: null,
      messages: [],
      badgeCounts: 0,
      showBadge: true,
      selectedTab: '',
      notifications: [],
      loader: false,
      tracks: [],
      hasMoreItems: false,
      nextHref: null,
      totalNotifications: 0,
      perPage: 4,
      page: 1
    };
  }

  selectedLink = (tab) => {
    this.setState({ selectedTab: tab });
  }

  componentWillMount() {
    let that = this;
    if (!sessionStorage.length || !sessionStorage.getItem('notificationFetched')) {
      // Ask other tabs for session storage
      localStorage.setItem('getSessionStorage', Date.now());
    };
    window.addEventListener('storage', function (event) {
      if (event.key === 'getSessionStorage') {
        // Some tab asked for the sessionStorage -> send it
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
        localStorage.removeItem('sessionStorage');
      } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
        // sessionStorage is empty -> fill it
        var data = JSON.parse(event.newValue);
        for (event.key in data) {
          sessionStorage.setItem("key", data[event.key]);
        }
        showSessionStorage();
      }
    });
    function showSessionStorage() {
      if (!sessionStorage.length || !sessionStorage.getItem('notificationFetched')) {
        console.log("Setting token");
        fetch(process.env.REACT_APP_MIS_API_URL + '/notifications?perPage=' + that.state.perPage, {
          method: "GET",
          headers: {
            'Auth': JSON.parse(readCookie('mis_data')).access_token
          }
        }).then(res => res.json())
          .then(res => {
            if (res.status === "ok") {
              sessionStorage.setItem('notificationFetched', true);
              sessionStorage.setItem('notificationCount', res.data.unseen);
              if (sessionStorage.getItem('notificationCount') > 0) {
                that.setState({ badgeCounts: sessionStorage.getItem('notificationCount') })
              }
            }
          })
      } else {
        if (sessionStorage.getItem('notificationCount') > 0) {
          that.setState({ badgeCounts: sessionStorage.getItem('notificationCount') })
        }
      }
    }
    window.addEventListener('load', function () {
      showSessionStorage();
    })
    let token = JSON.parse(readCookie('mis_data')).access_token;
    socket.on('connect', function () {
      socket.emit('authentication', { token: token });
      socket.on('authenticated', function () {
        socket.on('notificationsCount', (count) => {
          that.setState({ badgeCounts: count.count });
          sessionStorage.setItem('notificationCount', count.count);
        });
      });
    });
  }


  showNotifications() {
    fetch(process.env.REACT_APP_MIS_API_URL + "/notifications/mark-seen", {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('mis_data')).access_token
      }
    }).then(data => data.json()).then(data => {
      console.log(data);
    })
    sessionStorage.setItem('notificationCount', 0);
    window.location.pathname = '/im/projects/notifications';
  }

  render() {
    return (
      <div className="im-top-menu">
        <Row className="full-width">
          <div className="current_user">
            <Link to={"/im/settings"} className="loggedin_user">
              {(() => {
                if (this.props.userData["profile_picture"] === "") {
                  return (
                    <img alt={this.props.userData["first_name"]} src="/images/user.png" className="img-circle" />
                  );
                } else {
                  return (
                    <img alt={this.props.userData["first_name"]} src={this.props.userData["profile_picture"]} onError={event => event.target.setAttribute("src", "/images/user.png")} />
                  );
                }
              })()}
            </Link>
          </div>
          <div className="menu-items">
            <div className="menu-item-container">
              <Link to={"/"} className="menu-item home">
                Home
              </Link>
            </div>
            <div className="menu-item-container">
              <Link to={"/im/projects"} className="menu-item projects">
                Projects
              </Link>
            </div>
            <div className="menu-item-container">
              <Link to={"/im/partnerships"} className="menu-item partnerships">
                My Partnerships
              </Link>
            </div>
            <div className="logout-user-button-item"
              style={{
                top: '8px',
                right: '100px',
                fontWeight: 600,
                cursor: 'pointer',
                padding: '10px 0px',
                position: 'absolute'
              }}
              onClick={this.props.logoutUser}>
              <p><i className="fa fa-power-off"></i> Logout</p>
            </div>

            {/*<div className="menu-item-container">
              <Link to={"/im/projects"} className="menu-item beneficiaries">
                Beneficiaries
              </Link>
            </div>
            <div className="menu-item-container">
              <Link to={"/im/projects"} className="menu-item investors">
                Investors
              </Link>
            </div>
            <div className="menu-item-container">
              <Link to={"/im/projects"} className="menu-item field-data">
                Field Data
              </Link>
            </div>*/}
          </div>
          <div className="notification-container" >
            <div className="notification-btn" onClick={this.showNotifications.bind(this)}>
              <i className="fa fa-bell notifyBell" />
              {this.state.showBadge ? (
                this.state.badgeCounts > 0 ? (
                  <div className="notifyBadge">
                    <p className="badgeText">{this.state.badgeCounts}</p>
                  </div>
                ) : null
              ) : null}

              {/* {this.state.badgeCounts.map(badgecount => {
                console.log(badgecount)
                return()
              })} */}

              {/* {this.state.notification ? (
                <div className="notification">
                  <h4 className="notificationHeader" style={{ margin: "8px 0 8px 8px" }}>
                    Notifications
                  </h4>
                  <hr style={{ border: "solid .5px #ef5a20", marginBottom: "0", marginTop: "5px" }} />
                  <div id='scrollable_div' className="tip">
                    {this.state.notifications.length ? (
                      <InfiniteScroll
                        dataLength={this.state.notifications.length}
                        next={this.loadMoreNotifications}
                        hasMore={this.state.hasMoreItems}
                        loader={loader}
                        scrollableTarget={"scrollable_div"}
                        endMessage={
                          <p style={{ textAlign: "center", color: "#a9a9a9", marginTop: "12px" }}>
                            You don't have new notifications any more.
                          </p>
                        }
                      >
                        <div className="tracks">{items}</div>
                      </InfiniteScroll>
                    ) : (<div className="messageDivEmpty" style={{ height: "100%" }}>
                      <div className='notifyInfo' style={{ display: "flex", flexDirection: "column", marginLeft: "8px", marginRight: "8px", width: "100%" }}>
                        <h3 style={{ color: "#a9a9a9", fontSize: "16px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }} className="my-2">You don't have any new Notifications.</h3>
                      </div>
                    </div>)}
                  </div>
                </div>
              ) : (null)} */}
            </div>
          </div>
        </Row>
      </div>
    );
  }
}