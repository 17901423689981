import { addSettingsToElements } from './create';
import { textboxInput, textareaInput, locationInput, areaMapInput, distanceInput, nameInput, choiceCheckboxInput, coRelationDropdownInput, paymentRadioInput, choiceRadioInput, choiceDropdownInput, addressInput, yesnoInput, phoneInput, dateInput, timeInput, scaleInput, signatureInput, barcodeInput, emailInput, numberInput, websiteInput, currencyInput, ratingInput, fileInput, pagebreakInput, headerInput, contentInput, formSubmissionInput } from './elements';
import { textboxInputSettings, textareaInputSettings, locationInputSettings, areaMapInputSettings, scaleInputSettings, signatureInputSettings, barcodeInputSettings, distanceInputSettings, nameInputSettings, choiceInputSettings, coRelationInputSettings, paymentInputSettings, yesnoInputSettings, addressInputSettings, emailInputSettings, numberInputSettings, websiteInputSettings, currencyInputSettings, phoneInputSettings, dateInputSettings, timeInputSettings, ratingInputSettings, fileInputSettings, headerInputSettings, contentInputSettings, layoutSettings, backgroundSettings, fontsSettings, buttonsSettings } from './elementSettings';

const $ = window.$;

export function createFormToEdit(form) {
	// Form submission settings
	var formSubmissionSettings = form['submission_settings'];
	var selectedFormSettingsJSONString = JSON.stringify(formSubmissionSettings);
	$('._form-holder > ._form-submit-settings  > p._form-submit-settings-atr').attr('data-json', selectedFormSettingsJSONString);
	$('._form-holder > ._form-submit-settings  > p._form-submit-settings-atr').data('json', selectedFormSettingsJSONString);

	// Form Details
	var formDetails = form['form_details'];
	var selectedFormDetailsJSONString = JSON.stringify(formDetails);

	$('._form-holder > ._form-details ._form-name > p._form-name-element').attr('data-json', selectedFormDetailsJSONString);
	$('._form-holder > ._form-details ._form-name > p._form-name-element').data('json', selectedFormDetailsJSONString);
	$('._form-holder > ._form-details ._form-name > p._form-name-element').html(formDetails['title']);
	$('#_form-details-settings label[for="form_description"]').addClass('active');

	// Form Header
	var formHeader = form['form_header'];
	if(formHeader !== null && typeof formHeader === 'object' && formHeader.length === undefined) {
		var headerContainer = $('._form-holder ._header-container');
		$(headerContainer).append(headerInput);

		var headerJSONString = JSON.stringify(formHeader);

		$(headerContainer).find('._form-element').first().attr('data-json', headerJSONString);
		addSettingsToElements($(headerContainer).find('._form-element').first(), 'headerInput', headerInputSettings, 0);
		$(headerContainer).find('._form-element').first().find('h4').html(formHeader['headerTitle']);
		$(headerContainer).find('._form-element').first().find('h5').html(formHeader['headerSubtitle']);
		if(formHeader['headerBackground'] !== '') {
			$('._form-holder ._header-container').find('._form-element').first().find('._form-header').css('background', 'url(' + formHeader['headerBackground'] + ')');
		} else {
			$('._form-holder ._header-container').find('._form-element').first().find('._form-header').css('background', '#f1f1f1');
		}
	}

	// Form Elements
	var formContainer = $('._form-holder ._element-container');
	var elementIndex = 0;

	var formElements = form['form_element'];
	for(var i = 0; i < formElements.length; i++) {
		var selectedFormElement = formElements[i];
		if (selectedFormElement['type'] == 'textbox') {
			$(formContainer).append(textboxInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var textboxParentJSON = {};
			textboxParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			textboxParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			textboxParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			textboxParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			textboxParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				textboxParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}
			$(formContainer).find('._form-element').last().attr('data-isParent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);

			var textboxJSON = selectedFormElement;
			delete textboxJSON['parentIndex'];
			delete textboxJSON['parentAnswer'];
			delete textboxJSON['parentQuestion'];
			delete textboxJSON['parentCriteriaChoice'];
			delete textboxJSON['selectedParentData'];
			if(textboxJSON.hasOwnProperty('selectedChoiceConditionType')) delete textboxJSON['selectedChoiceConditionType'];

			var textboxJSONString = JSON.stringify(textboxJSON);

			$(formContainer).find('._form-element').last().attr('data-json', textboxJSONString);
			var textboxParentJSONString = JSON.stringify(textboxParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', textboxParentJSONString);
			if(textboxJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', textboxJSON['helpText']);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'textboxInput', textboxInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('label[for="textbox"]').html(textboxJSON['label']);
			$(formContainer).find('._form-element').last().find('label[for="textbox"]').attr('data-error', textboxJSON['error']);
			$(formContainer).find('._form-element').last().find('input#textbox').attr('placeholder', textboxJSON['placeholder']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', textboxJSON['required']);

		} else if(selectedFormElement['type'] == 'textarea') {
			$(formContainer).append(textareaInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var textareaParentJSON = {};
			textareaParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			textareaParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			textareaParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			textareaParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			textareaParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				textareaParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var textareaJSON = selectedFormElement;
			delete textareaJSON['parentIndex'];
			delete textareaJSON['parentAnswer'];
			delete textareaJSON['parentQuestion'];
			delete textareaJSON['parentCriteriaChoice'];
			delete textareaJSON['selectedParentData'];
			if(textareaJSON.hasOwnProperty('selectedChoiceConditionType')) delete textareaJSON['selectedChoiceConditionType'];
			var textareaJSONString = JSON.stringify(textareaJSON);

			$(formContainer).find('._form-element').last().attr('data-json', textareaJSONString);
			if(textareaJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', textareaJSON['helpText']);

			var textareaParentJSONString = JSON.stringify(textareaParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', textareaParentJSONString);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'textareaInput', textareaInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('label[for="textarea"]').html(textareaJSON['label']);
			$(formContainer).find('._form-element').last().find('label[for="textarea"]').attr('data-error', textareaJSON['error']);
			$(formContainer).find('._form-element').last().find('textarea#textarea').attr('placeholder', textareaJSON['placeholder']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', textareaJSON['required']);

		} else if(selectedFormElement['type'] == 'location') {
			$(formContainer).append(locationInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var locationParentJSON = {};
			locationParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			locationParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			locationParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			locationParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			locationParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);


			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var locationJSON = selectedFormElement;
			delete locationJSON['parentIndex'];
			delete locationJSON['parentAnswer'];
			delete locationJSON['parentQuestion'];
			delete locationJSON['parentCriteriaChoice'];
			delete locationJSON['selectedParentData'];
			var locationJSONString = JSON.stringify(locationJSON);

			$(formContainer).find('._form-element').last().attr('data-json', locationJSONString);
			if(locationJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', locationJSON['helpText']);

			var locationParentJSONString = JSON.stringify(locationParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', locationParentJSONString);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'locationInput', locationInputSettings, formElementIndex);

			var locationType = locationJSON['locationType'];
			var locationElem = '';
			if(locationType == 'searchbox') {
				if($('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').find('.location-container #location-map').length){
					$('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').find('.location-container #location-map').remove();
				}
				if(!$(formContainer).find('._form-element[data-index=' + formElementIndex + ']').find('.location-container input[type="text"]').length){
					locationElem = '<input type="text" id="locationLabel" class="validate browser-default form-control" placeholder="Input text here.." />';
					$(locationElem).insertAfter($(formContainer).find('._form-element[data-index=' + formElementIndex + ']').find('.location-container label'));
					var $location = $(formContainer).find('._form-element[data-index=' + formElementIndex + ']').find('.location-container input#locationLabel');
					var autocomplete = new window.google.maps.places.Autocomplete($location[0]);
					autocomplete.addListener('place_changed', function(){
						var place = autocomplete.getPlace();
						var lat = place.geometry.location.lat();
						var lng = place.geometry.location.lng();
					});
				}
			} else if(locationType == 'mappointer') {
				if($(formContainer).find('._form-element[data-index=' + formElementIndex + ']').find('.location-container input#locationLabel').length){
					$(formContainer).find('._form-element[data-index=' + formElementIndex + ']').find('.location-container input#locationLabel').remove();
				}
				if(!$('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').find('.location-container #location-map').length){
					locationElem = '<div id="location-map"></div>';
					$(locationElem).insertAfter($('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').find('.location-container label'));
					var india = {lat: 20.5937, lng: 78.9629};
					var map = new window.google.maps.Map(
						document.getElementById('location-map'), {zoom: 5, center: india}
					);
					var marker = new window.google.maps.Marker({position: india, map: map, draggable: true, animation: window.google.maps.Animation.DROP});
					window.google.maps.event.addListener(map, 'click', function(event) {
						if (marker) {
							marker.setPosition(event.latLng)
						} else {
							marker = new window.google.maps.Marker({
								map: map,
								draggable: true,
								position: event.latLng,
								animation: window.google.maps.Animation.DROP
							});
						}
					});
				}
			}

			$(formContainer).find('._form-element').last().find('label[for="locationLabel"]').html(locationJSON['label']);
			$(formContainer).find('._form-element').last().find('label[for="locationLabel"]').attr('data-error', locationJSON['error']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', locationJSON['required']);

		} else if(selectedFormElement['type'] == 'areamap') {
			$(formContainer).append(areaMapInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var areaMapParentJSON = {};
			areaMapParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			areaMapParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			areaMapParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			areaMapParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			areaMapParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);


			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var areaMapJSON = selectedFormElement;
			delete areaMapJSON['parentIndex'];
			delete areaMapJSON['parentAnswer'];
			delete areaMapJSON['parentQuestion'];
			delete areaMapJSON['parentCriteriaChoice'];
			delete areaMapJSON['selectedParentData'];
			var areaMapJSONString = JSON.stringify(areaMapJSON);

			$(formContainer).find('._form-element').last().attr('data-json', areaMapJSONString);
			if(areaMapJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', areaMapJSON['helpText']);

			var areaMapParentJSONString = JSON.stringify(areaMapParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', areaMapParentJSONString);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'areaMapInput', areaMapInputSettings, formElementIndex);

			var areaElem = '';

			if($(formContainer).find('._form-element[data-index=' + formElementIndex + ']').find('.area_map-container input#area_mapLabel').length){
				$(formContainer).find('._form-element[data-index=' + formElementIndex + ']').find('.area_map-container input#area_mapLabel').remove();
			}
			if(!$('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').find('.area_map-container #area_map').length){
				areaElem = '<div id="area_map"></div>';
				$(areaElem).insertAfter($('._form-holder ._element-container').find('._form-element[data-index=' + elementIndex + ']').find('.area_map-container label'));
				var india = {lat: 20.5937, lng: 78.9629};
				var map = new window.google.maps.Map(
					document.getElementById('area_map'), {zoom: 5, center: india}
				);
				var coords = [
				{lat: 11.9716, lng: 77.5946},
				{lat: 13.9716, lng: 76.5946},
				{lat: 15.9716, lng: 78.5946},
				{lat: 17.9716, lng: 79.5946}
			  ];

			  // Construct the polygon.
			  var editablePolygon = new window.google.maps.Polygon({
					paths: coords,
					strokeColor: '#FF0000',
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: '#FF0000',
					fillOpacity: 0.35,
					editable: true
			  });
			  editablePolygon.setMap(map);
			}

			$(formContainer).find('._form-element').last().find('label[for="area_mapLabel"]').html(areaMapJSON['label']);
			$(formContainer).find('._form-element').last().find('label[for="area_mapLabel"]').attr('data-error', areaMapJSON['error']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', areaMapJSON['required']);

		} else if(selectedFormElement['type'] == 'distance') {
			$(formContainer).append(distanceInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var distanceParentJSON = {};
			distanceParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			distanceParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			distanceParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			distanceParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			distanceParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);


			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var distanceJSON = selectedFormElement;
			delete distanceJSON['parentIndex'];
			delete distanceJSON['parentAnswer'];
			delete distanceJSON['parentQuestion'];
			delete distanceJSON['parentCriteriaChoice'];
			delete distanceJSON['selectedParentData'];
			var distanceJSONString = JSON.stringify(distanceJSON);

			$(formContainer).find('._form-element').last().attr('data-json', distanceJSONString);
			if(distanceJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', distanceJSON['helpText']);

			var distanceParentJSONString = JSON.stringify(distanceParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', distanceParentJSONString);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'distanceInput', distanceInputSettings, formElementIndex);

			if(!$('._form-holder ._element-container').find('._form-element[data-index=' + formElementIndex + ']').find('.distance-container #distance-map-'+formElementIndex).length){
				var locationElem = '<div id="distance-map-'+formElementIndex+'" class="distance-map"></div>';
				$(locationElem).insertAfter($('._form-holder ._element-container').find('._form-element[data-index=' + formElementIndex + ']').find('.distance-container label'));
				if(!$('._form-holder ._element-container').find('._form-element[data-index=' + formElementIndex + ']').find('.distance-container #distance-legend').length){
					var distanceLegend = '<div id="distance-legend"></div>';
					$(distanceLegend).insertAfter($('._form-holder ._element-container').find('._form-element[data-index=' + formElementIndex + ']').find('.distance-container #distance-map-'+formElementIndex));
				}
				var latLng = {lat: 20.5937, lng: 78.9629};
				var latLng2 = {lat: 20.0847, lng: 78.0449};
				var distance = getDistance(latLng['lat'], latLng['lng'], latLng2['lat'], latLng2['lng']);
				$('._form-holder ._element-container').find('._form-element[data-index=' + formElementIndex + ']').find('.distance-container #distance-legend').html('Distance: '+ distance);
				var map = new window.google.maps.Map(
					document.getElementById('distance-map-'+formElementIndex), {zoom: 5, center: latLng}
				);
				var marker = new window.google.maps.Marker({position: latLng, map: map, draggable: true, animation: window.google.maps.Animation.DROP, label: 'A'});
				var marker2 = new window.google.maps.Marker({position: latLng2, map: map, draggable: true, animation: window.google.maps.Animation.DROP, label: 'B'});
				var polyline = new window.google.maps.Polyline({
					path: [latLng, latLng2],
					geodesic: true,
					strokeColor: '#ef5a20',
					strokeOpacity: 0.9,
					strokeWeight: 2
				});
				polyline.setMap(map);
				marker.addListener('dragend', function (event) {
					latLng = {lat: event.latLng.lat(), lng: event.latLng.lng()};
					var path = [latLng, latLng2];
					polyline.setPath(path);
					distance = getDistance(latLng['lat'], latLng['lng'], latLng2['lat'], latLng2['lng']);
					$('._form-holder ._element-container').find('._form-element[data-index=' + formElementIndex + ']').find('.distance-container #distance-legend').html('Distance: '+ distance);
				});
				marker2.addListener('dragend', function (event) {
					latLng2 = {lat: event.latLng.lat(), lng: event.latLng.lng()};
					var path = [latLng, latLng2];
					polyline.setPath(path);
					distance = getDistance(latLng['lat'], latLng['lng'], latLng2['lat'], latLng2['lng']);
					$('._form-holder ._element-container').find('._form-element[data-index=' + formElementIndex + ']').find('.distance-container #distance-legend').html('Distance: '+ distance);
				});
			} else {
				if(!$('._form-holder ._element-container').find('._form-element[data-index=' + formElementIndex + ']').find('.distance-container #distance-legend').length){
					var distanceLegend = '<div id="distance-legend"></div>';
					$(distanceLegend).insertAfter($('._form-holder ._element-container').find('._form-element[data-index=' + formElementIndex + ']').find('.distance-container #distance-map-'+formElementIndex));
				}
				var latLng = {lat: 20.5937, lng: 78.9629};
				var latLng2 = {lat: 20.0847, lng: 78.0449};
				var distance = getDistance(latLng['lat'], latLng['lng'], latLng2['lat'], latLng2['lng']);
				$('._form-holder ._element-container').find('._form-element[data-index=' + formElementIndex + ']').find('.distance-container #distance-legend').html('Distance: '+ distance);
				var map = new window.google.maps.Map(
					document.getElementById('distance-map-'+formElementIndex), {zoom: 5, center: latLng}
				);
				var marker = new window.google.maps.Marker({position: latLng, map: map, draggable: true, animation: window.google.maps.Animation.DROP, label: 'A'});
				var marker2 = new window.google.maps.Marker({position: latLng2, map: map, draggable: true, animation: window.google.maps.Animation.DROP, label: 'B'});
				var polyline = new window.google.maps.Polyline({
					path: [latLng, latLng2],
					geodesic: true,
					strokeColor: '#ef5a20',
					strokeOpacity: 0.9,
					strokeWeight: 2
				});
				polyline.setMap(map);
				marker.addListener('dragend', function (event) {
					latLng = {lat: event.latLng.lat(), lng: event.latLng.lng()};
					var path = [latLng, latLng2];
					polyline.setPath(path);
					distance = getDistance(latLng['lat'], latLng['lng'], latLng2['lat'], latLng2['lng']);
					$('._form-holder ._element-container').find('._form-element[data-index=' + formElementIndex + ']').find('.distance-container #distance-legend').html('Distance: '+ distance);
				});
				marker2.addListener('dragend', function (event) {
					latLng2 = {lat: event.latLng.lat(), lng: event.latLng.lng()};
					var path = [latLng, latLng2];
					polyline.setPath(path);
					distance = getDistance(latLng['lat'], latLng['lng'], latLng2['lat'], latLng2['lng']);
					$('._form-holder ._element-container').find('._form-element[data-index=' + formElementIndex + ']').find('.distance-container #distance-legend').html('Distance: '+ distance);
				});
			}
			$(formContainer).find('._form-element').last().find('label[for="locationLabel"]').html(distanceJSON['label']);
			$(formContainer).find('._form-element').last().find('label[for="locationLabel"]').attr('data-error', distanceJSON['error']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', distanceJSON['required']);

		} else if(selectedFormElement['type'] == 'name') {
			$(formContainer).append(nameInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var nameParentJSON = {};
			nameParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			nameParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			nameParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			nameParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			nameParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				nameParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var nameJSON = selectedFormElement;
			delete nameJSON['parentIndex'];
			delete nameJSON['parentAnswer'];
			delete nameJSON['parentQuestion'];
			delete nameJSON['parentCriteriaChoice'];
			delete nameJSON['selectedParentData'];
			if(nameJSON.hasOwnProperty('selectedChoiceConditionType')) delete nameJSON['selectedChoiceConditionType'];

			var nameJSONString = JSON.stringify(nameJSON);

			$(formContainer).find('._form-element').last().attr('data-json', nameJSONString);
			var nameParentJSONString = JSON.stringify(nameParentJSON);

			$(formContainer).find('._form-element').last().attr('data-parent', nameParentJSONString);
			if(nameJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', nameJSON['helpText']);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'nameInput', nameInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('label[for="first_name"]').html(nameJSON['fields'][0]['label']);
			$(formContainer).find('._form-element').last().find('label[for="last_name"]').html(nameJSON['fields'][1]['label']);
			$(formContainer).find('._form-element').last().find('label[for="first_name"]').attr('data-error', nameJSON['fields'][0]['error']);
			$(formContainer).find('._form-element').last().find('label[for="last_name"]').attr('data-error', nameJSON['fields'][1]['error']);
			$(formContainer).find('._form-element').last().find('input#first_name').attr('placeholder', nameJSON['fields'][0]['placeholder']);
			$(formContainer).find('._form-element').last().find('input#last_name').attr('placeholder', nameJSON['fields'][1]['placeholder']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', nameJSON['required']);

		} else if(selectedFormElement['type'] == 'barcode') {
			$(formContainer).append(barcodeInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var barcodeParentJSON = {};
			barcodeParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			barcodeParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			barcodeParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			barcodeParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			barcodeParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				barcodeParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var barcodeJSON = selectedFormElement;
			delete barcodeJSON['parentIndex'];
			delete barcodeJSON['parentAnswer'];
			delete barcodeJSON['parentQuestion'];
			delete barcodeJSON['parentCriteriaChoice'];
			delete barcodeJSON['selectedParentData'];
			if(barcodeJSON.hasOwnProperty('selectedChoiceConditionType')) delete barcodeJSON['selectedChoiceConditionType'];

			var barcodeJSONString = JSON.stringify(barcodeJSON);

			$(formContainer).find('._form-element').last().attr('data-json', barcodeJSONString);
			var barcodeParentJSONString = JSON.stringify(barcodeParentJSON);

			$(formContainer).find('._form-element').last().attr('data-parent', barcodeParentJSONString);
			if(barcodeJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', barcodeJSON['helpText']);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'barcodeInput', barcodeInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('label[for="first_barcode"]').html(barcodeJSON['label']);
			$(formContainer).find('._form-element').last().find('label[for="first_barcode"]').attr('data-error', barcodeJSON['error']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', barcodeJSON['required']);

		} else if(selectedFormElement['type'] == 'choice') {
			$(formContainer).append(choiceCheckboxInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var choiceParentJSON = {};
			choiceParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			choiceParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			choiceParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			choiceParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			choiceParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				choiceParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var choiceJSON = selectedFormElement;
			delete choiceJSON['parentIndex'];
			delete choiceJSON['parentAnswer'];
			delete choiceJSON['parentQuestion'];
			delete choiceJSON['parentCriteriaChoice'];
			delete choiceJSON['selectedParentData'];
			if(choiceJSON.hasOwnProperty('selectedChoiceConditionType')) delete choiceJSON['selectedChoiceConditionType'];

			var choiceJSONString = JSON.stringify(choiceJSON);
			$(formContainer).find('._form-element').last().attr('data-json', choiceJSONString);

			if(choiceJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', choiceJSON['helpText']);
			var choiceParentJSONString = JSON.stringify(choiceParentJSON);

			$(formContainer).find('._form-element').last().attr('data-parent', choiceParentJSONString);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'choiceCheckboxInput', choiceInputSettings, formElementIndex);

			var newChoiceOptions = choiceJSON['newChoiceOptions'];
			var choiceType = choiceJSON['choiceType'];
			if(choiceType == 'checkbox') {
				var $choiceCheckboxInput = $(choiceCheckboxInput);
				$(formContainer).find('._form-element').last().find('._choice-container').html($choiceCheckboxInput.find('._choice-container').html());
				for(var j = 0; j < newChoiceOptions.length; j++) {
					$('<div class="col s4 _choice"><input type="checkbox" class="filled-in" id="option-' + String(j + 2) + '" /><label for="option-' + String(j + 2) + '">' + newChoiceOptions[j] + '</label></div>').insertAfter($(formContainer).find('._form-element').last().find('._choice').last());
				}
			} else if(choiceType == 'radio') {
				var $choiceRadioInput = $(choiceRadioInput);
				$(formContainer).find('._form-element').last().find('._choice-container').html($choiceRadioInput.find('._choice-container').html());
				for(var j = 0; j < newChoiceOptions.length; j++) {
					$('<div class="col s4 _choice"><input name="choiceRadio" type="radio" class="with-gap" id="option-' + String(j + 2) + '" /><label for="option-' + String(j + 2) + '">' + newChoiceOptions[j] + '</label></div>').insertAfter($(formContainer).find('._form-element').last().find('._choice').last());
				}
			} else if(choiceType == 'dropdown') {
				var $choiceDropdownInput = $(choiceDropdownInput);
				$(formContainer).find('._form-element').last().find('._choice-container').html($choiceDropdownInput.find('._choice-container').html());
				for(var j = 0; j < newChoiceOptions.length; j++) {
					$('<option id="option-' + String(j + 2) + '">' + newChoiceOptions[j] + '</option>').insertAfter($(formContainer).find('._form-element').last().find('._choice').find('option').last());
				}
			}
			$(formContainer).find('._form-element').last().find('span#choiceLabel').html(choiceJSON['choiceLabel']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', choiceJSON['required']);

			if(choiceType == 'dropdown') $(formContainer).find('._form-element').last().find('option#option-1').html(choiceJSON['choicePlaceholder1']);
			else $(formContainer).find('._form-element').last().find('label[for="option-1"]').html(choiceJSON['choicePlaceholder1']);

		} else if(selectedFormElement['type'] == 'payment') {
			$(formContainer).append(paymentRadioInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var paymentParentJSON = {};
			paymentParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			paymentParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			paymentParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			paymentParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			paymentParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				paymentParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var paymentJSON = selectedFormElement;
			delete paymentJSON['parentIndex'];
			delete paymentJSON['parentAnswer'];
			delete paymentJSON['parentQuestion'];
			delete paymentJSON['parentCriteriaChoice'];
			delete paymentJSON['selectedParentData'];
			if(paymentJSON.hasOwnProperty('selectedChoiceConditionType')) delete paymentJSON['selectedChoiceConditionType'];

			var paymentJSONString = JSON.stringify(paymentJSON);
			$(formContainer).find('._form-element').last().attr('data-json', paymentJSONString);

			if(paymentJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', paymentJSON['helpText']);
			var paymentParentJSONString = JSON.stringify(paymentParentJSON);

			$(formContainer).find('._form-element').last().attr('data-parent', paymentParentJSONString);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'paymentRadioInput', paymentInputSettings, formElementIndex);

			var newpaymentOptions = paymentJSON['newpaymentOptions'];
			var paymentType = paymentJSON['paymentType'];
			// if(paymentType == 'radio') {
				var $paymentRadioInput = $(paymentRadioInput);
				for(var j = 0; j < newpaymentOptions.length; j++) {
					var stringHtml = '';
				if(newpaymentOptions[j]['productValue'] > 0){
					stringHtml = '<label for="option-' + String(j + 2) + '">' + newpaymentOptions[j]['productLabel'] + '(Rs. ' + newpaymentOptions[j]['productValue'] + ')' + '</label>';
				} else {
					stringHtml = '<label for="option-' + String(j + 2) + '">' + newpaymentOptions[j]['productLabel'] +'</label>';
				}
				var html = '<div class="product-container"><div class="_product-label-container"><input name="paymentRadio" type="radio" class="with-gap" id="option-' + String(i + 2) + '" />'+ stringHtml +'</div></div>';
					if(newpaymentOptions[j].enableQuantity) {
						html += '<div class="_product-quantity-container"><label class="active _required-star control-label" data-required="true" for="paymentQuantity-' + String(i + 2) + '">Enter Quantity</label><input placeholder="10" id="paymentQuantity' + String(j + 2) + '" type="number" class="validate browser-default form-control" /></div></div>';
					} else {
						html += '</div>';
					}
					$(html).insertAfter($(formContainer).find('._form-element').last().find('.products-container > .product-container').last());
				}
			// }
			// else if(paymentType == 'dropdown') {
			// 	var $paymentDropdownInput = $(paymentDropdownInput);
			// 	$(formContainer).find('._form-element').last().find('._payment-container').html($paymentDropdownInput.find('._payment-container').html());
			// 	for(var j = 0; j < newpaymentOptions.length; j++) {
			// 		$('<option id="option-' + String(j + 2) + '">' + newpaymentOptions[j] + '</option>').insertAfter($(formContainer).find('._form-element').last().find('._product-label').find('option').last());
			// 	}
			// }
			$(formContainer).find('._form-element').last().find('span#paymentLabel').html(paymentJSON['paymentLabel']);
			$(formContainer).find('._form-element').last().find('label[for="payment"]').attr('data-error', paymentJSON['error']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', paymentJSON['required']);

			// if(paymentType == 'dropdown') $(formContainer).find('._form-element').last().find('option#option-1').html(paymentJSON['productLabel1']);else
			if(paymentJSON['productValue1'] > 0){
				$(formContainer).find('._form-element').last().find('label[for="option-1"]').html(paymentJSON['productLabel1'] + '(Rs. ' +  paymentJSON['productValue1'] + ')');
			} else {
				$(formContainer).find('._form-element').last().find('label[for="option-1"]').html(paymentJSON['productLabel1']);
			}

		} else if(selectedFormElement['type'] == 'correlation') {
			$(formContainer).append(coRelationDropdownInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var relationParentJSON = {};
			relationParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			relationParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			relationParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			relationParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			relationParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				relationParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var relationJSON = selectedFormElement;
			delete relationJSON['parentIndex'];
			delete relationJSON['parentAnswer'];
			delete relationJSON['parentQuestion'];
			delete relationJSON['parentCriteriaChoice'];
			delete relationJSON['selectedParentData'];
			if(relationJSON.hasOwnProperty('selectedChoiceConditionType')) delete relationJSON['selectedChoiceConditionType'];

			var relationJSONString = JSON.stringify(relationJSON);
			$(formContainer).find('._form-element').last().attr('data-json', relationJSONString);

			if(relationJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', relationJSON['helpText']);
			var relationParentJSONString = JSON.stringify(relationParentJSON);

			$(formContainer).find('._form-element').last().attr('data-parent', relationParentJSONString);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'coRelationDropdownInput', coRelationInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('label[for="relationLabel"]').html(relationJSON['relationLabel']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', relationJSON['required']);

		} else if(selectedFormElement['type'] == 'yesno') {
			$(formContainer).append(yesnoInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var yesnoParentJSON = {};
			yesnoParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			yesnoParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			yesnoParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			yesnoParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			yesnoParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				yesnoParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var yesnoJSON = selectedFormElement;
			delete yesnoJSON['parentIndex'];
			delete yesnoJSON['parentAnswer'];
			delete yesnoJSON['parentQuestion'];
			delete yesnoJSON['parentCriteriaChoice'];
			delete yesnoJSON['selectedParentData'];
			if(yesnoJSON.hasOwnProperty('selectedChoiceConditionType')) delete yesnoJSON['selectedChoiceConditionType'];

			var yesnoJSONString = JSON.stringify(yesnoJSON);
			$(formContainer).find('._form-element').last().attr('data-json', yesnoJSONString);

			if(yesnoJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', yesnoJSON['helpText']);
			var yesnoParentJSONString = JSON.stringify(yesnoParentJSON);

			$(formContainer).find('._form-element').last().attr('data-parent', yesnoParentJSONString);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'yesnoInput', yesnoInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('label[for="yesnoPlaceholder1"]').attr('for', 'yesnoPlaceholder1-' + i);
			$(formContainer).find('._form-element').last().find('label[for="yesnoPlaceholder2"]').attr('for', 'yesnoPlaceholder2-' + i);
			$(formContainer).find('._form-element').last().find('input#yesnoPlaceholder1').attr('id', 'yesnoPlaceholder1-' + i);
			$(formContainer).find('._form-element').last().find('input#yesnoPlaceholder2').attr('id', 'yesnoPlaceholder2-' + i);
			$(formContainer).find('._form-element').last().find('input#yesnoPlaceholder1-' + i).attr('name', 'yesnoPlaceholder-' + i);
			$(formContainer).find('._form-element').last().find('input#yesnoPlaceholder2-' + i).attr('name', 'yesnoPlaceholder-' + i);
			$(formContainer).find('._form-element').last().find('label[for="yesnoPlaceholder1-' + i + '"]').html(yesnoJSON['yesnoPlaceholder1']);
			$(formContainer).find('._form-element').last().find('label[for="yesnoPlaceholder2-' + i + '"]').html(yesnoJSON['yesnoPlaceholder2']);
			$(formContainer).find('._form-element').last().find('span#yesnoLabel').html(yesnoJSON['yesnoLabel']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', yesnoJSON['required']);

		} else if(selectedFormElement['type'] == 'address') {
			$(formContainer).append(addressInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var $element = $(formContainer).find('._form-element').last();
			$.get('https://www.letsendorse.com/getlocation?type=0').then(function(data) {
				for(var j = 0; j < data.length; j++) {
					$($element).find('select#country').append('<option value="' + data[j]['name'] + '" countryId="' + data[j]['id'] + '">' + data[j]['name'] + '</option>');
				}
			});
			$($element).find('select#country').change(function() {
				var $selectedCountry = $(this).find(':selected');
				var countryId = $selectedCountry.attr('countryId');
				$.get('https://www.letsendorse.com/getlocation?type=1&countryId=' + countryId).then(function(data) {
					for(var k = 0; k < data.length; k++) {
						$($element).find('select#state').append('<option value="' + data[k]['name'] + '" stateId="' + data[k]['id'] + '">' + data[k]['name'] + '</option>');
					}
				});
			});
			$($element).find('select#state').change(function() {
				var $selectedState = $(this).find(':selected');
				var stateId = $selectedState.attr('stateId');
				$.get('https://www.letsendorse.com/getlocation?type=2&stateId=' + stateId).then(function(data) {
					for(var l = 0; l < data.length; l++) {
						$($element).find('select#city').append('<option value="' + data[l]['name'] + '" cityId="' + data[l]['id'] + '">' + data[l]['name'] + '</option>');
					}
				});
			});

			var addressParentJSON = {};
			addressParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			addressParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			addressParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			addressParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			addressParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				addressParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var addressJSON = selectedFormElement;
			delete addressJSON['parentIndex'];
			delete addressJSON['parentAnswer'];
			delete addressJSON['parentQuestion'];
			delete addressJSON['parentCriteriaChoice'];
			delete addressJSON['selectedParentData'];
			if(addressJSON.hasOwnProperty('selectedChoiceConditionType')) delete addressJSON['selectedChoiceConditionType'];

			var addressJSONString = JSON.stringify(addressJSON);
			$(formContainer).find('._form-element').last().attr('data-json', addressJSONString);

			if(addressJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', addressJSON['helpText']);
			var addressParentJSONString = JSON.stringify(addressParentJSON);

			$(formContainer).find('._form-element').last().attr('data-parent', addressParentJSONString);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'addressInput', addressInputSettings, formElementIndex);
			$(formContainer).find('._form-element').last().find('input#zipcode').attr('placeholder', addressJSON['pincodePlaceholder']);
			$(formContainer).find('._form-element').last().find('input#area').attr('placeholder', addressJSON['areaPlaceholder']);
			$(formContainer).find('._form-element').last().find('input#street').attr('placeholder', addressJSON['streetPlaceholder']);
			$(formContainer).find('._form-element').last().find('span#addressLabel').html(addressJSON['label']);
			$(formContainer).find('._form-element').last().find('label[for="zipcode"]').html(addressJSON['pincodeLabel']);
			if(addressJSON['geoLocation']) $(formContainer).find('._form-element').last().find('label[for="area"]').html('Location');
			else $(formContainer).find('._form-element').last().find('label[for="area"]').html(addressJSON['areaLabel']);
			$(formContainer).find('._form-element').last().find('label[for="street"]').html(addressJSON['streetLabel']);
			$(formContainer).find('._form-element').last().find('label[for="pincode"]').attr('data-error', addressJSON['pincodeError']);
			$(formContainer).find('._form-element').last().find('label[for="area"]').attr('data-error', addressJSON['areaError']);
			$(formContainer).find('._form-element').last().find('label[for="street"]').attr('data-error', addressJSON['streetError']);
		} else if(selectedFormElement['type'] == 'phone') {
			$(formContainer).append(phoneInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			$(formContainer).find('._form-element').last().find("#phone").intlTelInput();
			var phoneParentJSON = {};
			phoneParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			phoneParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			phoneParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			phoneParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			phoneParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				phoneParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var phoneJSON = selectedFormElement;
			delete phoneJSON['parentIndex'];
			delete phoneJSON['parentAnswer'];
			delete phoneJSON['parentQuestion'];
			delete phoneJSON['parentCriteriaChoice'];
			delete phoneJSON['selectedParentData'];
			if(phoneJSON.hasOwnProperty('selectedChoiceConditionType')) delete phoneJSON['selectedChoiceConditionType'];

			var phoneJSONString = JSON.stringify(phoneJSON);
			$(formContainer).find('._form-element').last().attr('data-json', phoneJSONString);

			var phoneParentJSONString = JSON.stringify(phoneParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', phoneParentJSONString);

			if(phoneJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', phoneJSON['helpText']);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'phoneInput', phoneInputSettings, formElementIndex);
			$(formContainer).find('._form-element').last().find('label[for="phone"]').attr('data-error', phoneJSON['error']);
			$(formContainer).find('._form-element').last().find('#phoneLabel').html(phoneJSON['label']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', phoneJSON['required']);
		} else if(selectedFormElement['type'] == 'date') {
			$(formContainer).append(dateInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			// $('.datepicker').pickadate({
			// 	selectMonths: true, // Creates a dropdown to control month
			// 	selectYears: 15 // Creates a dropdown of 15 years to control year
			// });
			$('.datepicker-here').datepicker({language: 'en', dateFormat: 'dd/mm/yyyy'});
			var dateParentJSON = {};
			dateParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			dateParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			dateParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			dateParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			dateParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				dateParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var dateJSON = selectedFormElement;
			delete dateJSON['parentIndex'];
			delete dateJSON['parentAnswer'];
			delete dateJSON['parentQuestion'];
			delete dateJSON['parentCriteriaChoice'];
			delete dateJSON['selectedParentData'];
			if(dateJSON.hasOwnProperty('selectedChoiceConditionType')) delete dateJSON['selectedChoiceConditionType'];

			var dateJSONString = JSON.stringify(dateJSON);
			$(formContainer).find('._form-element').last().attr('data-json', dateJSONString);

			var dateParentJSONString = JSON.stringify(dateParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', dateParentJSONString);

			if(dateJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', dateJSON['helpText']);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'dateInput', dateInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('label[for="datepicker"]').attr('data-error', dateJSON['error']);
			$(formContainer).find('._form-element').last().find('#dateLabel').html(dateJSON['label']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', dateJSON['required']);

		} else if(selectedFormElement['type'] == 'time') {
			$(formContainer).append(timeInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			$('.timepicker').pickatime({
				default: 'now', // Set default time
				fromnow: 0,       // set default time to * milliseconds from now (using with default = 'now')
				twelvehour: true, // Use AM/PM or 24-hour format
				donetext: 'OK', // text for done-button
				cleartext: 'Clear', // text for clear-button
				canceltext: 'Cancel', // Text for cancel-button
				autoclose: false, // automatic close timepicker
				ampmclickable: true, // make AM PM clickable
				aftershow: function(){} //Function for after opening timepicker
			});
			var timeParentJSON = {};
			timeParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			timeParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			timeParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			timeParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			timeParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				timeParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var timeJSON = selectedFormElement;
			delete timeJSON['parentIndex'];
			delete timeJSON['parentAnswer'];
			delete timeJSON['parentQuestion'];
			delete timeJSON['parentCriteriaChoice'];
			delete timeJSON['selectedParentData'];
			if(timeJSON.hasOwnProperty('selectedChoiceConditionType')) delete timeJSON['selectedChoiceConditionType'];

			var timeJSONString = JSON.stringify(timeJSON);
			$(formContainer).find('._form-element').last().attr('data-json', timeJSONString);

			var timeParentJSONString = JSON.stringify(timeParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', timeParentJSONString);

			if(timeParentJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', timeParentJSON['helpText']);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'timeInput', timeInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('label[for="timepicker"]').attr('data-error', timeJSON['error']);
			$(formContainer).find('._form-element').last().find('#timeLabel').html(timeJSON['label']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', timeJSON['required']);

		} else if(selectedFormElement['type'] == 'email') {
			$(formContainer).append(emailInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var emailParentJSON = {};
			emailParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			emailParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			emailParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			emailParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			emailParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				emailParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var emailJSON = selectedFormElement;
			delete emailJSON['parentIndex'];
			delete emailJSON['parentAnswer'];
			delete emailJSON['parentQuestion'];
			delete emailJSON['parentCriteriaChoice'];
			delete emailJSON['selectedParentData'];
			if(emailJSON.hasOwnProperty('selectedChoiceConditionType')) delete emailJSON['selectedChoiceConditionType'];

			var emailJSONString = JSON.stringify(emailJSON);
			$(formContainer).find('._form-element').last().attr('data-json', emailJSONString);

			var emailParentJSONString = JSON.stringify(emailParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', emailParentJSONString);

			if(emailParentJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', emailParentJSON['helpText']);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'emailInput', emailInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('label[for="email"]').html(emailJSON['label']);
			$(formContainer).find('._form-element').last().find('label[for="email"]').attr('data-error', emailJSON['error']);
			$(formContainer).find('._form-element').last().find('input#email').attr('placeholder', emailJSON['placeholder']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', emailJSON['required']);
		} else if(selectedFormElement['type'] == 'number') {
			$(formContainer).append(numberInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var numberParentJSON = {};
			numberParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			numberParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			numberParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			numberParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			numberParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				numberParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var numberJSON = selectedFormElement;
			delete numberJSON['parentIndex'];
			delete numberJSON['parentAnswer'];
			delete numberJSON['parentQuestion'];
			delete numberJSON['parentCriteriaChoice'];
			delete numberJSON['selectedParentData'];
			if(numberJSON.hasOwnProperty('selectedChoiceConditionType')) delete numberJSON['selectedChoiceConditionType'];

			var numberJSONString = JSON.stringify(numberJSON);
			$(formContainer).find('._form-element').last().attr('data-json', numberJSONString);

			var numberParentJSONString = JSON.stringify(numberParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', numberParentJSONString);

			if(numberJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', numberJSON['helpText']);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'numberInput', numberInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('label[for="number"]').html(numberJSON['label']);
			$(formContainer).find('._form-element').last().find('label[for="number"]').attr('data-error', numberJSON['error']);
			$(formContainer).find('._form-element').last().find('input#number').attr('placeholder', numberJSON['placeholder']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', numberJSON['required']);

		} else if(selectedFormElement['type'] == 'website') {
			$(formContainer).append(websiteInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var websiteParentJSON = {};
			websiteParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			websiteParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			websiteParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			websiteParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			websiteParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				websiteParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var websiteJSON = selectedFormElement;
			delete websiteJSON['parentIndex'];
			delete websiteJSON['parentAnswer'];
			delete websiteJSON['parentQuestion'];
			delete websiteJSON['parentCriteriaChoice'];
			delete websiteJSON['selectedParentData'];
			if(websiteJSON.hasOwnProperty('selectedChoiceConditionType')) delete websiteJSON['selectedChoiceConditionType'];

			var websiteJSONString = JSON.stringify(websiteJSON);
			$(formContainer).find('._form-element').last().attr('data-json', websiteJSONString);

			if(websiteJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', websiteJSON['helpText']);
			var websiteParentJSONString = JSON.stringify(websiteParentJSON);

			$(formContainer).find('._form-element').last().attr('data-parent', websiteParentJSONString);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'websiteInput', websiteInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('label[for="website"]').html(websiteJSON['label']);
			$(formContainer).find('._form-element').last().find('label[for="website"]').attr('data-error', websiteJSON['error']);
			$(formContainer).find('._form-element').last().find('input#website').attr('placeholder', websiteJSON['placeholder']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', websiteJSON['required']);
		} else if(selectedFormElement['type'] == 'currency') {
			$(formContainer).append(currencyInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var currencyParentJSON = {};
			currencyParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			currencyParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			currencyParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			currencyParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			currencyParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				currencyParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var currencyJSON = selectedFormElement;
			delete currencyJSON['parentIndex'];
			delete currencyJSON['parentAnswer'];
			delete currencyJSON['parentQuestion'];
			delete currencyJSON['parentCriteriaChoice'];
			delete currencyJSON['selectedParentData'];
			if(currencyJSON.hasOwnProperty('selectedChoiceConditionType')) delete currencyJSON['selectedChoiceConditionType'];

			var currencyJSONString = JSON.stringify(currencyJSON);
			$(formContainer).find('._form-element').last().attr('data-json', currencyJSONString);

			if(currencyJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', currencyJSON['helpText']);
			var currencyParentJSONString = JSON.stringify(currencyParentJSON);

			$(formContainer).find('._form-element').last().attr('data-parent', currencyParentJSONString);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'currencyInput', currencyInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('span#currencyLabel').html(currencyJSON['label']);
			$(formContainer).find('._form-element').last().find('label._currency-symbol').html(currencyJSON['currencySymbol']);
			$(formContainer).find('._form-element').last().find('input#currency').attr('placeholder', currencyJSON['placeholder']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', currencyJSON['required']);
		} else if(selectedFormElement['type'] == 'rating') {
			$(formContainer).append(ratingInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			$(".my-rating").starRating({
				totalStars: 3,
				starSize: 25,
				disableAfterRate: false,
			});
			var ratingParentJSON = {};
			ratingParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			ratingParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			ratingParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			ratingParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			ratingParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				ratingParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var ratingJSON = selectedFormElement;
			delete ratingJSON['parentIndex'];
			delete ratingJSON['parentAnswer'];
			delete ratingJSON['parentQuestion'];
			delete ratingJSON['parentCriteriaChoice'];
			delete ratingJSON['selectedParentData'];
			if(ratingJSON.hasOwnProperty('selectedChoiceConditionType')) delete ratingJSON['selectedChoiceConditionType'];
			var ratingJSONString = JSON.stringify(ratingJSON);

			$(formContainer).find('._form-element').last().attr('data-json', ratingJSONString);
			if(ratingJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', ratingJSON['helpText']);

			var ratingParentJSONString = JSON.stringify(ratingParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', ratingParentJSONString);

			addSettingsToElements($(formContainer).find('._form-element').last(), 'ratingInput', ratingInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('#ratingLabel').html(ratingJSON['label']);
			$(formContainer).find('._form-element').last().find(".my-rating").starRating('unload');
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', ratingJSON['required']);
			$('<div class="my-rating"></div>').insertAfter($(formContainer).find('._form-element').last().find('#ratingLabel'));
			$(formContainer).find('._form-element').last().find(".my-rating").starRating({
				starSize: 25,
				disableAfterRate: false,
				totalStars: ratingJSON['ratingCount']
			});
		} else if(selectedFormElement['type'] == 'signature') {
			$(formContainer).append(signatureInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var signatureParentJSON = {};
			signatureParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			signatureParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			signatureParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			signatureParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			signatureParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				signatureParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var signatureJSON = selectedFormElement;
			delete signatureJSON['parentIndex'];
			delete signatureJSON['parentAnswer'];
			delete signatureJSON['parentQuestion'];
			delete signatureJSON['parentCriteriaChoice'];
			delete signatureJSON['selectedParentData'];
			if(signatureJSON.hasOwnProperty('selectedChoiceConditionType')) delete signatureJSON['selectedChoiceConditionType'];
			var signatureJSONString = JSON.stringify(signatureJSON);

			$(formContainer).find('._form-element').last().attr('data-json', signatureJSONString);
			if(signatureJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', signatureJSON['helpText']);

			var signatureParentJSONString = JSON.stringify(signatureParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', signatureParentJSONString);

			addSettingsToElements($(formContainer).find('._form-element').last(), 'signatureInput', signatureInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('#signatureLabel').html(signatureJSON['label']);
			$(formContainer).find('._form-element').last().find('label[for="signature-element"]').attr('data-required', signatureJSON['required']);

		} else if(selectedFormElement['type'] == 'scale') {
			$(formContainer).append(scaleInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var scaleParentJSON = {};
			scaleParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			scaleParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			scaleParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			scaleParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			scaleParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				scaleParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var scaleJSON = selectedFormElement;
			delete scaleJSON['parentIndex'];
			delete scaleJSON['parentAnswer'];
			delete scaleJSON['parentQuestion'];
			delete scaleJSON['parentCriteriaChoice'];
			delete scaleJSON['selectedParentData'];
			if(scaleJSON.hasOwnProperty('selectedChoiceConditionType')) delete scaleJSON['selectedChoiceConditionType'];
			var scaleJSONString = JSON.stringify(scaleJSON);

			$(formContainer).find('._form-element').last().attr('data-json', scaleJSONString);
			if(scaleJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', scaleJSON['helpText']);

			var scaleParentJSONString = JSON.stringify(scaleParentJSON);
			$(formContainer).find('._form-element').last().attr('data-parent', scaleParentJSONString);

			addSettingsToElements($(formContainer).find('._form-element').last(), 'scaleInput', scaleInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('#scaleLabel').html(scaleJSON['label']);
			$(formContainer).find('._form-element').last().find('input#scale-element').val(scaleJSON['scaleCount']);
			$(formContainer).find('._form-element').last().find('label[for="scale-element"]').attr('data-required', scaleJSON['required']);

		} else if(selectedFormElement['type'] == 'file') {
			$(formContainer).append(fileInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var fileParentJSON = {};
			fileParentJSON['parentIndex'] = selectedFormElement['parentIndex'];
			fileParentJSON['parentAnswer'] = selectedFormElement['parentAnswer'];
			fileParentJSON['parentQuestion'] = selectedFormElement['parentQuestion'];
			fileParentJSON['parentCriteriaChoice'] = selectedFormElement['parentCriteriaChoice'];
			fileParentJSON['selectedParentData'] = selectedFormElement['selectedParentData'];
			if(selectedFormElement.hasOwnProperty('selectedChoiceConditionType')){
				fileParentJSON['selectedChoiceConditionType'] = selectedFormElement['selectedChoiceConditionType'];
			}
			if(selectedFormElement['hasParent']) {
				if(selectedFormElement['selectedParentData']['question_id']){
					$(formContainer).find('._form-element').last().attr('data-parentQuesId', selectedFormElement['selectedParentData']['question_id']);
				}
			}

			$(formContainer).find('._form-element').last().attr('data-isparent', selectedFormElement['isParent']);

			if(selectedFormElement.hasOwnProperty('parentIndex')) $(formContainer).find('._form-element').last().attr('data-parentIndex', selectedFormElement['parentIndex']);
			var fileJSON = selectedFormElement;
			delete fileJSON['parentIndex'];
			delete fileJSON['parentAnswer'];
			delete fileJSON['parentQuestion'];
			delete fileJSON['parentCriteriaChoice'];
			delete fileJSON['selectedParentData'];
			if(fileJSON.hasOwnProperty('selectedChoiceConditionType')) delete fileJSON['selectedChoiceConditionType'];

			var fileJSONString = JSON.stringify(fileJSON);
			$(formContainer).find('._form-element').last().attr('data-json', fileJSONString);

			if(fileJSON['helpText']) $(formContainer).find('._form-element').last().attr('title', fileJSON['helpText']);
			var fileParentJSONString = JSON.stringify(fileParentJSON);

			$(formContainer).find('._form-element').last().attr('data-parent', fileParentJSONString);
			addSettingsToElements($(formContainer).find('._form-element').last(), 'fileInput', fileInputSettings, formElementIndex);

			$(formContainer).find('._form-element').last().find('#fileLabel').html(fileJSON['label']);
			$(formContainer).find('._form-element').last().find('#fileType').attr('accept', fileJSON['fileType']);
			$(formContainer).find('._form-element').last().find('._required-star').attr('data-required', fileJSON['required']);
		} else if(selectedFormElement['type'] == 'pagebreak') {
			$(formContainer).append(pagebreakInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var pagebreakJSON = selectedFormElement;
			delete pagebreakJSON['parentIndex'];
			delete pagebreakJSON['parentAnswer'];
			delete pagebreakJSON['parentQuestion'];
			delete pagebreakJSON['parentCriteriaChoice'];
			delete pagebreakJSON['selectedParentData'];
			if(pagebreakJSON.hasOwnProperty('selectedChoiceConditionType')) delete pagebreakJSON['selectedChoiceConditionType'];

			var pagebreakJSONString = JSON.stringify(pagebreakJSON);
			$(formContainer).find('._form-element').last().attr('data-json', pagebreakJSONString);
		} else if(selectedFormElement['type'] == 'content') {
			$(formContainer).append(contentInput);
			var formElementIndex = i;
			$(formContainer).find('._form-element').last().attr('data-index', formElementIndex);
			if(selectedFormElement['question_id']){
				$(formContainer).find('._form-element').last().attr('data-questionId', selectedFormElement['question_id']);
			}
			var contentJSON = selectedFormElement;
			delete contentJSON['parentIndex'];
			delete contentJSON['parentAnswer'];
			delete contentJSON['parentQuestion'];
			delete contentJSON['parentCriteriaChoice'];
			delete contentJSON['selectedParentData'];
			if(contentJSON.hasOwnProperty('selectedChoiceConditionType')) delete contentJSON['selectedChoiceConditionType'];

			var contentJSONString = JSON.stringify(contentJSON);
			$(formContainer).find('._form-element').last().attr('data-json', contentJSONString);

			addSettingsToElements($(formContainer).find('._form-element').last(), 'contentInput', contentInputSettings, formElementIndex);
			$(formContainer).find('._form-element').last().find('p').html(contentJSON['contentText']);
		}
	}
}

function getDistance(lat1, lon1, lat2, lon2) {
	var R = 6371e3; // metres
	var φ1 = lat1 * Math.PI / 180;
	var φ2 = lat2 * Math.PI / 180;
	var Δφ = (lat2-lat1) * Math.PI / 180;
	var Δλ = (lon2-lon1) * Math.PI / 180;

	var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
					Math.cos(φ1) * Math.cos(φ2) *
					Math.sin(Δλ/2) * Math.sin(Δλ/2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	var d = R * c;

	if(d > 1000) d = (parseFloat(Number(d/1000).toFixed(2))).toLocaleString() + ' km';
	else d = (parseFloat(Number(d).toFixed(2))).toLocaleString() + ' m';
	return d;
}