import React from 'react';
import IMSingleProjectSidebar from '../1-projects/IMSingleProjectSidebar';
import KeyIndicators from "./KeyIndicators";
import ProjectUpdates from "./ProjectUpdates";
import DataModal from './components/DataModal';
import GraphModal from './components/GraphModal';
import { Row } from 'react-bootstrap';
import Modal from 'react-responsive-modal';
import _ from 'lodash';

export default class Impact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTabKey: 1,
			dataPlot: null,
			graphPlot: null,
			key_indicators: true,
			product_updates: false,
			openDataModal: false,
			openGraphModal: false,
			key_indicatorClass: {
				backgroundColor: 'rgb(239, 90, 32)',
				color: '#333333',
				fontWeight: '700',
			},
			proj_updatesClass: {
				backgroundColor: '#F3F3F3',
				color: '#333333',
				fontWeight: '700',
				marginLeft: "20px"
			}
		}
	}

	showIndicators() {
		this.setState({
			key_indicators: true,
			product_updates: false,
			key_indicatorClass: {
				backgroundColor: 'rgb(239, 90, 32)',
				color: '#333333',
				fontWeight: '700',
			},
			proj_updatesClass: {
				backgroundColor: '#F3F3F3',
				color: '#333333',
				fontWeight: '700',
				marginLeft: "20px"
			}
		});
	}

	showProjectUpdates() {
		this.setState({
			key_indicators: false,
			product_updates: true,
			key_indicatorClass: {
				backgroundColor: '#F3F3F3',
				color: '#333333',
				fontWeight: '700',
			},
			proj_updatesClass: {
				backgroundColor: 'rgb(239, 90, 32)',
				color: '#333333',
				fontWeight: '700',
				marginLeft: "20px"
			}
		});
	}

	onCloseDataModal(event) {
		this.setState({ openDataModal: false, dataPlot: null })
	}

	changeActiveTab(key) {
		if (key !== this.state.activeTabKey) this.setState({ activeTabKey: key, historyExpenses: null });
	}

	openDataModal(data) {
		this.setState({ openDataModal: true, dataPlot: data })
	}

	onCloseGraphModal() {
		this.setState({ openGraphModal: false, graphPlot: null })
	}

	openGraphModal(data) {
		this.setState({ openGraphModal: true, graphPlot: data })
	}

	render() {
		return (
			<div className="im-container im-project-page im-project-finances-page">
				<Modal open={this.state.openDataModal} onClose={this.onCloseDataModal.bind(this)} center>
					<DataModal dataArray={this.state.dataPlot} />
				</Modal>

				<Modal open={this.state.openGraphModal} onClose={this.onCloseGraphModal.bind(this)} center>
					<GraphModal dataArray={this.state.graphPlot} />
				</Modal>

				<IMSingleProjectSidebar selected='impact' project_id={this.props.match.params.project_id} />
				<div className="im-project-container im-project-finance-container">
					<div className="finance-tabs-container" style={{ display: 'inline' }}>
						<button className={this.state.activeTabKey === 1 ? "btn finance-tab-btn selected" : "btn finance-tab-btn"} style={{ marginLeft: "15px" }} onClick={this.changeActiveTab.bind(this, 1)}>
							KEY INDICATORS
						</button>
						<button id="projectUpdates" className={this.state.activeTabKey === 2 ? "btn finance-tab-btn selected" : "btn finance-tab-btn"} style={{ marginLeft: "12px" }} onClick={this.changeActiveTab.bind(this, 2)}>
							PROJECT UPDATES
						</button>
					</div>
					<div className="finance-tabs-content">
						{this.state.activeTabKey === 1 ? (
							<div className="tab-content budget-tab-content">

								<KeyIndicators project_id={this.props.match.params.project_id}
									keyIndicators={this.state.key_indicators}
									ShowIndicators={this.showIndicators.bind(this)}
									ShowProjectUpdates={this.showProjectUpdates.bind(this)}
									onCloseDataModal={this.openDataModal.bind(this)}
									openGraphModal={this.openGraphModal.bind(this)}
								/>


							</div>
						) : (null)}

						{this.state.activeTabKey === 2 ? (
							<div>
								<Row className="tab-content history-tab-content">
									<div className="history-finance-data">

										<ProjectUpdates
											project_id={this.props.match.params.project_id}
											userData={this.props.userData}
										// ProductUpdates={this.state.product_updates}
										// ShowIndicators={this.showIndicators.bind(this)}
										// ShowProjectUpdates={this.showProjectUpdates.bind(this)}
										/>

									</div>

								</Row>


							</div>
						) : (null)}

					</div>
				</div>
			</div>

		)
	}
}
