import React, {Component} from 'react';

export default class Loader extends Component {
  render() {
  	if(this.props.backgroundColor !== undefined) {
  		return (
	      <div style={{backgroundColor: this.props.backgroundColor}} className={'content-loader ' + this.props.loaderHiding}></div>
	    );
  	} else {
	    return (
	      <div className={'content-loader ' + this.props.loaderHiding}></div>
	    );
	  }
  }
}