import React, {Component} from 'react';
import {Row, Tabs, Tab} from 'react-bootstrap';
import SingleResponseTable from './SingleResponseTable';
// import FormGallary from './FormGallary';

const readCookie = require('../../partials/cookie.js').readCookie;

export default class IndividualResponse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			activeKey: 1,
			formData: []
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/fb/get/responses/' + this.props.ngo.ngo_id + '/' + this.props.match.params['id'] + '?limit=20&skip=0',{
			method: 'POST',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			if(data.status === 'ok'){
				this.setState({title: data.form_name, formData: data.data});
			}
		});
	}

	handleSelect(selectedKey) {
		this.setState({ activeKey: selectedKey });
	}

	render(){
		return (
			<div className="fb-single-response-container">
				<Row className="fb-single-response-content-container">
					<div className="fb-single-response-content">
						<div className="fb-single-response-title">
							Title: {this.state.title.charAt(0).toUpperCase() + this.state.title.slice(1)}
						</div>
					</div>
					<Tabs activeKey={this.state.activeKey} id="response-tab" onSelect={this.handleSelect.bind(this)}>
						<Tab eventKey={1} title={<div className="response-header"><i className="fa fa-flag"></i><span> Individual Responses</span></div>}>
						  <SingleResponseTable ngo={this.props.ngo} formId={this.props.match.params['id']}/>
						</Tab>
						{/*<Tab eventKey={2} title={<div className="response-header"><span> Summary</span></div>}>
						  <SettingsEmailNotifications userData={this.props.userData} ngo={this.props.ngo} updateNgoData={this.props.updateNgoData} />
						</Tab>*/}
						{/*<Tab eventKey={3} title={<div className="response-header"><i className="fa fa-picture-o"></i><span> Media Gallary</span></div>}>
						  <FormGallary ngo={this.props.ngo}/>
						</Tab>*/}
					</Tabs>
				</Row>
			</div>
		);
	}
}
