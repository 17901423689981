import React from 'react';
import SwitchButton from 'react-switch-button';

import DashboardDonationChart from './DashboardDonationChart';
import DashboardDonorChart from './DashboardDonorChart';
import DashboardQuicklink from './DashboardQuicklink';
import DashboardTasks from './DashboardTasks';
import Loader from '../Loader';

const readCookie = require('../cookie.js').readCookie;

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      donationChartData: null,
      donorChartData: null,
      donationsCount: 0,
      donationsTotalAmount: 0,
      donorsCount: 0,
      listsCount: 0,
      donorDonationSwitchChecked: true,
      donor_count_acc_to_categories: [],
      currency: null,
      loadingDonations: true,
      loadingDonors: true,
      loaderHiding: '',
      donorListId: ''
    };
  }

  componentWillMount() {
    fetch(process.env.REACT_APP_API_URL + '/ngo/'+ this.props.ngo['ngo_id'] +'/get_dashboard_data',{
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      let tempDonations = data['allDonations'];
      for (let i = 0; i < tempDonations.length; i++) {
        let date = new Date(tempDonations[i]['name']);
        let monthCount = date.getUTCMonth();
        let day = date.getUTCDate();
        let year = date.getUTCFullYear();
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        let month = monthNames[monthCount];
        tempDonations[i]['name'] = day + ' ' + month + ' ' + year;
        tempDonations[i]['Amount'] = tempDonations[i]['amount'];
        delete tempDonations[i]['amount'];
      }
      tempDonations.sort(function(a,b){
        var c = new Date(a.name);
        var d = new Date(b.name);
        return c-d;
      });
      var finalDonations;
      function unique(tempDonations) {
        var a = [];
        var l = tempDonations.length;
        for(var i=0; i<l; i++) {
          for(var j=i+1; j<l; j++) {
              if (tempDonations[i]['name'] === tempDonations[j]['name']) {
                tempDonations[j]['Amount'] += tempDonations[i]['Amount']
                j = ++i;
              }
          }
          a.push(tempDonations[i]);
        }
        return a;
      }
      finalDonations = unique(tempDonations);
      this.setState({
        donationChartData: finalDonations,
        donationsCount: data['donationsCount'],
        donationsTotalAmount: data['donationsTotalAmount'],
        donorsCount: data['donorsCount'],
        currency: data['currency'],
        donor_count_acc_to_categories: data['donor_count_acc_to_categories'],
        listsCount: data['listsCount'],
        donorListId: data['donorListId'],
        loadingDonations: false
      }, function() {
        if(!this.state.loadingDonors) this.setState({ loaderHiding: 'hiding' });
      }.bind(this));
    }.bind(this))
    fetch(process.env.REACT_APP_API_URL + '/ngo/'+ this.props.ngo['ngo_id'] +'/donors', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      let tempDonors = [];
      for (let i = 0; i < data['donors'].length; i++) {
        var tempDonor = {};
        let date = new Date(data['donors'][i]['added_on']);
        let monthCount = date.getUTCMonth();
        let day = date.getUTCDate();
        let year = date.getUTCFullYear();
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        let month = monthNames[monthCount];
        tempDonor['Date'] = day + ' ' + month + ' ' + year;
        tempDonor['Count'] = 1;
        tempDonor['timestamp'] = date;
        tempDonors.push(tempDonor);
      }

      tempDonors.sort(function(a,b){
        var c = new Date(a.name);
        var d = new Date(b.name);
        return c-d;
      });
      var finalDonors;
      function uniqueDonors(tempDonors) {
        var a = [];
        var l = tempDonors.length;
        for(var i=0; i<l; i++) {
          for(var j=i+1; j<l; j++) {
            if (tempDonors[i]['Date'] === tempDonors[j]['Date']) {
              tempDonors[j]['Count'] += tempDonors[i]['Count'];
              j = ++i;
            }
          }
          a.push(tempDonors[i]);
        }
        return a;
      }
      finalDonors = uniqueDonors(tempDonors);
      this.setState({
        donorChartData: finalDonors,
        loadingDonors: false
      }, function() {
        if(!this.state.loadingDonations) this.setState({ loaderHiding: 'hiding' });
      }.bind(this));
    }.bind(this));
  }

  onChangeSwitch = ()=>{
    this.setState({donorDonationSwitchChecked: !this.state.donorDonationSwitchChecked});
  }

  render() {
    if(this.state.donationChartData !== null && this.state.donorChartData !== null) {
      return (
        <div>
          {this.state.loadingDonations || this.state.loadingDonors ? (
            <Loader loaderHiding={this.state.loaderHiding} />
          ) : ('')}
          <div className="page-container donor-donation-page text-align-right">
            <SwitchButton name="donor-donation-switch" mode="select" labelRight="Show Donations" label="Show Donors" defaultChecked={this.state.donorDonationSwitchChecked} onChange={this.onChangeSwitch} />
            {(() => {
              if(this.state.donorDonationSwitchChecked) {
                return (
                  <div className="donations-graph-page">
                    <DashboardDonationChart ngo={this.props.ngo} chartData={this.state.donationChartData} currency={this.state.currency} donationsCount={this.state.donationsCount} />
                    <DashboardQuicklink quickLink={this.state} />
                    {this.props.ngo.plan.name === 'premium' ? (
                      <DashboardTasks ngo={this.props.ngo} />
                    ) : (null)}
                  </div>
                );
              }
            })()}
            {(() => {
              if(!this.state.donorDonationSwitchChecked) {
                return (
                  <div className="donors-graph-page">
                    <DashboardDonorChart ngo={this.props.ngo} chartData={this.state.donorChartData} donationsCount={this.state.donorsCount} />
                    <DashboardQuicklink quickLink={this.state} />
                    {this.props.ngo.plan.name === 'premium' ? (
                      <DashboardTasks ngo={this.props.ngo} />
                    ) : (null)}
                  </div>
                );
              }
            })()}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
