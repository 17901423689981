import React, { Component } from "react";
import { Button, Row } from "react-bootstrap";
import ImportDonors from './ImportDonors';
import $ from 'jquery';
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../cookie.js').readCookie;

export default class ImportDonorsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubmitButton: false,
      systemHeaders: null,
      customSystemHeaders: null,
      data_id: null,
      donorCategories: [],
      donor_category: '',
      donor_category_name: '',
      showSubmittingLoader: false
    };
  }

  showSubmitButton() {
    this.setState({ showSubmitButton: true });
  }

  updateSystemHeaders(systemHeaders) {
    this.setState({ systemHeaders });
  }

  updateCustomSystemHeaders(customSystemHeaders) {
    this.setState({ customSystemHeaders });
  }

  updateDataId(data_id) {
    this.setState({ data_id });
  }

  submitHeaderMappings() {
    this.setState({ showSubmittingLoader: true });
    let systemHeaders = this.state.systemHeaders;
    let customSystemHeaders = this.state.customSystemHeaders;

    let mappings = {};
    let custom_system_mappings = {};
    let custom_mappings = [];

    Object.keys(systemHeaders).map(function(value) {
      let systemHeader = systemHeaders[value];
      let key_used_in_csv = $('.select-donor-data-headers').find('.system-headers-div').eq(value).find('ul > li').html()
      let system_key_array = Object.keys(systemHeader);
      let system_key = system_key_array[0];
      if(key_used_in_csv !== undefined) mappings[system_key] = key_used_in_csv;
    });

    Object.keys(customSystemHeaders).map(function(value) {
      let customSystemHeader = customSystemHeaders[value];
      let key_used_in_csv = $('.select-donor-data-headers').find('.custom-system-headers-div').eq(value).find('ul > li').html()
      let system_key_array = Object.keys(customSystemHeader);
      let system_key = system_key_array[0];
      if(key_used_in_csv !== undefined) custom_system_mappings[system_key] = key_used_in_csv;
    });

    $('.select-donor-data-headers').find('div.system-headers-div.other-headers-container > ul').find('li').each(function() {
      custom_mappings.push($(this).html());
    });

    let donor_category_obj = {};
    donor_category_obj['id'] = window.location.href.split('/lists/')[1];

    let data = {};
    data['data_id'] = this.state.data_id;
    data['mappings'] = mappings;
    data['custom_mappings'] = custom_mappings;
    data['custom_system_mappings'] = custom_system_mappings;
    data['donor_category'] = donor_category_obj;

    fetch(process.env.REACT_APP_API_URL + '/save_sheet/' + this.props.ngo_id, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify(data)
    }).then((response) => response.json())
    .then(function(data) {
      this.props.onImportClose();
      this.setState({ showSubmittingLoader: false });
      swal({
        text: data['message'],
        type: 'success',
      });
    }.bind(this));
  }

  render() {
    return (
      <div className="page-container import-donor-page">
        {this.props.showImportDonorModal ? (
          <div>
            <h2 className="modal-header-custom">
              Import Members
            </h2>
            <Row>
              <ImportDonors ngo={this.props.ngo} ngo_id={this.props.ngo_id} showSubmitButton={this.showSubmitButton.bind(this)}
                updateSystemHeaders={this.updateSystemHeaders.bind(this)} updateCustomSystemHeaders={this.updateCustomSystemHeaders.bind(this)}
                updateDataId={this.updateDataId.bind(this)} />
            </Row>
            <Row className="text-center mt30">
              <Button disabled={this.state.showSubmittingLoader} className="btn-danger mr5 ml5 mt30" onClick={this.props.onImportClose}>Cancel Import</Button>
              {this.state.showSubmitButton ? (
                !this.state.showSubmittingLoader ? (
                  <Button className="mr5 ml5 mt30" onClick={this.submitHeaderMappings.bind(this)}>
                    Submit
                  </Button>
                ) : (
                  <Button disabled className="mr5 ml5 mt30" onClick={this.submitHeaderMappings.bind(this)}>
                    Submitting
                    <i className="fa fa-spin fa-spinner"></i>
                  </Button>
                )
              ) : (null)}
            </Row>
          </div>
        ) : (null)}
      </div>
    );
  }
}