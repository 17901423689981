import React, {Component} from 'react';
import {Row, Col, ButtonGroup} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import swal from "sweetalert2";

const readCookie = require('../../cookie.js').readCookie;

export default class SingleDonorTasks extends Component {
	markCompleted(value) {
		this.setState({ value_id: value });
		fetch(process.env.REACT_APP_API_URL + '/update_task/' + value, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify({ status : "completed" })
		}).then((response) => response.json())
		.then((data) => {
			fetch(process.env.REACT_APP_API_URL + '/tasks/'+ this.props.ngo['ngo_id'], {
				method: "GET",
				headers: {
					'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
				}
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				this.setState({ data: data['tasks'], filteredData: data['tasks'].slice(0) });
			}.bind(this));
		});
	}

	onDelete(value) {
		this.setState({ value_id: value.id });
		swal({
			title: 'Are you sure?',
	    text: "You won't be able to revert this!",
	    type: 'warning',
	    showCancelButton: true,
	    confirmButtonText: 'Yes, Delete!',
	    cancelButtonText: 'No, Cancel!',
	    confirmButtonClass: 'btn',
	    cancelButtonClass: 'btn',
		}).then(function (result) {
			if(result) {
				fetch(process.env.REACT_APP_API_URL + '/update_task/' + value.id, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify({ is_deleted : true })
				}).then((response) => response.json())
				.then((data) => {
					if(data) {
						fetch(process.env.REACT_APP_API_URL + '/tasks/'+ this.props.ngo['ngo_id'], {
							method: "GET",
							headers: {
								'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
							}
						}).then(function(response) {
							return response.json();
						}).then(function(data) {
							if(data['tasks']) {
								this.setState({ data: data['tasks'], filteredData: data['tasks'].slice(0) });
							} else {
								this.setState({ data: [], filteredData: [] });
							}
						}.bind(this));
					}
				})
			}
		}.bind(this));
	}

	onEdit(value) {
		this.setState({ value_id: value.id });
		this.onEditOpen();
	}


	getCompletedButton(value ,id) {
		if(value !== 'Completed') {
			return(
				<button onClick={() => {this.markCompleted(id)}}
					type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'}>
					<span>Mark As Complete</span>
				</button>
			)
		}
	}

	getEditButton(value , id) {
		if(value !== 'Completed') {
			return(
				<button type='button' onClick={() => {this.onEdit(id)}}
				  className={'btn btn-success react-bs-table-add-btn hidden-print'}>
          <span>
            Edit
          </span>
				</button>
			)
		}
	}

	getEndDateFormat(date) {
		date = date.split(" ")
		return(date[0] + " " +date[1] +" "+ date[2] + " " + date[3])
	}

	getStopButton(value, id) {
		if(value !== 'Completed') {
			return(
				<button type='button' onClick={() => {this.onStop(id)}}
				  className={'btn btn-success react-bs-table-add-btn hidden-print'}>
          <span>
            Stop
          </span>
				</button>
			)
		}
	}

	onStop(id) {
		swal({
			title: 'Are you sure?',
	    text: "You won't be able to revert this!",
	    type: 'warning',
	    showCancelButton: true,
	    confirmButtonText: 'Yes, Delete!',
	    cancelButtonText: 'No, Cancel!',
	    confirmButtonClass: 'btn',
	    cancelButtonClass: 'btn',
		}).then(function (result) {
			if(result) {
				fetch(process.env.REACT_APP_API_URL + '/tasks/' + this.props.ngo['ngo_id'] + '/stop_sys_task/' + id, {
					method: 'GET',
					headers: {
						'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
					},
				}).then((response) => response.json())
				.then((data) => {
					fetch(process.env.REACT_APP_API_URL + '/tasks/'+ this.props.ngo['ngo_id'], {
						method: "GET",
						headers: {
							'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
						}
					}).then(function(response) {
						return response.json();
					}).then(function(data) {
						this.setState({ data: data.tasks, filteredData: data.tasks.slice(0) });
					}.bind(this));
				});
			}
		}.bind(this));
	}

  render() {
  	if(this.props.tasks.length) {
	    return (
	      <div>
	      	<Row className="task-container mt20">
						{this.props.tasks.map((value, i) => {
							var newClass = '';
							if('is_system' in value) newClass = 'system-generated';
							return (
								<div className="single-task" key={i}>
									<Col md={12}>
										<div className={newClass + ' task-box'}>
											<Row>
												<Col md={12} className="task-header-container">
													<span className="task-title">
														<Link to={"/dm/tasks/details/" + value.id}>{value.title}</Link>
													</span>
													<span className="task-date">
														<span className="assigned-title">Due Date: </span>
														{this.getEndDateFormat(value.due_on)}
													</span>
												</Col>
												<Col md={12}>
													<p className="task-desc">
														{value.description}
													</p>
												</Col>
												<Col md={12} className="assigned-box">
													<Row>
														{(() => {
		                          if(Object.keys(value.assigned_for).length) {
		                          	return (
																	<Col md={6}>
																		<Row>
				                              <div className="assigned-for assigned-desc">
				                                <span className="assigned-title">Assigned For:</span>
				                                {(() => {
					                                if('groups' in value.assigned_for) {
						                                return value.assigned_for.groups.map((group, i) => {
						                                	return (
						                                		<span key={i} className="group-tag-name" data-group-id={group.id}>{group.name}</span>
						                                	);
						                                })
						                              }
						                            })()}
						                            {(() => {
					                                if('users' in value.assigned_for) {
						                                return value.assigned_for.users.map((user, i) => {
						                                	return (
						                                		<span key={i} className="user-tag-name" data-user-id={user.id}>{user.name}</span>
						                                	);
						                                })
						                              }
						                            })()}
				                              </div>
																		</Row>
																	</Col>
																);
															}
														})()}
														<Col md={6}>
															<div className="assigned-to assigned-desc">
																<span className="assigned-title">Assigned To:</span>
																{(() => {
	                                if('groups' in value.assigned_to) {
		                                return value.assigned_to.groups.map((group, i) => {
			                              	return (
			                              		<span key={i} className="group-tag-name" data-group-id={group.id}>{group.name}</span>
			                              	);
	                            			})
	                              	}
			                          })()}
			                          {(() => {
	                                if('users' in value.assigned_to) {
		                                return value.assigned_to.users.map((user, i) => {
			                              	return (
			                              		<span key={i} className="user-tag-name" data-user-id={user.id}>{user.name}</span>
			                              	);
			                              })
		                              }
		                            })()}
		                            {(() => {
	                                if('id' in value.assigned_to) {
		                                if(!value.assigned_to.id) {
		                                	return (
		                                		<span className="user-tag-name" data-user-id={value.assigned_to.id}>{value.assigned_to.name}</span>
		                                	);
		                                }
		                              }
		                            })()}
															</div>
														</Col>
													</Row>
												</Col>
												<Col md={12}>
													<Row>
														<Col md={4}>
															<span className="created-by">
																<span className="assigned-title">Creator: </span>
																<span className={"assigned-desc creator " + newClass}>{value.creator_user_id['name']}</span>
															</span>
														</Col>
														<Col md={4}>
															<span>
																<span className="assigned-title">
																	<i className="fa fa-comments-o" aria-hidden="true"></i>
																	{' Total Comments: '}
																</span>
																<span className="assigned-desc">{value.comments.length}</span>
															</span>
														</Col>
														<Col md={4}>
															<span className="created-by">
																<span className="assigned-title">Current Status:</span>
																<span className="assigned-desc">
	                                <span>{' ' + value.status}</span>
																</span>
	                            </span>
														</Col>
													</Row>
												</Col>
												{(() => {
													if('is_system' in value) {
														return (
															<ButtonGroup className='my-custom-class task-buttons btn-group-md'>
																{this.getStopButton(value.status, value.id)}
															</ButtonGroup>
														);
													} else {
														return (
															<ButtonGroup className='my-custom-class task-buttons btn-group-md'>
																{this.getCompletedButton(value.status, value.id)}
																{this.getEditButton(value.status, value)}
																{(() => {
																	if(this.props.userData.user_id === value.creator_user_id.id) {
																		return (
																			<button type='button' onClick={() => {this.onDelete(value)}}
																		  	className={'btn btn-success react-bs-table-add-btn hidden-print'}>
																				<span>Delete</span>
																			</button>
																		);
																	}
																})()}
															</ButtonGroup>
														);
													}
												})()}
											</Row>
										</div>
									</Col>
								</div>
							);
						})}
					</Row>
	      </div>
	    );
		} else {
			return (
				<Row className="task-container">
					<div className="no-tasks">
						<h4>No Tasks Yet</h4>
					</div>
				</Row>
			)
		}
  }
}