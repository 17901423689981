import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import moment from 'moment';
import swal from "sweetalert2";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {BootstrapTable, TableHeaderColumn, ExportCSVButton} from 'react-bootstrap-table';
import Loader from '../Loader';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import "sweetalert2/dist/sweetalert2.min.css";

var ProgressBar = require('react-progressbar.js');
var Line = ProgressBar.Line;

const readCookie = require('../cookie.js').readCookie;

export default class SingleCampaignDetailsStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
    	open_rate: null,
    	click_rate: null,
    	overview_stats: null,
    	unique_opens: null,
    	unique_clicks: null,
    	allLoaded: false,
    	campaign_name: '',
    	campaign_stats: null,
    	total_logs: null,
    	sizePerPage: 20,
      currentPage: 1,
      searchText: '',
      showLoader: true,
      loaderHiding: 'campaign-details-stats'
    }
  }

  componentDidMount() {
  	this.setState({ campaignId: this.props.match.params['name'] });
    fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/overview-stats/' + this.props.match.params['name'], {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
    	let campaign_name = data['stats']['campaign']['name'];
    	let unique_opens = data['stats']['unique']['opened'],
    	unique_clicks = data['stats']['unique']['clicked'];

    	let open_rate = parseFloat(0),
    	click_rate = parseFloat(0);

    	let totalDeliveredEmails = 0;
    	if (data['stats']['total'] && data['stats']['total']['delivered']) {
    		totalDeliveredEmails = data['stats']['total']['delivered'];
    	}

    	if(totalDeliveredEmails !== 0) {
	    	open_rate = parseFloat(unique_opens / totalDeliveredEmails);
	    	click_rate = parseFloat(unique_clicks / data['stats']['total']['delivered']);
    	}

    	this.setState({
    		open_rate,
    		campaign_name,
    		click_rate,
    		total: data['stats']['total'],
    		unique: data['stats']['unique'],
    		unique_opens,
    		unique_clicks
    	}, function() {
    		this.setState({ allLoaded: true });
    	});
    }.bind(this));

    let query = '?page=' + (this.state.currentPage - 1) + '&per_page=' + this.state.sizePerPage;
    fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/detailed-stats/' + this.props.match.params['name'] + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
    	this.setState({ campaign_stats: data['logs']['logs'], total_logs: data['logs']['total_logs'], loaderHiding: 'hiding campaign-details-stats' }, function() {
        setTimeout(function() {
          this.setState({ showLoader: false });
        }.bind(this), 200);
      });
    }.bind(this));
  }

  emailFormatter(cell, row) {
  	return <a href={window.location.pathname.split('/stats')[0] + '/' + row.id + '/stats'}>{cell}</a>;
  }

  createCustomClearButton(onClick) {
    return (
      <span onClick={onClick}>
  	    <i className="clear-table-search glyphicon glyphicon-remove"></i>
      </span>
    );
  }

  firstNameFormatter(cell, row) {
  	return <a href={window.location.pathname.split('/stats')[0] + '/' + row.id + '/stats'}>{row.first_name}</a>;
  }

  lastNameFormatter(cell, row) {
    return <a href={window.location.pathname.split('/stats')[0] + '/' + row.id + '/stats'}>{row.last_name}</a>;
  }

  createCustomExportCSVButton() {
  	return (
      <ExportCSVButton onClick={this.exportToEmail.bind(this)} />
    );
  }

  exportToEmail() {
    swal({
      title: 'Are you sure?',
      text: "You will get an email with unique opens/clicks details!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Export!',
      cancelButtonText: 'No, Cancel!',
      confirmButtonClass: 'btn',
      cancelButtonClass: 'btn',
    }).then(function (result) {
      if(result) {
        fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/detailed-stats-to-csv/' + this.props.match.params['name'], {
          method: "GET",
          headers: {
            'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
          }
        }).then(function(response) {
          return response.json();
        }).then(function(data) {
          if(data.status === 'ok') {
            swal({
              title: 'Email sent successfully!',
              text: '',
              type: 'success'
            });
          } else {
            swal({
              title: 'Email export failed!',
              text: 'There was no data found to be exported.',
              type: 'error'
            });
          }
        });
      }
    }.bind(this));
  }

  onPageChange(page, sizePerPage) {
    this.setState({ showLoader: true, loaderHiding: 'data-update campaign-details-stats' });

    let query = '?page=' + (page - 1) + '&per_page=' + sizePerPage;
    fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/detailed-stats/' + this.props.match.params['name'] + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
    	this.setState({ campaign_stats: data['logs']['logs'], currentPage: page, loaderHiding: 'hiding campaign-details-stats' }, function() {
        setTimeout(function() {
          this.setState({ showLoader: false });
        }.bind(this), 100);
      });
    }.bind(this));
  }

  onSizePerPageList(sizePerPage) {
    this.setState({ showLoader: true, loaderHiding: 'data-update campaign-details-stats', currentPage: 1 });

    let query = '?page=0&per_page=' + sizePerPage;
    fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/detailed-stats/' + this.props.match.params['name'] + query, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
    	this.setState({ campaign_stats: data['logs']['logs'], sizePerPage, loaderHiding: 'hiding campaign-details-stats' }, function() {
        setTimeout(function() {
          this.setState({ showLoader: false });
        }.bind(this), 100);
      });
    }.bind(this));
  }

  onSortChange(sortBy, sortType) {
    if(sortBy === 'opens' || sortBy === 'clicks') {
      this.setState({ sortBy, sortType, showLoader: true, loaderHiding: 'data-update campaign-details-stats', currentPage: 1, searchText: '' });

      let query = '?page=0&per_page=' + this.state.sizePerPage + '&sort_by=' + sortBy + '&sort_type=' + sortType;

      fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/detailed-stats/' + this.props.match.params['name'] + query, {
        method: "GET",
        headers: {
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        }
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        this.setState({ campaign_stats: data['logs']['logs'], total_logs: data['logs']['total_logs'], loaderHiding: 'hiding campaign-details-stats' }, function() {
          setTimeout(function() {
            this.setState({ showLoader: false });
          }.bind(this), 200);
        });
      }.bind(this));
    }
  }

  gotoStatType(type) {
    var new_pathname = window.location.pathname + '/' + type;
    window.location.pathname = new_pathname;
  }

  render() {
  	let backUrl = window.location.pathname.split('/stats')[0];
  	var options = {
      strokeWidth: 4,
		  easing: 'easeInOut',
		  duration: 2500,
		  trailColor: '#ffd3c1',
		  trailWidth: 4,
		  svgStyle: {width: '100%', height: '100%'},
		  from: {color: '#ff8e8e'},
		  to: {color: '#ef5a20'},
		  text: {
		    style: {
		      color: '#ef5a20',
		      position: 'absolute',
		      right: '0',
		      padding: 0,
		      margin: '-40px 40px 0 0',
		      fontWeight: 600,
		      transform: null
		    },
		    autoStyleContainer: false
		  },
		  step: (state, bar) => {
		    bar.path.setAttribute('stroke', state.color);
		    bar.setText((bar.value() * 100).toFixed(1) + ' %');
		  }
    };
    var containerStyle = {
      width: 'calc(100% - 20px)',
	    height: '15px',
	    margin: '10px 10px 20px 2px'
    };
    var tableOptions = {
    	exportCSVBtn: this.createCustomExportCSVButton.bind(this),
      clearSearch: true,
      onSortChange: this.onSortChange.bind(this),
      // clearSearchBtn: this.createCustomClearButton,
      // sizePerPage: 20,
      // sizePerPageList: [20, 50, 100]
      page: this.state.currentPage,
      onPageChange: this.onPageChange.bind(this),
      sizePerPage: this.state.sizePerPage,
      sizePerPageList: [
        { text: '20 Per Page', value: 20 },
        { text: '50 Per Page', value: 50 },
        { text: '100 Per Page', value: 100 }
      ],
      onSizePerPageList: this.onSizePerPageList.bind(this),
    };

    if(this.state.allLoaded) {
    	return (
	      <div className="page-container single-campaign-detail-stats">
	      	<div>
	          <span className="back">
	            <Link to={backUrl} className="back-btn"><i className="fa fa-chevron-left"></i> Back To Campaign</Link>
	          </span>
	      	</div>
	      	<Row className="mb10 mt20">
	      		<Col md={12}>
	      			<Col md={6}>
	      				<span>Open Rate</span>
	      				<Line options={options}
	                progress={this.state.open_rate}
	                initialAnimate={true}
	                containerStyle={containerStyle}
	                containerClassName={'progressbar'} />
	      			</Col>

	      			<Col md={6}>
	      				<span>Click rate</span>
	      				<Line options={options}
	                progress={this.state.click_rate}
	                initialAnimate={true}
	                containerStyle={containerStyle}
	                containerClassName={'progressbar'} />
	      			</Col>
	      		</Col>
	      	</Row>

	      	<Row className="mb10 mt20">
            {this.state.unique_opens ? (
  	      		<Col md={3} className="stats-row-2 special">
  	      			<div className="value pointer" onClick={this.gotoStatType.bind(this, 'opened')}>{this.state.unique_opens.toLocaleString()}</div>
  	      			<div className="heading">Unique Opened</div>
  	      		</Col>
            ) : (
              <Col md={3} className="stats-row-2">
                <div className="value">{0}</div>
                <div className="heading">Unique Opened</div>
              </Col>
            )}

            {this.state.unique_clicks ? (
  	      		<Col md={3} className="stats-row-2 special">
  	      			<div className="value pointer" onClick={this.gotoStatType.bind(this, 'clicked')}>{this.state.unique_clicks.toLocaleString()}</div>
  	      			<div className="heading">Unique Clicked</div>
  	      		</Col>
            ) : (
              <Col md={3} className="stats-row-2">
                <div className="value">{0}</div>
                <div className="heading">Unique Clicked</div>
              </Col>
            )}

            {this.state.total.bounced ? (
  	      		<Col md={3} className="stats-row-2 special">
  	      			<div className="value pointer" onClick={this.gotoStatType.bind(this, 'bounced')}>{this.state.total.bounced}</div>
  	      			<div className="heading">Bounced</div>
  	      		</Col>
            ) : (
              <Col md={3} className="stats-row-2">
                <div className="value">{0}</div>
                <div className="heading">Bounced</div>
              </Col>
            )}

            {this.state.total.dropped ? (
  	      		<Col md={3} className="stats-row-2 special">
  	      			<div className="value pointer" onClick={this.gotoStatType.bind(this, 'dropped')}>{this.state.total.dropped}</div>
  	      			<div className="heading">Dropped</div>
  	      		</Col>
            ) : (
              <Col md={3} className="stats-row-2">
                <div className="value">{0}</div>
                <div className="heading">Dropped</div>
              </Col>
            )}
	      	</Row>

	      	<Row className="mb10 mt20">
	      		<Col md={6} className="stats-row-3">
	      			<div className="pull-left">Successful Deliveries</div>
	      			<div className="pull-right">
                {this.state.total.delivered.toLocaleString() > 0 ? (
                  <span className="value pointer" onClick={this.gotoStatType.bind(this, 'delivered')}>{this.state.total.delivered.toLocaleString() + ' '}</span>
                ) : (
                  <span>{'0 '}</span>
                )}
	      				<span>{((this.state.total.delivered / this.state.total.sent) * 100).toFixed(1) + ' %'}</span>
	      			</div>
	      		</Col>
	      		<Col md={6} className="stats-row-3">
	      			<div className="pull-left">Clicks per Unique Opens</div>
	      			<div className="pull-right value">{isNaN(this.state.total.clicked / this.state.unique_opens) ? 0 : ((this.state.total.clicked / this.state.unique_opens) * 100).toFixed(1) + ' %'}</div>
	      		</Col>
	      		<Col md={6} className="stats-row-3">
	      			<div className="pull-left">Total Opens</div>
	      			<div className="pull-right value">{this.state.total.opened.toLocaleString()}</div>
	      		</Col>
	      		<Col md={6} className="stats-row-3">
	      			<div className="pull-left">Total Clicks</div>
	      			<div className="pull-right value">{this.state.total.clicked.toLocaleString()}</div>
	      		</Col>
	      	</Row>

	      	{this.state.campaign_stats !== null ? (
		      	<Row className="mb20 mt20 stats-row-4" style={{position: 'relative'}}>
		      		{this.state.showLoader ? (
                <Loader backgroundColor='rgba(255,255,255,0.7)' loaderHiding={this.state.loaderHiding} />
              ) : ('')}
		      		<Tabs>
						    <TabList>
						      <Tab>Show Unique Opens/Clicks Details</Tab>
						    </TabList>

						    <TabPanel>
						      <BootstrapTable data={this.state.campaign_stats} options={tableOptions} className="total-opens-table" exportCSV
						      	csvFileName={this.state.campaign_name + ' - Total Opens - ' + String(moment().unix()) + '.csv'}
						      	fetchInfo={{ dataTotalSize: this.state.total_logs }} remote={true} striped={false} pagination={true}
	                  hover={true} ref='table' search={false} searchPlaceholder="Search by Email/Total Opens">
	                  <TableHeaderColumn dataField="id" isKey={true} hidden={true} dataSort={false} searchable={false}>Donor Id</TableHeaderColumn>
	                  <TableHeaderColumn dataField="first_name" dataSort={false} searchable={true} dataFormat={this.firstNameFormatter.bind(this)}>Fist Name</TableHeaderColumn>
	                  <TableHeaderColumn dataField="last_name" hidden={false} dataSort={false} searchable={true} dataFormat={this.lastNameFormatter.bind(this)}>Last Name</TableHeaderColumn>
	                  <TableHeaderColumn dataField="email" dataSort={false} searchable={true} dataFormat={this.emailFormatter.bind(this)}>Email</TableHeaderColumn>
	                  <TableHeaderColumn dataField="opens" dataSort={true} searchable={true}>Total Opens</TableHeaderColumn>
	                  <TableHeaderColumn dataField="clicks" dataSort={true} searchable={true}>Total Clicks</TableHeaderColumn>
	                </BootstrapTable>
						    </TabPanel>
						  </Tabs>
		      	</Row>
		      ) : (null)}
	      </div>
	    );
    } else return null;
  }
}