import _ from 'lodash';
import React from 'react';
import Modal from 'react-responsive-modal';

import Alert from 'react-s-alert';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { storeUpdateList } from "../../../actions/updates";
import { SQUARE, RECTANGLE, CIRCLE, FETCH_ALL_UPDATES, LOCAL_SOCKET_URL } from "./constants/constants";

import SeeMore from "./components/seeMore";
import ImageModal from "./components/ImageModal";
import CommentBox from "./components/CommentBox";
import Pagination from "./components/Pagination";
import UserProfile from "./components/userProfile";
import AttachmentBox from "./components/AttachmentBox";
import CreateUpdates from "./components/CreateUpdates";
import ImageContainer from "./components/ImageContainer";
import ImageComponents from "./components/ImageComponents";
//sockets
import io from 'socket.io-client';
import { SocketProvider } from "socket.io-react";
import {  } from "./constants/constants";
import { setConnectionHandlers } from "./sockets/handlers";

const readCookie = require('../cookie.js').readCookie;

class ProjectUpdates extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			show_table: true,
			show_graph: false,
			graph_data: null,
			update_response: null,
			openModal: false,
			user_pik: "https://le-uploaded-image-bucket.s3.amazonaws.com/sahaj/profile_pictures/598b2b5548332244cee846bb/user.png",
			openModal: false,
			displayText: { display: 'block' },
			displayImage: { display: 'none' },
			imgArray: [],
			contenteditable: "false",
			editableClass: null,
			name: null,
			// projectUpdates: null,
			projectUpdates: [],
			page: 0,
			pageDefault: 5,
			totalPosts: 0,
			loading: false,
		}

		this.socket = io.connect(LOCAL_SOCKET_URL);

	}

	componentDidMount() {
		let pageDefault = this.state.pageDefault;
		let uri = process.env.REACT_APP_MIS_API_URL + "/project/allUpdates/" + this.props.project_id + `?perPage=${pageDefault}`;
		fetch(uri, {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		})
			.then(res => (res.json()))
			.then(res => {
				console.log("projectUpdates::", res.posts);
				let projectUpdates = res.posts;
				this.setState({
					projectUpdates: projectUpdates,
					totalPosts: res.totalPosts
				})
				this.props.storeUpdateList(projectUpdates);
			})

	}

	onCloseModal() {
		this.setState({ openModal: false });
	}
	showMoreImages(event) {
		console.log("showMoreImages:proj", event);

		let img_array = Object.assign(this.state.imgArray, event);
		this.setState(
			{
				openModal: true,
				imgArray: img_array
			}
		);
	}

	showPostImage() {
		this.setState({ displayText: { display: 'none' }, displayImage: { display: 'block' } })
	}

	addNewUpdate(update) {
		let projectUpdates = this.state.projectUpdates;
		projectUpdates.splice(0, 0, update);
		this.setState({ projectUpdates, totalPosts: this.state.totalPosts + 1 });
	}
	deleteUpdate(index) {
		let projectUpdates = this.state.projectUpdates;
		projectUpdates.splice(index, 1);
		this.setState({ projectUpdates, totalPosts: this.state.totalPosts - 1 });
	}
	editUpdate(index, text) {
		let projectUpdates = this.state.projectUpdates;
		projectUpdates[index].text = text
		this.setState({ projectUpdates });
	}

	showMoreUpdates() {
		let page = this.state.page;
		page++
		let pageDefault = this.state.pageDefault;
		this.setState({ loading: true });
		let uri = process.env.REACT_APP_MIS_API_URL + "/project/allUpdates/" + this.props.project_id + `?page=${page}` + `&perPage=${pageDefault}`;
		fetch(uri, {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		})
			.then(res => (res.json()))
			.then(res => {
				console.log("projectUpdates::", res.posts, res);
				let projectUpdatesNew = res.posts;
				let projectUpdatesOld = this.state.projectUpdates;
				let projectUpdates = projectUpdatesOld.concat(projectUpdatesNew);
				this.setState({
					projectUpdates: projectUpdates,
					totalPosts: res.totalPosts,
					loading: false
				})
				this.props.storeUpdateList(projectUpdates);
			})
	}

	render() {
		return (
			<div className="padding0">
				<Alert stack={{ limit: 3 }} />
				<CreateUpdates
					project_id={this.props.project_id}
					user_pik={this.state.user_pik}
					addNewUpdate={this.addNewUpdate.bind(this)}
					displayImage={this.state.displayImage}
					displayPostImage={this.showPostImage.bind(this)}
					userData={this.props.userData}
				/>
				{(this.state.projectUpdates !== null) ? (this.state.projectUpdates.length > 0 ?
					_.map(this.state.projectUpdates, (proj, index) => {
						return (<CommentBox key={index} project_id={proj.id} index={index} update={proj} text={proj.text} deleteUpdate={this.deleteUpdate.bind(this)} editUpdate={this.editUpdate.bind(this)}
							postedBy={proj.postedBy.fullName} images={proj.images} profilePicture={this.state.user_pik} createdAt={proj.createdAt} showMoreImages={this.showMoreImages.bind(this)} />)
					}) : (<div>
						<h1 style={{ textAlign: "center", fontSize: "22px", color: "#a9a9a9" }}>No updates to show.</h1>
					</div>)) : null}
				{this.state.projectUpdates.length < this.state.totalPosts ? (<div className='show-more-button-container' style={{ display: "flex", justifyContent: "center" }}><button onClick={this.showMoreUpdates.bind(this)} className='btn btn-info show-more-button'>Show More</button></div>) : null}
				<Modal open={this.state.openModal} onClose={this.onCloseModal.bind(this)} center>
					<ImageModal imgArray={this.state.imgArray} openModal={this.state.openModal} imageIndex={0} />
				</Modal>
			</div>
		)
	}

}

function mapStateToProps(state) {
	const { projectUpdates } = state;
	return {
		projectUpdates
	}
}



export default connect(mapStateToProps, { storeUpdateList })(ProjectUpdates);