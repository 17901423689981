import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import _ from 'lodash';

import IMProjectsSidebar from './IMProjectsSidebar';

const readCookie = require('../cookie.js').readCookie;

function checkValue(value) {
	if (value === undefined || value === null) return true;
	else if (Array.isArray(value)) {
		if (value.length === 0) return true;
		else if (value.length === 1 && value[0] === '') return true;
		else return false;
	} else return false;
}

let year = moment().year();
const years = [];
for (var i = -3; i < 4; i++) {
	var yearStr = (year - i) + '-' + (year - i + 1);
	years.push(yearStr);
}

export default class IMProjects extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filters: {
				goalId: '',
				address: '',
				status: "All",
				searchText: '',
				projectCategoryId: '',
				fiscalYear: this.getCurrentFiscalYear()
			},
			projects: null,

			location: null,
			location_query: null,

			fiscalYear: null,
			year_query: null,

			goalId: null,
			goal_query: null,

			searchText: null,
			search_query: "",

			projectCategoryId: null,
			proj_cat_query: null,

			status: null,
			status_query: null,

			query_string: null,
		}
	}


	componentDidMount() {
		fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/project', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				if (data.status === 'ok') {
					this.setState({ projects: data.data });
				}
			}.bind(this));
	}

	getCurrentFiscalYear() {
		var today = new Date();
		var curMonth = today.getMonth();
		if (curMonth > 3) {
			return today.getFullYear().toString() + '-' + (today.getFullYear() + 1).toString();
		} else {
			return (today.getFullYear() - 1).toString() + '-' + today.getFullYear().toString();
		}
	}

	convertStringToQuery() {
		let query_string = null;
		if (this.state.location_query !== null && this.state.location_query !== "") {
			query_string = this.state.location_query;
		}
		if (this.state.year_query !== null && this.state.year_query !== "") {
			query_string = query_string + '&' + this.state.year_query;
		}
		if (this.state.goal_query !== null && this.state.goal_query !== "") {
			query_string = query_string + '&' + this.state.goal_query;
		}
		if (this.state.proj_cat_query !== null && this.state.proj_cat_query !== "") {
			query_string = query_string + '&' + this.state.proj_cat_query;
		}
		if (this.state.status_query !== null && this.state.status_query !== "") {
			query_string = query_string + '&' + this.state.status_query;
		}
		if (this.state.search_query !== null && this.state.search_query !== "") {
			query_string = query_string + '&' + this.state.search_query;
		}
		if (query_string !== null) {
			this.filterProjects(query_string);
		}
	}

	filterProjects(string) {
		fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/project?' + string, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(res => {
				this.setState({ projects: res.data })
			}).catch(err => { console.log("err", err) })
	}

	onFiltersChange(name, x, y) {
		var filters = this.state.filters;
		if (name !== 'status' && name !== "") {
			filters[name] = x.target.value;
			this.setState({ filters });
		}
		let queryStatus = x;
		if (name === 'status' && x === 'All') queryStatus = '';
		switch (name) {
			case 'clearFilter':
				fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/project', {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					}
				}).then((data) => data.json())
					.then(res => {
						this.setState({ projects: res.data })
						this.setState({
							filters: {
								goalId: '',
								address: '',
								status: 'All',
								searchText: '',
								projectCategoryId: '',
								fiscalYear: ''
							}, location: "", location_query: "", proj_cat_query: "", projectCategoryId: "", status_query: "", year_query: "", goal_query: "", goalId: ""
						}, function () {
							this.setState({ fiscalYear: this.getCurrentFiscalYear() });
						}.bind(this));
					}).catch(err => {
						console.log("err", err);
					});
				break;

			case 'location':
				let query = 'location=' + x.target.value;
				let location = x.target.value;
				this.setState({ location: location, location_query: query }, () => {
					this.convertStringToQuery();
				});
				break;

			case 'fiscalYear':
				let year = x.target.value.split('-')[0];
				let query_year = 'year=' + year;
				this.setState({ fiscalYear: year, year_query: query_year }, () => {
					this.convertStringToQuery();
				});
				break;

			case 'goalId':
				this.setState({ goalId: x.target.value, goal_query: 'goalId=' + x.target.value }, () => {
					this.convertStringToQuery();
				});
				break;

			case 'searchText':
				filters["searchText"] = x.target.value;
				let search_query = 'query=' + x.target.value;
				this.setState({ filters, search_query }, () => {
					this.convertStringToQuery();
				});
				break;

			case 'projectCategoryId':
				this.setState({ projectCategoryId: x.target.value, proj_cat_query: 'projectCategoryId=' + x.target.value }, () => {
					this.convertStringToQuery();
				});
				break;

			case 'status':
				filters["status"] = x;
				this.setState({ filters, status_query: 'status=' + queryStatus }, () => {
					this.convertStringToQuery();
				});
				break;

			default:
				// console.log("default case")
		}
		if (x === 'location') {
			return;
		}
	}



	addNewProject() {
		window.location.pathname = "/im/projects/new";
	}

	saveProject(project_id) {
		let project = {};
		project['status'] = 'Submitted';
		fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + project_id, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			},
			body: JSON.stringify(project)
		}).then((response) => response.json())
			.then((data) => {
				if (data['status'] === 'ok') {
					fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/mis/project', {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('mis_data')).access_token
						}
					}).then((data) => data.json())
						.then(function (data) {
							if (data.status === 'ok') {
								this.setState({ projects: data.data });
								this.notifySuccess('This Project has been submitted successfully.');
							} else {
								this.notifyError("Can't save the project there's been some problem. Please try again!");
							}
						}.bind(this));
				}
			});
	}

	notifySuccess = (text) => toast.success(text);

	cloneProject(project_id) {
		console.log(project_id)
	}

	deleteProject = (project_id, index) => {
		fetch(process.env.REACT_APP_MIS_API_URL + "/project/" + project_id ,{
			method: "DELETE",
			headers: {
  			'Auth': JSON.parse(readCookie('mis_data')).access_token
  		},
		}).then((data) => data.json())
		.then((data => {
			if(data.status === "ok"){
				this.notifySuccess('This Project has been deleted successfully.');
				let projects = this.state.projects;
				projects.splice(index , 1);
				this.setState({projects});
			} else {
				this.notifyError("Can't delete the project. Please try again!");
			}
		}))
	}

	render() {
		return (
			<div className="im-container im-projects-page">
				<IMProjectsSidebar onFiltersChange={this.onFiltersChange.bind(this)} getFiscalYear={this.getCurrentFiscalYear} filters={this.state.filters} />
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				{this.state.projects !== null ? (
					<div className="im-projects-container">
						<button type="button" className="btn btn-info" onClick={this.addNewProject.bind(this)}>Add Project</button>
						{this.state.projects.length ? (
							this.state.projects.map((project, index) => {
								return (
									<div className="single-project im-project" key={index}>
										<div className="project-img-container">
											<Link to={"/im/projects/" + project.id}>
												<img className="project-image" src={project.coverImage ? project.coverImage : "/images/project-investment-placeholder-new.png"} alt={project.title} />
												{project.status ? (
													<div className="project-status">
														<span className="current-state-outer submitted">
															<span className="outer-arrow"> </span>
															<span className="current-state-text">{project.status}</span>
														</span>
													</div>
												) : (null)}
											</Link>
										</div>
										<div className="title-desc-container">
											<Col md={12} className="project-title">
												<Link to={"/im/projects/" + project.id}><span>{project.title}</span></Link>
											</Col>
											<Col md={12} className="project-description">
												<p>{project.description}</p>
											</Col>
											{checkValue(project.targetBeneficiaries) !== true ? (
												<Col md={12} className="features" hidden={checkValue(project.targetBeneficiaries)}>
													<div className="heading">Target beneficiaries: </div>
													<div className="content">
														{project.targetBeneficiaries.map((targetBeneficiary, i) => {
															if (project.targetBeneficiaries.length - i === 1) return targetBeneficiary.name
															else return targetBeneficiary.name + ', '
														})}
													</div>
												</Col>
											) : (null)}
											{checkValue(project.goalData) !== true ? (
												<Col md={12} className="features" hidden={checkValue(project.goalData.name)}>
													<div className="heading">Cause/Developmental Goal: </div>
													<div className="content">{project.goalData.name}</div>
												</Col>
											) : (null)}
											<Col md={12} className="features">
												{project.status === 'Draft' || project.status === 'Submitted' ? (
													<Link to={"/im/projects/" + project.id + '/edit'}>
														<button className="btn btn-edit">
															<i className="fa fa-edit"></i>
															Edit
														</button>
													</Link>
												) : (null)}
												{project.allFieldsFilled === true ? (
													<button className="btn submit-project-btn" onClick={this.saveProject.bind(this, project.id)}>
														<i className="fa fa-paper-plane"></i>
														Submit
													</button>
												) : (null)}
												<Link to={"/im/projects/" + project.id + '/clone'}>
													<button className="btn submit-project-btn" onClick={this.cloneProject.bind(this, project.id)}>
														<i className="fa fa-clone"></i>
														Clone
													</button>
												</Link>
												{project.status === 'Draft' ?
													(<button className="btn submit-project-btn" onClick={this.deleteProject.bind(this, project.id, index)}>
														<i className="fa fa-times"></i>
														Delete
													</button>
													) : (null) }
											</Col>
											{(project.pendingInvitations) > 0 ? (
												<Col md={12} className="features" hidden={checkValue(project.targetBeneficiaries)}>
													<div className="content">
														<p style={{ marginTop: "10px", background: "#ef5a20", padding: "2px 10px", color: "#fff", fontSize: "14px", borderRadius: "4px" }}><i className="fa fa-info-circle" style={{ marginRight: "6px" }}></i> You have <b>{project.pendingInvitations}</b> Funding Invitation Pending</p>
													</div>
												</Col>
											) : (null)}
										</div>
										<div className="extra-container">
											{!checkValue(project.location) ? (
												<Col md={12} className="project-location">
													<div>
														<i className="fa fa-map-marker"></i><b>{' Location: '}</b>
														<span>{' ' + project.location[0].address.split(',')[0]}</span>
													</div>
													<span className="project-address">
														{project.location.map(function (location, index) {
															if (index !== 0) {
																return (
																	<div key={index}>{location.address.split(',')[0]}</div>
																)
															}
														})}
													</span>
												</Col>
											) : (null)}
											{!checkValue(project.proposedBudget) ? (
												<Col md={12} className="project-budget-con" hidden={checkValue(project.proposedBudget)}>
													<i className="fa fa-money"></i>
													<span className="project-budget">
														{project.hasOwnProperty('currencySymbol') ? (
															<span><b>{' Budget: '}</b>{project.currencySymbol + ' ' + (project.proposedBudget).toLocaleString()}</span>
														) : (
																<span><b>{' Budget: '}</b>{'₹ ' + (project.proposedBudget).toLocaleString()}</span>
															)}
													</span>
												</Col>
											) : (null)}
											{!checkValue(project.expectedDurationObject) ? (
												<Col md={12} className="project-duration-container" hidden={checkValue(project.expectedDurationObject.duration)}>
													<i className="fa fa-calendar"></i>
													<span className="project-duration"><b>{' Duration: '}</b>{project.expectedDurationObject.duration + ' ' + project.expectedDurationObject.type + '(s)'}</span>
												</Col>
											) : (null)}
											{!checkValue(project.assignedFor) ? (
												<Col md={12} className="project-duration-container" >
													<i className="fa fa-eye"></i>
													{/* <span className="project-duration"><b>{' Shared with: '}</b>{project.assignedFor.length ? <a style={{ color: "black", fontSize: "16px !important" }} href="/im/partnerships/projects-shared">Private</a> : "Public"}</span> */}
													<span className="project-duration"><b>{' Submitted : '}</b> <a style={{ color: "black", fontSize: "16px !important" }} href="/im/partnerships/projects-submitted"><span style={{ color: "#ef5a20", fontWeight: "bold" }}>{project.assignedFor.length}</span> corporates</a></span>
												</Col>
											) : (
													// <Col md={12} className="project-duration-container" >
													// 	<i className="fa fa-eye"></i>
													// 	<span className="project-duration"><b>{' Visibility: '}</b>Public</span>
													// </Col>
													null
												)}
											{!checkValue(project.funder) ? (
												<Col md={12} className="project-duration-container" >
													<i className="fa fa-user"></i>
													<span className="project-duration"><b>{' Funded by: '}</b>{project.funder.name}</span>
												</Col>
											) : null}
										</div>
									</div>
								)
							})
						) : (
								<div className="no-projects-found-container">
									<h3>No Projects Found</h3>
								</div>
							)}
					</div>
				) : (null)}
			</div>
		);
	}
}