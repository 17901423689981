import React, {Component} from 'react';
import { Modal, Button, Row, Col } from "react-bootstrap";

import $ from "jquery";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../cookie.js').readCookie;

export default class EditListModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
  		list_name: "",
  		list_desc: "",
      donor_type: false,
      show_name_error: false,
      show_desc_error: false
		};
	}

  componentDidMount() {
    let list_name = this.props.selectedObj['name'];
    let list_desc = this.props.selectedObj['description'];
    let donor_type = this.props.selectedObj['donor_type'];
    this.setState({list_name, list_desc, donor_type});
  }

  updateChange(name, e) {
  	this.setState({ [name]: e.target.value, show_name_error: false, show_desc_error: false });
  }

  editList() {
    if(this.state.list_name !== '' && this.state.list_desc !== '') {
    	var data = {};
    	data['name'] = this.state.list_name;
    	data['description'] = this.state.list_desc;
      data['donor_type'] = this.state.donor_type;

      fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo_id + '/edit/list/' + this.props.selectedObj['id'], {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify(data)
      }).then((response) => response.json())
      .then(function(data) {
        if(data.status === 'ok') {
          this.props.notifySuccess('The list has been created successfully.');
          this.props.hideEditListModal();
        }
      }.bind(this));
    } else {
      if(this.state.list_name === '') this.setState({ show_name_error: true });
      if(this.state.list_desc === '') this.setState({ show_desc_error: true });
    }
  }

  checkDonorList = (e) => {
    this.setState({donor_type: !this.state.donor_type});
  }

  cancelEditList() {
  	this.props.hideEditListModal();
  }

	render() {
		return (
			<div className="edit-org-details-modal">
				<Modal bsSize="large" className="edit-org-details-modal"
          show={this.props.showEditModal}
          onHide={this.props.hideEditListModal} >
          <Modal.Header>
            <Modal.Title className="modal-header-custom">
              Edit List
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="margin-col-50" md={12}>
		            <form id="edit-org-details-modal-form" ref="vForm">

                  <Col className="form-group" md={12}>
                    <label className="control-label is-imp">List Name: </label>
                    <input type="text" className="form-control" id="list_name" name="list_name" onChange={this.updateChange.bind(this, "list_name")}
                      placeholder="List Name" required value={this.state.list_name} />
                    <span hidden={!this.state.show_name_error}><span className="required-span"> Incorrect List Name! </span></span>
                  </Col>

                  <Col className="form-group" md={12}>
                    <label className="control-label is-imp">List Description: </label>
                    <textarea className="form-control" id="list_desc" onChange={this.updateChange.bind(this, "list_desc")}
                      placeholder="List Description" required value={this.state.list_desc} />
                    <span hidden={!this.state.show_desc_error}><span className="required-span"> Incorrect List Description! </span></span>
                  </Col>

                  <Col className="form-group mt15" md={12}>
                    <input type="checkbox" checked={this.state.donor_type} onChange={this.checkDonorList} />
                    <label style={{fontSize:'15px', marginLeft: '5px', color: '#6f6f6f', letterSpacing: '0.1rem'}}> Is this a donor list?</label>
                  </Col>
                </form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="footer-center">
          	<Button className="btn btn-danger brand-color-background" onClick={this.cancelEditList.bind(this)}>Cancel</Button>
          	<Button className="btn btn-warning brand-color-background" onClick={this.editList.bind(this)}>Save</Button>
          </Modal.Footer>
        </Modal>
			</div>
		);
	}
}
