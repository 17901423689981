import React, {Component} from 'react';
import { Modal, Button, Row, Col } from "react-bootstrap";

import $ from "jquery";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../cookie.js').readCookie;

export default class EditOrgDetailsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
  		org_name: this.props.ngo.ngo_name,
      org_email: this.props.ngo.ngo_email,
  		org_logo: this.props.ngo.ngo_logo,
			showLogoUploadButton: "hidden",
  		ngo_website_url: this.props.ngo.ngo_website_url,
			ngo_blog_url: this.props.ngo.ngo_blog_url,
			ngo_fb_url: this.props.ngo.ngo_fb_url,
			ngo_twitter_url: this.props.ngo.ngo_twitter_url,
			ngo_linkedin_url: this.props.ngo.ngo_linkedin_url,
			ngo_youtube_url: this.props.ngo.ngo_youtube_url,
			ngo_instagram_url: this.props.ngo.ngo_instagram_url
		};
	}

	showLogoUploadButton() {
    this.setState({ showLogoUploadButton: '' });
  }

  changeLogo() {
    var logo = $('input#ngo-logo-input');
    var data = new FormData();
    data.append('file', logo[0].files[0]);

    fetch(process.env.REACT_APP_API_URL + '/upload_to_s3/' + this.props.ngo_id, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: data
    }).then((response) => response.json())
    .then(function(data) {
      if(data['status'] === 'ok') {
        this.setState({ org_logo: data['url'], showLogoUploadButton: 'hidden' });
        $('input#ngo-logo-input').val('');
      }
    }.bind(this));
  }

  cancelChangeLogo() {
    this.setState({ showLogoUploadButton: 'hidden' });
    $('input#ngo-logo-input').val('');
  }

  updateChange(name, e) {
  	this.setState({ [name]: e.target.value });
  }

  updateOrgDetails() {
  	var data = {};
  	data['ngo_name'] = this.state.org_name;
    data['ngo_email'] = this.state.org_email;
  	data['ngo_logo'] = this.state.org_logo;
  	data['ngo_website_url'] = this.state.ngo_website_url;
  	data['ngo_blog_url'] = this.state.ngo_blog_url;
		data['ngo_fb_url'] = this.state.ngo_fb_url;
		data['ngo_twitter_url'] = this.state.ngo_twitter_url;
		data['ngo_linkedin_url'] = this.state.ngo_linkedin_url;
		data['ngo_youtube_url'] = this.state.ngo_youtube_url;
		data['ngo_instagram_url'] = this.state.ngo_instagram_url;

  	fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo_id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify({ data })
    }).then((response) => response.json())
    .then(function(data) {
      this.props.hideEditOrgDetailsModal();
      if(data['status'] === 'ok') {
        swal({
          title: 'Details updated.',
          text: 'Organisation details have been updated.',
          type: 'success'
        }).then(function(){
          this.props.updateNgoData();
        }.bind(this))
      } else {
        swal({
          title: 'Oops.',
          text: 'Something went wrong can not update details.',
          type: 'error'
        })
      }
    }.bind(this));
  }

  cancelUpdateOrgDetails() {
  	this.props.hideEditOrgDetailsModal();
  }

	render() {
		return (
			<div className="edit-org-details-modal">
				<Modal bsSize="large" className="edit-org-details-modal"
          show={this.props.showEditOrgDetailsModal}
          onHide={this.props.hideEditOrgDetailsModal} >
          <Modal.Header>
            <Modal.Title className="modal-header-custom">
              Edit Organisation Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="margin-col-50" md={12}>
		            <form id="edit-org-details-modal-form" ref="vForm">
                  <Col className="form-group" md={6}>
                    <label className="control-label">Organisation Name: </label>
                    <input type="text" className="form-control" id="org_name" name="org_name" onChange={this.updateChange.bind(this, "org_name")}
                      placeholder="Organisation Name" required defaultValue={this.state.org_name} />
                    <span><span className="red-color"> Incorrect Organisation Name! </span></span>
                  </Col>
                  <Col className="form-group" md={6}>
                    <label className="control-label">Organisation Email: </label>
                    <input type="text" className="form-control" id="org_email" name="org_email" onChange={this.updateChange.bind(this, "org_email")}
                      placeholder="Organisation Email" required defaultValue={this.state.org_email} />
                    <span><span className="red-color"> Incorrect Organisation Email! </span></span>
                  </Col>
                  <Col className="form-group" md={6} >
                    <label className="control-label">Organisation Logo: </label>
                    <div className="ngo-logo">
                      <img src={this.state.org_logo} alt="" />
                      <input id="ngo-logo-input" className="mt10" type="file" onChange={this.showLogoUploadButton.bind(this)} />
                      <Col md={12} className={"mt10 " + this.state.showLogoUploadButton}>
                        <Button id="uploadLogo" className="btn-success react-bs-table-add-btn col-md-offset-1 col-md-4" onClick={this.changeLogo.bind(this)}>Upload</Button>
                        <Button id="cancelUploadLogo" className="btn-success react-bs-table-add-btn col-md-offset-2 col-md-4" onClick={this.cancelChangeLogo.bind(this)}>Cancel</Button>
                      </Col>
                    </div>
                  </Col>

                  <Col className="form-group" md={6} >
                    <label className="control-label">Organisation Website Url: </label>
                    <input type="text" className="form-control" id="website_url" name="website_url" onChange={this.updateChange.bind(this, "ngo_website_url")}
                      placeholder="Organisation Website Url" required defaultValue={this.state.ngo_website_url} />
                    <span><span className="red-color"> Incorrect Organisation Website Url! </span></span>
                  </Col>
                  <Col className="form-group" md={6} >
                    <label className="control-label">Organisation Blogspot Url: </label>
                    <input type="text" className="form-control" id="blog_url" name="blog_url" onChange={this.updateChange.bind(this, "ngo_blog_url")}
                      placeholder="Organisation Blogspot Url" required defaultValue={this.state.ngo_blog_url} />
                    <span><span className="red-color"> Incorrect Organisation Blogspot Url! </span></span>
                  </Col>

                  <Col className="form-group" md={6} >
                    <label className="control-label">Organisation Facebook Url: </label>
                    <input type="text" className="form-control" id="fb_url" name="fb_url" onChange={this.updateChange.bind(this, "ngo_fb_url")}
                      placeholder="Organisation Facebook Url" required defaultValue={this.state.ngo_fb_url} />
                    <span><span className="red-color"> Incorrect Organisation Facebook Url! </span></span>
                  </Col>
                  <Col className="form-group" md={6} >
                    <label className="control-label">Organisation Twitter Url: </label>
                    <input type="text" className="form-control" id="twitter_url" name="twitter_url" onChange={this.updateChange.bind(this, "ngo_twitter_url")}
                      placeholder="Organisation Twitter Url" required defaultValue={this.state.ngo_twitter_url} />
                    <span><span className="red-color"> Incorrect Organisation Twitter Url! </span></span>
                  </Col>

                  <Col className="form-group" md={6} >
                    <label className="control-label">Organisation Linkedin Url: </label>
                    <input type="text" className="form-control" id="linkedin_url" name="linkedin_url" onChange={this.updateChange.bind(this, "ngo_linkedin_url")}
                      placeholder="Organisation Linkedin Url" required defaultValue={this.state.ngo_linkedin_url} />
                    <span><span className="red-color"> Incorrect Organisation Linkedin Url! </span></span>
                  </Col>
                  <Col className="form-group" md={6} >
                    <label className="control-label">Organisation Youtube Url: </label>
                    <input type="text" className="form-control" id="youtube_url" name="youtube_url" onChange={this.updateChange.bind(this, "ngo_youtube_url")}
                      placeholder="Organisation Youtube Url" required defaultValue={this.state.ngo_youtube_url} />
                    <span><span className="red-color"> Incorrect Organisation Youtube Url! </span></span>
                  </Col>

                  <Col className="form-group" md={6} >
                    <label className="control-label">Organisation Instagram Url: </label>
                    <input type="text" className="form-control" id="instagram_url" name="instagram_url" onChange={this.updateChange.bind(this, "ngo_instagram_url")}
                      placeholder="Organisation Instagram Url" required defaultValue={this.state.ngo_instagram_url} />
                    <span><span className="red-color"> Incorrect Organisation Instagram Url! </span></span>
                  </Col>
                  
                </form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="footer-center">
          	<Button className="btn btn-danger brand-color-background" onClick={this.cancelUpdateOrgDetails.bind(this)}>Cancel</Button>
          	<Button className="btn btn-warning brand-color-background" onClick={this.updateOrgDetails.bind(this)}>Save</Button>
          </Modal.Footer>
        </Modal>
			</div>
		);
	}
}
