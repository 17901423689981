import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import swal from "sweetalert2";
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';

import IMSingleProjectSidebar from './IMSingleProjectSidebar';
import ReportExpenseModal from './ReportExpenseModal';

import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../cookie.js').readCookie;

export default class IMSingleProjectFinances extends Component {
	constructor(props) {
		super(props);
		this.state = {
			perPage: 10,
			offset: 0,
			totalPages: 0,
			expenses: [],
			allHistories: [],
			histories: [],
			activeTabKey: 1,
			reportExpenseModalVisibility: false,
			reportType: '',
			expenseData: null,
			editExpense: false,
			historyExpenses: null,
			allowReporting: false,
			releasedAmount: 0,
			releasedAmountBreakup: [],
			tranches: [],
			message: '',
			disbursementType: '',
			showModal: false,
			projectStatus: ""
		}
	}

	getExpenses = () => {
		fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/reported-expenses', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				this.setState({ expenses: data.expenseItems, allowReporting: data.allowReporting });
			}.bind(this));
	}
	componentDidMount() {
		let tranches = []

		this.getExpenses();

		fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/reported-expenses/history', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
		.then(function (data) {
			let allHistories = data.logs;
			for (var i = 0; i < allHistories.length; i++) {
				allHistories[i]['selected'] = false;
			}
			this.setState({ allHistories, totalPages: Math.ceil(allHistories.length / this.state.perPage) }, function () {
				this.loadHistories(this);
			}.bind(this));
		}.bind(this));

		fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/released-amount', {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			if (data.status === 'ok') {
				let releasedAmount = data.data.releasedAmount;
				let releasedAmountBreakup = data.data.releasedAmountBreakup;
				this.setState({ releasedAmount, releasedAmountBreakup });
			}
		});

		fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/fund-disbursement', {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			if (data.status === "ok") {
				tranches = data.data.tranches;
				this.setState({ tranches, disbursementType: data.data.disbursementType });
			} else if (data.status === "error") {
				this.setState({ message: data.message });
			}
		});
	}

	loadHistories() {
		this.setState({ histories: this.state.allHistories.slice(this.state.offset, (this.state.offset + this.state.perPage)) });
	}

	handlePageClick(data) {
		let selected = data.selected;
		let offset = Math.ceil(selected * this.state.perPage);

		this.setState({ offset: offset, historyExpenses: null }, () => {
			this.loadHistories();
		});
	}

	changeActiveTab(key) {
		if (key !== this.state.activeTabKey) this.setState({ activeTabKey: key, historyExpenses: null });
	}

	costHeadFormatter(cell, row) {
		return cell.name;
	}

	typeFormatter(cell, row) {
		return cell.type;
	}

	budgetedFormatter(cell, row) {
		if (cell) return 'Yes';
		else if (row.isTotal) return '';
		else return 'No';
	}

	proposedAmountFormatter(cell, row) {
		return cell.toLocaleString();
	}

	reportedAmountFormatter(cell, row) {
		return cell.toLocaleString();
	}

	expenseStatusFormatter(cell, row) {
		if (cell !== '' && cell !== 'N/A') return cell + ' Payment';
		else if (cell === 'N/A') return cell;
		else return '';
	}

	percentageUtilizedFormatter(cell, row) {
		if (row.budgeted || row.isTotal) return cell + '%';
		else return 'N/A';
	}

	unitEconomicsFormatter(cell, row) {
		if (row.unitEconomics && cell) return cell;
		else return '';
	}

	editDetailsFormatter(cell, row) {
		if (row.isTotal) return null;
		else if (!this.state.allowReporting) return 'N/A';
		else return (
			<button className="btn table-btn" onClick={this.editExpense.bind(this, 'edit', row)}>
				Report
			</button>
		)
	}

	deleteItem(cell, row) {
		if (row.id) {
			return <span> {row.budgeted || row.isTotal  ? null :
				<button className="btn btn-sm btn-danger table-btn" onClick={this.deleteExpenseItem.bind(this, row.id)}>
				<i className="fa fa-trash"></i>
				{/* Delete */}
			</button>}</span>
		}
		else return null;
	}

	editExpense(reportType, expenseData) {
		this.setState({ expenseData, editExpense: true }, function () {
			this.showReportExpenseModal(reportType);
		}.bind(this));
	}

	viewDetailsFormatter(cell, row) {
		if (row.isTotal) return null;
		else return (
			<button className="btn table-btn" onClick={this.editExpense.bind(this, 'view', row)}>
				<i className="fa fa-file-text-o"></i>
				View
			</button>
		)
	}

	showModal(e) {
		this.setState({ showModal: true });
	}

	billsCountFormatter(cell, row) {
		if (cell === undefined) return '';
		if (cell.length) return (
			cell.length
		);
		else return row.billStatus;
	}


	openHistory(index) {
		let histories = this.state.histories;
		for (var i = 0; i < histories.length; i++) {
			histories[i]['selected'] = false;
		}
		histories[index]['selected'] = true;
		this.setState({ historyExpenses: null, histories }, function () {
			this.getHistoryExpensesData(histories[index].id);
		}.bind(this));
	}

	getHistoryExpensesData(history_id) {
		fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/reported-expenses/history/' + history_id, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				this.setState({ historyExpenses: data.expenseItems });
			}.bind(this));
	}

	showReportExpenseModal(reportType) {
		this.setState({ reportExpenseModalVisibility: true, reportType });
	}

	hideReportExpenseModal(type) {
		if (type === 'closeView') {
			this.setState({ reportExpenseModalVisibility: false, reportType: '', editExpense: false });
		} else {
			this.setState({ reportExpenseModalVisibility: false, reportType: '', editExpense: false, activeTabKey: 1, historyExpenses: null });
		}
		if (type === 'refresh') {
			fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/reported-expenses', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					this.setState({ expenses: data.expenseItems, allowReporting: data.allowReporting });
				}.bind(this));
		} else if (type === 'restore') {
			fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/reported-expenses', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					this.setState({ expenses: data.expenseItems, allowReporting: data.allowReporting });
				}.bind(this));

			fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/reported-expenses/history', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					let allHistories = data.logs;
					for (var i = 0; i < allHistories.length; i++) {
						allHistories[i]['selected'] = false;
					}
					this.setState({ allHistories, totalPages: Math.ceil(allHistories.length / this.state.perPage) }, function () {
						this.loadHistories(this);
					}.bind(this));
				}.bind(this));
		}
	}


	deleteExpenseItem = (rowId) =>{
		let that = this;
	swal({
		title: 'Are you sure?',
		text: 'This is an irreversible action, the expense item will be lost if you proceed!',
		type: 'warning',
		// icon: "warning",
		confirmButtonColor: '#ef5a20',
		cancelButtonColor: '#f1ffdd',
		showCancelButton: true,
		confirmButtonText: 'Yes, Please delete!',
		cancelButtonText: 'No, Cancel!',
		confirmButtonClass: 'btn',
		cancelButtonClass: 'btn',
	}).then(function(result) {
		if (result) {
			fetch(process.env.REACT_APP_MIS_API_URL + '/project/reported-expenses/'+rowId, {
				method: 'DELETE',
				headers: {
					"Content-Type": "application/json",
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				},
			}).then((response) => response.json())
				.then(function (data) {
					if (data['status'] === 'ok') {
						that.notifySuccess('Expense item deleted successfully!');
						that.getExpenses();
					}
					else {
						that.notifyError(data['message']);
					}
				}.bind(this));
		}
	}).catch(function() { });}

	restoreVersion(history_id) {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, Restore!',
			cancelButtonText: 'No, Cancel!',
			confirmButtonClass: 'btn',
			cancelButtonClass: 'btn',
		}).then(function (result) {
			if (result) {
				let data = {};
				data['logId'] = history_id;
				fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/restore-reported-expenses', {
					method: 'POST',
					headers: {
						"Content-Type": "application/json",
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					},
					body: JSON.stringify(data)
				}).then((response) => response.json())
					.then(function (data) {
						if (data['status'] === 'ok') {
							this.notifySuccess('This version has been restored successfully.');
							let that = this;
							setTimeout(function () {
								that.hideReportExpenseModal('restore');
							}, 2000);
						}
					}.bind(this));
			}
		}.bind(this)).catch(function () {
		});
	}

	amountFormatter(cell, row) {
		let elemStr = '';
		for (var i = 0; i < cell.length; i++) {
			elemStr += `<p>${cell[i].value}</p>`
		}
		return elemStr;
	}

	chequeOrUtrFormatter(cell, row) {
		if (row.hasOwnProperty('transactionRefNo')) {
			return cell;
		} else {
			return 'N/A';
		}
	}

	scanFormatter(cell, row) {
		if (row.hasOwnProperty('docs') && row.docs.length) {
			for (let i = 0; i < cell.length; i++) {
				return (
					<a onClick={() => { window.open(cell[i]) }} >View</a>
				);
			}
		} else {
			return 'N/A'
		}
	}

	tranchFormatter(cell, row) {
		if (cell) {
			return (
				<p>Tranch {cell++}</p>
			)
		}
	}

	plannedAmount(cell, row) {
		return (
			<p>INR {cell}</p>
		)
	}

	notifySuccess = (text) => toast.success(text);
	notifyError = (text) => toast.error(text);

	render() {
		return (
			<div className="im-container im-project-page im-project-finances-page">
				<IMSingleProjectSidebar selected='finances' project_id={this.props.match.params.project_id} />
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<div className="im-project-container im-project-finance-container">
					<div className="finance-tabs-container">
						<div className="left-section-tabs">
							<button className={this.state.activeTabKey === 1 ? "btn finance-tab-btn selected" : "btn finance-tab-btn"} onClick={this.changeActiveTab.bind(this, 1)}>
								Project Budget
							</button>
							<button className={this.state.activeTabKey === 3 ? "btn finance-tab-btn selected" : "btn finance-tab-btn"} onClick={this.changeActiveTab.bind(this, 3)}>
								Corporate Investments
							</button>
						</div>
						<div className="right-section-tab">
							{this.state.allowReporting ? (
								<button className={this.state.activeTabKey === 2 ? "btn finance-tab-btn selected" : "btn finance-tab-btn"} onClick={this.changeActiveTab.bind(this, 2)}>
									View History
							</button>
							) : (null)}
						</div>
					</div>
					<div className="finance-tabs-content">
						{this.state.activeTabKey === 1 ? (
							<div className="tab-content budget-tab-content">
								<BootstrapTable data={this.state.expenses} striped={false} ref='table' hover={true}>
									<TableHeaderColumn dataField="id" isKey={true} hidden={true} export={false} searchable={false}>Expense Id</TableHeaderColumn>
									<TableHeaderColumn dataField="costHead" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.costHeadFormatter}>Expense Head</TableHeaderColumn>
									<TableHeaderColumn dataField="expenseItem" width='180px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Expense Item</TableHeaderColumn>
									<TableHeaderColumn dataField="costHead" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.typeFormatter}>Type</TableHeaderColumn>
									<TableHeaderColumn dataField="totalUnits" width='60px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.unitEconomicsFormatter}>Units</TableHeaderColumn>
									<TableHeaderColumn dataField="unitCost" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.unitEconomicsFormatter}>Cost/Unit</TableHeaderColumn>
									<TableHeaderColumn dataField="budgeted" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.budgetedFormatter}>Budgeted</TableHeaderColumn>
									<TableHeaderColumn dataField="proposedAmount" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.proposedAmountFormatter}>Proposed Amount (with TAX)</TableHeaderColumn>
									<TableHeaderColumn dataField="reportedAmount" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.reportedAmountFormatter}>Reported Amount (with TAX)</TableHeaderColumn>
									<TableHeaderColumn dataField="expenseStatus" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.expenseStatusFormatter.bind(this)}>Expense Status</TableHeaderColumn>
									<TableHeaderColumn dataField="percentageUtilized" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.percentageUtilizedFormatter}>Percentage Utilized</TableHeaderColumn>
									<TableHeaderColumn dataField="bills" width='80px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.billsCountFormatter.bind(this)}>Bills</TableHeaderColumn>
									<TableHeaderColumn dataField="id" width='80px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={false} searchable={false} dataFormat={this.editDetailsFormatter.bind(this)}>Report Expense</TableHeaderColumn>
									<TableHeaderColumn dataField="budgeted" width='50px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={false} searchable={false} dataFormat={this.deleteItem.bind(this)}>Delete Expense</TableHeaderColumn>
								</BootstrapTable>

								{this.state.allowReporting ? (
									<div className="budget-tab-buttons-container">
										{/*<button className="btn" onClick={this.showReportExpenseModal.bind(this, 'reportBudgeted')}>
											Report an Expense
										</button>*/}
										<button className="btn" onClick={this.showReportExpenseModal.bind(this, 'reportUnbudgeted')}>
											Add an unbudgeted expense
										</button>
									</div>
								) : (null)}
							</div>
						) : (null)}

						{this.state.activeTabKey === 2 && this.state.allowReporting ? (
							<Row className="tab-content history-tab-content">
								{this.state.histories.length && this.state.allHistories.length ? (
									<span className="history-tab-heading">Click to view changes</span>
								) : (null)}
								{this.state.histories.map(function (history, index) {
									return (
										<div className="history-item" key={index}>
											<span onClick={this.openHistory.bind(this, index)}>{moment(history.date).format('DD-MM-YYYY, hh:mm A') + ': Changes made by ' + history.fullName}</span>
											{history.selected && this.state.historyExpenses !== null ? (
												<button className="btn revert-btn" onClick={this.restoreVersion.bind(this, history.id)}>Restore This Version</button>
											) : (null)}
											{history.selected && this.state.historyExpenses !== null ? (
												<div className="history-finance-data">
													<BootstrapTable data={this.state.historyExpenses} striped={false} ref='table' hover={true}>
														<TableHeaderColumn dataField="id" isKey={true} hidden={true} export={false} searchable={false}>Expense Id</TableHeaderColumn>
														<TableHeaderColumn dataField="costHead" width='140px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.costHeadFormatter}>Expense Head</TableHeaderColumn>
														<TableHeaderColumn dataField="expenseItem" width='180px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Expense Item</TableHeaderColumn>
														<TableHeaderColumn dataField="costHead" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.typeFormatter}>Type</TableHeaderColumn>
														<TableHeaderColumn dataField="totalUnits" width='60px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.unitEconomicsFormatter}>Units</TableHeaderColumn>
														<TableHeaderColumn dataField="unitCost" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.unitEconomicsFormatter}>Cost/Unit</TableHeaderColumn>
														<TableHeaderColumn dataField="budgeted" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.budgetedFormatter}>Budgeted</TableHeaderColumn>
														<TableHeaderColumn dataField="proposedAmount" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.proposedAmountFormatter}>Proposed Amount (with TAX)</TableHeaderColumn>
														<TableHeaderColumn dataField="reportedAmount" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.reportedAmountFormatter}>Reported Amount (with TAX)</TableHeaderColumn>
														<TableHeaderColumn dataField="expenseStatus" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.expenseStatusFormatter.bind(this)}>Expense Status</TableHeaderColumn>
														<TableHeaderColumn dataField="percentageUtilized" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.percentageUtilizedFormatter}>Percentage Utilized</TableHeaderColumn>
														<TableHeaderColumn dataField="bills" width='80px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.billsCountFormatter.bind(this)}>Bills</TableHeaderColumn>
														<TableHeaderColumn dataField="id" width='80px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={false} searchable={false} dataFormat={this.viewDetailsFormatter.bind(this)}>View Details</TableHeaderColumn>
													</BootstrapTable>
												</div>
											) : (null)}
										</div>
									)
								}.bind(this))}
								<Col md={12} className="text-center pagination-container">
									{this.state.histories.length && this.state.allHistories.length ? (
										<ReactPaginate previousLabel={"Prev"} nextLabel={"Next"}
											breakLabel={<a href="">...</a>} breakClassName={"break-me"}
											pageCount={this.state.totalPages} marginPagesDisplayed={2}
											pageRangeDisplayed={3} onPageChange={this.handlePageClick.bind(this)}
											containerClassName={"pagination"} subContainerClassName={"pages pagination"}
											activeClassName={"active"} />
									) : (
											<h3 className="text-center no-history-found">No History found.</h3>
										)}
								</Col>
							</Row>
						) : (null)}

						{this.state.activeTabKey === 3 ? (
							this.state.message === "" ? (
								<div className="tab-content investment-tab-content">
									<BootstrapTable data={this.state.releasedAmountBreakup} striped={false} ref='table' hover={true}>
										<TableHeaderColumn dataField="id" isKey={true} hidden={true} export={false} searchable={false}>Id</TableHeaderColumn>
										<TableHeaderColumn dataField="amount" width='60px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Amount Invested</TableHeaderColumn>
										<TableHeaderColumn dataField="date" width='50px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Date</TableHeaderColumn>
										<TableHeaderColumn dataField="amountBreakups" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.amountFormatter}>Amount Breakup</TableHeaderColumn>
										<TableHeaderColumn dataField="mode" width='50px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Payment Mode</TableHeaderColumn>
										<TableHeaderColumn dataField="transactionRefNo" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.chequeOrUtrFormatter}>Cheque/UTR No</TableHeaderColumn>
										<TableHeaderColumn dataField="docs" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.scanFormatter}>Scanned copy</TableHeaderColumn>
										<TableHeaderColumn dataField="tranchId" width='70px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.tranchFormatter}>Tranch</TableHeaderColumn>
										<TableHeaderColumn dataField="comment" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Comment</TableHeaderColumn>
									</BootstrapTable>
									<div style={{ marginTop: "30px", display: "flex", alignItems: this.state.tranches.length > 0 ? "flex-start" : "center", flexDirection: this.state.tranches.length > 0 ? "column" : "row" }}>
										{this.state.disbursementType !== "Tranches" ? (
											<h3 style={{ color: "##6f6f6f", margin: this.state.tranches.length > 0 ? "0px 0px 20px 0px" : "0px" }}>Disbursement Plan:</h3>
											) : (
											<div style={{display:"flex", alignItems:"center"}}>
												<h3 style={{ color: "##6f6f6f", margin: this.state.tranches.length > 0 ? "0px 0px 20px 0px" : "0px" }}>Disbursement Plan:</h3>
												<p style={{ margin: "0px 0px 20px 10px" }}>Tranche Disbursement</p>
											</div>
										)}
										{this.state.tranches.length > 0 ? (
											<BootstrapTable data={this.state.tranches} striped={false} ref='table' hover={true}>
												<TableHeaderColumn dataField="amount" isKey={true} hidden={true} export={false} searchable={false}>Tranches</TableHeaderColumn>
												<TableHeaderColumn dataField="amount" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.plannedAmount.bind(this)}>Planned Amount</TableHeaderColumn>
												<TableHeaderColumn dataField="disbursementCondition" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} >Disbursement Condition</TableHeaderColumn>
												<TableHeaderColumn dataField="disbursementCriteria" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} >Disbursement Criteria</TableHeaderColumn>
												<TableHeaderColumn dataField="date" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} >Tentative Disbursement Date</TableHeaderColumn>
											</BootstrapTable>
										) : (
												<p style={{ margin: "0px 0px 0px 10px" }}>{this.state.disbursementType === "One Time" ? (this.state.disbursementType + " Full Disbursement") : (this.state.disbursementType + " Disbursement")}</p>
											)}
									</div>
								</div>
							) : (
									<p style={{ height: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>No Corporate has Invested Yet.</p>
								)
						) : (null)}
					</div>

					{this.state.reportExpenseModalVisibility && this.state.reportType !== '' && this.state.allowReporting ? (
						<ReportExpenseModal hideReportExpenseModal={this.hideReportExpenseModal.bind(this)} type={this.state.reportType}
							project_id={this.props.match.params.project_id} editExpense={this.state.editExpense} expenseData={this.state.expenseData} />
					) : (null)}
				</div>
			</div>
		);
	}
}