import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';
import Alert from 'react-s-alert';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

const readCookie = require('../../cookie.js').readCookie;

export default class AlbumModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visibility: false,
			title: null,
			description: null,
			url: null,
			uploadFilesToAlbum: false,
			img_arr: [],
			loading: false,
			showNext: false,
			uploadVideos: false,
			videoUrl: "",
			albumId: "",
			isValid: false,
			files: [],
			name: ''
		}
	}

	notifySuccess = (text) => toast.success(text);
	notifyError = (text) => toast.error(text);

	componentDidMount() {
		console.log("props---", this.props)
	}

	showNext(e) {

	}

	showPrev() {

	}

	resourcesVisibility(event) {
		console.log("resourcesVisibility", event.target.value);
		this.setState({ visibility: event.target.value });
	}

	setTitle(event) {
		this.setState({ title: event.target.value });

	}

	setDescription(event) {
		this.setState({ description: event.target.value });
	}


	uploadDocFile(data, event) {
		console.log("uploadDocFile", data, event.target.files[0]);
		this.setState({ file: event.target.files[0], type: data });
	}


	uploadImage(e) {
		if (this.state.img_arr.length > 9) {
			Alert.info('You cannot upload more than 10 images')
			return;
		}
		else {
			let imgUpload = $('#upload_img').click();
			// this.setState({ loading: true });
		}
	}

	submitForm(name) {
		this.setState({ name, loading: true })
		var data = new FormData();
		if (this.state.title === null || this.state.type !== 'album') {
			toast.error('Please enter the manadatory fields')
			return;
		}
		if (name === "thumbnailSubmit") {
			data.append('type', this.state.type);
			data.append('title', this.state.title);
			data.append('description', this.state.description);
			data.append('visibility', this.state.visibility);
			data.append('file', this.state.file);
		} else if (name === 'imageSubmit') {
			this.state.files.map((file, i) => {
				data.append('file[' + i + ']', file);
			});
			data.append('albumId', this.state.albumId);
			data.append('type', "image");
		} else if (name === "videoUrlSubmit") {
			data.append('url', this.state.videoUrl);
			data.append('albumId', this.state.albumId);
			data.append('type', "video");
		}
		fetch(process.env.REACT_APP_MIS_API_URL + "/project/" + this.props.project_id + "/resources", {
			method: 'POST',
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			},
			body: data
		})
			.then((response) => response.json())
			.then(res => {
				if (res.status === 'ok') {
					this.setState({ name: "", loading: false })
				}
				if (name === "imageSubmit" && res.status === "ok") {
					this.notifySuccess(`You have successfully uploaded ${this.state.files.length} images to the album.`);
					this.setState({ img_arr: [] });
				}
				if (name === "videoUrlSubmit" && res.status === 'ok') {
					this.notifySuccess('You have successfully uploaded a video to the album.');
					this.setState({ uploadVideos: false });
				}
				if (name === "thumbnailSubmit" && res.status === "ok") {
					this.notifySuccess('You have successfully created a new album.');
					this.setState({ showNext: true, albumId: res.id })
				}
			})
			.catch(err => {
				console.log("err", err);
				this.notifyError('The album could not be created successfully')
			})
	}

	cancelForm() {
		this.props.cancel();
	}


	imgUploaded(name, e) {
		var files = Object.values(e.target.files);
		let prevFiles = this.state.files;
		for (var i = 0; i < files.length; i++) {
			prevFiles.push(files[i]);
		}
		this.setState({ files: prevFiles });
		var imgArr = this.state.img_arr, promiseArr = [];
		files.map((file, i) => {
			var reader = new FileReader();
			var promise = new Promise(function (resolve, reject) {
				reader.onload = function (e) {
					var url = e.target.result;
					imgArr.push({ url });
					resolve();
				}
				reader.onerror = function () {
					reject();
				}
				reader.readAsDataURL(file);
			});
			promiseArr.push(promise);
		});
		var that = this;
		Promise.all(promiseArr).then(function () {
			that.setState({ img_arr: imgArr });
		});
	}

	nextStep = (e) => {
		this.setState({ showNext: true });
	}
	uploadVideo = (e) => {
		this.setState({ uploadVideos: true });
	}
	videoUrl = (e) => {
		var valid = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
		let url = e.target.value
		if (valid.test(url) === true) {
			this.setState({ videoUrl: url, isValid: true });
		} else {
			this.setState({ videoUrl: url, isValid: false });
			this.notifyError('Plsease Enter a Valid URL');
		}
	}

	cancel(name) {
		if (name === "imageCancel") {
			this.setState({ img_arr: [] });
		} else if (name === "urlCancel") {
			this.setState({ uploadVideos: false, videoUrl: "" });
		}
	}

	render() {
		return (
			<div>
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				{this.state.showNext === false ? (
					<div className="im-album-modal">
						<Col md={12}>
							<div className="header">
								<h5>Create Album</h5>
							</div>
							<Col md={12}>
								<div className="album-data">
									<Row>
										<Col className="form-group" md={12}>
											<label className="control-label is-imp">Title:</label>
											<input className="form-control" type="text" placeholder="Enter Title" onChange={this.setTitle.bind(this)} />
										</Col>
										<Col className="form-group" md={12}>
											<label className="control-label is-imp">Description:</label>
											<input className="form-control" type="text" placeholder="Enter Description" onChange={this.setDescription.bind(this)} />
										</Col>
										<Col className="form-group" md={12}>
											<label className="control-label is-imp">Select Visibility:</label>
											<select className="form-control" required onChange={this.resourcesVisibility.bind(this)}>
												<option value=''>Select</option>
												<option value='public' >Visible To Corporates</option>
												<option value='private' >Visible Only To My Organisation</option>
											</select>
										</Col>
										<Col className="form-group" md={12}>
											<h4 className="control-label is-imp">Upload thumbnail for your album:</h4>
											<input type="file" accept="image/*" multiple className="btn btn-info" style={{ width: '100% !important' }}
												onChange={this.uploadDocFile.bind(this, 'album')}
											></input>
										</Col>
										<Col className="mt10 mb10 form-buttons" md={12} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
											{this.state.name === "thumbnailSubmit" ? <img style={{ width: "40px" }} src="/images/Spinner.svg" alt="" /> : null}
											<button type="button" className="btn btn-default pull-right" style={{ marginRight: '10px' }} onClick={this.submitForm.bind(this, 'thumbnailSubmit')}> Submit </button>
											<button type="button" className="btn btn-default btn-danger pull-right" onClick={this.cancelForm.bind(this)}> Cancel </button>
										</Col>
									</Row>
								</div>
							</Col>
						</Col>
					</div>
				) : (
						<div className="im-album-modal">
							<Col md={12} style={{ height: "inherit" }}>
								<div style={{ height: "inherit", display: "flex", justifyContent: "space-between" }}>
									<Col md={6} style={{ height: "inherit" }}>
										<div className="header">
											<h3 style={{ textAlign: "center" }}>Upload Images</h3>
										</div>
										<div className="image-upload-container">
											{(this.state.img_arr.length > 0) ?
												<div style={{ display: "flex", justifyContent: "center" }}>
													<div style={{ display: "flex", width: "400px", height: "210px", flexWrap: "wrap", overflowX: "scroll", alignItems: "center", justifyContent: "center" }}>
														{_.map(this.state.img_arr, (img, index) => (
															<div className="add-image-button"
																key={index + "add-img"}
																style={{
																	height: '90px', width: '90px', marginBottom: "10px",
																	backgroundImage: 'url(' + img.url + ')',
																	backgroundSize: 'cover',
																	marginRight: "10px"
																}}>
															</div>))
														}
													</div>
												</div>
												: <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", height: "145px", cursor: "pointer" }} onClick={this.uploadImage.bind(this)}>
													<img src="/images/img-upload.png" style={{ width: "20%" }} alt="" />
												</div>}
											<div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
												<div className={this.state.name === 'imageSubmit' ? "add-image-button-modal-disabled" : "add-image-button-modal"} disabled={this.state.name === 'imageSubmit' ? true : false} onClick={this.uploadImage.bind(this)}>
													<span>Upload Images</span>
													<input type="file" style={{ display: 'none' }} multiple accept="image/*" id="upload_img" onChange={this.imgUploaded.bind(this, 'image')} />
												</div>
												{this.state.img_arr.length > 0 ? (<div style={{ display: "flex", flexDirection: "column" }}>
													<button type="button" className="submit-images" style={{ marginTop: "10px", position: "relative" }} disabled={this.state.name === 'imageSubmit' ? true : false} onClick={this.submitForm.bind(this, "imageSubmit")}>
														{this.state.name === "imageSubmit" ? <img style={{ width: "30px", position: "absolute", left: "20px", bottom: "1.5px" }} src="/images/Spinner-white.svg" alt="" /> : null} Submit </button>
													<button type="button" style={{ marginTop: "10px" }} className="cancel-images" disabled={this.state.name === 'imageSubmit' ? true : false} onClick={this.cancel.bind(this, "imageCancel")}>Cancel</button>
												</div>) : (null)}

											</div>
										</div>
									</Col>


									<Col md={1} style={{ height: "inherit", display: "flex", justifyContent: "center", alignItems: "center" }}>
										<div style={{ width: "1px", background: "#a9a9a9", height: "70%" }}></div>
									</Col>


									<Col md={5} style={{ height: "inherit" }}>
										<div className="header">
											<h3 style={{ textAlign: "center" }}>Upload Videos</h3>
										</div>
										<div className="upload-video-container">
											{(this.state.uploadVideos) ?
												<div style={{ display: "flex", justifyContent: "center", height: "100px", alignItems: "center", flexDirection: "column" }}>
													<div style={{ width: "80%" }}><h4 style={{ fontSize: "15px", color: "#a9a9a9", marginBottom: "5px" }}>Paste your YoutTube URL here.</h4></div>
													<input className='urlInput' type="text" placeholder='Paste your Url' value={this.state.videoUrl} onChange={this.videoUrl.bind(this)} />
													{this.state.videoUrl !== "" ? <div style={{ width: "80%" }}>{this.state.isValid === true ? <h5 style={{ color: "green" }}><i style={{ color: "green" }} className="fa fa-check-circle"></i> URL is valid</h5> : <h5 style={{ color: "red" }}><i style={{ color: "red" }} className="fa fa-times-circle"></i> URL is invalid, please enter a valid url.</h5>}</div> : null}
												</div>
												: <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }} onClick={this.uploadVideo.bind(this)}>
													<img src="/images/upload_video.png" style={{ width: "25%" }} alt="" />
												</div>}
											<div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
												{this.state.uploadVideos === false ?
													(<div className={this.state.name === 'videourlSubmit' ? "add-image-button-modal-disabled" : "add-image-button-modal"} disabled={this.state.name === 'videourlSubmit' ? true : false} onClick={this.uploadVideo.bind(this)}>
														<span>Upload Videos</span>
														<input type="file" style={{ display: 'none' }} accept="image/*" id="upload_img" />
													</div>)
													: (<div style={{ display: "flex", flexDirection: "column" }}>
														<button type="button" className={this.state.isValid === true ? "submit-valid-url" : "submit-invalid-url"} disabled={this.state.isValid === true ? false : true} style={{ marginTop: '10px', position: "relative" }} onClick={this.submitForm.bind(this, "videoUrlSubmit")}>
															{this.state.name === "videoUrlSubmit" ? <img style={{ width: "30px", position: "absolute", left: "20px", bottom: "1.5px" }} src="/images/Spinner-white.svg" alt="" /> : null} Submit </button>
														<button type="button" style={{ marginTop: "10px" }} className="cancel-url" onClick={this.cancel.bind(this, "urlCancel")}>Cancel</button>
													</div>)}
											</div>
										</div>
									</Col>
								</div>
							</Col>
						</div>
					)}
			</div>
		)
	}
}