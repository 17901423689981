import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import _ from 'lodash';

export default class LinksTab2 extends Component {
  checkPage(page) {
    $('.sidebar').removeClass('ab-testing');
    $('.top-menu').removeClass('ab-testing');
  }

  render() {
    console.log("activated_tabs", this.props.activated_tabs)
    return (
      <div className="main-menus">
        <ul className="sidebar-menu-options">
          {
            _.map(this.props.activated_tabs, (nav, index) => (
              <Link key={index} to={nav.route} activeClassName="active" className="sidebar-menu-items" onClick={this.checkPage.bind(this, nav.value)}>
                <i className={nav.className}></i>
                  {nav.name}
              </Link>
            ))
          }
          {/* {this.props.activated_tabs.indexOf("Dashboard") > -1 ? (
            <Link to={'/dm/dashboard'} activeClassName="active" className="sidebar-menu-items" onClick={this.checkPage.bind(this, 'dashboard')}>
              <i className="fa fa-desktop sidebar-menu-item-icons"></i>
              Dashboard
            </Link>
          ) : (null)} */}

          {/* {this.props.activated_tabs.indexOf("Lists") > -1 ? (
            <Link to={'/dm/lists'} activeClassName="active" className="sidebar-menu-items" onClick={this.checkPage.bind(this, 'lists')}>
              <i className="fa fa-th-list sidebar-menu-item-icons"></i>
              Lists
            </Link>
          ) : (null)} */}

          {/*
          <Link to={'/donors'} activeClassName="active" className="sidebar-menu-items">
            <i className="fa fa-user sidebar-menu-item-icons"></i>
            Donors
          </Link>
          */}

          {/* {this.props.activated_tabs.indexOf("Donations") > -1 ? (
            <Link to={'/dm/donations'} activeClassName="active" className="sidebar-menu-items" onClick={this.checkPage.bind(this, 'donations')}>
              <i className="fa fa-clone sidebar-menu-item-icons"></i>
              Donations
            </Link>
          ) : (null)} */}

          {/*this.props.activated_tabs.indexOf("Groups") > -1 ? (
            <Link to={'/segments'} activeClassName="active" className="sidebar-menu-items">
              <i className="fa fa-users sidebar-menu-item-icons"></i>
              Groups
            </Link>
          ) : (null)*/}

          {/* {this.props.activated_tabs.indexOf("Campaigns") > -1 ? (
            <Link to={'/dm/campaigns'} activeClassName="active" className="sidebar-menu-items" onClick={this.checkPage.bind(this, 'campaigns')}>
              <i className="fa fa-flag sidebar-menu-item-icons"></i>
              Campaigns
              {this.props.ngo.plan.name === 'payment_gateway' || this.props.ngo.plan.name === 'payment_gateway_basic_plus' ? (
                <i className="fa fa-lock"></i>
              ) : (null)}
            </Link>
          ) : (null)} */}

          {/* {true || this.props.activated_tabs.indexOf("Campaigns") > -1 ? (
            <Link to={'/dm/campaigns/ab-testing'} activeClassName="active" className="sidebar-menu-items" onClick={this.checkPage.bind(this, 'ab-testing')}>
              <img src="/images/ab_testing.png" className="sidebar-ab-testing-icon" />
              A/B Testing Campaigns
              {this.props.ngo.plan.name === 'payment_gateway' || this.props.ngo.plan.name === 'payment_gateway_basic_plus' ? (
                <i className="fa fa-lock"></i>
              ) : (null)}
            </Link>
          ) : (null)} */}

          {/* {this.props.activated_tabs.indexOf("Tasks") > -1 ? (
            <Link to={'/dm/tasks'} activeClassName="active" className="sidebar-menu-items" onClick={this.checkPage.bind(this, 'tasks')}>
              <i className="fa fa-calendar-o sidebar-menu-item-icons"></i>
              Tasks
              {this.props.ngo.plan.name === 'payment_gateway' || this.props.ngo.plan.name === 'payment_gateway_basic_plus' ? (
                <i className="fa fa-lock"></i>
              ) : (null)}
            </Link>
          ) : (null)} */}

          {/* {this.props.activated_tabs.indexOf("Settings") > -1 ? (
            <Link to={'/dm/settings'} activeClassName="active" className="sidebar-menu-items" onClick={this.checkPage.bind(this, 'settings')}>
              <i className="fa fa-gear sidebar-menu-item-icons"></i>
              Settings
            </Link>
          ) : (null)} */}

          {/*<Link to={'/subscriptions'} activeClassName="active" className="sidebar-menu-items">
            <i className="fa fa-credit-card sidebar-menu-item-icons"></i>
            Subscription
          </Link>*/}
        </ul>
      </div>
      );
    }
  }