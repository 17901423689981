import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
const readCookie = require('../../ImpactManagement/cookie.js').readCookie;

// import Notification from  '../2-projects/Notification';

export default class EMTopMenu extends Component {
	state = {
		authorizedFor: JSON.parse(readCookie('userData')).authorizedFor,
		notification: false,
		badge: false,
		socket: null,
		messages: [],
		badgeCounts: "",
		showBadge: true,
		selectedTab: ''
	};

	componentDidMount() {
		let pathname = window.location.pathname;
		if(pathname.match('em/expense/report-for-approval') !== null) this.setState({ selectedTab: 'report-for-approval' });
		else if(pathname.match('em/expense/my-reports') !== null) this.setState({ selectedTab: 'my-reports' });
	}

	selectTab = (tab) => {
		this.setState({ selectedTab: tab });
	}

	showNotifications() {
		this.setState({
			notification: !this.state.notification,
			showBadge: false
		});
	}

	openNav() {
		document.getElementById("mySidenav").style.width = "250px";
	}

	closeNav() {
		document.getElementById("mySidenav").style.width = "0";
	}

	render() {
		return (
			<div className="em-top-menu">
				<Row className="full-width">
					<div className="mobile-view-sidenav">
						<span className="side-nav-opener" onClick={this.openNav.bind(this)}>&#9776;</span>
						<div id="mySidenav" className="sidenav">
						  <a href="#" className="closebtn" onClick={this.closeNav.bind(this)}>&times;</a>
						  <a href="/">Home</a>
						  <a href="/em/expense/my-reports">My Reports</a>
						  {this.state.authorizedFor.em === 'admin' ? (
							<a href="/em/expense/report-for-approval">Report Approvals</a>
							) : (null)}
						  <a href="/em/settings">Settings</a>
						  <a href="#" onClick={this.props.logoutUser}>Logout</a>
						</div>
						<div className="current_user_mobile" onClick={()=>{window.location.pathname = "/em/settings"}}>
							{this.props.userData["first_name"] !== '' && this.props.userData["last_name"] ? (
								<span>{this.props.userData["first_name"] + ' ' + this.props.userData["last_name"]}</span>
							) : (null)}
							{this.props.userData["profile_picture"] === "" ? (
								<img alt={this.props.userData["first_name"]} src="/images/user.png" className="img-circle"/>
							) : (
								<img alt={this.props.userData["first_name"]} src={this.props.userData["profile_picture"]}
									onError={event => event.target.setAttribute("src", "/images/user.png")}/>
							)}
						</div>
					</div>
					<div className="menu-items">
						<div className="menu-item-container">
						  <Link to={"/"} className="menu-item">
								Home
						  </Link>
						</div>
						<div className="menu-item-container" onClick={this.selectTab.bind(this, 'my-reports')}>
							<Link to={"/em/expense/my-reports"} className={this.state.selectedTab === 'my-reports' ? "menu-item activeTabs" : "menu-item"}>
								My Reports
							</Link>
						</div>
						{this.state.authorizedFor.em === 'admin' ? (
							<div className="menu-item-container" onClick={this.selectTab.bind(this, 'report-for-approval')}>
								<Link to={"/em/expense/report-for-approval"} className={this.state.selectedTab === 'report-for-approval' ? "menu-item activeTabs" : "menu-item"}>
									Report Approvals
							  </Link>
							</div>
						) : (null)}
					</div>
					<div className="current_user">
						<Link to={"/em/settings"} className="loggedin_user">
							{this.props.userData["first_name"] !== '' && this.props.userData["last_name"] ? (
								<h3>{this.props.userData["first_name"] + ' ' + this.props.userData["last_name"]}</h3>
							) : (null)}
							{this.props.userData["profile_picture"] === "" ? (
								<img alt={this.props.userData["first_name"]} src="/images/user.png" className="img-circle"/>
							) : (
								<img alt={this.props.userData["first_name"]} src={this.props.userData["profile_picture"]}
									onError={event => event.target.setAttribute("src", "/images/user.png")}/>
							)}
						</Link>
					</div>
					<div className="logout-user-button-item"
						style={{top: '8px', right: '45px', fontWeight: 600, cursor: 'pointer', padding: '10px 0px', position: 'absolute'}}
						onClick={this.props.logoutUser}>
						<p><i className="fa fa-power-off"></i> Logout</p>
				</div>
				</Row>
			</div>
		);
	}
}
