import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

const readCookie = require('../cookie.js').readCookie;

var EmailNotificationToast = React.createClass({
  render() {
    return (
      <span className="msg email-notification-settings-toast">{'Updated '}<highlight>{this.props.text}</highlight>{' email status!'}</span>
    )
  }
});

export default class SettingsEmailNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_types: [],
      when_to_send_options: [
        {
          value: 0,
          label: 'Same Day'
        }, {
          value: 1,
          label: ' Day in advance'
        }, {
          value: 2,
          label: ' Days in advance'
        }, {
          value: 3,
          label: ' Days in advance'
        }, {
          value: 4,
          label: ' Days in advance'
        }, {
          value: 5,
          label: ' Days in advance'
        }, {
          value: 6,
          label: ' Days in advance'
        }, {
          value: 7,
          label: ' Days in advance'
        }, {
          value: 8,
          label: ' Days in advance'
        }, {
          value: 9,
          label: ' Days in advance'
        }, {
          value: 10,
          label: ' Days in advance'
        }
      ]
    }
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      let email_notification_settings = data['ngo']['email_notification_settings'];
      for(let notification_type in email_notification_settings) {
        this.setState({ [notification_type]: email_notification_settings[notification_type] });
      }
      this.setState({ email_types: Object.keys(email_notification_settings) });
    }.bind(this));
  }

  notificationStatusChange(name) {
    var email = this.state[name];
    email.active = !email.active;

    let notificationSettingsObj = {};
    let email_types = this.state.email_types;

    for(var i = 0; i < email_types.length; i++) {
      if(name === email_types[i]) {
        notificationSettingsObj[name] = email;
      } else {
        notificationSettingsObj[email_types[i]] = this.state[email_types[i]];
      }
    }

    fetch(process.env.REACT_APP_API_URL + '/ngo/email-notification-settings/' + this.props.ngo.ngo_id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify(notificationSettingsObj)
    }).then(function(response) {
      return response.json();
    }).then(function(response) {
      if(response.status === 'ok') {
        this.notifySuccess(<EmailNotificationToast text={this.state[name].title} />);
        this.props.updateNgoData();
        this.setState({ [name]: email });
      }
    }.bind(this));
  }

  handleDaysBeforeChange(name, e) {
    var email = this.state[name];
    email.days_before = e.target.value;

    let notificationSettingsObj = {};
    let email_types = this.state.email_types;

    for(var i = 0; i < email_types.length; i++) {
      if(name === email_types[i]) {
        notificationSettingsObj[name] = email;
      } else {
        notificationSettingsObj[email_types[i]] = this.state[email_types[i]];
      }
    }

    fetch(process.env.REACT_APP_API_URL + '/ngo/email-notification-settings/' + this.props.ngo.ngo_id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify(notificationSettingsObj)
    }).then(function(response) {
      return response.json();
    }).then(function(response) {
      if(response.status === 'ok') {
        this.notifySuccess(<EmailNotificationToast text={this.state[name].title} />);
        this.props.updateNgoData();
        this.setState({ [name]: email });
      }
    }.bind(this));
  }

  notifySuccess = (text) => toast.success(text);

  render() {
    return (
      <div className="tab-container mt30">
        <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
        {this.state.email_types.map(function(type, index) {
          return (
            <Col md={10} mdOffset={1} className="settings-auto-notification-container">
              <div className="ondisplay2">
                <section>
                  <div className="slideTwo">
                    <input type="checkbox" id={type} checked={this.state[type].active}
                      onChange={this.notificationStatusChange.bind(this, type)}
                      className="send_email_receipt_check" name="check" />
                    <label htmlFor={type}></label>
                  </div>
                </section>
                <span className="msg">
                  Send automated
                  {this.state[type].template_id ? (
                    <a href={"/dm/template/" + this.state[type].template_id}>
                      <highlight>{this.state[type].title}</highlight>
                    </a>
                  ) : (
                    <highlight>{this.state[type].title}</highlight>
                  )}
                  emails
                </span>
              </div>
              <div className="when-to-send-container">
                <select className="form-control" defaultValue={this.state[type].days_before} onChange={this.handleDaysBeforeChange.bind(this, type)}>
                  <option value="" selected disabled>When to send</option>
                  {this.state.when_to_send_options.map(function(option, index) {
                    return (
                      <option value={option.value}>{option.value ? (option.value + option.label) : option.label}</option>
                    )
                  })}
                </select>
              </div>
            </Col>
          )
        }.bind(this))}
      </div>
    );
  }
}

// <label><input type="checkbox" name="checkbox" value="value" /></label>