import React, {Component} from 'react';
import swal from "sweetalert2";
import StarRatingComponent from 'react-star-rating-component';
import { ToastContainer, toast } from 'react-toastify';

import CreateListModal from './CreateListModal';
import EditListModal from './EditListModal';

import "sweetalert2/dist/sweetalert2.min.css";

var momentTimezone = require('moment-timezone');
var timezone = momentTimezone.tz.guess();

const readCookie = require('../cookie.js').readCookie;

export default class Lists extends Component {
	constructor(props) {
		super(props);
		this.state = {
      createListModalVisibility: false,
      editModalVisibility: false,
      selectedObj: {},
			lists: []
		};
    this.showCreateListModal = this.showCreateListModal.bind(this);
    this.hideCreateListModal = this.hideCreateListModal.bind(this);
    this.showEditModal = this.showEditModal.bind(this);
    this.hideEditListModal = this.hideEditListModal.bind(this);
	}

	deleteList(index) {
		swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!',
      cancelButtonText: 'No, Cancel!',
      confirmButtonClass: 'btn btn-danger',
      cancelButtonClass: 'btn',
  	}).then(function (result) {
      if(result) {
      	let list_id = this.state.lists[index].id;
      	fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/list/' + list_id, {
		      method: 'DELETE',
		      headers: {
		        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
		      }
		    }).then((response) => response.json())
		    .then(function(data) {
		      if(data.status === 'ok') {
		      	this.notifySuccess('The list has been deleted successfully.');
		        fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/lists', {
				      method: "GET",
				      headers: {
				        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
				      }
				    }).then(function(response) {
				      return response.json();
				    }).then(function(data) {
				      this.setState({ lists: data.lists });
				    }.bind(this));
				  }
				}.bind(this));
      }
    }.bind(this));
	}

	notifySuccess = (text) => toast.success(text);

	showCreateListModal() {
    this.setState({ createListModalVisibility: true });
  }

  showEditModal(index) {
  	let selectedObj = {};
  	selectedObj['id'] =  this.state.lists[index].id;
  	selectedObj['description'] =  this.state.lists[index].description;
  	selectedObj['name'] =  this.state.lists[index].name;
  	selectedObj['donor_type'] =  this.state.lists[index].donor_type;

    this.setState({ editModalVisibility: true, selectedObj });
  }

  hideCreateListModal() {
    this.setState({ createListModalVisibility: false });
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/lists', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ lists: data.lists });
    }.bind(this));
  }

  hideEditListModal() {
    this.setState({ editModalVisibility: false, selectedIndex: null });
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/lists', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ lists: data.lists });
    }.bind(this));
  }

  componentWillMount() {
  	fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo.ngo_id + '/lists', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
    	console.log(data)
      this.setState({ lists: data.lists });
    }.bind(this));
  }

	render() {
		return (
			<div className="page-container lists-page">
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<div className="list-header">
					<h2 className="mb20">Lists</h2>
					{this.props.ngo.plan.name !== 'payment_gateway' ? (
						<button className="btn btn-danger" onClick={this.showCreateListModal}>Create List</button>
					) : (null)}
				</div>
				<div className="lists-table-container">
					{/*<div className="lists-sorting-container">
						<div className="lists-checkbox-container">
							<input type="checkbox" className="list-check" />
						</div>
						<div className="lists-sort">
							<label>Sort By</label>
							<select>
								<option>Name</option>
								<option>Created At</option>
								<option>Rating</option>
							</select>
							<button className="btn">
								<i className="fa fa-arrow-down"></i>
							</button>
						</div>
					</div>*/}
					<div className="lists-table">
						{this.state.lists.length === 0 ? (
							<h4>No Lists added. Create Lists to view them.</h4>
						) : (null)}
						{this.state.lists.map(function(list, index) {
							return (
								<div className="list-conatiner" key={list.id}>
									{/*<div className="list-checkbox-container">
										<input type="checkbox" className="list-check" />
									</div>*/}
									<div className="list-details-container">
										<a href={"/dm/lists/" + list.id}><span className="list-name">{list.name}</span></a>
										{list.hasOwnProperty('created_at') ? (
											<span className="list-created-at">Created {momentTimezone.tz(list.created_at, timezone).format('ddd, DD MMM YYYY HH:mm A')}</span>
										) : (null)}
										{list.hasOwnProperty('rating') ? (
											list.rating !== null ? (
												<StarRatingComponent name="rating" editing={false} starCount={5} value={list.rating} className="rating-star" />
											) : (
												'No rating yet'
											)
										) : (null)}
									</div>
									{list.hasOwnProperty('subscribers') ? (
										<div className="list-subscribers-container">
											<span>{list.subscribers}</span>
											<span>Subscribers</span>
										</div>
									) : (
										<div className="list-subscribers-container"></div>
									)}
									{list.hasOwnProperty('opens') ? (
										<div className="list-opens-container">
											<span>{list.opens + '%'}</span>
											<span>Opens</span>
										</div>
									) : (
										<div className="list-opens-container"></div>
									)}
									{list.hasOwnProperty('clicks') ? (
										<div className="list-clicks-container">
											<span>{list.clicks + '%'}</span>
											<span>Clicks</span>
										</div>
									) : (
										<div className="list-clicks-container"></div>
									)}
									{list.hasOwnProperty('count') ? (
										<div className="list-count-container">
											<a href={"/dm/lists/" + list.id}><span>{list.count}</span></a>
											<span>{list.groups === 1 ? 'Member' : 'Members'}</span>
										</div>
									) : (
										<div className="list-count-container"></div>
									)}
									{list.hasOwnProperty('groups') ? (
										<div className="list-count-container">
											<a href={"/dm/lists/" + list.id + "/segments"}><span>{list.groups}</span></a>
											<span>{list.groups === 1 ? 'Segment' : 'Segments'}</span>
										</div>
									) : (
										<div className="list-count-container">
											<a href={"/dm/lists/" + list.id + "/segments"}><span>0</span></a>
											<span>Segments</span>
										</div>
									)}
									<div className="list-delete-container">
										{list.hasOwnProperty('system_generated') && list.system_generated ? (null) : (
											<button className="btn btn-danger" onClick={this.deleteList.bind(this, index)}>Delete</button>
										)}
									</div>
									<div className="list-delete-container">
										{list.hasOwnProperty('system_generated') && list.system_generated ? (null) : (
											<button className="btn le-btn" onClick={this.showEditModal.bind(this, index)}>Edit</button>
										)}
									</div>
								</div>
							);
						}.bind(this))}
					</div>
				</div>
				{this.state.createListModalVisibility ? (
	        <CreateListModal ngo={this.props.ngo}
	          ngo_id={this.props.ngo.ngo_id} notifySuccess={this.notifySuccess}
	          hideCreateListModal={this.hideCreateListModal}
	          showCreateListModal={this.state.createListModalVisibility} />
	      ) : (null)}
	      {this.state.editModalVisibility ? (
	        <EditListModal ngo={this.props.ngo}
	          ngo_id={this.props.ngo.ngo_id} notifySuccess={this.notifySuccess}
	          hideEditListModal={this.hideEditListModal}
	          selectedObj={this.state.selectedObj}
	          showEditModal={this.state.editModalVisibility} />
	      ) : (null)}
			</div>
		);
	}
}