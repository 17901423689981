import React, { Component } from 'react';

export default class EditModelIcon extends Component{
/* 
    props passed
    1. function --> deleteParent()
    2. isVisible
*/
    constructor(props){
        super(props);
        this.state = {

        }
    }

    deleteComponent(event){
        this.props.deleteParent();
    }

    render(){
        return(
            <div>
                {
                    (this.props.isVisible)
                        ?
                        (
                            this.props.attachmentCount !== null
                                ?
                            <div className="edit-mode-close" onClick={this.deleteComponent.bind(this)}>
                                <span style={{color: '#ffffff'}}>{this.props.attachmentCount}</span>
                                {/* <i className="fa fa-close" style={{color: "white", fontSize: '10px'}} /> */}
                            </div>
                                :
                                null
                            // <div className="edit-mode-close" onClick={this.deleteComponent.bind(this)}>
                            //     <i className="fa fa-close" style={{color: "white", fontSize: '10px'}} />
                            // </div>
                        )
                        
                        :
                    null
                }
            </div>
           
        )
    }
}