import _ from 'lodash';
import moment from 'moment';
import swal from 'sweetalert2';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { LocaleProvider, DatePicker } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { ToastContainer, toast } from 'react-toastify';

const dateFormat = 'YYYY/MM/DD';
const readCookie = require('../../ImpactManagement/cookie.js').readCookie;

moment.locale('en');

export default class ExpenseModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bill: '',
            categoryList: ["Meal", "Travel", "Entertainment", "Mobile", "Internet", "Sundry", "Others"],
            payments: ["Wallet", "UPI", "Cash", "Debit Card", "Credit Card", "Netbanking"],
            paymentMode: '',
            report: this.props.reports,
            description: this.props.reports.description,
            expense_category: this.props.reports.expense_category,
            payment_mode: this.props.reports.payment_mode,
            merchant_name: this.props.reports.merchant_name,
            bill_value_amount: Number(this.props.reports.bill_value_amount),
            bill_number: this.props.reports.bill_number,
            reimbursable_value: this.props.reports.reimbursable_value,
            uploaded_bill: this.props.reports.uploaded_bill,
            created_bill_date: moment(this.props.reports.created_bill_date).format('YYYY-MM-DD').toString(),
            showBill: false,
            File: null,
            //  File: null,
        }
    }

    setMerchant(e) {
        this.setState({ merchant_name: e.target.value })
    }
    billCategory(e) {
        this.setState({ expense_category: e.target.value })
    }
    paymentMode(e) {
        this.setState({ payment_mode: e.target.value })
    }

    submitForm(e) {
      let data = new FormData();
      let bill_number = this.state.bill_number;
      let description = this.state.description;
      let payment_mode = this.state.payment_mode;
      let merchant_name = this.state.merchant_name;
      let expense_category = this.state.expense_category;
      let bill_value_amount = this.state.bill_value_amount;
      let created_bill_date = this.state.created_bill_date;
      let reimbursable_value = this.state.reimbursable_value;

      if(Number(this.state.reimbursable_value) > Number(this.state.bill_value_amount)){
          toast.error('Reimbursable value cannot be greater than bill amount');
          return;
      }

      if(bill_number.trim() !== '' && description.trim() !== ''  && merchant_name.trim() !== '' && payment_mode !== '' && expense_category !== '' &&  bill_value_amount !== '' && created_bill_date !== '' && reimbursable_value !== '') {
        data.append('expense_category', this.state.expense_category);
        data.append("created_bill_date", this.state.created_bill_date);
        data.append('description', this.state.description);
        data.append('merchant_name', this.state.merchant_name);
        data.append('bill_value_amount', this.state.bill_value_amount);
        data.append('reimbursable_value', this.state.reimbursable_value);
        data.append('payment_mode', this.state.payment_mode);
        data.append('bill_number', this.state.bill_number);
        {
            (this.state.File !== null
                ?
                data.append('bill_file', this.state.File[0])
                :
                data.append('bill_file_url', this.state.uploaded_bill)
            )
        }

        swal({
            title: 'Are you sure you want to edit this expenseline?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            fetch(process.env.REACT_APP_API_URL + '/EM/update_expense_line/' + this.props.reportId + "/" + this.props.selectedExpenseId, {
                method: 'PUT',
                headers: {
                  'Authorization': JSON.parse(readCookie('access_token')).access_token
                },
                body: data
            }).then(res => (res.json()))
            .then(res => {
              console.log("data submitted", res, data);
              toast.success('You have successfully edited this expense row');
              window.location.pathname = "/em/expense/my-reports/" + this.props.reportId;
            }).catch(err => {
              toast.error('Failed to create edit this expense row');
            })
        });
      } else {
        toast.error('Please fill up the mandatory fields.');
      }
    }
    cancelForm(e) {
      window.location.pathname = "/em/expense/my-reports/" + this.props.reportId
    }
    backForm(e) {
      window.location.pathname = "/em/expense/"
    }
    UploadBill(e) {
      this.setState({ File: e.target.files })
    }
    setPurpose(event) {
        this.setState({ description: event.target.value })
    }
    setBillValue(e) {
        this.setState({ bill_value_amount: e.target.value })
    }
    setDate(date, dateString, e) {
        this.setState({ created_bill_date: dateString })
    }

    render() {
        return (
            <div>

                <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />

                <div>
                    <h4 style={{ textAlign: 'center' }}>Edit Expense Report</h4>
                    <Row>
                        <Col md={12}>
                            <Col md={6} className="mt10 mb10">
                                <label className="col-md-12 is-imp">Select Expense Category</label>
                                <Col md={12}>
                                    <select value={this.state.bill} className="form-control" onChange={this.billCategory.bind(this)}>
                                        <option value='' disabled>{this.state.expense_category}</option>
                                        {
                                            _.map(this.state.categoryList, (cat, index) => {
                                                if (this.state.report.expense_category === cat) {
                                                    return null;
                                                }
                                                else {
                                                    return (<option value={cat} key={cat + index}>{cat}</option>);
                                                }

                                            })
                                        }
                                    </select>
                                </Col>
                            </Col>
                            <Col md={6} className="mt10 mb10">
                                <label className="col-md-12 is-imp">Purpose/Description</label>
                                <Col md={12}>
                                    <input type="text" id="lead-assessor" placeholder="Enter Description"
                                        id="input-box-form" className="form-control" value={this.state.description}
                                        onChange={this.setPurpose.bind(this)}
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="mt10 mb10">
                                <label className="col-md-12 is-imp">Bill Date</label>
                                <Col md={12}>
                                    <LocaleProvider locale={enUS}>
                                        <DatePicker locale={enUS} style={{ width: "100%" }} id="lead-assessor" placeholder="Enter Bill Date" defaultValue={moment(this.state.created_bill_date, dateFormat)} format={dateFormat}
                                            //ref={(event) => this.bill_date = event}
                                            onChange={this.setDate.bind(this)}
                                        />
                                    </LocaleProvider>
                                </Col>
                            </Col>

                            <Col md={6} className="mt10 mb10">
                                <label className="col-md-12 is-imp">Select Payment Mode</label>
                                <Col md={12}>
                                    <select value={this.state.paymentMode} className="form-control" onChange={this.paymentMode.bind(this)}>
                                        <option value='' disabled>{this.state.payment_mode}</option>
                                        {
                                            _.map(this.state.payments, (payment, index) => {
                                                if (payment === this.state.report.payment_mode) {
                                                    return null;
                                                }
                                                else {
                                                    return (<option value={payment} key={index + payment}>{payment}</option>)
                                                }
                                            })
                                        }
                                    </select>

                                </Col>
                            </Col>

                            <Col md={6} className="mt10 mb10">
                                <label className="col-md-12 is-imp">Merchant Name</label>
                                <Col md={12}>
                                    <input type="text" id="lead-assessor" placeholder="Enter Merchant Name"
                                        id="input-box-form" className="form-control" value={this.state.merchant_name}
                                        onChange={this.setMerchant.bind(this)}
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="mt10 mb10">
                                <label className="col-md-12 is-imp">Enter Bill Amount</label>
                                <Col md={12}>
                                    <input type="number" id="lead-assessor" placeholder="Enter Bill Amount"
                                        id="input-box-form" className="form-control" value={this.state.bill_value_amount}
                                        //ref={(event) => this.bill_value = event}
                                        onChange={this.setBillValue.bind(this)}
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="mt10 mb10">
                                <label className="col-md-12 is-imp">Enter Bill Number</label>
                                <Col md={12}>
                                    <input type="text" id="lead-assessor" placeholder="Enter Bill Number"
                                        id="input-box-form" className="form-control" value={this.state.bill_number}
                                        //ref={(event) => this.bill_number = event}
                                        onChange={(e) => { this.setState({ bill_number: e.target.value }) }}
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="mt10 mb10">
                                <label className="col-md-12 is-imp">Enter Reimbursable Value (INR)</label>
                                <Col md={12}>
                                    <input type="number" id="lead-assessor" placeholder="Enter Reimbursable Value (INR)"
                                        id="input-box-form" className="form-control" value={this.state.reimbursable_value}
                                        //ref={(event) => this.reimbursable_value = event}
                                        onChange={(e) => { this.setState({ reimbursable_value: e.target.value }) }}
                                    />
                                </Col>
                            </Col>
                            <Col md={12} className="mt10 mb10">
                                <label className="col-md-12">Upload Bill</label>
                                <Col md={6}>
                                    {
                                        (this.state.uploaded_bill !== null
                                            ?
                                            <div>
                                                <a href={this.state.uploaded_bill} target="_blank" hidden={(this.state.uploaded_bill === null) ? true : false}>View bill</a>

                                                <input type="file" onChange={this.UploadBill.bind(this)} />
                                            </div>
                                            :
                                            <input type="file" onChange={this.UploadBill.bind(this)} />
                                        )
                                    }

                                </Col>

                            </Col>
                            <Col md={12} className="mt10 mb10">

                                <Col md={8}>
                                    <button type="button" className="btn btn-danger" onClick={this.cancelForm.bind(this)}>Cancel</button>
                                </Col>
                                <Col md={4}>
                                    <button type="button" className="btn btn-info" onClick={this.submitForm.bind(this)}>Update expense line</button>
                                </Col>

                            </Col>

                        </Col>
                    </Row>
                </div>

                {/* </div> */}
            </div>
        )
    }

}