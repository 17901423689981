import React, {Component} from 'react';

export default class Premium extends Component {
  render() {
    return (
      <div className='page-container premium-content' style={{background: "url('/images/" + this.props.background + "')"}}>
      	<div className='premium-container' dangerouslySetInnerHTML={{ __html: this.props.premiumText }}></div>
      </div>
    );
  }
}