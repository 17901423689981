import React, { Component } from 'react';
import _ from 'lodash';

export default class Pagination extends Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render(){
        return(
            <div className="pagination">
                <ul className="pagination">
                    {
                        _.times(28, function(index){
                            return (<li key={index}><a>{index+1}</a></li>)
                        })
                    }
                </ul>
            </div>
        )
    }
}