// elements
var textbox = `<div class="row _form-element">
		<div class="col-md-12">
			<label class="active _required-star control-label" for="textbox" data-error="Error" data-required="false">Textbox</label>
			<input placeholder="Input Text Here" id="textbox" type="text" class="validate browser-default form-control">
		</div>
	</div>`;


var textarea = `<div class="row _form-element">
		<div class=" col-md-12">
		  <label class="active _required-star control-label" for="textarea" data-error="Error" data-required="false">Textarea</label>
		  <textarea id="textarea" class="validate browser-default form-control" placeholder="Some text"></textarea>
		</div>
	</div>`;

var locationElement = `<div class="row _form-element _can-be-parent">
		<div class="col-md-12 location-container">
			<label class="active _required-star control-label" for="locationLabel" data-error="Error" data-required="false">Location</label>
		</div>
	</div>`;

var areaMapElement = `<div class="row _form-element _can-be-parent">
		<div class="col-md-12 area_map-container">
			<label class="active _required-star control-label" for="area_mapLabel" data-error="Error" data-required="false">Locate Area</label>
		</div>
	</div>`;

var distanceElement = `<div class="row _form-element _can-be-parent">
		<div class="col-md-12 distance-container">
			<label class="active _required-star control-label" for="distanceLabel" data-error="Error" data-required="false">Locate distance</label>
		</div>
	</div>`;

var scaleInput = `<div class="row _form-element _can-be-parent">
		<div class="col-md-12">
			<label for="scale-element" id="scaleLabel" data-error="Error" data-required="false" class="control-label">Scale</label>
			<input type="range" min=0 max=10 value=3 id="scale-element" class="scale-element browser-default form-control mb10" data-required="false">
		</div>
	</div>`;

var barcodeInput = `<div class="row _form-element _can-be-parent">
		<div class="col-md-12">
			<label for="barcode-element" id="barcodeLabel" data-error="Error" data-required="false" class="control-label">Barcode</label>
			<p style="color: red; font-size: 14px; text-decoration: underline;">Can only be used in mobile phones.</p>
		</div>
	</div>`;

var signatureInput = `<div class="row _form-element _can-be-parent">
		<div class="col-md-12">
			<label for="signature-element" id="signatureLabel" data-error="Error" data-required="false" class="control-label">Signature</label>
			<div class="sigPad" id="smoothed" style="width:100%;">
				<ul class="sigNav">
					<li class="drawIt"><a href="#draw-it" >Draw It</a></li>
					<li class="clearButton"><a href="#clear">Clear</a></li>
				</ul>
				<div class="sig sigWrapper" style="height:auto;">
					<div class="typed"></div>
					<canvas class="pad" width="925" height="200"></canvas>
					<input type="hidden" name="output" class="output">
				</div>
			</div>
		</div>
	</div>`;

var name = `<div class="row _form-element">
		<div class="col-md-6">
			<label class="active _required-star control-label" for="first_name" data-error="Error" data-required="false">First Name</label>
			<input placeholder="John" id="first_name" type="text" class="validate browser-default form-control">
		</div>
		<div class=" col-md-6">
			<label class="active _required-star control-label" for="last_name" data-error="Error" data-required="false">Last Name</label>
			<input placeholder="Doe" id="last_name" type="text" class="validate browser-default form-control">
		</div>
	</div>`;

var choiceCheckbox = `<div class="row _form-element _can-be-parent">
		<div class="_choice-container">
			<div class=" col-md-12">
				<span id="choiceLabel" class="_required-star control-label" data-required="false">Choice</span>
			</div>
			<div class="row _m0">
				<div class="col-md-4 _choice">
					<input type="checkbox" class="filled-in" id="option-1" />
					<label for="option-1"></label>
				</div>
			</div>
		</div>
	</div>`;

var paymentRadioInput = `<div class="row _form-element _can-be-parent">
	<div class="_payment-container">
		<div class="ml10 mt10 mb10">
			<span id="paymentLabel" class="_required-star control-label" data-required="false">Product Name</span>
		</div>
	</div>
	<div class="products-container">
		<div class="product-container">
			<div class="_product-label-container">
				<input name="paymentRadio" type="radio" class="with-gap" id="option-1" />
				<label for="option-1">Product Type</label>
			</div>
			<div class="_product-quantity-container">
				<label class="active _required-star control-label" data-required="false" for="paymentQuantity" data-error="Error">Enter Quantity</label>
				<input placeholder="10" id="paymentQuantity" type="number" class="validate browser-default form-control" />
			</div>
		</div>
	</div>
	<div class="col-md-12 _total">

	</div>
</div>`;

var paymentDropdownInput = `<div class="row _form-element _can-be-parent">
	<div class="_payment-container">
		<div class=" col-md-12">
			<span id="paymentLabel" class="_required-star control-label" data-required="false">Product Name</span>
		</div>
	</div>
	<div class="col-md-12">
		<div class="col-md-12 _product">
			<select id="productSelect" class="browser-default">
				<option id="option-1">Product Type</option>
			</select>
		</div>
		<div class=" col-md-12 _product-quantity">
			<label class="active _required-star control-label" data-required="false" for="payment" data-error="Error">Enter Quantity</label>
			<input placeholder="10" id="paymentQuantity" type="number" class="validate browser-default form-control">
		</div>
	</div>
	<div class="col-md-12 _total">

	</div>
</div>`;

var choiceRadio = `<div class="row _form-element _can-be-parent">
		<div class="_choice-container">
			<div class=" col-md-12">
				<span id="choiceLabel" class="_required-star control-label" data-required="false">Choice</span>
			</div>
			<div class="col-md-4 _choice">
				<input name="choiceRadio" type="radio" class="with-gap" id="option-1" />
				<label for="option-1"></label>
			</div>
		</div>
	</div>`;

var choiceDropdown = `<div class="row _form-element _can-be-parent">
		<div class="_choice-container">
			<div class=" col-md-12">
				<span id="choiceLabel" class="_required-star control-label" data-required="false">Choice</span>
			</div>
			<div class="col-md-12 _choice">
				<select id="choiceSelect" class="browser-default">
					<option id="option-1"></option>
				</select>
			</div>
		</div>
	</div>`;

var coRelationDropdownInput = `<div class="row _form-element _can-be-parent">
	<div class="_relation-container">
		<div class="col-md-12 _relation">
			<label for="relationLabel" class="_required-star control-label" data-required="false">Relational Element</label>
			<select id="relationSelect" class="browser-default">
				<option id="option-1"></option>
			</select>
		</div>
	</div>
</div>`;

var address = `<div class="row _form-element">
		<div class=" col-md-12 mt10">
			<span id="addressLabel" class="_required-star control-label" data-required="false">Address</span>
		</div>
		<div class=" col-md-4 mt10">
			<label class="control-label _required-star">Country</label>
			<select id="country" class="browser-default">
			</select>
		</div>
		<div class=" col-md-4 mt10">
			<label class="control-label _required-star">State</label>
			<select id="state" class="browser-default">
				<option value="" disabled selected>Choose your state</option>
			</select>
		</div>
		<div class=" col-md-4 mt10">
			<label class="control-label _required-star">City</label>
			<select id="city" class="browser-default">
				<option value="" disabled selected>Choose your city</option>
			</select>
		</div>
		<div class=" col-md-12 mt10">
			<label class="active control-label _required-star" for="zipcode" data-error="Error">Pincode</label>
			<input placeholder="560102" id="zipcode" type="text" class="validate browser-default form-control" maxlength="6">
		</div>
		<div class=" col-md-12 m0">
			<label class="active control-label _required-star" for="area" data-error="Error">Area</label>
			<input placeholder="HSR Layout Sector 6" id="area" type="text" class="validate browser-default form-control">
		</div>
		<div class=" col-md-12 m0">
			<label class="active control-label _required-star" for="street" data-error="Error">Street</label>
			<input placeholder="208 15th Main" id="street" type="text" class="validate browser-default form-control">
		</div>
	</div>`;

var yesno = `<div class="row _form-element _can-be-parent">
		<div class=" col-md-12">
			<span id="yesnoLabel" class="_required-star control-label" data-required="false">Yes/No</span>
		</div>
		<div class="col-md-6">
		  <input name="yesnoPlaceholder" type="radio" id="yesnoPlaceholder1" />
		  <label for="yesnoPlaceholder1">Yes</label>
		</div>
		<div class="col-md-6">
		  <input name="yesnoPlaceholder" type="radio" id="yesnoPlaceholder2" />
		  <label for="yesnoPlaceholder2">No</label>
		</div>
	</div>`;
var phone = `<div class="row _form-element _phone-element">
		<div class=" col-md-12">
			<span id="phoneLabel" class="_required-star control-label" data-required="false">Phone</span>
		</div>
		<div class="col-md-12">
			<input type="tel" id="phone" class="validate browser-default form-control">
		</div>
	</div>`

var date = `<div class="row _form-element">
		<div class="col-md-12">
			<label for="datepicker" id="dateLabel" class="control-label _required-star" data-error="Error">Date</label>
			<div class="col-md-12 dateinput-container">
				<input id="datepicker" autocomplete="false" class="datepicker-here browser-default form-control mb10" data-required="false" placeholder="DD/MM/YYYY">
				<i class="fa fa-calendar date-calendar-icon" aria-hidden="true"></i>
			</div>
		</div>
	</div>`;

var time = `<div class="row _form-element">
		<div class="col-md-12">
			<label for="timepicker" id="timeLabel" data-error="Error" class="control-label _required-star">Time</label>
			<div class="col-md-12 timeinput-container">
				<input type="time" id="timepicker" class="browser-default form-control mb10" data-required="false">
				<i class="fa fa-clock-o time-clock-icon" aria-hidden="true"></i>
			</div>
		</div>
	</div>`;

var email = `<div class="row _form-element">
		<div class="col-md-12">
			<label class="active _required-star control-label" for="email" data-error="Error">Email</label>
			<input placeholder="john@example.com" id="email" type="email" class="validate browser-default form-control">
		</div>
	</div>`;

var number = `<div class="row _form-element">
		<div class="col-md-12">
			<label class="active _required-star control-label" data-required="false" for="number" data-error="Error">Number</label>
			<input placeholder="1" id="number" type="number" class="validate browser-default form-control">
		</div>
	</div>`;

var website = `<div class="row _form-element">
		<div class="col-md-12">
			<label class="active _required-star control-label" data-required="false" for="website">Website</label>
			<input placeholder="https://www.example.com" id="website" type="url" class="validate browser-default form-control">
		</div>
	</div>`;

var currency = `<div class="row _form-element">
		<div class="col-md-12">
			<span id="currencyLabel" class="_required-star control-label" data-required="false">Currency</span>
		</div>
		<div class="col-md-4">
			<label class="_currency-symbol control-label">Rs</label>
			<input placeholder="0.00" id="currency" type="number" class="validate browser-default form-control">
		</div>
	</div>`;

var rating = `<div class="row _form-element">
		<div class="col-md-12">
			<label id="ratingLabel" class="_required-star control-label" data-required="false">Rating</label>
			<div class="my-rating mb10"></div>
		</div>
	</div>`;

var file = `<div class="row _form-element">
		<div class="col-md-12">
			<div class="file-field mt10">
			  <div class="btn _required-star">
					<span id="fileLabel">File</span>
					<input id="fileType" type="file" accept="*">
			  </div>
			  <div class="file-path-wrapper">
				<input class="file-path validate browser-default form-control" type="text" />
			  </div>
			</div>
		</div>
	</div>`;

var header = `<div class="row _form-element">
		<div class="_form-header col-md-12" id="headerBackground">
			<h4 id="headerTitle">Title</h4>
			<h5 id="headerSubtitle">Subtitle</h5>
		</div>
	</div>`;

var content = `<div class="row _form-element">
		<div class="_form-content col-md-12">
			<p>Title</p>
		</div>
	</div>`;

module.exports = {
	textbox,
	textarea,
	locationElement,
	areaMapElement,
	distanceElement,
	scaleInput,
	barcodeInput,
	signatureInput,
	name,
	choiceCheckbox,
	paymentRadioInput,
	paymentDropdownInput,
	choiceRadio,
	choiceDropdown,
	coRelationDropdownInput,
	address,
	yesno,
	phone,
	date,
	time,
	email,
	number,
	website,
	currency,
	rating,
	file,
	header,
	content
}

// elements end