import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { DatePicker } from 'antd';
import Icon from 'react-icons-kit';
import $ from 'jquery';
import swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';

import { ic_add_box } from 'react-icons-kit/md/ic_add_box';
import { ic_edit } from 'react-icons-kit/md/ic_edit';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import _ from 'lodash';
import IMSingleProjectSidebar from './IMSingleProjectSidebar';

import 'antd/dist/antd.css';

const readCookie = require('../cookie.js').readCookie;

// let eventTypes = [
// 	"Added Team Members", "Received Funding ", "Awards and Recognition", "Approvals", "Audit", "MoU", "Phase completion",
// 	"Project Start", "Project End", "Impact Assessment", "Custom Milestones"
// ]

export default class Milestone extends Component {
	constructor(props) {
		super(props);
		this.state = {
			project_id: this.props.match.params.project_id,
			project: null,
			current: 'overview',
			milestones: [],
			milestone_title: [],
			showAddMilestoneButton: false,
			showAddMilestoneForm: false,
			newMilestoneTitle: '',
			newMilestoneEventType: '',
			newMilestoneDate: '',
			newMilestoneUrl: '',
			newMilestoneDesc: '',
			editMilestoneIndex: null,
			showCustomEventType: false,
			showCustomTitle: false,
			editMilestoneForm: false,
			milestoneTitle: "",
			isCustom: false
		}
		this.showAddMilestoneForm = this.showAddMilestoneForm.bind(this);
		this.orderByDate = this.orderByDate.bind(this);
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_MIS_API_URL + "/get-project/" + this.props.match.params.project_id, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then(res => (res.json()))
		.then(res => {
			this.setState({ milestone_title: res.data.milestones });
		}).catch(err => {
			console.log("err", err);
		});

		fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + this.props.match.params.project_id + '/milestone', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then(res => (res.json()))
		.then(res => {
			this.setState({ milestones: res.data });
		}).catch(err => {
			console.log("err", err);
		});

		$(window).scroll(function () {
			if ($('#milestones').length) {
				if ($(window).scrollTop() >= ($('#milestones').offset().top - ($(window).outerHeight() / 1.33) - 50) && !this.state.showAddMilestoneButton) this.setState({ showAddMilestoneButton: true });
				else if ($(window).scrollTop() < ($('#milestones').offset().top - ($(window).outerHeight() / 1.33) - 50) && this.state.showAddMilestoneButton) this.setState({ showAddMilestoneButton: false });
			}
		}.bind(this));

		if ($('#milestones').length) {
			if ($(window).scrollTop() >= ($('#milestones').offset().top - ($(window).outerHeight() / 1.33) - 50) && !this.state.showAddMilestoneButton) this.setState({ showAddMilestoneButton: true });
			else if ($(window).scrollTop() < ($('#milestones').offset().top - ($(window).outerHeight() / 1.33) - 50) && this.state.showAddMilestoneButton) this.setState({ showAddMilestoneButton: false });
		}
	}

	handleClick(key) {
		if (key === 'financials') {
			this.setState({ view: 'financials' }, function () {
				this.setState({ current: key });
			}.bind(this));
		} else if (key === 'impact') {
			this.setState({ view: 'impact' }, function () {
				this.setState({ current: key });
			}.bind(this));
		} else {
			this.setState({ view: '' }, function () {
				this.setState({ current: key }, function () {
					if (key === 'milestones') {
						$('html, body').animate({
							scrollTop: $('#milestones').offset().top - 50
						}, 'slow');
					}
				});
			}.bind(this));
		}
	}

	showAddMilestoneForm() {
		if (this.state.showAddMilestoneForm) {
			this.setState({ showAddMilestoneForm: !this.state.showAddMilestoneForm , editMilestoneForm: false, isCustom: false });
		} else {
			$('html, body').animate({
				scrollTop: document.body.scrollHeight
			}, 'slow');
			this.setState({ showAddMilestoneForm: !this.state.showAddMilestoneForm , editMilestoneForm: false, isCustom: false });
		}
	}

	changeNewMilestone(name, value, dateString) {
		if (name === 'newMilestoneDate') {
			if (dateString !== undefined) this.setState({ [name]: dateString });
		} else {
			this.setState({ [name]: value.target.value });
		}
	}

	saveMilestone() {
		if (this.state.editMilestoneIndex === null) {
			if (this.state.milestoneTitle !== ''  && this.state.newMilestoneDate !== '' && this.state.newMilestoneDesc !== '') {
				let milestones = this.state.milestones;
				let milestone = {};
				milestone.title = this.state.milestoneTitle;
				milestone.date = this.state.newMilestoneDate;
				milestone.description = this.state.newMilestoneDesc;
				// milestone.eventType = this.eventType;
				if (this.state.newMilestoneUrl !== '') milestone.url = this.state.newMilestoneUrl;

				let project_id = this.props.match.params.project_id;
				fetch(process.env.REACT_APP_MIS_API_URL + '/project/' + project_id + '/milestone', {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					},
					body: JSON.stringify(milestone)
				}).then(function (response) {
					return response.json();
				}).then(function (response) {
					if (response.status === 'ok') {
						milestone.entererName = JSON.parse(readCookie('mis_data')).data.fullName;
						milestone.isDeletable = true;
						milestone.id = response.id;
						milestones.push(milestone);

						var orderedMilestones = this.orderByDate(milestones, 'date');
						this.setState({
							milestones: orderedMilestones,
							editMilestoneForm: false,
							newMilestoneTitle: '',
							newMilestoneEventType: '',
							newMilestoneDate: '',
							showCustomTitle: false
						}, function () {
							this.cancelMilestone();
							this.notifySuccess('Successfully added your Milestone.');
						}.bind(this));
					} else {
						this.notifyError('Failed to add your Milestone.');
					}
				}.bind(this));
			} else {
				this.notifyError('Mandatory Values are Missing.');
			}
		} else {
			if ((this.state.milestoneTitle !== '' || this.state.newMilestoneTitle !== "")  && this.state.newMilestoneDate !== '' && this.state.newMilestoneDesc !== '') {
				let milestones = this.state.milestones;

				let milestone = {};
				// milestone.title = this.state.newMilestoneTitle;
				// milestone.date = this.state.newMilestoneDate;
				// milestone.description = this.state.newMilestoneDesc;
				milestone.title = this.state.editMilestoneForm === true ? this.state.newMilestoneTitle : this.state.milestoneTitle;
				milestone.date = this.state.newMilestoneDate;
				milestone.description = this.state.newMilestoneDesc;
				// milestone.eventType = this.eventType;
				if (this.state.newMilestoneUrl !== '') milestone.url = this.state.newMilestoneUrl;

				let selectedMilestoneIndex = this.state.editMilestoneIndex;
				let project_id = this.props.match.params.project_id;
				fetch(process.env.REACT_APP_MIS_API_URL + "/project/" + project_id + "/milestone/" + milestones[selectedMilestoneIndex].id, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					},
					body: JSON.stringify(milestone)
				}).then(function (response) {
					return response.json();
				}).then(function (response) {
					if (response.status === 'ok') {
						milestones[selectedMilestoneIndex].title = this.state.newMilestoneTitle;
						milestones[selectedMilestoneIndex].date = this.state.newMilestoneDate;
						milestones[selectedMilestoneIndex].description = this.state.newMilestoneDesc;
						if (this.state.newMilestoneUrl !== '') milestones[selectedMilestoneIndex].url = this.state.newMilestoneUrl;
						var orderedMilestones = this.orderByDate(milestones, 'date');
						this.setState({ milestones: orderedMilestones }, function () {
							this.cancelMilestone();
							this.notifySuccess('Successfully added your Milestone.');
						}.bind(this));
					} else {
						this.notifyError('Failed to add your Milestone.');
					}
				}.bind(this));
			} else {
				this.notifyError('Mandatory Values are Missing.');
			}
		}
	}

	orderByDate(arr, dateProp) {
		return arr.slice().sort(function (a, b) {
			return this.process(a[dateProp]) < this.process(b[dateProp]) ? -1 : 1;
		}.bind(this));
	}

	process(date) {
		var parts = date.split("/");
		return new Date(parts[2], parts[1] - 1, parts[0]);
	}

	cancelMilestone() {
		this.setState({
			editMilestoneIndex: null,
			showAddMilestoneForm: false,
			newMilestoneTitle: '',
			newMilestoneDate: '',
			newMilestoneUrl: '',
			newMilestoneDesc: '',
			showCustomTitle: false
		});
	}

	hoverMilestone(index) {
		let milestones = this.state.milestones;
		milestones[index]['hovered'] = true;
		this.setState({ milestones });
	}

	hoveroutMilestone(index) {
		let milestones = this.state.milestones;
		milestones[index]['hovered'] = false;
		this.setState({ milestones });
	}

	deleteMilestone(index) {
		swal({
			title: 'Are you sure about deleting this?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, Delete!',
			cancelButtonText: 'No, Cancel!',
			confirmButtonClass: 'btn',
			cancelButtonClass: 'btn',
		}).then(function (result) {
			if (result) {
				let milestones = this.state.milestones;

				let project_id = this.props.match.params.project_id;
				fetch(process.env.REACT_APP_MIS_API_URL + "/project/" + project_id + "/milestone/" + milestones[index].id, {
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					}
				}).then(function (response) {
					return response.json();
				}).then(function (response) {
					if (response.status === 'ok') {
						milestones.splice(index, 1);
						this.setState({ milestones }, function () {
							this.notifySuccess('Successfully deleted your Milestone.');
						}.bind(this));
					}
				}.bind(this));
			}
		}.bind(this));
	}

	editMilestone(index) {
		let milestone = this.state.milestones[index];
		this.setState({ editMilestoneForm: true, newMilestoneTitle: milestone.title, newMilestoneEventType: milestone.eventType });

		$('html, body').animate({
			scrollTop: document.body.scrollHeight
		}, 'slow');
		this.setState({ showAddMilestoneForm: false }, function () {
			if (milestone.hasOwnProperty('url')) this.setState({ showAddMilestoneForm: true, editMilestoneIndex: index, newMilestoneTitle: milestone.title, newMilestoneDate: milestone.date, newMilestoneDesc: milestone.description, newMilestoneUrl: milestone.url });
			else this.setState({ showAddMilestoneForm: true, editMilestoneIndex: index, newMilestoneTitle: milestone.title, newMilestoneDate: milestone.date, newMilestoneDesc: milestone.description, newMilestoneUrl: '' });
		}.bind(this));
	}

	selectEventType(event) {
		this.eventType = event.target.value;
		if (this.eventType === "Custom Milestones") {
			this.setState({ showCustomEventType: true });
		}
	}

	milestoneTitleSelect(event) {
		if (this.state.editMilestoneForm !== true){
			this.state.milestoneTitle = event.target.value;
		} if (this.state.editMilestoneForm === true && event.target.value !== "0") {
			this.setState({newMilestoneTitle: event.target.value});
		}
		if (event.target.value === "0") {
			this.setState({ showCustomTitle: true, newMilestoneTitle: "" });
		}
	}

	notifySuccess = (text) => toast.success(text);
	notifyError = (text) => toast.error(text);

	render() {
		return (
			<div className="im-container im-project-page im-project-overview-page">
				<IMSingleProjectSidebar selected='milestones' project_id={this.props.match.params.project_id} />
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<div className="im-project-container im-live-project-details">
					<Col md={12} id="milestones" className="section pt30">
						<h1 className="ribbon">
							<strong className="ribbon-content">Milestones</strong>
						</h1>
						<div className="milestones-container">
							{this.state.milestones.length ? (<div className="milestones-start"></div>) : (null)}
							{this.state.milestones.map(function (milestone, index) {
								milestone.hover = true; milestone.isDeletable = true;
								return (
									<div className="milestone-container" key={index}>
										{index % 2 === 1 ? (
											<div className="milestones-line-container right">
												{milestone.hovered && milestone.isDeletable ? (
													<span className="dot hovered" onMouseEnter={this.hoverMilestone.bind(this, index)} onMouseLeave={this.hoveroutMilestone.bind(this, index)}>
														<span className="inner-dot">
															<div className="edit" title="Edit" onClick={this.editMilestone.bind(this, index)}><Icon icon={ic_edit} /></div>
															<div className="delete" title="Delete" onClick={this.deleteMilestone.bind(this, index)}><Icon icon={ic_delete} /></div>
														</span>
													</span>
												) : (
														<span className="dot" onMouseEnter={this.hoverMilestone.bind(this, index)} onMouseLeave={this.hoveroutMilestone.bind(this, index)}>
															<span className="inner-dot"></span>
														</span>
													)}
											</div>
										) : (null)}
										<div className={index % 2 === 0 ? "milestones-left-container" : "milestones-right-container"}>
											<div className={index % 2 === 0 ? "left-milestone" : "right-milestone"}>
												<span>
													{milestone.hasOwnProperty('url') ? (
														<a href={milestone.url} target="_blank"><span className="title">{milestone.title}</span></a>
													) : (
															<span className="title">{milestone.title}</span>
														)}
													<span className="date">{moment(milestone.date, 'DD/MM/YYYY').format('DD/MM/YYYY')}</span>
												</span>
												<span className="description">{milestone.description}</span>
											</div>
										</div>
										{index % 2 === 0 ? (
											<div className="milestones-line-container">
												{milestone.hovered && milestone.isDeletable ? (
													<span className="dot hovered" onMouseEnter={this.hoverMilestone.bind(this, index)} onMouseLeave={this.hoveroutMilestone.bind(this, index)}>
														<span className="inner-dot">
															<div className="edit" title="Edit" onClick={this.editMilestone.bind(this, index)}><Icon icon={ic_edit} /></div>
															<div className="delete" title="Delete" onClick={this.deleteMilestone.bind(this, index)}><Icon icon={ic_delete} /></div>
														</span>
													</span>
												) : (
														<span className="dot" onMouseEnter={this.hoverMilestone.bind(this, index)} onMouseLeave={this.hoveroutMilestone.bind(this, index)}>
															<span className="inner-dot"></span>
														</span>
													)}
											</div>
										) : (null)}
									</div>
								)
							}.bind(this))}
							{this.state.milestones.length ? (
								<div className="milestones-end"></div>
							) : (
									<h2 className="text-center">No Milestones Yet</h2>
								)}
							{this.state.milestones.length ? (
								<div className={this.state.showAddMilestoneButton ? "add-milestone-button-container show" : "add-milestone-button-container"} onClick={this.showAddMilestoneForm}>
									<Icon icon={ic_add_box} size={50} />
									<div className="add-milestone">Add Milestone</div>
								</div>
							) : (
									<div className={this.state.showAddMilestoneButton ? "add-milestone-button-container show always" : "add-milestone-button-container always"} onClick={this.showAddMilestoneForm}>
										<Icon icon={ic_add_box} size={50} />
										<div className="add-milestone">Add Milestone</div>
									</div>
								)}
						</div>
						{this.state.showAddMilestoneForm ? (
							<Col md={12} className="add-milestone-form">
								{this.state.editMilestoneForm === true ? <h3>Edit Milestone</h3> : <h3>Add New Milestone</h3>}
								{/* <Col md={5} className="mt10 mb10">
									<label className="col-md-12">Event Type:</label>
									<Col md={12}>
										{this.state.showCustomEventType || this.state.editMilestoneForm ? (
											<span className="milestone-title-span">
												<input type="text" className="form-control" id="milestone-event" value={this.state.newMilestoneEventType} onChange={(e) => { this.eventType = e.target.value; this.setState({ newMilestoneEventType: e.target.value }) }} />
											</span>
										) : (
												<select className="form-control" onChange={this.selectEventType.bind(this)}>
													<option>--Select EventTypes--</option>
													{_.map(eventTypes, (event, index) => (
														<option value={event} key={index}>{event}</option>
													))}
												</select>
											)}
									</Col>
								</Col> */}
								<Col md={2} className="mt10 mb10">
									<label className="col-md-12 is-imp">Date:</label>
									<Col md={12}>
										<LocaleProvider locale={enUS}>
											<DatePicker size="large" locale={enUS} format={'DD/MM/YYYY'} defaultValue={this.state.newMilestoneDate !== '' ? moment(this.state.newMilestoneDate, 'DD/MM/YYYY') : ''} onChange={this.changeNewMilestone.bind(this, 'newMilestoneDate')} allowClear={false} />
										</LocaleProvider>
									</Col>
								</Col>

								<Col md={5} className="mt10 mb10">
									<label className="col-md-12 is-imp">Title:</label>
									<Col md={12}>
										{this.state.showCustomTitle ? (
											<span className="milestone-title-span">
												<input type="text" className="form-control" id="milestone-title" value={this.state.newMilestoneTitle} onChange={(e) => { console.log(e.target.value); this.state.milestoneTitle = e.target.value; this.setState({ newMilestoneTitle: e.target.value, isCustom: true }) }} />
											</span>
										) : (
											this.state.editMilestoneForm === true ? (
												this.state.isCustom === true ? (<input type="text" className="form-control" id="milestone-title" value={this.state.newMilestoneTitle} onChange={(e) => { console.log(e.target.value); this.state.milestoneTitle = e.target.value; this.setState({ newMilestoneTitle: e.target.value, isCustom: true }) }} />) :
												(<select className="form-control" onChange={this.milestoneTitleSelect.bind(this)} value={ this.state.newMilestoneTitle}>
													<option disabled selected>Select Title</option>
													{this.state.milestone_title.map((title, index) => {
														return (
															<option value={title} key={index}>{title}</option>
														)
													})}
													<option value="0">Custom Title</option>
												</select>)
												) : (
												<select className="form-control" onChange={this.milestoneTitleSelect.bind(this)}>
													<option disabled selected>Select Title</option>
													{this.state.milestone_title.map((title, index) => {
														return (
															<option value={title} key={index}>{title}</option>
														)
													})}
													<option value="0">Custom Title</option>
												</select>
												)
											)}
									</Col>
								</Col>

								<Col md={12} className="mt10 mb10">
									<label className="col-md-12 is-imp">Description:</label>
									<Col md={12}>
										<textarea id="milestone-description" defaultValue={this.state.newMilestoneDesc} onChange={this.changeNewMilestone.bind(this, 'newMilestoneDesc')}></textarea>
									</Col>
								</Col>

								<Col md={12} className="mt10 mb10 form-buttons">
									<button className="btn btn-default btn-danger pull-right" onClick={this.cancelMilestone.bind(this)}>Cancel</button>
									<button className="btn btn-default pull-right" onClick={this.saveMilestone.bind(this)}>Save</button>
								</Col>
							</Col>
						) : (null)}
					</Col>
				</div>
			</div>
		)
	}
}