import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import ReactPlayer from 'react-player';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import moment from 'moment';
import _ from 'lodash';

export default class DataModal extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            dataArray: [],
            kpi_name: null,

        }
    }

    componentDidMount(){
        let arr = [];
        if(this.props.dataArray.length>0 || this.props.dataArray.length !==  undefined){
            _.map(this.props.dataArray, (el, i) => {
                arr.push({count: el.value[0].unit, date: el.date})
            })
            this.setState({dataArray: arr, kpi_name: this.props.dataArray[0].value[0].kpi_name});
        }
       
    }

    render(){
       // console.log("DataModal", this.state);
        return(
            <div className="im-updates-images-modal-container">
            {
                (this.state.dataArray.length>0)
                    ?
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>KPI NAME</th>
                            {
                                _.map(this.state.dataArray, (el, i) => (
                                    <th>{el.date}</th>
                                ))
                            }
                        </tr> 
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.state.kpi_name}</td>
                            {
                                _.map(this.state.dataArray, (el, i) => (
                                    <td>{el.count}</td>
                                ))
                            }
                        </tr>
                    </tbody>
                </table>
                    :
                   <div>You have not added any accessment to this KPI. Please add one!</div>
            }
               
           
           </div>
            )

    }
}