import React, { Component } from "react";
import { Modal, Row } from "react-bootstrap";

import DonorDonation from "./add-donation/DonorDonation";

const readCookie = require('../cookie.js').readCookie;

export default class AddDonation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      projectData: []
    };
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors',{
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ data });
    }.bind(this));

    fetch(process.env.REACT_APP_LE_URL + "/ngo/" + this.props.ngo["le_ngo_id"] + "/websiteproject")
    .then(function(response) {
      return response.json();
    }).then(function(projectData) {
      this.setState({ projectData });
    }.bind(this));
  }

  render() {
    return (
      <div>
        <Modal
          show={this.props.showAddDonationModal}
          onHide={this.props.onAddDonationClose}
          bsSize="large" >
          <Modal.Header>
            <Modal.Title className="modal-header-custom">Log A New Donation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <DonorDonation ngo={this.props.ngo} onAddDonationClose={this.props.onAddDonationClose}
                ngoId={this.props.ngo['ngo_id']} donorId={this.props.donorId}
                userEmail={this.props.userData.email} projectData={this.state.projectData}/>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
