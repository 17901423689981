import React, { Component } from "react";
import { Modal, Row } from "react-bootstrap";

import EditDonorDonation from "./edit-donation/EditDonorDonation";

const readCookie = require('../cookie.js').readCookie;

export default class EditDonation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      projectsData: null,
      donation: null
    };
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/donors', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ data });
    }.bind(this));

    let projectsData = {};

    fetch(process.env.REACT_APP_LE_URL + "/ngo/" + this.props.ngo["le_ngo_id"] + "/websiteproject")
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      projectsData['ngoProjects'] = data.data.projects;
      if(projectsData.hasOwnProperty('customProjects')) this.setState({ projectsData });
    }.bind(this));

    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/customprojects', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      let customProjects = [];
      for(var i = 0; i < data.data.length; i++) {
        let customProject = {};
        customProject['id'] = data.data[i]._id;
        customProject['title'] = data.data[i].name;
        customProjects.push(customProject);
      }
      projectsData['customProjects'] = customProjects;
      if(projectsData.hasOwnProperty('ngoProjects')) this.setState({ projectsData });
    }.bind(this));

    this.setState({ donation: this.props.donation });
  }

  render() {
    if(this.state.donation !== null && this.state.projectsData !== null) {
      return (
        <div>
          <Modal
            show={this.props.showEditDonationModal}
            onHide={this.props.onEditDonationClose}
            bsSize="large" >
            <Modal.Header>
              <Modal.Title className="modal-header-custom">
                Edit Donation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <EditDonorDonation ngo={this.props.ngo}
                  onEditDonationClose={this.props.onEditDonationClose}
                  ngoId={this.props.ngo['ngo_id']} donorId={this.props.donorId}
                  userEmail={this.props.userData.email} donation={this.state.donation}
                  projectData={this.state.projectsData}
                />
              </Row>
            </Modal.Body>
          </Modal>
        </div>
      );
    } else return null;
  }
}
