import React from 'react';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import moment from 'moment';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import RepositoryFilter from './RepositoryFilter';
import Loader from '../Loader';

const readCookie = require('../cookie.js').readCookie;

const styles = {
  clearBoth: {
    clear: 'both',
  }
};

function linkFormatter(cell, row) {
  var userProfile = "/dm/members/" + row.donor_id;
  var fullName = row['first_name'] + ' ' + row['last_name'];
  return (
    <Link to={userProfile}>{fullName}</Link>
  );
}

function dateFormatter(value, row, index) {
  return moment(value).format('DD/MM/YYYY');
}

export default class FailedRepositoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      totalFailedDonations: null,
      toDate: '',
      fromDate: '',
      showAddDonationModal: false,
      showEditDonationModal: false,
      selectRowProp: {
        mode: 'checkbox',
        onSelect: this.onRowSelect.bind(this),
        onSelectAll: this.onAllRowSelect.bind(this)
      },
      selectedRowCount: 0,
      exportButtonDisabled: true,
      showLoader: true,
      loaderHiding: '',
      donationToEdit: null,
      donorId: null
    };
  }

  onRowSelect(row, isSelected) {
    if(isSelected) {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length+1 }, function() {
        if(this.state.selectedRowCount) {
          this.setState({ exportButtonDisabled: false });
        }
      });
    } else {
      this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length-1 }, function() {
        if(this.state.selectedRowCount) {
          this.setState({ exportButtonDisabled: false });
        } else {
          this.setState({ exportButtonDisabled: true });
        }
      });
    }
  }

  onAllRowSelect(row, isSelected) {
    if(row) {
      this.setState({
        selectedRowCount: this.refs.table.state.data.length,
        exportButtonDisabled: false
      });
    } else {
      this.setState({
        selectedRowCount: 0,
        exportButtonDisabled: true
      });
    }
  }

  componentDidMount() {
    this.getFailedDonations();
  }

  createCustomButtonGroup (props, onClick) {
    return (
      <div>
        <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
          { props.exportCSVBtn }
        </ButtonGroup>
        <button className="total-donors">{'Total Failed Donations: ' + this.state.totalFailedDonations}</button>
      </div>
    );
  }

  createCustomExportCSVButton(onClick) {
    return (
      <ExportCSVButton disabled={this.state.exportButtonDisabled} />
    );
  }

  createCustomClearButton(onClick) {
    return (
      <span onClick={onClick}>
        <i className="clear-table-search glyphicon glyphicon-remove"></i>
      </span>
    );
  }

  contributionModeFormatter(value, row, index) {
    if(value) {
      return <span>{value}</span>
    } else return <span>Others</span>
  }

  handleToDateChange = (value, dateString) => {
    this.setState({ toDate: dateString });
  }

  handleFromDateChange = (value, dateString) => {
    this.setState({ fromDate: dateString });
  }

  applyFilters() {
    let data = this.state.data;
    let filteredData = [];
    for(var i = 0; i < data.length; i++) {
      var selectedDate = moment(data[i].date, 'YYYYMMDD').format('YYYY-MM-DD');
      var toDate = moment(this.state.toDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      var fromDate = moment(this.state.fromDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      if(moment(selectedDate).isBetween(fromDate, toDate) || moment(selectedDate).isSame(fromDate) || moment(selectedDate).isSame(toDate)) {
        filteredData.push(data[i]);
      }
    }
    this.setState({ filteredData });
  }

  resetFilters() {
    this.setState({ fromDate: null, toDate: null }, function() {
      this.setState({ filteredData: this.state.data, fromDate: '', toDate: '' });
    }.bind(this));
  }

  statusFormatter(cell, row) {
    if(cell) return cell;
    else return 'N/A';
  }

  reasonFormatter(cell, row) {
    if(cell) return cell;
    else return 'Unknown';
  }

  getFailedDonations() {
    fetch(process.env.REACT_APP_API_URL + '/ngo/' + this.props.ngo['ngo_id'] + '/repositories?status=failure', {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({
        data: data,
        filteredData: data,
        totalFailedDonations: data.length,
        loaderHiding: 'hiding'
      }, function() {
        setTimeout(function() {
          this.setState({ showLoader: false });
        }.bind(this), 400);
      });
    }.bind(this));
  }

  render () {
    const options = {
      btnGroup: this.createCustomButtonGroup.bind(this),
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton.bind(this),
      exportCSVBtn: this.createCustomExportCSVButton.bind(this),
      sizePerPage: 20,
      sizePerPageList: [20, 50, 100],
      onExportToCSV: () => {
        const selectedRows = this.refs.table.state.selectedRowKeys;
        return this.state.filteredData.filter(i => {
          if (selectedRows.indexOf(i.donation_id) > -1) {
            return i;
          }
        });
      },
    };

    return (
      <div style={styles.clearBoth}>
        {this.state.showLoader ? (
          <Loader loaderHiding={this.state.loaderHiding} />
        ) : ('')}
        {this.state.fromDate !== null && this.state.toDate !== null ? (
          <RepositoryFilter fromDate={this.state.fromDate}  toDate={this.state.toDate}
            applyFilters={this.applyFilters.bind(this)} resetFilters={this.resetFilters.bind(this)}
            handleToDateChange={this.handleToDateChange.bind(this)} handleFromDateChange={this.handleFromDateChange.bind(this)} />
        ) : (null)}
        <BootstrapTable data={this.state.filteredData} options={options} csvFileName={this.props.ngo.ngo_name + ' - Failed Donations ' + String(moment().unix()) + '.csv'}
          striped={false} ref='table' selectRow={this.state.selectRowProp} hover={true}
          pagination search exportCSV searchPlaceholder="Search by Name/Amount/Mode of Contribution/Receipt Id">
          <TableHeaderColumn dataField="donation_id" isKey={true} hidden={true} export={false} searchable={false}>Donation Id</TableHeaderColumn>
          <TableHeaderColumn dataField="first_name"   dataSort={true}   hidden={false}  export={true} searchable={true} dataFormat={linkFormatter}>Name</TableHeaderColumn>
          <TableHeaderColumn dataField="last_name"    dataSort={true}   hidden={true}   export={true} searchable={true}>Last Name</TableHeaderColumn>
          <TableHeaderColumn dataField="amount"       width='120px' dataSort={true}   hidden={false}  export={true} searchable={true}>Amount ({this.props.ngo.currency})</TableHeaderColumn>
          <TableHeaderColumn dataField="status"       width='120px' dataSort={true}   hidden={false}  export={true} searchable={true} dataFormat={this.statusFormatter.bind(this)}>Status</TableHeaderColumn>
          <TableHeaderColumn dataField="contribution_mode" width='120px' dataSort={true}   hidden={false}  export={true} searchable={true} dataFormat={this.contributionModeFormatter}>Mode of Contribution</TableHeaderColumn>
          <TableHeaderColumn dataField="date"         width='140px' dataSort={true}   hidden={false}  export={true} searchable={true} dataFormat={dateFormatter}>Date of Donation</TableHeaderColumn>
          <TableHeaderColumn dataField="failure_reason" dataSort={true}   hidden={false}  export={true} searchable={false} dataFormat={this.reasonFormatter.bind(this)}>Reason</TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}
