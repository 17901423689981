import React, { Component } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';

const readCookie = require('../../ImpactManagement/cookie.js').readCookie;

export default class UserDetailsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: null
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/user/' + this.props.ngo_id + '/' + this.props.userId, {
			method: 'GET',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(res => (res.json()))
		.then(data => {
			this.setState({ userData: data.data });
		});
	}

	render() {
		if(this.state.userData !== null) {
			return (
				<Modal bsSize="large" show={this.props.userDetailsModalVisibility} onHide={this.props.hideUserDetailsModal} style={{position: 'relative'}}>
					<span className="close" style={{position: 'absolute', right: 15, top: 10}} onClick={this.props.hideUserDetailsModal}><i className="fa fa-times"></i></span>
					<Modal.Body style={{margin: 20}}>
					  <Row>
							<Row className="flex-center">
							  <Col md={4} className="mb10">
							    <div className="ngo_image">
							      {(() => {
							        if(this.state.userData['profile_picture'] === '') {
							          return (
							            <img src='/images/user.png' alt="" className="img-circle" />
							          );
							        } else {
							          return (
							            <img src={this.state.userData['profile_picture']} alt="" onError={(event)=>event.target.setAttribute("src","/images/user.png")} />
							          );
							        }
							      })()}
							    </div>
							  </Col>
							  <Col md={8} className="mb10">
							    <Row>
							      {this.state.userData['first_name'] ? (
							      	<Col md={12} className="mb10">
								        <span className="detail-title settings-title">First Name:</span>
								        <span className="detail-data">
								          {this.state.userData['first_name']}
								        </span>
								      </Col>
								    ) : (null)}
							      {this.state.userData['last_name'] ? (
								      <Col md={12} className="mb10">
								        <span className="detail-title settings-title">Last Name:</span>
								        <span className="detail-data">
								          {this.state.userData['last_name']}
								        </span>
								      </Col>
								    ) : (null)}
							      {this.state.userData['email'] ? (
								      <Col md={12} className="mb10">
								        <span className="detail-title settings-title">Email:</span>
								        <span className="detail-data email">
								          {this.state.userData['email']}
								        </span>
								      </Col>
								    ) : (null)}
							      {this.state.userData['bank_name'] ? (
								      <Col md={12} className="mb10">
								        <span className="detail-title settings-title">Bank Name:</span>
								        <span className="detail-data email">
								          {this.state.userData['bank_name']}
								        </span>
								      </Col>
								    ) : (null)}
							      {this.state.userData['bank_account_number'] ? (
								      <Col md={12} className="mb10">
								        <span className="detail-title settings-title">Account Number:</span>
								        <span className="detail-data email">
								          {this.state.userData['bank_account_number']}
								        </span>
								      </Col>
								    ) : (null)}
							      {this.state.userData['ifsc'] ? (
								      <Col md={12} className="mb10">
								        <span className="detail-title settings-title">IFSC:</span>
								        <span className="detail-data email">
								          {this.state.userData['ifsc']}
								        </span>
								      </Col>
								    ) : (null)}
							      {this.state.userData['upi'] ? (
								      <Col md={12} className="mb10">
								        <span className="detail-title settings-title">UPI:</span>
								        <span className="detail-data email">
								          {this.state.userData['upi']}
								        </span>
								      </Col>
								    ) : (null)}
							      {this.state.userData['mobile'] ? (
								      <Col md={12} className="mb10">
								        <span className="detail-title settings-title">Mobile:</span>
								        <span className="detail-data email">
								          {this.state.userData['mobile']}
								        </span>
								      </Col>
								    ) : (null)}
							    </Row>
							  </Col>
							</Row>
						</Row>
					</Modal.Body>
				</Modal>
			);
		} else return null;
	}
}
