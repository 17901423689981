import React, { Component } from 'react';

export default class SeeMore extends Component{
    render(){
        return(
            <div>
                <button className="btn btn-info">See More</button>
            </div>
        )
    }
}