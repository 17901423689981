import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import enUS from 'antd/lib/locale-provider/en_US';
import $ from 'jquery';
import swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import _ from 'lodash';

import IMSingleProjectSidebar from './IMSingleProjectSidebar';

const readCookie = require('../cookie.js').readCookie;

function checkValue(value) {
	if (value === undefined || value === null) return true;
	else if (Array.isArray(value)) {
		if (value.length === 0) return true;
		else if (value.length === 1 && value[0] === '') return true;
		else return false;
	} else return false;
}

export default class IMSingleProject extends Component {
	constructor(props) {
		super(props);
		this.state = {
			project_id: '',
			project_status: false,
			project: null,
			current: 'overview',
			milestones: [],
			showAddMilestoneButton: false,
			showAddMilestoneForm: false,
			newMilestoneTitle: '',
			newMilestoneDate: '',
			newMilestoneUrl: '',
			newMilestoneDesc: '',
			editMilestoneIndex: null,
			readMore: false,
			sharedWith: []
		}
		this.showAddMilestoneForm = this.showAddMilestoneForm.bind(this);
		this.orderByDate = this.orderByDate.bind(this);
	}

	componentDidMount() {
		let project_id = this.props.match.params.project_id;
		this.setState({ project_id });

		fetch(process.env.REACT_APP_MIS_API_URL + '/get-project/' + project_id, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				this.setState({ project: data.data, sharedWith: data.data.sharedWith });
				// console.log("innovationData", data.data, this.state.project.innovationData)
			}.bind(this));
	}

	handleClick(key) {
		if (key === 'financials') {
			this.setState({ view: 'financials' }, function () {
				this.setState({ current: key });
			}.bind(this));
		} else if (key === 'impact') {
			this.setState({ view: 'impact' }, function () {
				this.setState({ current: key });
			}.bind(this));
		} else {
			this.setState({ view: '' }, function () {
				this.setState({ current: key }, function () {
					if (key === 'milestones') {
						$('html, body').animate({
							scrollTop: $('#milestones').offset().top - 50
						}, 'slow');
					}
				});
			}.bind(this));
		}
	}

	showAddMilestoneForm() {
		if (this.state.showAddMilestoneForm) {
			this.setState({ showAddMilestoneForm: !this.state.showAddMilestoneForm });
		} else {
			$('html, body').animate({
				scrollTop: document.body.scrollHeight
			}, 'slow');
			this.setState({ showAddMilestoneForm: !this.state.showAddMilestoneForm });
		}
	}

	changeNewMilestone(name, value, dateString) {
		if (name === 'newMilestoneDate') {
			if (dateString !== undefined) this.setState({ [name]: dateString });
		} else {
			this.setState({ [name]: value.target.value });
		}
	}

	saveMilestone() {
		if (this.state.editMilestoneIndex === null) {
			if (this.state.newMilestoneTitle !== '' && this.state.newMilestoneDate !== '' && this.state.newMilestoneDesc !== '') {
				let milestones = this.state.milestones;
				let milestone = {};
				milestone.title = this.state.newMilestoneTitle;
				milestone.date = this.state.newMilestoneDate;
				milestone.description = this.state.newMilestoneDesc;
				if (this.state.newMilestoneUrl !== '') milestone.url = this.state.newMilestoneUrl;

				let ngoId = JSON.parse(readCookie('mis_data')).data.organisationData.id;
				let project_id = this.props.match.params.project_id;
				fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/' + ngoId + '/mis/investment/' + project_id + '/milestone', {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					},
					body: JSON.stringify(milestone)
				}).then(function (response) {
					return response.json();
				}).then(function (response) {
					if (response.status === 'ok') {
						milestone.entererName = JSON.parse(readCookie('mis_data')).data.fullName;
						milestone.isDeletable = true;
						milestone.id = response.milestoneId;
						milestones.push(milestone);
						var orderedMilestones = this.orderByDate(milestones, 'date');
						this.setState({ milestones: orderedMilestones }, function () {
							this.cancelMilestone();
							this.notifySuccess('Successfully added your Milestone.');
						}.bind(this));
					}
				}.bind(this));
			}
		} else {
			let milestones = this.state.milestones;

			let milestone = {};
			milestone.title = this.state.newMilestoneTitle;
			milestone.date = this.state.newMilestoneDate;
			milestone.description = this.state.newMilestoneDesc;
			if (this.state.newMilestoneUrl !== '') milestone.url = this.state.newMilestoneUrl;

			let selectedMilestoneIndex = this.state.editMilestoneIndex;

			let ngoId = JSON.parse(readCookie('mis_data')).data.organisationData.id;
			let project_id = this.props.match.params.project_id;
			fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/' + ngoId + '/mis/investment/' + project_id + '/milestone/' + milestones[selectedMilestoneIndex].id, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					'Auth': JSON.parse(readCookie('mis_data')).access_token
				},
				body: JSON.stringify(milestone)
			}).then(function (response) {
				return response.json();
			}).then(function (response) {
				if (response.status === 'ok') {
					milestones[selectedMilestoneIndex].title = this.state.newMilestoneTitle;
					milestones[selectedMilestoneIndex].date = this.state.newMilestoneDate;
					milestones[selectedMilestoneIndex].description = this.state.newMilestoneDesc;
					if (this.state.newMilestoneUrl !== '') milestones[selectedMilestoneIndex].url = this.state.newMilestoneUrl;

					var orderedMilestones = this.orderByDate(milestones, 'date');
					this.setState({ milestones: orderedMilestones }, function () {
						this.cancelMilestone();
						this.notifySuccess('Successfully added your Milestone.');
					}.bind(this));
				}
			}.bind(this));
		}
	}

	orderByDate(arr, dateProp) {
		return arr.slice().sort(function (a, b) {
			return this.process(a[dateProp]) < this.process(b[dateProp]) ? -1 : 1;
		}.bind(this));
	}

	process(date) {
		var parts = date.split("/");
		return new Date(parts[2], parts[1] - 1, parts[0]);
	}

	cancelMilestone() {
		this.setState({
			editMilestoneIndex: null,
			showAddMilestoneForm: false,
			newMilestoneTitle: '',
			newMilestoneDate: '',
			newMilestoneUrl: '',
			newMilestoneDesc: ''
		});
	}

	hoverMilestone(index) {
		let milestones = this.state.milestones;
		milestones[index]['hovered'] = true;
		this.setState({ milestones });
	}

	hoveroutMilestone(index) {
		let milestones = this.state.milestones;
		milestones[index]['hovered'] = false;
		this.setState({ milestones });
	}

	deleteMilestone(index) {
		swal({
			title: 'Are you sure about deleting this?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, Delete!',
			cancelButtonText: 'No, Cancel!',
			confirmButtonClass: 'btn',
			cancelButtonClass: 'btn',
		}).then(function (result) {
			if (result) {
				let milestones = this.state.milestones;

				let ngoId = JSON.parse(readCookie('mis_data')).data.organisationData.id;
				let project_id = this.props.match.params.project_id;
				fetch(process.env.REACT_APP_MIS_API_URL + '/ngo/' + ngoId + '/mis/investment/' + project_id + '/milestone/' + milestones[index].id, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						'Auth': JSON.parse(readCookie('mis_data')).access_token
					},
					body: JSON.stringify({ isDeleted: true })
				}).then(function (response) {
					return response.json();
				}).then(function (response) {
					if (response.status === 'ok') {
						milestones.splice(index, 1);
						this.setState({ milestones }, function () {
							this.notifySuccess('Successfully deleted your Milestone.');
						}.bind(this));
					}
				}.bind(this));
			}
		}.bind(this));
	}

	editMilestone(index) {
		let milestone = this.state.milestones[index];
		$('html, body').animate({
			scrollTop: document.body.scrollHeight
		}, 'slow');
		this.setState({ showAddMilestoneForm: false }, function () {
			if (milestone.hasOwnProperty('url')) this.setState({ showAddMilestoneForm: true, editMilestoneIndex: index, newMilestoneTitle: milestone.title, newMilestoneDate: milestone.date, newMilestoneDesc: milestone.description, newMilestoneUrl: milestone.url });
			else this.setState({ showAddMilestoneForm: true, editMilestoneIndex: index, newMilestoneTitle: milestone.title, newMilestoneDate: milestone.date, newMilestoneDesc: milestone.description, newMilestoneUrl: '' });
		}.bind(this));
	}

	notifySuccess = (text) => toast.success(text)

	markComplete(event) {
		let projectStatus = this.state.project_status;
		this.setState({
			project_status: !projectStatus
		});
		//api call to mark status
	}

	// sharedWith() {
	// 	console.log(`${thi.state.sharedWith.length - 1} others`)
	// }

	render() {
		if (this.state.project_id !== null && this.state.project !== null) {
			return (
				<div className="im-container im-project-page im-project-overview-page">
					<IMSingleProjectSidebar selected='overview' project_id={this.props.match.params.project_id} />
					<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
					<div className="im-project-container im-live-project-details">
						<Col md={12} className="single-investment-container">
							<Col md={12} id="overview" className="section">
								<Col md={12}>
									<Row className="back-container">
										<button type="button" className="btn btn-default btn-danger pull-left" onClick={() => { window.location.pathname = "/im/projects" }}>
											GO TO ALL PROJECTS
										</button>
										{/* <span onClick={() => {window.location.pathname = "/im/projects"}} className="back">
											<i className="fa fa-chevron-left"></i>
											{' GO TO ALL PROJECTS'}
										</span> */}
									</Row>
									<Row className="project-title">
										<h1>{this.state.project.title}</h1>
									</Row>
								</Col>
								{this.state.project.hasOwnProperty('coverImage') ? (
									<Col md={6} className="image" style={{
										backgroundImage: "url('" + this.state.project.coverImage + "')",

									}}>
										<div className="project-cause-icon" title={this.state.project.goalData.name}
											style={{ backgroundImage: 'url("https://assets.letsendorse.com/' + this.state.project.goalData.iconUrl + '")' }}></div>
										<span className="project-cause-name"
											style={{
												position: 'absolute',
												bottom: '5px',
												marginLeft: '35px',
												height: '60px',
												lineHeight: '60px',
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'center',
												color: '#fff',
												fontSize: '18px',
												background: 'rgba(0, 0, 0, 0.5)',
												paddingLeft: '35px',
												paddingRight: '8px',
												borderBottomRightRadius: '35px',
												borderTopRightRadius: '35px'
											}}
										>{this.state.project.goalData.name}</span>
									</Col>
								) : (
										<Col md={6} className="image" style={{ backgroundImage: "url('/images/project-investment-placeholder-new.png')" }}></Col>
									)}
								<Col mdOffset={1} md={5}>

									{/* <Col md={12} className="features">
										<button type="button" className="btn btn-info"
											onChange={this.markComplete.bind(this)}
										>Mark Complete</button>
									</Col> */}
									{!checkValue(this.state.project.thematicCategory) ? (
										<Col md={12} className="features" hidden={checkValue(this.state.project.thematicCategory.name)}>
											<div className="heading" hidden={checkValue(this.state.project.thematicCategory.name)}>Thematic Category</div>
											<div className="content" hidden={checkValue(this.state.project.thematicCategory.name)}>{this.state.project.thematicCategory.name}</div>
										</Col>
									) : (null)}
									{!checkValue(this.state.project.expectedDurationObject) ? (
										<Col md={12} className="features">
											<div className="heading">Expected Duration</div>
											<div className="content">{this.state.project.expectedDurationObject.duration + ' ' + this.state.project.expectedDurationObject.type + '(s)'}</div>
										</Col>
									) : (null)}


									{!checkValue(this.state.project.targetBeneficiaries) ? (
										<Col md={12} className="features">
											<div className="heading">Beneficiaries</div>
											<div className="content">
												{this.state.project.targetBeneficiaries.map(function (targetBeneficiary, i) {
													if (this.state.project.targetBeneficiaries.length - i === 1) return this.state.project.targetBeneficiaries[i].name
													else return this.state.project.targetBeneficiaries[i].name + ', '
												}.bind(this))}
											</div>
										</Col>
									) : (null)}

									{!checkValue(this.state.project.agenda) ? (
										<Col md={12} className="features">
											<div className="heading">Agenda</div>
											<div className="content">
												{this.state.project.agenda.name}
											</div>
										</Col>
									) : (null)}

									{!checkValue(this.state.project.locationType) ? (
										<Col md={12} className="features" hidden={checkValue(this.state.project.locationType.name)}>
											<div className="heading" hidden={checkValue(this.state.project.locationType.name)}>Location Type</div>
											<div className="content" hidden={checkValue(this.state.project.locationType.name)}>{this.state.project.locationType.name}</div>
										</Col>
									) : (null)}

									{!checkValue(this.state.project.location) ? (
										<Col md={12} className="features">
											<div className="heading">Location</div>
											<div className="content">
												{this.state.project.location.map(function (location, index) {
													return (
														<ul style={{ listStyleType: 'disc !important' }}>
															<li>{location.address}</li>
														</ul>
													)
												})}
											</div>
										</Col>
									) : (null)}

									{this.state.sharedWith.length ? (
										<Col md={12} className="features">
											<div className="heading">Submitted</div>
											<div className="content">
												<p>
													{this.state.sharedWith[0].name}
													{this.state.sharedWith.length > 1 ? (
														<a style={{ fontSize: "12px !important" }} href="/im/partnerships/projects-shared">+{this.state.sharedWith.length - 1} others</a>
													) : (null)}
												</p>
											</div>
										</Col>
									) : (null)}

								</Col>
							</Col>
							<Col md={12} id="description" className="section">


								<Col md={8}>
									<h3 className="project-desc mb10 mt20" hidden={checkValue(this.state.project.description)}>Background and Rationale of the Project</h3>
									<div hidden={checkValue(this.state.project.description)} style={{ margin: '0px 20px 0px 20px' }}>{this.state.project.description}</div>

									<h3 className="project-desc mb10 mt20" hidden={checkValue(this.state.project.sustainabilityPlan)}>Sustainability Plan and funding source</h3>
									<div hidden={checkValue(this.state.project.sustainabilityPlan)} style={{ margin: '0px 20px 0px 20px' }}>{this.state.project.sustainabilityPlan}</div>
									<h3 className="project-desc mb10 mt20" hidden={checkValue(this.state.project.expectedImpact)}>Expected Impact</h3>
									<div hidden={checkValue(this.state.project.expectedImpact)} style={{ margin: '0px 20px 0px 20px' }}>{this.state.project.expectedImpact}</div>
									<h3 className="project-desc mb10 mt20" hidden={checkValue(this.state.project.need)}>Overall Objective of the Project</h3>
									<div hidden={checkValue(this.state.project.need)} style={{ margin: '0px 20px 0px 20px' }}>{this.state.project.need}</div>
									<h3 className="project-desc mb10 mt20" hidden={checkValue(this.state.project.impactMonitoring)}>Impact Monitoring Systems</h3>
									<div hidden={checkValue(this.state.project.need)} style={{ margin: '0px 20px 0px 20px' }}>{this.state.project.impactMonitoring}</div>
									<h3 className="project-desc mb10 mt20" hidden={checkValue(this.state.project.pastProjectHistory)}>History of Execution</h3>
									<div hidden={checkValue(this.state.project.need)} style={{ margin: '0px 20px 0px 20px' }}>{this.state.project.pastProjectHistory}</div>
									<h3 className="project-desc mb10 mt20" hidden={checkValue(this.state.project.projectPartner)}>Project Partners</h3>
									<div hidden={checkValue(this.state.project.projectPartner)}>
										<ul style={{ listStyleType: 'disc' }}>
											{
												_.map(this.state.project.projectPartner, (proj, index) => (

													<li key={"proj" + index} style={{ margin: '0px 20px 0px 20px' }}><i className="fa fa-circle" style={{ paddingRight: '10px', fontSize: '10px' }}></i>{proj}</li>

													// (index===this.state.project.projectPartner.length-1)
													// 	?
													// <span key={index+"k"}>{proj}</span>
													// 	:
													// <span key={index+"k"}>{proj} {","}{" "}</span>
												))
											}
										</ul>
									</div>
									{/* <h3 className="project-desc mb10 mt20" hidden={checkValue(this.state.project.goalData)}>Goal</h3>
								<div hidden={checkValue(this.state.project.goalData)}>{this.state.project.goalData.name}</div> */}
									{/* <h3 className="project-desc mb10 mt20" hidden={checkValue(this.state.project.agenda)}>Agenda</h3>
								<div hidden={checkValue(this.state.project.agenda)} style={{margin: '0px 20px 0px 20px'}}>{this.state.project.agenda.name}</div>
								 */}
									{/* <h3 className="project-desc mb10 mt20" hidden={checkValue(this.state.project.sustainabilityPlan)}>Unit Economics</h3>
								<div hidden={checkValue(this.state.project.unitEconomics)}>{this.state.project.sustainabilityPlan}</div>
								 */}
									<h3 className="project-desc mb10 mt20" hidden={checkValue(this.state.project.eeOpportunities)}>Employee Management Opportunities</h3>
									<div hidden={checkValue(this.state.project.eeOpportunities)} style={{ margin: '0px 20px 0px 20px' }}>
										<ul style={{ listStyleType: 'disc' }}>
											{
												_.map(this.state.project.eeOpportunities, (opp, index) => (
													<li key={index + "opp"}><i className="fa fa-circle" style={{ paddingRight: '10px', fontSize: '10px' }}></i>{opp}</li>))

											}
										</ul>
									</div>
									<h3 className="project-desc mb10 mt20" hidden={checkValue(this.state.project.milestones.length > 0)}>Key activities/milestones planned to achieve through this project</h3>
									<div hidden={checkValue(this.state.project.milestones)} style={{ margin: '0px 20px 0px 20px' }}>
										<ul style={{ listStyleType: 'disc' }}>
											{_.map(this.state.project.milestones, (milestone, index) => (
												<li key={index + "mile"}><i className="fa fa-circle" style={{ paddingRight: '10px', fontSize: '10px' }}></i>{milestone}</li>
											))}
										</ul>
									</div>
								</Col>

								<Col md={4}>
									{this.state.project.hasOwnProperty('innovationData') ? (
										<Col mdOffset={1} md={12}>
											<div className="innovation-card" hidden={checkValue(this.state.project.innovationData.url)}>
												<div className="is-inspired-by">THIS PROJECT IS INSPIRED BY</div>
												<a href={this.state.project.innovationData.url} target="_blank" className="innovation-card-image-link">
													<div className="innovation-card-cause-icon" style={{ backgroundColor: this.state.project.innovationData.goalBackgroundColor, backgroundImage: "url('" + this.state.project.innovationData.goalIcon + "')" }}></div>
													<img className="innovation-card-image" src={this.state.project.innovationData.image} alt={this.state.project.innovationData.title} />
												</a>
												<a href={this.state.project.innovationData.url} target="_blank"><div className="innovation-card-title">{this.state.project.innovationData.title}</div></a>
												<div className="innovation-card-description">
													<span className={this.state.readMore ? ('desc') : ('desc ellipsis')}>
														{this.state.project.innovationData.description}
													</span>
													<div className="read-more" onClick={() => { this.setState({ readMore: !this.state.readMore }) }}>
														{this.state.readMore ? ('Read Less') : ('Read More')}
													</div>
												</div>
											</div>
										</Col>
									) : (null)}
								</Col>
							</Col>
						</Col>
					</div>
				</div>
			)
		} else return null;
	}
}
