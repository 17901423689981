import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";
import {arrayMove} from 'react-sortable-hoc';
import $ from 'jquery';

const readCookie = require('../cookie.js').readCookie;

window.jQuery = $.noConflict();

export default class ImportDonations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importedDonationData: null,
      detected_headers: null,
      system_headers: null
    };
    this.uploadDonorFile = this.uploadDonorFile.bind(this);
  }

  showDonorsFileUploadButton(e) {
    if(e.target.files.length) {
      $('#uploadDonorsFile').removeClass('hidden');
      $('#cancelUploadDonorsFile').removeClass('hidden');
    } else {
      $('#uploadDonorsFile').addClass('hidden');
      $('#cancelUploadDonorsFile').addClass('hidden');
    }
  }

  uploadDonorFile() {
    var donorFile = $('input#importDonorsFile');

    var data = new FormData();
    data.append('data_sheet', donorFile[0].files[0]);

    // var list_id = window.location.href.split('/dm/lists/')[1];
    fetch(process.env.REACT_APP_API_URL + '/get_sheet_meta/donation/' + this.props.ngo.ngo_id, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: data
    }).then((response) => response.json())
    .then(function(data) {
      console.log(data)
      let dataObj = data.data;
      if(dataObj.hasOwnProperty('system_headers') && dataObj.system_headers.length){
        this.props.updateSystemHeaders(dataObj['system_headers']);
      }
      this.props.updateDataId(data['data']['data_id']);
      this.setState({
        importedDonationData: data['data'],
        detected_headers: data['data']['detected_headers'],
        system_headers: data['data']['system_headers']
      }, function() {
        let detected_headers = this.state.detected_headers;
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/css/jquery-ui.min.css';
        document.head.appendChild(link);

        $(document).ready(function() {
          $.getScript('/js/jquery-ui.min.js', function() {
            $("ul.system-header").sortable({
              placeholder: 'placeholder',
              connectWith: "ul.system-header",
              receive: function(event, ui) {
                var $this = $(this);
                if ($this.children('li').length > 1 && $this.hasClass('max-header-one')) {
                  $(ui.sender).sortable('cancel');
                }
              }
            });
            $("ul.system-header").disableSelection();

            for(var i = 0; i < detected_headers.length; i++) {
              let detected_header = detected_headers[i];
              if(detected_header.toLowerCase().match('amount') !== null) {
                if($('#system-headers-amount ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-amount ul.system-header');
              } else if(detected_header.toLowerCase().match('date') !== null) {
                if($('#system-headers-date ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-date ul.system-header');
              } else if(detected_header.toLowerCase().match('mode') !== null) {
                if($('#system-headers-contribution_mode ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-contribution_mode ul.system-header');
              } else if(((detected_header.toLowerCase().match('transaction') !== null) && ((detected_header.toLowerCase().match('number') !== null) ||
                (detected_header.toLowerCase().match('id') !== null) || (detected_header.toLowerCase().match('reference') !== null))) || 
                ((detected_header.toLowerCase().match('reference') !== null) && ((detected_header.toLowerCase().match('number') !== null) || 
                (detected_header.toLowerCase().match('id') !== null)))) {
                if($('#system-headers-transaction_reference_number ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-transaction_reference_number ul.system-header');
              } else if(detected_header.toLowerCase().match('currency') !== null) {
                if($('#system-headers-currency ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-currency ul.system-header');
              } else if(detected_header.toLowerCase().match('receipt') && detected_header.toLowerCase().match('no') !== null) {
                if($('#system-headers-receipt_no ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-receipt_no ul.system-header');
              } else if(detected_header.toLowerCase().match('receipt') !== null && detected_header.toLowerCase().match('url') !== null) {
                if($('#system-headers-receipt_url ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-receipt_url ul.system-header');
              } else if(detected_header.toLowerCase().match('tax') !== null && detected_header.toLowerCase().match('exemption') !== null) {
                if($('#system-headers-tax_exemption ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-tax_exemption ul.system-header');
              } else if(detected_header.toLowerCase().match('exemption') !== null && detected_header.toLowerCase().match('receipt') !== null) {
                if($('#system-headers-exemption_receipt_required ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-exemption_receipt_required ul.system-header');
              } else if(detected_header.toLowerCase().match('email') !== null) {
                if($('#system-headers-email ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-email ul.system-header');
              }
            }
          });
        });
      }.bind(this));
      $('#uploadDonorsFile').addClass('hidden');
      $('#cancelUploadDonorsFile').addClass('hidden');
      $('input#importDonorsFile').val('');
      this.props.showSubmitButton();
    }.bind(this));
  }

  cancelUploadDonorFile() {
    $('#uploadDonorsFile').addClass('hidden');
    $('#cancelUploadDonorsFile').addClass('hidden');
    $('input#importDonorsFile').val('');
  }

  onSortEnd({oldIndex, newIndex}) {
    this.setState({ detected_headers: arrayMove(this.state.detected_headers, oldIndex, newIndex) });
  }

  render() {
    return (
      <div>
        <Row>
          {(() => {
            if (this.state.importedDonationData === null) {
              return (
                <Row>
                  <Col md={12} className="mt10 mb10 text-center">
                    <input id="importDonorsFile" className="mt10 mb10" type="file" onChange={this.showDonorsFileUploadButton} accept=".csv,.xlsx" />
                  </Col>
                  <Col md={12} className="mt10 mb10 text-center">
                    <note>
                      Note: Maximum file size 15MB, only .csv & .xlsx files supported.
                    </note>
                  </Col>
                  <Col md={12} className="mt10">
                    <Button id="uploadDonorsFile" className="btn-success react-bs-table-add-btn col-md-offset-2 col-md-3 hidden" onClick={this.uploadDonorFile}>Upload</Button>
                    <Button id="cancelUploadDonorsFile" className="btn-danger react-bs-table-add-btn col-md-offset-2 col-md-3 hidden" onClick={this.cancelUploadDonorFile}>Cancel Upload</Button>
                  </Col>
                </Row>
              );
            } else if(this.state.importedDonationData !== null) {
              return (
                <Col md={12} className="select-donor-data-headers">
                  <Col md={12}><p className="modal-sec-title">CSV Headers</p></Col>
                  <div id="root">
                    <ul className="SortableList system-header" onSortEnd={this.onSortEnd.bind(this)} helperClass="SortableHelper">
                      {this.state.detected_headers.map(function (data, index) {
                        return (
                          <li id={'csv-headers-' + index} className="SortableItem" key={index} data-index={index}>
                            {data}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <Col md={12}>
                    <p className="modal-sec-title">Our Headers</p>
                    <p>Note: Drag & Drop the csv headers into our headers to map them.</p>
                  </Col>
                  {this.state.system_headers.map(function(system_header, index) {
                    // var system_header = key;
                    // var value = Object.keys(system_header);
                    return (
                      <div id={'system-headers-' + system_header.id} className="system-headers-div" key={system_header.id}>
                        <div className="system-headers-div-info">
                          <span style={{ lineHeight: '40px', fontWeight: 600}}>{system_header.label}<i className="fa fa-arrows-h" style={{marginLeft: '10px'}}></i></span>
                          <div style={{fontSize: '12px'}}>{system_header.help_text}</div> 
                        </div>
                        <ul className="system-header max-header-one"></ul>
                      </div>
                    );
                  })}
                  <Col md={12} className="checkbox-section mt20">
                    {this.props.certificate !== null && this.props.certificate && this.props.certificate !== '' ? (
                      <div className="form-group mb5">
                      <label style={{fontSize: '14px'}}>
                        <input type="checkbox" value="send_automated_receipts" onChange={this.props.checkMailsAndReceipts.bind(this, 'receipts')} 
                          checked={this.props.additionalDetails.indexOf('send_automated_receipts') > -1 ? true : false}  /> Do you want to issue 80g tax exemption receipts for all the uploaded donations? Your 80g tax exemption number is {this.props.certificate}
                      </label>
                      </div>
                    ) : (
                      <div className="form-group mb5">
                        <label style={{fontSize: '14px'}}>
                          <input type="checkbox" value="send_automated_receipts" onChange={this.props.checkMailsAndReceipts.bind(this, 'receipts')} 
                            checked={this.props.additionalDetails.indexOf('send_automated_receipts') > -1 ? true : false}  />
                          Do you want to issue donation receipts for all donations you have uploaded? Please note that since you do not have 80g certificate, your donors shall get no tax exemption against these receipts.
                        </label>
                      </div>
                    )}
                    {this.props.additionalDetails.indexOf('send_automated_receipts') > -1 ? (
                      <div className="form-group">
                        <label style={{fontSize: '14px'}}>
                          <input type="checkbox" value="send_emails" onChange={this.props.checkMailsAndReceipts.bind(this, 'emails')} 
                            checked={this.props.additionalDetails.indexOf('send_emails') > -1 ? true : false} />
                          Do you need to send "Thank you" emails to all donors with receipts?
                        </label>
                      </div>
                    ) : (null)}
                  </Col>
                </Col>
              );
            }
          })()}
        </Row>
      </div>
    );
  }
}

