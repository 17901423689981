import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";

import ImportantDateSingle from './ImportantDateSingle';

export default class ImportantDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      important_dates: this.props.donor.important_dates,
      impDateCount: this.props.donor.impDateCount,
      important_dates_elements: [],
      maxDate: ''
    }
  }

  onAddChild() {
    var important_dates_elements = this.state.important_dates_elements;

    var newImpDateCount = this.state.impDateCount + 1;
    important_dates_elements.push(<ImportantDateSingle number={newImpDateCount} key={newImpDateCount} removeChild={this.onRemoveChild.bind(this)} date="" details="" />)
    this.setState({
      impDateCount: this.state.impDateCount + 1,
      important_dates_elements: important_dates_elements
    }, function() {
      this.props.addChild();
    });
  }

  onRemoveChild(key) {
    key -= 1;
    var new_important_dates_elements = this.state.important_dates_elements;
    new_important_dates_elements.splice(key, 1);
    this.setState({
      important_dates_elements: new_important_dates_elements
    }, function() {
      this.props.removeChild();
    });
  }

  removeChild(key) {
    key -= 1;
    var new_important_dates = this.state.important_dates;
    new_important_dates.splice(key, 1);
    this.setState({ important_dates: new_important_dates });
  }

  componentDidMount() {
    var prevYear = new Date();
    var dd = prevYear.getDate();
    var mm = prevYear.getMonth()+1;
    var yyyy = prevYear.getFullYear();
    if(dd < 10) dd = '0' + dd; 
    if(mm < 10) mm = '0' + mm;
    yyyy -= 1;
    prevYear = yyyy + '-' + mm + '-' + dd;
    
    this.setState({ maxDate: prevYear });
  }

  render() {
    return (
      <Row>
        <Col className="margin-col-10" md={12}>
          <Row>
            <Col md={12}>
              <p className="modal-sec-title member-add-edit-modal-subtitle">Important Dates</p>
            </Col>
            {this.props.ngo.hasOwnProperty('account_type') ? (
              this.props.ngo.account_type === 'ngo' ? (
                <div>
                  {this.props.list.default_member_fields_others.map(function(field, index) {
                    if(field.id === 'dob_father') {
                      return (
                        <Col className="form-group" md={4} key={index}>
                          <label className="control-label">DOB of Father:</label>
                          <input type="date" className="form-control" id="dob_father" name="dob_father" max={this.state.maxDate}
                            placeholder="04/05/1994" required value={this.props.donor.dob_father} onChange={this.props.onDonorDobFatherChange}/>
                          <span><span className="red-color">Input is too long!</span></span>
                        </Col>
                      )
                    } else if(field.id === 'dob_mother') {
                      return (
                        <Col className="form-group" md={4} key={index}>
                          <label className="control-label">DOB of Mother:</label>
                          <input type="date" className="form-control" id="dob_mother" name="dob_mother" max={this.state.maxDate}
                            placeholder="04/05/1994" required value={this.props.donor.dob_mother} onChange={this.props.onDonorDobMotherChange}/>
                          <span><span className="red-color">Input is too long!</span></span>
                        </Col>
                      )
                    } else if(field.id === 'dob_spouse') {
                      return (
                        <Col className="form-group" md={4} key={index}>
                          <label className="control-label">DOB of Spouse:</label>
                          <input type="date" className="form-control" id="dob_spouse" name="dob_spouse" max={this.state.maxDate}
                            placeholder="04/05/1994" required value={this.props.donor.dob_spouse} onChange={this.props.onDonorDobSpouseChange}/>
                          <span><span className="red-color">Input is too long!</span></span>
                        </Col>
                      )
                    } else if(field.id === 'dob_first_child') {
                      return (
                        <Col className="form-group" md={4} key={index}>
                          <label className="control-label">DOB of 1st Child:</label>
                          <input type="date" className="form-control" id="dob_first_child" name="dob_first_child" max={this.state.maxDate}
                            placeholder="04/05/1994" required value={this.props.donor.dob_first_child} onChange={this.props.onDonorDobFirstChildChange}/>
                          <span><span className="red-color">Input is too long!</span></span>
                        </Col>
                      )
                    } else if(field.id === 'dob_second_child') {
                      return (
                        <Col className="form-group" md={4} key={index}>
                          <label className="control-label">DOB of 2nd Child:</label>
                          <input type="date" className="form-control" id="dob_second_child" name="dob_second_child" max={this.state.maxDate}
                            placeholder="04/05/1994" required value={this.props.donor.dob_second_child} onChange={this.props.onDonorDobSecondChildChange}/>
                          <span><span className="red-color">Input is too long!</span></span>
                        </Col>
                      )
                    } else if(field.id === 'marriage_anniversary') {
                      return (
                        <Col className="form-group" md={4} key={index}>
                          <label className="control-label">Anniversary:</label>
                          <input type="date" className="form-control" id="marriage_anniversary" name="marriage_anniversary" max={this.state.maxDate}
                            placeholder="04/05/1994" required value={this.props.donor.marriage_anniversary} onChange={this.props.onDonorAnniversaryChange}/>
                          <span><span className="red-color">Input is too long!</span></span>
                        </Col>
                      )
                    }
                  }.bind(this))}
                </div>
              ) : (null)
            ) : (
              <div>
                {this.props.list.default_member_fields_others.map(function(field, index) {
                  if(field.id === 'dob_father') {
                    return (
                      <Col className="form-group" md={4} key={index}>
                        <label className="control-label">DOB of Father:</label>
                        <input type="date" className="form-control" id="dob_father" name="dob_father" max={this.state.maxDate}
                          placeholder="04/05/1994" required value={this.props.donor.dob_father} onChange={this.props.onDonorDobFatherChange}/>
                        <span><span className="red-color">Input is too long!</span></span>
                      </Col>
                    )
                  } else if(field.id === 'dob_mother') {
                    return (
                      <Col className="form-group" md={4} key={index}>
                        <label className="control-label">DOB of Mother:</label>
                        <input type="date" className="form-control" id="dob_mother" name="dob_mother" max={this.state.maxDate}
                          placeholder="04/05/1994" required value={this.props.donor.dob_mother} onChange={this.props.onDonorDobMotherChange}/>
                        <span><span className="red-color">Input is too long!</span></span>
                      </Col>
                    )
                  } else if(field.id === 'dob_spouse') {
                    return (
                      <Col className="form-group" md={4} key={index}>
                        <label className="control-label">DOB of Spouse:</label>
                        <input type="date" className="form-control" id="dob_spouse" name="dob_spouse" max={this.state.maxDate}
                          placeholder="04/05/1994" required value={this.props.donor.dob_spouse} onChange={this.props.onDonorDobSpouseChange}/>
                        <span><span className="red-color">Input is too long!</span></span>
                      </Col>
                    )
                  } else if(field.id === 'dob_first_child') {
                    return (
                      <Col className="form-group" md={4} key={index}>
                        <label className="control-label">DOB of 1st Child:</label>
                        <input type="date" className="form-control" id="dob_first_child" name="dob_first_child" max={this.state.maxDate}
                          placeholder="04/05/1994" required value={this.props.donor.dob_first_child} onChange={this.props.onDonorDobFirstChildChange}/>
                        <span><span className="red-color">Input is too long!</span></span>
                      </Col>
                    )
                  } else if(field.id === 'dob_second_child') {
                    return (
                      <Col className="form-group" md={4} key={index}>
                        <label className="control-label">DOB of 2nd Child:</label>
                        <input type="date" className="form-control" id="dob_second_child" name="dob_second_child" max={this.state.maxDate}
                          placeholder="04/05/1994" required value={this.props.donor.dob_second_child} onChange={this.props.onDonorDobSecondChildChange}/>
                        <span><span className="red-color">Input is too long!</span></span>
                      </Col>
                    )
                  } else if(field.id === 'marriage_anniversary') {
                    return (
                      <Col className="form-group" md={4} key={index}>
                        <label className="control-label">Anniversary:</label>
                        <input type="date" className="form-control" id="marriage_anniversary" name="marriage_anniversary" max={this.state.maxDate}
                          placeholder="04/05/1994" required value={this.props.donor.marriage_anniversary} onChange={this.props.onDonorAnniversaryChange}/>
                        <span><span className="red-color">Input is too long!</span></span>
                      </Col>
                    )
                  }
                }.bind(this))}
              </div>
            )}
            {this.state.important_dates.map((important_date, i) => {
              return <ImportantDateSingle number={i} key={i} removeChild={this.removeChild.bind(this)} date={important_date.date} details={important_date.details} />
            })}
            {this.state.important_dates_elements}
            <Col className="form-group mt20" md={12}>
              <Button onClick={this.onAddChild.bind(this)}>Add Another Date</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
