import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import { LocaleProvider, DatePicker } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import swal from "sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../../ImpactManagement/cookie.js').readCookie;

moment.locale('en');

export default class EMAdminExpense extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bill: null,
			status: "",
			submitted_by: "",
			submitters: [],
			reports: [],
			category: '',
			filters: {},
			reportInfo: [],
			approvedBy: "",
			timestampTo: "",
			pending_list: 0,
			approved_list: 0,
			timestampFrom: "",
			checkedData: true,
			rejected_list: [],
			pending_amount: 0,
			approved_amount: 0,
			expenseCategory: ["Meal", "Travel", "Entertainment", "Mobile", "Internet", "Sundry", "Others"],
			payments: ["Wallet", "UPI", "Cash", "Debit Card", "Credit Card", "Netbanking"],
			sizePerPage: 20,
			currentPage: 1,
			total_reports: 0,
			sortName: 'submitted_date',
			sortOrder: 'desc'
		}
		this.getAllExpenseReport = this.getAllExpenseReport.bind(this);
	}

	componentDidMount() {
		let query = '?limit=20&skip=0', filters = {};
		filters['sort_by'] = this.state.sortName;
		filters['sort_type'] = this.state.sortOrder;

		this.getAllExpenseReport(filters, query);
	}

	getAllExpenseReport(filters, query){
		fetch(process.env.REACT_APP_API_URL + '/EM/fetch_all_expense_report/' + this.props.ngo.ngo_id + query, {
			method: 'POST',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(filters)
		}).then(res => (res.json()))
		.then(res => {
			this.setState({
				reports: res.data,
				submitters: res.submitters,
				pending_list: res.report_stats.pending_report,
				approved_list: res.report_stats.approved_report,
				pending_amount: res.report_stats.pending_value,
				approved_amount: res.report_stats.approved_value,
				total_reports: res.total_reports
			});
		}).catch(err => {
			console.log("err", err);
		});
	}

	expenseLineBox(event) {
		return (
			<input type="text" ref={(e) => this.expenseLine = e} className="form-control" />
		)
	}

	selectCategory(event) {
		this.setState({ category: event.target.value })
	}

	expenseCategoryBox(event) {
		return (
			<select className="form-control" value={this.state.category} onChange={this.selectCategory.bind(this)}>
				<option value='' disabled>Select Category</option>
				{_.map(this.state.expenseCategory, (cat, index) => (
					<option value={cat} key={cat + index}>
						{cat}
					</option>
				))}
			</select>
		)
	}

	expenseDestributionBox(event) {
		return (
			<input type="text" ref={(e) => this.expenseDestribution = e} className="form-control" />
		)
	}

	billDateBox(event) {
		return (
			<input type="date" ref={(e) => this.billDate = e} className="form-control" />
		)
	}

	uploadbillBox(event) {
		return (
			<input type="file" onChange={(e) => this.setState({ bill: e.target.files })} />
		)
	}

	merchantNameBox(event) {
		return (
			<input type="text" ref={(e) => this.merchantName = e} className="form-control" />
		)
	}

	billValueBox(event) {
		return (
			<input type="text" ref={(e) => this.billValue = e} className="form-control" />
		)
	}

	reimbursableValueBox(event) {
		return (
			<input type="text" ref={(e) => this.reimbursableValue = e} className="form-control" />
		)
	}

	statusExpenseBox(event) {
		return (
			<input type="text" ref={(e) => this.statusExpense = e} className="form-control" />
		)
	}

	saveExpenseBox(event) {
		return (
			<button type="button" className="btn btn-primary">save</button>
		)
	}

	actionCheckbox(event) {
		return (
			<input type="checkbox" checked={(this.state.checkedData === true ? true : false)} onChange={this.checkRow.bind(this)} />
		)
	}

	actionExpenseBox(event) {
		return (
			<div>
				<button type="button" className="btn btn-info"
					style={{
						margin: '2px',
						padding: '5px 0px 5px 5px'
					}}
					onClick={this.editData.bind(this)}>
					<i className="fa fa-pencil-square-o" style={{ fontSize: 'larger !important' }}></i>
				</button>
				<button type="button" className="btn btn-danger"
					style={{
						margin: '2px',
						padding: '5px 5px 5px 5px'
					}}
					onClick={this.deleteData.bind(this)}>
					<i className="fa fa-remove" style={{ fontSize: 'larger !important' }}></i>
				</button>
			</div>
		)
	}

	editData(event) {
		console.log("edit", event, this.statusExpense.value)
	}

	deleteData(event) {
		console.log("delete", event)
	}

	checkRow(event) {
		this.state.checkedData === true ? this.setState({ checkedData: false }) : this.setState({ checkedData: true })
	}

	paymentMode(event) {
		return (
			<select className="form-control" value={this.state.category} onChange={this.selectCategory.bind(this)}>
				<option value='' disabled>Select Payment</option>
				{_.map(this.state.payments, (payment, index) => (
					<option value={payment} key={payment + index}>
						{payment}
					</option>
				))}
			</select>
		)
	}

	addExpenseRow(event) {
		let newReport = [];
		let prevReport = this.state.reports;
		this.setState({ reports: prevReport.concat(newReport) })
	}

	submitReport(event) {
		toast.error('Cannot submit the report')
	}

	expenseLine(row) {
		return row.length
	}

	viewReport(row) {
		let data = _.filter(this.state.reports, (el, i) => (el.expense_report_id === row));
		return (
			<Link to={'/em/expense/report-for-approval/' + data[0]._id} className="note">{row}</Link>
		)
	}

	selectFromDate(date) {
		let filters = this.state.filters;
		if(date === null){
			filters = _.omit(filters, 'date_from');
		} else {
			let fromDate = moment(date);
			fromDate.set('hour', 0);
			fromDate.set('minute', 0);
			fromDate.set('second', 0);
			filters['date_from'] = moment(fromDate).unix();
		}
		this.setState({ timestampFrom: date, filters})
	}

	applyFilter() {
		let filters = this.state.filters;
		filters['sort_by'] = this.state.sortName;
		filters['sort_type'] = this.state.sortOrder;
		let query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage;
		if(filters.hasOwnProperty('date_to') && filters.hasOwnProperty('date_from')){
			if(filters['date_to'] > filters['date_from']){
				this.getAllExpenseReport(filters, query);
			} else {
				toast.error('To date cannot be earlier than From date');
			}
		} else {
			this.getAllExpenseReport(filters, query);
		}
	}

	selectToDate(date) {
		let filters = this.state.filters;
		if(date === null){
			filters = _.omit(filters,'date_to');
		} else {
			let toDate = moment(date);
			toDate.set('hour', 23);
			toDate.set('minute', 59);
			toDate.set('second', 59);
			filters['date_to'] = moment(toDate).unix();
		}
		this.setState({ timestampTo: date, filters });
	}

	filterByStatus(e) {
		let filters = this.state.filters;
		if(e.target.value === '') {
			filters = _.omit(filters, 'status')
		}	else {
			filters['status'] = e.target.value;
		}
		this.setState({ status: e.target.value, filters })
	}

	filterBySubmittedBy(e) {
		if (e.target.value === "0" || e.target.value === "") {
			return;
		} else {
			let filters = this.state.filters;
			filters['submitted_by'] = e.target.value;
			this.setState({ submitted_by: e.target.value, filters })
		}
	}

	dateFormatter(row) {
		if (row !== null || row !== undefined || row !== '') {
			return (moment(row).format('DD/MM/YYYY'))
		} else {
			return "NA"
		}
	}

	sortDates(sortBy, a, b, sortType) {
		let indices = [6, 7, 8, 9, 3, 4, 0, 1];
		let aDate = moment(a[sortBy]).format('DD/MM/YYYY');
		let bDate = moment(b[sortBy]).format('DD/MM/YYYY');
		if (sortType === 'asc') {
			let r = 0;
			indices.find(i => r = aDate.charCodeAt(i) - bDate.charCodeAt(i));
			return r;
		} else if (sortType === 'desc') {
			let r = 0;
			indices.find(i => r = bDate.toString().charCodeAt(i) - aDate.toString().charCodeAt(i));
			return r;
		}
	}

	clearfilter() {
		let filters = {};
		filters['sort_by'] = 'submitted_date';
		filters['sort_orderby'] = 'desc';

		let query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage;
		this.getAllExpenseReport(filters, query)
		this.setState({timestampTo: "", timestampFrom: "", status: "", submitted_by: "", filters: {}, sortName: 'submitted_date', sortOrder: 'desc' })
	}

	transformStatus(row){
		if(row==="Submitted"){
			return "Pending";
		} else{
			return row;
		}
	}

	approverName(cell, row){
		if(cell === undefined || cell === null || cell === ''){
			return "NA"
		} else{
			let flag = false,
				approver = cell[0];
			for(let i = 1; i < cell.length; i++) {
				if(cell[i] !== approver) {
					flag = true;
					break;
				}
			}
			if(flag) {
				return "Multiple";
			} else {
				return approver;
			}
		}
	}

	onExportToCSV() {
		let filters = this.state.filters;
		filters['sort_by'] = this.state.sortName;
		filters['sort_type'] = this.state.sortOrder;
		fetch(process.env.REACT_APP_API_URL + '/EM/export-reports/admin', {
			method: 'POST',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(filters)
		}).then(res => (res.json()))
		.then(data => {
			if(data.status === 'ok') {
				swal({
					html: '<img src="/images/report_image.png" style="height: 120px; margin: 20px 0;" />' +
							'<div style="font-size: 26px; letter-spacing: 0.5px; margin: 0 0 20px;">Your expenses are ready to be downloaded!</div>' +
							'<a class="btn" style="background: #3085d6; color: #fff; font-size: 22px !important; letter-spacing: 0.5px; padding: 10px 20px; width: 240px; margin: 0 0 10px;" href="' + data.fileUrl + '" target="_blank">Download</a>',
					showCancelButton: false,
					showConfirmButton: false
				});
			}
		}).catch(err => {
			toast.error('Failed to apply filter. Please try again')
		})
  }

  onPageChange(page, sizePerPage) {
		let filters = this.state.filters;
		filters['sort_by'] = this.state.sortName;
		filters['sort_type'] = this.state.sortOrder;
		let query = '?limit=' + sizePerPage + '&skip=' + (page - 1) * sizePerPage;
		this.getAllExpenseReport(filters, query);
		this.setState({currentPage: page, sizePerPage: sizePerPage});
	}

	onSizePerPageList(sizePerPage) {
		this.setState({currentPage: 1, sizePerPage: sizePerPage});
		let filters = this.state.filters;
		filters['sort_by'] = this.state.sortName;
		filters['sort_type'] = this.state.sortOrder;

		let query = '?limit=' + sizePerPage + '&skip=' + (this.state.currentPage - 1) * sizePerPage;
		this.getAllExpenseReport(filters, query);
	}

	onSortChange(sortName, sortOrder) {
    console.log(sortName, sortOrder);
    this.setState({sortName, sortOrder})

		let filters = this.state.filters,
		query = '?limit=' + this.state.sizePerPage + '&skip=' + (this.state.currentPage - 1) * this.state.sizePerPage;
		filters['sort_by'] = sortName;
		filters['sort_type'] = sortOrder;

		this.getAllExpenseReport(filters, query);
  }

	render() {
		const options = {
			onExportToCSV: this.onExportToCSV.bind(this),
			sizePerPage: this.state.sizePerPage,
			onPageChange: this.onPageChange.bind(this),
			page: this.state.currentPage,
			onSortChange: this.onSortChange.bind(this),
			sizePerPageList: [
				{ text: '20 Per Page', value: 20 },
				{ text: '50 Per Page', value: 50 },
				{ text: '100 Per Page', value: 100 }
			],
			onSizePerPageList: this.onSizePerPageList.bind(this)
		}

		return (
			<div className="im-container im-project-page im-project-finances-page">
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />

				<div className="im-project-container im-project-finance-container" style={{ width: 'calc(100% - 20px)', left: '0px' }}>
					<div className="finance-tabs-content" style={{ overflowX: 'scroll' }}>
						<Row>
							<Col md={12} className="expense-header-mobile" style={{ fontSize: "25px", padding: "0", marginBottom: "25px" }}>Expense Reports <hr style={{ borderTop: "1px solid #ddd", marginBottom: "0" }} /></Col>
							<Col md={12} className="total-tags-mobile" style={{ marginBottom: '30px', padding: "0", display: "flex", justifyContent: "space-between" }}>
								<Col md={2} className="individual-tags-mobile" style={{ border: '1px solid #ef5a20', padding: "10px 5px", background: '#fafafa', justifyContent: "center", }}>
									<p style={{ margin: 0, fontSize: '14px', fontWeight: 600}}>Total Pending Reports: </p><span style={{ marginLeft: "10px", fontSize: '18px' }}>{this.state.pending_list}</span>
								</Col>

								<Col md={2} className="individual-tags-mobile" style={{ border: '1px solid #ef5a20', padding: "10px 5px", background: '#fafafa', justifyContent: "center" }}>
									<p style={{ margin: 0, fontSize: '14px', fontWeight: 600}}>Total Pending Value: </p><span style={{ marginLeft: "10px", fontSize: '18px' }}>{'INR ' + this.state.pending_amount}</span>
								</Col>

								<Col md={2} className="individual-tags-mobile" style={{ border: '1px solid #ef5a20', padding: "10px 5px", background: '#fafafa', justifyContent: "center" }}>
									<p style={{ margin: 0, fontSize: '14px', fontWeight: 600}}>Total Approved Reports: </p><span style={{ marginLeft: "10px", fontSize: '18px' }}>{this.state.approved_list}</span>
								</Col>

								<Col md={2} className="individual-tags-mobile" style={{ border: '1px solid #ef5a20', padding: "10px 5px", background: '#fafafa', justifyContent: "center" }}>
									<p style={{ margin: 0, fontSize: '14px', fontWeight: 600}}>Total Approved Value: </p><span style={{ marginLeft: "10px", fontSize: '18px' }}>{'INR ' + this.state.approved_amount}</span>
								</Col>
							</Col>
							<Col md={12} className="filter-holder" style={{ display: "flex", alignItems: "center", marginBottom: "20px", padding: "0" }}>
								<Col md={5} className="date-holder-mobile-view">
									<Col md={12} className="mb10" style={{ padding: "0px" }}>
										<div className="note" style={{ fontSize: "16px" }}>Date of Submission :</div>
									</Col>

									<Col md={12} style={{ padding: "0", display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
										<div style={{ flex: 1, marginRight: "10px" }}>
											<label style={{ marginRight: "10px", fontSize: '14px', padding: "0"}}>From :</label>
											<LocaleProvider locale={enUS}>
												<DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" value={this.state.timestampFrom} type="date" onChange={this.selectFromDate.bind(this)} />
											</LocaleProvider>
										</div>
										<div style={{ flex: 1, marginLeft: "10px" }}>
											<label style={{ marginRight: "10px", fontSize: '14px', padding: "0" }}> To : </label>
											<LocaleProvider locale={enUS}>
												<DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" value={this.state.timestampTo} type="date" onChange={this.selectToDate.bind(this)} />
											</LocaleProvider>
										</div>
									</Col>
								</Col>
								<Col md={6} className="status-holder-mobile-view">
									<Col md={12} className="status-inner-mobile-view" style={{ padding: "0", marginTop: "20px", display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}>
										<div className="filters-mobile" style={{display: 'flex'}}>
											<div style={{ marginRight: "20px" }}>
												<div className="note" style={{ fontSize: "16px" }}>Report Status :</div>
												<select style={{ width: "200px", marginTop: "10px" }} value={this.state.status} className="select-mobile form-control" onChange={this.filterByStatus.bind(this)}>
													<option value="disabled" disabled>Select Status</option>
													<option value="">All</option>
													<option value="Approved">Approved</option>
													<option value="Partially approved">Partially approved</option>
													<option value="Rejected">Rejected</option>
													<option value="Submitted">Pending</option>
													<option value="Draft">Draft</option>
												</select>
											</div>
											<div style={{ marginRight: "20px" }}>
												<div className="note" style={{ fontSize: "16px" }}>Submitted By :</div>
												<select style={{ width: "200px", marginTop: "10px" }} value={this.state.submitted_by} className="select-mobile form-control" onChange={this.filterBySubmittedBy.bind(this)}>
													<option value="" disabled>Select Employee</option>
													{this.state.submitters.map(function(submitter, index) {
														return (
															<option value={submitter.id}>{submitter.name}</option>
														)
													})}
												</select>
											</div>
										</div>
										<div className="btn-container-mobile" style={{ display: "flex" }}>
											<button className="btn btn-info" onClick={this.applyFilter.bind(this)}>Apply</button>
											<button onClick={this.clearfilter.bind(this)} style={{ background: "transparent", border: "solid 1px #ef5a20", color: "#ef5a20", padding: "3px 15px", borderRadius: "3px", marginLeft: "10px" }}>Reset</button>
										</div>
									</Col>
								</Col>
							</Col>
						</Row>

						<div className="tab-content budget-tab-content" style={{ overflowX: 'scroll' }}>
							<BootstrapTable data={this.state.reports} striped={false} ref='table' fetchInfo={{dataTotalSize: this.state.total_reports}} remote={true} pagination={true} sort={true} hover={true} exportCSV options={options}>
								<TableHeaderColumn dataField="_id" isKey={true} hidden={true} export={false} searchable={false}>Id</TableHeaderColumn>
								<TableHeaderColumn dataField="expense_report_id" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.viewReport.bind(this)}>Expense Report ID</TableHeaderColumn>
								<TableHeaderColumn dataField="submitted_by" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>Submitted By</TableHeaderColumn>
								<TableHeaderColumn dataField="submitted_date" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.dateFormatter.bind(this)} sortFunc={this.sortDates.bind(this, "submitted_date")}>Submitted On</TableHeaderColumn>
								<TableHeaderColumn dataField="expense_lines" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.expenseLine.bind(this)}>Total Expense Lines</TableHeaderColumn>
								<TableHeaderColumn dataField="reimbursable_amount" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true}>Reimbursable Amount (INR)</TableHeaderColumn>
								<TableHeaderColumn dataField="approved_amount" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true}>Approved Amount (INR)</TableHeaderColumn>
								<TableHeaderColumn dataField="status" width='100px' headerAlign='center' dataAlign='center' dataSort={true} hidden={false} export={true} searchable={true} dataFormat={this.transformStatus.bind(this)}>Status</TableHeaderColumn>
								<TableHeaderColumn dataField="approved_by" width='100px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.approverName.bind(this)}>Approved By</TableHeaderColumn>
							</BootstrapTable>
						</div>
					</div>
				</div>
			</div>
		)
	}

}