import React, {Component} from 'react';
import {Tabs, Tab} from 'react-bootstrap';

import CampaignsTable from './CampaignsTable';
import TagsTable from './TagsTable';
// import CampaignTemplates from './CampaignTemplates';
import SettingsEmailNotifications from '../7-settings/SettingsEmailNotifications';

const readCookie = require('../cookie.js').readCookie;

export default class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: null,
      selectedTemplateId: null,
      activeKey: 1,
      showAddCampaignModal: false
    }
    this.startCampaignFromTemplate = this.startCampaignFromTemplate.bind(this);
    this.onAddCampaignOpen = this.onAddCampaignOpen.bind(this);
    this.onAddCampaignClose = this.onAddCampaignClose.bind(this);
  }

  handleSelect(selectedKey) {
    this.setState({ activeKey: selectedKey });
  }

  startCampaignFromTemplate(selectedTemplateId) {
    this.setState({
      selectedTemplateId,
      showAddCampaignModal: true
    });
    this.handleSelect(1);
  }

  onAddCampaignOpen() {
    this.setState({ showAddCampaignModal: true });
  }

  onAddCampaignClose(data) {
    this.setState({ showAddCampaignModal: false });
    fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo['ngo_id'], {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ campaigns: data['campaigns'] });
    }.bind(this))
  }

  componentWillMount() {
    fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo['ngo_id'], {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ campaigns: data['campaigns'] });
    }.bind(this))
  }

  render() {
    if(this.state.showAddCampaignModal && this.state.campaigns !== null) {
      return (
        <div className='page-container'>
          <CampaignsTable ngo={this.props.ngo} selectedTemplateId={this.state.selectedTemplateId} campaigns={this.state.campaigns}
            onAddCampaignOpen={this.onAddCampaignOpen} onAddCampaignClose={this.onAddCampaignClose} showAddCampaignModal={this.state.showAddCampaignModal} />
        </div>
      )
    } else {
      return (
        <div className='page-container'>
          <Tabs activeKey={this.state.activeKey} id="single-donor-tab" onSelect={this.handleSelect.bind(this)}>
              <Tab eventKey={1} title={<div className="single-donor-tab-header"><i className='fa fa-flag'></i><span> Campaigns</span></div>}>
                {this.state.campaigns !== null ? (
                  <CampaignsTable ngo={this.props.ngo} selectedTemplateId={this.state.selectedTemplateId} campaigns={this.state.campaigns}
                    onAddCampaignOpen={this.onAddCampaignOpen} onAddCampaignClose={this.onAddCampaignClose} showAddCampaignModal={this.state.showAddCampaignModal} />
                ) : (null)}
              </Tab>
              {this.props.ngo.hasOwnProperty('account_type') ? (
                this.props.ngo.account_type === 'ngo' ? (
                  <Tab eventKey={2} title={<div className="single-donor-tab-header"><i className='fa fa-envelope'></i><span> Email/Notifications</span></div>}>
                    <SettingsEmailNotifications userData={this.props.userData} ngo={this.props.ngo} updateNgoData={this.props.updateNgoData} />
                    {/*<CampaignTemplates ngo={this.props.ngo} startCampaignFromTemplate={this.startCampaignFromTemplate} />*/}
                  </Tab>
                ) : (null)
              ) : (
                <Tab eventKey={2} title={<div className="single-donor-tab-header"><i className='fa fa-envelope'></i><span> Email/Notifications</span></div>}>
                  <SettingsEmailNotifications userData={this.props.userData} ngo={this.props.ngo} updateNgoData={this.props.updateNgoData} />
                  {/*<CampaignTemplates ngo={this.props.ngo} startCampaignFromTemplate={this.startCampaignFromTemplate} />*/}
                </Tab>
              )}
              <Tab eventKey={3} title={<div className="single-donor-tab-header"><i className='fa fa-tags'></i><span>Campaign Tags</span></div>}>
                {this.state.tags !== null ? (
                  <TagsTable ngo={this.props.ngo} />
                ) : (null)}
              </Tab>
          </Tabs>
        </div>
      );
    }
  }
}