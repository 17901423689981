import React, {Component} from 'react';
import {Row} from 'react-bootstrap';
import moment from 'moment';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { DatePicker } from 'antd';

import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';

export default class RepositoryFilter extends Component {
	disabledToDate(current) {
		var date = new Date(moment(this.props.fromDate, 'DD/MM/YYYY').format('MM-DD-YYYY'));
		return current && current.valueOf() < date;
	}

  render() {
    return (
      <Row className="filters repository-filter">
        <span className="to-text">From</span>
        <div>
          <LocaleProvider locale={enUS}>
            <DatePicker locale={enUS} size='large' defaultValue={this.props.fromDate} id="donorDatePickerFrom"
              format="DD/MM/YYYY" allowClear={false} onChange={this.props.handleFromDateChange} />
          </LocaleProvider>
        </div>
        <span className="to-text">To</span>
        <div>
          <LocaleProvider locale={enUS}>
          	{this.props.fromDate !== '' ? (
		          <DatePicker locale={enUS} size='large' defaultValue={this.props.toDate} min id="donorDatePickerTo" disabledDate={this.disabledToDate.bind(this)}
		            format="DD/MM/YYYY" allowClear={false} onChange={this.props.handleToDateChange} />
          	) : (
          		<DatePicker locale={enUS} size='large' defaultValue={this.props.toDate} id="donorDatePickerTo"
		            format="DD/MM/YYYY" allowClear={false} onChange={this.props.handleToDateChange} />
          	)}
          </LocaleProvider>
        </div>
        <div className="filter-buttons">
        	<button type='button' className='btn btn-success react-bs-table-add-btn'
						disabled={this.props.fromDate === '' || this.props.toDate === '' ? (true) : (false)}
						onClick={this.props.applyFilters.bind(this)}>
						<span>Apply</span>
					</button>
					<button type='button' className={'btn btn-success react-bs-table-add-btn'} onClick={this.props.resetFilters.bind(this)}>
						<span>Reset</span>
					</button>
        </div>
      </Row>
    );
  }
}