import React from 'react';
import {Tabs, Tab} from 'react-bootstrap';

import SettingsOrg from './SettingsOrg';
import SettingsMembers from './SettingsMembers';
import SettingsSingle from './SettingsSingle';

export default class EMAdminSettings extends React.Component {
  render() {
    return (
      <div className="page-container-expense">
        <Tabs defaultActiveKey={1} id="single-donor-tab">
            <Tab eventKey={1} title={<div className="single-donor-tab-header"><i className='fa fa-user'></i><span> Profile</span></div>}>
              <SettingsSingle userData={this.props.userData} ngo={this.props.ngo} updateUser={this.props.updateUser} />
            </Tab>
            <Tab eventKey={2} title={<div className="single-donor-tab-header"><i className='fa fa-building-o'></i><span> Organization</span></div>}>
              <SettingsOrg userDate={this.props.userData} ngo={this.props.ngo} updateNgoData={this.props.updateNgoData} />
            </Tab>
            <Tab eventKey={3} title={<div className="single-donor-tab-header"><i className='fa fa-users'></i><span> Members</span></div>}>
              <SettingsMembers userData={this.props.userData} ngo={this.props.ngo} />
            </Tab>
        </Tabs>
      </div>
    );
  }
}