import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import moment from 'moment';
import swal from "sweetalert2";
import {BootstrapTable, TableHeaderColumn, ExportCSVButton} from 'react-bootstrap-table';
import Loader from '../Loader';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../cookie.js').readCookie;

export default class SingleCampaignDetailsStatsType extends Component {
  constructor(props) {
    super(props);
    this.state = {
    	open_rate: null,
    	click_rate: null,
    	overview_stats: null,
    	unique_opens: null,
    	unique_clicks: null,
    	allLoaded: false,
    	campaign_name: '',
    	campaign_unique_stats: null,
    	total_logs: null,
    	sizePerPage: 20,
      currentPage: 1,
      searchText: '',
      showLoader: true,
      loaderHiding: 'campaign-details-stats'
    }
  }

  componentDidMount() {
  	this.setState({ campaignId: this.props.params['name'] });
    var allowed_events = ['delivered', 'clicked', 'opened', 'bounced', 'dropped', 'complained'];
    if(allowed_events.indexOf(this.props.params['type']) > -1) {
      fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/' + this.props.params['name'] + '/unique-stats/' + this.props.params['type'], {
        method: "GET",
        headers: {
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        }
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
      	this.setState({
          campaign_unique_stats: data['stats']['target_donors'],
          campaign_name: data['stats']['campaign']['name'],
      		allLoaded: true
      	}, function() {
          setTimeout(function() {
            this.setState({ showLoader: false });
          }.bind(this), 200);
      	}.bind(this));
      }.bind(this));
    } else {
      var new_pathname = window.location.pathname.split('/');
      new_pathname = new_pathname.slice(0, new_pathname.length - 1).join('/');
      window.location.pathname = new_pathname;
    }
  }

  emailFormatter(cell, row) {
  	return <a href={window.location.pathname.split('/stats')[0] + '/' + row.id + '/stats'}>{cell}</a>;
  }

  // createCustomClearButton(onClick) {
  //   return (
  //     <span onClick={onClick}>
  // 	    <i className="clear-table-search glyphicon glyphicon-remove"></i>
  //     </span>
  //   );
  // }

  nameFormatter(cell, row) {
  	return row.first_name + ' ' + row.last_name;
  }

  createCustomExportCSVButton() {
  	return (
      <ExportCSVButton />
    );
  }

  // exportToEmail() {
  //   swal({
  //     title: 'Are you sure?',
  //     text: "You will get an email with unique opens/clicks details!",
  //     type: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, Export!',
  //     cancelButtonText: 'No, Cancel!',
  //     confirmButtonClass: 'btn',
  //     cancelButtonClass: 'btn',
  //   }).then(function (result) {
  //     if(result) {
  //       fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/detailed-stats-to-csv/' + this.props.params['name'], {
  //         method: "GET",
  //         headers: {
  //           'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
  //         }
  //       }).then(function(response) {
  //         return response.json();
  //       }).then(function(data) {
  //         if(data.status === 'ok') {
  //           swal({
  //             title: 'Email sent successfully!',
  //             text: '',
  //             type: 'success'
  //           });
  //         } else {
  //           swal({
  //             title: 'Email export failed!',
  //             text: 'There was no data found to be exported.',
  //             type: 'error'
  //           });
  //         }
  //       });
  //     }
  //   }.bind(this));
  // }

  // onPageChange(page, sizePerPage) {
  //   this.setState({ showLoader: true, loaderHiding: 'data-update campaign-details-stats' });

  //   let query = '?page=' + (page - 1) + '&per_page=' + sizePerPage;
  //   fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/detailed-stats/' + this.props.params['name'] + query, {
  //     method: "GET",
  //     headers: {
  //       'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
  //     }
  //   }).then(function(response) {
  //     return response.json();
  //   }).then(function(data) {
  //   	this.setState({ campaign_stats: data['logs']['logs'], currentPage: page, loaderHiding: 'hiding campaign-details-stats' }, function() {
  //       setTimeout(function() {
  //         this.setState({ showLoader: false });
  //       }.bind(this), 100);
  //     });
  //   }.bind(this));
  // }

  // onSizePerPageList(sizePerPage) {
  //   this.setState({ showLoader: true, loaderHiding: 'data-update campaign-details-stats', currentPage: 1 });

  //   let query = '?page=0&per_page=' + sizePerPage;
  //   fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/detailed-stats/' + this.props.params['name'] + query, {
  //     method: "GET",
  //     headers: {
  //       'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
  //     }
  //   }).then(function(response) {
  //     return response.json();
  //   }).then(function(data) {
  //   	this.setState({ campaign_stats: data['logs']['logs'], sizePerPage, loaderHiding: 'hiding campaign-details-stats' }, function() {
  //       setTimeout(function() {
  //         this.setState({ showLoader: false });
  //       }.bind(this), 100);
  //     });
  //   }.bind(this));
  // }

  // onSortChange(sortBy, sortType) {
  //   if(sortBy === 'opens' || sortBy === 'clicks') {
  //     this.setState({ sortBy, sortType, showLoader: true, loaderHiding: 'data-update campaign-details-stats', currentPage: 1, searchText: '' });

  //     let query = '?page=0&per_page=' + this.state.sizePerPage + '&sort_by=' + sortBy + '&sort_type=' + sortType;

  //     fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/detailed-stats/' + this.props.params['name'] + query, {
  //       method: "GET",
  //       headers: {
  //         'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
  //       }
  //     }).then(function(response) {
  //       return response.json();
  //     }).then(function(data) {
  //       this.setState({ campaign_stats: data['logs']['logs'], total_logs: data['logs']['total_logs'], loaderHiding: 'hiding campaign-details-stats' }, function() {
  //         setTimeout(function() {
  //           this.setState({ showLoader: false });
  //         }.bind(this), 200);
  //       });
  //     }.bind(this));
  //   }
  // }

  render() {
  	let backUrl = window.location.pathname.split('/stats')[0] + '/stats';
  	var tableOptions = {
    	exportCSVBtn: this.createCustomExportCSVButton.bind(this),
      // clearSearch: true,
      // onSortChange: this.onSortChange.bind(this),
      // clearSearchBtn: this.createCustomClearButton,
      // sizePerPage: 20,
      // sizePerPageList: [20, 50, 100]
      // page: this.state.currentPage,
      // onPageChange: this.onPageChange.bind(this),
      sizePerPage: 20,
      sizePerPageList: [
        { text: '20 Per Page', value: 20 },
        { text: '50 Per Page', value: 50 },
        { text: '100 Per Page', value: 100 }
      ],
      // onSizePerPageList: this.onSizePerPageList.bind(this),
    };

    if(this.state.allLoaded) {
    	return (
	      <div className="page-container single-campaign-detail-stats">
	      	<div>
	          <span className="back">
	            <Link to={backUrl} className="back-btn"><i className="fa fa-chevron-left"></i> Back To Campaign Stats</Link>
	          </span>
	      	</div>

          <Row className="mb10 mt0">
            <h1 className="text-center text-capitalize">Unique {this.props.params['type']}</h1>
          </Row>

	      	{this.state.campaign_unique_stats !== null ? (
		      	<Row className="mb20 mt10 stats-row-4" style={{position: 'relative'}}>
		      		{this.state.showLoader ? (
                <Loader backgroundColor='rgba(255,255,255,0.7)' loaderHiding={this.state.loaderHiding} />
              ) : ('')}
				      <BootstrapTable data={this.state.campaign_unique_stats} options={tableOptions} className="total-opens-table" exportCSV
				      	csvFileName={this.state.campaign_name + ' - Unique ' + this.props.params['type'] + ' - ' + String(moment().unix()) + '.csv'}
				      	fetchInfo={{ dataTotalSize: this.state.total_logs }} remote={true} striped={false} pagination={true}
                hover={true} ref='table' search={false} searchPlaceholder="Search by Email/Total Opens">
                <TableHeaderColumn dataField="id" isKey={true} hidden={true} dataSort={false} searchable={false}>Donor Id</TableHeaderColumn>
                <TableHeaderColumn dataField="first_name" dataSort={false} searchable={true} dataFormat={this.nameFormatter.bind(this)}>Full Name</TableHeaderColumn>
                <TableHeaderColumn dataField="last_name" hidden={true} dataSort={false} searchable={true}>Last Name</TableHeaderColumn>
                <TableHeaderColumn dataField="email" dataSort={false} searchable={true} dataFormat={this.emailFormatter.bind(this)}>Email</TableHeaderColumn>
              </BootstrapTable>
		      	</Row>
		      ) : (null)}
	      </div>
	    );
    } else return null;
  }
}