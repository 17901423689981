import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {Link} from 'react-router-dom';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
const readCookie = require('../cookie.js').readCookie;

export default class TagList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      campaign_tag_name: '',
    }
  }

  componentDidMount() {
    let ngoID = this.props.ngo.ngo_id;
    fetch(process.env.REACT_APP_API_URL + '/get/unsubscriber/names/' + this.props.match.params['id'] + '/' + ngoID, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(res => res.json())
    .then((data) => {
      if(data.status === 'ok'){
        let list = [];
        let campaign_tag_name = data.campaign_tag_name;
        for(var i = 0; i < data.data.length; i++){
          let listObj = {};
          listObj['id'] = data.data[i]['_id'];
          listObj['name'] = data.data[i]['first_name'] + ' ' + data.data[i]['last_name'];
          listObj['email'] = data.data[i]['email'];
          list.push(listObj);
        }
      this.setState({list, campaign_tag_name});
      }
    });
  }

  render() {
    let backUrl = '/dm/campaigns';
    return (
      <div className="page-container">
        <div>
          <span className="back">
            <Link to={backUrl} className="back-btn"><i className="fa fa-chevron-left"></i> Go to All campaigns</Link>
          </span>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <h3>Unsubscribed users from:</h3>
          <h4 style={{
            margin: '10px 0 0 10px',
            color: '#777',
            fontWeight: '600',
            textDecoration: 'underline'
          }}>{this.state.campaign_tag_name}</h4>
        </div>
        <BootstrapTable data={this.state.list} striped={true} hover={true} ref='table'>
          <TableHeaderColumn dataField="id" isKey={true} hidden={true}>Id</TableHeaderColumn>
          <TableHeaderColumn dataField="name" dataSort={true} hidden={false} export={true} > Name</TableHeaderColumn>
          <TableHeaderColumn dataField="email" dataSort={true} hidden={false} export={true} > Email</TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}
