import React from 'react';
import {ButtonToolbar} from 'react-bootstrap'
import {Link} from 'react-router-dom';

export default class DashboardQuicklink extends React.Component {
  render() {
    return (
      <div>
        <ButtonToolbar className="quicklink-container">
          {this.props.quickLink['donorListId'] !== '' ? (
            <Link to={'/dm/lists/' + this.props.quickLink['donorListId']} className="quicklink-btn">
              <span>{this.props.quickLink['donorsCount']} Donors</span>
            </Link>
          ) : (
            <Link className="quicklink-btn">
              <span>{this.props.quickLink['donorsCount']} Donors</span>
            </Link>
          )}
          {/*this.props.quickLink.donor_count_acc_to_categories.map(function(item, index) {
            return (
              <Link key={index} to={'/donors?donor_category=' + item.id} className="quicklink-btn">
                <span>
                  {item.count + ' ' + item.name}
                  {item.count > 1 ? 's' : ''}
                </span>
              </Link>
            )
          })*/}
          <Link to={'/dm/lists'} className="quicklink-btn">
            <span>{this.props.quickLink['listsCount']} Lists</span>
          </Link>
          <Link to={'/dm/donations'} className="quicklink-btn">
            <span>{this.props.quickLink['donationsCount']} Donations</span>
          </Link>
          <Link to={'/dm/donations'} className="quicklink-btn">
            <span>{this.props.quickLink['donationsTotalAmount']} Total Amount Donated</span>
          </Link>
        </ButtonToolbar>
      </div>
    );
  }
}