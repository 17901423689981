import React,
{Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn, ButtonGroup} from 'react-bootstrap-table';

const readCookie = require('../../partials/cookie.js').readCookie;

export default class FormResponse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			responseTableData: [],
			sizePerPage: 20,
			currentPage: 1,
			totalDataSize: 0,
			allRepo: [],
			filteredRepo: [],
			searchVal: ''
		}
	}

	componentDidMount(){
		let query = '?status=Published&limit=20&skip=0';
		this.getResponses(query);
	}

	getResponses = (query) =>{
		fetch(process.env.REACT_APP_API_URL + '/fb/get/all_form_dashboard/' + this.props.ngo.ngo_id + query, {
			method: 'GET',
			headers: {
				'Authorization': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(data => data.json())
		.then(data => {
			if(data.status === 'ok'){
				this.setState({responseTableData: data.data, totalDataSize: data['total_forms']});
			}
		});
	}

	onPageChange(page, sizePerPage) {
		let query = '?status=Published&limit=' + sizePerPage + '&skip=' + (page - 1) * sizePerPage;
		this.setState({currentPage: page, sizePerPage: sizePerPage});
		this.getResponses(query);
	}

	onSizePerPageList(sizePerPage) {
		this.setState({currentPage: 1, sizePerPage: sizePerPage});
		let query = '?status=Published&limit=' + sizePerPage + '&skip=' + (this.state.currentPage - 1) * sizePerPage;
		this.getResponses(query);
	}

	createCustomButtonGroup (props, onClick) {
		return (
		  <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
				<button type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'} onClick={this.getResponses}>
				  <span>
						<i className="glyphicon glyphicon-refresh"></i>
						Refresh
				  </span>
				</button>
		  </ButtonGroup>
		);
  }

	createCustomClearButton(onClick) {
		return (
		  <span onClick={onClick}>
				<i className="clear-table-search glyphicon glyphicon-remove"></i>
		  </span>
		);
  }

  nameFormatter(cell, row) {
		if(row.response_count > 0){
			return(
				<div style={{
					color: '#108ee9',
					cursor: 'pointer'
				}}
				onClick={()=>{window.location.pathname = '/fb/individual/'+ row['_id'] +'/response'}}>
					{cell.charAt(0).toUpperCase() + cell.slice(1)}
				</div>
			)
		} else {
			return cell
		}
  }

  responseFormatter(cell, row){
		if(row.response_count > 0){
			return(
				<div style={{
					color: '#108ee9',
					cursor: 'pointer'
				}}
				onClick={()=>{window.location.pathname = '/fb/individual/'+ row['_id'] +'/response'}}>
					{cell}
				</div>
			)
		} else {
			return cell
		}
  }

	render() {
		const options = {
	  btnGroup: this.createCustomButtonGroup.bind(this),
	  clearSearch: true,
	  clearSearchBtn: this.createCustomClearButton,
		sizePerPage: this.state.sizePerPage,
		onPageChange: this.onPageChange.bind(this),
		page: this.state.currentPage,
	  sizePerPageList: [
				{ text: '20 Per Page', value: 20 },
				{ text: '50 Per Page', value: 50 },
				{ text: '100 Per Page', value: 100 }
			],
		onSizePerPageList: this.onSizePerPageList.bind(this)
	};
		return (
			<div className="fb-response-container">
				<Row className="fb-response-content-container">
					<Col md={12} className="fb-response-content">
						<div className="fb-response-title">
							Form Responses
						</div>
					</Col>
					<Col md={12} className="fb-response-table">
						<BootstrapTable data={this.state.responseTableData} striped={false} ref='table' fetchInfo={{dataTotalSize: this.state.totalDataSize}} remote={true} pagination={true} options={options} hover={true} search searchPlaceholder="Search by First Name/Response Status/Last Updated by">
							<TableHeaderColumn dataField="_id" width='0px' headerAlign='center' dataAlign='center' dataSort={true} hidden={true} export={true} searchable={true}></TableHeaderColumn>
							<TableHeaderColumn dataField="name" width='80px' isKey={true} headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.nameFormatter.bind(this)}>Title</TableHeaderColumn>
							<TableHeaderColumn dataField="description" width='150px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} >Description</TableHeaderColumn>
							<TableHeaderColumn dataField="response_count" width='120px' headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.responseFormatter.bind(this)}>Responses</TableHeaderColumn>
						</BootstrapTable>
					</Col>
				</Row>
			</div>
		);
	}
}
