import React, {Component} from 'react';
import { Row, Col } from "react-bootstrap";
import moment from 'moment';
import swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require('../cookie.js').readCookie;
export default class ABTestingCampaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abCampaigns: null
    };
    this.createVersionData = this.createVersionData.bind(this);
  }

  onAddCampaignOpen() {
    window.location.pathname = '/dm/campaigns/ab-testing/new';
  }

  createVersionData(campaignData, campaignIndex) {
    var campaignPromise;
    var campaign_stats;
    if(campaignData.status === 'Winner Declared') {
      campaignPromise = fetch(process.env.REACT_APP_API_URL + '/campaigns/' + this.props.ngo.ngo_id + '/overview-stats/' + campaignData.parent_campaign_id, {
        method: "GET",
        headers: {
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        }
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        campaign_stats = {};
        campaign_stats['sent'] = data['stats']['total']['sent'];
        campaign_stats['delivered'] = data['stats']['total']['delivered'];
        campaign_stats['opened'] = data['stats']['unique']['opened'];
        campaign_stats['clicked'] = data['stats']['unique']['clicked'];
      });
    }
    var child_campaign_ids = campaignData.child_campaign_ids;
    var allChildCampaignPromises = [];
    var versions_data = [];
    var childCampaignPromise;
    for(var i = 0; i < child_campaign_ids.length; i++) {
      childCampaignPromise = fetch(process.env.REACT_APP_API_URL + '/campaigns/' + this.props.ngo.ngo_id + '/overview-stats/' + child_campaign_ids[i], {
        method: "GET",
        headers: {
          'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
        }
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        var stats = {};
        stats['id'] = data['stats']['campaign']['id'];
        stats['name'] = data['stats']['campaign']['name'];
        stats['sent'] = data['stats']['total']['sent'];
        stats['delivered'] = data['stats']['total']['delivered'];
        stats['opened'] = data['stats']['unique']['opened'];
        stats['clicked'] = data['stats']['unique']['clicked'];
        versions_data.push(stats);
      });
      allChildCampaignPromises.push(childCampaignPromise);
    }

    var abCampaigns = this.state.abCampaigns;
    var that = this;
    Promise.all(allChildCampaignPromises, campaignPromise).then(function() {
      var newCampaignData = campaignData;
      newCampaignData['versions_data'] = versions_data;
      if(campaign_stats !== undefined) newCampaignData['campaign_stats'] = campaign_stats;
      abCampaigns[campaignIndex] = newCampaignData;
      that.setState({ abCampaigns });
    });
  }

  setWinner(campaign_id, version_id) {
    fetch(process.env.REACT_APP_API_URL + '/ab-testing/campaigns/' + this.props.ngo.ngo_id + '/' + campaign_id + '/winner', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify({ winner_campaign: version_id })
    }).then((response) => response.json())
    .then((data) => {
      if(data.status === 'ok') {
        this.notifySuccess('Winner Campaign has been sent successfully.');
        window.location.reload();
      }
    });
  }

  notifySuccess = (text) => toast.success(text);

  componentWillMount() {
    fetch(process.env.REACT_APP_API_URL + '/ab-testing/campaigns/' + this.props.ngo.ngo_id, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data)
      if(data.status === 'ok'){
        this.setState({ abCampaigns: data['campaigns'] }, function() {
          for(var i = 0; i < data['campaigns'].length; i++) {
            this.createVersionData(data['campaigns'][i], i);
          }
        });
      } else {
        swal({
          title: data['message'],
          text: data['message'],
          type: 'error'
        })
      }
    }.bind(this));
  }

  render() {
    if(this.state.abCampaigns !== null) {
      return (
        <div className='page-container ab-testing-campaigns'>
          <ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
          <h2>A/B Testing Campaigns</h2>
          <div className='my-custom-class' sizeClass='btn-group-md'>
            <button type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'} onClick={this.onAddCampaignOpen.bind(this)}>
              <span>
                <i className="glyphicon glyphicon-plus"></i>
                Create New A/B Campaign
              </span>
            </button>
          </div>
          <Row className="ab-campaigns-container">
            {this.state.abCampaigns.length === 0 ? (
              <div className="no-ab-testing-campaigns">
                <h3>No A/B Testing Campaigns Yet.</h3>
                <h3 className="create" onClick={this.onAddCampaignOpen.bind(this)}>Create One Now!</h3>
              </div>
            ) : (null)}
            {this.state.abCampaigns.map(function(abCampaign, index) {
              return (
                <div className="ab-campaign-container">
                  {(() => {
                    if(abCampaign.status === 'Draft' || abCampaign.status === 'Discarded' || abCampaign.status === 'Scheduled') {
                      return (
                        <h4 className="campaign-name">
                          <a href={"/dm/campaigns/ab-testing/" + abCampaign.id}>{abCampaign.name}</a>
                          <div className={"campaign-status " + abCampaign.status.replace(/\s+/g, '-').toLowerCase()}>{abCampaign.status}</div>
                        </h4>
                      )
                    } else if (abCampaign.status === 'Ongoing' || abCampaign.status === 'Test Emails Sent' || abCampaign.status === 'Testing Completed') {
                      return (
                        <h4 className="campaign-name">
                          <span>{abCampaign.name}</span>
                          <div className={"campaign-status " + abCampaign.status.replace(/\s+/g, '-').toLowerCase()}>{abCampaign.status}</div>
                        </h4>
                      )
                    } else if(abCampaign.status === 'Winner Declared' || abCampaign.status === 'Sent') {
                      return (
                        <h4 className="campaign-name">
                          <a href={"/dm/campaigns/details/" + abCampaign.parent_campaign_id}>{abCampaign.name}</a>
                          <div className={"campaign-status " + abCampaign.status.replace(/\s+/g, '-').toLowerCase()}>{abCampaign.status}</div>
                        </h4>
                      )
                    } else {
                      return (
                        <h4 className="campaign-name">
                          <span>{abCampaign.name}</span>
                        </h4>
                      )
                    }
                  })()}
                  <Row className="campaign-data">
                    {abCampaign.is_scheduled ? (
                      <Col className="form-group" md={2}>
                        <label>Scheduled:</label>
                        <span>{moment(abCampaign.scheduled_datetime).format('DD/MM/YYYY')}</span>
                      </Col>
                    ) : (null)}
                    <Col className="form-group" md={3}>
                      <label>Creator:</label>
                      <span>{abCampaign.created_by.created_by_name}</span>
                    </Col>
                    <Col className="form-group" md={2}>
                      <label>Test Type:</label>
                      <span>{abCampaign.test_based_on.toUpperCase()}</span>
                    </Col>
                    {abCampaign.hasOwnProperty('sent_at') && abCampaign.sent_at ? (
                      <Col className="form-group" md={2}>
                        <label>Sent At:</label>
                        <span>{moment(abCampaign.sent_at).format('DD/MM/YYYY')}</span>
                      </Col>
                    ) : (null)}
                    {abCampaign.hasOwnProperty('created_at') && abCampaign.created_at ? (
                      <Col className="form-group" md={2}>
                        <label>Created At:</label>
                        <span>{moment(abCampaign.created_at).format('DD/MM/YYYY')}</span>
                      </Col>
                    ) : (null)}
                  </Row>
                  {abCampaign.hasOwnProperty('campaign_stats') ? (
                    <Row className="campaign-stats">
                      <Col className="form-group" md={2}>
                        <label>Sent:</label>
                        <span>{abCampaign.campaign_stats.sent}</span>
                      </Col>
                      <Col className="form-group" md={2}>
                        <label>Delivered:</label>
                        <span>{abCampaign.campaign_stats.delivered}</span>
                      </Col>
                      <Col className="form-group" md={2}>
                        <label>Opened:</label>
                        <span>{abCampaign.campaign_stats.opened}</span>
                      </Col>
                      <Col className="form-group" md={2}>
                        <label>Clicked:</label>
                        <span>{abCampaign.campaign_stats.clicked}</span>
                      </Col>
                    </Row>
                  ) : (null)}
                  {abCampaign.hasOwnProperty('versions_data') && Object.keys(abCampaign.versions_data).length === abCampaign.versions_count ? (
                    <Row className="campaign-version-stats">
                      {(() => {
                        return abCampaign.versions_data.map(function(version, index) {
                          return (
                            <div className={abCampaign.hasOwnProperty('winner_campaign_id') && version.id === abCampaign.winner_campaign_id ? "version-container row winner-version" : "version-container row"} key={version.id}>
                              <Col className="form-group text-left" md={3}>
                                <label>Version:</label>
                                <span><a href={"/dm/campaigns/details/" + version.id}>{version.name}</a></span>
                              </Col>
                              <Col className="form-group" md={1}>
                                <label>Sent:</label>
                                <span>{version.sent}</span>
                              </Col>
                              <Col className="form-group" md={2}>
                                <label>Delivered:</label>
                                <span>{version.delivered}</span>
                              </Col>
                              <Col className="form-group" md={1}>
                                <label>Opened:</label>
                                <span>{version.opened}</span>
                              </Col>
                              <Col className="form-group" md={2}>
                                <label>Clicked:</label>
                                <span>{version.clicked}</span>
                              </Col>
                              {abCampaign.status === 'Testing Completed' ? (
                                <Col className="form-group set-as-winner-container" md={2}>
                                  <a onClick={this.setWinner.bind(this, abCampaign.id, version.id)} className="set-as-winner">Set as Winner &amp; trigger mail</a>
                                </Col>
                              ) : (null)}
                              {abCampaign.status === 'Winner Declared' && version.id !== abCampaign.winner_campaign_id ? (
                                <Col className="form-group" md={2}>
                                  <span>Discarded</span>
                                </Col>
                              ) : (null)}
                            </div>
                          )
                        }.bind(this))
                      })()}
                    </Row>
                  ) : (null)}
                </div>
              )
            }.bind(this))}
          </Row>
        </div>
      );
    } else return null;
  }
}