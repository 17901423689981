import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Select from 'react-select';

import 'react-select/dist/react-select.css';

const readCookie = require('../../cookie.js').readCookie;

export default class OtherInformation extends Component {
  constructor(props){
    super(props);
    var group_ids = [];
    for(var i = 0; i < this.props.donor.groups.length; i++) {
      group_ids.push(this.props.donor.groups[i]['id']);
    }
    this.state = {
      value: group_ids
    }
    this.onChange = this.onChange.bind(this);
    this.getOptions = this.getOptions.bind(this);
  }

  onChange = (options) => {
    this.setState({
      value: options.map(x => x.value)
    }, function() {
      this.props.updateGroups(this.state.value);
    });
  };

  getOptions() {
    return fetch(process.env.REACT_APP_API_URL + '/view_groups/'+ this.props.ngo_id, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      var options = [];
      for(var i = 0; i < data['groups'].length; i++) {
        var option = {};
        option['value'] = data['groups'][i]['group_id'];
        option['label'] = data['groups'][i]['group_name'];
        options.push(option);
      }
      return { options: options };
    });
  }

  render () {
    if(this.props.list !== null) {
      if(this.props.list.default_member_fields_others.length || this.props.list.custom_member_fields.length) {
        return (
          <Row>
            <Col className="margin-col-10" md={12}>
              <Row>
                <Col md={12}>
                  <p className="modal-sec-title member-add-edit-modal-subtitle">Other Information</p>
                </Col>

                {this.props.list.default_member_fields_others.map(function(field, index) {
                  if(field.id === 'groups') {
                    return (
                      <Col className="form-group" md={6} key={index}>
                        <label className="control-label">Groups:</label>
                        <div>
                          <Select.Async multi key={this.state.value.length}
                            value={this.state.value} loadOptions={this.getOptions}
                            onChange={this.onChange} isLoading={true} backspaceRemoves={false} />
                        </div>
                        <span><span className="red-color">Input is too long!</span></span>
                      </Col>
                    )
                  }
                }.bind(this))}
              </Row>

              <Row>
                {this.props.list.default_member_fields_others.map(function(field, index) {
                  if(field.id === 'frequency') {
                    return (
                      <Col className="form-group" md={4} key={index}>
                        <label className="control-label">Pledge Frequency:</label>
                        <select className="form-control" defaultValue={this.props.frequency} onChange={this.props.changeDonationFrequency}>
                          <option value="" disabled selected>Select Donation Frequency</option>
                          <option value="monthly">Monthly</option>
                          <option value="half-yearly">Half Yearly</option>
                          <option value="yearly">Yearly</option>
                        </select>
                      </Col>
                    )
                  } else if(field.id === 'pledged_amount') {
                    return (
                      <Col className="form-group" md={4} key={index}>
                        <label className="control-label">Pledge Amount (in INR):</label>
                        <input className="form-control" type="number" placeholder="Donation Amount" defaultValue={this.props.pledged_amount} onChange={this.props.changeDonationAmount} />
                      </Col>
                    )
                  }
                }.bind(this))}

                {/*<Col className="form-group" md={4}>
                  <label className="control-label">Pledge Start Date:</label>
                  {!this.props.pledge_start || this.props.pledge_start === "" ? (
                    <DatePicker className="form-control" placeholderText="Click to select a date" openFromDate={moment()}
                      onChange={this.props.handleDateChange} scrollableYearDropdown
                      showYearDropdown showMonthDropdown dateFormat="DD/MM/YYYY" />
                  ) : (
                    <DatePicker className="form-control" selected={moment(this.props.pledge_start)}
                      onChange={this.props.handleDateChange} scrollableYearDropdown
                      showYearDropdown showMonthDropdown dateFormat="DD/MM/YYYY" />
                  )}
                </Col>*/}
              </Row>

              <Row>
                {this.props.list.custom_member_fields.map(function(field, index) {
                  if(field.data_type === 'Text') {
                    return (
                      <Col className="form-group" md={4} key={index}>
                        <label className="control-label">{field.label + ':'}</label>
                        <input className="form-control" type="text" placeholder={field.label} value={this.props.custom_fields[field.id]} onChange={this.props.changeCustomFieldsValue.bind(this, field.id)} />
                      </Col>
                    )
                  }
                }.bind(this))}
              </Row>
            </Col>
          </Row>
        );
      } else return null;
    } else return null;
  }
}
