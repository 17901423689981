import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const readCookie = require('../cookie.js').readCookie;

export default class SingleCampaignDetailsDonorStats extends Component {
	constructor(props) {
		super(props);
		this.state = {
			campaign_id: null,
			donor_id: null,
			open_data: null,
			click_data: null,
			donor_data: null
		}
	}

  componentDidMount() {
  	this.setState({ campaign_id: this.props.match.params['campaign_id'], donor_id: this.props.match.params['donor_id'] });
  	fetch(process.env.REACT_APP_API_URL + '/campaigns/'+ this.props.ngo.ngo_id + '/' + this.props.match.params['campaign_id'] + '/user-stats/' + this.props.match.params['donor_id'], {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+JSON.parse(readCookie('access_token')).access_token
      }
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
    	if(data.status === 'ok') {
	    	let donor_data = {};
	    	donor_data['email'] = data.data.email;
	    	this.setState({ open_data: data.data.logs.opens, click_data: data.data.logs.clicks, donor_data });
	    }
    }.bind(this));
  }

  createCustomClearButton(onClick) {
    return (
      <span onClick={onClick}>
  	    <i className="clear-table-search glyphicon glyphicon-remove"></i>
      </span>
    );
  }

  timeFormatter(cell, row) {
  	return moment(cell).format('LLLL');
  }

  locationFormatter(cell, row) {
  	if(row.country && row.country !== '' && row.country !== 'Unknown') return row.city + ', ' + row.country;
  	else return row.city;
  }

  linkFormatter(cell, row) {
  	return <a href={cell} target="_blank">{cell}</a>
  }

  deviceFormatter(cell, row) {
  	return cell.charAt(0).toUpperCase() + cell.substr(1);
  }

	render() {
		let backUrl = window.location.pathname.split(this.props.match.params['donor_id'])[0] + 'stats';

    var tableOptions = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      sizePerPage: 20,
      sizePerPageList: [20, 50, 100]
    };

    if(this.state.open_data !== null && this.state.click_data !== null && this.state.donor_data !== null) {
			return (
				<div className="page-container single-campaign-detail-donor-stats">
					<div>
	          <span className="back">
	            <Link to={backUrl} className="back-btn"><i className="fa fa-chevron-left"></i> Back To Campaign Stats</Link>
	          </span>
	      	</div>
		      <Row className="mb20 mt20">
		      	<Col md={12}>
	      			<span>
	      				<b>Donor Email: </b>
	      				{this.state.donor_data.email}
	      			</span>
	      		</Col>

	      		<Col md={12}>
		      		<Tabs>
						    <TabList>
						      <Tab className="tab"><div className="tab-box"><span>Open Stats</span></div></Tab>
						      <Tab className="tab"><div className="tab-box"><span>Click Stats</span></div></Tab>
						    </TabList>

						    <TabPanel>
						      <BootstrapTable data={this.state.open_data} options={tableOptions} className="total-opens-table"
		                striped={false} hover={true} ref='table' pagination search searchPlaceholder="Search by Location/Device/Browser">
		                <TableHeaderColumn dataField="date" isKey={true} dataSort={false} searchable={false} dataFormat={this.timeFormatter.bind(this)}>Opened At</TableHeaderColumn>
		                <TableHeaderColumn dataField="city"  dataSort={true} searchable={true} dataFormat={this.locationFormatter.bind(this)}>Location</TableHeaderColumn>
		                <TableHeaderColumn dataField="platform"    width='140px' dataSort={true} searchable={true} dataFormat={this.deviceFormatter.bind(this)}>Device</TableHeaderColumn>
		                <TableHeaderColumn dataField="client"   width='140px' dataSort={true} searchable={true}>Browser</TableHeaderColumn>
		              </BootstrapTable>
						    </TabPanel>

						    <TabPanel>
						      <BootstrapTable data={this.state.click_data} options={tableOptions} className="total-clicks-table"
		                striped={false} hover={true} ref='table' pagination search searchPlaceholder="Search by Link/Location/Device/Browser">
		                <TableHeaderColumn dataField="date" isKey={true} dataSort={false} searchable={false} dataFormat={this.timeFormatter.bind(this)}>Clicked At</TableHeaderColumn>
		                <TableHeaderColumn dataField="url"     dataSort={true} searchable={true} dataFormat={this.linkFormatter.bind(this)}>Link</TableHeaderColumn>
		                <TableHeaderColumn dataField="city" width='200px' dataSort={true} searchable={true} dataFormat={this.locationFormatter.bind(this)}>Location</TableHeaderColumn>
		                <TableHeaderColumn dataField="platform"   width='110px' dataSort={true} searchable={true} dataFormat={this.deviceFormatter.bind(this)}>Device</TableHeaderColumn>
		                <TableHeaderColumn dataField="client"  width='110px' dataSort={true} searchable={true}>Browser</TableHeaderColumn>
		              </BootstrapTable>
						    </TabPanel>
						  </Tabs>
						</Col>
	      	</Row>
				</div>
			);
		} else {
			return null;
		}
	}
}