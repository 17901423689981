// elements settings

var textboxInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Textbox</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="textboxLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="textboxLabel" name="textboxLabel" required="" value="Textbox">
	</div>
	<div class="col-md-12">
		<label for="textboxPlaceholder" class="active control-label">Placeholder:</label>
		<input type="text" class="validate browser-default form-control" id="textboxPlaceholder" name="textboxPlaceholder" required="" value="Suitable Placeholder">
	</div>
	<div class="col-md-12">
		<label for="textboxError" class="active control-label">Error Message:</label>
		<input type="text" class="validate browser-default form-control" id="textboxError" name="textboxError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<label for="textboxMinLength" class="control-label">Min Characters:</label>
		<input type="text" class="validate browser-default form-control" id="textboxMinLength" name="textboxMinLength" required="" value="">
	</div>
	<div class="col-md-6">
		<label for="textboxMaxLength" class="control-label">Max Characters:</label>
		<input type="text" class="validate browser-default form-control" id="textboxMaxLength" name="textboxMaxLength" required="" value="">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var textareaInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Textarea</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="textareaLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="textareaLabel" name="textareaLabel" required="" value="Textarea">
	</div>
	<div class="col-md-12">
		<label for="textareaPlaceholder" class="active control-label">Placeholder:</label>
		<input type="text" class="validate browser-default form-control" id="textareaPlaceholder" name="textareaPlaceholder" required="" value="Suitable Placeholder">
	</div>
	<div class="col-md-12">
		<label for="textareaError" class="active control-label">Error Message:</label>
		<input type="text" class="validate browser-default form-control" id="textareaError" name="textareaError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<label for="textareaMinLength" class="control-label">Min Characters:</label>
		<input type="text" class="validate browser-default form-control" id="textareaMinLength" name="textareaMinLength" required="" value="">
	</div>
	<div class="col-md-6">
		<label for="textareaMaxLength" class="control-label">Max Characters:</label>
		<input type="text" class="validate browser-default form-control" id="textareaMaxLength" name="textareaMaxLength" required="" value="">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var locationInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Location</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="locationLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="locationLabel" name="locationLabel" required="" value="Location">
	</div>
	<div class="col-md-12">
		<input class="validate browser-default form-control" id="locationType1" name="locationType" required="" type="radio" value="searchbox">
		<label for="locationType1" class="active control-label">Location searchbox</label>
	</div>
	<div class="col-md-12">
		<input class="validate browser-default form-control" id="locationType2" name="locationType" required="" type="radio" value="mappointer">
		<label for="locationType2" class="active control-label">Map pointer</label>
	</div>
	<div class="col-md-12">
		<label for="locationError" class="active control-label">Error Message:</label>
		<input type="text" class="validate browser-default form-control" id="locationError" name="locationError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var areaMapInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Area on map</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="area_mapLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="area_mapLabel" name="area_mapLabel" required="" value="Locate Area">
	</div>
	<div class="col-md-12">
		<label for="area_mapError" class="active control-label">Error Message:</label>
		<input type="text" class="validate browser-default form-control" id="area_mapError" name="area_mapError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var scaleInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Scale</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="scaleLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="scaleLabel" name="scaleLabel" required="" value="Scale">
	</div>
	<div class="col-md-12">
		<label for="scaleError" class="active control-label">Error Message:</label>
		<input type="text" class="validate browser-default form-control" id="scaleError" name="scaleError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var signatureInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Signature</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="signatureLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="signatureLabel" name="signatureLabel" required="" value="Signature">
	</div>
	<div class="col-md-12">
		<label for="signatureError" class="active control-label">Error Message:</label>
		<input type="text" class="validate browser-default form-control" id="signatureError" name="scaleError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var barcodeInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Barcode</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="barcodeLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="barcodeLabel" name="barcodeLabel" required="" value="Barcode">
	</div>
	<div class="col-md-12">
		<label for="barcodeError" class="active control-label">Error Message:</label>
		<input type="text" class="validate browser-default form-control" id="barcodeError" name="scaleError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;
	// <div class="col-md-6">
	// 	<input id="required" name="checkbox" type="checkbox" value="value">
	// 	<label for="required">Required</label>
	// </div>

var distanceInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Distance on map</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="distanceLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="distanceLabel" name="distanceLabel" required="" value="Locate distance">
	</div>
	<div class="col-md-12">
		<label for="distanceError" class="active control-label">Error Message:</label>
		<input type="text" class="validate browser-default form-control" id="distanceError" name="distanceError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var nameInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Name</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<div class="row">
			<div class="col-md-12">
				<label for="textboxLabel1" class="active is-imp control-label">Label 1:</label>
				<input class="validate browser-default form-control" id="textboxLabel1" name="textboxLabel" required="" type="text" value="First Name">
			</div>
			<div class="col-md-12">
				<label for="textboxError1" class="active control-label">Error 1:</label>
				<input type="text" class="validate browser-default form-control" id="textboxError1" name="textboxError1" required="" value="Error Message">
			</div>
			<div class="col-md-12">
				<label for="textboxPlaceholder1" class="active control-label">Placeholder 1:</label>
				<input class="validate browser-default form-control" id="textboxPlaceholder1" name="textboxPlaceholder" required="" type="text" value="John">
			</div>
			<div class="col-md-12">
				<label for="textboxLabel2" class="active is-imp control-label">Label 2:</label>
				<input class="validate browser-default form-control" id="textboxLabel2" name="textboxLabel" required="" type="text" value="Last Name">
			</div>
			<div class="col-md-12">
				<label for="textboxPlaceholder2" class="active control-label">Placeholder 2:</label>
				<input class="validate browser-default form-control" id="textboxPlaceholder2" name="textboxPlaceholder" required="" type="text" value="Doe">
			</div>
			<div class="col-md-12">
				<label for="textboxError2" class="active control-label">Error 2:</label>
				<input type="text" class="validate browser-default form-control" id="textboxError2" name="textboxError2" required="" value="Error Message">
			</div>
			<div class="col-md-12">
				<label for="helptextLabel" class="active control-label">Help text:</label>
				<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
			</div>
			<div class="col-md-6">
				<input id="required" name="checkbox" type="checkbox" value="value">
				<label for="required">Required</label>
			</div>
			<div class="col-md-6 _has-parent-div">
				<input id="hasParent" name="checkbox" type="checkbox" value="value">
				<label for="hasParent">Is Conditional</label>
			</div>
			<div class="col-md-12">
				<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
			</div>
		</div>
	</div>
</div>`;

var choiceInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Choice</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<div class="row">
			<div class="col-md-12">
				<label for="choiceLabel" class="active is-imp control-label">Question:</label>
				<input class="validate browser-default form-control" id="choiceLabel" name="choiceLabel" required="" type="text" value="Choice">
			</div>
			<div class="col-md-12 _choice-option">
				<label for="choicePlaceholder1" class="active control-label">Option 1:</label>
				<input class="validate browser-default form-control" id="choicePlaceholder1" name="choicePlaceholder" required="" type="text" value="Option 1">
			</div>
			<div class="_add-choice-option-div col-md-12">
				<div class="_add-choice-option">
					<a class="btn-floating waves-effect waves-light btn _add-button">
						<i class="material-icons">add</i>
					</a>
				</div>
			</div>
			<div class="col-md-12">
				<label class="control-label">Select Choice type:</label>
				<select id="choiceType" class="browser-default">
					<option value="checkbox" selected>Checkbox</option>
					<option value="radio">Radio</option>
					<option value="dropdown">Dropdown</option>
				</select>
			</div>
			<div class="col-md-12">
				<label for="helptextLabel" class="active control-label">Help text:</label>
				<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
			</div>
			<div class="col-md-6">
				<input id="required" name="checkbox" type="checkbox" value="value">
				<label for="required">Required</label>
			</div>
			<div class="col-md-6 _has-parent-div">
				<input id="hasParent" name="checkbox" type="checkbox" value="value">
				<label for="hasParent">Is Conditional</label>
			</div>
			<div class="col-md-12">
				<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
			</div>
		</div>
	</div>
</div>`;

var coRelationInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Correlational Element</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<div class="row">
			<div class="col-md-12">
				<label for="relationLabel" class="active is-imp control-label">Question:</label>
				<input class="validate browser-default form-control" id="relationLabel" name="relationLabel" required="" type="text" value="Relational">
			</div>
			<div class="col-md-12">
				<label for="relationPlaceholder" class="active control-label">Placeholder:</label>
				<input class="validate browser-default form-control" id="relationPlaceholder" name="relationPlaceholder" required="" type="text" value="Select your option">
			</div>
			<div class="col-md-12">
				<label class="active is-imp control-label">Select A Form:</label>
				<select id="relationForm" class="browser-default">
					<option value="" disabled selected>Select form</option>
				</select>
			</div>
			<div class="col-md-12">
				<label class="active is-imp control-label">Select Column:</label>
				<select id="relationColumns" class="browser-default">
					<option value="checkbox" disabled selected>Select cloumn</option>
				</select>
			</div>
			<div class="col-md-12 _select-multiple-div mt15">
				<input id="selectMultiple" name="checkbox" type="checkbox" value="">
				<label for="selectMultiple">Multiple select</label>
			</div>
			<div class="col-md-12 _select-answer-from-parent mt15">
				<input id="answerParent" name="checkbox" type="checkbox" value="">
				<label for="answerParent">Select answer from parent</label>
			</div>
			<div class="col-md-12">
				<label for="helptextLabel" class="active control-label">Help text :</label>
				<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
			</div>
			<div class="col-md-12">
				<label for="relationError" class="active control-label">Error :</label>
				<input type="text" class="validate browser-default form-control" id="relationError" name="relationError" required="" value="Error Message">
			</div>
			<div class="col-md-6">
				<input id="required" name="checkbox" type="checkbox" value="value">
				<label for="required">Required</label>
			</div>
			<div class="col-md-6 _has-parent-div">
				<input id="hasParent" name="checkbox" type="checkbox" value="value">
				<label for="hasParent">Is Conditional</label>
			</div>
			<div class="col-md-12">
				<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
			</div>
		</div>
	</div>
</div>`;

var paymentInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Payment</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<div class="row">
			<div>
				<label for="paymentLabel" class="active is-imp control-label">Product Name:</label>
				<input class="validate browser-default form-control" id="paymentLabel" name="paymentLabel" required="" type="text" value="Product Name">
			</div>
			<div class="col-md-12 product-container">
				<div class="col-md-6 _payment-option" style='padding: 0px;'>
					<label for="productLabel1" class="active is-imp control-label">Enter Product 1:</label>
					<input class="validate browser-default form-control" id="productLabel1" name="productLabel1" required="" type="text" value="">
				</div>
				<div class="col-md-6 _payment-value-option" style='padding: 0 0 0 10px;'>
					<label for="productValue1" class="active control-label">Value:</label>
					<input class="validate browser-default form-control" id="productValue1" name="productValue1" required="" type="text" value="">
				</div>
				<div class="col-md-12 _payment-url-option" style='padding: 0px;'>
					<label for="productUrl1" class="active is-imp control-label">Payment gateway url:</label>
					<input class="validate browser-default form-control" id="productUrl1" name="productUrl1" required="" type="text" value="">
				</div>
			</div>
			<div class="_add-payment-option-div col-md-12" style="display: flex; justify-content: center;">
				<div class="_add-payment-option">
					<a class="btn-floating waves-effect waves-light btn _add-button">
						<i class="material-icons">add</i>
					</a>
				</div>
			</div>
			<div class="col-md-12" style='padding: 0px;'>
				<label for="paymentError" class="active control-label">Error :</label>
				<input type="text" class="validate browser-default form-control" id="paymentError" name="paymentError" required="" value="Error Message">
			</div>
			<div class="col-md-12" style='padding: 0px;'>
				<label for="helptextLabel" class="active control-label">Help text:</label>
				<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
			</div>
			<div class="col-md-6" style='padding: 0px;'>
				<input id="required" name="checkbox" type="checkbox" value="value">
				<label for="required">Required</label>
			</div>
			<div class="col-md-6 _has-parent-div" style='padding: 0px;'>
				<input id="hasParent" name="checkbox" type="checkbox" value="value">
				<label for="hasParent">Is Conditional</label>
			</div>
			<div class="col-md-12">
				<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
			</div>
		</div>
	</div>
</div>`;

var yesnoInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Yes/No</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<div class="row">
			<div class="col-md-12">
				<label for="yesnoLabel" class="active is-imp control-label">Question:</label>
				<input class="validate browser-default form-control" id="yesnoLabel" name="yesnoLabel" required="" type="text" value="Yes/No">
			</div>
			<div class="col-md-6">
				<label for="yesnoPlaceholder1" class="active control-label">True</label>
				<input class="validate browser-default form-control" id="yesnoPlaceholder1" name="yesnoPlaceholder" required="" type="text" value="Yes">
			</div>
			<div class="col-md-6">
				<label for="yesnoPlaceholder2" class="active control-label">False</label>
				<input class="validate browser-default form-control" id="yesnoPlaceholder2" name="yesnoPlaceholder" required="" type="text" value="No">
			</div>
			<div class="col-md-12">
				<label for="helptextLabel" class="active control-label">Help text:</label>
				<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
			</div>
			<div class="col-md-6">
				<input id="required" name="checkbox" type="checkbox" value="value">
				<label for="required">Required</label>
			</div>
			<div class="col-md-6 _has-parent-div">
				<input id="hasParent" name="checkbox" type="checkbox" value="value">
				<label for="hasParent">Is Conditional</label>
			</div>
			<div class="col-md-12">
				<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
			</div>
		</div>
	</div>
</div>`;

var addressInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Address</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<div class="row">
			<div class="col-md-12">
				<label for="addressLabel" class="active is-imp control-label">Label:</label>
				<input class="validate browser-default form-control" id="addressLabel" name="addressLabel" required="" type="text" value="Address">
			</div>
			<div class="col-md-12">
				<label for="pincodeLabel" class="active is-imp control-label">Pincode Label:</label>
				<input class="validate browser-default form-control" id="pincodeLabel" name="pincodeLabel" required="" type="text" value="560102">
			</div>
			<div class="col-md-12">
				<label for="pincodePlaceholder" class="active control-label">Pincode Placeholder:</label>
				<input class="validate browser-default form-control" id="pincodePlaceholder" name="pincodePlaceholder" required="" type="text" value="Pincode">
			</div>
			<div class="col-md-12">
				<label for="pincodeError" class="active control-label">Pincode Error:</label>
				<input type="text" class="validate browser-default form-control" id="pincodeError" name="pincodeError" required="" value="Error Message">
			</div>
			<div class="col-md-12">
				<label for="areaLabel" class="active is-imp control-label">Area Label:</label>
				<input class="validate browser-default form-control" id="areaLabel" name="areaLabel" required="" type="text" value="Area">
			</div>
			<div class="col-md-12">
				<label for="areaPlaceholder" class="active control-label">Area Placeholder:</label>
				<input class="validate browser-default form-control" id="areaPlaceholder" name="areaPlaceholder" required="" type="text" value="HSR Layout Sector 6">
			</div>
			<div class="col-md-12">
				<label for="areaError" class="active control-label">Area Error:</label>
				<input type="text" class="validate browser-default form-control" id="areaError" name="areaError" required="" value="Error Message">
			</div>
			<div class="col-md-12">
				<label for="streetLabel" class="active is-imp control-label">Street Label:</label>
				<input class="validate browser-default form-control" id="streetLabel" name="streetLabel" required="" type="text" value="Street">
			</div>
			<div class="col-md-12">
				<label for="streetPlaceholder" class="active control-label">Street Placeholder:</label>
				<input class="validate browser-default form-control" id="streetPlaceholder" name="streetPlaceholder" required="" type="text" value="208 15th Main">
			</div>
			<div class="col-md-12">
				<label for="streetError" class="active control-label">Street Error:</label>
				<input type="text" class="validate browser-default form-control" id="streetError" name="streetError" required="" value="Error Message">
			</div>
			<div class="col-md-12">
				<label for="helptextLabel" class="active control-label">Help text:</label>
				<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
			</div>
			<div class="col-md-6">
				<input id="required" name="checkbox" type="checkbox" value="value">
				<label for="required">Required</label>
			</div>
			<div class="col-md-6 _has-parent-div">
				<input id="hasParent" name="checkbox" type="checkbox" value="value">
				<label for="hasParent">Is Conditional</label>
			</div>
			<div class="col-md-12">
				<input id="geoLocation" name="checkbox" type="checkbox" value="value">
				<label for="geoLocation">Suggest geolocation</label>
			</div>
			<div class="col-md-12">
				<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
			</div>
		</div>
	</div>
</div>`;

var emailInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Email</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="textboxLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="emailLabel" name="emailLabel" required="" value="Textbox">
	</div>
	<div class="col-md-12">
		<label for="textboxPlaceholder" class="active control-label">Placeholder:</label>
		<input type="text" class="validate browser-default form-control" id="emailPlaceholder" name="emailPlaceholder" required="" value="Suitable Placeholder">
	</div>
	<div class="col-md-12">
		<label for="emailError" class="active control-label">Email Error:</label>
		<input type="text" class="validate browser-default form-control" id="emailError" name="emailError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var numberInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Number</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="numberLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="numberLabel" name="numberLabel" required="" value="Textbox">
	</div>
	<div class="col-md-12">
		<label for="numberPlaceholder" class="active control-label">Placeholder:</label>
		<input type="text" class="validate browser-default form-control" id="numberPlaceholder" name="numberPlaceholder" required="" value="Suitable Placeholder">
	</div>
	<div class="col-md-12">
		<label for="numberError" class="active control-label">Error:</label>
		<input type="text" class="validate browser-default form-control" id="numberError" name="numberError" required="" value="Error Message">
	</div>
	<div class="col-md-6">
		<label for="numberMinLength" class="control-label">Min Characters:</label>
		<input type="text" class="validate browser-default form-control" id="numberMinLength" name="numberMinLength" required="" value="">
	</div>
	<div class="col-md-6">
		<label for="numberMaxLength" class="control-label">Max Characters:</label>
		<input type="text" class="validate browser-default form-control" id="numberMaxLength" name="numberMaxLength" required="" value="">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var websiteInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Website</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="textboxLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="websiteLabel" name="websiteLabel" required="" value="Website">
	</div>
	<div class="col-md-12">
		<label for="textboxPlaceholder" class="active control-label">Placeholder:</label>
		<input type="text" class="validate browser-default form-control" id="websitePlaceholder" name="websitePlaceholder" required="" value="https://www.example.com">
	</div>
	<div class="col-md-12">
		<label for="websiteError" class="active control-label">Website Error:</label>
		<input type="text" class="validate browser-default form-control" id="websiteError" name="websiteError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var currencyInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Currency</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="currencyLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="currencyLabel" name="currencyLabel" required="" value="Currency">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-12">
		<label for="currencySymbol" class="active is-imp control-label">Currency:</label>
		<select id="currencySymbol" class="browser-default">
			<option value="" disabled selected>Choose your currency</option>
		</select>
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var phoneInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Phone</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="textboxLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="phoneLabel" name="phoneLabel" required="" value="Phone">
	</div>
	<div class="col-md-12">
		<label for="phoneError" class="active control-label">Phone Error:</label>
		<input type="text" class="validate browser-default form-control" id="phoneError" name="phoneError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var dateInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Date</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="textboxLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="dateLabel" name="dateLabel" required="" value="Date">
	</div>
	<div class="col-md-12">
		<label for="dateError" class="active control-label">Error:</label>
		<input type="text" class="validate browser-default form-control" id="dateError" name="dateError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var timeInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Time</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="textboxLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="timeLabel" name="timeLabel" required="" value="Time">
	</div>
	<div class="col-md-12">
		<label for="timeError" class="active control-label">Error:</label>
		<input type="text" class="validate browser-default form-control" id="timeError" name="timeError" required="" value="Error Message">
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var ratingInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Rating</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="textboxLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate" id="ratingLabel" name="ratingLabel" required="" value="Rating">
	</div>
	<div class="col-md-12">
		<label class="control-label">Star Count</label>
		<select id="ratingCount" class="browser-default">
			<option value="3" selected>3</option>
			<option value="5">5</option>
			<option value="10">10</option>
		</select>
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var fileInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - File</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="textboxLabel" class="active is-imp control-label">Question:</label>
		<input type="text" class="validate browser-default form-control" id="fileLabel" name="fileLabel" required="" value="File">
	</div>
	<div class="col-md-12">
		<label class="control-label">File Type</label>
		<select id="fileType" multiple class="browser-default">
			<option value="*" selected>All Files</option>
			<option value="image/*">All Images</option>
			<option value="video/*">All Video</option>
			<option value="audio/*">All Audio</option>
			<option value=".pdf">PDF</option>
			<option value=".doc, .docx">DOC</option>
			<option value=".csv, .xls, .xlsx">CSV/Excel</option>
		</select>
	</div>
	<div class="col-md-12">
		<label for="helptextLabel" class="active control-label">Help text:</label>
		<input type="text" class="validate browser-default form-control" id="helpTextLabel" name="helpTextLabel" required="" value="Helptext">
	</div>
	<div class="col-md-6">
		<input id="select-multiple" name="checkbox" type="checkbox" value="value">
		<label for="select-multiple">Select multiple</label>
	</div>
	<div class="col-md-6">
		<input id="required" name="checkbox" type="checkbox" value="value">
		<label for="required">Required</label>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-6">
		<input id="enableGeoTag" name="checkbox" type="checkbox" value="value">
		<label for="enableGeoTag">Enable Geo Tag</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var headerInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Header</div>
		<button class="btn _go-to-form-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="col-md-12">
		<label for="textboxLabel" class="active is-imp control-label">Title:</label>
		<input type="text" class="validate browser-default form-control" id="headerTitle" name="headerTitle" required="" value="Title">
	</div>
	<div class="col-md-12">
		<label for="textboxPlaceholder" class="active control-label">Subtitle:</label>
		<input type="text" class="validate browser-default form-control" id="headerSubtitle" name="headerSubtitle" required="" value="Subtitle">
	</div>
	<div class="file-field col-md-12">
	  <div class="btn">
		<span>Background</span>
		<input type="file" id="_header-background" class="browser-default form-control" onchange="encodeImageFileAsURL(this)">
	  </div>
	  <div class="file-path-wrapper">
		<input class="file-path validate browser-default form-control" type="text">
	  </div>
	</div>
	<div class="col-md-6 offset-s3 _relative">
		<img id="headerBackgroundPreview" src="" alt="" />
		<span id="noBackground" onclick="removeHeaderBackground(this)"><i class="material-icons">close</i></span>
	</div>
	<div class="col-md-6 _has-parent-div">
		<input id="hasParent" name="checkbox" type="checkbox" value="value">
		<label for="hasParent">Is Conditional</label>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var contentInputSettings = `<div class="row _single-element-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Field - Content</div>
	</div>
	<div class="col-md-12">
		<label for="contentText" class="active is-imp control-label">Content Text:</label>
		<textarea class="validate browser-default form-control" id="contentText" name="contentText" value="Suitable Placeholder"></textarea>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var layoutSettings = `<div class="row _single-element-design-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Design - Layout</div>
		<button class="btn _go-to-design-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="layout-title-align-container mb20 mr10 ml10">
		<label class="active control-label">Select title alignment</label>
		<div class="title-align-btn-container mt20 mb20" style="display: flex;">
			<div class="left-align-div">Left</div>
			<div class="center-align-div">Center</div>
			<div class="right-align-div">Right</div>
		</div>
	</div>
	<div class="layout-input-radius-container mb20 mt20 mr10 ml10">
		<div class="input-field-border-slider">
			<div class="slidecontainer" style="width: 100%;">
				<label for="input-border" class="active control-label">Select input field border radius</label>
				<div class="range-slider-container">
					<input type="range" min="0" max="10" value="0" class="slider" id="input-border">
					<div id="input-border-value">0 px</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var backgroundSettings = `<div class="row _single-element-design-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Design - Background</div>
		<button class="btn _go-to-design-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="form-background-color-container mb20 mr10 ml10">
		<label for="form-background-color" class="active control-label mr15">Form background color</label>
		<input type="color" value="#ffffff" id="form-background-color">
	</div>
	<div class="input-background-color-container mb20 mr10 ml10 mt20">
		<label for="input-background-color" class="active control-label mr15">Input field background color</label>
		<input type="color" value="#ffffff" id="input-background-color">
	</div>
	<div class="form-border-container mb20 mt20 mr10 ml10">
		<div class="form-border-slider">
			<div class="slidecontainer" style="width: 100%;">
				<label for="form-border-thickness" class="active control-label">Form border thickness</label>
				<div class="range-slider-container">
					<input type="range" min="0" max="10" value="0" class="slider" id="form-border-thickness">
					<div id="form-border-thickness-value">0 px</div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-border-color-container mb20 mr10 ml10 mt20">
		<label for="form-background-color" class="active control-label mr15">Form border color</label>
		<input type="color" value="#ffffff" id="form-border-color">
	</div>
	<div class="form-border-radius-container mb20 mt20 mr10 ml10">
		<div class="form-border-radius-slider">
			<div class="slidecontainer" style="width: 100%;">
				<label for="form-border-radius" class="active control-label">Form border radius</label>
				<div class="range-slider-container">
					<input type="range" min="0" max="10" value="0" class="slider" id="form-border-radius">
					<div id="form-border-radius-value">0 px</div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-width-container mb20 mt20 mr10 ml10">
		<div class="form-width-slider">
			<div class="slidecontainer" style="width: 100%;">
				<label for="form-width" class="active control-label">Form width</label>
				<div class="range-slider-container">
					<input type="range" min="50" max="75" value="75" class="slider" id="form-width">
					<div id="form-width-value">75 %</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var fontsSettings = `<div class="row _single-element-design-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Design - Fonts</div>
		<button class="btn _go-to-design-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="title-settings mb20">
		<label class="control-label">Title</label>
		<div class="title-settings-container">
			<input type="color" value="#000000" id="title-font-color">
			<select id="title-fontstyle" class="browser-default ml10">
				<option class="abel" value="abel">Abel</option>
			  <option class="archivo-narrow" value="archivo-narrow">Archivo Narrow</option>
			  <option class="arvo" value="arvo">Arvo</option>
			  <option class="bitter" value="bitter">Bitter</option>
			  <option class="cabin" value="cabin">Cabin</option>
			  <option class="chewy" value="chewy">Chewy</option>
			  <option class="chivo" value="chivo">Chivo</option>
			  <option class="dancing-script" value="dancing-script">Dancing Script</option>
			  <option class="delius-swash-caps" value="delius-swash-caps">Delius Swash Caps</option>
			  <option class="fira-mono" value="fira-mono">Fira Mono</option>
			  <option class="great-vibes" value="great-vibes">Great Vibes</option>
			  <option class="inconsolata" value="inconsolata">Inconsolata</option>
			  <option class="karla" value="karla">Karla</option>
			  <option class="lato" value="lato">Lato</option>
			  <option class="libre-franklin" value="libre-franklin">Libre Franklin</option>
			  <option class="lobster" value="lobster">Lobster</option>
			  <option class="merienda-one" value="merienda-one">Merienda One</option>
			  <option class="montserrat" value="montserrat">Montserrat</option>
			  <option class="muli" value="muli">Muli</option>
			  <option class="nunito" value="nunito">Nunito</option>
			  <option class="open-sans" value="open-sans">Open Sans</option>
			  <option class="pangolin" value="pangolin">Pangolin</option>
			  <option class="parisienne" value="parisienne">Parisienne</option>
			  <option class="playfair-display" value="playfair-display">Playfair Display</option>
			  <option class="poppins" value="poppins">Poppins</option>
			  <option class="proxima-nova" value="proxima-nova">Proxima Nova</option>
			  <option class="pt-sans-narrow" value="pt-sans-narrow">Pt Sans Narrow</option>
			  <option class="quicksand" value="quicksand">Quicksand</option>
			  <option class="raleway" value="raleway">Raleway</option>
			  <option class="roboto" value="roboto">Roboto</option>
			  <option class="rubik" value="rubik">Rubik</option>
			  <option class="source-pro" value="source-pro">Source Sans Pro</option>
			  <option class="space-mono" value="space-mono">Space Mono</option>
			  <option class="sue-ellen-francisco" value="sue-ellen-francisco">Sue Ellen Francisco</option>
			  <option class="vollkorn" value="vollkorn">Vollkorn</option>
			  <option class="voltaire" value="voltaire">Voltaire</option>
			  <option class="work-sans" value="work-sans">Work Sans</option>
			</select>
			<input type="number" id="title-font-size" class="form-control browser-default ml10" min="8" max="24" value="16" style="width: 80px">
		</div>
	</div>
	<div class="label-text-settings mb20">
		<label class="control-label">Label Text</label>
		<div class="label-settings-container">
			<input type="color" value="#000000" id="label-font-color">
			<select id="label-fontstyle" class="browser-default ml10">
				<option class="abel" value="abel">Abel</option>
			  <option class="archivo-narrow" value="archivo-narrow">Archivo Narrow</option>
			  <option class="arvo" value="arvo">Arvo</option>
			  <option class="bitter" value="bitter">Bitter</option>
			  <option class="cabin" value="cabin">Cabin</option>
			  <option class="chewy" value="chewy">Chewy</option>
			  <option class="chivo" value="chivo">Chivo</option>
			  <option class="dancing-script" value="dancing-script">Dancing Script</option>
			  <option class="delius-swash-caps" value="delius-swash-caps">Delius Swash Caps</option>
			  <option class="fira-mono" value="fira-mono">Fira Mono</option>
			  <option class="great-vibes" value="great-vibes">Great Vibes</option>
			  <option class="inconsolata" value="inconsolata">Inconsolata</option>
			  <option class="karla" value="karla">Karla</option>
			  <option class="lato" value="lato">Lato</option>
			  <option class="libre-franklin" value="libre-franklin">Libre Franklin</option>
			  <option class="lobster" value="lobster">Lobster</option>
			  <option class="merienda-one" value="merienda-one">Merienda One</option>
			  <option class="montserrat" value="montserrat">Montserrat</option>
			  <option class="muli" value="muli">Muli</option>
			  <option class="nunito" value="nunito">Nunito</option>
			  <option class="open-sans" value="open-sans">Open Sans</option>
			  <option class="pangolin" value="pangolin">Pangolin</option>
			  <option class="parisienne" value="parisienne">Parisienne</option>
			  <option class="playfair-display" value="playfair-display">Playfair Display</option>
			  <option class="poppins" value="poppins">Poppins</option>
			  <option class="proxima-nova" value="proxima-nova">Proxima Nova</option>
			  <option class="pt-sans-narrow" value="pt-sans-narrow">Pt Sans Narrow</option>
			  <option class="quicksand" value="quicksand">Quicksand</option>
			  <option class="raleway" value="raleway">Raleway</option>
			  <option class="roboto" value="roboto">Roboto</option>
			  <option class="rubik" value="rubik">Rubik</option>
			  <option class="source-pro" value="source-pro">Source Sans Pro</option>
			  <option class="space-mono" value="space-mono">Space Mono</option>
			  <option class="sue-ellen-francisco" value="sue-ellen-francisco">Sue Ellen Francisco</option>
			  <option class="vollkorn" value="vollkorn">Vollkorn</option>
			  <option class="voltaire" value="voltaire">Voltaire</option>
			  <option class="work-sans" value="work-sans">Work Sans</option>
			</select>
			<input type="number" id="label-font-size" class="form-control browser-default ml10" min="8" max="24" value="16" style="width: 80px">
		</div>
	</div>
	<div class="input-text-settings mb20">
		<label class="control-label">Input text</label>
		<div class="input-settings-container">
			<input type="color" value="#000000" id="input-font-color">
			<select id="input-fontstyle" class="browser-default ml10">
				<option class="abel" value="abel">Abel</option>
			  <option class="archivo-narrow" value="archivo-narrow">Archivo Narrow</option>
			  <option class="arvo" value="arvo">Arvo</option>
			  <option class="bitter" value="bitter">Bitter</option>
			  <option class="cabin" value="cabin">Cabin</option>
			  <option class="chewy" value="chewy">Chewy</option>
			  <option class="chivo" value="chivo">Chivo</option>
			  <option class="dancing-script" value="dancing-script">Dancing Script</option>
			  <option class="delius-swash-caps" value="delius-swash-caps">Delius Swash Caps</option>
			  <option class="fira-mono" value="fira-mono">Fira Mono</option>
			  <option class="great-vibes" value="great-vibes">Great Vibes</option>
			  <option class="inconsolata" value="inconsolata">Inconsolata</option>
			  <option class="karla" value="karla">Karla</option>
			  <option class="lato" value="lato">Lato</option>
			  <option class="libre-franklin" value="libre-franklin">Libre Franklin</option>
			  <option class="lobster" value="lobster">Lobster</option>
			  <option class="merienda-one" value="merienda-one">Merienda One</option>
			  <option class="montserrat" value="montserrat">Montserrat</option>
			  <option class="muli" value="muli">Muli</option>
			  <option class="nunito" value="nunito">Nunito</option>
			  <option class="open-sans" value="open-sans">Open Sans</option>
			  <option class="pangolin" value="pangolin">Pangolin</option>
			  <option class="parisienne" value="parisienne">Parisienne</option>
			  <option class="playfair-display" value="playfair-display">Playfair Display</option>
			  <option class="poppins" value="poppins">Poppins</option>
			  <option class="proxima-nova" value="proxima-nova">Proxima Nova</option>
			  <option class="pt-sans-narrow" value="pt-sans-narrow">Pt Sans Narrow</option>
			  <option class="quicksand" value="quicksand">Quicksand</option>
			  <option class="raleway" value="raleway">Raleway</option>
			  <option class="roboto" value="roboto">Roboto</option>
			  <option class="rubik" value="rubik">Rubik</option>
			  <option class="source-pro" value="source-pro">Source Sans Pro</option>
			  <option class="space-mono" value="space-mono">Space Mono</option>
			  <option class="sue-ellen-francisco" value="sue-ellen-francisco">Sue Ellen Francisco</option>
			  <option class="vollkorn" value="vollkorn">Vollkorn</option>
			  <option class="voltaire" value="voltaire">Voltaire</option>
			  <option class="work-sans" value="work-sans">Work Sans</option>
			</select>
			<input type="number" id="input-font-size" class="form-control browser-default ml10" min="8" max="20" value="14" style="width: 80px">
		</div>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

var buttonsSettings = `<div class="row _single-element-design-settings-div">
	<div class="col-md-12" style="justify-content: center; display: flex; align-items: center;">
		<div class="col-md-6" style="font-weight: 600; width: auto; font-size: 16px; border-bottom: 1px solid #ef5a20; padding-bottom: 5px; text-align: center;">Design - Buttons</div>
		<button class="btn _go-to-design-elems" style="left: 10px; position: absolute; font-size: 15px !important; font-weight: 600; padding: 0px 10px;"><<</button>
	</div>
	<div class="button-align-container mb20 mr10 ml10">
		<label class="active control-label">Select button alignment</label>
		<div class="button-align-btn-container mt20 mb20">
			<div class="left-align-div">Left</div>
			<div class="center-align-div">Center</div>
			<div class="right-align-div">Right</div>
		</div>
	</div>
	<div class="button-background-color-container mb20 mr10 ml10 mt20">
		<label for="button-background-color" class="active control-label mr15">Button background color</label>
		<input type="color" value="#ef5a20" id="button-background-color">
	</div>
	<div class="button-text-color-container mb20 mr10 ml10 mt20">
		<label for="button-text-color" class="active control-label mr15">Button text color</label>
		<input type="color" value="#ffffff" id="button-text-color">
	</div>
	<div class="button-border-radius-container mb20 mt20 mr10 ml10">
		<div class="button-border-radius-slider">
			<div class="slidecontainer" style="width: 100%;">
				<label for="button-border-radius" class="active control-label">Select button border radius</label>
				<div class="range-slider-container">
					<input type="range" min="0" max="20" value="5" class="slider" id="button-border-radius">
					<div id="button-border-radius-value">0px</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-12">
		<button class="waves-effect waves-light btn" id="saveBtn">Save</button>
	</div>
</div>`;

module.exports = {
	textboxInputSettings,
	textareaInputSettings,
	locationInputSettings,
	areaMapInputSettings,
	scaleInputSettings,
	signatureInputSettings,
	barcodeInputSettings,
	distanceInputSettings,
	nameInputSettings,
	choiceInputSettings,
	coRelationInputSettings,
	paymentInputSettings,
	yesnoInputSettings,
	addressInputSettings,
	emailInputSettings,
	numberInputSettings,
	websiteInputSettings,
	currencyInputSettings,
	phoneInputSettings,
	dateInputSettings,
	timeInputSettings,
	ratingInputSettings,
	fileInputSettings,
	headerInputSettings,
	contentInputSettings,
	layoutSettings,
	backgroundSettings,
	fontsSettings,
	buttonsSettings
}

// elements settings end