import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import ReactPlayer from 'react-player';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend
} from "recharts";
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import moment from 'moment';
import _ from 'lodash';

export default class GraphModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataArray: [],
            kpi_name: null,

        }
    }

    componentDidMount() {
        let arr = [];
        if (this.props.dataArray.length > 0 || this.props.dataArray.length !== undefined) {
            this.props.dataArray.map((el, i) => {
                arr.push({ count: Number(el.value), date: el.date })
            })
            this.setState({ dataArray: arr, kpi_name: this.props.dataArray });
        }

    }
    renderCusomizedLegend = (graphInfo) => {
        return (
            <div className="customized-legend" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className="legend-box" style={{ backgroundColor: "#52a9f5", width: "10px", height: "10px", marginRight: "3px", marginTop: "4px" }}></div>
                <div className="legend-text" style={{ textAlign: "center" }}>{graphInfo}</div>
            </div>
        )
    }

    render() {
        let graphInfo = ""
        if (this.state.kpi_name !== null && this.state.kpi_name.length) {
            graphInfo = this.state.kpi_name[0].name;
            if (this.state.kpi_name[0].unit !== 'None') {
                graphInfo += ' (' + this.state.kpi_name[0].unit + ')';
            }
        }

        return (
            <div className="im-updates-images-modal-container">
                {
                    (this.state.dataArray.length > 0)
                        ?
                        <div>

                            <ResponsiveContainer>
                                <LineChart width="50%" data={this.state.dataArray}
                                    margin={{ top: 5, right: 50, left: 50, bottom: 5 }}>
                                    <XAxis dataKey="date" label="date" />
                                    <YAxis label="count" dataKey="count" />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <Tooltip />
                                    <Legend align='center' content={this.renderCusomizedLegend.bind(this, graphInfo)} />
                                    <Line type="monotone" dataKey="count"
                                        stroke="#52a9f5" strokeWidth={3} />
                                </LineChart>

                            </ResponsiveContainer>
                        </div>
                        :
                        <div>You have not added any accessment to this KPI. Please add one!</div>
                }


            </div>
        )

    }
}