import React from 'react';
import classnames from 'classnames';

import GroupsTable from './GroupsTable';

export default class Groups extends React.Component {
  render() {
    return (
      <div className={classnames('page-container')}>
        <GroupsTable  ngo={this.props.ngo} userData={this.props.userData} params={this.props.match.params} />
      </div>
    );
  }
}