import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import swal from 'sweetalert2';
import Alert from 'react-s-alert';
import { ToastContainer, toast } from 'react-toastify';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';

const readCookie = require('../cookie.js').readCookie;

export default class ImpactTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			impactList: [],
			columns: 0,
			meta_length: 0,
			meta_data: [],
			keyIndicator: null,
			kpiData: null,
			sortedAssessmentData: null,
			dateArray: [],
			sortByDate: [],
			assessmentdata: [],
			allowReporting: false
		}
	}

	componentDidMount() {

		let url = process.env.REACT_APP_MIS_API_URL + "/project/" + this.props.project_id + "/beneficiaries";

		fetch(url, {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('mis_data')).access_token
			}
		})
			.then(res => (res.json()))
			.then(res => {
				console.log(res);
				let sortByDate = [];
				let benefeciaryDates = {};
				for (let i = 0; i < res.beneficiaries.length; i++) {
					for (let j = 0; j < res.beneficiaries[i].reported.length; j++) {
						if (!benefeciaryDates[res.beneficiaries[i].reported[j].date]) {
							sortByDate.push(res.beneficiaries[i].reported[j])
							benefeciaryDates[res.beneficiaries[i].reported[j].date] = true;
						}
					}
				}
				console.log(sortByDate)
				sortByDate = _.sortBy(sortByDate, function (node) {
					return (new Date(node.internalDate).getTime());
				});
				console.log(sortByDate)
				this.setState({ kpiData: res.beneficiaries, sortByDate, allowReporting: res.allowReporting });
			})
			.catch(er => {
				console.log("err", er)
			})

	}

	addAccessmentForm(cell, string, row) {
		let kpi_array = _.find(this.state.kpiData, (el, i) => {
			if (el.id === cell) {
				return el.name
			}
		});
		let assessmentdata = _.find(this.state.assessmentdata, (el, i) => el.value[0].kpi === row);
		// console.log(kpi_array);
		this.props.addAccessment(kpi_array, string, assessmentdata);
	}

	showTableData(row) {
		let accessment_array = _.filter(this.state.keyIndicator, (el, i) => (row === el.value[0].kpi))
		if (accessment_array.length > 0) {
			this.props.onCloseModal(accessment_array);
		}
		else {
			Alert.info("There is no Accessment Data to show. Please Add one");
		}

	}

	showGraphTable(row) {
		let graph_data = _.filter(this.state.keyIndicator, (el, i) => (row === el.value[0].kpi));
		if (graph_data.length > 0) {
			this.props.graphModal(graph_data);
		}
		else {
			Alert.info("There is no Accessment Data to show. Please Add one");
		}

	}

	addNewButton = (row) => {
		return (
			<button className="btn btn-info" onClick={this.addAccessmentForm.bind(this, row)}>Add New</button>
		)
	}

	addViewButton = (row) => {
		return (
			<button className="btn btn-info" onClick={this.showTableData.bind(this, row)}>View</button>
		)

	}

	addPlotGraphButton = (row) => {
		return (
			<button className="btn btn-info" onClick={this.showGraphTable.bind(this, row)}>Plot Graph</button>
		)
	}

	deleteButton = (row) => {
		return (
			<button className="btn btn-default btn-danger" onClick={this.deleteKPI.bind(this, row)}>Delete</button>
		)
	}

	addNewButton = (row) => {
		if (this.state.allowReporting === true) {
			return (
				<button className="btn btn-info" onClick={this.addAccessmentForm.bind(this, row, "beneficiary")}>Add New</button>
			)
		} else {
			return (
				<button className="btn btn-info" title="You can add assesment only when it is funded!" disabled>Add New</button>
			)
		}
	}

	showAssessmentData(date, reported) {
		for (var i = 0; i < reported.length; i++) {
			if (reported[i].hasOwnProperty('date') && reported[i].date === date) return reported[i].value;
		}
		return "-";
	}

	notifySuccess = (text) => toast.success(text);

	render() {
		console.log(this.state.sortByDate)
		return (
			<div>
				<Alert stack={{ limit: 3 }} />
				<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />

				{
					(this.props.visibility)
						?
						(<div style={{ overflowX: 'scroll', overflowY: 'scroll' }}>
							<BootstrapTable data={this.state.kpiData} striped={false} ref='table' hover={true}>
								<TableHeaderColumn dataField="id" isKey={true} hidden={true} export={false} searchable={false}>Id</TableHeaderColumn>
								<TableHeaderColumn dataField="type" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true}>TYPE</TableHeaderColumn>
								<TableHeaderColumn dataField="name" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} >CATEGORY</TableHeaderColumn>
								<TableHeaderColumn dataField="potential" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} >TARGET</TableHeaderColumn>
								{_.map(this.state.sortByDate, (date, i) => (
									<TableHeaderColumn dataField="reported" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.showAssessmentData.bind(this, date.date)}>{date.date}</TableHeaderColumn>
								))}
								<TableHeaderColumn dataField="id" headerAlign='center' dataAlign='center' dataSort={false} hidden={false} export={true} searchable={true} dataFormat={this.addNewButton.bind(this)}>ASSESMENT</TableHeaderColumn>

							</BootstrapTable>
						</div>)

						:
						(null)
				}

			</div>
		)
	}
}